<ng-container *ngIf="payRunReport">
    <div class="summary">
        <div class="summary-column">
            <div class="summary-column-title">
                <span>Pay period</span>
            </div>
            <div class="summary-column-value">
                {{ payRunReport.periodStart | date: "dd/MM/yyyy" }} -
                {{ payRunReport.periodEnd | date: "dd/MM/yyyy" }}
            </div>
        </div>
        <div class="summary-column">
            <div class="summary-column-title">
                <span>Pay date</span>
            </div>
            <div class="summary-column-value">
                {{ payRunReport.payDate | date: "EEE dd MMM, YYYY" }}
            </div>
        </div>
        <div
            class="summary-column"
            *ngIf="payRunReport.bankFileSetting"
        >
            <div class="summary-column-title">
                <span>Bank account</span>
            </div>
            <div class="summary-column-value">
                {{ payRunReport.bankFileSetting.label }}
            </div>
        </div>
        <div class="summary-column">
            <div class="summary-column-title">
                <span>Net payroll</span>
                <i
                    class="las la-exclamation-circle"
                    [tuiHint]="payRunReportInfo.NetPayroll"
                    tuiHintDirection="top"
                ></i>
            </div>
            <div
                data-hj-suppress
                class="summary-column-value"
            >
                {{ payRunReport.netAmount | currency: (currency | uppercase) }}
            </div>
        </div>
        <div class="summary-column">
            <div class="summary-column-title">
                <span>Previous net payroll</span>
                <i
                    class="las la-exclamation-circle"
                    [tuiHint]="payRunReportInfo.PreviousNetPayroll"
                    tuiHintDirection="top"
                ></i>
            </div>
            <div
                data-hj-suppress
                class="summary-column-value"
            >
                {{ payRunReport.previousAmount | currency: (currency | uppercase) }}
            </div>
        </div>

        @if (payRunReport?.totalPayments !== payRunReport?.netAmount) {
            <div class="summary-column">
                <div class="summary-column-title">
                    <span>Total payments</span>
                    <i
                        class="las la-exclamation-circle"
                        [tuiHint]="payRunReportInfo.TotalPayments"
                        tuiHintDirection="top"
                    ></i>
                </div>
                <div
                    data-hj-suppress
                    class="summary-column-value"
                >
                    {{ payRunReport.totalPayments | currency: (currency | uppercase) }}
                </div>
            </div>
        }
    </div>
</ng-container>
