import { KeyValue } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import {
    IRequestFileData,
    IThreadCard,
    ITimeline,
    IVaultInstructionsRequestCardState,
    IVaultInstructionsRequestItem,
    IVaultRequestCardState,
} from "@visoryplatform/threads";
import { GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { Subscription } from "rxjs";
import { mapTo, take, takeUntil } from "rxjs/operators";
import { RfiService } from "../../../services/rfi.service";
import { IRequestForm, IRequestItemFormGroup, RequestItemFormControlValue } from "../interfaces/IRequestForms";
import { RequestTodoActionsComponent } from "../request-todo-actions/request-todo-actions.component";

const defaultGroup = "none";

interface GroupedFormItem {
    originalIndex: number;
    groupName: string;
    formGroup: FormGroup<IRequestItemFormGroup>;
}
interface GroupedForm {
    [key: string]: GroupedFormItem[];
}

function isInstructions(requestItem: RequestItemFormControlValue): requestItem is IVaultInstructionsRequestItem {
    return requestItem instanceof Object && "category" in requestItem;
}

@Component({
    selector: "rfi-todos",
    templateUrl: "./rfi-todos.component.html",
    styleUrls: ["./rfi-todos.component.scss"],
})
export class RfiTodosComponent extends SubscriberBaseComponent implements AfterViewInit, OnChanges, OnDestroy {
    @ViewChildren("todoItemComponents", { read: ElementRef }) todoItemComponents: QueryList<ElementRef>;
    @ViewChildren("todoItemActionComponents") todoItemActionComponents: QueryList<RequestTodoActionsComponent>;

    @Input() state: IVaultRequestCardState | IVaultInstructionsRequestCardState;
    @Input() actionedRequestItems: number;
    @Input() actionedPercentage: number;
    @Input() title: string;
    @Input() form: FormGroup<IRequestForm>;
    @Input() card: IThreadCard;
    @Input() canUpdateTodoListItem: boolean;
    @Input() userId: string;
    @Input() readonly: boolean;
    @Input() thread: ITimeline;
    @Input() canReopenRequest: boolean;
    @Input() isEditing = false;
    @Input() isSkipped = false;
    @Input() showActions = true;
    @Input() secondaryTitle = "To-do list";

    @Output() removeRequestItem = new EventEmitter<{ controlIndex: number; control: AbstractControl }>();
    @Output() addRequestItem = new EventEmitter<boolean>();
    @Output() updateControl = new EventEmitter();

    readonly ANALYTICS_PREFIX = GA_EVENTS_PREFIX.RFI;
    readonly DEFAULT_GROUP = defaultGroup;

    groupedControls: GroupedForm;
    private formSub: Subscription;

    constructor(
        private rfiService: RfiService,
        private cdr: ChangeDetectorRef,
    ) {
        super();
    }

    get vaultId(): string {
        return this.state.vaultId;
    }

    get requestItems(): FormArray<FormGroup<IRequestItemFormGroup>> {
        return this.form.controls.requestItems;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { state } = changes;
        if (state?.currentValue) {
            const formControls = this.form.controls.requestItems;
            this.groupedControls = this.groupFormGroups(formControls);
        }

        if (!this.formSub) {
            this.onControlUpdate();
        }
    }

    ngAfterViewInit(): void {
        this.todoItemComponents.changes
            .pipe(mapTo(this.todoItemComponents), takeUntil(this.ngUnsubscribe))
            .subscribe((components) => {
                components.last?.nativeElement.focus();
                this.cdr.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe();
    }

    trackGroup(_index: number, groupedControls: KeyValue<string, GroupedFormItem[]>): string {
        return groupedControls.key;
    }

    trackGroupedControls(_index: number, groupedControls: GroupedFormItem): string {
        const groupName = groupedControls.formGroup?.controls?.requestItem?.value?.fileId;
        return groupName;
    }

    updateControlValue(control: FormControl<RequestItemFormControlValue>, value: boolean): void {
        if (control.value) {
            control.value.response.complete.state = value;
        }
        this.updateControl.emit(control);
    }

    public unsorted(): number {
        return 0;
    }

    async handleFileAttached(requestItem: RequestItemFormControlValue, file: File): Promise<void> {
        const updatedRequestItem = {
            fileId: requestItem.fileId,
            filenames: [file.name],
        };

        await this.rfiService.updateRequestItems(
            this.thread.id,
            this.vaultId,
            this.card.id,
            [updatedRequestItem],
            file,
        );
    }

    handleRequestItemRemoved(
        controlIndex: number,
        control: FormControl<RequestItemFormControlValue>,
        isCompleted?: boolean,
    ): void {
        if (isCompleted) {
            return;
        }
        const update = {
            controlIndex,
            control,
        };
        this.removeRequestItem.emit(update);
    }

    handleFileDeleted(requestItem: RequestItemFormControlValue, file: IRequestFileData): void {
        this.rfiService
            .handleFileDeleted(requestItem, file, this.thread.id, this.card.id, this.vaultId)
            .pipe(take(1))
            .subscribe();
    }

    async handleTextResponse(requestItem: RequestItemFormControlValue, value: string): Promise<void> {
        if (!requestItem) {
            return;
        }

        const updatedRequestItem = {
            fileId: requestItem?.fileId,
            textResponse: value,
        };

        await this.rfiService.updateRequestItems(this.thread.id, this.vaultId, this.card.id, [updatedRequestItem]);
    }

    async downloadFile(fileId: string, attachment: IRequestFileData): Promise<void> {
        const fileName = attachment.filename;
        await this.rfiService.downloadFile(this.vaultId, fileId, fileName);
    }

    keyboardEvent(event: KeyboardEvent, state: boolean): void {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            this.addRequestItem.emit(state);
        }
    }

    private getExistingControlsLength(groupedForm: GroupedForm): number {
        return Object.values(groupedForm)
            .flatMap((array) => array)
            .reduce((accumulator, item) => (item.formGroup ? accumulator + 1 : accumulator), 0);
    }

    private allElementsHaveCategory(elements: FormArray<FormGroup<IRequestItemFormGroup>>): boolean {
        return elements.controls.every((control) => {
            const requestItem = control.value?.requestItem;
            const hasCategory = isInstructions(requestItem) ? requestItem?.category : null;

            return !!hasCategory;
        });
    }

    private groupFormGroups(formArray: FormArray<FormGroup<IRequestItemFormGroup>>): GroupedForm {
        if (!formArray) {
            return null;
        }

        const allItemsAreGrouped = this.allElementsHaveCategory(formArray);
        const groupFormGroups = formArray.controls.reduce((acc, currentValue, currentIndex) => {
            const requestItem = currentValue.value?.requestItem;
            const nestedValue =
                isInstructions(requestItem) && allItemsAreGrouped ? requestItem?.category : this.DEFAULT_GROUP;

            if (nestedValue !== undefined) {
                if (!acc[nestedValue]) {
                    acc[nestedValue] = [];
                }
                acc[nestedValue].push({
                    originalIndex: currentIndex,
                    groupName: nestedValue || null,
                    formGroup: currentValue,
                });
            }

            return acc;
        }, {});

        return groupFormGroups;
    }

    private onControlUpdate(): void {
        if (this.isEditing) {
            this.formSub = this.form.valueChanges.subscribe((value) => {
                const groupedFormRequestItemLength = this.getExistingControlsLength(this.groupedControls);
                if (value.requestItems.length !== groupedFormRequestItemLength) {
                    const formControls = this.form.controls.requestItems;
                    this.groupedControls = this.groupFormGroups(formControls);
                }
            });
        }
    }
}
