import { Component, Input, OnInit } from "@angular/core";
import { IUpcomingMeeting, Role, WebsocketNotification, WebsocketSubjectType } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { CalendarCardComponent } from "projects/default-plugins/calendar/components/calendar-card/calendar-card.component";
import { combineLatest, concat, interval, Observable } from "rxjs";
import { debounceTime, filter, map, shareReplay, startWith, switchMap } from "rxjs/operators";
import { EmptyStateImageType } from "../../../../portal-modules/src/lib/empty-state/components/empty-state.component";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { ThreadsWebsocketService } from "../../../../portal-modules/src/lib/shared/services/threads-websocket.service";
import { CalendarService } from "../../../calendar/services/calendar.service";

@Component({
    selector: "upcoming-meetings",
    templateUrl: "./upcoming-meetings.component.html",
    styleUrls: ["./upcoming-meetings.component.scss"],
})
export class UpcomingMeetingsComponent implements OnInit {
    @Input() role: Role;

    readonly emptyState = EmptyStateImageType;

    upcomingMeetings$: Observable<IUpcomingMeeting[]>;
    loader = new Loader();
    currentDateMinuteInterval$: Observable<Date>;

    private readonly upcomingMeetingsLimit = 3;

    constructor(
        private calendarService: CalendarService,
        private websocketService: ThreadsWebsocketService,
    ) {
        this.currentDateMinuteInterval$ = interval(1000 * 60).pipe(
            startWith(() => new Date()),
            map(() => new Date()),
        );
    }

    ngOnInit(): void {
        const initial$ = this.loader.wrap(this.calendarService.getUpcomingMeetings());
        const updated$ = this.getMeetingUpdates();

        const upcomingMeetings$ = concat(initial$, updated$);

        this.upcomingMeetings$ = combineLatest([upcomingMeetings$, this.currentDateMinuteInterval$]).pipe(
            map(([upcomingMeetings, currentTime]) => {
                const currentDateTime = DateTime.fromJSDate(currentTime);
                return this.getUpcomingMeetingsInAWeekUpToLimit(
                    upcomingMeetings,
                    currentDateTime,
                    this.upcomingMeetingsLimit,
                );
            }),
            shareReplay(1),
        );
    }

    getUpcomingMeetingsInAWeekUpToLimit(
        upcomingMeetings: IUpcomingMeeting[],
        currentDateTime: DateTime,
        limit: number,
    ): IUpcomingMeeting[] {
        const meetingsInAWeek = upcomingMeetings?.filter((meeting) => this.isUpcomingInAWeek(meeting, currentDateTime));
        const sortedMeetings = meetingsInAWeek?.sort(
            (a, b) =>
                DateTime.fromISO(a.nextInstance.start).toMillis() - DateTime.fromISO(b.nextInstance.start).toMillis(),
        );
        const meetingsUpToLimit = sortedMeetings?.slice(0, limit);

        return meetingsUpToLimit || [];
    }

    trackMeeting(_index: number, meeting: IUpcomingMeeting): string | IUpcomingMeeting {
        if (!meeting) {
            return meeting;
        }

        return `${meeting.threadId}/${meeting.cardId}/${meeting.nextInstance?.start}`;
    }

    private isUpcomingInAWeek(meeting: IUpcomingMeeting, currentDateTime: DateTime): boolean {
        if (!meeting.nextInstance) {
            return false;
        }

        const meetingDate = DateTime.fromISO(meeting.nextInstance.end);
        const weekFromNowDate = DateTime.now().plus({ days: 7 });
        const meetingIsNotInPast = meetingDate >= currentDateTime;
        const meetingIsNotTooFarInFuture = meetingDate <= weekFromNowDate;

        return meetingIsNotInPast && meetingIsNotTooFarInFuture;
    }

    private getMeetingUpdates(): Observable<IUpcomingMeeting[]> {
        return this.websocketService.connectAllEvents().pipe(
            filter((event) => this.isCalendarUpdate(event)),
            debounceTime(1000), //if we have a lot of state changes close together
            switchMap(() => this.calendarService.getUpcomingMeetings()),
            shareReplay(1),
        );
    }

    private isCalendarUpdate(event: WebsocketNotification): boolean {
        return (
            event.subjectType === WebsocketSubjectType.State &&
            event.payload?.cardType === CalendarCardComponent.cardType
        );
    }
}
