<div class="timeline-list">
    <div class="timeline-list-header">
        <h2>Workflows</h2>
    </div>

    <div
        class="timeline-list-top-panel"
        [formGroup]="form"
    >
        <table-filter-sort-actions
            class="timeline-list-top-panel-table-filter-sort-actions"
            [activeFilterCount]="activeFilterCount()"
        >
            <timelines-filters
                table-filters
                formControlName="filters"
            >
            </timelines-filters>
        </table-filter-sort-actions>
    </div>

    @if (formValues$ | async; as formValues) {
        <timelines-paginated
            [filters]="formValues"
            (filtersChange)="form.controls.filters.setValue($event)"
            [delphiSortEnabled]="form.controls.filters.value.delphiSort"
        ></timelines-paginated>
    }
</div>

@if (loader.counter) {
    <loader-icon color="light"></loader-icon>
}
