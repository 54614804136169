import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Invoice } from "@visoryplatform/copilot";
import { BillApprovalState, InvoiceItem } from "@visoryplatform/threads";
import { VaultService } from "@visoryplatform/vault";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";

@Injectable()
export class BillApprovalService {
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private vaultService: VaultService,
    ) {}

    listInvoices(threadId: string, fromDate: string, toDate: string): Observable<Invoice[]> {
        const url = `${this.environment.threadsEndpoints.base}/threads/${threadId}/copilot/bills`;
        return this.http.get<Invoice[]>(url, { params: { fromDate, toDate } });
    }

    downloadVaultPayRunReport(vaultId: string, fileId: string): Observable<Invoice[]> {
        return this.vaultService
            .getDownloadUrl(vaultId, fileId, "index.json")
            .pipe(switchMap((url) => this.downloadRequestAttachment(url)));
    }

    updateRequestItems(threadId: string, cardId: string, data: unknown): Observable<void> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}`;

        return this.http.post<void>(url, { data });
    }

    getInvoiceItemStatus(state$: Observable<BillApprovalState>): Observable<boolean> {
        return state$.pipe(map((state) => state.isCompleted));
    }

    getTotalTransactionAmount(state$: Observable<BillApprovalState>): Observable<number> {
        return state$.pipe(map((state) => this.getCompletedInvoiceItemAmount(state.invoiceItems).length));
    }

    getCompletedInvoiceItemPercentage(state$: Observable<BillApprovalState>): Observable<number> {
        return state$.pipe(
            map((state) => {
                const invoiceItems = state.invoiceItems;
                const completed = this.getCompletedInvoiceItemAmount(invoiceItems);
                return Math.floor((completed.length / invoiceItems.length) * 100);
            }),
        );
    }

    getTotalCompletedInvoiceItemAmount(state$: Observable<BillApprovalState>): Observable<number> {
        return state$.pipe(map((state) => state.invoiceItems.length));
    }

    private getCompletedInvoiceItemAmount(invoiceItems: InvoiceItem[]): InvoiceItem[] {
        return invoiceItems.filter(
            (invoiceItem) => invoiceItem?.response?.approved?.state || invoiceItem?.response?.declined?.state,
        );
    }

    private downloadRequestAttachment(url: string): Observable<Invoice[]> {
        return this.http.get<Invoice[]>(url);
    }
}
