import {
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { CardReply, ICardEvent, IThread, IThreadCard, Role } from "@visoryplatform/threads";
import { IExtension } from "@visoryplatform/workflow-core";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IUiCard, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { filter, map, shareReplay, switchMap, switchMapTo, take } from "rxjs/operators";
import { Loader } from "../../../shared/services/loader";
import { ViewportObserverService } from "../../services/viewport-observer.service";

@Component({
    selector: "ui-card-portal",
    templateUrl: "./ui-card-portal.component.html",
    styleUrls: ["./ui-card-portal.component.scss"],
})
export class UiCardPortalComponent implements OnChanges, OnDestroy {
    @ViewChild("viewExtension", { read: ElementRef, static: false }) set viewExtension(content: ElementRef) {
        this.viewExtension$.next(content);
    }

    @Input() uiCard: IUiCard;
    @Output() cardUpdated = new EventEmitter<void>();

    viewExtension$ = new ReplaySubject<ElementRef>(1);
    userId$: Observable<string>;
    injector: Injector;

    private cardSubscription: Subscription;

    constructor(
        private parentInjector: Injector,
        authService: AuthService,
        private viewPortObserverService: ViewportObserverService,
        public elementRef: ElementRef,
    ) {
        this.userId$ = authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        const { uiCard } = changes;

        if (uiCard && uiCard.currentValue) {
            const {
                threadId,
                cardId,
                thread$,
                card$,
                state$,
                replies$,
                events$,
                navigateTo$,
                role,
                loader,
                cardExtension$,
            } = uiCard.currentValue as IUiCard;

            this.updateComponent(
                threadId,
                cardId,
                thread$,
                card$,
                state$,
                replies$,
                events$,
                navigateTo$,
                role,
                loader,
                cardExtension$,
            );
        }
    }

    ngOnDestroy() {
        if (!this.uiCard) {
            return;
        }

        if (this.cardSubscription) {
            this.cardSubscription.unsubscribe();
        }

        //Complete what we can so card-modules don't accidentally cause memory leaks
        this.uiCard.navigateToSubject.complete();
        this.viewExtension$.complete();
    }

    private updateComponent(
        threadId: string,
        cardId: string,
        thread$: Observable<IThread>,
        card$: Observable<IThreadCard>,
        state$: Observable<any>,
        replies$: Observable<CardReply[]>,
        events$: Observable<ICardEvent>,
        navigateTo$: Observable<any>,
        role: Role,
        loader: Loader,
        cardExtension$: Observable<IExtension>,
    ) {
        const isVisible$ = this.viewExtension$.pipe(
            switchMap((elm) => this.viewPortObserverService.observe(elm)),
            filter((isVisible) => !!isVisible),
            take(1),
            shareReplay(1),
        );

        const visibleState$ = isVisible$.pipe(switchMapTo(state$));
        const visibleReplies$ = isVisible$.pipe(switchMapTo(replies$));

        const cardResources = {
            threadId,
            cardId,
            thread$,
            card$,
            state$: visibleState$,
            replies$: visibleReplies$,
            events$,
            navigateTo$,
            role,
            loader,
            cardExtension$,
        };

        this.injector = Injector.create({
            parent: this.parentInjector,
            providers: [{ provide: THREAD_CARD_RESOURCES, useValue: cardResources }],
        });

        if (this.cardSubscription) {
            this.cardSubscription.unsubscribe();
        }

        this.cardSubscription = cardResources.card$.subscribe((card) => {
            //TODO: refactor timestamp usages to use card observable so we don't need to mutate
            const { modifiedAt, createdAt } = card;
            this.uiCard.timestamp = new Date(modifiedAt || createdAt).getTime();
            this.cardUpdated.emit();
        });
    }
}
