import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { map, Observable } from "rxjs";

const WORKFLOWS_ROUTE = "workflows";

export const threadViewRedirectGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const featureFlagService = inject(FeatureFlagService);

    return featureFlagService.getFlag(route.data.featureFlag as LaunchDarklyFeatureFlags).pipe(
        map((hasOverviewFlag) => {
            const threadId = route.params.threadId;
            const segment = hasOverviewFlag ? route.data.primaryPath : route.data.fallbackPath;
            return router.createUrlTree([WORKFLOWS_ROUTE, threadId, segment]);
        }),
    );
};
