import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "new-password-dialog",
    templateUrl: "./new-password-dialog.component.html",
    styleUrls: ["./new-password-dialog.component.scss"],
})
export class NewPasswordDialogComponent implements OnInit {
    readonly userDetails: any;

    constructor(
        private dialogRef: MatDialogRef<NewPasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private analyticsService: AnalyticsService,
    ) {
        this.userDetails = data.userDetails;
    }

    ngOnInit(): void {
        this.recordAnalyticsEvent("displayed");
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("new-password", category);
    }

    public closeDialog(submitted: boolean) {
        this.dialogRef.close(submitted);
    }
}
