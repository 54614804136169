@if (threads$ | async; as threads) {
    @if (paginator.sort$ | async; as sort) {
        <div class="timelines-paginated-container">
            @if (loader.counter) {
                <loader-icon color="light"></loader-icon>
            }
            <div [class.timelines-paginated-loading]="loader.counter">
                <timelines-table
                    [threads]="threads || []"
                    [globalRole]="role$ | async"
                    [userId]="userId$ | async"
                    [sortActive]="sort.sort"
                    [sortDirection]="sort.order"
                    [hideAccounts]="hideAccounts"
                    [disableSort]="filters.delphiSort"
                    (sortByClick)="onSortByClick($event)"
                >
                </timelines-table>
                <div class="fx-dashboard-threads-paginator">
                    <fx-paginator
                        [enableBack]="paginator.canGoBack$ | async"
                        [enableNext]="paginator.canGoNext$ | async"
                        [paginationDetails]="paginator.paginationDetails$ | async"
                        (goNext)="paginator.goNext()"
                        (goBack)="paginator.goBack()"
                        (goTo)="paginator.goTo($event)"
                    ></fx-paginator>
                </div>
            </div>
        </div>
    }
}
