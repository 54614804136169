import { Pipe, PipeTransform } from "@angular/core";
import { IWorkflow, IWorkflowAction } from "@visoryplatform/workflow-core";
import { Observable, from } from "rxjs";
import { isActionVisible } from "./helpers";

@Pipe({
    name: "showStepAction",
})
export class ShowStepActionPipe implements PipeTransform {
    transform(workflow: IWorkflow, action: IWorkflowAction): Observable<boolean> {
        return from(isActionVisible(workflow, action));
    }
}
