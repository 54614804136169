import { Component, Inject, OnInit } from "@angular/core";
import { Account, AccountMetadata, Tenant } from "@visoryplatform/threads";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { paramsToConnect } from "../../constants/integrations.constants";
import { IntegrationsService } from "../../services/integrations.service";
import { Integration } from "../../types";

type ConnectedParams = Record<string, string>;

@Component({
    selector: "integrations-settings",
    templateUrl: "./integrations-settings.component.html",
    styleUrls: ["./integrations-settings.component.scss"],
})
export class IntegrationsSettingsComponent implements OnInit {
    appName: string = this.environment.appName;
    integrations$: Observable<Integration[]>;
    account$: Observable<Account>;
    hasUpdatePermissions$: Observable<boolean>;
    loader = new Loader();

    showPayrunSettings$: Observable<boolean>;

    constructor(
        private integrationsService: IntegrationsService,
        private accountRouteService: AccountRouteService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        this.hasUpdatePermissions$ = this.integrationsService.hasUpdatePermission();
        this.account$ = this.accountRouteService.getAccount();
        this.integrations$ = this.loader.wrap(this.integrationsService.listIntegrations());

        this.showPayrunSettings$ = combineLatest([this.account$, this.hasUpdatePermissions$]).pipe(
            map(([account, hasUpdatePermissions]) => this.showPayrunSettings(account) && hasUpdatePermissions),
        );

        const params = this.getConnectedParams();

        if (params) {
            this.account$
                .pipe(
                    take(1),
                    switchMap((account) => {
                        const updatedMetadata = this.mapAccountWithTenantParams(account.metadata, params);
                        return this.accountRouteService.updateMetadata(account.id, updatedMetadata);
                    }),
                )
                .subscribe();
        }
    }

    private showPayrunSettings(account: Account): any {
        const integrations = account.metadata.integrations;
        return !integrations?.keypay?.businessId && integrations?.xero?.businessId;
    }

    savePayrunSettings(payrunSettings: Partial<{ deNumber: string; selfBalancing: boolean }>): void {
        this.account$
            .pipe(
                take(1),
                switchMap((account) => {
                    const updatedMetadata = this.mapAccountWithWithPayrunSettings(account.metadata, payrunSettings);
                    return this.accountRouteService.updateMetadata(account.id, updatedMetadata);
                }),
            )
            .subscribe();
    }

    connectIntegration(integration: Integration): void {
        const { issuerType, scopes } = integration;
        const locationHref = window.location.href.split("?")[0];
        const redirectUrl = this.integrationsService.getRedirectUrl(issuerType, scopes, locationHref);
        window.location.href = redirectUrl;
    }

    selectTenant(account: Account, tenant: Tenant, integration: Integration): void {
        const metadata = this.mapAccountWithTenantBusiness(
            account.metadata,
            integration.issuerType,
            tenant.id,
            tenant.name,
        );

        this.accountRouteService.updateMetadata(account.id, metadata).subscribe();
    }

    disconnectIntegration(integration: Integration): void {
        const { issuerType } = integration;
        this.account$
            .pipe(
                take(1),
                switchMap((account) => {
                    const updateMetdata = this.mapAccountWithEmptyIntegration(account.metadata, issuerType);
                    return this.accountRouteService.updateMetadata(account.id, updateMetdata);
                }),
            )
            .subscribe();
    }

    private mapAccountWithWithPayrunSettings(
        accountMetadata: AccountMetadata,
        updatedMetadata: Partial<{
            deNumber: string;
            selfBalancing: boolean;
        }>,
    ): AccountMetadata {
        return {
            ...accountMetadata,
            payrunSettings: {
                ...accountMetadata?.payrunSettings,
                ...updatedMetadata,
            },
        };
    }

    private mapAccountWithTenantMetadata(
        accountMetadata: AccountMetadata,
        issuer: string,
        updatedMetadata: Record<string, string>,
    ): AccountMetadata {
        return {
            ...accountMetadata,
            integrations: {
                ...accountMetadata?.integrations,
                [issuer]: {
                    ...accountMetadata?.integrations?.[issuer],
                    ...updatedMetadata,
                },
            },
        };
    }

    private mapAccountWithTenantParams(metadata: AccountMetadata, params: ConnectedParams): AccountMetadata {
        const issuer = params.iss?.replace(/\//g, "\\");
        const token = `${issuer}/${params.aud}/${params.sub}`;
        console.log("Our params are", params);
        return this.mapAccountWithTenantMetadata(metadata, params.issuerType, { token });
    }

    private mapAccountWithTenantBusiness(
        metadata: AccountMetadata,
        issuer: string,
        businessId: string,
        tenantName: string,
    ): AccountMetadata {
        return this.mapAccountWithTenantMetadata(metadata, issuer, { businessId, tenantName });
    }

    private mapAccountWithEmptyIntegration(metadata: AccountMetadata, issuer: string): AccountMetadata {
        const emptyIssuerMetadata = { token: null, businessId: null, tenantName: null };
        return this.mapAccountWithTenantMetadata(metadata, issuer, emptyIssuerMetadata);
    }

    private getConnectedParams(): ConnectedParams {
        const params = new URLSearchParams(window.location.search);
        const isConnected = paramsToConnect.every((value) => params.has(value));

        if (isConnected) {
            return paramsToConnect.reduce((prev, value) => ({ ...prev, [value]: params.get(value) }), {});
        } else {
            return null;
        }
    }
}
