import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { InternalRoles } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

export const staffGuard: CanActivateFn = (): Observable<boolean> => {
    const authService = inject(AuthService);

    return authService.getUser().pipe(
        map((user) => user && user.globalRole),
        map((role) => InternalRoles.includes(role)),
    );
};
