import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { AcceptedCurrencies } from "@visoryplatform/payments-service-sdk";
import { IBankPayment, IDeduction, IEarningLine, IKiwiSaver, ILeave, IReimbursement } from "@visoryplatform/threads";
import {
    PayRunExpandedCardTitles,
    PayrunKiwiSaverTitles,
    PayrunStudentLoanTitles,
    PayrunStudentLoanTooltip,
} from "../../../constants/payrun-report-constants";
import { IPayrunReportLineData } from "../../../interfaces/IPayrunReportLineData";
import { IPayrunReportLineUI, IPayrunTransformedLine } from "../../../interfaces/IPayrunReportLineUI";

@Injectable({
    providedIn: "root",
})
export class PayrunExpandedDetailsService {
    constructor(
        private currencyPipe: CurrencyPipe,
        private decimalPipe: DecimalPipe,
    ) {}

    mapReportLine(
        reportLine: IPayrunReportLineData,
        isNZPayrun: boolean,
        isSummaryView: boolean,
    ): IPayrunReportLineUI[] {
        let groups = [
            this.getEarningsGroup(isSummaryView, reportLine),
            {
                title: PayRunExpandedCardTitles.Deductions,
                value: this.getDeductionsUI(reportLine.deductions, reportLine.currency),
            },
            {
                title: PayRunExpandedCardTitles.Leave,
                value: this.getLeaveDetailsUI(reportLine.leaves),
            },
        ];

        if (isNZPayrun) {
            groups = groups.concat(
                {
                    title: PayRunExpandedCardTitles.KiwiSaver,
                    value: this.getKiwiSaverPaymentsUI(reportLine.kiwiSaverPayments, reportLine.currency),
                },
                {
                    title: PayRunExpandedCardTitles.StudentLoan,
                    value: this.getStudentLoanUI(reportLine, reportLine.currency),
                },
            );
        }

        groups = groups.concat(
            {
                title: PayRunExpandedCardTitles.Reimbursements,
                value: this.getReimbursementsUI(reportLine.reimbursements, reportLine.currency),
            },
            {
                title: PayRunExpandedCardTitles.Payments,
                value: this.getBankPaymentsUI(reportLine.bankPayments, reportLine.currency),
            },
        );

        return groups.filter((group) => group.value?.length);
    }

    private getEarningsGroup(isSummary: boolean, reportLine: IPayrunReportLineData): IPayrunReportLineUI {
        if (isSummary) {
            return {
                title: PayRunExpandedCardTitles.Earnings,
                value: this.getEarningsSummarisedByRateUI(reportLine.earningsLines, reportLine.currency),
            };
        } else {
            return {
                title: PayRunExpandedCardTitles.Earnings,
                value: this.getEarningsUI(reportLine.earningsLines, reportLine.currency),
            };
        }
    }

    private mapEarningLine(
        line: IEarningLine,
        currency: AcceptedCurrencies,
        isSummary?: boolean,
    ): IPayrunTransformedLine {
        const earningType = {
            type: line.type || "-",
        };

        const earningDetails = {
            location: line.location || "-",
            notes: line.notes || "-",
        };

        const earningUI = {
            hours: this.decimalPipe.transform(line.hours, "1.2-2") || "-",
            rate: this.currencyPipe.transform(line.rate, currency.toUpperCase()) || "-",
            pay_amount: this.currencyPipe.transform(line.payAmount) || "-",
        };

        if (isSummary) {
            return {
                ...earningType,
                ...earningUI,
            };
        } else {
            return {
                ...earningType,
                ...earningDetails,
                ...earningUI,
            };
        }
    }

    private getEarningsUI(
        lines: IEarningLine[],
        currency: AcceptedCurrencies,
        isSummary?: boolean,
    ): IPayrunTransformedLine[] {
        return lines
            .sort((a, b) => a.type.localeCompare(b.type))
            .map((line) => this.mapEarningLine(line, currency, isSummary));
    }

    private getEarningsSummarisedByRateUI(
        lines: IEarningLine[],
        currency: AcceptedCurrencies,
    ): IPayrunTransformedLine[] {
        const summarisedLines = lines.reduce((acc, currentLine) => {
            const matchingIndex = acc.findIndex(
                (existingLine) => existingLine.type === currentLine.type && existingLine.rate === currentLine.rate,
            );
            if (matchingIndex !== -1) {
                const mappedLines = acc.map((existingLine, existingLineIndex) =>
                    this.mapSummarisedLine(currentLine, matchingIndex, existingLine, existingLineIndex),
                );
                return mappedLines;
            } else {
                return [...acc, currentLine];
            }
        }, [] as IEarningLine[]);

        return this.getEarningsUI(summarisedLines, currency, true);
    }

    private mapSummarisedLine(
        line: IEarningLine,
        lineIndex: number,
        existingLine: IEarningLine,
        existingLineIndex: number,
    ): IEarningLine {
        const { hours, payAmount } = existingLine;
        if (existingLineIndex === lineIndex) {
            return { ...existingLine, hours: hours + line.hours, payAmount: payAmount + line.payAmount };
        } else {
            return existingLine;
        }
    }

    private getReimbursementsUI(lines: IReimbursement[], currency: AcceptedCurrencies): Array<IPayrunTransformedLine> {
        return lines?.map((line) => {
            return {
                type: line.label || "-",
                pay_amount: this.currencyPipe.transform(line.amount, currency.toUpperCase()) || "-",
            };
        });
    }

    private getLeaveDetailsUI(lines: ILeave[]): Array<IPayrunTransformedLine> {
        return lines?.map((line) => {
            return {
                type: line.type || "-",
                accrued: this.decimalPipe.transform(line.accrued, "1.2-2") || "-",
                taken: this.decimalPipe.transform(line.taken, "1.2-2") || "-",
                remaining: this.decimalPipe.transform(line.remaining, "1.2-2") || "-",
            };
        });
    }

    private getDeductionsUI(lines: IDeduction[], currency: AcceptedCurrencies): Array<IPayrunTransformedLine> {
        return lines?.map((line) => {
            return {
                type: line.type || "-",
                notes: line.notes || "-",
                pre_tax: this.currencyPipe.transform(line.preTax, currency.toUpperCase()) || "-",
                post_tax: this.currencyPipe.transform(line.postTax, currency.toUpperCase()) || "-",
            };
        });
    }

    private getBankPaymentsUI(lines: IBankPayment[], currency: AcceptedCurrencies): Array<IPayrunTransformedLine> {
        return lines?.map((line) => {
            return {
                type: line.type || "-",
                account: line.account || "-",
                pay_amount: this.currencyPipe.transform(line.payAmount, currency.toUpperCase()) || "-",
            };
        });
    }

    private getKiwiSaverPaymentsUI(lines: IKiwiSaver[], currency: AcceptedCurrencies): Array<IPayrunTransformedLine> {
        const employeeContribution = lines.reduce((sum, obj) => sum + obj.employeeContribution, 0);
        const employerContribution = lines.reduce((sum, obj) => sum + obj.employerContribution, 0);
        const esctContribution = lines.reduce((sum, obj) => sum + obj.esctContribution, 0);

        return [
            {
                type: PayrunKiwiSaverTitles.EmployeeContribution,
                amount: this.currencyPipe.transform(employeeContribution, currency.toUpperCase()) || "-",
            },
            {
                type: PayrunKiwiSaverTitles.EmployerContribution,
                amount: this.currencyPipe.transform(employerContribution, currency.toUpperCase()) || "-",
            },
            {
                type: PayrunKiwiSaverTitles.EsctContribution,
                amount: this.currencyPipe.transform(esctContribution, currency.toUpperCase()) || "-",
            },
        ];
    }

    private getStudentLoanUI(
        lines: IPayrunReportLineData,
        currency: AcceptedCurrencies,
    ): Array<IPayrunTransformedLine> {
        return [
            {
                type: {
                    value: PayrunStudentLoanTitles.SL,
                    tooltip: PayrunStudentLoanTooltip.SL,
                },
                amount: this.currencyPipe.transform(lines.totals.studentLoanAmount, currency.toUpperCase()) || "-",
            },
            {
                type: {
                    value: PayrunStudentLoanTitles.SLCIR,
                    tooltip: PayrunStudentLoanTooltip.SLCIR,
                },
                amount:
                    this.currencyPipe.transform(
                        lines.totals.studentLoanAdditionalVoluntaryAmount,
                        currency.toUpperCase(),
                    ) || "-",
            },
            {
                type: {
                    value: PayrunStudentLoanTitles.SLBOR,
                    tooltip: PayrunStudentLoanTooltip.SLBOR,
                },
                amount:
                    this.currencyPipe.transform(
                        lines.totals.studentLoanAdditionalMandatoryAmount,
                        currency.toUpperCase(),
                    ) || "-",
            },
        ];
    }
}
