import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PayRunEarningsViewType, PayRunExpandedCardTitles } from "../../constants/payrun-report-constants";
import { IPayrunReportLineData } from "../../interfaces/IPayrunReportLineData";
import { IPayrunReportLineUI } from "../../interfaces/IPayrunReportLineUI";
import { PayrunExpandedDetailsService } from "./services/payrun-expanded-details.service";

@Component({
    selector: "payrun-expanded-details",
    templateUrl: "./payrun-expanded-details.component.html",
    styleUrls: ["./payrun-expanded-details.component.scss"],
})
export class PayrunExpandedDetailsComponent implements OnChanges {
    @Input() data: IPayrunReportLineData;
    @Input() isNZPayrun: boolean;

    @Output() viewType = new EventEmitter<PayRunEarningsViewType>();

    titles = PayRunExpandedCardTitles;
    payrunReportGroups$: Observable<IPayrunReportLineUI[]>;
    payrunEarningsViewTypeSource = new BehaviorSubject(PayRunEarningsViewType.Summary);

    constructor(private payrunExpandedDetailsService: PayrunExpandedDetailsService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.payrunReportGroups$ = this.payrunEarningsViewTypeSource.pipe(
                map((value) =>
                    this.payrunExpandedDetailsService.mapReportLine(
                        changes.data.currentValue,
                        this.isNZPayrun,
                        value === PayRunEarningsViewType.Summary,
                    ),
                ),
            );
        }
    }

    switchEarningsViewType(event: PayRunEarningsViewType): void {
        this.payrunEarningsViewTypeSource.next(event);
        this.viewType.emit(event);
    }
}
