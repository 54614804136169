import { AsyncPipe, JsonPipe } from "@angular/common";
import { Component, computed, effect, Inject, Signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { ITimeline, Role } from "@visoryplatform/threads";
import { AssigneeExtensionHelpers, AssigneeType, IStep, IWorkflowAction } from "@visoryplatform/workflow-core";
import { BannerRelevantMemoriesComponent } from "projects/default-plugins/memories/components/banner-relevant-memories/banner-relevant-memories.component";
import { firstValueFrom, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { AnalyticsService, GA_EVENTS_PREFIX } from "../../../analytics";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { SharedModule } from "../../../shared";
import { TaskActionService } from "../../../shared/components/actionable-card/task-action.service";
import { EmbeddedService } from "../../../shared/services/embedded.service";
import { Loader } from "../../../shared/services/loader";
import { ROLE, THREAD } from "../../../threads-ui/interfaces/IUiCard";
import { WorkflowExtensionService } from "../../../threads-ui/services/workflow/workflow-extension.service";
import { isActionEnabled } from "../../../workflow-status/pipes/enable-step-action.pipe";
import { isActionVisible } from "../../../workflow-status/pipes/helpers";
import { ThreadOverviewActionsComponent } from "./thread-overview-actions/thread-overview-actions.component";
import { ThreadOverviewContentComponent } from "./thread-overview-content/thread-overview-content.component";
import { ThreadOverviewHeaderComponent } from "./thread-overview-header/thread-overview-header.component";

@Component({
    selector: "thread-overview",
    templateUrl: "./thread-overview.component.html",
    styleUrls: ["./thread-overview.component.scss"],
    standalone: true,
    imports: [
        ThreadOverviewHeaderComponent,
        BannerRelevantMemoriesComponent,
        ThreadOverviewActionsComponent,
        SharedModule,
        AsyncPipe,
        JsonPipe,
        ThreadOverviewContentComponent,
    ],
    providers: [
        {
            provide: EXTENSION_DISPLAY_SERVICE,
            useClass: EmbeddedService,
        },
        WorkflowExtensionService,
        TaskActionService,
    ],
})
export class ThreadOverviewComponent {
    thread: Signal<ITimeline | undefined>;
    role: Signal<Role | undefined>;
    workflow = computed(() => this.thread()?.workflow);
    currentStepId = computed(() => this.workflow()?.currentStepId);
    currentStep = computed(() => this.getCurrentStep());
    actions = computed(() => this.getStepActions());
    visibleActions = this.createVisibleActionsSignal();
    firstVisibleActionId = computed(() => this.getFirstVisibleActionId());
    stepActionsEnabled = computed(() => isActionEnabled(this.currentStep()));
    loadInitialExtensions = computed(() => this.shouldLoadInitialExtensions());

    actionBusy = false;

    readonly assigneeData = computed(() => AssigneeExtensionHelpers.getAssigneeData(this.currentStep()?.extensions));

    loader = new Loader();
    private lastRanActionId = "";

    constructor(
        @Inject(THREAD) thread$: Observable<ITimeline>,
        @Inject(ROLE) role$: Observable<Role>,
        private workflowExtensionService: WorkflowExtensionService,
        private analyticsService: AnalyticsService,
        private featureFlagService: FeatureFlagService,
        private router: Router,
    ) {
        this.thread = toSignal(thread$);
        this.role = toSignal(role$);

        const isCentreAppEnabled = toSignal(
            this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableCentreAppComponent),
        );

        effect(async () => {
            if (!isCentreAppEnabled()) {
                const threadId = this.thread()?.id;
                await this.router.navigate(["workflows", threadId]);
            }
        });

        effect(async () => {
            if (
                this.stepActionsEnabled() &&
                this.firstVisibleActionId() &&
                this.lastRanActionId !== this.firstVisibleActionId() &&
                this.loadInitialExtensions()
            ) {
                await this.initialExtensionLoad(this.firstVisibleActionId());
            }
        });
    }

    async performAction(actionId: string): Promise<void> {
        console.log("Performing action", this.workflow(), this.currentStepId(), actionId);
        await firstValueFrom(
            this.loader.wrap(
                this.workflowExtensionService.handleAction(
                    this.thread(),
                    this.role(),
                    this.workflow(),
                    this.currentStepId(),
                    actionId,
                ),
            ),
        );
        this.analyticsService.recordEvent(GA_EVENTS_PREFIX.THREADS, actionId);
    }

    async initialExtensionLoad(actionId: string): Promise<void> {
        this.lastRanActionId = actionId;
        await firstValueFrom(
            this.loader.wrap(
                this.workflowExtensionService.runNextActionExtension(
                    this.thread(),
                    this.role(),
                    this.workflow(),
                    this.currentStepId(),
                    actionId,
                ),
            ),
        );
    }

    private async filterVisibleActions(actions: IWorkflowAction[]): Promise<IWorkflowAction[]> {
        if (!actions || actions.length === 0) {
            return [];
        }

        const mappedActions = await Promise.all(
            actions.map(async (action) => {
                const isVisible = await isActionVisible(this.workflow(), action);
                return isVisible ? action : null;
            }),
        );

        return mappedActions.filter((action) => action !== null);
    }

    private getCurrentStep(): IStep | undefined {
        const workflow = this.workflow();
        const stepId = this.currentStepId();
        return workflow?.steps[stepId];
    }

    private getStepActions(): IWorkflowAction[] {
        return this.currentStep()?.actions ?? [];
    }

    private getFirstVisibleActionId(): string | undefined {
        return this.visibleActions()[0]?.id;
    }

    private createVisibleActionsSignal(): Signal<IWorkflowAction[]> {
        return toSignal(toObservable(this.actions).pipe(switchMap((actions) => this.filterVisibleActions(actions))), {
            initialValue: [] as IWorkflowAction[],
        });
    }

    // Only load initial extensions if the step assignee and user role match
    private shouldLoadInitialExtensions(): boolean {
        const isClient = this.role() === Role.Client;

        const loadForExternal = this.assigneeData()?.assigneeType === AssigneeType.External && isClient;
        const loadForInternal = this.assigneeData()?.assigneeType === AssigneeType.Internal && !isClient;

        return loadForExternal || loadForInternal;
    }
}
