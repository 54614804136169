import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IGlobalSearchMessage, SearchSqlRecord } from "@visoryplatform/threads";
import { EmptyStateImageType } from "projects/portal-modules/src/lib/empty-state/components/empty-state.component";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { SearchComponent } from "projects/portal-modules/src/lib/search/components/search/search.component";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { AnalyticsService, GA_EVENTS } from "../../../../portal-modules/src/lib/analytics";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { GlobalSearchService } from "../../services/global-search.service";

enum KeypressEvent {
    Enter = "Enter",
}

@Component({
    selector: "global-search-page",
    templateUrl: "./global-search-page.component.html",
    styleUrls: ["./global-search-page.component.scss"],
})
export class GlobalSearchPageComponent implements OnInit, OnDestroy {
    @ViewChild("searchComponent", { static: false }) searchComponent: SearchComponent;

    readonly gaEvents = GA_EVENTS;
    readonly emptyState = EmptyStateImageType;
    readonly SEARCH_CHARACTER_LIMIT = 2;
    readonly DEBOUNCE_LIMIT = 0;

    form = new FormGroup({
        search: new FormControl<string | null>(null),
    });
    loader = new Loader();
    results$: Observable<SearchSqlRecord<IGlobalSearchMessage>>;
    aiAssistantFeatureEnabled$: Observable<boolean>;
    queryParamsSubscription: Subscription;
    queryParams$: Observable<string>;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private globalSearchService: GlobalSearchService,
        private featureFlagService: FeatureFlagService,
        private anayticsService: AnalyticsService,
        @Inject(ENVIRONMENT) public environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        this.aiAssistantFeatureEnabled$ = this.checkAiAssistantFeatureEnabled();

        this.queryParamsSubscription = this.globalSearchService.setControlValueFromSearchQueryParams(
            this.form.controls.search,
        );

        this.queryParams$ = this.mapQueryParamsToSearch();

        this.results$ = this.queryParams$.pipe(
            switchMap((searchTerm) => {
                if (!searchTerm) {
                    return of({
                        result: [],
                    });
                }

                this.anayticsService.recordEvent("global-search", this.gaEvents.APP_GLOBAL_SEARCH, searchTerm);

                return this.loader.wrap(this.globalSearchService.getSearch(searchTerm));
            }),
            shareReplay(1),
        );
    }

    onKeyPress(event: KeyboardEvent): void {
        if (event.key === KeypressEvent.Enter) {
            this.routeToSearchPage(this.form.value.search);
            this.searchComponent.inputElement.nativeElement.blur();
        }
    }

    ngOnDestroy(): void {
        this.queryParamsSubscription?.unsubscribe();
    }

    routeToSearchPage(query?: string): void {
        void this.router.navigate([], { queryParams: { query } });
    }

    routeToAssistantPage(): void {
        const query = this.form.value.search;
        void this.router.navigate(["/assistant/chats"], { queryParams: { query } });
    }

    private checkAiAssistantFeatureEnabled(): Observable<boolean> {
        const aiAssistantEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableAIAssistant);
        const aiBrandingEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding);

        return combineLatest([aiAssistantEnabled$, aiBrandingEnabled$]).pipe(
            map(([aiAssistant, branding]) => aiAssistant && branding),
        );
    }

    private mapQueryParamsToSearch(): Observable<string> {
        const queryParams$ = this.activatedRoute.queryParams;
        return queryParams$.pipe(map((params) => params.query, shareReplay(1)));
    }
}
