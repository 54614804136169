import { Component } from "@angular/core";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui/findex-ui.module";

@Component({
    selector: "thread-overview-header-ghost-loader",
    imports: [FindexUiModule],
    template: `
        <div class="thread-overview-header-ghost-loader">
            <ghost-loader
                [showAnimation]="true"
                shape="square"
            >
            </ghost-loader>
        </div>
        <div class="thread-overview-subheader-ghost-loader">
            <ghost-loader
                [showAnimation]="true"
                shape="square"
            >
            </ghost-loader>
            <ghost-loader
                class="ghost-loader-avatar"
                [showAnimation]="true"
                shape="round"
                [width]="16"
            >
            </ghost-loader>
            <ghost-loader
                [showAnimation]="true"
                shape="square"
            >
            </ghost-loader>
            <ghost-loader
                [showAnimation]="true"
                shape="square"
            >
            </ghost-loader>
        </div>
    `,
    styles: [
        `
            .thread-overview-header-ghost-loader {
                height: 2rem;
                width: 26rem;
            }

            .thread-overview-subheader-ghost-loader {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin: 1rem 0;

                ghost-loader:not(.ghost-loader-avatar) {
                    width: 6rem;
                    height: 1rem;
                }

                .ghost-loader-avatar {
                    width: 1rem;
                }
            }
        `,
    ],
    standalone: true,
})
export class ThreadOverviewHeaderGhostLoaderComponent {}
