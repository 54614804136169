import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input } from "@angular/core";
import { IParticipant } from "@visoryplatform/threads";

@Component({
    selector: "user-item",
    templateUrl: "./user-item.component.html",
    styleUrls: ["./user-item.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [style({ opacity: 0 }), animate("100ms", style({ opacity: 1 }))]),
            transition(":leave", [animate("100ms", style({ opacity: 0 }))]),
        ]),
    ],
})
export class UserItemComponent {
    @Input() user: IParticipant;
}
