import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { Observable } from "rxjs";
import { VideoChatService } from "../../services/video-chat.service";

@Component({
    selector: "meeting-details",
    templateUrl: "./meeting-details.component.html",
    styleUrls: ["./meeting-details.component.scss"],
})
export class MeetingDetailsComponent implements OnInit {
    @Input() status: string;
    @Input() sessionTerminated: boolean;
    @Input() role: Role;
    @Input() disableJoinCall: boolean;
    @Input() disableEndCall: boolean;

    @Input() showJoinCall: boolean;
    @Input() showEndCall: boolean;

    @Output() terminate = new EventEmitter();
    @Output() join = new EventEmitter();

    readonly gaEvents = GA_EVENTS;
    readonly roles = Role;

    isUserJoined$: Observable<boolean>;

    constructor(private videoChatService: VideoChatService) {}

    ngOnInit() {
        this.isUserJoined$ = this.videoChatService.sharedIsUserInMeeting$;
    }

    terminateSession() {
        this.terminate.emit();
    }
}
