import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IAvatarContent } from "@visoryplatform/fx-ui";
import { IParticipantListing } from "@visoryplatform/threads";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AuthService } from "../../../../../findex-auth";
import { ParticipantCache } from "../../../../services/participant-cache.service";

@Component({
    selector: "participant-avatars",
    templateUrl: "participant-avatars.component.html",
    styleUrls: ["participant-avatars.component.scss"],
})
export class ParticipantAvatarsComponent implements OnChanges {
    @Input() participants: IParticipantListing[];
    @Input() includeSelf = false;

    avatars$: Observable<IAvatarContent[]> = of([]);

    constructor(
        private authService: AuthService,
        private participantCache: ParticipantCache,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        const { participants } = changes;

        if (participants && participants.currentValue) {
            this.avatars$ = this.fetchAvatars(participants.currentValue);
        }
    }

    fetchAvatars(participants: IParticipantListing[] = []): Observable<IAvatarContent[]> {
        if (!participants) {
            return of([]);
        }

        if (participants.length === 1) {
            return this.participantCache.getAvatarContent(participants[0]).pipe(map((avatar) => [avatar]));
        }

        if (this.includeSelf) {
            return this.participantCache.getMultipleAvatars(participants);
        } else {
            return this.authService
                .getUser()
                .pipe(switchMap((currentUser) => this.participantCache.getMultipleAvatars(participants, currentUser)));
        }
    }
}
