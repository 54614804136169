import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AcceptedCurrencies } from "@visoryplatform/payments-service-sdk";
import {
    BankfileSetting,
    CompletedLongRunningTask,
    IntegrationTypes,
    IPayRunListItem,
    IPayRunReport,
    LongRunningTask,
    PayrunReportRegion,
} from "@visoryplatform/threads";
import { VaultService } from "@visoryplatform/vault";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { LongRunningTaskService } from "projects/portal-modules/src/lib/long-running-task/services/long-running-task.service";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "../../../../src/app/injection-token";
export type LongRunningPayrunReport = {
    task: LongRunningTask;
    report: IPayRunReport | null;
};

@Injectable({
    providedIn: "root",
})
export class PayrunReportDataService {
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private http: HttpClient,
        private vaultService: VaultService,
        private longRunningTaskService: LongRunningTaskService,
    ) {}

    getPayRunList(accountId: string, provider: IntegrationTypes): Observable<IPayRunListItem[]> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/payroll/${provider}/items`;

        return this.http.get<IPayRunListItem[]>(url);
    }

    getBankFileSettings(accountId: string, provider: IntegrationTypes): Observable<BankfileSetting[]> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/payroll/${provider}/bank-file/settings`;
        return this.http.get<BankfileSetting[]>(url);
    }

    getPayRunReportById(
        reportId: string | number,
        accountId: string,
        provider: IntegrationTypes,
    ): Observable<LongRunningPayrunReport> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/payroll/${provider}/payrun/${reportId}`;

        return this.http.get<LongRunningTask>(url).pipe(
            switchMap((task) => this.longRunningTaskService.waitForTask(task.id)),
            map((task) => this.mapTaskData(task)),
        );
    }

    downloadVaultPayRunReport(vaultId: string, fileId: string): Observable<IPayRunReport> {
        return this.vaultService
            .getDownloadUrl(vaultId, fileId, "index.json")
            .pipe(switchMap((url) => this.downloadRequestAttachment(url)));
    }

    refreshPayRun(threadId: string, cardId: string): Observable<void> {
        const { base } = this.environment.vaultThreadsEndpoints;
        const url = `${base}/threads/${threadId}/cards/${cardId}/actions/refresh`;

        return this.http.post<LongRunningTask>(url, {}).pipe(
            switchMap((task) => this.longRunningTaskService.waitForTask(task.id)),
            map(() => {}),
        );
    }

    getCurrencyByRegion(region: PayrunReportRegion): AcceptedCurrencies {
        switch (region) {
            case PayrunReportRegion.AU:
                return AcceptedCurrencies.aud;
                break;
            case PayrunReportRegion.NZ:
                return AcceptedCurrencies.nzd;
                break;
            case PayrunReportRegion.SG:
                return AcceptedCurrencies.sgd;
                break;
            // case PayrunReportRegion.US:
            //     return AcceptedCurrencies.usd;
            //     break;
            default:
                return AcceptedCurrencies.aud;
        }
    }

    private mapTaskData(task: CompletedLongRunningTask): LongRunningPayrunReport {
        return {
            task,
            report: this.isPayRunReport(task.data) ? task.data : null,
        };
    }

    private isPayRunReport(data: unknown): data is IPayRunReport {
        return data !== null && typeof data === "object" && "payrunId" in data && "lines" in data;
    }

    private downloadRequestAttachment(url: string): Observable<IPayRunReport> {
        return this.http.get<IPayRunReport>(url);
    }
}
