import { Component, Input } from "@angular/core";

export type BadgeType = "default" | "success" | "error" | "info" | "warning";

@Component({
    selector: "fx-badge",
    templateUrl: "./fx-badge.component.html",
    styleUrls: ["./fx-badge.component.scss"],
})
export class FxBadgeComponent {
    @Input() label: string;
    @Input() type: BadgeType = "default";

    constructor() {}
}
