<div class="thread-overview">
    <div class="thread-overview-header">
        <thread-overview-header
            [thread]="thread()"
            [workflow]="workflow()"
            [currentStep]="currentStep()"
            [busy]="actionBusy"
        ></thread-overview-header>
    </div>
    <div class="thread-overview-content">
        <thread-overview-content
            [busy]="!!loader.counter"
            [assigneeType]="assigneeData()?.assigneeType"
            [role]="role()"
            [currentStepId]="currentStepId()"
        />
    </div>
    <div class="thread-overview-actions">
        <thread-overview-actions
            [thread]="thread()"
            [role]="role()"
            (performAction)="performAction($event)"
            [busy]="actionBusy"
            [visibleActions]="visibleActions()"
        />
    </div>
</div>
