<div class="customer-body">
    <div class="customer-subtitle">Let us know how we can help! Your Visory team will respond as soon as possible.</div>

    <div class="expert-dropdown">
        <label class="fx-form-label">I need help with:</label>
        <div class="v-select-container">
            <v-select
                placeholder="Select a request type"
                [options]="requestCategories"
                [optionContent]="requestTypeContent"
                [tuiTextfieldCleaner]="false"
                [formControl]="formGroup.controls.requestCategory"
            >
            </v-select>

            <ng-template
                #requestTypeContent
                let-option
            >
                {{ option }}
            </ng-template>
        </div>
    </div>

    <select-account
        [accounts]="accounts"
        [formControl]="formGroup.controls.account"
    ></select-account>

    <message-input [formControl]="formGroup.controls.message"></message-input>
</div>
