@if (globalRole$ | async) {
    <div
        [formGroup]="form"
        class="filters"
    >
        <div class="filters-field">
            <v-multi-select
                [options]="services$ | async"
                [optionContent]="serviceOptionContent"
                [valueContent]="serviceValueContent"
                [formControl]="form.controls.type"
                [placeholder]="'All services'"
            ></v-multi-select>
            <ng-template
                #serviceOptionContent
                let-option
            >
                {{ option.value | sentencecase }}
            </ng-template>
            <ng-template
                #serviceValueContent
                let-selected
            >
                {{ selected.length === 1 ? selected[0].value : selected.length + " services" }}
            </ng-template>
        </div>
        @if (!accountId) {
            <div class="filters-field">
                <v-multi-select
                    [options]="accounts$ | async"
                    [optionContent]="accountOptionContent"
                    [valueContent]="accountValueContent"
                    [formControl]="form.controls.account"
                    [placeholder]="'All accounts'"
                ></v-multi-select>
                <ng-template
                    #accountOptionContent
                    let-option
                >
                    {{ option.value }}
                </ng-template>
                <ng-template
                    #accountValueContent
                    let-selected
                >
                    {{ selected.length === 1 ? selected[0].value : selected.length + " accounts" }}
                </ng-template>
            </div>
        }

        @if (globalRole$ | async | permissions: "ViewWorkflowFilter" | async) {
            <div class="filters-field">
                <v-multi-select
                    [options]="workflows$ | async"
                    [optionContent]="workflowOptionContent"
                    [valueContent]="workflowValueContent"
                    [formControl]="form.controls.workflow"
                    [placeholder]="'All workflows'"
                ></v-multi-select>
                <ng-template
                    #workflowOptionContent
                    let-option
                >
                    {{ option.value | sentencecase }}
                </ng-template>
                <ng-template
                    #workflowValueContent
                    let-selected
                >
                    {{ selected.length === 1 ? selected[0].value : selected.length + " workflows" }}
                </ng-template>
            </div>
        }

        @if (FEATURE_FLAGS.EnableDueDateWorkflowFilter | featureEnabled | async) {
            <div class="filters-field filters-field--date-range">
                <v-input-date-range
                    [formControl]="form.controls.dateRange"
                    [dateRangeOptions]="dateRangeOptions"
                    [placeholder]="'Due date'"
                ></v-input-date-range>
            </div>
        }

        <div class="filters-field">
            <v-multi-select
                [options]="statusOptions$ | async"
                [optionContent]="statusOptionContent"
                [valueContent]="statusValueContent"
                [formControl]="form.controls.status"
                [placeholder]="'All statuses'"
                [isGrouped]="true"
            ></v-multi-select>
            <ng-template
                #statusOptionContent
                let-option
            >
                {{ option.value | sentencecase }}
            </ng-template>
            <ng-template
                #statusValueContent
                let-selected
            >
                {{ selected.length === 1 ? selected[0].value : selected.length + " statuses" }}
            </ng-template>
        </div>

        <div class="filters-field">
            <v-multi-select
                [options]="assignees$ | async"
                [optionContent]="assigneesOptionContent"
                [valueContent]="assigneesValueContent"
                [formControl]="form.controls.assignees"
                [placeholder]="'All assignees'"
            ></v-multi-select>
            <ng-template
                #assigneesOptionContent
                let-option
            >
                {{ option.value }}
            </ng-template>
            <ng-template
                #assigneesValueContent
                let-selected
            >
                {{ selected.length === 1 ? selected[0].value : selected.length + " assignees" }}
            </ng-template>
        </div>

        @if (
            (FEATURE_FLAGS.EnableRolesFilter | featureEnabled | async) &&
            (globalRole$ | async | permissions: "ViewRolesFilter" | async)
        ) {
            <div class="filters-field">
                <v-multi-select
                    [options]="rolesFilterOptions"
                    [optionContent]="rolesOptionContent"
                    [valueContent]="rolesValueContent"
                    [formControl]="form.controls.roles"
                    [placeholder]="'All roles'"
                ></v-multi-select>
                <ng-template
                    #rolesOptionContent
                    let-option
                >
                    {{ option.value | sentencecase }}
                </ng-template>
                <ng-template
                    #rolesValueContent
                    let-selected
                >
                    {{ selected.length === 1 ? selected[0].value : selected.length + " roles" }}
                </ng-template>
            </div>
        }

        <div class="filters-field filters-field--search">
            <search
                [analyticsClickEvent]="gaEvents.WORKFLOW_LISTING_SEARCH"
                formControlName="search"
                placeholder="Search workflows"
                tooltip="Search by account, title, status, type, participant or assignee."
            ></search>
        </div>

        @if (showAllWorkflowsToggle$ | async) {
            <div class="filters-field filters-field--toggle">
                <v-toggle formControlName="includeAll">Show all workflows</v-toggle>
            </div>
        }

        @if (showDelphiSortToggle$ | async) {
            <div class="filters-field filters-field--toggle">
                <v-toggle formControlName="delphiSort">
                    <div class="filters-delphi-sort-content">
                        <div
                            class="filters-delphi-sort"
                            [ngClass]="{
                                'filters-delphi-sort--active': form.controls.delphiSort.value,
                                'filters-delphi-sort--inactive': !form.controls.delphiSort.value,
                            }"
                        >
                            <span class="ai-icon"></span>

                            @if (FEATURE_FLAGS.EnableDelphiBranding | featureEnabled | async) {
                                <span>Delphi sort</span>
                            } @else {
                                <span>Smart sort</span>
                            }
                        </div>
                        <i
                            class="las la-exclamation-circle filters-hint"
                            tuiHint="Sort workflows based on priority"
                        >
                        </i>
                    </div>
                </v-toggle>
            </div>
        }
    </div>
}
