import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AccountStatement } from "@visoryplatform/copilot";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";

@Injectable()
export class CopilotService {
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    listStatementLines(
        threadId: string,
        fromDate: string,
        toDate: string,
        useDeprecatedId?: boolean,
    ): Observable<AccountStatement[]> {
        const url = `${this.environment.threadsEndpoints.base}/threads/${threadId}/copilot/statementlines`;
        return this.http.get<AccountStatement[]>(url, { params: { fromDate, toDate, useDeprecatedId } });
    }
}
