import { Inject, Pipe, PipeTransform } from "@angular/core";
import { ActorId } from "@visoryplatform/threads";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";

@Pipe({ name: "isSystemName" })
export class IsSystemNamePipe implements PipeTransform {
    systemNames: string[] = [ActorId.System];

    constructor(@Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        this.systemNames.push(environment.appName.toLowerCase());
    }

    transform(item: string): boolean {
        return typeof item === "string" && this.systemNames.includes(item.toLowerCase());
    }
}
