import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FindexUiModule } from "../findex-ui";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { ExtensionMenuModalComponent } from "./components/extension-menu-modal/extension-menu-modal.component";

@NgModule({
    imports: [CommonModule, FindexUiModule, SharedPipesModule],
    declarations: [ExtensionMenuModalComponent],
})
export class ExtensionSharedModule {}
