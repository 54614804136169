import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ObservableInput, of } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";

// Helper to maintain display state of the loader icon
@Injectable({ providedIn: "root" })
export class Loader {
    public counter = 0;
    public counter$ = new BehaviorSubject(this.counter);

    show(): void {
        void Promise.resolve().then(() => {
            this.counter++;
            this.counter$.next(this.counter);
        });
    }

    hide(): void {
        void Promise.resolve().then(() => {
            this.counter--;
            if (this.counter < 0) {
                console.info("Hiding loader too many times");
                this.counter = 0;
            }
            this.counter$.next(this.counter);
        });
    }

    wrap<T>(observable: ObservableInput<T>): Observable<T> {
        return of(null).pipe(
            tap(() => this.show()),
            switchMap(() => observable),
            finalize(() => this.hide()),
        );
    }

    toggleVisibility(showLoader: boolean): void {
        if (showLoader === true) {
            this.show();
        } else {
            this.hide();
        }
    }
}
