<fx-checkbox
    #checkbox
    [checked]="isCompleted() || isClosed()"
    [disabled]="true"
    [ngClass]="{
        'list-item-checkbox--active': isActive(),
        'list-item-checkbox--completed': isCompleted(),
        'list-item-checkbox--not-ready': isNotReady(),
        'list-item-checkbox--closed': isClosed(),
    }"
>
    <div
        [ngClass]="{
            'list-item-checkbox-grey': isGreyNumber(),
            'list-item-checkbox-number-double-digit': isDoubleDigit(),
            'list-item-checkbox-number': true,
        }"
    >
        {{ displayNumber() }}
    </div>
</fx-checkbox>
