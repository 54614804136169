import { Component, ElementRef, HostBinding, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { AccessLevel, Enterprise, EnterpriseListing, Entity } from "@visoryplatform/openmeasures-core";
import { OpenMeasuresService } from "projects/default-plugins/insights/services/open-measures.service";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import {
    ConfirmPromptComponent,
    ConfirmPromptData,
} from "projects/portal-modules/src/lib/threads-ui/components/confirm-prompt/confirm-prompt.component";
import { Observable, of } from "rxjs";
import { catchError, distinctUntilChanged, map, switchMap } from "rxjs/operators";
import {
    AddEnterpriseDialogComponent,
    AddEnterpriseDialogData,
} from "../../../../portal-modules/src/lib/user-profile/components/user-profile/add-enterprise-dialog/add-enterprise-dialog.component";
import { UserProfileService } from "../../../../portal-modules/src/lib/user-profile/services/user-profile.service";

export type PermissionsListing = EnterpriseListing & {
    enterprise$: Observable<Enterprise>;
    entities$: Observable<Entity[]>;
    permissions$: Observable<AccessLevel>;
};

@Component({
    selector: "insights-permissions-component",
    templateUrl: "./insights-permissions.component.html",
    styleUrls: ["./insights-permissions.component.scss"],
})
export class InsightsPermissionsComponent implements OnInit {
    @HostBinding("class.active") activeClass: boolean;
    @ViewChild("search", { static: false }) inputElement: ElementRef;

    loader = new Loader();

    userId$: Observable<string>;
    enterpriseListings$: Observable<PermissionsListing[]>;

    constructor(
        private openMeasures: OpenMeasuresService,
        private dialog: MatDialog,
        private userProfileService: UserProfileService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        const routeUserId$ = this.route.parent.params.pipe(
            map((params) => params.userId),
            distinctUntilChanged(),
        );

        this.userId$ = routeUserId$.pipe(
            switchMap((userId) => this.getProfile(userId)),
            map((user) => user.id),
        );

        this.enterpriseListings$ = this.userId$.pipe(
            switchMap((userId) => {
                return this.getPermissions(userId);
            }),
        );
    }

    async savePermissions(enterpriseId: string, userId: string, accessLevel: AccessLevel) {
        this.loader.show();
        await this.openMeasures.setPermissions(enterpriseId, userId, accessLevel).toPromise();
        this.loader.hide();
    }

    async removeEnterprise(enterpriseId: string, userId: string): Promise<void> {
        const dialogData: ConfirmPromptData = {
            header: "Are you sure you want to remove this enterprise?",
            message: "This will remove access to the enterprise for the user.",
            analyticsPrefix: GA_EVENTS.INSIGHTS_REMOVE_DIALOG,
            saveButtonTitle: "DELETE",
        };

        const shouldDelete = await this.dialog
            .open<ConfirmPromptComponent, ConfirmPromptData, boolean>(ConfirmPromptComponent, {
                panelClass: ["centered-modal"],
                width: "420px",
                disableClose: true,
                data: dialogData,
            })
            .afterClosed()
            .toPromise();

        if (!shouldDelete) {
            return;
        }

        this.loader.show();
        await this.openMeasures.removeUser(enterpriseId, userId).toPromise();
        this.loader.hide();

        this.enterpriseListings$ = this.getPermissions(userId);
    }

    async addEnterprise(userId: string, enterprises: EnterpriseListing[]): Promise<void> {
        const existingEnterpriseIds = enterprises?.map((enterprise) => enterprise.id) || [];

        await this.dialog
            .open<AddEnterpriseDialogComponent, AddEnterpriseDialogData, void>(AddEnterpriseDialogComponent, {
                panelClass: ["centered-modal"],
                width: "761px",
                disableClose: true,
                data: { userId, existingEnterpriseIds },
            })
            .afterClosed()
            .toPromise();

        this.enterpriseListings$ = this.getPermissions(userId);
    }

    private getPermissions(userId: string): Observable<PermissionsListing[]> {
        const enterprises$ = this.loader.wrap(this.openMeasures.listEnterprises(userId));

        return enterprises$.pipe(
            map((enterpriseListings) => {
                return enterpriseListings.map((listing) => this.getPermissionsListing(listing, userId));
            }),
        );
    }

    private getPermissionsListing(listing: EnterpriseListing, userId: string): PermissionsListing {
        //TODO: handle error instead of swallowing
        const enterprise$ = this.loader.wrap(
            this.openMeasures
                .getEnterprise(listing.id)
                .pipe(catchError((err: unknown) => (console.log("ERROR", err), of(null)))),
        );
        const entities$ = this.loader.wrap(this.openMeasures.getEntities(listing.id));
        const permissions$ = this.loader.wrap(this.openMeasures.getPermissions(listing.id, userId));

        return {
            ...listing,
            enterprise$,
            entities$,
            permissions$,
        };
    }

    private getProfile(userId: string): Observable<AppUser> {
        if (userId) {
            return this.loader.wrap(this.userProfileService.getUserProfile(userId));
        } else {
            return this.loader.wrap(this.userProfileService.getCurrentUserProfile());
        }
    }
}
