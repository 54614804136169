import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { IInvitation } from "@visoryplatform/calendar-types";
import { ISlot } from "@visoryplatform/fx-ui/lib/components/calendar/calendar";
import { IStaffProfile } from "@visoryplatform/threads";
import { CalendarModalComponent } from "projects/default-plugins/calendar/components/calendar-modal/calendar-modal.component";
import { CalendarService } from "projects/default-plugins/calendar/services/calendar.service";
import { FeatureFlagService } from "projects/portal-modules/src/lib/feature-flags";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { OnboardingService } from "projects/portal-modules/src/lib/onboarding/services/onboarding.service";
import { Tour } from "projects/portal-modules/src/lib/shared/interfaces/tours";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ProductTypes, StaffService } from "projects/portal-modules/src/lib/shared/services/staff.service";
import { TourService } from "projects/portal-modules/src/lib/shared/services/tour.service";
import { Observable, of } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { PortalService } from "../../../../../../projects/portal-modules/src/lib/shared/services/portal.service";
import { IOnboardingStep } from "../onboarding-completion/IOnboardingStep";

@Component({
    selector: "app-select-account-executive",
    templateUrl: "./select-account-executive.component.html",
    styleUrls: ["./select-account-executive.component.scss"],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: "fx-onboarding-modal-template" },
})
export class SelectAccountExecutiveComponent implements OnInit, IOnboardingStep {
    readonly theme;

    user: AppUser;
    accountExecutives: IStaffProfile[];
    error: string;
    loader = new Loader();
    currentSlide = 0;

    constructor(
        private staffService: StaffService,
        private portalService: PortalService,
        private router: Router,
        private authService: AuthService,
        private onboardingService: OnboardingService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<SelectAccountExecutiveComponent>,
        private calendarService: CalendarService,
        private tourService: TourService,
        private featureFlagService: FeatureFlagService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.theme = environment.theme;
    }

    getFirstName(staffProfile: IStaffProfile): string {
        const parts = staffProfile.name.split(" ");
        return parts.length > 0 ? parts[0] : "advisor";
    }

    async ngOnInit(): Promise<void> {
        this.loader.show();
        try {
            const staffResponse = await this.staffService.fetchStaff({ product: ProductTypes.Bookkeeping }).toPromise();
            this.accountExecutives = staffResponse.matchingStaff;

            this.user = await this.authService.getUser().pipe(take(1)).toPromise();
            await this.checkUserAccountExecutiveStatus();
        } catch (err) {
            this.error = "An error occurred, please try again";
        } finally {
            this.loader.hide();
        }
    }

    async checkUserAccountExecutiveStatus(): Promise<void> {
        const onboardingDetails = await this.onboardingService.getCompletionDetails(this.user.id).toPromise();
        if (onboardingDetails && onboardingDetails.selectedStaff) {
            this.router.navigateByUrl("/dashboard");
        }
    }

    async requestChat(accountExecutive: IStaffProfile, type: string): Promise<void> {
        this.error = null;

        this.loader.show();

        try {
            const { userId } = accountExecutive;
            const createOnboarding$ = this.portalService.createOnboarding(userId, type === "call");
            const { thread, appointmentId } = await this.loader.wrap(createOnboarding$).toPromise();

            await this.onboardingService
                .updateCompletionDetails(this.user.id, { selectedStaff: accountExecutive })
                .toPromise();

            this.dialogRef.close();

            if (type !== "call") {
                this.router.navigate(["timelines", thread.id]);
                return;
            }

            const invite = await this.calendarService.getClientInvitation(appointmentId).toPromise();
            this.router.navigate(["/dashboard"]);
            this.openCalendarModal(appointmentId, accountExecutive, invite);
        } catch (err) {
            this.error = "An error occurred, please try again";
            throw err;
        } finally {
            this.loader.hide();
        }
    }

    openCalendarModal(invitationId: string, accountExecutive: IStaffProfile, invite: IInvitation): void {
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                invitationId,
                staffParticipant: accountExecutive,
                participants: [...invite.invitees, ...invite.staff],
                duration: invite.duration,
                organiser: { name: accountExecutive.name, id: accountExecutive.userId },
                title: invite.details.title,
                message: invite.message,
                recurrence: invite.recurrence,
            },
        };

        this.dialog
            .open<CalendarModalComponent, any, ISlot>(CalendarModalComponent, options)
            .afterClosed()
            .pipe(
                switchMap((booking) => {
                    if (booking) {
                        return this.bookMeeting(invitationId, booking);
                    } else {
                        return of(null);
                    }
                }),
            )
            .subscribe(() => {
                const { tours } = this.environment.featureFlags;
                const shouldShowTour = this.urlMatchesTour(tours, this.router.url);
                if (shouldShowTour) {
                    this.manageTour(tours);
                }
            });
    }

    updateModel(): void {
        //No update required.
    }

    prevSlide(): void {
        this.scrollToSlide("slide", this.currentSlide - 1);
    }

    nextSlide(): void {
        this.scrollToSlide("slide", this.currentSlide + 1);
    }

    scrollToSlide(prefix: string, sectionId: number): void {
        const id = prefix + "-" + sectionId;
        const element = document.getElementById(id);
        this.currentSlide = sectionId;
        if (element) {
            element.scrollIntoView();
        }
    }

    private urlMatchesTour(tours: Tour[], url: string): boolean {
        if (!tours) {
            return false;
        }
        return tours.some((tour) => url.includes(tour.tourRoute));
    }

    private bookMeeting(invitationId: string, slot: ISlot): Observable<void> {
        return this.loader.wrap(this.calendarService.setAppointment(invitationId, slot.start, slot.end));
    }

    private async manageTour(tours: Tour[]): Promise<void> {
        if (!tours) {
            this.tourService.hideTour();
            return;
        }

        const featureFlags$ = this.featureFlagService.getFlags().pipe(take(1));
        featureFlags$
            .pipe(
                switchMap((featureFlags) => {
                    return this.tourService.queueTours(this.user, featureFlags, tours);
                }),
            )
            .subscribe();
    }
}
