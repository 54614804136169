import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { TuiHintModule } from "@taiga-ui/core";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { PortalUiModule, VPaginationComponent } from "@visoryplatform/portal-ui";
import { SharedModule } from "../shared";
import { FxPaginatorComponent } from "./components/fx-paginator/fx-paginator.component";
import { FxTableComponent } from "./components/fx-table.component";
import { FxColumnDirective } from "./directives/fx-column.directive";
import { FxRowChildDirective } from "./directives/fx-row-child.directive";

const exportableDeclarations = [FxTableComponent, FxColumnDirective, FxRowChildDirective, FxPaginatorComponent];

@NgModule({
    declarations: [...exportableDeclarations],
    exports: [...exportableDeclarations],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        FxUiModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        TuiHintModule,
        PortalUiModule,
        VPaginationComponent,
    ],
})
export class FxTableModule {}
