import { Component, Input, OnDestroy } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";

import { IWorkflowConfiguration } from "@visoryplatform/threads";
import { IWorkflowDesign } from "@visoryplatform/workflow-core";
import { Subscription } from "rxjs";
import { SelectDesignControl } from "../../types/SelectDesignType";
import { WorkflowConfigurationOptions } from "../../types/UniqueThreadType";

type FormDesignGroup = {
    designId: FormControl<string>;
    workflowConfigurationId: FormControl<string>;
};

export type ServiceControl = {
    threadType: string;
    configurationOption: WorkflowConfigurationOptions;
};

export type WorkflowControl = {
    designId: string;
    workflowConfigurationId: string;
};

type SelectDesignChange = (obj: SelectDesignControl) => void;

@Component({
    selector: "select-workflow-form",
    templateUrl: "./select-workflow-form.component.html",
    styleUrls: ["./select-workflow-form.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectWorkflowFormComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: SelectWorkflowFormComponent,
        },
    ],
})
export class SelectWorkflowFormComponent implements ControlValueAccessor, Validator, OnDestroy {
    @Input() workflowDesigns: IWorkflowDesign[];
    @Input() workflowConfigurations: IWorkflowConfiguration[];
    @Input() selectedConfigurationOption: WorkflowConfigurationOptions;

    onChange?: (obj: Partial<WorkflowControl>) => void;
    onTouch?: () => void;
    validatorFn?: () => void;

    readonly selectPlaceholder = "Select a workflow";
    readonly noWorkflowsPlaceholder = "There is no workflow configured for the selected service";
    readonly WorkflowConfigurationOptions = WorkflowConfigurationOptions;

    formSub: Subscription;

    form = new FormGroup<FormDesignGroup>(
        {
            designId: new FormControl<string>(null),
            workflowConfigurationId: new FormControl<string>(null),
        },
        Validators.required,
    );

    constructor() {
        this.formSub = this.form.valueChanges.subscribe((value) => {
            if (this.onTouch) {
                this.onTouch();
            }

            if (this.onChange) {
                this.onChange(value);
            }
        });
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe();
    }

    validate(_control: AbstractControl<any, any>): ValidationErrors | null {
        return this.form.valid ? null : { invalid: true };
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.validatorFn = fn;
    }

    writeValue(obj: WorkflowControl): void {
        this.form.patchValue(obj);
    }

    registerOnChange(fn: SelectDesignChange): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }
}
