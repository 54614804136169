import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Enterprise, LatestTableData, Period, TableReportRow } from "@visoryplatform/openmeasures-core";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { LATEST_PERIOD_ID } from "../../interfaces/IInsightsReportGroup";
import { OpenMeasuresService } from "../../services/open-measures.service";
import { ScatterOrder } from "../scatter-report/scatter-report.component";

@Component({
    selector: "app-insights-metric-detail-route",
    templateUrl: "./insights-metric-detail-route.component.html",
    styleUrls: ["./insights-metric-detail-route.component.scss"],
})
export class InsightsMetricDetailRouteComponent implements OnInit {
    readonly isHigherBetterLabel = "(higher is better)";
    readonly isLowerBetterLabel = "(lower is better)";

    loader = new Loader();
    period: Period;
    report$: Observable<LatestTableData>;
    enterprise$: Observable<Enterprise>;
    metricGroupRow$: Observable<TableReportRow>;
    sortOrder$: Observable<ScatterOrder>;

    enterpriseId: string;
    entityId: string;
    metricId: string;
    metricName: string;
    shouldShowDataSource = false;

    tableData = [];

    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private openMeasures: OpenMeasuresService,
    ) {}

    ngOnInit(): void {
        const enterpriseId = this.route.snapshot?.params?.enterpriseId;
        const entityId = this.route.snapshot?.params?.entityId;
        const metricId = this.route.snapshot?.params?.metricId;
        const periodId = this.route.snapshot?.params?.periodId;

        this.enterprise$ = this.route.params?.pipe(
            map((params) => params.enterpriseId),
            distinctUntilChanged(),
            switchMap((enterpriseId) => this.openMeasures.getEnterprise(enterpriseId)),
            shareReplay(1),
        );

        this.report$ = this.loader.wrap(this.getLatestTableData(enterpriseId, entityId, periodId)).pipe(shareReplay(1));
        this.metricGroupRow$ = this.report$.pipe(
            map((report) => report.groups.find((group) => group?.rows.find((row) => row.metric.id == metricId))),
            map((group) => group?.rows.find((row) => row.metric.id == metricId)),
            shareReplay(1),
        );

        this.sortOrder$ = this.metricGroupRow$.pipe(
            map((groupRow) => (groupRow.metric.isHigherBetter ? ScatterOrder.Ascending : ScatterOrder.Descending)),
        );

        this.enterpriseId = enterpriseId;
        this.entityId = entityId;
        this.metricId = metricId;
    }

    goBack(): void {
        this.location.back();
    }

    toggleShowDataSource(): void {
        this.shouldShowDataSource = !this.shouldShowDataSource;
    }

    getLatestTableData(enterpriseId: string, entityId: string, periodId: string): Observable<LatestTableData> {
        if (periodId === LATEST_PERIOD_ID) {
            return this.openMeasures.getMetrics(enterpriseId, entityId);
        } else {
            return this.getReportsByPeriod(enterpriseId, entityId, periodId);
        }
    }

    getReportsByPeriod(enterpriseId: string, entityId: string, periodId: string): Observable<LatestTableData> {
        const report$ = this.openMeasures.getReportsByPeriod(enterpriseId, periodId, entityId);
        return report$.pipe(map((report) => ({ ...report, periods: [report.period] })));
    }
}
