import { Component, HostBinding, Inject, Input, OnInit } from "@angular/core";
import { ENVIRONMENT } from "../../../../../../../src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";

@Component({
    selector: "loader-icon",
    templateUrl: "./loader-icon.component.html",
    styleUrls: ["./loader-icon.component.scss"],
})
export class LoaderIconComponent implements OnInit {
    @Input() color = "#fff";
    @Input() static = false;
    @Input() showBackground = false;
    enableBrandLoader: boolean;
    @HostBinding("class.static") staticClass = false;
    @HostBinding("class.show-background") background = false;
    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {
        this.enableBrandLoader = this.environment.featureFlags.enableBrandLoader;
    }
    ngOnInit() {
        this.staticClass = this.static;
        this.background = this.showBackground;
    }
}
