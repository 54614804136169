import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { PaymentTypes } from "@visoryplatform/payments-service-sdk";
import { CardStatus, IThreadCard, PaymentAction, Role } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { IPackagePriceDetails } from "../../interfaces/IPackagePriceDetails";
import { IPaymentCardState } from "../../interfaces/IPaymentCardState";

@Component({
    selector: "payment-details",
    templateUrl: "./payment-details.component.html",
    styleUrls: ["./payment-details.component.scss"],
})
export class PaymentDetailsComponent implements OnChanges {
    @Input() packagePriceDetails: IPackagePriceDetails;
    @Input() cardState: IPaymentCardState;
    @Input() accountId: string;
    @Input() role: Role;
    @Input() card: IThreadCard;
    @Output() makePayment = new EventEmitter<string>();

    readonly gaEvents = GA_EVENTS;
    readonly paymentTypes = PaymentTypes;
    readonly ADD_PAYMENT_METHOD_TASK_ACTION = PaymentAction.PAYMENT_METHOD_REQUIRED;
    readonly PAY_NOW_TASK_ACTION = PaymentAction.PAYMENT_REQUIRED;

    roles = Role;
    cardStatuses = CardStatus;

    ngOnChanges(changes: SimpleChanges): void {
        const { cardState, packagePriceDetails } = changes;
        if (cardState && cardState.currentValue) {
            this.cardState = cardState.currentValue;
        }
        if (packagePriceDetails && packagePriceDetails.currentValue) {
            this.packagePriceDetails = packagePriceDetails.currentValue;
        }
    }
}
