import { ROUTES, RouterModule, Routes } from "@angular/router";
import { authGuard } from "projects/portal-modules/src/lib/findex-auth";
import { InsightsAccountListingComponent } from "./components/account-listing/insights-account-listing.component";
import { InsightsMetricDetailRouteComponent } from "./components/insights-metric-detail-route/insights-metric-detail-route.component";
import { InsightsOverviewComponent } from "./components/insights-overview/insights-overview.component";
import { InsightsReportingComponent } from "./components/insights-reporting/insights-reporting.component";

import { NgModule } from "@angular/core";
import { ILibrary, RouteExtension, RouteHelper } from "projects/portal-modules/src/lib/plugins";
import { INSIGHTS_ROUTE_LIBRARY } from "src/app/injection-token";
import { InsightsRouteComponent } from "./components/insights-route/insights-route.component";
import { insightsGuard } from "./services/insights-guard";

// rename route
export const scorecardRoutes: Routes = [
    { path: "", component: InsightsAccountListingComponent, canActivate: [authGuard] },
    { path: ":enterpriseId/entity/:entityId", component: InsightsOverviewComponent },
    {
        path: ":enterpriseId/entity/:entityId/metric/:metricId/period/:periodId",
        component: InsightsMetricDetailRouteComponent,
    },
    { path: "**", redirectTo: "" },
];

const insightsRoutes: Routes = [
    { path: "reports", component: InsightsReportingComponent },
    { path: "report/:vaultId/:fileId", component: InsightsReportingComponent },
];

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule],
    providers: [
        {
            provide: ROUTES,
            useFactory: (library: ILibrary<RouteExtension>) => {
                const dynamicRoutes = RouteHelper.getRoutes(library);

                return [
                    {
                        path: "",
                        component: InsightsRouteComponent,
                        canActivate: [authGuard, insightsGuard],
                        children: [...dynamicRoutes, ...insightsRoutes],
                    },
                    { path: "user/:userId", component: InsightsReportingComponent, canActivate: [authGuard] },
                    { path: "**", redirectTo: "" },
                ];
            },
            deps: [INSIGHTS_ROUTE_LIBRARY],
            multi: true,
        },
    ],
})
export class InsightsRoutingModule {}
