import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IParticipant } from "@visoryplatform/threads";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth/model/AppUser";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { HandledError } from "../../shared/interfaces/errors";

interface UpdateProfileResponse {
    updatedUser: AppUser;
}

export interface ParticipantDetail extends IParticipant {
    participantDetail?: string;
}

interface UpdateUserPayload {
    emailAddress?: string;
    oldPassword?: string;
    newPassword?: string;
    password?: string;
    mobileNumber?: string;
    name?: string;
}

export interface PackageCostDetails {
    amount: string;
    currency: "AUD";
    frequency: "MONTHLY";
}

export interface ActivePackageDetails {
    packageId: string;
    name: string;
    description: string;
    costDetails: PackageCostDetails;
    startDate: string;
    endDate?: string;
    updatedDate: string;
    trial: boolean;
}

@Injectable({ providedIn: "root" })
export class UserProfileService {
    constructor(
        private httpClient: HttpClient,
        private analyticsService: AnalyticsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    getCurrentUserProfile(): Observable<AppUser> {
        const { base } = this.environment.threadsEndpoints;
        const { myProfile } = environmentCommon.threadsEndpoints;
        const url = `${base}${myProfile}`;
        return this.fetchUser(url);
    }

    getUserProfile(userId: string): Observable<AppUser> {
        const { base } = this.environment.threadsEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}/${userId}`;
        return this.fetchUser(url);
    }

    private fetchUser(url: string) {
        return this.httpClient.get<AppUser>(url);
    }

    updateUserProfile(userId: string, details: UpdateUserPayload): Observable<{ updatedUser: Omit<AppUser, "id"> }> {
        const { base } = this.environment.threadsEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}/${userId}`;
        return this.updateProfile(url, details);
    }

    updateCurrentUserProfile(details: UpdateUserPayload): Observable<{ updatedUser: Omit<AppUser, "id"> }> {
        //TODO Ideally this would go through threads backend, didn't have time to do this though.
        const url = `${this.environment.auth.base}${environmentCommon.auth.endpoints.myProfile}`;
        return this.updateProfile(url, details);
    }

    private updateProfile(url: string, details: UpdateUserPayload): Observable<{ updatedUser: Omit<AppUser, "id"> }> {
        const body = {
            ...details,
            emailAddress: details.emailAddress ? details.emailAddress.toLowerCase() : undefined,
            userPoolClientId: this.environment.auth.userPoolWebClientId,
            themeName: this.environment.appTheme,
            redirectUrl: this.environment.emailVerifyUrl,
        };
        return this.httpClient.post<UpdateProfileResponse>(url, body).pipe(
            map((response: UpdateProfileResponse) => {
                const { updatedUser } = response;
                return {
                    updatedUser,
                    //The user ID returned from auth does not have the prefix e.g. cognito-* , so we have to discard it.
                    id: undefined,
                };
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.error && errorResponse.error.data && errorResponse.error.data.status) {
                    const { data } = errorResponse.error;
                    const status = data.status;
                    if (status === "THROTTLED") {
                        this.recordAnalyticsEvent("update-throttle");
                        const throttleHorizon = data.throttleHorizonMinutes
                            ? `up to ${data.throttleHorizonMinutes}`
                            : "a few";

                        return throwError(
                            new Error(
                                `You have sent too many verifications recently. Please wait for ${throttleHorizon} minutes.`,
                            ),
                        );
                    }
                }
                this.recordAnalyticsEvent("update-unknown-error");
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            }),
        );
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("user-profile", category);
    }

    updateUserLastLogin(userId: string): Observable<void> {
        const { base } = this.environment.commonEndpoints;
        const { lastLogin } = environmentCommon.threadsEndpoints;
        const lastLoginUserId = lastLogin.replace(":userId", userId);
        const url = `${base}${lastLoginUserId}`;
        return this.httpClient.post<void>(url, {}).pipe(
            catchError((err) => {
                throw new HandledError(err);
            }),
        );
    }

    deleteUser(userId: string): Observable<{ data: { status: string }; message: string }> {
        const { base } = this.environment.threadsEndpoints;
        const { deleteUser } = environmentCommon.threadsEndpoints;
        const endpointWithUserId = deleteUser.replace(":userId", userId);
        const url = `${base}${endpointWithUserId}`;
        return this.httpClient.delete<{ data: { status: string }; message: string }>(url, {});
    }
}
