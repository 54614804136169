import { Injectable } from "@angular/core";

import { IPaginated } from "@visoryplatform/datastore-types";
import { CrudTypes, ITimeline, WebsocketNotification, WebsocketSubjectType } from "@visoryplatform/threads";
import { Observable, combineLatest } from "rxjs";
import { filter, map, scan, startWith, switchMap, take } from "rxjs/operators";
import { ThreadUpdateService } from "../../shared/services/thread-update-service";
import { ThreadsWebsocketService } from "../../shared/services/threads-websocket.service";
import { TableThreadListing, ThreadsEnrichmentService } from "./threads-enrichment.service";

@Injectable({ providedIn: "root" })
export class SearchableThreadsService {
    constructor(
        private threadsEnrichmentService: ThreadsEnrichmentService,
        private threadsWebsocketService: ThreadsWebsocketService,
        private threadUpdateService: ThreadUpdateService,
    ) {}

    getThreadUpdates(listing: IPaginated<ITimeline>): Observable<IPaginated<ITimeline>> {
        const threadListing$ = listing.result.map((listing) => this.threadUpdateService.getUpdatesByThread(listing));
        return combineLatest(threadListing$).pipe(
            map((result) => ({ ...listing, result })),
            startWith(listing),
        );
    }

    getListingCreatedUpdates(
        userId$: Observable<string>,
        listing: IPaginated<ITimeline>,
    ): Observable<IPaginated<ITimeline>> {
        return userId$.pipe(
            take(1),
            switchMap((userId) => this.threadCreatedUpdates(listing.result, userId)),
            map((result) => ({ ...listing, result })),
            startWith(listing),
        );
    }

    threadListUpdates(currentPage: string, userId: string): Observable<WebsocketNotification> {
        return this.threadsWebsocketService.watchThreadParticipantId(userId).pipe(
            filter(() => !currentPage),
            filter((notification) => this.isThreadListUpdatedEvent(notification)),
        );
    }

    isThreadListUpdatedEvent(event: WebsocketNotification): boolean {
        return event.subjectType === WebsocketSubjectType.ThreadList && event.eventType === CrudTypes.Updated;
    }

    getEnrichedListings(listing: IPaginated<ITimeline>): IPaginated<TableThreadListing> {
        const enrichedThreads = this.threadsEnrichmentService.enrichThreads(listing.result);
        const enrichedListing = {
            ...listing,
            result: enrichedThreads,
        };
        return enrichedListing;
    }

    isThreadListDeletedEvent(event: WebsocketNotification): boolean {
        return event.subjectType === WebsocketSubjectType.ThreadList && event.eventType === CrudTypes.Deleted;
    }

    private threadCreatedUpdates(threads: ITimeline[], userId: string): Observable<ITimeline[]> {
        return this.threadsWebsocketService.watchThreadParticipantId(userId).pipe(
            filter((notification) => this.isThreadListDeletedEvent(notification)),
            filter((update) => this.existsInThreadList(threads, update.threadId)),
            scan((threads, update) => this.removeThreadUpdate(threads, update.threadId), threads),
        );
    }

    private existsInThreadList(threadList: ITimeline[], threadId: string): boolean {
        return threadList.some((thread) => thread.id === threadId);
    }

    private removeThreadUpdate(threads: ITimeline[], threadId: string): ITimeline[] {
        return threads.filter((thread) => thread.id !== threadId);
    }
}
