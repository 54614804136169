import { Inject, Injectable } from "@angular/core";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { IAnalyticsService } from "./IAnalyticsService";

import { ENVIRONMENT } from "src/app/injection-token";
import { AppUser } from "../../findex-auth/model/AppUser";

export interface IFreshmarketer {
    trackCustomEvent: (...args: any[]) => any;
}

declare global {
    interface Window {
        FM?: IFreshmarketer;
    }
}

declare let FM: IFreshmarketer;

@Injectable()
export class FreshmarketerAnalyticService implements IAnalyticsService {
    private currentUserEmailAddress: string;

    configId: string;

    constructor(@Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        if (environment.freshmarketer) {
            const { cdnUrl: freshmarketerCdn } = environment.freshmarketer;
            this.configId = freshmarketerCdn;
        }
    }

    init(user: AppUser) {
        this.currentUserEmailAddress = user.details?.emailAddress;
    }

    sendEvent(category: string, action: string, label?: string, value?: number) {
        if (this.configId && window.FM && this.currentUserEmailAddress) {
            FM.trackCustomEvent(category, {
                email: this.currentUserEmailAddress.toLowerCase(),
                event_action: action,
                event_category: category,
                event_label: label,
                event_value: value,
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/explicit-function-return-type
    setUserId(_user: AppUser) {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/explicit-function-return-type
    pageViewed(_path: string) {}
}
