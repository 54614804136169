@if (shape === GhostLoaderShape.Square) {
    <div
        [style.width]="width + '%'"
        class="ghost-loader ghost-loader--square"
        [ngClass]="{ secondary: secondary, 'ghost-loader--animated': showAnimation }"
    >
        &nbsp;
    </div>
} @else if (shape === GhostLoaderShape.Round) {
    <div
        [style.width]="width + 'px'"
        [style.height]="width + 'px'"
        class="ghost-loader ghost-loader--round"
        [ngClass]="{ secondary: secondary, 'ghost-loader--animated': showAnimation }"
    >
        &nbsp;
    </div>
}
