import { Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Account, IntegrationTypes } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { AccountsService } from "projects/portal-modules/src/lib/threads-ui/services/accounts.service";
import { Observable, Subscription, zip } from "rxjs";
import { map, take } from "rxjs/operators";

type InsightsMetadata = {
    enabled: boolean;
};

type Period = {
    periodStart: string;
    periodEnd: string;
};

@Component({
    selector: "account-insights",
    templateUrl: "./account-insights.component.html",
    styleUrls: ["./account-insights.component.scss"],
})
export class AccountInsightsComponent implements OnDestroy {
    enabled = new FormControl(false);
    loader = new Loader();
    account$: Observable<Account>;
    insights$: Observable<{ enterpriseId?: string; entityId?: string }>;

    range = new FormGroup({
        periodStart: new FormControl(),
        periodEnd: new FormControl(),
    });

    private accountSub: Subscription;
    private readonly format = "yyyy-MM-dd";

    constructor(
        private accountRouteService: AccountRouteService,
        private accountService: AccountsService,
    ) {
        this.account$ = this.accountRouteService.getAccount();
        this.insights$ = this.account$.pipe(map((account) => account.metadata.insights));

        this.accountSub = this.account$.subscribe((account) => {
            const isEnabled = account?.metadata?.insights?.enabled;
            const hasXeroIntegration = account?.metadata?.integrations?.[IntegrationTypes.Xero]?.businessId;

            if (hasXeroIntegration) {
                this.enabled.enable();
            } else {
                this.enabled.disable();
            }

            this.enabled.setValue(isEnabled);
        });
    }

    ngOnDestroy(): void {
        this.accountSub?.unsubscribe();
    }

    saveForm(account: Account, insights: InsightsMetadata): void {
        this.loader.wrap(this.accountService.toggleInsights(account.id, insights.enabled)).pipe(take(1)).subscribe();
    }

    refreshPeriod(account: Account, period: Partial<Period>): void {
        const { periodStart, periodEnd } = period;
        const periods = this.splitPeriodsByMonth(
            DateTime.fromISO(periodStart).startOf("month"),
            DateTime.fromISO(periodEnd),
        );

        const updates$ = periods.map((period) =>
            this.accountService.refreshPeriod(account.id, period.periodStart, period.periodEnd),
        );

        this.loader.wrap(zip(updates$)).pipe(take(1)).subscribe();
    }

    refreshLatestPeriods(account: Account, numPeriods: number): void {
        this.loader.wrap(this.accountService.refreshLatestPeriods(account.id, numPeriods)).pipe(take(1)).subscribe();
    }

    private splitPeriodsByMonth(start: DateTime, end: DateTime): Period[] {
        const nextMonth = start.plus({ months: 1 }).startOf("month");
        const periodStart = start.toFormat(this.format);

        if (nextMonth < end) {
            const currentPeriod = {
                periodStart: periodStart,
                periodEnd: start.endOf("month").toFormat(this.format),
            };
            return [currentPeriod, ...this.splitPeriodsByMonth(nextMonth, end)];
        } else {
            const currentPeriod = { periodStart: periodStart, periodEnd: end.toFormat(this.format) };
            return [currentPeriod];
        }
    }
}
