import { Directive, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

/** @deprecated do not use this component. any subscription which begins from ngOnChanges, or some other place which executes multiple times during the component's lifetime will cause a memory leak */
@Directive()
export class SubscriberBaseComponent implements OnDestroy {
    protected ngUnsubscribe = new Subject();

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    }
}
