import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ITimeline, Role } from "@visoryplatform/threads";
import { IWorkflow, IWorkflowAction } from "@visoryplatform/workflow-core";
import { AnalyticsService, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { merge, Observable, Subject } from "rxjs";
import { Loader } from "../../../shared/services/loader";
import { PortalService } from "../../../shared/services/portal.service";
import { ThreadUpdateService } from "../../../shared/services/thread-update-service";
import {
    EditThreadModalComponent,
    EditThreadModalData,
} from "../../../threads-ui/modules/create-thread/components/edit-thread-modal/edit-thread-modal.component";
import { WorkflowExtensionService } from "../../../threads-ui/services/workflow/workflow-extension.service";
import { WorkflowRouteNames } from "../../../workflow-status/constants/workflow-route-names";

export interface IStateModalData {
    thread: ITimeline;
    role: Role;
}

@Component({
    selector: "thread-state-modal",
    templateUrl: "./thread-state-modal.component.html",
    styleUrls: ["./thread-state-modal.component.scss"],
})
export class ThreadStateModalComponent implements OnInit {
    role: Role;
    thread$: Observable<ITimeline>;
    routes = WorkflowRouteNames;

    loader = new Loader();
    private threadRefresh$ = new Subject<ITimeline>();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: IStateModalData,
        private dialogRef: MatDialogRef<ThreadStateModalComponent>,
        private workflowExtensionService: WorkflowExtensionService,
        private analyticsService: AnalyticsService,
        private threadUpdateService: ThreadUpdateService,
        private portalService: PortalService,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.setValuesFromData();
    }

    close(): void {
        this.dialogRef.close();
    }

    async performAction(thread: ITimeline, role: Role, workflow: IWorkflow, action: IWorkflowAction): Promise<void> {
        console.log("Performing action", workflow, action);

        this.loader.show();

        try {
            await this.workflowExtensionService.handleAction(thread, role, workflow, workflow.currentStepId, action.id);
            this.analyticsService.recordEvent(GA_EVENTS_PREFIX.THREADS, action.label);
        } finally {
            this.loader.hide();
        }
    }

    editDates(thread: ITimeline, globalRole: Role): void {
        const config = {
            data: { thread, role: globalRole },
            disableClose: true,
            panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
        };

        this.dialog
            .open<EditThreadModalComponent, EditThreadModalData>(EditThreadModalComponent, config)
            .afterClosed()
            .subscribe((updated) => {
                if (!updated) {
                    return;
                }

                this.refreshThread(thread.id);
            });
    }

    private refreshThread(threadId: string): void {
        //TODO: wiring in websocket for extension updates
        this.loader
            .wrap(this.portalService.getThreadListById(threadId))
            .subscribe((thread) => this.threadRefresh$.next(thread));
    }

    private setValuesFromData(): void {
        this.role = this.data.role;
        this.thread$ = merge(this.threadUpdateService.getUpdatesByThread(this.data.thread), this.threadRefresh$);
    }
}
