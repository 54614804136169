import { Injectable } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class PayrunRequestService {
    private readonly updatePayrunPermissions = ["PayrunRequestApprove", "ThreadUpdateAll"];

    constructor(private permissionService: PermissionService) {}

    getCanUpdatePayrunItems(userRole: Role, notDisabledOrReadonly: boolean): Observable<boolean> {
        return this.permissionService.hasSomePermission(userRole, this.updatePayrunPermissions).pipe(
            map((canReopenPayrunRequest) => canReopenPayrunRequest && notDisabledOrReadonly),
            shareReplay(1),
        );
    }
}
