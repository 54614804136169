import { Component, computed, Inject, Injector, OnDestroy, OnInit, signal, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BankfileSetting, IntegrationTypes, IPayrunBankSettings, IThreadCard } from "@visoryplatform/threads";
import { PayrunReportDataService } from "projects/default-plugins/payroll/services/payrun-report-data.service";
import { ExtensionDisplayRef } from "projects/portal-modules/src/lib/shared/services/extension-display-ref";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { Observable, of, Subscription } from "rxjs";
import { catchError, filter, map, retry, shareReplay, startWith, take } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { PayrollReportRequest } from "src/environments/sigma-feature-flags";
import { ThreadCardService } from "../../../../../portal-modules/src/lib/threads-ui/services/thread-card.service";
import { VaultRequestService } from "../../../services/vault-request.service";
import { RequestActionButtonLabel, RequestTitle, VaultCardType } from "../constants/request.constants";
import { CreateRequestService } from "../create-rfi-request/create-request.service";
import { IRequestAnalyticsTags } from "../interfaces/IRequestAnalyticsTags";
import { IRequestForm } from "../interfaces/IRequestForms";
import { ICreateRequestModalData } from "../interfaces/request.interfaces";

@Component({
    selector: "app-create-payrun-request",
    templateUrl: "./create-payrun-request.component.html",
    styleUrls: ["./create-payrun-request.component.scss"],
})
export class CreatePayrunRequestComponent implements OnInit, OnDestroy {
    readonly buttonLabel = RequestActionButtonLabel;

    accountName: string;
    analyticsTags: IRequestAnalyticsTags;
    form: FormGroup<IRequestForm>;
    loader = new Loader();
    modalTitle = RequestTitle;
    subtitle: string;
    modalData: ICreateRequestModalData;
    bankFileSettings$: Observable<BankfileSetting[]>;

    longRunningTaskId = signal<string>(null);
    selectedBankFile = signal<BankfileSetting>(null);
    hasNoBankFileSettings = signal<boolean>(false);
    isFormValid: Signal<boolean>;
    disableSave = computed(() => this.shouldDisableSave());

    private bankFileSettingsSub: Subscription;
    private extensionDisplayRef: ExtensionDisplayRef<string>;

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private cardService: ThreadCardService,
        private unsavedDialogService: UnsavedModalDialogService,
        private createRequestService: CreateRequestService,
        private vaultRequestService: VaultRequestService,
        private injector: Injector,
        private payrunDataService: PayrunReportDataService,
    ) {
        this.setCreatePayrunReportForm();

        const isFormValid = this.form.statusChanges.pipe(map(() => this.form.valid));
        this.isFormValid = toSignal(isFormValid);
    }

    async ngOnInit(): Promise<void> {
        this.modalData = await this.extensionDisplayService.getData<ICreateRequestModalData>(this.injector).toPromise();
        this.extensionDisplayRef = await this.extensionDisplayService.getRef<string>(this.injector).toPromise();
        this.bankFileSettings$ = this.listBankFileSettings(this.modalData.thread.accountId, this.modalData.provider);

        this.setLocalVariables(this.modalData);

        this.bankFileSettingsSub = this.bankFileSettings$.pipe(startWith([])).subscribe((settings) => {
            this.hasNoBankFileSettings.set(!settings?.length);
        });
    }

    ngOnDestroy(): void {
        this.bankFileSettingsSub.unsubscribe();
    }

    handleSelectedBankFile(bankFileSetting: BankfileSetting): void {
        this.selectedBankFile.set(bankFileSetting);
    }

    save(): void {
        const { title, cardDescription } = this.form.value;
        const cardType = this.modalData.type;
        const bankFileId = this.selectedBankFile().id;
        const bankFileLabel = `${this.selectedBankFile().accountName} - Account: ${this.selectedBankFile().accountNumber}`;

        const bankFileSetting = bankFileId && {
            id: bankFileId,
            label: bankFileLabel,
        };

        const longRunningTaskId = this.longRunningTaskId();
        this.createPayrunReportCard(cardDescription, title, cardType, longRunningTaskId, bankFileSetting);
    }

    async close(): Promise<void> {
        if (!this.form.dirty) {
            this.extensionDisplayRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("payrun-create");
            if (confirmClose) {
                this.extensionDisplayRef.close();
            }
        }
    }

    private listBankFileSettings(accountId: string, provider: IntegrationTypes): Observable<BankfileSetting[]> {
        const bankFileSettings$ = this.payrunDataService.getBankFileSettings(accountId, provider).pipe(
            catchError((_error: unknown) => of([])),
            filter((settings) => !!settings.length),
            take(1),
            shareReplay(1),
        );

        return this.loader.wrap(bankFileSettings$);
    }

    private createPayrunReportCard(
        cardDescription: string,
        vaultTitle: string,
        cardType: string,
        longRunningTaskId: string,
        bankFileSetting?: IPayrunBankSettings,
    ): void {
        const cardPayload = {
            cardDescription,
            vaultTitle,
            accountId: this.modalData.thread.accountId,
            payrunId: this.modalData.reportListItem.id.toString(),
            payrollType: this.modalData.provider,
            disableEmails: this.modalData.disableEmails,
            isInternal: this.modalData.isInternal,
            longRunningTaskId: longRunningTaskId,
            bankFileSetting,
        };

        this.loader
            .wrap(
                this.cardService
                    .createCard<any, IThreadCard>(this.modalData.thread.id, cardType, cardPayload)
                    .pipe(retry(1)),
            )
            .pipe(take(1))
            .subscribe((response) => {
                this.extensionDisplayRef.close(response.id);
            });
    }

    private getRequestTitle(data: ICreateRequestModalData): string {
        return data.reportListItem ? data.reportListItem.label : data.thread.title || "";
    }

    private setCreatePayrunReportForm(): void {
        this.form = new FormGroup<IRequestForm>({
            title: new FormControl(this.modalTitle.PayrolReport, [Validators.required]),
            cardDescription: new FormControl(PayrollReportRequest.Message, [Validators.required]),
        });
    }

    private setLocalVariables(modalData: ICreateRequestModalData): void {
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultPayrollApprovalRequest);
        this.subtitle = this.getRequestTitle(modalData);
        this.accountName = this.createRequestService.getRequestAccountName(modalData);
    }

    private shouldDisableSave(): boolean {
        return (
            !this.isFormValid() ||
            !this.longRunningTaskId() ||
            (!this.hasNoBankFileSettings() && !this.selectedBankFile())
        );
    }
}
