import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AppUser } from "../../model/AppUser";
import { AuthService } from "../../services/auth.service";

@Component({
    selector: "login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit, OnDestroy {
    private redirectUrl: string;
    user$: Observable<AppUser>;
    loginSub: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
    ) {
        this.user$ = this.authService.getUserWithoutRole().pipe(filter((user) => !!user));

        this.loginSub = this.authService.onLoginSuccess().subscribe(() => {
            this.router.navigateByUrl(this.redirectUrl || "/dashboard");
        });
    }

    ngOnInit() {
        const { redirect } = this.activatedRoute.snapshot.queryParams;
        this.redirectUrl = redirect;
    }

    ngOnDestroy(): void {
        if (this.loginSub) {
            this.loginSub.unsubscribe();
        }
    }
}
