import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { FormControl, FormRecord } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Query, StatementLine } from "@visoryplatform/copilot";
import { VaultService } from "@visoryplatform/vault";
import { NgChanges } from "projects/portal-modules/src/lib/shared/interfaces/ng-changes.interface";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Subscription, merge } from "rxjs";
import { debounceTime, filter, last, map, pairwise, startWith } from "rxjs/operators";

export type TransactionQuery = Partial<{
    query: Partial<Query>;
    statementLine: StatementLine;
}>;

@Component({
    selector: "transaction-list",
    templateUrl: "./transaction-list.component.html",
    styleUrls: ["./transaction-list.component.scss"],
})
export class TransactionListComponent implements OnChanges, OnDestroy {
    @Input() transactions: TransactionQuery[];
    @Input() editMode = false;

    @Output() remove = new EventEmitter<StatementLine>();
    @Output() changeQuery = new EventEmitter<TransactionQuery>();

    loader = new Loader();
    tableData = new MatTableDataSource<TransactionQuery>();

    queryForms = new FormRecord<FormControl<string>>({});
    answerForms = new FormRecord<FormControl<Partial<Query>>>({});

    valueSub: Subscription;

    constructor(private vaultService: VaultService) {
        const queryChanges$ = this.queryForms.valueChanges.pipe(
            pairwise(),
            map(([prev, curr]) => this.reduceQueryChanges(prev, curr)),
            startWith(null),
        );

        const answerChanges$ = this.answerForms.valueChanges.pipe(
            debounceTime(400),
            pairwise(),
            map(([prev, curr]) => this.reduceAnswerChanges(prev, curr)),
            startWith(null),
        );

        this.valueSub = merge(queryChanges$, answerChanges$)
            .pipe(filter((queryVals) => !!queryVals))
            .subscribe((queryVals) => {
                const [subjectId, query] = Object.entries(queryVals).find((query) => !!query) ?? [];
                const findQuery = this.transactions.find(({ query }) => query.subjectId === subjectId);
                if (!findQuery) {
                    return;
                }

                this.changeQuery.emit({
                    ...findQuery,
                    query: {
                        ...findQuery.query,
                        ...query,
                    },
                });
            });
    }

    ngOnDestroy(): void {
        this.valueSub?.unsubscribe();
    }

    ngOnChanges(changes: NgChanges<TransactionListComponent>): void {
        const { transactions, editMode } = changes;

        if (transactions && this.transactions) {
            this.setForms(this.transactions);
            this.tableData.data = this.transactions.map((transaction) => ({
                ...transaction,
                hideRowChild: true,
            }));
        }

        if (editMode || transactions) {
            if (this.editMode) {
                this.answerForms.disable();
            } else {
                this.answerForms.enable();
            }
        }
    }

    async attachFile(query: Partial<Query>, file: File): Promise<void> {
        const { id: queryId } = query;
        const vaultId = query?.vaultDetails?.vaultId;
        const fileId = await this.loader
            .wrap(this.vaultService.uploadFile(vaultId, file.name, file, queryId).pipe(last()))
            .toPromise();

        if (!fileId || typeof fileId !== "string") {
            return;
        }

        const vaultDetails = {
            vaultId,
            fileId,
            filename: file.name,
        };

        const answerControl = this.answerForms.get(query.subjectId);
        const answerValue = { ...answerControl.value, vaultDetails };

        answerControl.patchValue(answerValue);
    }

    async deleteFile(query: Partial<Query>): Promise<void> {
        const update = {
            ...query,
            vaultDetails: {
                vaultId: null,
                fileId: null,
                filename: null,
            },
        };

        this.answerForms.get(query.subjectId).patchValue(update);
    }

    async clearAnswer(query: Partial<Query>): Promise<void> {
        const update = {
            ...query,
            answer: "",
            vaultDetails: {
                vaultId: null,
                fileId: null,
                filename: null,
            },
        };

        this.answerForms.get(query.subjectId).patchValue(update);
    }

    private setForms(transactions: TransactionQuery[]): void {
        for (const { query } of transactions) {
            if (!this.queryForms.get(query.subjectId)) {
                this.queryForms.setControl(query.subjectId, new FormControl(query?.query), { emitEvent: false });
            }

            if (!this.answerForms.get(query.subjectId)) {
                this.answerForms.setControl(query.subjectId, this.buildAnswerControl(query), { emitEvent: false });
            }
        }
    }

    private buildAnswerControl(query: Partial<Query>): FormControl<Partial<Query>> {
        return new FormControl<Partial<Query>>({
            answer: query?.answer,
            vaultDetails: query?.vaultDetails,
        });
    }

    private reduceAnswerChanges(
        prev: Partial<Record<string, Partial<Query>>>,
        curr: Partial<Record<string, Partial<Query>>>,
    ): Record<string, Partial<Query>> {
        return Object.entries(curr).reduce((acc, [id, answer]) => {
            if (this.hasAnswerChange(prev[id], answer)) {
                const queryVal = this.queryForms.controls[id].value;
                acc[id] = { ...answer, query: queryVal };
            }
            return acc;
        }, {});
    }

    private hasAnswerChange(prevQuery: Partial<Query>, query: Partial<Query>): boolean {
        if (this.isSameAnswer(prevQuery, query) && this.isSameVault(prevQuery, query)) {
            return false;
        }

        return true;
    }

    private isSameAnswer(prevQuery: Partial<Query>, query: Partial<Query>): boolean {
        return prevQuery?.answer == query?.answer;
    }

    private isSameVault(prevQuery: Partial<Query>, query: Partial<Query>): boolean {
        const prevFileName = prevQuery?.vaultDetails?.filename || null;
        const nextFileName = query?.vaultDetails?.filename || null;
        return prevFileName == nextFileName;
    }

    private reduceQueryChanges(
        prev: Partial<Record<string, string>>,
        curr: Partial<Record<string, string>>,
    ): Record<string, Partial<Query>> {
        const test = Object.entries(curr).reduce((acc, [id, query]) => {
            if (query !== prev[id]) {
                const answerVal = this.answerForms.controls[id].value;
                acc[id] = { ...answerVal, query };
            }
            return acc;
        }, {});

        return test;
    }
}
