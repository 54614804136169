import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { CheckResult } from "@visoryplatform/threads";
import { TransformSourceResult } from "@visoryplatform/transformers";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { TransformersService } from "../../services/transformers.service";

@Component({
    selector: "transformer-results-modal",
    templateUrl: "./transformer-results-modal.component.html",
    styleUrls: ["./transformer-results-modal.component.scss"],
})
export class TransformerResultsModalComponent implements OnInit {
    loader = new Loader();
    tableData$: Observable<MatTableDataSource<TransformSourceResult>>;
    data: { transformer: CheckResult };

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private transformService: TransformersService,
        private injector: Injector,
    ) {}

    async ngOnInit(): Promise<void> {
        this.data = await this.extensionDisplayService.getData<{ transformer: CheckResult }>(this.injector).toPromise();

        this.tableData$ = this.fetchResults(this.data.transformer.resultTransformId).pipe(
            map((results) => new MatTableDataSource<TransformSourceResult>(this.enrichResults(results))),
        );
    }

    goBack(): void {
        void this.extensionDisplayService.back();
    }

    private fetchResults(transformerResultId: string): Observable<TransformSourceResult[]> {
        return this.loader.wrap(this.transformService.getSourceResults(transformerResultId)).pipe(take(1));
    }

    private enrichResults(
        results: TransformSourceResult[],
    ): (TransformSourceResult & { hideRowChild: boolean; total: number })[] {
        return results
            .map((result) => {
                if (Array.isArray(result.result?.data) && result.result?.data?.length > 0) {
                    return { ...result, total: result.result.data.length, hideRowChild: true };
                } else {
                    return null;
                }
            })
            .filter((result) => !!result);
    }
}
