import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IParticipant, IUpcomingMeeting, Role } from "@visoryplatform/threads";
import { combineLatest, Observable, of, timer } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon } from "src/environments/environment";
import { GA_EVENTS } from "../../../analytics";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { AppUser, AuthService } from "../../../findex-auth";
import { TaskActionService } from "../../../shared/components/actionable-card/task-action.service";
import { PortalService } from "../../../shared/services/portal.service";
import { CardResources } from "../../interfaces/IUiCard";
import { ParticipantCache } from "../../services/participant-cache.service";
import { ThreadCardService } from "../../services/thread-card.service";
import { UiCardService } from "../../services/ui-card.service";
import { UpcomingMeetingService } from "../../services/upcoming-meeting.service";

@Component({
    selector: "upcoming-meeting-detail",
    templateUrl: "./upcoming-meeting-detail.component.html",
    styleUrls: ["./upcoming-meeting-detail.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [style({ opacity: 0 }), animate("100ms", style({ opacity: 1 }))]),
            transition(":leave", [animate("100ms", style({ opacity: 0 }))]),
        ]),
    ],
})
export class UpcomingMeetingDetailComponent implements OnInit {
    @Input() upcomingMeeting: IUpcomingMeeting;
    @Input() role: Role;

    readonly GA_EVENTS = GA_EVENTS;
    readonly theme = this.environment.theme;
    readonly dateFormat = environmentCommon.dateFormats;
    readonly oneMinute = 1000 * 60;

    user$: Observable<AppUser>;
    createdBy$: Observable<IParticipant>;
    avatars$: Observable<any[]>;

    timeUntilMeetingBegins: number;

    meetingIsImminent$: Observable<boolean>;

    constructor(
        private participantCacheService: ParticipantCache,
        private authService: AuthService,
        private router: Router,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        protected taskActionService: TaskActionService,
        private uiCardService: UiCardService,
        private portalService: PortalService,
        private threadCardService: ThreadCardService,
        private upcomingMeetingService: UpcomingMeetingService,
    ) {
        this.avatars$ = of([]);
    }

    ngOnInit(): void {
        const { staff, invitees } = this.upcomingMeeting.attendees;
        const participants = [].concat(staff).concat(invitees);

        this.user$ = this.authService.getUser();
        this.createdBy$ = this.participantCacheService.getParticipant(this.upcomingMeeting.createdBy);
        this.avatars$ = this.user$.pipe(
            filter((user) => !!user),
            switchMap((currentUser) => this.participantCacheService.getMultipleAvatars(participants, currentUser)),
        );
        this.setMeetingIsImminent();
    }

    viewMeeting($event: MouseEvent, joinMeeting: boolean): void {
        if ($event) {
            $event.stopPropagation();
        }

        const { accountId, threadId, cardId } = this.upcomingMeeting;
        this.upcomingMeetingService
            .navigateToMeeting(accountId, threadId, cardId, joinMeeting)
            .pipe(take(1))
            .subscribe();
    }

    getCardResources(threadId: string, cardId: string): Observable<CardResources> {
        const thread$ = this.portalService.getThreadListById(threadId);
        const card$ = this.threadCardService.getCard(threadId, cardId);

        return combineLatest([card$, thread$]).pipe(
            map(([card]) => this.uiCardService.getCardResources(threadId, thread$, card, this.role)),
        );
    }

    async navigateToCard(threadId: string, cardId: string): Promise<void> {
        await this.router.navigate(["/timelines", threadId, "cards", cardId]);
    }

    private setMeetingIsImminent(): void {
        // if meeting start time is +-15 minutes of now, show join button, check every minute
        this.meetingIsImminent$ = timer(0, 1000 * 60).pipe(
            map(() => {
                const now = Date.now();
                const meetingStart = Date.parse(this.upcomingMeeting.nextInstance.start);
                const meetingEnd = Date.parse(this.upcomingMeeting.nextInstance.end);

                const meetingStartWithinFifteenMinutes = this.upcomingMeetingService.isMeetingStartingInFifteenMinutes(
                    meetingStart,
                    now,
                );
                const meetingInProgress = this.upcomingMeetingService.isMeetingInProgress(
                    meetingStart,
                    meetingEnd,
                    now,
                );
                const timeuntilMeetingBegins = this.upcomingMeetingService.timeUntilMeetingStarts(meetingStart, now);

                if (meetingStartWithinFifteenMinutes || meetingInProgress) {
                    this.timeUntilMeetingBegins = timeuntilMeetingBegins;
                    return true;
                } else {
                    return false;
                }
            }),
        );
    }
}
