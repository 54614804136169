import { Injectable, Injector } from "@angular/core";
import { TRANSFORMER_CARD_TYPE } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries, TaskAction } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { CreateTransformerCheckComponent } from "./components/create-transformer-check/create-transformer-check.component";
import { TransformerActionComponent } from "./components/transformer-action/transformer-action.component";
import { TransformerCardComponent } from "./components/transformer-card/transformer-card.component";
import { ITransformerModalData } from "./types/ITransformerModalData";
import { TransformerActions } from "./types/TransformerActions";

async function viewTransformActionFactory(
    cardResources: Partial<CardResources>,
    injector: Injector,
    _options: void,
): Promise<boolean> {
    const extensionDisplayService = injector.get(EXTENSION_DISPLAY_SERVICE);
    const { thread$, card$, state$, role, replies$ } = cardResources;
    const data: ITransformerModalData = {
        state$,
        thread$,
        replies$,
        card$,
        role,
        readonly: false,
        focusReplyInput: false,
    };

    const config = {
        data,
        panelClass: ["centered-modal"],
        disableClose: true,
        width: "761px",
        autoFocus: false,
    };

    return extensionDisplayService.open<boolean>(TransformerActionComponent, config).toPromise();
}

@Injectable()
export class TransformersPlugin implements IPluginFactory {
    readonly id = "TransformsPlugin";
    readonly gaEvents = GA_EVENTS;

    constructor(private libraries: Libraries) {
        this.registerExtensions();
    }

    private registerExtensions(): void {
        const viewTransformerResultAction: TaskAction<boolean> = {
            analyticsEvents: [GA_EVENTS.TRANSFORMER_VIEW],
            cardTypes: [TRANSFORMER_CARD_TYPE],
            action: viewTransformActionFactory,
            buttonLabel: "View",
            statusIcon: "las la-check-square",
        };

        this.libraries.cardViews.register(TRANSFORMER_CARD_TYPE, TransformerCardComponent);
        this.libraries.taskActions.register(TransformerActions.ViewTransformResults, viewTransformerResultAction);
        this.libraries.createCard.register(TRANSFORMER_CARD_TYPE, {
            title: "Delphi check",
            tooltipMessage: "Create a Xero file check",
            analyticsEvent: this.gaEvents.APP_CREATE_PAYMENT_CARD,
            permission: ["CreateTransformerCard"],
            featureFlags: [LaunchDarklyFeatureFlags.EnableTransformersCard],
            icon: "la-check-square",
            disableInternalCreation: true,
            componentRef: CreateTransformerCheckComponent,
            config: {
                panelClass: ["centered-modal"],
                disableClose: false,
            },
        });
    }
}
