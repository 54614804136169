import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginated } from "@visoryplatform/datastore-types";
import { ITimeline, IWorkflowConfiguration } from "@visoryplatform/threads";
import {
    EnvironmentSpecificConfig,
    environmentCommon,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";

@Injectable()
export class UserAssigneeService {
    constructor(
        private httpClient: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    listTimelines(
        userId: string,
        page?: string | number,
        pageSize?: number,
        sortBy?: string,
        onlyAccountIds?: string[],
    ): Observable<IPaginated<ITimeline>> {
        const { base } = this.environment.threadsEndpoints;
        const { users, timelines } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}/${userId}${timelines}`;

        const params = {
            next: page?.toString() || "",
            limit: pageSize?.toString() || "",
            sortBy: sortBy || "",
        };

        const body = {
            onlyAccountIds,
        };

        return this.httpClient.post<IPaginated<ITimeline>>(url, body, { params });
    }

    listConfigurations(
        userId: string,
        page?: string | number,
        pageSize?: number,
        onlyAccountIds?: string[],
    ): Observable<IPaginated<IWorkflowConfiguration>> {
        const { base } = this.environment.threadsEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}/${userId}/configurations`;
        const params = { next: page?.toString() || "", limit: pageSize?.toString() || "" };

        const body = {
            onlyAccountIds,
        };

        return this.httpClient.post<IPaginated<IWorkflowConfiguration>>(url, body, { params });
    }

    replaceUserWorkflows(
        userId: string,
        keepAsParticipant: boolean,
        newUsers: string[],
        excludeThreadIds: string[],
        onlyAccountIds?: string[],
    ): Observable<void> {
        const { base } = this.environment.commonEndpoints;
        const { users, replaceWorkflows } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}/${userId}${replaceWorkflows}`;
        const payload = {
            keepAsParticipant,
            newUsers,
            excludeThreadIds,
            onlyAccountIds,
        };

        return this.httpClient.post<void>(url, payload);
    }

    replaceUserConfigurations(
        userId: string,
        newUsers: string[],
        excludeConfigurationIds: string[],
        onlyAccountIds?: string[],
    ): Observable<void> {
        const { base } = this.environment.commonEndpoints;
        const { users, replaceConfigurations } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}/${userId}${replaceConfigurations}`;
        const payload = {
            newUsers,
            excludeConfigurationIds,
            onlyAccountIds,
        };

        return this.httpClient.post<void>(url, payload);
    }
}
