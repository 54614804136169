import { Injectable } from "@angular/core";
import {
    DUE_DATE_EXTENSION_TYPE,
    DueDateExtensionHelpers,
    IStep,
    IWorkflow,
    IWorkflowDesign,
    MILESTONE_EXTENSION_TYPE,
    MilestoneExtensionHelpers,
    SLA_EXTENSION_TYPE,
    SlaExtensionHelpers,
} from "@visoryplatform/workflow-core";

@Injectable({
    providedIn: "root",
})
export class WorkflowValidationService {
    validateWorkflow(workflowDesign: IWorkflowDesign, defaultWorkflow?: IWorkflow): boolean {
        if (!defaultWorkflow) {
            return true;
        }

        const workflowDesignSteps: IStep[] = Object.values(workflowDesign.steps);
        const defaultWorkflowSteps: IStep[] = Object.values(defaultWorkflow.steps);

        if (workflowDesignSteps?.length !== defaultWorkflowSteps?.length) {
            return false;
        }

        return workflowDesignSteps.every((designStep) => {
            const defaultWorkflowStep = defaultWorkflow.steps[designStep.id];

            if (!defaultWorkflowStep) {
                return false;
            }

            return this.checkExtensionInputsMatchDesign(defaultWorkflowStep, designStep);
        });
    }

    private checkExtensionInputsMatchDesign(defaultWorkflowStep: IStep, designStep: IStep): boolean {
        const defaultWorkflowExtensions = defaultWorkflowStep.extensions;
        const designExtensions = designStep.extensions;

        return designExtensions.every((designExtension) => {
            const defaultWorkflowExtension = defaultWorkflowExtensions.find(
                (defaultExtension) => defaultExtension?.type === designExtension?.type,
            );

            // Due Date Extension
            if (defaultWorkflowExtension?.type === DUE_DATE_EXTENSION_TYPE) {
                const defaultInput = defaultWorkflowExtension?.inputs?.find(DueDateExtensionHelpers.isDueDateInput);
                return defaultInput;
            }

            // SLA Extension
            if (defaultWorkflowExtension?.type === SLA_EXTENSION_TYPE) {
                const defaultExtension = SlaExtensionHelpers.getSlaExtension(defaultWorkflowStep.extensions);
                const defaultInput = SlaExtensionHelpers.getSlaInput(defaultExtension?.inputs);

                const designExtension = SlaExtensionHelpers.getSlaExtension(designStep.extensions);
                const designInput = SlaExtensionHelpers.getSlaInput(designExtension?.inputs);

                return defaultInput?.data?.duration === designInput?.data?.duration;
            }

            // Milestone Extension
            if (defaultWorkflowExtension?.type === MILESTONE_EXTENSION_TYPE) {
                const defaultExtension = MilestoneExtensionHelpers.isStepMilestone(defaultWorkflowStep);
                const designExtension = MilestoneExtensionHelpers.isStepMilestone(designStep);

                return defaultExtension === designExtension;
            }

            return true;
        });
    }
}
