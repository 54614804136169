import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IVaultReport } from "@visoryplatform/threads";
import {
    EnvironmentSpecificConfig,
    environmentCommon,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { IVaultReportGroup } from "../interfaces/IInsightsReportGroup";

@Injectable({ providedIn: "root" })
export class InsightsService {
    readonly BASE_URL = this.environment.sigmaEndpoints.base;
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    getInsightsReports(userId?: string): Observable<IVaultReportGroup[]> {
        const url = `${this.BASE_URL}${environmentCommon.sigmaEndpoints.insightsReports}/${userId ? userId : "me"}`;
        return this.http.get<IVaultReport[]>(url).pipe(map((reports) => this.mapReportGroups(reports)));
    }

    mapReportGroups(reports: IVaultReport[]): IVaultReportGroup[] {
        const reportYears = reports.map((report) => new Date(report.file.timestamp).getFullYear());
        const distinctYears = [...new Set(reportYears)];
        return distinctYears
            .map((reportYear) => ({
                calendarYear: reportYear,
                reports: this.getReportsInYear(reports, reportYear),
            }))
            .sort((a, b) => b.calendarYear - a.calendarYear);
    }

    private getReportsInYear(reports: IVaultReport[], calendarYear: number) {
        return reports
            .filter((report) => new Date(report.file.timestamp).getFullYear() === calendarYear)
            .sort(
                (reportA, reportB) =>
                    new Date(reportB.file.timestamp).getTime() - new Date(reportA.file.timestamp).getTime(),
            );
    }
}
