import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { EntityRank } from "@visoryplatform/openmeasures-core";
import { ChartDataset, ScatterDataPoint } from "chart.js";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ColorService } from "../../../../portal-modules/src/lib/shared/services/color.service";
import { OpenMeasuresService } from "../../services/open-measures.service";

export enum ScatterOrder {
    Name = "Entity Name",
    Ascending = "Ascending",
    Descending = "Descending",
}

@Component({
    selector: "scatter-report",
    templateUrl: "./scatter-report.component.html",
})
export class ScatterReportComponent implements OnChanges {
    @Input() enterpriseId: string;
    @Input() entityId: string;
    @Input() periodId: string;
    @Input() metricId: string;
    @Input() metricName: string;
    @Input() groupByIndex: number;
    @Input() sortOrder: ScatterOrder;

    entityRanks$: Observable<EntityRank[]>;
    chartData$: Observable<ChartDataset<"scatter">[]>;
    chartLabels: string[];
    unit: string;
    loader = new Loader();

    private colorService = new ColorService();
    private sortOrder$ = new ReplaySubject<ScatterOrder>(1);

    constructor(private openMeasures: OpenMeasuresService) {
        this.sortOrder$.next(this.sortOrder);
    }

    ngOnChanges(changes: SimpleChanges) {
        const { enterpriseId, entityId, periodId, metricId, sortOrder } = changes;

        if (enterpriseId || entityId || periodId || metricId) {
            if (!this.enterpriseId || !this.entityId || !this.periodId || !this.metricId) {
                return;
            }

            if (enterpriseId?.currentValue) {
                this.colorService = new ColorService();
            }

            const entityRanks = this.openMeasures.getRanks(this.enterpriseId, this.periodId, this.metricId);
            this.entityRanks$ = this.loader.wrap(entityRanks).pipe(shareReplay(1));
        }

        if (sortOrder && sortOrder.currentValue) {
            this.sortOrder$.next(this.sortOrder);
        }

        if (this.entityRanks$) {
            this.chartData$ = combineLatest([this.entityRanks$, this.sortOrder$]).pipe(
                map(([entityRanks, sortOrder]) => this.groupEntityRanks(entityRanks, sortOrder)),
            );
        }
    }

    private groupEntityRanks(entityRanks: EntityRank[], sortOrder: ScatterOrder): ChartDataset<"scatter">[] {
        this.chartLabels = [];

        const ranksWithValue = entityRanks.filter((rank) => rank.result.metricValue);
        const sortedEntityRanks = ranksWithValue.sort((a, b) => this.sortRank(sortOrder, a, b));
        const otherDatasets = this.getOtherDatasets(sortedEntityRanks);
        const selectedEntityIndex = sortedEntityRanks.findIndex((entityRank) => entityRank?.entityId === this.entityId);
        const selectedEntityRank = sortedEntityRanks[selectedEntityIndex];
        const selectedEntityDataset = this.getEntityDataset(selectedEntityRank, selectedEntityIndex);

        return [...otherDatasets, selectedEntityDataset];
    }

    private getEntityDataset(entityRank: EntityRank, position: number): ChartDataset<"scatter"> {
        const lastIndex = entityRank.classifications.length - 1;
        const entityClassification = entityRank.classifications[lastIndex];
        const dataset = this.createDataset(entityClassification);
        const value = entityRank.result.metricValue
            ? Number(entityRank.result.metricValue.toFixed(2))
            : entityRank.result.metricValue;

        const scatterPoint: ScatterDataPoint = {
            y: value,
            x: position,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label: entityRank.label,
        };
        dataset.data.push(scatterPoint);

        dataset.pointRadius[0] = 16;
        dataset.pointBorderWidth[0] = 1;
        dataset.pointBorderColor[0] = this.colorService.defaultColors.selectedSolid;
        dataset.animations = {
            borderWidth: {
                duration: 1000,
                from: 1,
                to: 9,
                loop: true,
            },
        };

        return dataset;
    }

    private getDataset(datasets: ChartDataset<"scatter">[], entityRank: any): ChartDataset<"scatter"> {
        const index = this.groupByIndex ?? entityRank.classifications.length - 1;
        const classification = entityRank.classifications[index];
        const found = datasets.find((dataset) => dataset.label === classification);

        if (found) {
            return found;
        }

        const created = this.createDataset(classification);
        datasets.push(created);
        return created;
    }

    private createDataset(label: string): ChartDataset<"scatter"> {
        const color = this.colorService.getColor(label);
        const defaultBackgroundColor = this.colorService.defaultColors.background;

        const created: ChartDataset<"scatter"> = {
            label: label,
            data: [],
            backgroundColor: color ?? defaultBackgroundColor,
            pointBorderColor: [],
            pointBorderWidth: [],
            pointRadius: [],
        };

        return created;
    }

    private sortRank(sortOrder: ScatterOrder, a: EntityRank, b: EntityRank): number {
        switch (sortOrder) {
            default:
            case ScatterOrder.Ascending:
                return a.result.metricValue - b.result.metricValue;
            case ScatterOrder.Descending:
                return b.result.metricValue - a.result.metricValue;
            case ScatterOrder.Name:
                return ("" + a.label).localeCompare(b.label);
        }
    }

    private getOtherDatasets(sortedEntityRanks: EntityRank[]): ChartDataset<"scatter">[] {
        return sortedEntityRanks.reduce<ChartDataset<"scatter">[]>((datasets, rank, i) => {
            if (rank && rank.entityId === this.entityId) {
                return datasets;
            }

            const dataset = this.getDataset(datasets, rank);

            const value = rank.result.metricValue
                ? Number(rank.result.metricValue.toFixed(2))
                : rank.result.metricValue;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dataset.data.push({ y: value, x: i, label: rank.label });
            const index = dataset.data.length - 1;

            this.chartLabels.push(rank.label);
            this.unit = rank.result.unit;
            dataset.pointRadius[index] = 8;
            dataset.pointBorderWidth[index] = 1;
            dataset.pointBorderColor[index] = dataset.backgroundColor;

            return datasets;
        }, []);
    }
}
