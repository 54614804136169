import { VideoCallAction, VideoChatAction } from "@visoryplatform/threads";
import { filter, map, switchMap } from "rxjs/operators";
import { vcEndSessionTaskAction, vcJoinCallTaskAction } from "./video-chat-task-action";

import { Injectable } from "@angular/core";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable } from "rxjs";
import { RecordingListComponent } from "./components/recording-list/recording-list.component";
import { VcCardComponent } from "./components/vc-card/vc-card.component";
import { VideoCallCardComponent } from "./components/video-call-card/video-call-card.component";
import { videoCallEndSessionTaskAction } from "./video-call-task-action";

const LIST_RECORDINGS = "ListRecordings";
@Injectable()
export class VideoChatPlugin implements IPluginFactory {
    id = "VideoChatPlugin";

    constructor(
        libraries: Libraries,
        private authService: AuthService,
        private permissionService: PermissionService,
    ) {
        libraries.cardViews.register("video-chat", VcCardComponent);
        libraries.cardViews.register("video-call", VideoCallCardComponent);

        libraries.threadViews.register("video-chat", {
            label: "Recordings",
            icon: null,
            showExtension: () => this.getShowView(),
            route: {
                path: "recordings",
                component: RecordingListComponent,
            },
        });

        libraries.taskActions.register(VideoChatAction.JOIN_CALL, vcJoinCallTaskAction);
        libraries.taskActions.register(VideoChatAction.END_SESSION, vcEndSessionTaskAction);

        libraries.taskActions.register(VideoCallAction.END_SESSION, videoCallEndSessionTaskAction);
    }

    private getShowView(): Observable<boolean> {
        return this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
            switchMap((role) => this.permissionService.checkPermissions(role, LIST_RECORDINGS)),
        );
    }
}
