import { ThreadStatus } from "@visoryplatform/threads";

export const ALL_OPTION = {
    key: "all",
    value: "All",
};

export const ALL_STATUSES_LABEL = "All statuses";

export const ACTIVE_STATUS_OPTION = {
    key: ThreadStatus.active,
    value: "Active",
    group: "Workflow status",
};
