import { Inject, Injectable } from "@angular/core";
import { ActorId } from "@visoryplatform/threads";
import { Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";
import { ENVIRONMENT } from "../../../../../../src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../feature-flags";

const delphiAiLogo = "./assets/images/visory/delphi-ai-alt.svg";
const visoryLogo = "./assets/images/visory/visory-avatar.svg";

@Injectable({
    providedIn: "root",
})
export class ProfilePictureService {
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private featureFlagService: FeatureFlagService,
    ) {}

    getUrl(userId: string): string {
        const { cachedBaseImages } = this.environment.publicEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        return `${cachedBaseImages}${users}/${userId}/photo`;
    }

    getUserProfilePicture(userId: string): Observable<string> {
        if (!this.featureFlagService.isInitialised()) {
            return of(this.getUrl(userId));
        }

        return this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding).pipe(
            take(1),
            map((flag) => (userId === ActorId.System ? this.getSystemAsset(flag) : this.getUrl(userId))),
        );
    }

    private getSystemAsset(aiBrandingFlag: boolean): string {
        if (aiBrandingFlag) {
            return delphiAiLogo;
        } else {
            return visoryLogo;
        }
    }
}
