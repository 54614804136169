import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { customValidators, getPasswordErrorMessage } from "projects/portal-modules/src/lib/shared/validators";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { AuthService } from "../../services/auth.service";

@Component({
    selector: "change-password-form",
    templateUrl: "./new-password-form.component.html",
    styleUrls: ["./new-password-form.component.scss"],
})
export class NewPasswordFormComponent implements OnInit, OnDestroy {
    readonly theme = this.environment.theme;
    readonly textResource = this.environment.featureFlags.text;

    form = new UntypedFormGroup(
        {
            newPassword: new UntypedFormControl(null, [Validators.required, customValidators.passwordValidator]),
            confirmPassword: new UntypedFormControl(null, [Validators.required, customValidators.passwordValidator]),
        },
        customValidators.passwordsMatchValidator("newPassword", "confirmPassword"),
    );

    showLoader: boolean;
    errorMessage = "";

    @Input() userDetails: any;
    @Output() passwordUpdated: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    async ngOnInit() {
        this.form.setValue({
            newPassword: "",
            confirmPassword: "",
        });
    }

    ngOnDestroy(): void {}

    async updatePassword() {
        const newPassword = this.form.controls.newPassword.value;

        this.showLoader = true;
        this.authService.completeNewPassword(this.userDetails, newPassword).subscribe(
            () => {
                this.showLoader = false;
                this.passwordUpdated.emit();
            },
            (error) => {
                this.showLoader = false;
                this.errorMessage = error.message || "An unknown error occurred.";
            },
        );
    }

    getPasswordError(controlName: string, passwordMatchError: boolean): string {
        return getPasswordErrorMessage(controlName, passwordMatchError === false);
    }
}
