<section class="request-header">
    {{ title }}

    <fx-badge
        [label]="label"
        [type]="type"
        class="request-header-badge"
    >
    </fx-badge>
</section>
<section
    *ngIf="subTitle && accountName"
    class="request-subheader"
>
    <span class="request-subheader-account-name">{{ accountName }}</span>
    <span class="request-subheader-separator">|</span>
    <span class="request-subheader-title"><i class="las la-sitemap"></i> {{ subTitle | titlecase }}</span>
</section>
