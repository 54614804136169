import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { ChartConfiguration, ChartDataset, LegendItem, TooltipItem } from "chart.js";
import { InsightsMetricService } from "../../../../services/insights-metric.service";
import { ChartComponent } from "../chart/chart.component";

@Component({
    selector: "scatter-chart",
    templateUrl: "./scatter-chart.component.html",
})
export class ScatterChartComponent implements OnChanges {
    @ViewChild("chart", { static: true }) chart: ChartComponent;
    @Input() labels: string[];
    @Input() datasets: ChartDataset<"scatter">[];
    @Input() yLabel?: string;
    @Input() unit?: string;

    config: ChartConfiguration = {
        type: "scatter",
        data: {
            datasets: [],
        },
        options: {
            layout: {
                padding: {
                    left: -25,
                },
            },
            elements: {
                point: {
                    hoverRadius: 15,
                    hitRadius: 12,
                },
            },
            scales: {
                xAxis: {
                    offset: true,
                    display: false,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
                yAxis: {
                    offset: true,
                    ticks: {
                        padding: 10,
                        callback: (value) => InsightsMetricService.formatMetric(value, this.unit),
                    },
                    title: {
                        display: true,
                    },
                    grid: {
                        borderDash: [6, 6],
                        drawBorder: false,
                    },
                },
            },
            plugins: {
                legend: {
                    position: "bottom",
                    align: "start",
                    labels: {
                        usePointStyle: true,
                        padding: 40,
                        generateLabels: (chart: any): LegendItem[] => {
                            const datasetsToShow = chart.data.datasets.slice(0, -1);
                            const datasetMetasToShow = chart._getSortedDatasetMetas().slice(0, -1);
                            const {
                                labels: { usePointStyle, pointStyle, textAlign, color },
                            } = chart.legend.options;

                            return datasetMetasToShow.map((meta) => {
                                const style = meta.controller.getStyle(usePointStyle ? 0 : undefined);
                                return {
                                    text: datasetsToShow[meta.index].label,
                                    fillStyle: style.backgroundColor,
                                    fontColor: color,
                                    hidden: !meta.visible,
                                    lineCap: style.borderCapStyle,
                                    lineDash: style.borderDash,
                                    lineDashOffset: style.borderDashOffset,
                                    lineJoin: style.borderJoinStyle,
                                    lineWidth: 0,
                                    strokeStyle: "",
                                    pointStyle: pointStyle || style.pointStyle,
                                    rotation: style.rotation,
                                    textAlign: textAlign || style.textAlign,
                                    borderRadius: 0,
                                    datasetIndex: meta.index,
                                };
                            }, this);
                        },
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context: TooltipItem<"scatter">): string => {
                            const datasetLabel = (context.raw as any)?.label;
                            const metric = context.parsed.y;
                            const formattedMetric = InsightsMetricService.formatMetric(metric, this.unit);

                            return `  ${datasetLabel}: ${formattedMetric}`;
                        },
                    },
                },
            },
        },
    };

    ngOnChanges(changes: SimpleChanges) {
        const { labels, datasets } = changes;

        if (labels) {
            this.config.data.labels = labels.currentValue;
        }

        if (datasets) {
            this.config.data.datasets = datasets.currentValue ?? [];
        }

        if (this.chart) {
            this.chart.update();
        }
    }
}
