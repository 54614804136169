import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginated } from "@visoryplatform/datastore-types";
import { AccountChatListing, ChatMessage } from "@visoryplatform/threads";
import { EMPTY, Observable } from "rxjs";
import { expand, map, toArray } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    EnvironmentSpecificConfig,
    environmentCommon,
} from "../../../portal-modules/src/lib/environment/environment.common";

@Injectable()
export class AccountAssistantService {
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private http: HttpClient,
    ) {}

    listChats(accountId: string): Observable<AccountChatListing[]> {
        const { assistant, chats } = environmentCommon.assistantEndpoints;
        const { accounts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${assistant}${chats}`;

        return this.http.get<AccountChatListing[]>(url);
    }

    getSystemPrompt(accountId: string): Observable<string> {
        const { assistant, systemPrompt } = environmentCommon.assistantEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const { accounts } = environmentCommon.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${assistant}${systemPrompt}`;

        return this.http.get<string>(url);
    }

    createChat(accountId: string, message: string): Observable<string> {
        const { assistant, chats } = environmentCommon.assistantEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const { accounts } = environmentCommon.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${assistant}${chats}`;

        return this.http.post<string>(url, { message });
    }

    addMessage(accountId: string, conversationId: string, message: string): Observable<ChatMessage> {
        const { assistant, chats, messages } = environmentCommon.assistantEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const { accounts } = environmentCommon.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${assistant}${chats}/${conversationId}${messages}`;

        return this.http.post<ChatMessage>(url, { message });
    }

    listMessages(accountId: string, conversationId: string, next?: string): Observable<IPaginated<ChatMessage>> {
        const { assistant, chats, messages } = environmentCommon.assistantEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const { accounts } = environmentCommon.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${assistant}${chats}/${conversationId}${messages}`;
        const query = next ? `?next=${next}` : "";

        return this.http.get<IPaginated<any>>(url + query);
    }

    //TODO: implement proper pagination
    listAllMessages(accountId: string, conversationId: string): Observable<ChatMessage[]> {
        return this.listMessages(accountId, conversationId).pipe(
            expand((paginated) => {
                if (paginated.next) {
                    return this.listMessages(accountId, conversationId, paginated.next);
                } else {
                    return EMPTY;
                }
            }),
            map((result) => result.result),
            toArray(),
            map((results) => results.flat()),
        );
    }

    runChat(accountId: string, conversationId: string): Observable<void> {
        const { assistant, chats, run } = environmentCommon.assistantEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const { accounts } = environmentCommon.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${assistant}${chats}/${conversationId}${run}`;

        return this.http.post<void>(url, {});
    }

    checkRunStatus(accountId: string, conversationId: string): Observable<void> {
        const { assistant, chats, run } = environmentCommon.assistantEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const { accounts } = environmentCommon.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${assistant}${chats}/${conversationId}${run}`;

        return this.http.get<void>(url);
    }

    retryRun(accountId: string, conversationId: string): Observable<void> {
        const { assistant, chats, retry } = environmentCommon.assistantEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const { accounts } = environmentCommon.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${assistant}${chats}/${conversationId}${retry}`;

        return this.http.post<void>(url, {});
    }
}
