import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Account, AccountMetadata } from "@visoryplatform/threads";
import { merge, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { Loader } from "../../shared/services/loader";
import { AccountsService } from "../../threads-ui/services/accounts.service";

@Injectable()
export class AccountRouteService {
    private accountUpdates = new Subject<Account>();
    private readonly account$: Observable<Account>;

    constructor(
        route: ActivatedRoute,
        private accountsService: AccountsService,
        private accountLoader: Loader,
    ) {
        const getAccount$ = route.paramMap.pipe(
            map((params) => params.get("accountId")),
            filter((accountId) => !!accountId),
            distinctUntilChanged(),
            switchMap((accountId) => accountLoader.wrap(accountsService.getAccount(accountId))),
        );

        this.account$ = merge(getAccount$, this.accountUpdates).pipe(shareReplay(1));
    }

    updateMetadata(accountId: string, accountMetadata: AccountMetadata): Observable<Account> {
        const update$ = this.accountsService.updateAccountMetadata(accountId, accountMetadata).pipe(
            switchMap(() => this.accountsService.getAccount(accountId)),
            tap((account: Account) => this.accountUpdates.next(account)), // ideally this would come from a websocket
        );

        return this.accountLoader.wrap(update$);
    }

    getAccount(): Observable<Account> {
        return this.account$;
    }
}
