import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../portal-modules/src/lib/feature-flags";
import { AuthService, authGuard } from "../../portal-modules/src/lib/findex-auth";
import { IPluginFactory, Libraries, RouteExtension } from "../../portal-modules/src/lib/plugins";
import { PermissionService } from "../../portal-modules/src/lib/threads-ui/services/permissions.service";
import { BulkReplaceUserComponent } from "./components/bulk-replace-user/bulk-replace-user.component";
import { ListUserConfigurationsComponent } from "./components/list-user-configurations/list-user-configurations.component";
import { ListUserTimelinesComponent } from "./components/list-user-timelines/list-user-timelines.component";

@Injectable()
export class UserTimelinesPlugin implements IPluginFactory {
    readonly id = "UserTimelinesPlugin";

    constructor(
        libraries: Libraries,
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
    ) {
        const listUserThreads: RouteExtension = {
            label: "Workflows",
            icon: "la-chart-bar",
            showExtension: () => this.canViewWorkflows(),
            route: {
                path: "workflows",
                canActivate: [authGuard],
                component: ListUserTimelinesComponent,
            },
        };

        const workflowConfigurations: RouteExtension = {
            label: "Configurations",
            icon: "la-chart-bar",
            showExtension: () => this.canViewWorkflows(),
            route: {
                path: "configurations",
                canActivate: [authGuard],
                component: ListUserConfigurationsComponent,
            },
        };

        const bulkReplaceUser: RouteExtension = {
            label: "Bulk change",
            icon: "la-chart-bar",
            showExtension: () => this.canViewWorkflows(),
            route: {
                path: "replace",
                canActivate: [authGuard],
                component: BulkReplaceUserComponent,
            },
        };

        libraries.profileRoutes.register("timelines", listUserThreads);
        libraries.profileRoutes.register("configs", workflowConfigurations);
        libraries.profileRoutes.register("replace", bulkReplaceUser);
    }

    canViewWorkflows(): Observable<boolean> {
        const hasPermission$ = this.authService
            .getValidUser()
            .pipe(switchMap((user) => this.permissionService.checkPermissions(user.globalRole, "ListAllUserThreads")));

        return this.featureFlagService
            .getFlag(LaunchDarklyFeatureFlags.EnableBulkChangeAssignees)
            .pipe(switchMap((flag) => (flag ? hasPermission$ : of(false))));
    }
}
