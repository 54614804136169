@if (dueDateStatus$ | async; as dueDateStatus) {
    <div class="workflow-status-indicator">
        <v-indicator
            [color]="dueDateStatus.color"
            [tuiHint]="!showTooltipIcon ? dueDateStatus.tooltip : null"
            [size]="size"
        >
            {{ dueDateStatus.status | uppercase }}
        </v-indicator>

        @if (showTooltipIcon) {
            <i
                class="las la-exclamation-circle"
                [tuiHint]="dueDateStatus.tooltip"
            >
            </i>
        }
    </div>
}
