import { Component, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CardReply, IThreadCard, ITimeline, IVaultRequestCardState, Role } from "@visoryplatform/threads";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { ExtensionDisplayRef } from "projects/portal-modules/src/lib/shared/services/extension-display-ref";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { forkJoin, Observable } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { VaultRequestService } from "../../../services/vault-request.service";
import { RequestActionButtonLabel, RequestStatuses, VaultCardType } from "../constants/request.constants";
import { IRequestAnalyticsTags } from "../interfaces/IRequestAnalyticsTags";
import { IRequestForm } from "../interfaces/IRequestForms";
import { IRequestModalData } from "../interfaces/IRequestModalData";
import { IUpdatedRequestState } from "../interfaces/IUpdatedRequestState";
import { RequestReplyComponent } from "../request-reply/request-reply.component";

@Component({
    selector: "edit-payrun-request",
    templateUrl: "./edit-payrun-request.component.html",
    styleUrls: ["./edit-payrun-request.component.scss"],
})
export class EditPayrunRequestComponent extends SubscriberBaseComponent implements OnInit {
    @ViewChild("requestReplyComponent") requestReplyComponent: RequestReplyComponent;

    readonly buttonLabel = RequestActionButtonLabel;
    readonly requestStatuses = RequestStatuses;

    actionedPercentage = 0;
    analyticsTags: IRequestAnalyticsTags;
    card$: Observable<IThreadCard>;
    form: FormGroup<IRequestForm>;
    loader = new Loader();
    readonly: boolean;
    state$: Observable<IVaultRequestCardState>;
    thread$: Observable<ITimeline>;
    updatedRequestState: IUpdatedRequestState;
    currentUserRole: Role;
    modalData: IRequestModalData;

    replies$: Observable<CardReply[]>;
    userId$: Observable<string>;

    private extensionDisplayRef: ExtensionDisplayRef;

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private unsavedDialogService: UnsavedModalDialogService,
        private vaultRequestService: VaultRequestService,
        private injector: Injector,
        private authService: AuthService,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.extensionDisplayRef = await this.extensionDisplayService.getRef(this.injector).toPromise();
        this.modalData = await this.extensionDisplayService.getData<IRequestModalData>(this.injector).toPromise();

        this.initForm();
        this.setLocalVariables(this.modalData);
        this.updateCardDescription(this.card$);

        this.updatedRequestState = {
            attachmentsAdded: [],
            attachmentsDeleted: [],
            requestItemsRemoved: [],
        };
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultRequest);
    }

    updateRequestState(card: IThreadCard, thread: ITimeline, state: IVaultRequestCardState): void {
        const saved$ = this.vaultRequestService.sendRequestCardEvent(thread.id, card.id, card.type, {
            body: { saved: true },
        });
        const updateRequestState$ = this.vaultRequestService.updateRequestState(
            card,
            thread,
            this.form,
            state,
            this.updatedRequestState,
        );

        this.loader
            .wrap(forkJoin([saved$, updateRequestState$]))
            .pipe(take(1))
            .subscribe(() => {
                this.loader.hide();
                this.extensionDisplayRef.close();
            });
    }

    async close(): Promise<void> {
        if (!this.form.dirty) {
            this.extensionDisplayRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("payrun-edit");
            if (confirmClose) {
                this.extensionDisplayRef.close();
            }
        }
    }

    private initForm(): void {
        this.form = new FormGroup<IRequestForm>({
            title: new FormControl(""),
            cardDescription: new FormControl("", Validators.required),
        });
    }

    private setLocalVariables(modalData: IRequestModalData): void {
        this.readonly = modalData.readonly;
        this.thread$ = modalData.thread$;
        this.state$ = modalData.state$;
        this.replies$ = modalData.replies$;
        this.userId$ = this.authService.getUserId();
        this.currentUserRole = this.modalData.role;
        this.state$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
            this.updateRequestProgress(state);
        });
        this.card$ = modalData.card$;
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultPayrollApprovalRequest);
    }

    private updateRequestProgress(state: IVaultRequestCardState): void {
        const progress = VaultRequestService.calculateProgress(state.requestItems);
        this.actionedPercentage = progress.actionedPercentage;
    }

    private updateCardDescription(card$: Observable<IThreadCard>): void {
        card$.pipe(take(1)).subscribe((card) => {
            this.form.patchValue({
                cardDescription: card.description,
            });
        });
    }
}
