import { Inject, Injectable, OnDestroy, signal } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { FilterOption, ITimelineFilters, TimelineFilterValue } from "../../timeline/interfaces/timeline-filters";
import { WindowListenersService } from "./window-listeners.service";

@Injectable()
export class TableMobileViewControlsService implements OnDestroy {
    filterReset$: Observable<void>;

    showFilter = signal(true);
    showSort = signal(false);
    sortApplied = signal(false);
    isMobileView = signal(false);
    sortEnabled = signal(true);

    private filterResetSubject = new Subject<void>();
    private windowResizeSubscription: Subscription;

    constructor(
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.filterReset$ = this.filterResetSubject.asObservable();

        this.isMobileView.set(
            this.windowListenersService.isWindowSmaller(this.environment.featureFlags.windowWidthTabletBreakpoint),
        );

        this.windowResizeSubscription = this.windowListenersService.resize.subscribe(() => {
            const newIsMobileView = this.windowListenersService.isWindowSmaller(
                this.environment.featureFlags.windowWidthTabletBreakpoint,
            );
            if (this.isMobileView() !== newIsMobileView) {
                this.isMobileView.set(newIsMobileView);
                this.handleMobileViewChange();
            }
        });

        this.handleMobileViewChange();
    }

    ngOnDestroy(): void {
        this.windowResizeSubscription.unsubscribe();
    }

    toggleFilter(): void {
        const newValue = !this.showFilter();
        this.showFilter.set(newValue);
        this.showSort.set(false);
    }

    resetFilters(): void {
        this.filterResetSubject.next();
        this.sortApplied.set(false);
    }

    toggleSort(): void {
        const newValue = !this.showSort();
        this.showSort.set(newValue);
        this.showFilter.set(false);
    }

    setSortApplied(value: boolean): void {
        this.sortApplied.set(value);
    }

    closeActions(): void {
        this.showFilter.set(false);
        this.showSort.set(false);
    }

    setSortEnabled(value: boolean): void {
        this.sortEnabled.set(value);
    }

    /**
     * Calculates the number of active filters by comparing selected filters against their default values.
     * Only counts FilterOption[] types, ignoring boolean and string filters.
     * @param defaultFilters - The default filter values to compare against
     * @param selectedFilters - The currently selected filter values
     * @returns The count of active filters that differ from their defaults
     */
    getActiveFilterCount(defaultFilters: ITimelineFilters, selectedFilters: ITimelineFilters): number {
        let activeCount = 0;

        const entries = Object.entries(selectedFilters) as Array<[keyof ITimelineFilters, TimelineFilterValue]>;
        for (const [key, selectedFilter] of entries) {
            const defaultFilter = defaultFilters[key];
            activeCount += this.isFilterActive(selectedFilter, defaultFilter) ? 1 : 0;
        }

        return activeCount;
    }

    /**
     * Determines if a filter is active by comparing it with its default value.
     * Only FilterOption[] types are counted as active filters.
     * @param selectedFilter - The currently selected filter value
     * @param defaultFilter - The default filter value to compare against
     * @returns true if the filter is active, false otherwise
     */
    private isFilterActive(selectedFilter: TimelineFilterValue, defaultFilter: TimelineFilterValue): boolean {
        if (Array.isArray(selectedFilter)) {
            return this.isArrayFilterActive(selectedFilter, defaultFilter as FilterOption[]);
        }
        return false;
    }

    /**
     * Checks if an array of FilterOptions is active.
     * An array filter is considered active if it contains any items.
     * @param selectedFilter - Array of selected FilterOptions to check
     * @param defaultFilter - Array of default FilterOptions to compare against
     * @returns true if the array contains non-default filters, false if not
     */
    private isArrayFilterActive(selectedFilter: FilterOption[], defaultFilter: FilterOption[]): boolean {
        const defaultFilterkeys = defaultFilter.map((filter) => filter.key);
        const selectedFilterkeys = selectedFilter.map((filter) => filter.key);
        return selectedFilterkeys.some((key) => !defaultFilterkeys.includes(key));
    }

    private handleMobileViewChange(): void {
        if (this.isMobileView()) {
            this.showFilter.set(false);
            this.showSort.set(false);
        } else {
            this.showFilter.set(true);
            this.showSort.set(false);
        }
    }
}
