import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Period, TableReportRow } from "@visoryplatform/openmeasures-core";
import { InsightsMetricService } from "../../services/insights-metric.service";

@Component({
    selector: "insights-metric-widget",
    templateUrl: "./insights-metric-widget.component.html",
    styleUrls: ["./insights-metric-widget.component.scss"],
})
export class InsightsMetricWidgetComponent implements OnChanges {
    @Input() public periods: Period[];
    @Input() public set tableReportRow(tableReportRow: TableReportRow) {
        this.setMetricsUI(tableReportRow);
        this.setValuesUI(tableReportRow);
        this._tableReportRow = tableReportRow;
    }
    public get tableReportRow(): TableReportRow {
        return this._tableReportRow;
    }

    public amount: number;
    public isMetricBelowTarget: boolean;
    public isMetricBetterThenTarget: boolean;
    public label: string;
    public target: number;
    public unit: string;
    public value: number;
    public period: Period;

    private _tableReportRow: TableReportRow;

    constructor(private insightsMetricService: InsightsMetricService) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { periods, tableReportRow } = changes;

        if (periods || tableReportRow) {
            this.period = this.periods?.find((period) => period.id === this.tableReportRow?.result?.periodId);
        }
    }

    private setMetricsUI(tableReportRow: TableReportRow): void {
        this.isMetricBelowTarget = this.insightsMetricService.isMetricBelow(tableReportRow);
        this.isMetricBetterThenTarget = this.insightsMetricService.isMetricBetterThanTarget(tableReportRow);
    }

    private setValuesUI(tableReportRow: TableReportRow): void {
        this.unit = tableReportRow?.metric.unit;
        this.amount = tableReportRow?.result?.value;
        this.target = tableReportRow?.target?.targetValue;
        this.label = tableReportRow?.metric?.label;
        this.value = ((this.amount - this.target) / this.target) * 100;
    }
}
