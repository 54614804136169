import { ActivatedRouteSnapshot, ActivationEnd, Route, Router, RouterModule, Routes } from "@angular/router";

import { NgModule } from "@angular/core";
import { DashboardComponent } from "projects/default-plugins/dashboard/components/dashboard/dashboard.component";
import { GlobalSearchPageComponent } from "projects/default-plugins/global-search/components/global-search-page/global-search-page.component";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { authGuard } from "projects/portal-modules/src/lib/findex-auth";
import { LoginPageComponent } from "projects/portal-modules/src/lib/findex-auth/components/login-page/login-page.component";
import { ResetPasswordComponent } from "projects/portal-modules/src/lib/findex-auth/components/reset-password/reset-password.component";
import { loggedInGuard } from "projects/portal-modules/src/lib/findex-auth/services/logged-in.guard";
import { noAuthGuard } from "projects/portal-modules/src/lib/findex-auth/services/no-auth.guard";
import { staffGuard } from "projects/portal-modules/src/lib/findex-auth/services/staff.guard";
import { verifiedGuard } from "projects/portal-modules/src/lib/findex-auth/services/verified.guard";
import { ErrorComponent } from "projects/portal-modules/src/lib/shared/components/error/error.component";
import { filter } from "rxjs/operators";
import { CalendarAgendaComponent } from "../../projects/portal-modules/src/lib/threads-ui/components/calendar-agenda/calendar-agenda.component";
import { CalendarPageComponent } from "../../projects/portal-modules/src/lib/threads-ui/components/calendar-page/calendar-page.component";
import { environment } from "../environments/environment";

const registrationEnabledPaths = [
    {
        path: "register",
        loadChildren: () =>
            import("projects/portal-modules/src/lib/onboarding/onboarding.module").then((m) => m.OnboardingModule),
    },
];
const rootPaths = environment.featureFlags.signupEnabled ? registrationEnabledPaths : [];

export const LEGACY_TIMELINE_ROUTE = "/timelines";
export const NEW_TIMELINE_ROUTE = "/workflows";

export const securedRoutes: Route[] = [
    {
        path: "timelines",
        redirectTo: "workflows",
    },
    {
        path: "timelines/:threadId",
        redirectTo: "workflows/:threadId",
    },
    {
        path: "timelines/:threadId/cards/:cardId",
        redirectTo: "workflows/:threadId/cards/:cardId",
    },
    {
        path: "v2/timelines",
        redirectTo: "workflows",
    },
    {
        path: "v2/timelines/:threadId",
        redirectTo: "workflows/:threadId",
    },
    {
        path: "v2/timelines/:threadId/activity",
        redirectTo: "workflows/:threadId",
    },
    {
        path: "v2/timelines/:threadId/activity/cards/:cardId",
        redirectTo: "workflows/:threadId/cards/:cardId",
    },
    {
        path: "search",
        component: GlobalSearchPageComponent,
        canActivate: [authGuard],
    },
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [authGuard],
    },
    {
        path: "calendar",
        component: CalendarPageComponent,
        canActivate: [authGuard],
    },
    {
        path: "calendar-agenda",
        component: CalendarAgendaComponent,
        canActivate: [authGuard],
    },
    {
        path: "admin",
        loadChildren: () => import("projects/portal-modules/src/lib/admin/admin.module").then((m) => m.AdminModule),
        canActivate: [staffGuard],
    },
    {
        path: "accounts",
        loadChildren: () =>
            import("projects/portal-modules/src/lib/account/account.module").then((m) => m.AccountModule),
        canActivate: [authGuard],
    },
    {
        path: "workflows",
        loadChildren: () =>
            import("projects/portal-modules/src/lib/timeline/timeline.module").then((m) => m.TimelineModule),
        canActivate: [authGuard],
    },
    {
        path: "profile",
        loadChildren: () =>
            import("projects/portal-modules/src/lib/user-profile/user-profile.module").then((m) => m.UserProfileModule),
    },
    { path: "reset-password", component: ResetPasswordComponent, canActivate: [noAuthGuard] },
    { path: "error", component: ErrorComponent },
    ...rootPaths,
    { path: "", redirectTo: "/login", pathMatch: "full" },
];

export const routes: Routes = [
    { path: "login", component: LoginPageComponent, canActivate: [loggedInGuard] },
    { path: "staff-login", redirectTo: "login" },
    {
        //Redirect users to the mobile verification screen if they are not mobile verified
        canActivateChild: [verifiedGuard],
        path: "",
        children: securedRoutes,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: "always" })],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(router: Router, analyticsService: AnalyticsService) {
        router.events.pipe(filter((event) => event instanceof ActivationEnd)).subscribe((event: ActivationEnd) => {
            if (!event.snapshot.firstChild) {
                const eventPath = this.getFullRoutePath(event.snapshot);
                const santizedEventPath = eventPath === "" ? "/" : eventPath;
                analyticsService.pageViewed(santizedEventPath);
            }
        });
    }

    private getFullRoutePath(snapshot: ActivatedRouteSnapshot): string {
        if (!snapshot || !snapshot.routeConfig) {
            return "";
        }
        const parentPath = this.getFullRoutePath(snapshot.parent);
        const prefix = parentPath ? `${parentPath}/` : "";
        return `${prefix}${snapshot.routeConfig.path}`;
    }
}
