import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { SharedPipesModule } from "../../../shared/pipes/shared-pipes.module";
import { ThreadsUiPipesModule } from "../../pipes/threads-ui-pipes.module";
import { SelectServiceComponent } from "./components/select-service/select-service.component";

const exportableDeclarations = [SelectServiceComponent];

@NgModule({
    declarations: [...exportableDeclarations],
    exports: [...exportableDeclarations],
    imports: [CommonModule, SharedPipesModule, ThreadsUiPipesModule, PortalUiModule, ReactiveFormsModule],
})
export class SelectServiceModule {}
