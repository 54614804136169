import {
    AssigneeExtensionHelpers,
    BUSINESS_START_HOUR,
    BUSINESS_START_MINUTE,
    DUE_DATE_EXTENSION_TYPE,
    DateAdjustService,
    DueDateAnchor,
    DueDateExtensionHelpers,
    IExtension,
    IStep,
    IWorkflow,
    IWorkflowDesign,
    SlaExtensionHelpers,
    WorkflowDueDateService,
    WorkflowGraphService,
} from "@visoryplatform/workflow-core";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, FormRecord } from "@angular/forms";
import { IThreadListing, ITimeline, IWorkflowConfigurationSteps, Role } from "@visoryplatform/threads";

import { DateTime } from "luxon";
import { EditWorkflowInput } from "../../../edit-workflow-config/components/edit-workflow-config/edit-workflow-config.component";
import { StepDataForm } from "../../types/StepDataForm";
import { Subscription } from "rxjs";
import { WorkflowFormsService } from "../../../edit-workflow-config/services/due-date-forms.service";
import { WorkflowValidationService } from "../../../../services/workflow/workflow-validation.service";
import { dueDateValidator } from "../../../edit-workflow-config/components/edit-workflow-config/due-date.validator";
import { startWith } from "rxjs/operators";

@Component({
    selector: "configure-workflow",
    templateUrl: "./configure-workflow.component.html",
    styleUrls: ["./configure-workflow.component.scss"],
})
export class ConfigureWorkflowComponent implements OnChanges {
    @Input() defaultThread?: IThreadListing | ITimeline;
    @Input() workflowConfigurationSteps?: IWorkflowConfigurationSteps;
    @Input() timezone: string;
    @Input() workflowDesign: IWorkflowDesign;
    @Input() role: Role;

    @Output() dueDates = new EventEmitter<Record<string, DateTime>>();
    @Output() assignees = new EventEmitter<Record<string, string[]>>();
    @Output() isWorkflowValid = new EventEmitter<boolean>();
    @Output() isFormValid = new EventEmitter<boolean>();

    editWorkflowControl = new FormControl<EditWorkflowInput>({
        calculateFromDate: null,
        datesCalculationPoint: DueDateAnchor.StartDate,
    });
    stepConfig = new FormRecord<FormGroup<StepDataForm>>({});

    private dueDateSubscription?: Subscription;

    constructor(
        private dueDateFormsService: WorkflowFormsService,
        private workflowValidationService: WorkflowValidationService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { workflowDesign, workflowConfigurationSteps } = changes;

        if (workflowDesign?.currentValue || workflowConfigurationSteps?.currentValue) {
            this.stepConfig = null;
        }
    }

    resetDates(): void {
        this.resetWorkflowControl();

        if (this.stepConfig?.controls) {
            Object.values(this.stepConfig.controls).forEach((value) => {
                value.controls.dateTime?.reset();
            });
        }
    }

    async onCalculationFromTimeChanged(): Promise<void> {
        const workflowDesign = this.getProvidedWorkflowDesign();
        const steps = WorkflowGraphService.orderWorkflowSteps(workflowDesign);

        const dueDatesFormValues = this.dueDateFormsService.getFormValues(
            this.editWorkflowControl.value.calculateFromDate,
            this.editWorkflowControl.value.datesCalculationPoint,
            workflowDesign,
            !this.workflowConfigurationSteps,
        );

        this.setFormConfigValues(dueDatesFormValues, steps);

        this.dueDateSubscription?.unsubscribe();
        this.dueDateSubscription = this.stepConfig.valueChanges
            .pipe(startWith(this.stepConfig.value))
            .subscribe((value) => {
                const dueDates = Object.entries(value)
                    .filter(([, val]) => !!val.dateTime)
                    .map(([key, value]) => [key, value.dateTime]);

                const assignees = Object.entries(value)
                    .filter(([, val]) => !!val.assignees)
                    .map(([key, value]) => [key, value.assignees]);

                this.dueDates.emit(Object.fromEntries(dueDates));
                this.assignees.emit(Object.fromEntries(assignees));
                this.isFormValid.emit(this.stepConfig.valid);
            });
    }

    setFormConfigValues(values: Record<string, FormGroup<StepDataForm>>, steps: IStep[]): void {
        if (!this.stepConfig) {
            const dueDateSteps = WorkflowDueDateService.filterDueDateSteps(steps);
            this.stepConfig = new FormRecord<FormGroup>(values, dueDateValidator(dueDateSteps));

            Object.values(values).forEach((value) => {
                value.controls.assignees?.markAsTouched();
            });
        } else {
            Object.entries(this.stepConfig.controls).forEach(([key, value]) => {
                value.controls.dateTime?.setValue(values[key]?.controls?.dateTime?.value);
            });
        }
    }

    recalculateFromTemplate(): void {
        this.defaultThread = null;
        this.stepConfig = null;
        this.onCalculationFromTimeChanged();
    }

    private getProvidedWorkflowDesign(): IWorkflowDesign | IWorkflow {
        const validationResult = this.workflowValidationService.validateWorkflow(
            this.workflowDesign,
            this.defaultThread?.workflow,
        );

        this.isWorkflowValid.emit(validationResult);

        if (!this.defaultThread || !validationResult) {
            return this.workflowDesign;
        } else {
            return this.cloneWorkflow(
                this.defaultThread.createdAt,
                this.timezone,
                this.workflowDesign,
                this.defaultThread.workflow,
            );
        }
    }

    private cloneWorkflow(
        threadCreatedAt: string,
        timezone: string,
        cloneTo: IWorkflowDesign,
        cloneFrom: IWorkflowDesign,
    ): IWorkflowDesign {
        const startDate = DateTime.fromISO(threadCreatedAt)
            .setZone(timezone)
            .set({ hour: BUSINESS_START_HOUR, minute: BUSINESS_START_MINUTE });

        const slas = this.getSlasFromDueDates(startDate, cloneFrom);
        const assignees = this.getAssigneesFromWorkflow(cloneFrom);

        const clonedSteps = Object.entries(cloneTo.steps).map(([key, step]) => {
            const clonedStep = this.cloneStep(step, slas, assignees);
            return [key, clonedStep];
        });

        return {
            ...cloneTo,
            steps: Object.fromEntries(clonedSteps),
        };
    }

    private cloneStep(step: IStep, slas: Record<string, number>, assignees: Record<string, string[]>): IStep {
        const dueDateExtension = this.cloneDueDateExtension(step);
        const slaExtension = this.cloneSlaExtension(step, slas);
        const assigneeExtension = this.cloneAssigneeExtension(step, assignees);
        const otherExtensions = step.extensions?.filter(
            (ext) => ext.id !== dueDateExtension?.id && ext.id !== slaExtension?.id && ext.id !== assigneeExtension?.id,
        );
        const extensions = [dueDateExtension, slaExtension, assigneeExtension].filter((extension) => !!extension);

        return {
            ...step,
            extensions: [...otherExtensions, ...extensions],
        };
    }

    private cloneDueDateExtension(step: IStep): IExtension {
        const dueDateExtension = step.extensions?.find((ext) => ext?.type === DUE_DATE_EXTENSION_TYPE);
        if (!dueDateExtension) {
            return null;
        }

        const dueDateInput = dueDateExtension?.inputs?.find(DueDateExtensionHelpers.isDueDateInput);
        const otherDueDateInputs = dueDateExtension?.inputs?.filter((input) => input != dueDateInput);

        return {
            ...dueDateExtension,
            inputs: [
                ...otherDueDateInputs,
                {
                    ...dueDateInput,
                    data: null,
                },
            ],
        };
    }

    private cloneSlaExtension(step: IStep, slas: Record<string, number>): IExtension {
        const slaExtension = SlaExtensionHelpers.getSlaExtension(step.extensions);
        if (!slaExtension || !slas[step.id]) {
            return null;
        }

        const slaInputs = slaExtension?.inputs?.find(SlaExtensionHelpers.isSlaInput);
        const otherSlaInputs = slaExtension?.inputs?.filter((input) => input != slaInputs);

        return {
            ...slaExtension,
            inputs: [
                ...otherSlaInputs,
                {
                    ...slaInputs,
                    data: { duration: slas[step.id] },
                },
            ],
        };
    }

    private cloneAssigneeExtension(step: IStep, assignees: Record<string, string[]>): IExtension {
        const assigneeExtension = AssigneeExtensionHelpers.getAssigneeExtension(step.extensions);
        if (!assigneeExtension || !assignees[step.id]) {
            return null;
        }

        const assigneeInputs = assigneeExtension?.inputs?.find(AssigneeExtensionHelpers.isAssigneeInput);
        const otherAssigneeInputs = assigneeExtension?.inputs?.filter((input) => input != assigneeInputs);

        return {
            ...assigneeExtension,
            inputs: [
                ...otherAssigneeInputs,
                {
                    ...assigneeInputs,
                    data: { ...assigneeInputs.data, assignees: assignees[step.id] },
                },
            ],
        };
    }

    private getSlasFromDueDates(startDate: DateTime, workflow: IWorkflowDesign): Record<string, number> {
        const orderedSteps = WorkflowGraphService.orderWorkflowSteps(workflow);
        const dueDateSteps = WorkflowDueDateService.filterDueDateSteps(orderedSteps);

        const slas = dueDateSteps.reduce(
            (acc, step) => {
                const dueDate = WorkflowDueDateService.getExtensionDueDate(step);
                const stepSla = WorkflowDueDateService.getExtensionSla(step);

                const nextDueDate = dueDate ?? acc.startDate.plus({ milliseconds: stepSla });
                const sla = dueDate ? DateAdjustService.getUnadjustedDuration(acc.startDate, dueDate) : stepSla;

                return {
                    slas: {
                        ...acc.slas,
                        [step.id]: sla,
                    },
                    startDate: nextDueDate,
                };
            },
            { slas: {}, startDate },
        );

        return slas.slas;
    }

    private getAssigneesFromWorkflow(workflow: IWorkflowDesign): Record<string, string[]> {
        const orderedSteps = WorkflowGraphService.orderWorkflowSteps(workflow);
        const assignees = orderedSteps.reduce((acc, step) => {
            const assigneeData = AssigneeExtensionHelpers.getAssigneeData(step.extensions);
            return { ...acc, [step.id]: assigneeData?.assignees };
        }, {});

        return assignees;
    }

    private resetWorkflowControl(): void {
        this.editWorkflowControl.setValue({
            calculateFromDate: null,
            datesCalculationPoint: this.editWorkflowControl.value.datesCalculationPoint,
        });
        this.editWorkflowControl.markAsPristine();
        this.editWorkflowControl.markAsUntouched();
    }
}
