import { Injectable } from "@angular/core";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { featureFlagGuard } from "projects/portal-modules/src/lib/feature-flags/services/feature-flag.guard";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { ThreadOverviewComponent } from "../../portal-modules/src/lib/timeline/components/thread-overview/thread-overview.component";
import { OVERVIEW_PATH } from "../../portal-modules/src/lib/timeline/timeline-routes.module";
@Injectable()
export class ThreadOverviewPlugin implements IPluginFactory {
    readonly id = "ThreadOverviewPlugin";

    constructor(
        libraries: Libraries,
        private featureFlagService: FeatureFlagService,
    ) {
        libraries.threadViews.register(OVERVIEW_PATH, {
            label: "Overview",
            showExtension: () => this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableCentreAppComponent),
            route: {
                path: OVERVIEW_PATH,
                component: ThreadOverviewComponent,
                data: {
                    featureFlag: LaunchDarklyFeatureFlags.EnableCentreAppComponent,
                },
                canActivate: [featureFlagGuard],
            },
        });
    }
}
