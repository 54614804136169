import { Component, Inject, Injector } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
    CheckGroup,
    CreateTransformerCard,
    ITimeline,
    IntegrationTypes,
    TRANSFORMER_CARD_TYPE,
} from "@visoryplatform/threads";
import { RequestStatuses } from "projects/default-plugins/vault/components/request/constants/request.constants";
import { ExtensionDisplayRef } from "projects/portal-modules/src/lib/shared/services/extension-display-ref";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ICreateCardEvent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card.component";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";

@Component({
    selector: "create-transformer-check",
    templateUrl: "./create-transformer-check.component.html",
    styleUrls: ["./create-transformer-check.component.scss"],
})
export class CreateTransformerCheckComponent {
    extensionDisplayRef$: Observable<ExtensionDisplayRef<CreateTransformerCheckComponent>>;
    modalData$: Observable<ICreateCardEvent>;

    RequestStatuses = RequestStatuses;
    checkGroups = [{ label: "Supplier pay run", value: CheckGroup.SupplierPayRun }];
    checkDropdown = new FormControl<(typeof this.checkGroups)[0]>(null);
    loader = new Loader();

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private threadCardService: ThreadCardService,
        private injector: Injector,
    ) {
        this.extensionDisplayRef$ = this.extensionDisplayService.getRef(this.injector);
        this.modalData$ = this.extensionDisplayService.getData<ICreateCardEvent>(this.injector);
    }

    async createCheck(groupId: CheckGroup): Promise<void> {
        const [modalData, dialogRef] = await Promise.all([
            this.modalData$.pipe(take(1)).toPromise(),
            this.extensionDisplayRef$.pipe(take(1)).toPromise(),
        ]);
        this.checkTenantId(modalData.thread);

        const payload: CreateTransformerCard = {
            groupId,
        };

        this.loader
            .wrap(this.threadCardService.createCard(modalData.thread.id, TRANSFORMER_CARD_TYPE, payload))
            .pipe(take(1))
            .subscribe(() => dialogRef.close());
    }

    private checkTenantId(timeline: ITimeline): void {
        const integrations = timeline.account?.metadata?.integrations;
        const xeroIntegration = integrations[IntegrationTypes.Xero];

        if (!xeroIntegration) {
            throw new Error("Xero integration not connected");
        }

        const tenantId = xeroIntegration.businessId;
        if (!tenantId || typeof tenantId !== "string") {
            throw new Error("Xero business not connected");
        }
    }
}
