import { Component, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { IInvitee } from "@visoryplatform/calendar-types";
import { ISlot } from "@visoryplatform/fx-ui/lib/components/calendar/calendar";
import { ICalendarParticipant, IParticipant, IThread, Role } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { AnalyticsService, GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ExtensionDisplayRef } from "projects/portal-modules/src/lib/shared/services/extension-display-ref";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import {
    ButtonType,
    IStepConfiguration,
    MultiComponentLayoutComponent,
    MultiComponentService,
} from "projects/portal-modules/src/lib/ui-layouts";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ENVIRONMENT, EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { environmentCommon } from "../../../../../src/environments/environment";
import { IMeetingAttendee } from "../../interfaces/IMeetingAttendee";
import { IMeetingDetails } from "../../interfaces/IMeetingDetails";
import { IMeetingRequestDetails } from "../../interfaces/IMeetingRequestDetails";
import { IMeetingReviewData } from "../../interfaces/IMeetingReviewData";
import { CalendarCardService } from "../../services/calendar-card.service";
import { CalendarModalService } from "../../services/calendar-modal.service";
import { CalendarService } from "../../services/calendar.service";
import { ICreateInvitationCloseModalData } from "../calendar-meeting-request/close-modal/create-invitation-close-modal.component";

export type MeetingBookModalData = {
    meetingData?: IMeetingRequestDetails;
    thread: IThread;
    disableEmails?: boolean;
};

@Component({
    selector: "calendar-book-meeting",
    templateUrl: "./calendar-book-meeting.component.html",
    styleUrls: ["./calendar-book-meeting.component.scss"],
})
export class CalendarBookMeetingComponent implements OnInit {
    @ViewChild(MultiComponentLayoutComponent)
    multiComponentLayoutComponent: MultiComponentLayoutComponent;

    readonly CALENDAR_MAX_TIME_AHEAD: number = this.environment.featureFlags.calendarBookingLimits.maxTimeAheadMs;
    readonly theme = this.environment.theme;
    readonly defaultTimezoneName = environmentCommon.defaultTimezone;

    loader = new Loader();
    slots: ISlot[];
    minDate: string;
    maxDate: string;
    duration: number;
    meetingReviewData: IMeetingReviewData;
    participants: IParticipant[] = [];
    showValidationErrors = false;
    stepConfigurations: IStepConfiguration[] = [
        {
            stepIndex: 0,
            buttons: [
                {
                    title: "Next",
                    type: ButtonType.Forward,
                    isDisabled: false,
                    isHidden: false,
                },
            ],
        },
        {
            stepIndex: 1,
            buttons: [
                {
                    title: "Meeting Details",
                    type: ButtonType.Backward,
                    isDisabled: false,
                    isHidden: true,
                },
                {
                    title: "Next",
                    type: ButtonType.Forward,
                    isDisabled: true,
                    isHidden: true,
                },
            ],
        },
        {
            stepIndex: 2,
            buttons: [
                {
                    title: "Calendar",
                    type: ButtonType.Backward,
                    isDisabled: false,
                    isHidden: true,
                },
                {
                    title: "Yes, Book Meeting",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: true,
                },
            ],
        },
    ];
    timeZoneName: string;
    meetingRequestData: IMeetingRequestDetails;

    private activeStepIndex = 0;
    private userConfirmedEmptyMeetingDescription = false;
    private staffUserIds: string[];
    private selectedSlot: ISlot;
    private data: MeetingBookModalData;
    private extensionDisplayRef: ExtensionDisplayRef<string>;

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private cardService: CalendarCardService,
        private calendarService: CalendarService,
        private authService: AuthService,
        private multiComponentService: MultiComponentService,
        private analytics: AnalyticsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private injector: Injector,
        private calendarModalService: CalendarModalService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.extensionDisplayRef = await this.extensionDisplayService.getRef<string>(this.injector).toPromise();
        this.data = await this.extensionDisplayService.getData<MeetingBookModalData>(this.injector).toPromise();
        this.participants = this.data.thread.participants;
        this.maxDate = new Date(Date.now() + this.CALENDAR_MAX_TIME_AHEAD).toISOString();
        this.minDate = new Date().toISOString();
        this.meetingRequestData = this.data.meetingData;
    }

    async monthChange(startDate: string | Date): Promise<void> {
        this.loader.show();
        this.slots = [];
        this.timeZoneName = this.getTimeZoneName(startDate);

        const startDateTime = DateTime.fromJSDate(new Date(startDate)).toISO();

        try {
            const calendarAvailabilitySpaces = await this.calendarService
                .checkUserAvailability(
                    this.staffUserIds,
                    startDateTime,
                    this.meetingRequestData.duration,
                    this.data.thread.id,
                )
                .toPromise();
            const user = await this.getCurrentUser().toPromise();
            const userRole = user.globalRole;
            const unfilteredSlots = calendarAvailabilitySpaces?.slots || [];
            this.slots = this.calendarService.filterSlotsByRole(userRole, unfilteredSlots);
        } finally {
            this.loader.hide();
        }
    }

    async handleTransition(activeStepIndex: number): Promise<void> {
        if (!this.calendarModalService.validateMeetingDetailsEventBody(this.participants, this.meetingRequestData)) {
            this.showValidationErrors = true;
            this.stepConfigurations = this.multiComponentService.toggleForwardButtons(
                this.activeStepIndex,
                this.stepConfigurations,
                false,
            );

            this.multiComponentLayoutComponent.selectPreviousStep();
            return;
        }

        const user = await this.loader.wrap(this.getCurrentUser()).toPromise();
        const attendees: IInvitee[] = this.buildAttendees(user, this.meetingRequestData.attendees);
        const previousIndex = this.activeStepIndex;

        this.stepConfigurations = [
            ...this.multiComponentService.showCurrentStepButtons(activeStepIndex, this.stepConfigurations),
        ];
        this.activeStepIndex = activeStepIndex;
        this.setMeetingRequestData(this.meetingRequestData);

        switch (activeStepIndex) {
            case 0:
                this.userConfirmedEmptyMeetingDescription = false;
                return;
            case 1:
                if (!this.userConfirmedEmptyMeetingDescription) {
                    await this.confirmEmptyMeetingDescription();
                }
                if (this.userConfirmedEmptyMeetingDescription || this.meetingRequestData.meetingDescription) {
                    this.userConfirmedEmptyMeetingDescription = true;
                    if (this.staffUserIds && previousIndex < activeStepIndex) {
                        await this.monthChange(this.minDate);
                    }
                }
                return;
            case 2:
                this.meetingReviewData = this.buildMeetingReviewData(user, this.selectedSlot, attendees);
                return;
        }
    }

    handleTimeSelection(event: ISlot): void {
        this.selectedSlot = event;
        this.stepConfigurations = [
            ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true),
        ];
    }

    async create(): Promise<void> {
        this.analytics.recordEvent("calendar", GA_EVENTS.CALENDAR_CREATESCHEDULED);

        const request$ = this.cardService.createBooked(
            this.meetingReviewData.date.start,
            this.meetingReviewData.date.end,
            this.data.thread.id,
            this.meetingRequestData.title,
            this.meetingRequestData.duration,
            this.meetingRequestData.attendees,
            this.meetingRequestData.recurrenceType,
            this.meetingRequestData.numberOfOccurrences,
            this.meetingRequestData.meetingDescription,
            this.data.disableEmails,
        );
        const card = await this.loader.wrap(request$).toPromise();
        this.extensionDisplayRef.close(card?.id);
    }

    handleMeetingDetailsUpdated(event: IMeetingRequestDetails): void {
        this.meetingRequestData = event;
        this.showValidationErrors = false;

        if (this.calendarModalService.validateMeetingDetailsEventBody(this.participants, event)) {
            this.stepConfigurations = [
                ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true),
            ];
        }
    }

    async close(): Promise<void> {
        const data: ICreateInvitationCloseModalData = {
            acceptButtonName: "Discard",
            rejectButtonName: "Keep Editing",
            confirmDescription: "Are you sure you want to discard this meeting?",
            confirmTitle: "Discard meeting request",
        };

        const confirmClose = await this.calendarModalService.confirmClose(data);

        if (confirmClose) {
            this.extensionDisplayRef.close();
        }
    }

    private getCurrentUser(): Observable<AppUser> {
        return this.authService.getUser().pipe(take(1));
    }

    private buildAttendees(user: AppUser, calendarParticipants: ICalendarParticipant[]): IMeetingAttendee[] {
        return calendarParticipants.map((attendee) => ({
            id: attendee.id,
            name: attendee.profile.name,
            email: attendee.profile.emailAddress,
            invitedBy: user.id,
            required: attendee.required,
        }));
    }

    private buildMeetingReviewData(user: AppUser, event: ISlot, attendees: IInvitee[]): IMeetingDetails {
        return {
            title: this.meetingRequestData.title,
            date: event,
            organiser: { name: user.name, id: user.id },
            message: this.meetingRequestData.meetingDescription,
            attendees,
            recurrence: {
                type: this.meetingRequestData.recurrenceType,
                numberOfOccurrences: this.meetingRequestData.numberOfOccurrences,
            },
        };
    }

    private async confirmEmptyMeetingDescription(): Promise<void> {
        if (!this.meetingRequestData.meetingDescription) {
            const data: ICreateInvitationCloseModalData = {
                acceptButtonName: "Yes",
                rejectButtonName: "No",
                confirmDescription: "Are you sure you want to continue this meeting request without a description?",
                confirmTitle: "Missing description",
            };
            const shouldContinue = await this.calendarModalService.confirmClose(data);
            if (!shouldContinue) {
                this.activeStepIndex = 0;
                this.userConfirmedEmptyMeetingDescription = false;
                this.multiComponentLayoutComponent.selectPreviousStep();
                return;
            }
        }
        this.userConfirmedEmptyMeetingDescription = true;
    }

    private setMeetingRequestData(event: IMeetingRequestDetails): void {
        this.duration = event.duration;
        this.staffUserIds = event.attendees
            .filter((attendee) => attendee.role !== Role.Client && attendee.required)
            .map((attendee) => attendee.id);
    }

    private getTimeZoneName(date: string | Date): string {
        const intlTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const luxonDateTimezoneName = DateTime.fromJSDate(new Date(date)).zone?.name || this.defaultTimezoneName;

        return intlTimezoneName || luxonDateTimezoneName || this.defaultTimezoneName;
    }
}
