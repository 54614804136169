import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActorId, CardReply, CardStatus, IThread, IThreadCard, Role } from "@visoryplatform/threads";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ForwardMessageModalComponent } from "projects/portal-modules/src/lib/threads-ui/modules/forward-message/components/forward-message-modal/forward-message-modal.component";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { combineLatest, Observable, Subject } from "rxjs";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";

@Component({
    selector: "message-card",
    templateUrl: "./message-card.component.html",
    styleUrls: ["./message-card.component.scss"],
})
export class MessageCardComponent implements OnInit {
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    card$: Observable<IThreadCard>;
    cardStatuses = CardStatus;
    edit$ = new Subject<boolean>();
    errorMessage: string;
    message: string;
    replies$: Observable<CardReply[]>;
    thread$: Observable<IThread>;
    userId$: Observable<string>;
    userRole$: Observable<Role>;
    canRemoveCard$: Observable<boolean>;
    actorIds = ActorId;
    loader = new Loader();

    private threadId: string;
    private cardId: string;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private resources: CardResources,
        private authService: AuthService,
        private cardService: ThreadCardService,
        private dialog: MatDialog,
        private permissionService: PermissionService,
    ) {}

    ngOnInit(): void {
        const { threadId, cardId, card$, thread$, replies$ } = this.resources;
        const user$ = this.authService.getUser().pipe(filter((user) => !!user));

        this.threadId = threadId;
        this.cardId = cardId;
        this.thread$ = thread$;
        this.card$ = card$.pipe(shareReplay(1));
        this.replies$ = replies$;
        this.userId$ = user$.pipe(
            map((user) => user.id),
            shareReplay(1),
        );
        this.userRole$ = user$.pipe(
            map((user) => user.globalRole),
            shareReplay(1),
        );

        this.canRemoveCard$ = this.userRole$.pipe(
            switchMap((role) => this.permissionService.checkPermissions(role, "RemoveCard")),
            switchMap((permission) => this.canRemoveCard(permission)),
            shareReplay(1),
        );
    }

    editMessage(): void {
        this.edit$.next(true);
    }

    async forwardMessage(): Promise<void> {
        const cardPromise = this.card$.pipe(take(1)).toPromise();
        const threadPromise = await this.thread$.pipe(take(1)).toPromise();
        const [card, thread] = await Promise.all([cardPromise, threadPromise]);
        const config = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            data: {
                thread,
                card,
            },
        };

        this.dialog
            .open(ForwardMessageModalComponent, config)
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe();
    }

    async removeMessage(): Promise<void> {
        this.errorMessage = null;
        try {
            await this.loader.wrap(this.cardService.removeCard(this.threadId, this.cardId)).toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        }
    }

    async save(updatedMessage: string): Promise<void> {
        this.errorMessage = null;
        try {
            await this.loader
                .wrap(
                    this.cardService.updateCardDescription(
                        this.threadId,
                        this.cardId,
                        updatedMessage,
                        CardStatus.Edited,
                    ),
                )
                .toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        }
    }

    private canRemoveCard(removeCardPermission: boolean): Observable<boolean> {
        return combineLatest([this.card$, this.userId$]).pipe(
            map(([card, userId]) => {
                const systemCard = card.createdBy === ActorId.System;
                const cardCreatedByActor = card.createdBy === userId;
                return (cardCreatedByActor || removeCardPermission) && !systemCard;
            }),
        );
    }
}
