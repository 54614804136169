import { Pipe, PipeTransform } from "@angular/core";
import { IParticipant, Role } from "@visoryplatform/threads";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ParticipantCache } from "../services/participant-cache.service";

@Pipe({ name: "threadParticipants" })
export class ThreadParticipantsPipe implements PipeTransform {
    constructor(private participantCache: ParticipantCache) {}

    transform(participants: IParticipant[], role: string) {
        if (!role || !participants) {
            return null;
        }

        if (role === Role.Staff || role === Role.Client) {
            const items = participants.filter((val) => val.role === role);
            return combineLatest(items.map((item) => this.participantCache.getParticipant(item.id))).pipe(
                map((users) => users.map((user) => (user.profile ? user.profile.name : "(Deleted user)")).join(", ")),
            );
        }
        return null;
    }
}
