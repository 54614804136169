import { Component, OnInit } from "@angular/core";
import { OnboardingWizardComponent } from "src/solutions/sigma/components/onboarding-wizard/onboarding-wizard.component";
import { OnboardingService } from "../../../../../projects/portal-modules/src/lib/onboarding/services/onboarding.service";

/**
 * wrapper for timelines during onboarding so we don't have to add any onboarding logic to thread-list-route
 */
@Component({
    selector: "app-onboarding-timeline-container-component",
    templateUrl: "./onboarding-timeline-container.component.html",
    styleUrls: ["./onboarding-timeline-container.component.scss"],
})
export class OnboardingTimelineContainerComponent implements OnInit {
    constructor(private onboardingService: OnboardingService) {}

    ngOnInit() {
        this.onboardingService.initVisoryOnboardingModal(OnboardingWizardComponent);
    }
}
