import { Component, forwardRef, HostListener, Input } from "@angular/core";
import { CheckboxControlValueAccessor, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "threads-checkbox",
    templateUrl: "./threads-checkbox.component.scss",
    styleUrls: ["./threads-checkbox.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxControlValueAccessor),
            multi: true,
        },
    ],
})
export class ThreadsCheckboxComponent implements ControlValueAccessor {
    @Input() checked: boolean;
    @Input() disabled: boolean;

    private onChange: (value: boolean) => void;
    private onTouch: () => void;

    @HostListener("change", ["$event"])
    change(event: any) {
        const target = event.target as HTMLInputElement;
        if (this.onChange) {
            this.checked = target.checked;
            this.onChange(target.checked);
        }
    }

    @HostListener("blur")
    blur() {
        if (this.onTouch) {
            this.onTouch();
        }
    }

    writeValue(val: boolean): void {
        this.checked = val;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
