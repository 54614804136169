import { Injectable } from "@angular/core";
import { IAvatarContent } from "@visoryplatform/fx-ui";
import { ICardRecordings, IParticipant, RecordingStatus } from "@visoryplatform/threads";
import { VideoChatService } from "projects/default-plugins/video-chat/services/video-chat.service";
import { Observable } from "rxjs";
import { ParticipantCache } from "./participant-cache.service";

export interface IRecordingWithContext extends ICardRecordings {
    threadId: string;
    cardId: string;
    cardDescription: string;
    avatars: Observable<IAvatarContent[]>;
}

@Injectable({ providedIn: "root" })
export class ThreadsRecordingService {
    constructor(
        private videoChatService: VideoChatService,
        private participantsCache: ParticipantCache,
    ) {}

    async getDownloadUrl(threadId: string, cardId: string, archiveId: string): Promise<string> {
        return this.videoChatService.getRecordingDownloadUrl(threadId, cardId, archiveId);
    }

    async getDownloadUrlFromCard(threadId: string, cardId: string): Promise<string> {
        const recordings = await this.videoChatService.getRecordings(threadId, cardId);
        const mostRecentRecording = recordings
            .reverse()
            .find((recording) => recording.status === RecordingStatus.Ready);

        if (!recordings.length || !mostRecentRecording) {
            return null;
        }

        const { id } = mostRecentRecording;

        return this.getDownloadUrl(threadId, cardId, id);
    }

    async getThreadRecordings(threadId: string): Promise<IRecordingWithContext[]> {
        const recordingsByThread = await this.videoChatService.getRecordingsByThread(threadId);
        const threadRecordings = recordingsByThread.map((threadRecording) =>
            threadRecording.recordings.map((recording) => ({
                threadId: threadRecording.threadId,
                cardId: threadRecording.cardId,
                cardDescription: threadRecording.description,
                avatars: this.getAttendeeAvatars(recording.participants),
                ...recording,
            })),
        );

        return [].concat(...threadRecordings);
    }

    private getAttendeeAvatars(participants: IParticipant[]): Observable<IAvatarContent[]> {
        return this.participantsCache.getMultipleAvatars(participants);
    }
}
