import { Component, Input, OnInit } from "@angular/core";
import {
    Account,
    ActorId,
    CardReply,
    IParticipant,
    IThread,
    IThreadCard,
    ITimeline,
    Role,
} from "@visoryplatform/threads";
import { Observable, combineLatest } from "rxjs";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import {
    CreateThreadModalComponent,
    CreateWorkflowModalData,
} from "../../modules/create-thread/components/create-thread-modal/create-thread-modal.component";

import { ComponentType } from "@angular/cdk/portal";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "../../../findex-auth";
import { CreateWorkflowModalComponent } from "../../modules/create-thread/components/create-workflow-modal/create-workflow-modal.component";
import { CreateWorkflowModal } from "../../modules/create-thread/types/CreateWorkflowModalType";
import { ForwardMessageModalComponent } from "../../modules/forward-message/components/forward-message-modal/forward-message-modal.component";
import { PermissionService } from "../../services/permissions.service";

const COMMON_MODAL_CONFIG = {
    disableClose: false,
    backdropClass: "modal-backdrop",
    panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
    closeOnNavigation: true,
    maxWidth: "100%",
    maxHeight: "100%",
    minHeight: "100%",
    height: "100vh",
};

@Component({
    selector: "threads-context-menu",
    templateUrl: "./threads-context-menu.component.html",
    styleUrls: ["./threads-context-menu.component.scss"],
})
export class ThreadsContextMenuComponent implements OnInit {
    @Input() thread: ITimeline;
    @Input() card: IThreadCard;
    @Input() reply: CardReply;
    @Input() hideMenu: boolean;

    userRole$: Observable<Role>;
    canForwardCard$: Observable<boolean>;
    canCreateWorkflow$: Observable<boolean>;
    canForwardOrCreateWorkflow$: Observable<boolean>;
    featureEnabled$: Observable<boolean>;

    constructor(
        private dialog: MatDialog,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        const user$ = this.authService.getUser().pipe(filter((user) => !!user));

        this.userRole$ = user$.pipe(
            map((user) => user.globalRole),
            shareReplay(1),
        );

        this.canForwardCard$ = this.userRole$.pipe(
            switchMap((role) => this.canForwardCard(role)),
            shareReplay(1),
        );

        this.canCreateWorkflow$ = this.userRole$.pipe(
            switchMap((role) => this.canCreateWorkflow(role)),
            shareReplay(1),
        );

        this.canForwardOrCreateWorkflow$ = combineLatest([this.canForwardCard$, this.canCreateWorkflow$, user$]).pipe(
            map(([canForward, canCreateWorkflow]) => {
                const systemCard = this.card.createdBy === ActorId.System;
                return (canForward || canCreateWorkflow) && !systemCard;
            }),
        );

        this.featureEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableCreateWorkflowModal);
    }

    async forward(thread: IThread, card: IThreadCard, reply: CardReply): Promise<void> {
        this.dialog
            .open(ForwardMessageModalComponent, {
                ...COMMON_MODAL_CONFIG,
                data: {
                    thread,
                    card,
                    reply,
                },
            })
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe();
    }

    convertToWorkflow(threadId: string, card: IThreadCard, reply: CardReply, featureEnabled: boolean): void {
        this.userRole$
            .pipe(
                take(1),
                switchMap((role) =>
                    this.openCreateModalWithConfig(
                        role,
                        this.thread.account,
                        this.thread.participants,
                        threadId,
                        card,
                        reply,
                        featureEnabled,
                    ),
                ),
            )
            .pipe(filter((result) => !!result))
            .subscribe();
    }

    private openCreateModalWithConfig(
        role: Role,
        account: Account,
        defaultParticipants: IParticipant[],
        threadId: string,
        card: IThreadCard,
        reply: CardReply,
        featureEnabled: boolean,
    ): Observable<unknown> {
        const createThreadConfig: CreateWorkflowModalData = {
            role,
            account,
            defaultParticipants,
            referenceFrom: {
                cardId: card.id,
                threadId,
                replyId: reply?.id,
            },
            referenceCard: card,
            referenceReply: reply,
        };

        const componentToRender: ComponentType<CreateWorkflowModal> = featureEnabled
            ? CreateWorkflowModalComponent
            : CreateThreadModalComponent;

        return this.dialog
            .open(componentToRender, {
                ...COMMON_MODAL_CONFIG,
                data: createThreadConfig,
            })
            .afterClosed();
    }

    private canForwardCard(role: Role): Observable<boolean> {
        const forwardFlag = LaunchDarklyFeatureFlags.EnableForwardingMessageOrReply;
        const canForwardCardFlag$ = this.featureFlagService.getFlag(forwardFlag);
        const permission$ = this.permissionService.checkPermissions(role, "ForwardCard");

        return combineLatest([canForwardCardFlag$, permission$]).pipe(map(([flag, permission]) => flag && permission));
    }

    private canCreateWorkflow(role: Role): Observable<boolean> {
        const forwardFlag = LaunchDarklyFeatureFlags.EnableConvertMessageToWorkflow;
        const canCreateWorkflowFlag$ = this.featureFlagService.getFlag(forwardFlag);
        const permission$ = this.permissionService.checkPermissions(role, ["ConvertMessageToWorkflow", "CreateThread"]);

        return combineLatest([canCreateWorkflowFlag$, permission$]).pipe(
            map(([flag, permission]) => flag && permission),
        );
    }
}
