import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ISlot } from "@visoryplatform/fx-ui/lib/components/calendar/calendar";
import { IStaffProfile } from "@visoryplatform/threads";
import {
    ButtonType,
    IStepConfiguration,
    MultiComponentLayoutComponent,
} from "projects/portal-modules/src/lib/ui-layouts/components/deprecated/multi-component-layout/multi-component-layout.component";
import { MultiComponentService } from "projects/portal-modules/src/lib/ui-layouts/components/deprecated/multi-component-layout/multi-component.service";
import {
    AnalyticsService,
    GA_EVENTS,
    GA_EVENTS_PREFIX,
} from "../../../../../projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { AuthService } from "../../../../../projects/portal-modules/src/lib/findex-auth";
import { SelectionTypes } from "../../../../../projects/portal-modules/src/lib/findex-ui/components/fx-tile/fx-tile.component";
import {
    ISignupUserOnboardingDetails,
    OnboardingService,
    SignUpUserPayload,
} from "../../../../../projects/portal-modules/src/lib/onboarding/services/onboarding.service";
import { Loader } from "../../../../../projects/portal-modules/src/lib/shared/services/loader";
import { ENVIRONMENT } from "../../../../app/injection-token";
import { ICreateAccountModel } from "../onboarding/create-account/create-account.component";
import { OnboardingBookMeetingComponent } from "../onboarding/select-customer-success-manager/onboarding-book-meeting/onboarding-book-meeting.component";
import {
    ISelectCustomerSuccessManagerPayload,
    SelectCustomerSuccessManagerComponent,
} from "../onboarding/select-customer-success-manager/select-customer-success-manager.component";

enum STEPS {
    "GetStarted" = 0,
    "SelectInterestedServices" = 1,
    "SelectCustomerSuccessManager" = 2,
    "BookMeeting" = 3,
    "CreateAccount" = 4,
}

@Component({
    selector: "onboarding-wizard",
    templateUrl: "./onboarding-wizard.component.html",
    styleUrls: ["./onboarding-wizard.component.scss"],
})
export class OnboardingWizardComponent implements OnInit {
    @ViewChild(SelectCustomerSuccessManagerComponent)
    selectCustomerSuccessManagerComponent: SelectCustomerSuccessManagerComponent;

    @ViewChild(OnboardingBookMeetingComponent)
    onboardingBookMeetingComponent: OnboardingBookMeetingComponent;

    @ViewChild(MultiComponentLayoutComponent)
    multiComponentLayoutComponent: MultiComponentLayoutComponent;

    readonly gaEventsPrefix = GA_EVENTS_PREFIX;
    readonly gaEvents = GA_EVENTS;

    loader = new Loader();
    activeStepIndex = 0;
    goalsConfig = this.environment.featureFlags.onboardingConfiguration.selectGoals;
    industryConfig = this.environment.featureFlags.onboardingConfiguration.selectIndustry;
    servicesConfig = this.environment.featureFlags.onboardingConfiguration.selectServices;
    numberOfEmployeesConfig = this.environment.featureFlags.onboardingConfiguration.selectNumberOfEmployees;
    selectionTypes = SelectionTypes;
    signUpUserPayload: SignUpUserPayload;
    onboardingDetails?: ISignupUserOnboardingDetails;
    stepConfigurations: IStepConfiguration[] = [
        {
            name: "GetStarted",
            stepIndex: STEPS.GetStarted,
            buttons: [
                {
                    type: ButtonType.Forward,
                    title: "Get started",
                    analyticsTitle: "next",
                    isHidden: false,
                    isDisabled: false,
                },
            ],
        },
        {
            name: "SelectInterestedServices",
            stepIndex: STEPS.SelectInterestedServices,
            buttons: [
                { type: ButtonType.Backward, title: "Back", analyticsTitle: "back", isHidden: true, isDisabled: false },
                {
                    type: ButtonType.Forward,
                    title: "Next",
                    analyticsTitle: "next",
                    isHidden: true,
                    isDisabled: true,
                },
            ],
        },
        {
            name: "SelectCustomerSuccessManager",

            stepIndex: STEPS.SelectCustomerSuccessManager,
            buttons: [
                { type: ButtonType.Backward, title: "Back", analyticsTitle: "back", isHidden: true, isDisabled: false },
                {
                    type: ButtonType.Forward,
                    title: "Next",
                    analyticsTitle: "bookmeeting",
                    isHidden: true,
                    isDisabled: false,
                },
            ],
        },
        {
            name: "BookMeeting",

            stepIndex: STEPS.BookMeeting,
            buttons: [
                { type: ButtonType.Backward, title: "Back", analyticsTitle: "back", isHidden: true, isDisabled: false },
                {
                    type: ButtonType.Forward,
                    title: "Next",
                    analyticsTitle: "bookmeeting",
                    isHidden: true,
                    isDisabled: true,
                },
            ],
        },
        {
            name: "CreateAccount",
            stepIndex: STEPS.CreateAccount,
            buttons: [
                { type: ButtonType.Backward, title: "Back", analyticsTitle: "back", isHidden: true, isDisabled: false },
                {
                    type: ButtonType.Finish,
                    title: "Create account",
                    analyticsTitle: "createaccount",
                    isHidden: true,
                    isDisabled: true,
                },
            ],
        },
    ];

    createAccountError: string;
    verifyEmailMessage: { error: boolean; message: string };
    emailVerificationSent: boolean;
    enableFooter = true;
    hideNext: boolean;
    activeStaffMember: IStaffProfile;

    constructor(
        private multiComponentService: MultiComponentService,
        private analyticsService: AnalyticsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private onboardingService: OnboardingService,
        private router: Router,
        private route: ActivatedRoute,
        private dialogRef: MatDialogRef<OnboardingWizardComponent>,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params) {
                window.history.replaceState(null, null, `${window.location.pathname}`);
            }
        });
    }

    async handleTransition(activeStepIndex: number): Promise<void> {
        this.stepConfigurations = [
            ...this.multiComponentService.showCurrentStepButtons(activeStepIndex, this.stepConfigurations),
        ];
        this.activeStepIndex = activeStepIndex;

        this.recordRegisterCompletionEvent(this.stepConfigurations[activeStepIndex].name);
        switch (this.activeStepIndex) {
            case STEPS.SelectCustomerSuccessManager:
                await this.selectCustomerSuccessManagerComponent.initialize(this.activeStaffMember);
                break;
            case STEPS.BookMeeting:
                this.onboardingBookMeetingComponent.initialize(this.activeStaffMember);
                break;
            default:
                return;
        }
    }

    handleSelectServices(services: string[]): void {
        if (services?.length) {
            this.onboardingDetails = {
                ...this.onboardingDetails,
                interestedServices: services,
            };
            this.toggleNextStep(true);
        } else {
            this.toggleNextStep(false);
        }
    }

    handleResetStaff(): void {
        this.onboardingDetails = {
            ...this.onboardingDetails,
            start: undefined,
            end: undefined,
        };
        this.toggleNextStep(false);
    }

    handleDateSelected(calendarEvent: ISlot): void {
        if (!calendarEvent) {
            this.toggleNextStep(false);
            return;
        }

        this.onboardingDetails = {
            ...this.onboardingDetails,
            start: calendarEvent.start,
            end: calendarEvent.end,
        };
        this.toggleNextStep(true);
    }

    handleSelectStaff(payload: ISelectCustomerSuccessManagerPayload): void {
        this.activeStaffMember = payload.activeStaffMember;

        const { activeStaffMember } = payload;
        if (activeStaffMember) {
            this.onboardingDetails = {
                ...this.onboardingDetails,
                staffId: payload.activeStaffMember.userId,
            };
            this.toggleNextStep(true);
        } else {
            this.toggleNextStep(false);
        }
    }

    handleValidAccountDetails(event: ICreateAccountModel): void {
        const { businessName, password, familyName, givenName, emailAddress, mobileNumber } = event;
        if (businessName && password && familyName && givenName && emailAddress && mobileNumber) {
            this.signUpUserPayload = {
                ...this.signUpUserPayload,
                password,
                familyName,
                givenName,
                emailAddress,
                mobileNumber,
            };
            this.onboardingDetails = {
                ...this.onboardingDetails,
                businessName,
            };
        }
    }

    enableCreateAccountStep(isFormValid: boolean): void {
        if (this.activeStepIndex === STEPS.CreateAccount) {
            this.toggleNextStep(isFormValid);
        }
    }

    async signUpUser(): Promise<void> {
        try {
            const { emailAddress } = this.signUpUserPayload;

            this.loader.show();
            const result = await this.authService.checkSignUpStatus(emailAddress);
            if (!result.success) {
                this.createAccountError = result.errorMessage;
                this.recordAnalyticsEvent("signup-error");
                return;
            }
            if (result.verificationSent) {
                this.emailVerificationSent = true;
                return;
            }
            if (result.loginRequired) {
                //Navigate the user to the login page - they will proceed to the appropriate place afterwards
                const emailExists = true;
                this.dialogRef.close();
                await this.router.navigate(["/login"], {
                    queryParams: { emailAddress, emailExists },
                });
                return;
            }
            const response = await this.onboardingService.createTrialUser(
                this.signUpUserPayload,
                this.onboardingDetails,
            );
            if (!response.signupSuccessful) {
                this.createAccountError = response.errorMessage;
                this.recordAnalyticsEvent("create-error");
            } else {
                this.emailVerificationSent = true;
            }
        } finally {
            this.loader.hide();
        }
    }

    async resendEmailVerification(): Promise<void> {
        try {
            this.createAccountError = null;
            this.loader.show();
            const signupResult = await this.authService.checkSignUpStatus(this.signUpUserPayload.emailAddress);
            if (signupResult.loginRequired) {
                await this.router.navigateByUrl("/register/email-success");
            } else if (signupResult.success && signupResult.verificationSent) {
                this.verifyEmailMessage = {
                    message: "Verification email sent",
                    error: false,
                };
            } else if (signupResult.errorMessage) {
                this.verifyEmailMessage = {
                    message: signupResult.errorMessage,
                    error: true,
                };
            }
        } catch (error) {
            this.verifyEmailMessage = {
                message: "Sorry, something went wrong",
                error: true,
            };
        } finally {
            this.loader.hide();
        }
    }

    toggleNextStep(isEnabled: boolean): void {
        this.stepConfigurations = [
            ...this.multiComponentService.toggleForwardButtons(
                this.activeStepIndex,
                this.stepConfigurations,
                isEnabled,
            ),
        ];
    }

    skipRevenueStep(): void {
        this.multiComponentLayoutComponent.selectNextStep();
    }

    exitOnboarding(): void {
        this.router.navigateByUrl("/login");
    }

    private recordAnalyticsEvent(category: string): void {
        this.analyticsService.recordEvent("onboarding", category);
    }

    private recordRegisterCompletionEvent(stepName: string): void {
        const analyticsPath = this.route.snapshot.pathFromRoot
            .map((value) => value.url.map((urlValue) => urlValue.toString()).join("/"))
            .join("/");
        this.analyticsService.recordEvent(`register-completion-${stepName}`, "next");
        this.analyticsService.pageViewed(analyticsPath);
        window.history.replaceState(null, null, `${window.location.pathname}?status=${stepName}`);
    }
}
