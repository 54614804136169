import { Injectable } from "@angular/core";
import { VaultStateAction } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import {
    editVaultVaultPayrunApprovalRequestTaskAction,
    provideInfoVaultPayrunApprovalRequestTaskAction,
    viewVaultVaultPayrunApprovalRequestTaskAction,
} from "../vault/vault-task-actions";
import { VaultRequestApprovalPayrunCardComponent } from "./components/vault-request-approval-payrun-card/vault-request-approval-payrun-card.component";

@Injectable()
export class PayrollPlugin implements IPluginFactory {
    readonly id = "PayrollPlugin";
    readonly gaEvents = GA_EVENTS;

    constructor(libraries: Libraries) {
        libraries.cardViews.register("vault-request-approval-payrun", VaultRequestApprovalPayrunCardComponent);

        libraries.taskActions.register(
            VaultStateAction.PayrunApprovalRequestResponse,
            provideInfoVaultPayrunApprovalRequestTaskAction,
        );
        libraries.taskActions.register(
            VaultStateAction.PayrunApprovalEditResponse,
            editVaultVaultPayrunApprovalRequestTaskAction,
        );
        libraries.taskActions.register(
            VaultStateAction.PayrunApprovalViewResponse,
            viewVaultVaultPayrunApprovalRequestTaskAction,
        );
    }
}
