import { Component, Inject, Injector, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Query } from "@visoryplatform/copilot";
import {
    CardReply,
    CardStatus,
    CopilotTransaction,
    CopilotTransactionsState,
    CopilotTransactionsUpdate,
    CopilotUpdateType,
    IThreadCard,
    ITimeline,
    SubjectType,
} from "@visoryplatform/threads";
import { ExtensionDisplayRef } from "projects/portal-modules/src/lib/shared/services/extension-display-ref";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ICreateCardEvent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card.component";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { Observable, Subscription } from "rxjs";
import { map, pairwise, startWith, switchMap } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { AuthService } from "../../../../portal-modules/src/lib/findex-auth";
import {
    RequestActionButtonLabel,
    RequestStatuses,
} from "../../../vault/components/request/constants/request.constants";
import { IRequestAnalyticsTags } from "../../../vault/components/request/interfaces/IRequestAnalyticsTags";
import { IRequestForm } from "../../../vault/components/request/interfaces/IRequestForms";
import { TransactionHelperService } from "../../services/transaction-helper.service";

@Component({
    selector: "transactions-action",
    templateUrl: "./transactions-action.component.html",
    styleUrls: ["./transactions-action.component.scss"],
})
export class TransactionsActionComponent implements OnInit, OnDestroy {
    readonly CARD_STATUSES = CardStatus;
    readonly buttonLabels = RequestActionButtonLabel;
    readonly requestStatuses = RequestStatuses;

    analyticsTags: IRequestAnalyticsTags;
    card$: Observable<IThreadCard>;
    form$: Observable<FormGroup<IRequestForm>>;
    state$: Observable<CopilotTransactionsState>;
    transactions$: Observable<CopilotTransaction[]>;
    stateSubscription: Subscription;
    thread$: Observable<ITimeline>;
    userId$: Observable<string>;
    replies$: Observable<CardReply[]>;
    extensionDisplayRef: ExtensionDisplayRef;
    modalData: any;

    loader = new Loader();

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private authService: AuthService,
        private injector: Injector,
        private threadCardService: ThreadCardService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.extensionDisplayRef = await this.loader
            .wrap(this.extensionDisplayService.getRef(this.injector))
            .toPromise();
        this.modalData = await this.loader
            .wrap(this.extensionDisplayService.getData<ICreateCardEvent>(this.injector))
            .toPromise();

        this.thread$ = this.modalData.thread$;
        this.card$ = this.modalData.card$;
        this.state$ = this.modalData.state$;
        this.transactions$ = this.state$.pipe(
            startWith(null),
            pairwise(),
            map(([oldState, state]) => TransactionHelperService.mapTransactionOrder(state, oldState)),
            switchMap((transactions) => {
                const vaultId$ = this.card$.pipe(map((card) => this.getVaultIdFromCard(card)));
                return vaultId$.pipe(map((vaultId) => this.mapTransactions(transactions, vaultId)));
            }),
        );
        this.replies$ = this.modalData.replies$;
        this.userId$ = this.authService.getUserId();
    }

    async updateQuery(threadId: string, cardId: string, update: Partial<Query>): Promise<void> {
        console.log("Updating", { update });

        const data: CopilotTransactionsUpdate = {
            type: CopilotUpdateType.EditQuery,
            queryId: update.id,
            update,
        };

        await this.threadCardService.updateCardExtension(threadId, cardId, data).toPromise();
    }

    ngOnDestroy(): void {
        this.stateSubscription?.unsubscribe();
    }

    trackTransaction(_index: number, transaction: CopilotTransaction): string {
        return transaction.query.id;
    }

    private getVaultIdFromCard(card: IThreadCard): string {
        return card.subjects?.find((subject) => subject.type === SubjectType.Vault)?.id;
    }

    private mapTransactions(transaction: CopilotTransaction[], vaultId: string): CopilotTransaction[] {
        return transaction.map((transaction) => this.mapTransactionWithVaultId(transaction, vaultId));
    }

    private mapTransactionWithVaultId(transaction: CopilotTransaction, vaultId: string): CopilotTransaction {
        const vaultDetails = {
            ...transaction.query.vaultDetails,
            vaultId,
        };
        const updatedQuery = {
            ...transaction.query,
            vaultDetails,
        };

        return {
            ...transaction,
            query: updatedQuery,
        };
    }
}
