import { Inject, Injectable } from "@angular/core";
import { IAvatarContent } from "@visoryplatform/fx-ui";
import { ActorId, IParticipant } from "@visoryplatform/threads";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { combineLatest, forkJoin, from, Observable, of, ReplaySubject } from "rxjs";
import { bufferTime, filter, map, mergeMap, shareReplay, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { LaunchDarklyFeatureFlags } from "../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { FeatureFlagService } from "../../feature-flags/services/feature-flags.service";
import { AppUser } from "../../findex-auth";
import { ProfilePictureService } from "./profile-picture.service";

@Injectable({ providedIn: "root" })
export class ParticipantCache {
    private cachedParticipants: { [id: string]: Observable<IParticipant> } = {};
    private debounceRequests = new ReplaySubject<string>(1);
    private resolverQueue: Observable<IParticipant>;
    private systemIdName$: Observable<string>;

    constructor(
        private threadsService: ThreadsService,
        private profilePictureService: ProfilePictureService,
        private featureFlagService: FeatureFlagService,
        @Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig,
    ) {
        this.resolverQueue = this.debounceRequests.pipe(
            bufferTime(25),
            map((ids) => [...new Set(ids)]),
            mergeMap((ids) => this.getParticipants(ids)),
            mergeMap((participants) => from(participants)),
        );

        this.systemIdName$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding).pipe(
            map((isDelphiBrandingEnabled) => (isDelphiBrandingEnabled ? environment.aiName : environment.appName)),
            take(1),
        );
    }

    getParticipant(id: string): Observable<IParticipant> {
        if (id == null) {
            return of(null);
        }

        this.debounceRequests.next(id);

        return this.resolverQueue.pipe(
            filter((participant) => participant?.id === id),
            take(1),
        );
    }

    getParticipants(participantIds: string[]): Observable<IParticipant[]> {
        if (!participantIds || participantIds.length === 0) {
            return of([]);
        }

        const cachedObservables = participantIds
            .filter((id) => this.cachedParticipants[id])
            .map((id) => this.cachedParticipants[id]);

        const cachedParticipants$ = cachedObservables?.length ? forkJoin(cachedObservables) : of([]);
        const participantsToFetch = participantIds.filter((id) => !this.cachedParticipants[id]);

        if (participantsToFetch.length === 0) {
            return cachedParticipants$;
        }

        const fetchParticipants$ = this.fetchParticipants(participantsToFetch);

        return forkJoin([fetchParticipants$, cachedParticipants$]).pipe(
            map(([fetchedParticipants, cachedParticipants]) => [...fetchedParticipants, ...cachedParticipants]),
        );
    }

    update(participants: IParticipant[], systemUserName: string): void {
        this.cachedParticipants = {};

        for (const participant of participants) {
            this.cachedParticipants[participant.id] = of(this.safeParticipant(participant, systemUserName));
        }
    }

    getAvatarContent(participant: IParticipant): Observable<IAvatarContent> {
        return this.profilePictureService.getUserProfilePicture(participant.id).pipe(
            map((avatarImageUrl) => ({
                name: participant?.name || participant?.profile?.name,
                id: participant?.id,
                image: `${avatarImageUrl}`,
                secondary: false,
            })),
        );
    }

    /**
     * @param participants
     * @param currentUser user to filter out of the avatars if you don't want to show them
     */
    getMultipleAvatars(participants: IParticipant[], currentUser?: AppUser): Observable<IAvatarContent[]> {
        const avatarParticipants = (participants || [])
            .filter((value) => !!value)
            .filter((participant) => (currentUser ? participant.id !== currentUser.id : true));

        return forkJoin(avatarParticipants.map((participant) => this.getAvatarContent(participant)));
    }

    cleanName(participant: IParticipant): string {
        if (participant?.profile?.name) {
            return participant.profile.name;
        } else {
            return "Deleted";
        }
    }

    private fetchParticipants(participantsToFetch: string[]): Observable<IParticipant[]> {
        const participants$ = this.threadsService.getParticipants(participantsToFetch);

        const allParticipants$ = combineLatest([participants$, this.systemIdName$]).pipe(
            map(([participants, systemIdName]) =>
                participants.map((participant) => this.safeParticipant(participant, systemIdName)),
            ),
            shareReplay(1),
        );

        for (const participantId of participantsToFetch) {
            this.cachedParticipants[participantId] = allParticipants$.pipe(
                map((participants) => participants.find((participant) => participant.id === participantId)),
                shareReplay(1),
                take(1),
            );
        }

        return allParticipants$;
    }

    private safeParticipant(participant: IParticipant, systemName: string): IParticipant {
        const isSystem = participant.id === ActorId.System;
        if (isSystem) {
            return {
                ...participant,
                ...{ profile: { name: systemName } },
            };
        }

        const profile = participant.profile && participant.profile.name ? participant.profile : { name: undefined };
        return this.threadsService.mapParticipant({
            ...participant,
            ...{ profile },
        });
    }
}
