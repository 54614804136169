import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { IParticipant } from "@visoryplatform/threads";
import { Subject } from "rxjs";

@Component({
    selector: "app-card",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnChanges {
    @Input() name?: string;
    @Input() image?: string;
    @Input() participants?: IParticipant[];
    @Input() threadType?: string;
    @Input() timestamp?: string | Date | number;
    @Input() title?: string;
    @Input() description?: string;
    @Input() lineCard: boolean;
    @Input() invertTheme?: boolean;
    @Input() conversation = true;
    @Input() align?: "left" | "right" | "centre";
    @Input() edit: Subject<boolean>;
    @Input() edited: boolean | Subject<boolean>;
    @Input() loading = false;
    @Input() showDescription = true;
    @Input() showFullDescription = false;
    @Input() isUnread: boolean;
    @Input() isInternal: boolean;
    @Input() useQuillHtml?: boolean;

    @Output() save = new EventEmitter();

    messageFormControl = new FormControl(this.description || "", [Validators.required]);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.description) {
            this.messageFormControl.setValue(this.description);
        }
    }

    saveEdit(value: string): void {
        this.edit.next(false);
        this.save.emit(value);
    }

    cancelEdit(): void {
        this.edit.next(false);
    }
}
