import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface ConfirmModalParams {
    analyticsPrefix?: string;
    confirmText?: string;
    declineText?: string;
    promptText: string;
    areYouSureText: string;
}

@Component({
    selector: "app-upload-cancel-modal",
    templateUrl: "./confirm-modal.component.html",
    styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent implements OnInit {
    analyticsPrefix: string;
    confirmText: string;
    declineText: string;
    promptText: string;
    areYouSureText: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmModalParams,
        public dialogRef: MatDialogRef<ConfirmModalComponent>,
    ) {}

    ngOnInit() {
        this.confirmText = this.data.confirmText || "OK";
        this.declineText = this.data.declineText || "Cancel";
        this.promptText = this.data.promptText;
        this.areYouSureText = this.data.areYouSureText;
        this.analyticsPrefix = this.data.analyticsPrefix;
    }

    async close(confirm: boolean): Promise<void> {
        this.dialogRef.close(confirm);
    }
}
