import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, timer } from "rxjs";
import { debounceTime, map, shareReplay } from "rxjs/operators";

const ONE_MINUTE_MS = 60 * 1000;
const DEBOUNCE_TIME_MS = 2500;

@Injectable({
    providedIn: "root",
})
export class WorkflowTimerService {
    private timer$: Observable<DateTime>;

    constructor() {
        const now = DateTime.now();
        const nextMinute = now.startOf("minute").plus({ minutes: 1 });
        const msUntilNextMinute = nextMinute.diff(now).milliseconds;

        this.timer$ = timer(msUntilNextMinute, ONE_MINUTE_MS).pipe(
            debounceTime(DEBOUNCE_TIME_MS),
            map(() => DateTime.now()),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    public getTimer(): Observable<DateTime> {
        return this.timer$;
    }
}
