<div
    #appComponent
    class="embedded-app-component"
>
    @if (item$ | async; as item) {
        <ng-container>
            <ng-container *ngComponentOutlet="item.component; injector: item.injector"></ng-container>
        </ng-container>
    } @else if (busy()) {
        <thread-overview-content-ghost-loader />
    } @else {
        <thread-overview-content-placeholder
            [assigneeType]="assigneeType()"
            [role]="role()"
            [currentStepId]="currentStepId()"
        ></thread-overview-content-placeholder>
    }
</div>
