import { Pipe, PipeTransform } from "@angular/core";
import { Observable, of } from "rxjs";
import { AppUser, AuthService } from "../../findex-auth";

import { IParticipant } from "@visoryplatform/threads";
import { map } from "rxjs/operators";
import { MentionableUser } from "../components/quill-wrapper/mentionable-user";
import { ProfilePictureService } from "../services/profile-picture.service";

@Pipe({ name: "participantsToMention" })
export class ParticipantsToMentionPipe implements PipeTransform {
    currentUser$: Observable<AppUser>;

    constructor(
        private profilePictureService: ProfilePictureService,
        private authService: AuthService,
    ) {
        this.currentUser$ = this.authService.getUser();
    }

    transform(participants: IParticipant[]): Observable<MentionableUser[]> {
        if (!participants?.length) {
            return of([]);
        }

        return this.currentUser$.pipe(
            map((currentUser) => participants.filter((participant) => participant.id !== currentUser.id)),
            map((filteredParticipants) => this.mapToMentionableUser(filteredParticipants)),
        );
    }

    private mapToMentionableUser(filteredParticipants: IParticipant[]): MentionableUser[] {
        return filteredParticipants
            .map((participant) => ({
                id: participant.id,
                value: participant.name || participant.profile?.name,
                title: participant.profile?.title,
                avatarUrl: this.profilePictureService.getUrl(participant.id),
            }))
            .sort(this.sortByName);
    }

    private sortByName(a: MentionableUser, b: MentionableUser): number {
        if (!a.value) {
            return 1;
        }
        if (!b.value) {
            return -1;
        }
        return a.value.localeCompare(b.value);
    }
}
