import { inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { mapTo, switchMap } from "rxjs/operators";
import { LoginDialogComponent } from "../components/login-dialog/login-dialog.component";
import { AppUser } from "../model/AppUser";
import { AuthService } from "./auth.service";

export const authGuard: CanActivateFn = (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Observable<UrlTree | boolean> => {
    const authService = inject(AuthService);
    const dialog = inject(MatDialog);
    const router = inject(Router);

    const loggedOutHandler = (user: AppUser): Observable<UrlTree | boolean> => {
        if (!user) {
            return of(true);
        } else {
            if (user.mobileNumberVerified) {
                return of(router.parseUrl("/dashboard"));
            } else {
                return of(router.parseUrl("/register/verify-mobile"));
            }
        }
    };

    const validOrAuth = (user: AppUser): Observable<boolean | UrlTree> => {
        if (user) {
            if (!user.mobileNumberVerified) {
                return of(router.parseUrl("/register/verify-mobile"));
            }
            return of(true);
        } else {
            const options = {
                disableClose: true,
                backdropClass: "modal-backdrop",
                panelClass: ["modal-container", "mat-dialog-no-styling"],
                maxWidth: "100%",
                minWidth: "100%",
                maxHeight: "100%",
                minHeight: "100%",
            };

            return dialog.open(LoginDialogComponent, options).afterClosed().pipe(mapTo(true));
        }
    };

    return authService.getUserWithoutRole().pipe(
        switchMap((user) => {
            if (state.url === "/register/signup") {
                return loggedOutHandler(user);
            }
            if (state.url.startsWith("/register/verify-mobile")) {
                return of(true);
            }
            return validOrAuth(user);
        }),
    );
};
