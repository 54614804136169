import { InjectionToken } from "@angular/core";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";

export const ENVIRONMENT = new InjectionToken("environment");

export const SENTRY_ERROR_HANDLER = new InjectionToken("sentry_error_handler");
export const THEME = new InjectionToken("theme");
export const SENTRY = new InjectionToken("sentry");
export const MOBILE_VERIFY_SERVICE = new InjectionToken("mobileVerifyService");

export const CARD_LIBRARY = new InjectionToken("CardViewLibrary");
export const THREAD_LIBRARY = new InjectionToken("ThreadViewLibrary");
export const APP_ROUTE_LIBRARY = new InjectionToken("AppRouteLibrary");
export const ACCOUNT_ROUTE_LIBRARY = new InjectionToken("AccountRouteLibrary");
export const PROFILE_ROUTE_LIBRARY = new InjectionToken("ProfileRouteLibrary");
export const INSIGHTS_ROUTE_LIBRARY = new InjectionToken("InsightsRouteLibrary");
export const EXTENSION_MENU_LIBRARY = new InjectionToken("ExtensionMenuLibrary");
export const CREATE_CARD_LIBRARY = new InjectionToken("CreateCardLibrary");
export const DUPLICATE_CARD_LIBRARY = new InjectionToken("DuplicateCardLibrary");
export const TASK_ACTION_LIBRARY = new InjectionToken("TaskActionLibrary");
export const BANNER_LIBRARY = new InjectionToken("BannerLibrary");
export const EXTENSION_DISPLAY_SERVICE = new InjectionToken<ExtensionDisplayService>("ExtensionDisplayService");
