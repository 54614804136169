import { Component, forwardRef, Input, OnDestroy, OnInit } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { Account, IAccountListing } from "@visoryplatform/threads";
import { Subscription } from "rxjs";
import { RequestService } from "../../services/request.service";
import { CreateRequest } from "../create-request-modal/create-request-modal.component";

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomerComponent),
    multi: true,
};

const VALIDATORS = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CustomerComponent),
    multi: true,
};

@Component({
    selector: "customer",
    templateUrl: "./customer.component.html",
    styleUrls: ["./customer.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR, VALIDATORS],
})
export class CustomerComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() accounts: Account[] = [];

    formSubscription: Subscription;
    onChange: (newVal: CreateRequest) => void;
    onTouched?: () => void;

    readonly requestCategories: string[];

    formGroup = new FormGroup({
        account: new FormControl<IAccountListing | null>(null, [Validators.required]),
        requestCategory: new FormControl<string>("", [Validators.required]),
        message: new FormControl<string>("", [Validators.required]),
    });

    constructor(private requestService: RequestService) {
        this.requestCategories = this.requestService.getCustomerCategories();
    }

    ngOnInit(): void {
        this.formSubscription = this.formGroup.valueChanges.subscribe((value) => {
            this.onChange?.({
                account: value.account,
                message: value.message,
                requestCategory: value.requestCategory,
            });
        });
    }

    ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    }

    writeValue(values: CreateRequest): void {
        this.formGroup.setValue({
            account: values.account,
            requestCategory: values.requestCategory,
            message: values.message,
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    validate(): ValidationErrors | null {
        return this.formGroup.invalid ? { invalid: true } : null;
    }
}
