import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TableReportRow, TableReportTrend } from "@visoryplatform/openmeasures-core";
import { ChartDataset } from "chart.js";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ColorService } from "../../../../portal-modules/src/lib/shared/services/color.service";
import { OpenMeasuresService } from "../../services/open-measures.service";

@Component({
    selector: "historical-report",
    templateUrl: "./historical-report.component.html",
})
export class HistoricalComponent implements OnChanges {
    @Input() enterpriseId: string;
    @Input() entityId: string;
    @Input() periodId: string;
    @Input() row: TableReportRow;

    public loader = new Loader();
    public chartLabels$: Observable<string[]>;
    public chartDatasets$: Observable<ChartDataset<"line">[]>;

    private colorService = new ColorService();

    constructor(private openMeasures: OpenMeasuresService) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { enterpriseId, entityId, periodId } = changes;

        if (enterpriseId || entityId || periodId) {
            if (!this.enterpriseId || !this.entityId || !this.periodId || !this.row) {
                return;
            }

            if (enterpriseId?.currentValue) {
                this.colorService = new ColorService();
            }

            const { metric } = this.row;
            const trends$ = this.loader
                .wrap(this.openMeasures.getTrends(this.enterpriseId, this.entityId, this.periodId, metric.id))
                .pipe(shareReplay(1));

            this.chartLabels$ = trends$.pipe(map((trends) => trends.map((trend) => trend.periodLabel)));

            this.chartDatasets$ = trends$.pipe(map((trends) => this.getChartDataset(trends)));
        }
    }

    private getChartDataset(trends: TableReportTrend[]): ChartDataset<"line">[] {
        const label = "Result";
        const color = this.colorService.getColor(label);
        const targetColor = this.colorService.defaultColors.targetLine;

        const resultsData: ChartDataset<"line"> = {
            label: label,
            data: trends.map((trend) => trend.metricResult),
            borderColor: color,
            backgroundColor: color,
            hoverBackgroundColor: "white",
            spanGaps: true,
            tension: 0.25,
        };

        const targetsData: ChartDataset<"line"> = {
            label: "Target",
            data: trends.map((trend) => trend.targetValue),
            backgroundColor: "transparent",
            borderColor: targetColor,
            borderDash: [10, 10],
            spanGaps: true,
            borderWidth: 1.5,
            pointRadius: 0,
            pointHoverRadius: 0,
        };

        return [resultsData, targetsData];
    }
}
