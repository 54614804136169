import { Injectable } from "@angular/core";
import { PaginatedDataset } from "@visoryplatform/datastore-types-frontend";
import { Notification } from "@visoryplatform/notifications-core";
import { NotificationsService } from "projects/notifications-frontend/src/services/notifications.service";
import { Observable, forkJoin, of } from "rxjs";
import { defaultIfEmpty, map, shareReplay, switchMap } from "rxjs/operators";
import { INotification } from "src/app/interfaces/INotification";
import { AuthService } from "../../findex-auth";
import { ProfilePictureService } from "../../threads-ui/services/profile-picture.service";

const ACTIVITY_PAGE_SIZE = 25;
@Injectable({ providedIn: "root" })
export class ActivityNotificationsService {
    private notificationsPage: PaginatedDataset<Notification>;

    constructor(
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private profilePictureService: ProfilePictureService,
    ) {}

    getActivity(showUnreadOnly?: boolean, groupType?: string): Observable<Notification[]> {
        const user$ = this.authService.getUserWithoutRole();
        return user$.pipe(
            switchMap(() => this.updateNotificationPage(showUnreadOnly, groupType)),
            switchMap(() => this.getNextPage()),
            switchMap(() => this.notificationsPage.getDataObservable()),
            switchMap((notifications) => this.getAllNotifications(notifications)),
            shareReplay(1),
        );
    }

    updateNotificationPage(showUnreadOnly: boolean, groupType?: string): Observable<any> {
        this.notificationsPage = this.notificationsService.getCurrentNotifications(
            "activity",
            ACTIVITY_PAGE_SIZE,
            showUnreadOnly,
            groupType,
        );
        return of([]);
    }
    /**
     * TODO: Refactor. Last day, bug squash to release important features.
     * See previous commit. There's a bug where the API will not return all
     * results because of the dynamo filter which means the cdk virtual scroll
     * thinks it's done loading items
     *
     * Shani's user in UAT experiences the problem
     */
    async getNextPage(prevCount = 0): Promise<number> {
        const nextPage = await this.notificationsPage.nextPage().toPromise();
        const count = nextPage?.result?.length || 0;

        if (count + prevCount < 10 && nextPage?.next) {
            const result = await this.getNextPage(prevCount);
            return result + count + prevCount;
        } else {
            return count;
        }
    }
    async markAllAsRead(): Promise<void> {
        await this.notificationsService.markAllAsRead("activity");
    }

    private getAllNotifications(notifications: Notification[]): Observable<Notification[]> {
        const allNotifications$ = notifications.map((notification) => this.getActivityNotification(notification));
        return forkJoin(allNotifications$).pipe(defaultIfEmpty([]));
    }

    private getActivityNotification(notification: Notification): Observable<INotification> {
        return this.profilePictureService
            .getUserProfilePicture(notification.actorId)
            .pipe(map((avatarImage) => ({ ...notification, avatarImage })));
    }
}
