import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, FormRecord } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { AcceptedCurrencies } from "@visoryplatform/payments-service-sdk";
import {
    IPayRunReport,
    IRequestItem,
    IThreadCard,
    IVaultRequestCardState,
    PayrunReportRegion,
    Role,
} from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable, Subscription } from "rxjs";
import { map, pairwise, scan, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { FormRequestItem, FormRequestTable } from "../../../vault/types/RequestTableForm";
import { IPayrunReportLineData } from "../../interfaces/IPayrunReportLineData";
import { PayrunReportDataService } from "../../services/payrun-report-data.service";

@Component({
    selector: "payrun-report-action-request",
    templateUrl: "./payrun-report-action-request.component.html",
    styleUrls: ["./payrun-report-action-request.component.scss"],
})
export class PayrunReportActionRequestComponent implements OnInit {
    @Input() cardId: string;
    @Input() isCompleted: boolean;
    @Input() readonly: boolean;
    @Input() role: Role;
    @Input() state$: Observable<IVaultRequestCardState>;
    @Input() threadId: string;
    @Input() card$: Observable<IThreadCard>;
    @Output() approveAll = new EventEmitter();
    @Output() formChanges = new EventEmitter();
    @Output() requestItemsCompletedChanges = new EventEmitter<boolean[]>();
    @Output() updateControl = new EventEmitter();

    readonly appName = this.environment.appName;
    readonly regions = PayrunReportRegion;
    readonly ROLES = Role;

    currency: AcceptedCurrencies;
    form$: Observable<FormGroup<FormRequestTable>>;
    loader = new Loader();
    report$: Observable<IPayRunReport>;
    requestItemsCompletedChangesSub: Subscription;
    tableData$: Observable<MatTableDataSource<IPayrunReportLineData>>;
    refreshSubscription: Subscription;
    isInternal: boolean;

    private readonly dataSource = new MatTableDataSource<IPayrunReportLineData>();

    constructor(
        private payrunReportDataService: PayrunReportDataService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    // eslint-disable-next-line max-lines-per-function
    ngOnInit(): void {
        this.report$ = this.state$.pipe(
            switchMap((state) => {
                const fileId = state.attachments.fileId;
                const vaultId = state.vaultId;
                return this.payrunReportDataService.downloadVaultPayRunReport(vaultId, fileId);
            }),
            shareReplay(1),
        );

        this.card$.pipe(take(1)).subscribe((card) => {
            this.isInternal = card.isInternal;
        });

        this.tableData$ = this.report$.pipe(
            map((report) => {
                const dataSource = this.dataSource;
                dataSource.data = report.lines
                    .sort((a, b) => a.employeeName.localeCompare(b.employeeName))
                    .map((reportLine) => ({
                        ...reportLine,
                        currency: this.payrunReportDataService.getCurrencyByRegion(report.region),
                        hideRowChild: this.buildHideRowChild(dataSource.data, reportLine),
                    }));
                return dataSource;
            }),
        );

        const initialForm = { requestItems: new FormRecord<FormGroup<FormRequestItem>>({}) };
        const formGroup = new FormGroup<FormRequestTable>(initialForm);

        this.form$ = this.state$.pipe(
            startWith(null),
            pairwise(),
            scan((_, [oldState, currState]) => this.setRequestItems(formGroup, oldState, currState), formGroup),
        );

        this.requestItemsCompletedChangesSub = formGroup.valueChanges.subscribe((value) => {
            const requestItemsValues = Object.keys(value.requestItems).map((key) => value.requestItems[key]?.completed);
            this.requestItemsCompletedChanges.emit(requestItemsValues);
        });
    }

    ngOnDestroy(): void {
        this.requestItemsCompletedChangesSub?.unsubscribe();
    }

    approveAllRequestItems(formGroup: FormGroup<FormRequestTable>): void {
        const requestItems = formGroup.controls.requestItems;
        if (!requestItems) {
            return;
        }

        const requestItemControls = requestItems.controls;
        const keys = Object.keys(requestItemControls);
        for (const key of keys) {
            const control = requestItemControls[key];
            control.controls.completed.setValue(true);
        }
        this.approveAll.emit();
    }

    updateControlValue(formGroup: FormGroup<FormRequestTable>, control: FormGroup<FormRequestItem>): void {
        this.updateControl.emit({ control, formValues: formGroup.value });
    }

    private buildHideRowChild(data: IPayrunReportLineData[], reportLine: IPayrunReportLineData): boolean {
        return data?.find((line) => line.employeeId === reportLine.employeeId)?.hideRowChild || true;
    }

    private setRequestItems(
        formGroup: FormGroup<FormRequestTable>,
        oldState: IVaultRequestCardState,
        state: IVaultRequestCardState,
    ): FormGroup<FormRequestTable> {
        this.updateRequestItems(formGroup, oldState?.requestItems, state?.requestItems);
        return formGroup;
    }

    private updateRequestItems(
        formGroup: FormGroup<FormRequestTable>,
        oldItems: IRequestItem[],
        currItems: IRequestItem[],
    ): void {
        const removeItems = oldItems?.filter((oldItem) => !this.isItemInCurrentItems(oldItem.fileId, currItems)) || [];
        const addItems = currItems?.filter((currItem) => !this.isItemInOldItems(currItem.fileId, oldItems)) || [];
        const updateItems = currItems?.filter((currItem) => this.isItemInOldItems(currItem.fileId, oldItems)) || [];

        const requestItems = formGroup.controls.requestItems;

        for (const item of removeItems) {
            const existingItemId = this.findControlByFileId(item.fileId, requestItems);
            requestItems.removeControl(existingItemId);
        }

        for (const item of addItems) {
            this.addItem(item, requestItems);
        }

        for (const item of updateItems) {
            const oldItem = oldItems.find((oItem) => oItem.fileId === item.fileId);
            this.updateItem(oldItem, item, requestItems);
        }
    }

    private isItemInCurrentItems(fileId: string, currItems: IRequestItem[]): boolean {
        return currItems?.some((currItem) => currItem.fileId === fileId) ?? false;
    }

    private isItemInOldItems(fileId: string, oldItems: IRequestItem[]): boolean {
        return oldItems?.some((oldItem) => oldItem.fileId === fileId) ?? false;
    }

    private addItem(item: IRequestItem, requestItems: FormRecord<FormGroup<FormRequestItem>>): void {
        const newControl = new FormGroup<FormRequestItem>({
            fileId: new FormControl({ value: item.fileId, disabled: false }),
            description: new FormControl({ value: item.description, disabled: false }),
            completed: new FormControl({ value: item.response?.complete?.state, disabled: false }),
        });

        requestItems.addControl(item.description, newControl);
    }

    private updateItem(
        oldItem: IRequestItem,
        item: IRequestItem,
        requestItems: FormRecord<FormGroup<FormRequestItem>>,
    ): void {
        const existingItemId = this.findControlByFileId(item.fileId, requestItems);
        const requestItemControl = requestItems.controls[existingItemId];

        if (this.hasItemStateChanged(oldItem, item)) {
            requestItemControl.patchValue({
                completed: item.response?.complete?.state,
            });
        } else {
            requestItemControl.patchValue({
                description: item.description,
            });
        }
    }

    private findControlByFileId(fileId: string, requestItemControls: FormRecord<FormGroup<FormRequestItem>>): string {
        const keys = Object.keys(requestItemControls.controls);
        return keys.find((key) => requestItemControls.controls[key].value?.fileId === fileId);
    }

    private hasItemStateChanged(oldItem: Partial<IRequestItem>, currItem: Partial<IRequestItem>): boolean {
        return oldItem?.response?.complete?.state !== currItem?.response?.complete?.state;
    }
}
