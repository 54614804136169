import { IWorkflow, IWorkflowAction, SystemStepId } from "@visoryplatform/workflow-core";
import jsonata from "jsonata";

export async function isActionVisible(workflow: IWorkflow, action: IWorkflowAction): Promise<boolean> {
    if (action.id === SystemStepId.Cancelled) {
        return false;
    }

    if (typeof action.hidden === "boolean") {
        return !action.hidden;
    }

    /** Hidden changed temporarily to a jsonata expression, we need to migrate all workflows back to boolean and then we can strip out everything below */
    if (action.hidden === "true") {
        return false;
    }

    if (action.hidden === "false") {
        return true;
    }

    return evaluateHidden(workflow, action);
}

async function evaluateHidden(workflow: IWorkflow, action: IWorkflowAction): Promise<boolean> {
    try {
        const expression = jsonata(action.hidden as unknown as string); //HACK for reversion
        const result = await expression.evaluate(workflow);
        return typeof result === "boolean" ? !result : true;
    } catch (error) {
        console.error("Error evaluating hidden expression", error);
        return true;
    }
}
