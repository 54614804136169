import { Injectable, InjectionToken, Injector, Type } from "@angular/core";
import { map, Observable, Observer, of, Subject, Subscriber } from "rxjs";
import { FeatureFlagService } from "../../feature-flags";
import { LaunchDarklyFeatureFlags } from "../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { EmbeddedRef } from "./embedded-ref";
import { ExtensionDisplayService } from "./extension-display.service";

export interface EmbeddedConfig {
    data: any;
}

export interface EmbeddedContext<R = unknown> {
    $implicit: Subscriber<R>;
    config: EmbeddedConfig;
    component: Type<any>;
}

export interface EmbeddedItem extends EmbeddedContext {
    injector: Injector;
}

export const EMBEDDED_DATA = new InjectionToken("EMBEDDED_DATA");
export const EMBEDDED_REF = new InjectionToken("EMBEDDED_REF");

@Injectable({
    providedIn: "root",
})
export class EmbeddedService implements ExtensionDisplayService {
    items: EmbeddedItem[] = [];
    item$ = new Subject<EmbeddedItem>();
    private observer: Observer<any> | null = null;

    constructor(
        private injector: Injector,
        private featureFlagService: FeatureFlagService,
    ) {}

    open<R = void>(component: Type<any>, config: EmbeddedConfig): Observable<R> {
        return new Observable<R>((observer) => {
            this.observer = observer;
            const context = {
                config,
                $implicit: observer,
                component: component,
            };
            const embeddedRef = new EmbeddedRef(context);

            const injector = Injector.create({
                parent: this.injector,
                providers: [
                    { provide: EMBEDDED_DATA, useValue: config.data },
                    { provide: EmbeddedRef, useValue: embeddedRef },
                ],
            });

            const item = {
                ...context,
                injector,
            };

            this.item$.next(item);
            this.items.push(item);

            return () => {
                this.item$.next(null);
                this.items = [];
            };
        });
    }

    getRef<R = unknown>(injector: Injector): Observable<EmbeddedRef<R>> {
        return of(injector.get(EmbeddedRef) as EmbeddedRef<R>);
    }

    getData<T = unknown>(injector: Injector): Observable<T> {
        return of(injector.get(EMBEDDED_DATA) as T);
    }

    close(): void {
        this.item$.next(null);
        this.items = [];
        if (this.observer) {
            this.observer.next(null);
            this.observer.complete();
            this.observer = null;
        }
    }

    back(): void {
        this.items = this.items.filter((_, index) => index !== this.items.length - 1);
        this.item$.next(this.items[this.items.length - 1]);
    }

    getConfirmConfigPanelClass(): Observable<string[]> {
        return this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableWorkflowListAndStepper).pipe(
            map((flag) => {
                if (flag) {
                    return ["threads-sidebar-inner-close-modal"];
                } else {
                    return ["threads-sidebar", "threads-sidebar-inner-close-modal"];
                }
            }),
        );
    }
}
