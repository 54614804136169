import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { FindexAuthModule } from "../findex-auth";
import { FindexUiModule } from "../findex-ui/findex-ui.module";
import { OnboardingModule } from "../onboarding/onboarding.module";
import { ThreadsUiModule } from "../threads-ui/threads-ui.module";
import { UserProfileRoutingModule } from "./user-profile-routing.module";

import { SharedModule } from "../shared";
import { AdminSetPasswordDialogComponent } from "./components/admin-set-password-dialog/admin-set-password-dialog";
import { ChangePasswordDialogComponent } from "./components/change-password-dialog/change-password-dialog.component";
import { ChangePasswordFormComponent } from "./components/change-password-form/change-password-form.component";
import { ConfirmEmailComponent } from "./components/confirm-email/confirm-email.component";
import { UserProfileExtensionDirective } from "./components/user-profile/user-profile-extension.directive";

import { PortalUiModule } from "@visoryplatform/portal-ui";
import { OpenMeasuresService } from "projects/default-plugins/insights/services/open-measures.service";
import { ProfileRouteComponent } from "./components/profile-route/profile-route.component";
import { GeneralSettingsComponent } from "./components/user-profile/general-settings/general-settings.component";
import { NotificationSettingsComponent } from "./components/user-profile/notification-settings/notification-settings.component";
import { UserNotificationSettingsService } from "./services/user-notification-settings.service";

const angularimports = [CommonModule, FormsModule, FindexAuthModule];

@NgModule({
    imports: [
        ...angularimports,
        FindexUiModule,
        ThreadsUiModule,
        UserProfileRoutingModule,
        OnboardingModule,
        FxUiModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        SharedModule,
        PortalUiModule,
    ],
    declarations: [
        UserProfileExtensionDirective,
        ChangePasswordDialogComponent,
        ChangePasswordFormComponent,
        ConfirmEmailComponent,
        AdminSetPasswordDialogComponent,
        GeneralSettingsComponent,
        ProfileRouteComponent,
        NotificationSettingsComponent,
    ],
    exports: [],
    providers: [OpenMeasuresService, UserNotificationSettingsService],
})
export class UserProfileModule {}
