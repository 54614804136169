import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, FormRecord } from "@angular/forms";
import { MAT_LUXON_DATE_FORMATS } from "@angular/material-luxon-adapter";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IParticipant, ITimeline, Role, WorkflowExtensionService } from "@visoryplatform/threads";
import {
    AssigneeExtensionHelpers,
    DUE_DATE_EXTENSION_TYPE,
    DueDateAnchor,
    IStep,
    IWorkflow,
    IWorkflowInputs,
    SlaExtensionHelpers,
    WorkflowCoreExtensionService,
    WorkflowDueDateService,
    WorkflowGraphService,
    WorkflowSteps,
} from "@visoryplatform/workflow-core";
import { DateTime } from "luxon";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ThreadUpdateService } from "projects/portal-modules/src/lib/shared/services/thread-update-service";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { filter, map, startWith, switchMap, take } from "rxjs/operators";
import { IParticipantUi } from "../../../../interfaces/IParticipantUi";
import { ParticipantService } from "../../../../services/participant.service";
import { ThreadsService } from "../../../../services/threads.service";
import { dueDateValidator } from "../../../edit-workflow-config/components/edit-workflow-config/due-date.validator";
import { EditWorkflowInput } from "../../../edit-workflow-config/components/edit-workflow-config/edit-workflow-config.component";
import { WorkflowFormsService } from "../../../edit-workflow-config/services/due-date-forms.service";
import { DATE_LOCALE_VALUE } from "../../constants";
import { StepDataForm, StepDataValues } from "../../types/StepDataForm";

export type EditThreadModalData = {
    thread: ITimeline;
    role: Role;
};

@Component({
    selector: "edit-thread-modal",
    templateUrl: "./edit-thread-modal.component.html",
    styleUrls: ["./edit-thread-modal.component.scss"],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: DATE_LOCALE_VALUE },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    ],
})
export class EditThreadModalComponent implements OnInit, OnDestroy {
    @Output() dateChange: EventEmitter<MatDatepickerInputEvent<Event>> = new EventEmitter();

    thread$: Observable<ITimeline>;
    timezone$: Observable<string>;
    role: Role;

    hasDueDates$: Observable<boolean>;
    loader = new Loader();
    stepConfig = new FormRecord<FormGroup<StepDataForm>>({});
    threadHasDateChanges = false;
    participants$: Observable<IParticipantUi[]>;
    formValueChanges: Subscription;
    selectedHasSlas?: boolean;

    editWorkflowControl = new FormControl<EditWorkflowInput>({
        calculateFromDate: null,
        datesCalculationPoint: DueDateAnchor.EndDate,
    });

    private threadSub: Subscription;
    private calculateFromDateSub: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: EditThreadModalData,
        public dialogRef: MatDialogRef<EditThreadModalComponent>,
        private threadUpdateService: ThreadUpdateService,
        private threadService: ThreadsService,
        private dueDateFormsService: WorkflowFormsService,
        private participantService: ParticipantService,
    ) {}

    ngOnInit(): void {
        this.role = this.data.role;
        this.setDataFromThread(this.data.thread);
        this.calculateFromDateHasChanges();
    }

    resetDates(): void {
        this.resetWorkflowControl();

        if (this.stepConfig?.controls) {
            Object.values(this.stepConfig.controls).forEach((value) => {
                value.controls.dateTime?.reset();
            });
        }
    }

    onCalculationFromTimeChanged(
        workflow: IWorkflow,
        _timezone: string,
        calculateFromDate: DateTime,
        calculationPoint: DueDateAnchor,
    ): void {
        const formValues = this.dueDateFormsService.getFormValues(calculateFromDate, calculationPoint, workflow);

        this.setStepConfigValues(formValues);
        this.stepConfigValueChanges(this.stepConfig);
    }

    setStepConfigValues(values: Record<string, FormGroup<StepDataForm>>): void {
        Object.entries(this.stepConfig.controls).forEach(([key, value]) => {
            value.controls.dateTime?.setValue(values[key]?.controls?.dateTime?.value);
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.threadSub?.unsubscribe();
        this.calculateFromDateSub?.unsubscribe();
        this.formValueChanges?.unsubscribe();
    }

    updateParticipants(participants: IParticipantUi[]): void {
        this.participants$ = of(participants);
    }

    updateThread(
        stepConfig: FormRecord<FormGroup<StepDataForm>>,
        thread: ITimeline,
        participants$: Observable<IParticipant[]>,
    ): void {
        participants$
            .pipe(
                filter((participants) => !!participants?.length),
                take(1),
                switchMap((participants) => {
                    const workflowInputs = this.getExtensionInputUpdates(stepConfig, thread.workflow.steps);
                    const updateThread$ = this.threadService.updateThread(
                        thread.id,
                        null,
                        workflowInputs,
                        participants,
                    );

                    return this.loader.wrap(updateThread$);
                }),
            )
            .subscribe(() => {
                this.dialogRef.close(true);
            });
    }

    private getStepConfig(thread: ITimeline): FormRecord<FormGroup<StepDataForm>> {
        const timeZone = thread.account?.metadata?.contactInfo?.timeZone;
        const createdAt = DateTime.fromISO(thread.createdAt).setZone(timeZone || "local");
        const formRecords = this.dueDateFormsService.getFormValues(
            createdAt,
            DueDateAnchor.StartDate,
            thread.workflow,
            true,
        );
        const orderedSteps = WorkflowGraphService.orderWorkflowSteps(thread.workflow);

        this.selectedHasSlas = this.getHasSlas(orderedSteps);

        const dueDateSteps = WorkflowDueDateService.filterDueDateSteps(orderedSteps);

        return new FormRecord<FormGroup<StepDataForm>>(formRecords, dueDateValidator(dueDateSteps));
    }

    private setDataFromThread(thread: ITimeline): void {
        this.thread$ = this.threadUpdateService.getUpdatesByThread(thread);
        this.timezone$ = this.thread$.pipe(map((thread) => thread?.account?.metadata?.contactInfo?.timeZone));

        this.hasDueDates$ = this.thread$.pipe(
            map((thread) => Object.values(thread?.workflow?.steps)?.some(this.isDueDateStep)),
        );

        this.threadSub?.unsubscribe();
        this.threadSub = this.thread$.subscribe((thread) => {
            this.stepConfig = this.getStepConfig(thread);
            this.setCalculateFromDate(this.stepConfig.value);
            this.stepConfigValueChanges(this.stepConfig);
        });
    }

    private isDueDateStep(step: IStep): boolean {
        return step.extensions.some((extension) => extension.type === DUE_DATE_EXTENSION_TYPE);
    }

    private stepConfigValueChanges(form: FormRecord<FormGroup<StepDataForm>>): void {
        this.formValueChanges?.unsubscribe();
        const formValue$ = form.valueChanges.pipe(startWith(form.value));
        const workflowSteps$ = this.thread$.pipe(map((thread) => thread.workflow.steps));

        this.formValueChanges = combineLatest([formValue$, workflowSteps$]).subscribe(([stepConfig, steps]) => {
            const assignees = this.getAssigneesFromGroups(stepConfig, steps);
            this.setParticipantUpdates(assignees, this.data.thread.participants);
        });
    }

    private setCalculateFromDate(
        stepConfig: Record<string, Partial<{ dateTime: DateTime; assignees: string[] }>>,
    ): void {
        const stepsDateTime = Object.values(stepConfig)
            .map((group) => group.dateTime)
            .filter((val) => !!val);
        const latestDate = DateTime.max(...stepsDateTime);

        this.editWorkflowControl.setValue({
            ...this.editWorkflowControl.value,
            calculateFromDate: latestDate,
        });
    }

    private calculateFromDateHasChanges(): void {
        this.calculateFromDateSub?.unsubscribe();
        this.calculateFromDateSub = this.editWorkflowControl.valueChanges.subscribe(() => {
            this.threadHasDateChanges = true;
        });
    }

    private setParticipantUpdates(
        assignees: Record<string, string[]>,
        defaultThreadParticipants: IParticipant[],
    ): void {
        const uniqueAssignees = new Set(Object.values(assignees || []).flat());
        this.participants$ = this.participantService
            .getClonedParticipants(defaultThreadParticipants, [...uniqueAssignees])
            .pipe(startWith([]));
    }

    private getExtensionInputUpdates(
        formGroups: FormRecord<FormGroup<StepDataForm>>,
        steps: WorkflowSteps,
    ): IWorkflowInputs {
        const groupsValue = formGroups.value;
        const assignees = this.getAssigneesFromGroups(groupsValue, steps);
        const dueDates = this.getDueDatesFromGroups(groupsValue);
        return WorkflowExtensionService.getExtensionInputUpdates({ steps }, assignees, dueDates);
    }

    private getHasSlas(steps: IStep[]): boolean {
        return Object.values(steps).some((step: IStep) => SlaExtensionHelpers.isStepSla(step));
    }

    private getDueDatesFromGroups(groups: Record<string, Partial<StepDataValues>>): Record<string, DateTime> {
        return Object.entries(groups).reduce<Record<string, DateTime>>((acc, [stepId, group]) => {
            return { ...acc, [stepId]: group.dateTime };
        }, {});
    }

    private getAssigneesFromGroups(
        groups: Record<string, Partial<StepDataValues>>,
        workflowSteps: WorkflowSteps,
    ): Record<string, string[]> {
        return Object.entries(groups).reduce<Record<string, string[]>>((acc, [stepId, group]) => {
            const assigneeExtension = AssigneeExtensionHelpers.getAssigneeExtension(workflowSteps[stepId].extensions);
            const resolvedInput = WorkflowCoreExtensionService.findResolvedInput(assigneeExtension?.inputs ?? []);
            const resolved = resolvedInput?.resolved ?? false;

            if (resolved) {
                return acc;
            } else {
                return { ...acc, [stepId]: group.assignees };
            }
        }, {});
    }

    private resetWorkflowControl(): void {
        this.editWorkflowControl.setValue({
            calculateFromDate: null,
            datesCalculationPoint: this.editWorkflowControl.value.datesCalculationPoint,
        });
        this.editWorkflowControl.markAsPristine();
        this.editWorkflowControl.markAsUntouched();
    }
}
