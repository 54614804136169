import { Pipe, PipeTransform } from "@angular/core";
import { IParticipant } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { AuthService } from "../../findex-auth";
import { UserToNamePipe } from "./user-to-name";

@Pipe({ name: "listOtherParticipants" })
export class ListOtherParticipantsPipe implements PipeTransform {
    private userId$: Observable<string>;

    constructor(
        private authService: AuthService,
        private userToNamePipe: UserToNamePipe,
    ) {
        this.userId$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.id),
            shareReplay(1),
        );
    }

    transform(participants: IParticipant[]): Observable<string> {
        if (!participants) {
            return null;
        }

        return this.userId$.pipe(
            take(1),
            map((userId) => participants.filter((user) => user.id !== userId)),
            switchMap((filteredParticipants) => this.toNames(filteredParticipants)),
        );
    }

    private async toNames(participants: IParticipant[]): Promise<string> {
        const firstTwo = participants.slice(0, 2);
        const remaining = participants.length - firstTwo.length;

        const names = await Promise.all(
            firstTwo.map((participant) => this.userToNamePipe.transform(participant.id).toPromise()),
        );

        const displayText = names.filter((name) => !!name).join(", ");
        if (remaining > 0) {
            return `${displayText}, +${remaining}`;
        } else {
            return displayText;
        }
    }
}
