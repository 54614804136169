import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginated } from "@visoryplatform/datastore-types";
import {
    CardHelper,
    CardReply,
    CardStatus,
    CopilotTransactionsState,
    IThreadCard,
    IVaultListItem,
    IVaultRequestCardState,
    VaultCardTypes,
} from "@visoryplatform/threads";
import { VAULT_ACTION } from "@visoryplatform/vault";
import { IVaultItem } from "projects/default-plugins/vault/interfaces/IVaultItem";
import { IVaultItemFile } from "projects/default-plugins/vault/interfaces/IVaultItemFile";
import { IVaultState } from "projects/default-plugins/vault/interfaces/IVaultState";
import { combineLatest, concat, forkJoin, Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { VaultRequestService } from "../../../../../default-plugins/vault/services/vault-request.service";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { Loader } from "../../shared/services/loader";
import { ThreadsWebsocketService } from "../../shared/services/threads-websocket.service";
import { CardStateResponse } from "../interfaces/CardStateResponse";
import { ParticipantCache } from "./participant-cache.service";
import { ThreadCardService } from "./thread-card.service";

export type VaultDocument = {
    card: IThreadCard;
    actorId: string;
    timestamp: string;
    title: string;
    category: string;
    vaultId: string;
    fileId: string;
    filename: string;
    isRfi?: boolean;
    signable?: boolean;
    signed?: boolean;
    signer?: string;
    signedOn?: string;
};
export type VaultRequestRow = {
    title: string;
    createdBy: string;
    progress: number;
    status: string;
    cardReplies: CardReply[];
    card: IThreadCard;
    state: IVaultRequestCardState;
};

@Injectable({ providedIn: "root" })
export class ThreadsVaultService {
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private cardService: ThreadCardService,
        private websocketService: ThreadsWebsocketService,
        private http: HttpClient,
        private participantCache: ParticipantCache,
    ) {}

    getDocumentList(threadId: string, loader: Loader): Observable<VaultDocument[]> {
        const cards$ = this.cardService.getCards(threadId);

        return loader.wrap(cards$).pipe(switchMap((cards) => this.getVaultDocuments(threadId, cards, loader)));
    }

    getRequestList(threadId: string, loader: Loader): Observable<VaultRequestRow[]> {
        const cards$ = this.cardService.getCards(threadId);
        return loader.wrap(cards$).pipe(switchMap((cards) => this.getRequestRows(threadId, cards, loader)));
    }

    async enrichVaultListWithActorProfile(vaultList: IVaultListItem[]) {
        return await Promise.all(
            vaultList.map(async (vaultItem) => {
                const actorProfile = await this.participantCache.getParticipant(vaultItem.actorId).toPromise();
                return {
                    actorName: actorProfile.profile.name || actorProfile.name || "Deleted",
                    ...vaultItem,
                };
            }),
        );
    }

    getListAllVaults(userId = "me"): Observable<IVaultListItem[]> {
        return this.listVaults(userId).pipe(
            switchMap((firstPage) => {
                const totalPages = firstPage.total / firstPage.limit;
                const otherPages: Observable<IPaginated<IVaultListItem>>[] = [];
                const startingPage = of(firstPage);

                if (!firstPage?.next) {
                    return forkJoin([startingPage]);
                }

                const nextPage = Number(firstPage.next);

                for (let currentPage = nextPage; currentPage <= totalPages; currentPage++) {
                    const pageNo = currentPage.toString();
                    otherPages.push(this.listVaults(userId, pageNo, firstPage.limit));
                }

                return forkJoin([startingPage, ...otherPages]);
            }),
            map((pages) => pages.map((page) => page.result).flat()),
            switchMap((vaultList) => this.enrichVaultListWithActorProfile(vaultList)),
        );
    }

    listVaults(userId = "me", next?: string, limit = 100): Observable<IPaginated<IVaultListItem>> {
        const { base } = this.environment.commonEndpoints;
        const baseUrl = `${base}/vault/user/${userId}`;

        const queryParams = { next, limit: limit?.toString() };
        const url = this.appendQuery(queryParams, baseUrl);

        return this.http.get<IPaginated<IVaultListItem>>(url);
    }

    private isValidRequestCard(type: string, status: CardStatus): boolean {
        return (
            (type === "vault-request" || type === "vault-request-approval-payrun") &&
            status !== CardStatus.Removed &&
            status !== CardStatus.Deleted
        );
    }

    private getRequestRows(threadId: string, cards: IThreadCard[], loader: Loader): Observable<VaultRequestRow[]> {
        const requestCards = cards.filter((card) => this.isValidRequestCard(card.type, card.status));
        if (requestCards?.length === 0) {
            return of([]);
        }
        const rows$ = requestCards.map((card) => this.getRequestRow(threadId, card, loader));

        return combineLatest(rows$).pipe(map((cardRequest) => [].concat(...cardRequest)));
    }

    private getRequestRow(threadId: string, card: IThreadCard, loader: Loader): Observable<Partial<VaultRequestRow>> {
        return this.getStateUpdates(threadId, card, loader).pipe(
            map((state) => this.mapRequestRow(card, state.cardReplies, state.state)),
        );
    }

    private getVaultDocuments(threadId: string, cards: IThreadCard[], loader: Loader): Observable<VaultDocument[]> {
        const documentCards = cards.filter((card) => this.isVaultCard(card) && CardHelper.isCardActive(card.status));
        if (documentCards?.length === 0) {
            return of([]);
        }

        const documents$ = documentCards.map((card) => this.getCardDocuments(threadId, card, loader));

        return combineLatest(documents$).pipe(map((cardDocuments) => [].concat(...cardDocuments)));
    }

    private isVaultCard(card: IThreadCard): boolean {
        return (
            card.type === VaultCardTypes.Vault ||
            card.type === VaultCardTypes.Request ||
            card.type === VaultCardTypes.TransactionCard
        );
    }

    private getStateUpdates<cardState = any>(
        threadId: string,
        card: IThreadCard,
        loader: Loader,
    ): Observable<CardStateResponse<cardState>> {
        const state$ = loader.wrap(this.cardService.getCardState<cardState>(threadId, card.id));

        const stateUpdates$ = this.websocketService.watchCardId(threadId, card.id).pipe(
            filter((notification) => notification.state),
            switchMap(() => loader.wrap(this.cardService.getCardState<cardState>(threadId, card.id))),
        );

        return concat(state$, stateUpdates$);
    }

    private getCardDocuments(threadId: string, card: IThreadCard, loader: Loader): Observable<VaultDocument[]> {
        return this.getStateUpdates(threadId, card, loader).pipe(
            map((state) => this.stateToDocuments(card, state && state.state)),
        );
    }

    private mapVaultDocuments(card: IThreadCard, state: IVaultState): VaultDocument[] {
        if (!state || !state.groups) {
            return [];
        }

        const documents: VaultDocument[] = [];

        for (const group of state.groups) {
            for (const item of group.items) {
                const itemDocs = this.groupItemToDocuments(group.displayName, card, item);
                documents.push(...itemDocs);
            }
        }

        return documents;
    }

    private mapRequestRow(card: IThreadCard, cardReplies: CardReply[], state: IVaultRequestCardState): VaultRequestRow {
        const { actionedPercentage } = VaultRequestService.calculateProgress(state.requestItems);
        return {
            title: state.title,
            createdBy: card.createdBy,
            progress: actionedPercentage,
            status: actionedPercentage === 100 ? "Complete" : "Pending",
            state,
            card,
            cardReplies,
        };
    }

    private mapRequestFileData(
        card: IThreadCard,
        actorId: string,
        timestamp: string,
        vaultId: string,
        fileId: string,
        filename: string,
    ): VaultDocument {
        return {
            card,
            actorId,
            timestamp,
            title: filename,
            category: "Request",
            vaultId,
            fileId,
            filename,
            isRfi: true,
            signable: false,
            signed: false,
            signer: null,
            signedOn: null,
        };
    }

    private mapTransactionsFileData(
        card: IThreadCard,
        actorId: string,
        timestamp: string,
        vaultId: string,
        fileId: string,
        filename: string,
    ): VaultDocument {
        return {
            card,
            actorId: actorId || "system",
            timestamp,
            title: filename,
            category: "Request",
            vaultId,
            fileId,
            filename,
            isRfi: true,
            signable: false,
            signed: false,
            signer: null,
            signedOn: null,
        };
    }

    private buildAttachmentDocuments(card: IThreadCard, state: IVaultRequestCardState): VaultDocument[] {
        return state?.attachments?.data
            ?.map((requestFileData) =>
                this.mapRequestFileData(
                    card,
                    requestFileData.actorId,
                    requestFileData.timestamp,
                    state.vaultId,
                    state.attachments.fileId,
                    requestFileData.filename,
                ),
            )
            .filter((document) => !!document);
    }

    private buildRequestItemDocuments(card: IThreadCard, state: IVaultRequestCardState): VaultDocument[] {
        return state?.requestItems
            .map((requestItem) =>
                requestItem?.response?.data?.state?.map((requestFileData) =>
                    this.mapRequestFileData(
                        card,
                        requestFileData.actorId,
                        requestFileData.timestamp,
                        state.vaultId,
                        requestItem.fileId,
                        requestFileData.filename,
                    ),
                ),
            )
            .reduce((accumulator, value) => accumulator.concat(value), [])
            .filter((document) => !!document);
    }

    private buildTransactionsDocuments(card: IThreadCard, state: CopilotTransactionsState): VaultDocument[] {
        if (!state?.transactions) {
            return [];
        }

        return state.transactions
            .filter((transactions) => transactions.query?.vaultDetails?.filename)
            .map((requestFileData) => {
                const { createdBy, createdAt, vaultDetails } = requestFileData.query;
                const { filename, fileId, vaultId } = vaultDetails;
                return this.mapTransactionsFileData(card, createdBy, createdAt, vaultId, fileId, filename);
            });
    }

    private mapRequestDocuments(card: IThreadCard, state: IVaultRequestCardState): VaultDocument[] {
        const attachmentDocuments = this.buildAttachmentDocuments(card, state) || []; // handle broken cards on dev
        const requestItemDocuments = this.buildRequestItemDocuments(card, state);
        return [...attachmentDocuments, ...requestItemDocuments];
    }

    private mapTransactionsDocuments(card: IThreadCard, state: CopilotTransactionsState): VaultDocument[] {
        const attachmentDocuments = this.buildTransactionsDocuments(card, state);

        if (!attachmentDocuments?.length) {
            return []; // handle broken cards on dev
        }

        return [...attachmentDocuments];
    }

    private stateToDocuments(card: IThreadCard, state: any): VaultDocument[] {
        if (card.type === VaultCardTypes.Vault) {
            return this.mapVaultDocuments(card, state);
        } else if (card.type === VaultCardTypes.Request) {
            return this.mapRequestDocuments(card, state);
        } else if (card.type === VaultCardTypes.TransactionCard) {
            return this.mapTransactionsDocuments(card, state);
        }
        console.warn("Unknown vault card type " + card.type);
        return [];
    }

    private groupItemToDocuments(category: string, card: IThreadCard, item: IVaultItem): VaultDocument[] {
        if (!item || !item.files) {
            return [];
        }
        return item.files.map((file) => {
            const { vaultId, fileId, signed, signedOn, signer, actions } = item;
            const { filename, actorId, timestamp } = file;

            const signable = actions.includes(VAULT_ACTION.Sign);
            const isRfi = actions.includes(VAULT_ACTION.Rfi);
            const title = this.getDocumentTitle(file);

            return {
                card,
                actorId,
                timestamp,
                title,
                vaultId,
                fileId,
                filename,
                category,
                isRfi,
                signed,
                signable,
                signedOn,
                signer,
            };
        });
    }

    private getDocumentTitle(file: IVaultItemFile): string {
        const { filename } = file;
        return filename;
    }

    private appendQuery(filterParams: Record<string, string>, baseUrl: string) {
        if (!filterParams) {
            return baseUrl;
        }

        //Was going to replace with straight URLSearchParams,
        //but it encodes spaces differently, so sticking with this for now
        const params = Object.entries(filterParams)
            .filter(([, value]) => value != null)
            .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`);

        return `${baseUrl}?${params.join("&")}`;
    }
}
