import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { ITimeline, Role } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { CREATE_CARD_LIBRARY } from "src/app/injection-token";
import { GA_EVENTS } from "../../../analytics";
import { CreateCardExtension, ExtensionEntry, ILibrary } from "../../../plugins";
import { MenuService, MenuType } from "../../../shared/services/menu.service";

export interface ICreateCardEvent {
    component?: any;
    type?: string;
    thread?: ITimeline;
    extensionConfig?: { extensionId: string };
    data?: any;
    config?: MatDialogConfig;
    disableEmails?: boolean;
    isInternal?: boolean;
}

@Component({
    selector: "create-card",
    templateUrl: "./create-card.component.html",
    styleUrls: ["./create-card.component.scss"],
})
export class CreateCardComponent implements OnChanges {
    @Input() role: Role;
    @Input() globalRole: Role;
    @Input() showLoader: boolean;
    @Input() restrictCardsToInternal: boolean;
    @Output() newCard = new EventEmitter<ICreateCardEvent>();

    readonly gaEvents = GA_EVENTS;
    roles = Role;

    cardExtensions: ExtensionEntry<CreateCardExtension>[];
    showThreadsMenu$: Observable<boolean>;

    constructor(
        @Inject(CREATE_CARD_LIBRARY) private createCardExtensions: ILibrary<CreateCardExtension>,
        private menuService: MenuService,
    ) {
        this.showThreadsMenu$ = menuService.showThreadsMenu();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.restrictCardsToInternal) {
            this.cardExtensions = this.createCardExtensions
                .listAll()
                .filter((extension) => !this.restrictCardsToInternal || !extension.extension.disableInternalCreation);
        }
    }

    addCard(component: any, data: any, config: any): void {
        this.toggleContextMenu();

        //TODO: shouldn't extend the existing object as it might overwrite properties. This is for an urgent hotfix
        //TODO: enrich with thread too. or convert to injection tokens
        const cardCreateData = {
            ...data,
            role: this.role,
        };

        const event: ICreateCardEvent = {
            component,
            data: cardCreateData,
            config,
        };

        this.newCard.emit(event);
    }

    toggleContextMenu(): void {
        this.menuService.toggle(MenuType.ThreadsMenu);
    }
}
