import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, forwardRef } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";

import { IWorkflowDesignType } from "@visoryplatform/threads";
import { Subscription } from "rxjs";
import { CreateWorkflowModalService } from "../../../../services/create-workflow-modal.service";
import { ThreadDatesService } from "../../../../services/thread-dates.service";

type WorkflowTitleForm = {
    title: FormControl<string>;
};

type SelectWorkflowTokenChange = (obj: string) => void;

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditWorkflowTitleComponent),
    multi: true,
};

const VALIDATORS = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EditWorkflowTitleComponent),
    multi: true,
};

@Component({
    selector: "edit-workflow-title",
    templateUrl: "./edit-workflow-title.component.html",
    styleUrls: ["./edit-workflow-title.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR, VALIDATORS],
})
export class EditWorkflowTitleComponent implements ControlValueAccessor, Validator, OnChanges, OnDestroy, OnInit {
    @Input() selectedDesignType: IWorkflowDesignType;

    readonly CUSTOM_TITLE_MAX_LENGTH = 20;

    workflowMonths: string[];
    requiresCustomMonth = false;
    requiresCustomTitle = false;

    form: FormGroup;
    formSub: Subscription;

    onChange?: (obj: string) => void;
    onTouch?: () => void;
    validatorFn: () => void;

    constructor(
        private threadDatesService: ThreadDatesService,
        private createWorkflowModalService: CreateWorkflowModalService,
    ) {
        this.form = this.buildForm();

        this.formSub = this.form.valueChanges.subscribe((value) => {
            this.onTouch?.();
            this.onChange?.(value.title);
        });
    }

    ngOnInit(): void {
        this.workflowMonths = this.threadDatesService.generateMonths();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { selectedDesignType } = changes;

        if (selectedDesignType?.currentValue) {
            this.initSelectedDesign(selectedDesignType.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe();
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.validatorFn = fn;
    }

    writeValue(title: string): void {
        this.form.controls.title.setValue(title);
    }

    validate(_control: AbstractControl<unknown, unknown>): ValidationErrors | null {
        // Only checking max length of custom title when required
        if (!this.requiresCustomTitle) {
            return null;
        }

        return this.form.controls.title.errors ? { invalid: true } : null;
    }

    registerOnChange(fn: SelectWorkflowTokenChange): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private buildForm(): FormGroup<WorkflowTitleForm> {
        return new FormGroup<WorkflowTitleForm>({
            title: new FormControl<string | null>(null, [
                Validators.required,
                Validators.maxLength(this.CUSTOM_TITLE_MAX_LENGTH),
            ]),
        });
    }

    private initSelectedDesign(selectedDesignType: IWorkflowDesignType): void {
        this.requiresCustomTitle = this.createWorkflowModalService.worflowRequiresCustomTitle(selectedDesignType);
        this.requiresCustomMonth = !!selectedDesignType?.requiresCustomMonth;

        if (this.requiresCustomMonth) {
            this.form.reset();
        }
    }
}
