import { Component, Inject, OnDestroy, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TabsItemComponent } from "@visoryplatform/fx-ui";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { ILibrary, RouteExtension, RouteHelper, VisibleExtension } from "projects/portal-modules/src/lib/plugins";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable, Subscription, combineLatest } from "rxjs";
import { distinctUntilChanged, filter, map, startWith, switchMap } from "rxjs/operators";
import { INSIGHTS_ROUTE_LIBRARY } from "src/app/injection-token";

@Component({
    selector: "app-insights-route",
    templateUrl: "./insights-route.component.html",
    styleUrls: ["./insights-route.component.scss"],
})
export class InsightsRouteComponent implements OnDestroy {
    @ViewChildren(TabsItemComponent) set tabs(tabs: QueryList<TabsItemComponent>) {
        if (tabs) {
            this.initTabs(tabs.toArray());
        }
    }

    gaEvents = GA_EVENTS;
    loader = new Loader();
    extensions$: Observable<VisibleExtension[]>;
    private routeSub: Subscription;
    private fxTabSkip = false;

    constructor(
        @Inject(INSIGHTS_ROUTE_LIBRARY) library: ILibrary<RouteExtension>,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.extensions$ = RouteHelper.getVisibleExtensions(library);
    }

    async navigateTo(path: string): Promise<void> {
        //Hack around fx-tabs, like the other in HTML
        if (this.fxTabSkip) {
            this.fxTabSkip = false;
            return;
        }
        await this.router.navigate([path], { relativeTo: this.route });
    }

    //See HTML comment, one big hack, should be able to remove this with eco-ui
    initTabs(tabs: TabsItemComponent[]): void {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }

        const navigationEnd$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.route.firstChild),
            startWith(this.route.firstChild),
        );

        const path$ = navigationEnd$.pipe(
            filter((child) => !!child),
            switchMap((child) => child.url),
            map((url) => url.find((segment) => !!segment)?.path),
            distinctUntilChanged(),
        );

        this.routeSub = combineLatest([this.extensions$, path$]).subscribe(([extensions, path]) =>
            Promise.resolve().then(() => this.setTab(extensions, tabs, path)),
        );
    }

    ngOnDestroy() {
        this.routeSub?.unsubscribe();
    }

    private setTab(extensions: VisibleExtension[], tabs: TabsItemComponent[], path: string): void {
        if (extensions) {
            const matchedExtension = extensions?.find((extension) => extension.path === path);
            for (const tab of tabs) {
                this.fxTabSkip = true;
                tab.setActive(tab.header === matchedExtension?.label);
            }
        }
    }
}
