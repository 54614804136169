<payrun-summary
    [payRunReport]="report"
    [currency]="currency"
></payrun-summary>

<form
    *ngIf="form"
    [formGroup]="form"
>
    <ng-container formGroupName="requestItems">
        <div class="threads table-container">
            <fx-table
                [tableData]="tableData"
                [trackBy]="'employeeId' | trackProperty"
                [enableFooter]="true"
                [stickyHeader]="true"
                [enableExpandAll]="true"
                (expandClick)="expandLineClick()"
                (expandAllClick)="expandAllClick()"
                class="background-porcelain"
            >
                <ng-container
                    *fxColumn="
                        'employeeName';
                        label: tableHeaders.Employee;
                        mobileCollapse: true;
                        sortable: true;
                        expandable: true;
                        type: 'string';
                        mobileGrid: true;
                        hjSupressData: false;
                        let employeeLine
                    "
                >
                    <div class="payrun-employee">
                        <span class="employee-name"> {{ employeeLine.employeeName || "-" }} </span>
                    </div>
                </ng-container>
                <ng-container
                    *fxColumn="
                        'totalHours';
                        label: tableHeaders.TotalHours;
                        mobileCollapse: true;
                        alignRight: true;
                        sortable: true;
                        info: INFO_TEXT.TotalHours;
                        type: 'decimal';
                        mobileGrid: true;
                        hjSupressData: true;
                        let employeeLine
                    "
                >
                    <div class="payrun-col">
                        <span class="mobile-cell-label">{{ tableHeaders.TotalHours }}: </span>
                        <span class="tertiary"> {{ (employeeLine.totalHours | number: "1.2-2") || "-" }} </span>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'grossEarnings';
                        label: tableHeaders.GrossEarnings;
                        sortable: true;
                        mobileCollapse: true;
                        alignRight: true;
                        info: INFO_TEXT.GrossEarnings;
                        type: 'currency';
                        mobileGrid: true;
                        hjSupressData: true;
                        currency: currency;
                        let employeeLine
                    "
                >
                    <div class="payrun-col">
                        <span class="mobile-cell-label">{{ tableHeaders.GrossEarnings }}: </span>
                        <span class="tertiary">
                            {{ (employeeLine.grossEarnings | currency: (employeeLine.currency | uppercase)) || "-" }}
                        </span>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'taxWithHeld';
                        label: tableHeaders.Tax;
                        sortable: true;
                        mobileCollapse: true;
                        alignRight: true;
                        info: INFO_TEXT.TaxWithheld;
                        type: 'currency';
                        mobileGrid: true;
                        hjSupressData: true;
                        currency: currency;
                        let employeeLine
                    "
                >
                    <div class="payrun-col">
                        <span class="mobile-cell-label">{{ tableHeaders.Tax }}: </span>
                        <span class="tertiary">
                            {{ (employeeLine.taxWithHeld | currency: (employeeLine.currency | uppercase)) || "-" }}
                        </span>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        isNzPayrun ? 'totalStudentLoanAmount' : 'stsl';
                        label: isNzPayrun ? tableHeaders.StudentLoan : tableHeaders.STSL;
                        sortable: true;
                        mobileCollapse: true;
                        alignRight: true;
                        info: isNzPayrun ? INFO_TEXT.StudentLoan : INFO_TEXT.Stsl;
                        type: 'currency';
                        mobileGrid: true;
                        hjSupressData: true;
                        currency: currency;
                        let employeeLine
                    "
                >
                    <div class="payrun-col">
                        <span class="mobile-cell-label"
                            >{{ isNzPayrun ? tableHeaders.StudentLoan : tableHeaders.STSL }}:
                        </span>
                        <span
                            class="tertiary"
                            *ngIf="!isNzPayrun"
                        >
                            {{ (employeeLine.stsl | currency: (employeeLine.currency | uppercase)) || "-" }}
                        </span>
                        <span
                            class="tertiary"
                            *ngIf="isNzPayrun"
                        >
                            {{
                                (employeeLine.totalStudentLoanAmount | currency: (employeeLine.currency | uppercase)) ||
                                    "-"
                            }}
                        </span>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        isNzPayrun ? 'totalKiwiSaverPaymentsAmount' : 'super';
                        label: isNzPayrun ? tableHeaders.KiwiSaver : tableHeaders.SuperGuarantee;
                        sortable: true;
                        mobileCollapse: true;
                        alignRight: true;
                        info: isNzPayrun ? INFO_TEXT.KiwiSaver : INFO_TEXT.SuperGuarantee;
                        type: 'currency';
                        mobileGrid: true;
                        hjSupressData: true;
                        currency: currency;
                        let employeeLine
                    "
                >
                    <div class="payrun-col">
                        <span class="mobile-cell-label"
                            >{{ isNzPayrun ? tableHeaders.KiwiSaver : tableHeaders.SuperGuarantee }}:
                        </span>
                        <span
                            class="tertiary"
                            *ngIf="!isNzPayrun"
                        >
                            {{ (employeeLine.super | currency: (employeeLine.currency | uppercase)) || "-" }}
                        </span>
                        <span
                            class="tertiary"
                            *ngIf="isNzPayrun"
                        >
                            {{
                                (employeeLine.totalKiwiSaverPaymentsAmount
                                    | currency: (employeeLine.currency | uppercase)) || "-"
                            }}
                        </span>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'netEarnings';
                        label: tableHeaders.NetEarnings;
                        sortable: true;
                        mobileCollapse: true;
                        alignRight: true;
                        info: INFO_TEXT.NetEarnings;
                        type: 'currency';
                        mobileGrid: true;
                        hjSupressData: true;
                        currency: currency;
                        let employeeLine
                    "
                >
                    <div class="payrun-col">
                        <span class="mobile-cell-label">{{ tableHeaders.NetEarnings }}: </span>
                        <span class="tertiary">
                            {{ (employeeLine.netEarnings | currency: (employeeLine.currency | uppercase)) || "-" }}
                        </span>
                    </div>
                </ng-container>

                <ng-container *ngIf="allowEdit && form?.controls?.requestItems as requestItems">
                    <ng-container
                        *fxColumn="
                            'approve';
                            label: tableHeaders.Approve;
                            sortable: true;
                            mobileCollapse: true;
                            alignRight: true;
                            mobileGrid: true;
                            hjSupressData: true;
                            let employeeLine
                        "
                    >
                        <div class="payrun-col">
                            <span class="mobile-cell-label">{{ tableHeaders.Approve }}: </span>
                            <div
                                class="table-checkbox"
                                [formGroupName]="employeeLine.employeeId"
                            >
                                <fx-checkbox
                                    *ngIf="requestItems.controls[employeeLine.employeeId] as employeeControl"
                                    class="todo-item-green-checkbox"
                                    [disabled]="readonly"
                                    [checked]="employeeControl.value.completed"
                                    (change)="updateValue(employeeControl, $event)"
                                >
                                </fx-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *fxRowChild="let row">
                    <payrun-expanded-details
                        [data]="row"
                        [isNZPayrun]="isNzPayrun"
                        (viewType)="toggleViewType($event)"
                    ></payrun-expanded-details>
                </ng-container>
            </fx-table>
        </div>
        <div
            *ngIf="allowEdit"
            class="table-footer-buttons"
        >
            <button
                [disabled]="readonly || payrunAllApproved"
                type="button"
                (click)="approvalAll()"
            >
                Approve all
            </button>
        </div>
    </ng-container>
</form>
