import { Component, computed, input } from "@angular/core";
import { calculateWorkflowPriorityWeight } from "@visoryplatform/threads";
import { IWorkflow } from "@visoryplatform/workflow-core";

@Component({
    selector: "workflow-priority-debug",
    standalone: true,
    template: `<div>{{ priority() }}</div>`,
})
export class WorkflowPriorityDebugComponent {
    workflow = input.required<IWorkflow>();

    priority = computed(() => {
        const workflow = this.workflow();

        if (!workflow?.currentStepId) {
            return "N/A";
        }

        const priority = calculateWorkflowPriorityWeight(workflow, workflow.currentStepId);
        return priority?.toString() ?? "N/A";
    });
}
