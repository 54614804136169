import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FindexUiModule } from "../../../findex-ui";
import { PluginsModule } from "../../../plugins";
import { TaskListItemComponent } from "./components/task-list-item/task-list-item.component";
import { ThreadsTaskComponent } from "./components/threads-task/threads-task.component";

const declarations = [ThreadsTaskComponent, TaskListItemComponent];

@NgModule({
    imports: [CommonModule, PluginsModule, FindexUiModule],
    exports: [...declarations],
    declarations: [...declarations],
})
export class ThreadTasksModule {}
