import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";
import { environmentCommon } from "../../../../../../src/environments/environment";

@Pipe({
    name: "todayDate",
})
export class TodayDatePipe implements PipeTransform {
    datePipe = new DatePipe("en-US");

    readonly dateFormat = environmentCommon?.dateFormats;

    transform(date: string | Date | DateTime, format: string): string {
        try {
            const dateTime = this.getDateTime(date);
            const isToday = dateTime.hasSame(DateTime.local(), "day");
            const shortFormat = this.dateFormat?.short || "h:mm a";
            const shortDate = this.datePipe.transform(dateTime.toJSDate(), shortFormat);

            return isToday ? "Today " + shortDate : this.datePipe.transform(dateTime.toJSDate(), format);
        } catch {
            console.error(`Could not convert ${JSON.stringify(date)} to DateTimeObject in TodayDatePipe`);

            return "";
        }
    }

    private getDateTime(date: string | Date | DateTime): DateTime {
        if (typeof date === "string") {
            return DateTime.fromISO(date);
        }
        if (date instanceof Date) {
            return DateTime.fromJSDate(date);
        }
        return date;
    }
}
