import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AlertMessage, AlertService } from "@visoryplatform/portal-ui";
import {
    CardStatus,
    IFileUploadResponse,
    IThreadCard,
    ITimeline,
    IUpdatedRequestItems,
    IVaultRequestCardState,
} from "@visoryplatform/threads";
import { ISignedRequest, VaultService } from "@visoryplatform/vault";
import { retryBackoff } from "backoff-rxjs";
import { GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { ErrorMessagesConstants } from "projects/portal-modules/src/lib/shared/constants/error-messages.constants";
import { ToastKeys, ToastSeverity } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { ErrorService } from "projects/portal-modules/src/lib/shared/services/error.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { forkJoin, Observable, of } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "../../../../src/app/injection-token";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "../../../portal-modules/src/lib/environment/environment.common";
import {
    InstructionsRequestCompleteModalData,
    InstructionsRequestSkippedModalData,
    PayRunRequestCompleteRequestModalData,
    PayrunRequestReviewModalData,
    RequestCompleteModalData,
    RfiRequestReviewModalData,
    VaultCardType,
} from "../components/request/constants/request.constants";
import { IRequestAnalyticsTags } from "../components/request/interfaces/IRequestAnalyticsTags";
import { IRequestConfirmDialogParams } from "../components/request/interfaces/IRequestConfirmDialogParams";
import { IRequestConfirmDialogText } from "../components/request/interfaces/IRequestConfirmDialogText";
import {
    IRequestForm,
    IRequestItemFormGroup,
    RequestItemFormControlValue,
} from "../components/request/interfaces/IRequestForms";
import { IUpdatedRequestState } from "../components/request/interfaces/IUpdatedRequestState";
import { IsCompleteModalRfi } from "../components/request/interfaces/request.interfaces";
import { VaultCardService } from "../components/vault-card/services/vault-card.service";

type RequestProgress = {
    actionedPercentage: number;
    actionedRequestItems: number;
    requestItemTextResponses: number;
    requestItemUploadResponses: number;
};

type RequestCardEventPayload = {
    body: { isReview?: boolean; isSkipped?: boolean; isCompleted?: boolean; saved?: boolean };
};

export type CreateVaultBankFileRequestCardPayload = {
    accountId: string;
    bankFileSettingId: string;
    payrunId: string | number;
    requestItems: { description: string }[];
    thread: ITimeline;
    type: VaultCardType;
};

export enum RequestAttachmentType {
    Rfi = "rfi",
    Attachment = "attachment",
}

@Injectable({ providedIn: "root" })
export class VaultRequestService {
    constructor(
        private vaultService: VaultService,
        private http: HttpClient,
        private errorService: ErrorService,
        private cardService: ThreadCardService,
        private alertService: AlertService,
        private vaultCardService: VaultCardService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    static isRequestComplete(requestItems: RequestItemFormControlValue[]): boolean {
        return requestItems.every((requestItem) => requestItem.response?.complete?.state === true);
    }

    static calculateProgress(requestItems: RequestItemFormControlValue[]): RequestProgress {
        const numRequestItems = requestItems?.length;
        if (!numRequestItems) {
            return {
                actionedPercentage: 0,
                actionedRequestItems: 0,
                requestItemTextResponses: 0,
                requestItemUploadResponses: 0,
            };
        }

        const isActionedItem = (item: RequestItemFormControlValue): boolean => {
            return !!item.response?.complete?.state;
        };
        const reduceUploadResponses = (prev: number, item: RequestItemFormControlValue): number => {
            return prev + item.response?.data?.state?.length;
        };
        const reduceTextResponses = (prev: number, item: RequestItemFormControlValue): number => {
            return prev + (item.response?.text?.state !== null && 1);
        };

        const actionedRequestItems = requestItems.filter(isActionedItem).length;
        const requestItemTextResponses = requestItems.reduce(reduceTextResponses, 0);
        const requestItemUploadResponses = requestItems.reduce(reduceUploadResponses, 0);
        const actionedPercentage = Math.floor((actionedRequestItems / numRequestItems) * 100);

        return {
            actionedPercentage,
            actionedRequestItems,
            requestItemTextResponses,
            requestItemUploadResponses,
        };
    }

    async uploadRequestAttachments(
        threadId: string,
        cardId: string,
        vaultId: string,
        fileId: string,
        attachments: File[],
        type: RequestAttachmentType,
    ): Promise<void> {
        if (!attachments?.length) {
            return null;
        }

        const filenames = attachments.map((file) => file.name);

        const attachmentUploadResponse = await this.addRequestAttachments(
            threadId,
            cardId,
            vaultId,
            fileId,
            filenames,
            type,
        ).toPromise();

        const signedFiles = attachments.map((attachment) => ({
            file: attachment,
            signedUrl: this.getSignedUrl(attachmentUploadResponse, attachment),
        }));

        try {
            await Promise.all(signedFiles.map((signedFile) => this.uploadFile(signedFile)));
        } catch (err) {
            await this.cardService.deleteCard(threadId, cardId).toPromise();
            throw err;
        }
    }

    async uploadFile(signedFile: { file: File; signedUrl: ISignedRequest }): Promise<void> {
        try {
            await this.vaultService
                .upload(signedFile.signedUrl, signedFile.file)
                .pipe(
                    retryBackoff({
                        initialInterval: 1000,
                        maxRetries: 4,
                    }),
                )
                .toPromise();
        } catch {
            const file = signedFile?.file;
            if (file) {
                this.showErrorToast(signedFile?.file);
                this.errorService.logUploadFileError(file, ErrorMessagesConstants.AttachmentsFailed);
            }
            const error = new Error(ErrorMessagesConstants.CardDeletedDueToUpload);
            throw error;
        }
    }

    downloadRequestAttachment(url: string): Observable<unknown> {
        return this.http.get<unknown>(url);
    }

    createVaultBankFileRequestCard(threadId: string, payload: CreateVaultBankFileRequestCardPayload): Observable<void> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request-bank-file`;
        return this.http.post<void>(url, { data: payload });
    }

    addRequestItems(
        threadId: string,
        cardId: string,
        vaultId: string,
        requestItems: { description: string }[],
    ): Observable<void> {
        if (!requestItems?.length) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/item`;
        const body = { requestItems };
        return this.http.post<void>(url, body);
    }

    deleteRequestFiles(
        threadId: string,
        cardId: string,
        vaultId: string,
        files: { fileId: string; filenames: string[] }[],
    ): Observable<void> {
        if (!files?.length) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/files`;
        const options = {
            headers: new HttpHeaders({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                "Content-Type": "application/json",
            }),
            body: { files },
        };
        return this.http.delete<void>(url, options);
    }

    removeRequestItems(threadId: string, cardId: string, vaultId: string, fileIds: string[]): Observable<void> {
        if (!fileIds?.length) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/item`;
        const options = {
            headers: new HttpHeaders({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                "Content-Type": "application/json",
            }),
            body: { fileIds },
        };
        return this.http.delete<void>(url, options);
    }

    sendRequestCardEvent(
        threadId: string,
        cardId: string,
        type: string,
        payload: RequestCardEventPayload,
    ): Observable<void> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/threads/${threadId}/cards/${cardId}/events`;
        const body = { type, payload, description: "" };
        return this.http.post<void>(url, body);
    }

    async uploadRequestResponse(
        threadId: string,
        cardId: string,
        vaultId: string,
        updatedRequestItems: IUpdatedRequestItems[],
        requestTitle?: string,
        file?: File,
    ): Promise<void> {
        const fileUploadDetails = await this.updateRequestItems(
            threadId,
            cardId,
            vaultId,
            updatedRequestItems,
            requestTitle,
        ).toPromise();
        if (file && fileUploadDetails) {
            const fileUpload = {
                file,
                signedUrl: fileUploadDetails.find((uploadResponse) => uploadResponse.filename === file.name)
                    .signedRequest,
            };
            await this.vaultService.upload(fileUpload.signedUrl, fileUpload.file).toPromise();
        }
    }

    async updateRequest(
        updatedRequestItems: IUpdatedRequestItems[],
        cardId: string,
        threadId: string,
        vaultId: string,
        file?: File,
    ): Promise<void> {
        return this.uploadRequestResponse(threadId, cardId, vaultId, updatedRequestItems, null, file);
    }

    updateRequestItems(
        threadId: string,
        cardId: string,
        vaultId: string,
        updatedRequestItems: IUpdatedRequestItems[],
        requestTitle?: string,
    ): Observable<IFileUploadResponse[]> {
        if (!updatedRequestItems?.length && !requestTitle) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/item`;
        const body = { updatedRequestData: { requestItems: updatedRequestItems || [], requestTitle } };
        return this.http.put<IFileUploadResponse[]>(url, body);
    }

    async downloadFile(vaultId: string, fileId: string, filename: string): Promise<void> {
        window.open("", "_self").location.href = await this.vaultService
            .getDownloadUrl(vaultId, fileId, filename)
            .toPromise();
    }

    getConfirmReviewDialogParams(confirmText: string, cardType: string): IRequestConfirmDialogParams {
        const { promptText, areYouSureText } = this.getConfirmDialogText(cardType);
        return {
            data: {
                confirmText,
                declineText: "Cancel",
                promptText,
                areYouSureText,
            },
            panelClass: ["centered-modal"],
            width: "420px",
        };
    }

    getRequestReviewModalTitles(cardType: VaultCardType): {
        analyticsPrefix: GA_EVENTS_PREFIX;
        analyticsApproveAll: GA_EVENTS;
    } {
        switch (cardType) {
            case VaultCardType.VaultPayrollApprovalRequest: {
                return {
                    analyticsPrefix: GA_EVENTS_PREFIX.PAYRUN,
                    analyticsApproveAll: GA_EVENTS.PAYRUN_LINES_APPROVE_ALL,
                };
            }
            case VaultCardType.VaultInstructionsRequest: {
                return {
                    analyticsPrefix: GA_EVENTS_PREFIX.INSTRUCTIONS,
                    analyticsApproveAll: GA_EVENTS.PAYRUN_LINES_APPROVE_ALL,
                };
            }
            default: {
                return {
                    analyticsPrefix: GA_EVENTS_PREFIX.RFI,
                    analyticsApproveAll: GA_EVENTS.RFI_LINES_APPROVE_ALL,
                };
            }
        }
    }

    getSkipInstructionsRequestModalData(): IsCompleteModalRfi {
        return {
            title: InstructionsRequestSkippedModalData.Title,
            subhead: InstructionsRequestSkippedModalData.Subhead,
            analyticsPrefix: GA_EVENTS.INSTRUCTIONS_CONFIRM_SKIP,
        };
    }

    getCompleteRequestModalData(cardType: string, analyticsTags: IRequestAnalyticsTags): IsCompleteModalRfi {
        switch (cardType) {
            case VaultCardType.VaultPayrollApprovalRequest: {
                return {
                    title: PayRunRequestCompleteRequestModalData.Title,
                    subhead: PayRunRequestCompleteRequestModalData.Subhead,
                    analyticsPrefix: analyticsTags.analyticsConfirmComplete,
                };
            }
            case VaultCardType.VaultInstructionsRequest: {
                return {
                    title: InstructionsRequestCompleteModalData.Title,
                    subhead: InstructionsRequestCompleteModalData.Subhead,
                    analyticsPrefix: analyticsTags.analyticsConfirmComplete,
                };
            }
            default: {
                return {
                    title: RequestCompleteModalData.Title,
                    subhead: RequestCompleteModalData.Subhead,
                    analyticsPrefix: analyticsTags.analyticsConfirmComplete,
                };
            }
        }
    }

    updateRequestDescription(threadId: string, card: IThreadCard, description: string): Observable<void> {
        if (description !== card.description) {
            return this.cardService.updateCardDescription(threadId, card.id, description, CardStatus.Edited);
        } else {
            return of(null);
        }
    }

    updateRequestTitle(
        threadId: string,
        cardId: string,
        vaultId: string,
        title: string,
        stateTitle: string,
    ): Observable<IFileUploadResponse[]> {
        if (title !== stateTitle) {
            return this.updateRequestItems(threadId, cardId, vaultId, undefined, title);
        } else {
            return of(null);
        }
    }

    getAnalyticsTags(cardType: VaultCardType): IRequestAnalyticsTags {
        switch (cardType) {
            case VaultCardType.VaultPayrollApprovalRequest: {
                return {
                    analyticsPrefix: GA_EVENTS_PREFIX.PAYRUN,
                    analyticsApproveAll: GA_EVENTS.PAYRUN_LINES_APPROVE_ALL,
                    analyticsEventComplete: GA_EVENTS.PAYRUN_REPORT_APPROVE,
                    analyticsConfirmComplete: GA_EVENTS.PAYRUN_CONFIRM_COMPLETE,
                    analyticsEventPending: GA_EVENTS.PAYRUN_SEND_FOR_REVIEW,
                    analyticsEventReview: GA_EVENTS.PAYRUN_SEND_FOR_REVIEW,
                    analyticsEventClose: GA_EVENTS.PAYRUN_CARD_CLOSE_MODAL,
                };
            }
            case VaultCardType.VaultInstructionsRequest: {
                return {
                    analyticsPrefix: GA_EVENTS_PREFIX.INSTRUCTIONS,
                    analyticsApproveAll: GA_EVENTS.INSTRUCTIONS_LINES_APPROVE_ALL,
                    analyticsEventComplete: GA_EVENTS.INSTRUCTIONS_COMPLETE,
                    analyticsConfirmComplete: GA_EVENTS.INSTRUCTIONS_CONFIRM_COMPLETE,
                    analyticsEventPending: GA_EVENTS.INSTRUCTIONS_COMPLETE,
                    analyticsEventReview: GA_EVENTS.INSTRUCTIONS_COMPLETE,
                    analyticsEventClose: GA_EVENTS.INSTRUCTIONS_CLOSE,
                };
            }
            default: {
                return {
                    analyticsPrefix: GA_EVENTS_PREFIX.INSTRUCTIONS,
                    analyticsApproveAll: GA_EVENTS.RFI_LINES_APPROVE_ALL,
                    analyticsEventComplete: GA_EVENTS.RFI_COMPLETE,
                    analyticsConfirmComplete: GA_EVENTS.RFI_CONFIRM_COMPLETE,
                    analyticsEventPending: GA_EVENTS.RFI_SEND_FOR_REVIEW,
                    analyticsEventReview: GA_EVENTS.RFI_SEND_FOR_REVIEW,
                    analyticsEventClose: GA_EVENTS.RFI_CLOSE,
                };
            }
        }
    }

    updateRequestState(
        card: IThreadCard,
        thread: ITimeline,
        form: FormGroup<IRequestForm>,
        state: IVaultRequestCardState,
        updatedRequestState: IUpdatedRequestState,
    ): Observable<void> {
        const cardDescription$ = this.updateRequestDescription(
            thread.id,
            card,
            form.controls.cardDescription.value,
        ).pipe(take(1));

        const updateTitle$ = this.updateRequestTitle(
            thread.id,
            card.id,
            state.vaultId,
            form.controls.title.value,
            state.title,
        ).pipe(take(1));

        const removeRequestItems$ = this.removeRequestItems(
            thread.id,
            card.id,
            state.vaultId,
            updatedRequestState.requestItemsRemoved,
        ).pipe(take(1));

        const addedRequestItems = this.getAddedItems(form);

        const addRequestItems$ = this.addRequestItems(thread.id, card.id, state.vaultId, addedRequestItems).pipe(
            take(1),
        );

        const attachmentsDeleted$ = this.deleteRequestFiles(
            thread.id,
            card.id,
            state.vaultId,
            updatedRequestState.attachmentsDeleted,
        ).pipe(take(1));

        const attachmentsAdded$ = this.uploadRequestAttachments(
            thread.id,
            card.id,
            state.vaultId,
            state.attachments.fileId,
            updatedRequestState.attachmentsAdded,
            RequestAttachmentType.Attachment,
        );

        const update = [
            attachmentsAdded$,
            cardDescription$,
            addRequestItems$,
            removeRequestItems$,
            updateTitle$,
            attachmentsDeleted$,
        ];

        return forkJoin(update).pipe(
            switchMap(() => {
                if (form.dirty) {
                    return this.sendRequestCardEvent(thread.id, card.id, card.type, {
                        body: { isCompleted: false },
                    });
                }
                return of(null);
            }),
        );
    }

    getAddedItems(form: FormGroup<IRequestForm>): Array<{ description: string }> {
        return form.controls.requestItems?.controls
            .map((requestItemControl: FormGroup<IRequestItemFormGroup>) => {
                if (requestItemControl.controls.requestItem?.value) {
                    return null;
                }
                return {
                    description: requestItemControl.controls.description?.value,
                };
            })
            .filter((item) => !!item);
    }

    private getConfirmDialogText(cardType: string): IRequestConfirmDialogText {
        switch (cardType) {
            case VaultCardType.VaultPayrollApprovalRequest: {
                return {
                    promptText: PayrunRequestReviewModalData.Title,
                    areYouSureText: PayrunRequestReviewModalData.Subhead,
                };
            }
            default: {
                return {
                    promptText: RfiRequestReviewModalData.Title,
                    areYouSureText: RfiRequestReviewModalData.Subhead,
                };
            }
        }
    }

    private showErrorToast(file: File): void {
        const errorModalTitle = ErrorMessagesConstants.AttachmentsFailed;
        const fileName = file?.name ? [file.name] : [];
        const messageText = this.vaultCardService.getFilesUploadErrorToastMessageText(fileName);
        const toastMessage: AlertMessage = {
            channel: ToastKeys.fileUploadError,
            status: ToastSeverity.Error,
            label: errorModalTitle,
            message: messageText,
        };
        this.alertService.show(toastMessage).subscribe();
        this.errorService.logUploadFileError(file, errorModalTitle);
    }

    private addRequestAttachments(
        threadId: string,
        cardId: string,
        vaultId: string,
        fileId: string,
        filenames: string[],
        type: RequestAttachmentType,
    ): Observable<IFileUploadResponse[]> {
        if (!filenames?.length) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/attachment`;
        const body = { fileId, filenames, type };
        return this.http.post<IFileUploadResponse[]>(url, body);
    }

    private getSignedUrl(attachmentUploadResponse: IFileUploadResponse[], attachment: File) {
        return attachmentUploadResponse.find((uploadResponse) => uploadResponse.filename === attachment.name)
            .signedRequest;
    }
}
