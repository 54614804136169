import {
    AssigneeExtensionHelpers,
    AssigneeInput,
    AssigneeType,
    DueDateAnchor,
    IStep,
    IWorkflow,
    InputType,
    MILESTONE_EXTENSION_TYPE,
    WORKFLOW_EXTENSION_TYPE,
    WorkflowCoreExtensionService,
    WorkflowDueDateService,
    WorkflowGraphService,
} from "@visoryplatform/workflow-core";

import { Injectable } from "@angular/core";
import { IWorkflowConfigurationSteps } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { IStepUI } from "../../interfaces/IStepUi";
import { IWorkflowStepUI } from "../../interfaces/IWorkflowStepUI";

@Injectable({
    providedIn: "root",
})
export class WorkflowStepsService {
    getUpdatedStepEntries(startDate: string, workflow: IWorkflow): IWorkflowStepUI[] {
        if (!workflow) {
            return null;
        }

        const dateNow = DateTime.now();
        const workflowStart = startDate ? DateTime.fromISO(startDate, { setZone: true }) : dateNow;

        const steps = WorkflowGraphService.orderWorkflowSteps(workflow);
        const dueDateSteps = WorkflowDueDateService.filterDueDateSteps(steps);
        const dueDates = WorkflowDueDateService.getDueDates(DueDateAnchor.StartDate, workflowStart, dueDateSteps);

        const currentStepIndex = steps.findIndex((step) => step.id === workflow.currentStepId);

        const allSteps = steps.map((step, index) => {
            const stepDate = dueDates[step.id];
            return this.mapStepToStepEntry(step, index, currentStepIndex, stepDate?.dueDate, stepDate?.isOverdue);
        });

        return allSteps;
    }

    getStepsUI(steps: IStep[], workflowConfigurationSteps?: IWorkflowConfigurationSteps): IStepUI[] {
        const filteredSteps = steps.filter(this.isNonConfigStep);

        return filteredSteps.map((step) => {
            const assigneeData = AssigneeExtensionHelpers.getAssigneeData(step.extensions);
            const stepAssignees = this.getStepAssignees(step, workflowConfigurationSteps, assigneeData);
            const isAssignedToClient = AssigneeExtensionHelpers.isAssignedToClient(step.extensions);
            const assigneeType = assigneeData?.assigneeType;

            const assigneeExtension = AssigneeExtensionHelpers.getAssigneeExtension(step.extensions);
            const resolvedInput = WorkflowCoreExtensionService.findResolvedInput(assigneeExtension?.inputs ?? []);
            const resolved = resolvedInput?.resolved ?? false;

            return {
                id: step.id,
                label: step.label,
                assignees: stepAssignees,
                isAssignedToClient,
                assigneeType,
                disabled: resolved,
            };
        });
    }

    getStepAssignees(
        step: IStep,
        workflowConfigurationSteps?: IWorkflowConfigurationSteps,
        assigneeData?: AssigneeInput | null,
    ): string[] {
        const stepConfig = workflowConfigurationSteps && workflowConfigurationSteps[step.id];

        if (stepConfig) {
            return stepConfig?.assignees ?? [];
        } else {
            return assigneeData?.assignees ?? [];
        }
    }

    private mapStepToStepEntry(
        step: IStep,
        index: number,
        currentStepIndex: number,
        dueDate: DateTime,
        isOverdue: boolean,
    ): IWorkflowStepUI {
        const isCompleted = index < currentStepIndex;

        return this.getStepEntry(step, isCompleted, dueDate, isOverdue);
    }

    private isMilestone(step: IStep): boolean {
        const milestoneExtension = step.extensions?.find((ext) => ext?.type === MILESTONE_EXTENSION_TYPE);
        return !!milestoneExtension?.inputs?.find(
            (input) => input.type === InputType.Static && (input.data as any)?.isMilestone,
        );
    }

    private getStepEntry(step: IStep, isCompleted: boolean, dueDate: DateTime, isOverdue: boolean): IWorkflowStepUI {
        const timingsOutput = WorkflowDueDateService.getTimings(step);
        const completedDate = timingsOutput?.completedDate || null;

        const isMilestone = this.isMilestone(step);
        const assignee = AssigneeExtensionHelpers.getAssigneeData(step.extensions);

        const hideFromExternal = assignee?.hideFrom?.includes(AssigneeType.External);

        return {
            step,
            isCompleted,
            dueDate: dueDate?.toISO(),
            isOverdue,
            assignee: assignee || null,
            completedDate,
            isMilestone,
            hideFromExternal,
        };
    }

    private isNonConfigStep(step: IStep): boolean {
        return !step.extensions.find((ext) => ext?.type === WORKFLOW_EXTENSION_TYPE);
    }
}
