import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, delay, mergeMap } from "rxjs/operators";
import { ErrorService } from "./error.service";

@Injectable()
export class NetworkConnectionInterceptor implements HttpInterceptor {
    constructor(private errorService: ErrorService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.offlineIntercept(req, next);
    }

    private handleOffline(): Observable<boolean> {
        return this.errorService.showErrorModal(
            "Internet disconnected",
            "Please check your internet connection and try again.",
            "Retry",
            true,
        );
    }

    private retryOffline(
        err: any,
        req: HttpRequest<any>,
        next: HttpHandler,
        retryCount: number,
    ): Observable<HttpEvent<any>> {
        if ((err?.status === 0 || err?.status === 504) && !navigator.onLine) {
            const retryDelay = 1000 * Math.min(Math.pow(2, retryCount), 8);
            console.log("Retrying offline network request", retryCount, retryDelay);

            return this.handleOffline().pipe(
                delay(retryDelay),
                mergeMap(() => this.offlineIntercept(req, next, retryCount + 1)),
            );
        }

        return throwError(err);
    }

    private offlineIntercept(req: HttpRequest<any>, next: HttpHandler, retryCount = 0): Observable<HttpEvent<any>> {
        const isOnline = navigator.onLine;

        const catchResponse$ = next
            .handle(req)
            .pipe(catchError((err) => this.retryOffline(err, req, next, retryCount)));

        if (isOnline) {
            return catchResponse$;
        }

        return this.handleOffline().pipe(mergeMap(() => catchResponse$));
    }
}
