import { Injectable } from "@angular/core";
import { ITimeline } from "@visoryplatform/threads";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ThreadUpdateService } from "projects/portal-modules/src/lib/shared/services/thread-update-service";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from "rxjs/operators";
import { InternalChatService } from "../../services/internal-chat.service";

@Injectable({
    providedIn: "root",
})
export class InternalChatRouteService {
    constructor(
        private threadUpdateService: ThreadUpdateService,
        private internalChatService: InternalChatService,
    ) {}

    getThread(accountRouteService: AccountRouteService, loader: Loader): Observable<ITimeline> {
        return this.getCurrentThread(accountRouteService, loader);
    }

    private getCurrentThread(accountRouteService: AccountRouteService, loader: Loader): Observable<ITimeline> {
        const account$ = accountRouteService.getAccount();

        return account$.pipe(
            map((account) => account.id),
            filter((accountId) => !!accountId),
            switchMap((accountId) => loader.wrap(this.internalChatService.getInternalChat(accountId))),
            switchMap((thread) => this.threadUpdateService.getUpdatesByThread(thread)),
            shareReplay(1),
            distinctUntilChanged(),
        );
    }
}
