import { NgModule } from "@angular/core";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { ThreadOverviewComponent } from "../../portal-modules/src/lib/timeline/components/thread-overview/thread-overview.component";
import { ThreadOverviewPlugin } from "./thread-overview.plugin";

@NgModule({
    imports: [ThreadOverviewComponent],
    providers: [{ provide: PLUGIN_FACTORY, useClass: ThreadOverviewPlugin, multi: true }],
})
export class ThreadOverviewModule {}
