import { Component, computed, effect, EventEmitter, Inject, input, Input, Output, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { VAvatar } from "@visoryplatform/portal-ui";
import { IThread, Role, WorkflowStepType } from "@visoryplatform/threads";
import {
    AssigneeExtensionHelpers,
    IStep,
    IWorkflow,
    IWorkflowAction,
    SlaExtensionHelpers,
    SystemStepId,
    WorkflowDueDateService,
    WorkTimeExtensionHelpers,
} from "@visoryplatform/workflow-core";
import { filter, firstValueFrom, Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { IWorkflowStepUI } from "../../../threads-ui/interfaces/IWorkflowStepUI";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { UserAvatarService } from "../../../threads-ui/services/user-avatar.service";
import { ACTIVITY_PATH } from "../../../timeline/timeline-routes.module";
import { WorkflowTextConstants } from "../../constants/workflow-text.constants";
import { DurationBadgeColour } from "../duration-badge/duration-badge.component";
@Component({
    selector: "workflow-step-entry",
    templateUrl: "./workflow-step-entry.component.html",
    styleUrls: ["./workflow-step-entry.component.scss"],
})
export class WorkflowStepEntryComponent {
    @Input() workflow: IWorkflow;
    @Input() thread: IThread;
    @Input() accountLabel: string;
    @Input() index: number;
    @Input() isLastMilestone: boolean;
    @Input() timezone: string;
    @Output() performAction = new EventEmitter<IWorkflowAction>();

    entry = input.required<IWorkflowStepUI>();
    role = input<Role>();

    readonly hasDueDates = computed(() => WorkflowDueDateService.isSla(this.entry()?.step));
    readonly assignees = computed(
        () => AssigneeExtensionHelpers.getAssigneeData(this.entry()?.step?.extensions)?.assignees || [],
    );

    readonly avatars = signal<VAvatar[]>([]);
    readonly completedBy = signal<VAvatar | null>(null);
    readonly workTime = signal<number | null>(null);

    readonly stepTypes = WorkflowStepType;
    readonly theme = this.environment.theme;
    readonly SystemStepId = SystemStepId;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    slaTime$: Observable<number>;
    slaColour$: Observable<DurationBadgeColour>;

    roles = Role;
    selectedStep: IStep;
    stateChanged = false;
    text = WorkflowTextConstants;
    timeZone: string;
    displayActionButtons = computed(() => this.currentUrl().includes(ACTIVITY_PATH));

    private readonly enableCompletedBy = toSignal(
        this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableWorkflowStepCompletedBy),
    );
    private readonly enableWorkTime = toSignal(
        this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableWorkTimeOnWorkflowStepper),
    );

    private readonly viewCompletedByPermission = this.permissionService.checkPermissionSignal("ViewCompletedBy");
    private readonly viewWorkTimePermission = this.permissionService.checkPermissionSignal("ViewWorkTime");
    private currentUrl = signal(this.router.url);

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private userAvatarService: UserAvatarService,
        private router: Router,
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => this.currentUrl.set(event.urlAfterRedirects));

        effect(() => this.handleCompletedByEffect(), { allowSignalWrites: true });
        effect(() => this.handleWorkTimeEffect(), { allowSignalWrites: true });
        effect(() => this.handleAvatarsEffect(), { allowSignalWrites: true });
    }

    selectStepAt(action: IWorkflowAction): void {
        this.performAction.emit(action);
    }

    private async handleCompletedByEffect(): Promise<void> {
        const enableCompletedBy = this.enableCompletedBy();
        const viewCompletedByPermission = this.viewCompletedByPermission();
        const step = this.entry()?.step;

        if (enableCompletedBy && viewCompletedByPermission && step) {
            const completedBy = await this.getCompletedBy(step);
            this.completedBy.set(completedBy);
        } else {
            this.completedBy.set(null);
        }
    }

    private handleWorkTimeEffect(): void {
        const enableWorkTime = this.enableWorkTime();
        const viewWorkTimePermission = this.viewWorkTimePermission();
        const step = this.entry()?.step;

        if (enableWorkTime && viewWorkTimePermission && step) {
            const workTime = WorkTimeExtensionHelpers.getMultipliedWorkTime(step?.extensions);
            this.workTime.set(workTime);
        } else {
            this.workTime.set(null);
        }
    }

    private async handleAvatarsEffect(): Promise<void> {
        const assignees = this.assignees();
        const avatars = await this.getAssigneeAvatars(assignees);
        this.avatars.set(avatars);
    }

    private async getCompletedBy(step: IStep): Promise<VAvatar | null> {
        try {
            const userId = SlaExtensionHelpers.getSlaCompletedByFromStep(step);
            if (!userId) {
                return null;
            }

            return await firstValueFrom(this.userAvatarService.getAvatarFromId(userId));
        } catch (error) {
            return null;
        }
    }

    private async getAssigneeAvatars(assignees: string[]): Promise<VAvatar[]> {
        try {
            return await firstValueFrom(this.userAvatarService.getAvatarsFromIds(assignees));
        } catch (error) {
            console.error("Error getting assignee avatars", error);
            return [];
        }
    }
}
