import {
    ConfigTokenHelpers,
    VariationsControlContent,
    WorkflowVariationTypes,
    workflowVariations,
} from "@visoryplatform/threads";
import { IWorkflowVariation } from "@visoryplatform/workflow-core";

type TokenMap = { [id: string]: IWorkflowVariation };

export class WorkflowVariationsService {
    getWorkflowTokens(tokenIds?: string[]): IWorkflowVariation[] | null {
        const tokenMap = this.getTokenMap();

        const foundTokens: IWorkflowVariation[] = tokenIds?.map((id) => tokenMap[id]).filter((token) => token);
        if (!foundTokens?.length) {
            return null;
        }

        return foundTokens;
    }

    getWorkflowVariationsControlContent(tokenIds?: string[]): VariationsControlContent[] {
        if (!tokenIds?.length) {
            return [];
        }

        const matchedControls: VariationsControlContent[] = [];

        for (const controls of Object.values(workflowVariations)) {
            const filteredControls = controls.filter((control) => this.hasMatchingToken(control, tokenIds));
            matchedControls.push(...filteredControls);
        }

        return matchedControls.filter((control) => !control.mandatory);
    }

    getTokenAffectingScheduling(workflowVariations?: IWorkflowVariation[]): IWorkflowVariation | null {
        if (!workflowVariations?.length) {
            return null;
        }

        const workflowVariationsAffectingScheduled = workflowVariations.find(
            (workflowVariation) => workflowVariation?.affectsScheduling,
        );
        if (!workflowVariationsAffectingScheduled) {
            return null;
        }

        return workflowVariationsAffectingScheduled;
    }

    getRequiredVariations(workflowVariationType?: WorkflowVariationTypes): number | null {
        if (!workflowVariationType) {
            return null;
        }

        const requiredVariations = workflowVariations[workflowVariationType];
        const variations = requiredVariations.filter(ConfigTokenHelpers.excludeMandatory);

        return variations?.length;
    }

    private hasMatchingToken(control: VariationsControlContent, tokenIds: string[]): boolean {
        return control.tokens.some((token) => tokenIds.includes(token.id));
    }

    private getTokenMap(): TokenMap {
        const tokenTypes = Object.keys(workflowVariations);

        return tokenTypes.reduce<TokenMap>((tokenMap, type) => {
            const tokensFromType = this.getTokensFromType(type);
            return this.mergeTokensToMap(tokensFromType, tokenMap);
        }, {});
    }

    private getTokensFromType(type: string): VariationsControlContent[] {
        const controls: VariationsControlContent[] = workflowVariations[type];
        return controls;
    }

    private mergeTokensToMap(tokensFromType: VariationsControlContent[], tokenMap: TokenMap): TokenMap {
        for (const control of tokensFromType) {
            for (const token of control.tokens) {
                tokenMap[token.id] = token;
            }
        }

        return tokenMap;
    }
}
