import { Injectable } from "@angular/core";
import { CompletedLongRunningTask, FailedLongRunningTask, LongRunningTaskStatus } from "@visoryplatform/threads";
import { ThreadsWebsocketService } from "projects/portal-modules/src/lib/shared/services/threads-websocket.service";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class LongRunningTaskService {
    constructor(private websocketService: ThreadsWebsocketService) {}

    waitForTask(taskId: string): Observable<CompletedLongRunningTask> {
        return this.websocketService.watchLongRunningTask(taskId).pipe(
            filter((task) => task.status !== LongRunningTaskStatus.Pending),
            map((task) => this.mapTaskData(task)),
        );
    }

    private mapTaskData(task: FailedLongRunningTask | CompletedLongRunningTask): CompletedLongRunningTask {
        if (task.status === LongRunningTaskStatus.Failed) {
            throw new Error(task.error);
        }

        return task;
    }
}
