import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { FxTableModule } from "projects/portal-modules/src/lib/fx-table/fx-table.module";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { SharedPipesModule } from "../../portal-modules/src/lib/shared/pipes/shared-pipes.module";
import { PayrunExpandedDetailsBlockComponent } from "./components/payrun-expanded-details-block/payrun-expanded-details-block.component";
import { PayrunExpandedDetailsComponent } from "./components/payrun-expanded-details/payrun-expanded-details.component";
import { PayrunReportActionRequestComponent } from "./components/payrun-report-action-request/payrun-report-action-request.component";
import { PayrunReportCreateRequestComponent } from "./components/payrun-report-create-request/payrun-report-create-request.component";
import { PayrunReportEditRequestComponent } from "./components/payrun-report-edit-request/payrun-report-edit-request.component";
import { PayrunRequestTableComponent } from "./components/payrun-request-table/payrun-request-table.component";
import { PayrunSummaryComponent } from "./components/payrun-summary/payrun-summary.component";
import { VaultRequestApprovalPayrunCardComponent } from "./components/vault-request-approval-payrun-card/vault-request-approval-payrun-card.component";
import { PayrollPlugin } from "./payroll.plugin";
import { CheckPayrunTransformedLineTypePipe } from "./pipes/check-payrun-transformed-line-type.pipe";

const sharedComponents = [
    PayrunSummaryComponent,
    PayrunExpandedDetailsComponent,
    PayrunExpandedDetailsBlockComponent,
    VaultRequestApprovalPayrunCardComponent,
    PayrunRequestTableComponent,
    PayrunReportActionRequestComponent,
    PayrunReportEditRequestComponent,
];

@NgModule({
    exports: [PayrunReportCreateRequestComponent, ...sharedComponents],
    declarations: [PayrunReportCreateRequestComponent, CheckPayrunTransformedLineTypePipe, ...sharedComponents],
    providers: [CurrencyPipe, DecimalPipe, { provide: PLUGIN_FACTORY, useClass: PayrollPlugin, multi: true }],
    imports: [
        BrowserModule,
        CommonModule,
        FxUiModule,
        ThreadsUiModule,
        MatDialogModule,
        FindexUiModule,
        RouterModule,
        FxTableModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        MatSelectModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        PortalUiModule,
        SharedPipesModule,
    ],
})
export class PayrollModule {}
