import { Injector } from "@angular/core";
import { CalendarAction, CardTaskActionLabel, SubjectType } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { TaskAction } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { Observable, combineLatest, of } from "rxjs";
import { filter, map, mergeMap, shareReplay, switchMap, take } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { CalendarState } from "./calendar-state.type";
import {
    CalendarDetailsModalComponent,
    CalendarDetailsModel,
} from "./components/calendar-details-modal/calendar-details-modal.component";
import { CalendarModalComponent, ICalendarModalData } from "./components/calendar-modal/calendar-modal.component";
import { CalendarCardService } from "./services/calendar-card.service";
import { CalendarService, ICalendarAvailability, ISlot } from "./services/calendar.service";

export enum OpenCalendarModal {
    RESCHEDULE = "reschedule",
    SCHEDULE = "schedule",
}

const meetingAction =
    (reschedule?: boolean) =>
    async ({ card$ }: Partial<CardResources>, injector: Injector) => {
        const extensionDisplayService = injector.get(EXTENSION_DISPLAY_SERVICE);
        const calendarService = injector.get(CalendarService);
        const loader = injector.get(Loader);
        const card = await card$.pipe(take(1)).toPromise();

        const calendarSubjects = card.subjects.filter((cardSubject) => cardSubject.type === SubjectType.Calendar);
        if (calendarSubjects.length !== 1) {
            console.error("Did not find exactly 1 calendar subject", calendarSubjects);
            return null;
        }

        const invitationId = calendarSubjects.slice(0, 1).pop()?.id;

        const data: ICalendarModalData = {
            card,
            reschedule,
            invitationId,
        };
        const options = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            data,
        };

        const slot = await extensionDisplayService
            .open<ISlot>(CalendarModalComponent, options)
            .pipe(take(1))
            .toPromise();
        const checkSlotAvailability$ = checkSlotAvailability(calendarService, invitationId, slot).pipe(take(1));
        return await loader.wrap(checkSlotAvailability$).toPromise();
    };

const checkSlotAvailability = (
    calendarService: CalendarService,
    invitationId: string,
    slot: ISlot,
): Observable<ISlot> => {
    if (!slot) {
        return of(slot);
    }

    return calendarService.checkAvailability(invitationId, slot.start).pipe(
        mergeMap((checkAvailabilityResponse: ICalendarAvailability) => {
            const startDate = DateTime.fromISO(slot.start);
            const slotIsAvailable = checkAvailabilityResponse.slots.find(
                (currentSlot: ISlot) => DateTime.fromISO(currentSlot.start).toMillis() === startDate.toMillis(),
            );

            if (slotIsAvailable) {
                return calendarService.setAppointment(invitationId, slot.start, slot.end).pipe(map(() => slot));
            } else {
                return of(slot);
            }
        }),
    );
};

const viewDetailsAction = async (cardResources: CardResources<CalendarState>, injector: Injector) => {
    //TODO: we need to convert these actions to classes so we can use DI via the constructor
    const authService = injector.get(AuthService);
    const extensionDisplayService = injector.get(EXTENSION_DISPLAY_SERVICE);
    const participantsCache = injector.get(ParticipantCache);
    const taskActionService = injector.get(TaskActionService);
    const calendarService = injector.get(CalendarService);

    const userId$ = authService.getUser().pipe(
        filter((user) => !!user),
        map((user) => user.id),
    );

    const { state$, thread$, role, card$ } = cardResources;

    const meetingStatus$ = state$.pipe(map((state) => calendarService.getMeetingStatus(state)));
    const participant$ = combineLatest([state$, userId$]);
    const otherParticipant$ = participant$.pipe(
        switchMap(([state, userId]) => {
            const attendees = CalendarCardService.getAllAttendees(state);

            const participantIds = attendees
                .filter((attendee) => attendee.id !== userId)
                .map((attendee) => attendee.id);

            return participantsCache.getParticipants(participantIds);
        }),
    );
    const invitedToMeeting$ = participant$.pipe(
        map(([state, userId]) => {
            const attendees = CalendarCardService.getAllAttendees(state);
            return attendees.some((attendee) => attendee.id === userId);
        }),
        shareReplay(1),
    );
    const avatar$ = otherParticipant$.pipe(
        switchMap((participants) => participantsCache.getMultipleAvatars(participants)),
    );

    const detailsData: CalendarDetailsModel = {
        state$,
        userId$,
        thread$,
        meetingStatus$,
        invitedToMeeting$,
        avatar$,
        card$,
        role,
    };

    const config = {
        disableClose: false,
        backdropClass: "modal-backdrop",
        panelClass: ["calendar-details-modal", "modal-container", "mat-dialog-no-styling"],
        closeOnNavigation: true,
        hasBackdrop: true,
        data: detailsData,
        height: "auto",
    };

    const dialogCallback = extensionDisplayService.open<{ openCalendarModal?: OpenCalendarModal }>(
        CalendarDetailsModalComponent,
        config,
    );

    dialogCallback.subscribe((data) => {
        if (data?.openCalendarModal) {
            if (data.openCalendarModal === OpenCalendarModal.RESCHEDULE) {
                taskActionService.action(CalendarAction.RESCHEDULE, cardResources);
            } else {
                taskActionService.action(CalendarAction.SCHEDULE, cardResources);
            }
        }
    });
};

export const calendarScheduleTaskAction: TaskAction<ISlot> = {
    analyticsEvents: [GA_EVENTS.APP_VIEWAVAILABILITY, GA_EVENTS.CALENDAR_OPENSCHEDULING],
    cardTypes: ["calendar"],
    action: meetingAction(false),
    buttonLabel: CardTaskActionLabel.ScheduleMeeting,
    statusIcon: "las la-calendar",
};

export const calendarRescheduleTaskAction: TaskAction<ISlot> = {
    analyticsEvents: [GA_EVENTS.CALENDAR_RESCHEDULE],
    cardTypes: ["calendar"],
    action: meetingAction(true),
    buttonLabel: CardTaskActionLabel.RescheduleMeeting,
    statusIcon: "las la-calendar",
};

export const calendarViewDetails: TaskAction<any> = {
    analyticsEvents: [GA_EVENTS.CALENDAR_VIEWDETAILS],
    cardTypes: ["calendar"],
    action: viewDetailsAction,
    buttonLabel: CardTaskActionLabel.ViewDetails,
    statusIcon: "las la-calendar",
};
