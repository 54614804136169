import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { TuiHintModule } from "@taiga-ui/core";
import { SharedPipesModule } from "../../../shared/pipes/shared-pipes.module";

export enum DurationBadgeColour {
    Red = "red",
    Yellow = "yellow",
    Green = "green",
}

@Component({
    selector: "duration-badge",
    templateUrl: "./duration-badge.component.html",
    styleUrls: ["./duration-badge.component.scss"],
    standalone: true,
    imports: [SharedPipesModule, NgClass, TuiHintModule],
})
export class DurationBadgeComponent {
    @Input() label: string;
    @Input() time: number | null;
    @Input() hint: string;
    @Input() colour: DurationBadgeColour;
    @Input() isCountdown = true;
}
