import { Pipe, PipeTransform } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { LaunchDarklyFeatureFlags } from "../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { FeatureFlagService } from "../../feature-flags/services/feature-flags.service";

@Pipe({ name: "featureEnabled" })
export class FeatureEnabledPipe implements PipeTransform {
    constructor(private featureFlagService: FeatureFlagService) {}

    transform(featureFlag: LaunchDarklyFeatureFlags | LaunchDarklyFeatureFlags[]): Observable<boolean> {
        if (!featureFlag) {
            return of(true);
        }
        const featureFlags = typeof featureFlag === "string" ? [featureFlag] : featureFlag;

        return this.featureFlagService.getFlags().pipe(map((flags) => featureFlags.every((flag) => flags[flag])));
    }
}
