<v-tabs>
    @for (extension of threadExtensions$ | async; track trackByExtension($index, extension)) {
        @if (!extension?.redirect) {
            <v-tab
                [tabRouterLink]="['.', extension.route?.path ?? extension.label.toLowerCase()]"
                [analyticsClick]="gaEvents.APP_WORKFLOW_TAB + extension.label"
            >
                @if (extension.icon) {
                    <i class="las {{ extension.icon }} tab-icon"></i>
                }
                {{ extension.label }}
            </v-tab>
        } @else {
            <v-tab
                [analyticsClick]="gaEvents.APP_WORKFLOW_TAB + extension.label"
                (activated)="handleRedirect(extension)"
            >
                <i class="las {{ extension.icon }} tab-icon"></i>
                {{ extension.label }}
            </v-tab>
        }
    }
</v-tabs>
