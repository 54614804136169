<centered-modal-layout
    *ngIf="extensionDisplayRef$ | async as extensionDisplayRef"
    [loading]="!!loader.counter"
    [disableSave]="!checkDropdown.valid"
    cancelButtonTitle="Close"
    saveButtonTitle="Create"
    (save)="createCheck(checkDropdown.value.value)"
    (close)="extensionDisplayRef.close()"
>
    <div modal-header>
        <request-header
            title="Create Delphi check"
            [isComplete]="false"
            [completedStatusLabel]="RequestStatuses.Pending"
            [accountName]="(modalData$ | async).thread?.account?.label || ''"
        ></request-header>
    </div>

    <div modal-content>
        <div class="check-control">
            <label class="fx-form-label">Checks:</label>
            <div class="v-select-container">
                <v-select
                    placeholder="Select a check"
                    [options]="checkGroups"
                    [optionContent]="optionContent"
                    [formControl]="checkDropdown"
                >
                </v-select>

                <ng-template
                    #optionContent
                    let-option
                >
                    {{ option.label }}
                </ng-template>
            </div>
        </div>
    </div>
</centered-modal-layout>
