import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
    CardReply,
    CardStatus,
    IThread,
    IThreadCard,
    IVaultRequestCardState,
    Role,
    VaultStateAction,
} from "@visoryplatform/threads";
import { VaultCardType } from "projects/default-plugins/vault/components/request/constants/request.constants";
import { VaultRequestService } from "projects/default-plugins/vault/services/vault-request.service";
import { GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { ActionableCardComponent } from "projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card.component";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { combineLatest, Observable, Subscription } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { ENVIRONMENT, TASK_ACTION_LIBRARY } from "src/app/injection-token";

@Component({
    selector: "vault-request-approval-payrun-card",
    templateUrl: "./vault-request-approval-payrun-card.component.html",
    styleUrls: ["./vault-request-approval-payrun-card.component.scss"],
})
export class VaultRequestApprovalPayrunCardComponent
    extends ActionableCardComponent<boolean>
    implements OnDestroy, OnInit
{
    readonly gaEventsPrefix = GA_EVENTS_PREFIX;
    readonly gaEvents = GA_EVENTS;
    readonly VAULT_CARD_TYPES = VaultCardType;

    readonly VAULT_ACTION_REQUEST_RESPONSE = VaultStateAction.PayrunApprovalRequestResponse;
    readonly VAULT_ACTION_REQUEST_VIEW_RESPONSE = VaultStateAction.PayrunApprovalViewResponse;
    readonly VAULT_ACTION_REQUEST_EDIT_RESPONSE = VaultStateAction.PayrunApprovalEditResponse;

    card$: Observable<IThreadCard>;
    thread$: Observable<IThread>;
    userId$: Observable<string>;
    replies$: Observable<CardReply[]>;
    canEditCard$: Observable<boolean>;
    isThreadActive$: Observable<boolean>;
    canRespondToRequest$: Observable<boolean>;
    state$: Observable<IVaultRequestCardState>;

    cardStatus: CardStatus;
    role: Role;
    cardStatuses = CardStatus;
    allowEdit = this.environment.featureFlags.editCardDescription;
    errorMessage: string;
    loader = new Loader();
    actionedPercentage = 0;
    actionedRequestItems = 0;

    private stateSub: Subscription;
    private navigationSub: Subscription;
    private threadId: string;
    private cardId: string;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) protected cardResources: CardResources<IVaultRequestCardState>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<boolean>>,
        private cardService: ThreadCardService,
        private authService: AuthService,
        private threadsService: ThreadsService,
        private permissionService: PermissionService,
        protected taskActionService: TaskActionService,
    ) {
        super(cardResources, taskActionService);
    }

    ngOnInit(): void {
        const { threadId, cardId, thread$, card$, state$, role, replies$ } = this.cardResources;
        this.userId$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );

        this.role = role;
        this.thread$ = thread$;
        this.card$ = card$;
        this.threadId = threadId;
        this.cardId = cardId;
        this.replies$ = replies$;
        this.state$ = state$;
        this.stateSub = state$.subscribe((vaultState) => this.updateState(vaultState));
        this.isThreadActive$ = thread$.pipe(map((thread) => this.threadsService.isThreadActive(thread)));

        if (!this.navigationSub) {
            this.navigationSub = this.cardResources.navigateTo$.subscribe(() => this.openRequestModal(true, false));
        }

        this.canRespondToRequest$ = combineLatest([
            state$,
            this.permissionService.hasSomePermission(this.role, ["PayrunRequestRespond", "ThreadUpdateAll"]),
            card$,
            this.isThreadActive$,
        ]).pipe(
            map(
                ([state, hasRespondPermission, card, isThreadActive]) =>
                    hasRespondPermission &&
                    card.status !== this.cardStatuses.Disabled &&
                    !state.isCompleted &&
                    isThreadActive,
            ),
        );

        this.canEditCard$ = combineLatest([
            state$,
            card$,
            this.permissionService.hasSomePermission(this.role, ["PayrunRequestEdit", "ThreadUpdateAll"]),
            this.userId$,
        ]).pipe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            map(([_state$, card, hasPayrunRequestEdit, currentUserId]) => {
                if (card.status === CardStatus.Disabled) {
                    return false;
                }
                if (hasPayrunRequestEdit || card.createdBy === currentUserId) {
                    return true;
                }
                return false;
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.stateSub) {
            this.stateSub.unsubscribe();
        }
        if (this.navigationSub) {
            this.navigationSub.unsubscribe();
        }
    }

    async deleteCard(): Promise<void> {
        try {
            this.loader.show();
            await this.cardService.deleteCard(this.threadId, this.cardId).toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    async openRequestModal(hasFileId: boolean, focusReplyInput: boolean): Promise<void> {
        if (!hasFileId) {
            return;
        }

        const threadIsActive = await this.isThreadActive$.pipe(take(1)).toPromise();
        if (!threadIsActive) {
            return;
        }

        const canRespondToRequest = await this.canRespondToRequest$.pipe(take(1)).toPromise();
        if (canRespondToRequest) {
            this.action(this.VAULT_ACTION_REQUEST_RESPONSE, { focusReplyInput });
        } else {
            this.action(this.VAULT_ACTION_REQUEST_VIEW_RESPONSE, { focusReplyInput });
        }
    }

    private updateState(state: IVaultRequestCardState): void {
        if (!state) {
            return;
        }
        const { actionedRequestItems, actionedPercentage } = VaultRequestService.calculateProgress(state.requestItems);

        this.actionedRequestItems = actionedRequestItems;
        this.actionedPercentage = actionedPercentage;
    }
}
