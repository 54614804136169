import { EmbeddedContext } from "./embedded.service";

export class EmbeddedRef<R = unknown> {
    private context: EmbeddedContext<R>;

    constructor(context: EmbeddedContext<R>) {
        this.context = context;
    }

    close(result?: R): void {
        this.context.$implicit.next(result);
        this.context.$implicit.complete();
    }
}
