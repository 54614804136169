import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ConfirmPaymentData, SetupIntent, StripeElements, StripeError } from "@stripe/stripe-js";
import {
    AcceptedCurrencies,
    ICustomer,
    ICustomerUpdateParams,
    IInvoice,
    IPayment,
    IPaymentMethod,
    IPrice,
    ISetupIntent,
    ISubscription,
    PaymentMethodTypes,
} from "@visoryplatform/payments-service-sdk";
import { IPaymentInvoiceDetails } from "@visoryplatform/threads";
import { StripeFactoryService, StripeInstance } from "ngx-stripe";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs/internal/Observable";
import { map, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { UrlHttpCodec } from "./url-http-codec";

interface SetupPaymentMethodPayload {
    threadId: string;
    cardId: string;
    paymentMethodId: string;
    paymentMethodType: PaymentMethodTypes;
    invoiceDetails: IPaymentInvoiceDetails;
    currency: AcceptedCurrencies;
    accountId?: string;
    customerId?: string;
    paymentSubjectId?: string;
}

interface ICustomerAddress {
    name: string;
    address: string;
    city: string;
    postcode: string;
    state: string;
    country: string;
}

@Injectable({ providedIn: "root" })
export class PaymentService {
    stripe: StripeInstance;

    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private stripeFactory: StripeFactoryService,
    ) {
        this.stripe = this.stripeFactory.create(this.environment.payments.publishableApiKey);
    }

    getPrices(): Observable<IPrice[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.prices}`;
        return this.http.get<IPrice[]>(url);
    }

    completePayment(
        threadId: string,
        paymentId: string,
        paymentMethodId: string,
        removeFailedCard: boolean,
    ): Observable<IPayment> {
        const body = {
            threadId,
            paymentMethodId,
            removeFailedCard,
        };
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.payment}/${paymentId}/${environmentCommon.paymentsEndpoints.completePayment}`;

        return this.http.post<IPayment>(url, body, {});
    }

    activateSubscription(
        threadId: string,
        subscriptionId: string,
        paymentMethodId: string,
        removeFailedCard: boolean,
        invoiceId?: string,
    ): Observable<ISubscription> {
        const body = {
            threadId,
            paymentMethodId,
            removeFailedCard,
            invoiceId,
        };
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.subscription}/${subscriptionId}/${environmentCommon.paymentsEndpoints.activateSubscription}`;
        return this.http.post<ISubscription>(url, body);
    }
    async getSubscription(subscriptionId: string, threadId: string): Promise<ISubscription> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.subscription}`;
        const params = UrlHttpCodec.parseParams({ threadId });

        return this.http.get<ISubscription>(`${url}/${subscriptionId}`, { params }).toPromise();
    }
    getAccountCustomer(accountId: string): Observable<ICustomer> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.account}/${accountId}/${environmentCommon.paymentsEndpoints.customer}`;
        return this.http.get<ICustomer>(url, {});
    }
    getCurrentUserPaymentDetails(): Observable<ICustomer[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.userDetails}/${environmentCommon.paymentsEndpoints.currentUserDetails}`;
        return this.http.get<ICustomer[]>(url, {});
    }

    getUserPaymentDetails(userId: string): Observable<ICustomer[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.userDetails}/${userId}`;
        return this.http.get<ICustomer[]>(url, {});
    }

    getPaymentMethods(customerId: string): Observable<IPaymentMethod[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.paymentMethod}`;
        return this.http.get<IPaymentMethod[]>(`${url}/${customerId}`);
    }

    updateCustomer(customerId: string, updateCustomerParams: ICustomerUpdateParams): Observable<ICustomer> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.customer}`;
        const payload = {
            updateCustomerParams,
        };
        return this.http.put<ICustomer>(`${url}/${customerId}`, payload);
    }

    setDefaultPaymentMethod(customerId: string, paymentMethodId: string): Observable<ICustomer> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.setDefaultPaymentMethod}`;
        const payload = {
            paymentMethodId,
            customerId,
        };
        return this.http.put<ICustomer>(`${url}`, payload);
    }

    removePaymentMethod(customerId: string, paymentMethodId: string): Observable<IPayment> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.removePaymentMethod}`;
        return this.http.delete<IPayment>(`${url}/${customerId}/${paymentMethodId}`);
    }

    listInvoices(customerId: string): Observable<IInvoice[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.invoices}`;
        const params = UrlHttpCodec.parseParams({ customerId });

        return this.http.get<IInvoice[]>(url, { params });
    }

    setupPaymentMethod(
        paymentMethodTypes: string[],
        invoiceDetails: IPaymentInvoiceDetails,
        currency: AcceptedCurrencies,
        accountId?: string,
        customerId?: string,
    ): Observable<ISetupIntent> {
        const taxRateId = this.environment.payments.taxRateIds[currency];
        const body = {
            paymentMethodTypes,
            invoiceDetails,
            taxRate: {
                taxRateId,
            },
            currency,
            accountId,
            customerId,
        };
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.setupIntent}/${environmentCommon.paymentsEndpoints.paymentMethod}`;
        return this.http.post<ISetupIntent>(url, body);
    }

    async setupPaymentMethodForCard(payload: SetupPaymentMethodPayload): Promise<void> {
        const {
            paymentMethodId,
            paymentMethodType,
            invoiceDetails,
            currency,
            accountId,
            customerId,
            threadId,
            cardId,
            paymentSubjectId,
        } = payload;
        const taxRateId = this.environment.payments.taxRateIds[currency];
        const body = {
            paymentMethodId,
            paymentMethodType,
            invoiceDetails,
            taxRate: {
                taxRateId,
            },
            currency,
            accountId,
            customerId,
            threadId,
            cardId,
            description: "Payment Method Added",
            paymentObjectId: paymentSubjectId,
        };
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.setupIntent}/${environmentCommon.paymentsEndpoints.paymentMethodPaymentCard}`;
        return this.http.post<void>(url, body).toPromise();
    }

    createCustomerAndressAndInvoice(accountId: string): Observable<[ICustomerAddress, IPaymentInvoiceDetails]> {
        const mapCustomerToAddresses = (customer: ICustomer): [ICustomerAddress, IPaymentInvoiceDetails] => [
            this.mapCustomerToAddress(customer),
            this.mapCustomerToInvoice(customer),
        ];
        return this.getAccountCustomer(accountId).pipe(map((customer) => mapCustomerToAddresses(customer)));
    }

    updateCustomerAddress(accountId: string, customerParams: ICustomerUpdateParams): Observable<ICustomer> {
        return this.getAccountCustomer(accountId).pipe(
            switchMap((customer) => this.updateCustomer(customer.id, customerParams)),
        );
    }

    confirmSetupIntent(
        elements: StripeElements,
        confirmParams: Partial<ConfirmPaymentData>,
        redirect: "if_required",
    ): Observable<{ setupIntent?: SetupIntent; error?: StripeError }> {
        return this.stripe
            .confirmSetup({
                elements,
                confirmParams,
                redirect,
            })
            .pipe(
                map((result) => {
                    if (result.error) {
                        throw result.error;
                    }
                    return result;
                }),
            );
    }

    private mapCustomerToAddress(customer: ICustomer): ICustomerAddress {
        const { line1, city, postal_code: postcode, state, country } = customer.address || {};
        return {
            name: customer.name,
            address: line1 || "",
            city: city || "",
            postcode: postcode || "",
            state: state || "",
            country: country || "",
        };
    }

    private mapCustomerToInvoice(customer: ICustomer): IPaymentInvoiceDetails {
        const { line1, city, postal_code, state, country } = customer.address || {};
        return {
            name: customer.name,
            address: {
                line1: line1 || "",
                city: city || "",
                state: state || "",
                country: country || "",
                postal_code: postal_code || "",
            },
        };
    }
}
