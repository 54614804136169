import { Component, OnInit, computed, input } from "@angular/core";
import { ITimeline, calculateWorkflowPriorityWeight } from "@visoryplatform/threads";
import {
    DueDateExtensionHelpers,
    IWorkflow,
    WorkflowDueDateService,
    getLatestAdjustedSlaDate,
    getTimeToAdjustedSla,
} from "@visoryplatform/workflow-core";

import { DateTime } from "luxon";
import { Observable } from "rxjs";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { FeatureFlagService } from "../../../feature-flags/services/feature-flags.service";

@Component({
    selector: "workflow-details-header",
    templateUrl: "./workflow-details-header.component.html",
    styleUrls: ["./workflow-details-header.component.scss"],
})
export class WorkflowDetailsHeaderComponent implements OnInit {
    thread = input.required<ITimeline>();

    hasWorkflowStatusIndicatorEnabled$: Observable<boolean>;
    debugDueDate = computed(() => this.getDueDate(this.thread().workflow));
    debugPriority = computed(() => this.getPriority(this.thread().workflow));
    debugPredictedDate = computed(() => this.getPredictedFinishDate(this.thread().workflow));

    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    constructor(private featureFlagService: FeatureFlagService) {}

    ngOnInit(): void {
        this.hasWorkflowStatusIndicatorEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkflowStatusIndicator,
        );
    }

    private getPredictedFinishDate(workflow: IWorkflow): string {
        if (!workflow?.currentStepId) {
            return "N/A";
        }

        try {
            const currentStep = workflow.steps[workflow.currentStepId];
            const dueDateStep = WorkflowDueDateService.getNextDueDateStep(workflow);
            const adjustedDueDate = getLatestAdjustedSlaDate(dueDateStep);
            const timeSinceCurrentStepSla = getTimeToAdjustedSla(currentStep, DateTime.now());

            return adjustedDueDate.plus({ milliseconds: timeSinceCurrentStepSla }).setZone().toISO();
        } catch (error) {
            return "N/A";
        }
    }

    private getDueDate(workflow: IWorkflow): string {
        if (!workflow?.currentStepId) {
            return "N/A";
        }

        const nextDueDate = WorkflowDueDateService.getNextDueDateStep(workflow);

        if (!nextDueDate?.extensions) {
            return "N/A";
        }

        const dueDate = DueDateExtensionHelpers.getDueDate(nextDueDate.extensions);
        return dueDate ? DateTime.fromISO(dueDate).toString() : "N/A"; // User's timezone (not Accounts timezone)
    }

    private getPriority(workflow: IWorkflow): string {
        if (!workflow?.currentStepId) {
            return "N/A";
        }
        const priority = calculateWorkflowPriorityWeight(workflow, workflow.currentStepId);
        return priority ? priority.toString() : "N/A";
    }
}
