import { ComponentType } from "@angular/cdk/portal";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    CrudTypes,
    ICardEvent,
    ICardReadStatus,
    IThread,
    IThreadCard,
    ITimeline,
    ReadStatus,
    Role,
} from "@visoryplatform/threads";
import { CreateCardExtensionHelpers, IExtension, WorkflowSteps } from "@visoryplatform/workflow-core";
import { NotificationsService } from "projects/notifications-frontend/src/services/notifications.service";
import { concat, EMPTY, from, merge, Observable, of, ReplaySubject, Subject } from "rxjs";
import { expand, filter, map, mergeMap, shareReplay, startWith, switchMap, toArray } from "rxjs/operators";
import { CARD_LIBRARY } from "src/app/injection-token";
import { TaskNotificationsService } from "../../notifications/services/task-notifications.service";
import { ILibrary } from "../../plugins";
import { Loader } from "../../shared/services/loader";
import { ThreadsWebsocketService } from "../../shared/services/threads-websocket.service";
import { CardStateResponse } from "../interfaces/CardStateResponse";
import { CardResources, IUiCard } from "../interfaces/IUiCard";
import { ActivityChannelService } from "./activity-channel-service";
import { ParticipantService } from "./participant.service";
import { ThreadCardService } from "./thread-card.service";

@Injectable({ providedIn: "root" })
export class UiCardService {
    activeCardScroller$ = new Subject<string>();

    constructor(
        private cardService: ThreadCardService,
        private websocketService: ThreadsWebsocketService,
        private taskNotificationsService: TaskNotificationsService,
        private notificationService: NotificationsService,
        private activityChannelService: ActivityChannelService,
        private participantService: ParticipantService,
        private activatedRoute: ActivatedRoute,
        @Inject(CARD_LIBRARY) private cardLibrary: ILibrary<ComponentType<any>>,
    ) {}

    mapCard(threadId: string, thread$: Observable<ITimeline>, card: IThreadCard, role: Role): IUiCard {
        const { type, createdAt, modifiedAt } = card;
        const component = this.cardLibrary.resolve(type);

        if (!component) {
            console.warn("Unsupported card", card);
            return null;
        }

        const navigateToSubject = new ReplaySubject<void>(1);
        const cardResources = this.getCardResources(threadId, thread$, card, role, navigateToSubject);

        return {
            ...cardResources,
            timestamp: new Date(modifiedAt || createdAt).getTime(),
            component,
            navigateToSubject,
        };
    }

    /**
     * maps cardResources without making any network requests until the respective properties are subscribed to.
     */
    getCardResources(
        threadId: string,
        thread$: Observable<ITimeline>,
        card: IThreadCard,
        role: Role,
        navigateToSubject?: Subject<void>,
    ): CardResources {
        const loader = new Loader();
        const stateWrapper = this.getCardState(threadId, card.id, loader);

        const state$ = stateWrapper.pipe(map((stateResponse) => stateResponse.state));
        const replies$ = stateWrapper.pipe(
            map((state) => state.cardReplies.filter((reply) => reply.message)),
            map((replies) => replies.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())),
        );
        const cardExtension$ = thread$.pipe(map((thread) => this.getCardExtension(card.id, thread.workflow?.steps)));

        const card$ = merge(of(card), this.cardChanges(threadId, card.id)).pipe(shareReplay(1));
        const events$ = this.getAllCardEvents(loader, threadId, card.id);

        const { ignoreModalOpen } = this.activatedRoute.snapshot.queryParams;

        const navigateTo$ = navigateToSubject?.asObservable().pipe(filter(() => !ignoreModalOpen));

        return {
            threadId,
            thread$,
            card$,
            cardId: card.id,
            events$,
            state$,
            replies$,
            cardExtension$,
            navigateTo$,
            role,
            loader,
        };
    }

    getActiveCardScroller(): Observable<string> {
        return this.activeCardScroller$.asObservable();
    }

    scrollToCard(cardId: string): void {
        this.activeCardScroller$.next(cardId);
    }

    resetScroll(): void {
        this.activeCardScroller$.next("");
    }

    routeToCard(allCards: IUiCard[], cardId: string): void {
        const card = allCards.find((uiCard) => uiCard.cardId === cardId);
        if (!card) {
            return console.error("Could not find card with id", cardId);
        }

        console.info("Routing to card", cardId);
        card.scrollTo = true;
        card.navigateToSubject.next(null);
        this.scrollToCard(cardId);
    }

    compareCards(a: IUiCard, b: IUiCard): number {
        return a.timestamp - b.timestamp;
    }

    getCardContentReadStatusUpdates(cardStatus: ReadStatus, threadId: string, cardId: string): Observable<ReadStatus> {
        const channel = this.activityChannelService.getCardContentChannel(threadId, cardId);
        const channelUpdates$ = this.notificationService.subscribeToChannel(channel);
        return channelUpdates$.pipe(
            switchMap(() => this.taskNotificationsService.getCardReadStatus(threadId, cardId)),
            map((fullReadStatus) => fullReadStatus.card),
            startWith(cardStatus),
            shareReplay(1),
        );
    }

    getCardRepliesReadStatusUpdates(
        cardRepliesStatus: Record<string, ReadStatus>,
        threadId: string,
        cardId: string,
    ): Observable<Record<string, ReadStatus>> {
        const channel = this.activityChannelService.getCardReplyChannel(threadId, cardId);
        const channelUpdates$ = this.notificationService.subscribeToChannel(channel);
        return channelUpdates$.pipe(
            switchMap(() => this.taskNotificationsService.getCardReadStatus(threadId, cardId)),
            map((fullReadStatus: ICardReadStatus) => fullReadStatus.replies),
            startWith(cardRepliesStatus),
            shareReplay(1),
        );
    }

    isStatusResolved(status: ReadStatus): boolean {
        if (!status) {
            return false;
        }
        const { resolved, unresolved } = status;
        return unresolved.participantIds.length === 0 && resolved.participantIds.length !== 0;
    }

    getCardExtension(cardId: string, workflowSteps?: WorkflowSteps): IExtension {
        if (!workflowSteps) {
            return null;
        }

        const extensions = Object.values(workflowSteps)
            .map((step) => step.extensions)
            .flat();

        return CreateCardExtensionHelpers.findCardIdInExtensions(cardId, extensions);
    }

    isCardUnseenByCurrentUser(thread: IThread, card: IThreadCard, participantId: string): boolean {
        const isUserThreadParticipant = this.participantService.isUserThreadParticipant(
            thread.participants,
            participantId,
        );
        const isCardSeenByCurrentUser = this.cardSeenByCurrentUser(card, participantId);
        const createdByCurrentUser = card.createdBy === participantId;

        return isUserThreadParticipant && !isCardSeenByCurrentUser && !createdByCurrentUser && card.disableEmails;
    }

    private getAllCardEvents(loader: Loader, threadId: string, cardId: string): Observable<ICardEvent> {
        const existingEvents$ = this.cardService.getCardEvents(threadId, cardId).pipe(
            expand((page) => {
                if (page.next) {
                    return this.cardService.getCardEvents(threadId, cardId, page.next);
                } else {
                    return EMPTY;
                }
            }),
            map((page) => page.result),
            toArray(),
            map((results) => [].concat(...results)), //flatten, without .flatten available
            mergeMap((events) => from(events.reverse())), //oldest to newest, one event at a time
        );

        const websocketEvents$ = this.websocketService.watchCardId(threadId, cardId).pipe(
            filter((websocketEvent) => websocketEvent.subjectType === "event"),
            switchMap((websocketEvent) =>
                this.cardService.getEvent(websocketEvent.threadId, websocketEvent.cardId, websocketEvent.eventKey),
            ),
        );

        const loaderExisting$ = loader.wrap(existingEvents$);
        return concat(loaderExisting$, websocketEvents$);
    }

    private getCardState(threadId: string, cardId: string, loader: Loader): Observable<CardStateResponse<any>> {
        const state$ = loader.wrap(this.cardService.getCardState(threadId, cardId));
        const changes$ = this.cardStateChanges(threadId, cardId);

        return concat(state$, changes$).pipe(shareReplay(1));
    }

    private cardStateChanges(threadId: string, cardId: string): Observable<CardStateResponse<any>> {
        return this.websocketService.watchCardId(threadId, cardId).pipe(
            filter((notification) => notification.state),
            switchMap(() => this.cardService.getCardState(threadId, cardId)),
        );
    }

    private cardChanges(threadId: string, cardId: string): Observable<IThreadCard> {
        return this.websocketService.watchCardId(threadId, cardId).pipe(
            filter(
                (notification) =>
                    (notification.eventType === CrudTypes.Updated || notification.eventType === CrudTypes.Deleted) &&
                    !notification.state,
            ),
            switchMap(() => this.cardService.getCard(threadId, cardId)),
        );
    }

    private cardSeenByCurrentUser(card: IThreadCard, participantId: string): boolean {
        if (!card.seenIndicators?.length) {
            return false;
        }

        return card.seenIndicators.some((indicator) => indicator.participant === participantId);
    }
}
