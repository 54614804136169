import { Component, Input } from "@angular/core";
import { AcceptedCurrencies } from "@visoryplatform/payments-service-sdk";
import { IPayRunReport } from "@visoryplatform/threads";
import { PayRunReportInfo } from "../../constants/payrun-report-constants";

@Component({
    selector: "payrun-summary",
    templateUrl: "./payrun-summary.component.html",
    styleUrls: ["./payrun-summary.component.scss"],
})
export class PayrunSummaryComponent {
    @Input() payRunReport: IPayRunReport;
    @Input() currency: AcceptedCurrencies;

    readonly payRunReportInfo = PayRunReportInfo;
}
