import { ErrorHandler, Inject, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ErrorService } from "projects/portal-modules/src/lib/shared/services/error.service";
import { ENVIRONMENT } from "src/app/injection-token";

const FILTER_ERRORS = ["ClientAuthError: Token calls are blocked in hidden iframes"];
@Injectable()
export class ThreadsErrorHandler extends ErrorHandler {
    constructor(
        private errorService: ErrorService,
        @Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig,
    ) {
        super();

        Sentry.init({
            dsn: environment.sentry,
            environment: environment.stage,
            enabled: ["uat", "prod"].includes(environment.stage),
            release: environment.appVersion,
            integrations: [Sentry.browserTracingIntegration()],
            tracesSampleRate: 0.15,
            ignoreErrors: FILTER_ERRORS,
        });
    }

    handleError(err: any): void {
        this.errorService.handleError(err);
    }
}
