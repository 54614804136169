import { inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class UserProfileRouteService {
    private authService = inject(AuthService);
    private route = inject(ActivatedRoute);

    getUserId(): Observable<string> {
        return combineLatest([this.authService.getUserId(), this.route.params]).pipe(
            map(([userId, params]) => params.userId || userId),
        );
    }
}
