import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { MemoriesModule } from "../memories/memories.module";
import { VaultModule } from "../vault/vault.module";
import { InstructionsActionComponent } from "./components/instructions-action/instructions-action.component";
import { InstructionsCardComponent } from "./components/instructions-card/instructions-card.component";
import { InstructionsPlugin } from "./instructions.plugin";

@NgModule({
    imports: [
        CommonModule,
        FxUiModule,
        ThreadsUiModule,
        MatDialogModule,
        FindexUiModule,
        SharedModule,
        VaultModule,
        MemoriesModule,
    ],
    exports: [InstructionsActionComponent],
    declarations: [InstructionsActionComponent, InstructionsCardComponent],
    providers: [{ provide: PLUGIN_FACTORY, useClass: InstructionsPlugin, multi: true }],
})
export class InstructionsModule {}
