export enum RequestTitle {
    Request = "New request",
    PayrolReport = "Payroll report for review and approval",
}

export enum RequestActionButtonLabel {
    Complete = "Complete request",
    Review = "Send for review",
    MarkAllAsComplete = "Mark all as complete",
    AddItem = "+ Add a new item",
    SaveClose = "Save and close",
    Close = "Close",
    Create = "Create",
    NextStep = "Next step",
}

export enum RequestCompleteModalData {
    Title = "Complete request for information",
    Subhead = "Are you sure you want to complete this request? If yes, you won't be able to add further information",
}

export enum InstructionsRequestCompleteModalData {
    Title = "Complete instruction",
    Subhead = "Are you sure you want to complete this instruction?",
}

export enum InstructionsRequestSkippedModalData {
    Title = "Skip instruction",
    Subhead = "Are you sure you want to skip this instruction?",
}

export enum RfiRequestReviewModalData {
    Title = "Are you sure you want to send this Request for information for review?",
    Subhead = "Changes will be saved and sent to your Visory team for attention.",
}

export enum PayrunRequestReviewModalData {
    Title = "Are you sure you want to send this report for review?",
    Subhead = "Changes will be saved and sent to your Visory team for attention.",
}

export enum PayRunRequestCompleteRequestModalData {
    Title = "Are you sure you want to approve this pay run?",
    Subhead = "No further changes can be made and we will process this pay run.",
}

export enum PayRunRequestActionButtonText {
    Complete = "Approve payrun",
    Review = "Send for review",
}

export enum VaultCardType {
    VaultRequest = "vault-request",
    VaultPayrollApprovalRequest = "vault-request-approval-payrun",
    VaultInstructionsRequest = "vault-request-instructions",
}

export enum RequestStatuses {
    Approved = "Approved",
    Skipped = "Skipped",
    Pending = "Pending",
    Completed = "Completed",
    Cancelled = "Cancelled",
}

export enum RequestProgressTypes {
    Skipped = "warning",
    Default = "default",
    Success = "success",
    Error = "error",
}
