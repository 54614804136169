import { HttpClient } from "@angular/common/http";
import { computed, Inject, Injectable, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { IPermissionMatrix, Permission, PermissionsMatrices, Role } from "@visoryplatform/threads";
import { EMPTY, forkJoin, Observable, of, zip } from "rxjs";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { AuthService } from "../../findex-auth";

export enum DefaultPermissions {
    UpdateInternalWorkflow = "UpdateInternalWorkflow",
    VcEndSession = "EndSession",
}

@Injectable({ providedIn: "root" })
export class PermissionService {
    private permissions$: Observable<IPermissionMatrix>;
    private globalRole$: Observable<Role>;

    private readonly permissions: Signal<IPermissionMatrix>;
    private readonly globalRole: Signal<Role>;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.permissions$ = this.authService.getUserWithoutRole().pipe(
            switchMap((user) => {
                if (!user) {
                    return EMPTY;
                }
                return this.getPermissions().pipe(map((matrices) => this.mergeMatrices(matrices)));
            }),
            shareReplay(1),
        );

        this.globalRole$ = this.authService.getUser().pipe(
            map((user) => user?.globalRole),
            shareReplay(1),
        );

        this.permissions = toSignal(this.permissions$);
        this.globalRole = toSignal(this.globalRole$);
    }

    hasSomePermission(role: Role, permissionsToCheck: string | string[]): Observable<boolean> {
        const permissions = [].concat(permissionsToCheck);

        const hasPermissions$ = permissions.map((permission) => this.checkPermissions(role, permission));
        return forkJoin(hasPermissions$).pipe(
            map((hasPermissions) => hasPermissions.some((hasPermission) => hasPermission)),
        );
    }

    checkPermissionSignal(permissionsToCheck: string | string[]): Signal<boolean> {
        return computed(() => {
            if (!permissionsToCheck) {
                return false;
            }

            const mergedMatrix = this.permissions();
            const globalRole = this.globalRole();
            const arr = [].concat(permissionsToCheck);

            if (globalRole && !Role[globalRole]) {
                return false;
            }

            const globalHasPermission = this.matrixHasPermissions(mergedMatrix, globalRole, arr);

            if (globalHasPermission) {
                return true;
            }

            return false;
        });
    }

    // TODO: Refactor to not pass in the role, this method is only using globalRole for permissions
    checkPermissions(role: Role | undefined, permissionsToCheck: string | string[]): Observable<boolean> {
        if (!permissionsToCheck || !Role[role]) {
            return of(false);
        }

        const arr = [].concat(permissionsToCheck);
        const permissions$ = this.permissions$.pipe(take(1));
        const currentGlobalRole$ = this.globalRole$.pipe(take(1));

        return zip(permissions$, currentGlobalRole$).pipe(
            map(([mergedMatrix, globalRole]) => {
                const globalHasPermission = this.matrixHasPermissions(mergedMatrix, globalRole, arr);
                if (globalHasPermission) {
                    return true;
                }

                return false;
            }),
        );
    }

    assertPermissions(role: Role, permissions: string | string[]): Observable<true> {
        return this.checkPermissions(role, permissions).pipe(
            map((authorised) => {
                if (!authorised) {
                    throw new Error("Unauthorised");
                }

                return true;
            }),
        );
    }

    private mergeMatrices(matrices: PermissionsMatrices): IPermissionMatrix {
        //What even is the point of namespacing these if we merge them together like this....? not cleaning now
        return [
            matrices.threadPermissionMatrix,
            matrices.accountPermissionMatrix,
            matrices.appLevelPermissionMatrix,
        ].reduce((merged, matrix) => {
            for (const [roleName, permissions] of Object.entries(matrix)) {
                merged[roleName] = Object.assign({}, merged[roleName], permissions);
            }
            return merged;
        }, {});
    }

    private matrixHasPermissions(matrix: IPermissionMatrix, role: Role, permissions: string[]): boolean {
        const mergedPermissions = matrix[Role[role]];
        if (!mergedPermissions) {
            return false;
        }

        return permissions.every((permission) => mergedPermissions[permission] === Permission.Allow);
    }

    private getPermissions(): Observable<PermissionsMatrices> {
        const { permissions } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;

        const url = `${base}${permissions}`;
        return this.http.get<PermissionsMatrices>(url);
    }
}
