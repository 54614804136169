import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IThread } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "../../../../src/app/injection-token";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "../../../portal-modules/src/lib/environment/environment.common";

@Injectable({ providedIn: "root" })
export class InternalChatService {
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    getInternalChat(accountId: string): Observable<IThread> {
        const { accounts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}/thread/internalChat`;
        return this.http.get<IThread>(url);
    }
}
