import { Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { IParticipant, ITimeline, IUserSetupRequest, Role } from "@visoryplatform/threads";
import { WorkflowSteps } from "@visoryplatform/workflow-core";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { IParticipantDetail } from "../../../../interfaces/IParticipantDetail";
import { ParticipantService } from "../../../../services/participant.service";
import { PermissionService } from "../../../../services/permissions.service";
import { ThreadAddParticipantComponent } from "./thread-add-participant/thread-add-participant.component";
import { ThreadRemoveParticipantComponent } from "./thread-remove-participant/thread-remove-participant.component";

const isIUserSetupRequest = (data: any): data is IUserSetupRequest =>
    data && data.clients && data.threads && data.additionalInformation;

@Component({
    selector: "thread-participants",
    templateUrl: "./thread-participants.component.html",
    styleUrls: ["./thread-participants.component.scss"],
})
export class ThreadParticipantsComponent implements OnChanges {
    @Input() thread: ITimeline;
    @Input() role: Role;
    @Input() showBadge = true;
    @Input() originX: "start" | "end" | "center" = "end";

    readonly addParticipantsButton = this.environment.featureFlags.addParticipants;
    readonly threadParticipantDetails = this.environment.featureFlags.threadParticipantDetail;
    readonly gaEvents = GA_EVENTS;

    threadParticipants: IParticipantDetail[] = [];
    roles = Role;
    showDialog = false;
    allowRemoveParticipant: boolean;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private permissionService: PermissionService,
        private participantsService: ParticipantService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.thread && changes.thread.currentValue.participants) {
            await this.setParticipantData();

            this.allowRemoveParticipant = this.checkThreadAllowsRemoveParticipant(changes.thread?.currentValue);
        }
    }

    async setParticipantData(): Promise<void> {
        try {
            this.threadParticipants = await this.getThreadParticipants(this.thread);
        } catch (err) {
            this.threadParticipants = this.thread.participants;
        }
    }

    async openDialog(): Promise<void> {
        const modalData = await this.dialog
            .open(ThreadAddParticipantComponent, {
                data: { thread: this.thread, role: this.role },
                disableClose: true,
                panelClass: ["threads-add-participants-modal", "timeline-participants"],
            })
            .afterClosed()
            .toPromise();

        if (isIUserSetupRequest(modalData)) {
            //  TODO: ED-1193 race condition from thread endpoint prevents usernames from being returned, fix that and remove this workaround
            this.addInvitedParticipants(modalData);
        }
    }

    addInvitedParticipants(data: IUserSetupRequest): void {
        const currentSetParticipants = this.threadParticipants;

        const filteredParticipants = currentSetParticipants.filter((participant) => !!participant.profile);

        const newParticipants = [
            ...filteredParticipants,
            ...data.clients.map((client) => ({
                profile: {
                    ...client,
                    name: client.firstName + " " + client.lastName,
                },
            })),
        ];

        this.threadParticipants = newParticipants;
    }

    async openProfile(participantId: string): Promise<void> {
        const canOpenProfile = await this.permissionService.checkPermissions(this.role, "ReadProfile").toPromise();
        if (canOpenProfile) {
            this.router.navigate(["/admin", "clients", participantId]);
        }
    }

    remove(participant: IParticipantDetail): void {
        this.dialog.open(ThreadRemoveParticipantComponent, {
            panelClass: "centered-modal",
            data: { participant, thread: this.thread },
        });
    }

    private checkThreadAllowsRemoveParticipant(thread: ITimeline): boolean {
        if (!thread) {
            return null;
        }
        const hasTwoParticipants = thread.participants.length > 2;
        const allowUpdateParticipant =
            hasTwoParticipants && this.addParticipantsButton && !thread.restrictCardsToInternal;

        return allowUpdateParticipant;
    }

    private async getThreadParticipants(thread: ITimeline): Promise<IParticipant[] | IParticipantDetail[]> {
        const participants = thread.participants;
        const workflowSteps = thread.workflow.steps;

        if (thread.workflowConfigurationId) {
            return this.getWorkflowParticipants(participants, workflowSteps);
        } else {
            return participants;
        }
    }

    private async getWorkflowParticipants(
        participants: IParticipant[],
        workflowSteps: WorkflowSteps,
    ): Promise<IParticipantDetail[]> {
        const uniqueAssignees = this.participantsService.getThreadAssignees(workflowSteps);
        return this.getParticipantsDetails(participants, uniqueAssignees);
    }

    private getParticipantsDetails(
        participants: IParticipantDetail[],
        uniqueAssignees: string[],
    ): IParticipantDetail[] {
        return participants.map((participant) => {
            const businessName = participant.role === Role.Client ? this.thread.account?.label : null;
            const isAssignee = !!uniqueAssignees.find((assigneeId) => assigneeId === participant.id);

            return { ...participant, isAssignee, businessName };
        });
    }
}
