import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { ErrorHandler, Inject, Injectable } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { combineLatest, EMPTY, lastValueFrom, merge, Observable, of, zip } from "rxjs";
import { filter, map, mapTo, shareReplay, switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { HandledError } from "../../shared/interfaces/errors";
import { StorageService } from "../../shared/services/storage.service";
import { ThreadsService } from "../../threads-ui/services/threads.service";
import { UserProfileService } from "../../user-profile/services/user-profile.service";
import { AppUser, AuthStrategy } from "../model/AppUser";
import { AuthorizationLevel } from "../model/AuthorizationLevel";
import { LoginChallengeResult, LoginStep, LoginStepDetails } from "../model/LoginStep";
import { AzureAdAuthStrategy } from "./strategies/azure-ad-auth.strategy";
import { CognitoAuthStrategy } from "./strategies/cognito-auth.strategy";

interface WebServiceStatusResponse {
    data: { status: string };
    message?: string;
}

type ImpersonatedUser = {
    user: AppUser;
    token: string;
    expiresAt: string;
};

const IMPERSONATION_TOKEN_KEY = "visory-impersonate-token";

@Injectable({ providedIn: "root" })
export class AuthService implements HttpInterceptor {
    private readonly user$: Observable<AppUser>;
    private impersonatedUser: ImpersonatedUser;

    constructor(
        private activeDirectoryStrategy: AzureAdAuthStrategy,
        private cognitoStrategy: CognitoAuthStrategy,
        private http: HttpClient,
        private errorHandler: ErrorHandler,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private threadsService: ThreadsService,
        private storageService: StorageService,
        private userProfileService: UserProfileService,
    ) {
        this.impersonatedUser = this.storageService.getItem(IMPERSONATION_TOKEN_KEY);
        if (this.impersonatedUser) {
            this.manageImpersonationSession();
        }

        this.user$ = this.getUserWithoutRole().pipe(
            switchMap((user) => {
                if (!user) {
                    return of(null);
                }

                if (user.authorizationLevel !== AuthorizationLevel.VERIFIED) {
                    return of(user);
                }

                return this.threadsService
                    .getGlobalRole(user.id)
                    .pipe(map((globalRole) => ({ ...user, globalRole: globalRole ? globalRole : Role.Client })));
            }),
            shareReplay(1),
        );
    }

    isImpersonated(): boolean {
        return !!this.impersonatedUser;
    }

    async impersonate(userId: string): Promise<void> {
        const { base } = this.environment.threadsEndpoints;
        const { users, impersonate } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}/${userId}${impersonate}`;

        const currentUser = await lastValueFrom(this.getUserWithoutRole().pipe(take(1)));
        const token = await lastValueFrom(this.http.post<string>(url, {}));
        if (!token) {
            throw new Error("Failed to impersonate user");
        }

        const user = await lastValueFrom(this.userProfileService.getUserProfile(userId));
        if (!user) {
            throw new Error("Failed to impersonate user");
        }

        const [, payload] = token.split(".");
        const decodedPayload = JSON.parse(atob(payload));
        const expiresAt = decodedPayload.exp ? DateTime.fromSeconds(decodedPayload.exp).toISO() : null;

        this.impersonatedUser = {
            user: {
                ...user,
                authorizationLevel: AuthorizationLevel.VERIFIED,
                emailAddressVerified: true,
                mobileNumberVerified: true,
                type: currentUser.type,
            },
            token,
            expiresAt,
        };
        this.storageService.setItem(IMPERSONATION_TOKEN_KEY, JSON.stringify(this.impersonatedUser));

        window.location.href = "/";
    }

    stopImpersonation(): void {
        this.impersonatedUser = null;
        this.storageService.removeItem(IMPERSONATION_TOKEN_KEY);
        window.location.reload();
    }

    getLogin(): Observable<LoginStepDetails> {
        return merge(this.activeDirectoryStrategy.getLogin(), this.cognitoStrategy.getLogin());
    }

    onLoginSuccess(): Observable<AppUser> {
        return this.getLogin().pipe(
            filter((loginStepDetails) => loginStepDetails && loginStepDetails.step === LoginStep.LOGIN_COMPLETE),
            switchMap(() => this.getUserWithoutRole()),
        );
    }

    loginAsStaff(): Observable<LoginStepDetails> {
        return this.activeDirectoryStrategy.startLogin();
    }

    switchUser(): void {
        this.activeDirectoryStrategy.switchUser(); // only for staff atm
    }

    loginWithEmail(emailAddress: string, password: string): Observable<LoginStepDetails> {
        return this.cognitoStrategy.startLogin(emailAddress.toLowerCase(), password);
    }

    completeTwoFactor(code: string, rememberDevice: boolean): Observable<LoginChallengeResult> {
        return this.cognitoStrategy.completeTwoFactor(code, rememberDevice);
    }

    reLogin() {
        return this.cognitoStrategy.reLogin();
    }

    completeNewPassword(userDetails: any, newPassword: string): Observable<void> {
        return this.cognitoStrategy.completeNewPassword(userDetails, newPassword);
    }

    beginVerifyMobileNumber(mobileNumber: string): Observable<{ status: string; message: string }> {
        return this.cognitoStrategy.beginVerifyMobileNumber(mobileNumber);
    }

    confirmMobileNumber(code: string): Observable<{ status: string; message: string }> {
        return this.cognitoStrategy.confirmVerifyMobileNumber(code);
    }

    confirmEmail(code: string): Observable<{ status: string; message: string }> {
        return this.cognitoStrategy.confirmVerifyEmailAddress(code);
    }

    logout(): Observable<void> {
        if (this.impersonatedUser) {
            this.stopImpersonation();
            return of(null);
        }

        return zip(this.activeDirectoryStrategy.logout(), this.cognitoStrategy.logout()).pipe(mapTo(null));
    }

    getUser(): Observable<AppUser> {
        return this.user$;
    }

    getValidUser(): Observable<AppUser> {
        return this.user$.pipe(
            filter((user) => !!user),
            shareReplay(1),
        );
    }

    getGlobalRole(): Observable<Role> {
        const user$ = this.getValidUser();
        return user$.pipe(map((user) => user.globalRole));
    }

    getUserId(): Observable<string> {
        const user$ = this.getUser().pipe(filter((user) => !!user));
        return user$.pipe(map((user) => user.id));
    }

    getVerifiedHttpHeaders(endpoint: string): Observable<Record<string, string>> {
        return this.getUserWithoutRole().pipe(
            take(1),
            switchMap((user) => {
                if (user.authorizationLevel < AuthorizationLevel.NOMINAL) {
                    return EMPTY;
                }
                return this.userStrategyHeaders(user, endpoint);
            }),
        );
    }

    getUserWithoutRole(): Observable<AppUser> {
        if (this.impersonatedUser) {
            return of(this.impersonatedUser?.user);
        }

        return combineLatest([this.activeDirectoryStrategy.getUser(), this.cognitoStrategy.getUser()]).pipe(
            map(([staffUser, cognitoUser]) => cognitoUser || staffUser),
            map((user) => {
                if (!user) {
                    return null;
                }

                return user;
            }),
        );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.getUserWithoutRole().pipe(
            take(1),
            switchMap((user) => this.userStrategyInterceptor(user, req, next)),
        );
    }

    async refreshUserTokens() {
        await this.cognitoStrategy.refreshTokens();
    }

    async checkSignUpStatus(emailAddress: string): Promise<{
        success: boolean;
        loginRequired: boolean;
        verificationSent: boolean;
        errorMessage?: string;
    }> {
        const { base } = this.environment.auth;
        const { endpoints } = environmentCommon.auth;
        const url = `${base}${endpoints.checkUser}`;

        const body = {
            emailAddress: emailAddress.toLowerCase(),
            userPoolClientId: this.environment.auth.userPoolWebClientId,
            redirectUrl: this.environment.registration.redirectUrl,
            errorRedirectUrl: this.environment.errorRedirectUrl,
            themeName: this.environment.appTheme,
        };
        try {
            const result = await this.http.post<WebServiceStatusResponse>(url, body).toPromise();
            const { status } = result.data ? result.data : { status: "UNKNOWN" };
            switch (status) {
                case "PROCEED_WITH_SIGNUP":
                    return {
                        success: true,
                        loginRequired: false,
                        verificationSent: false,
                    };
                case "VERIFICATION_SENT":
                    return {
                        success: true,
                        loginRequired: false,
                        verificationSent: true,
                    };
                case "LOGIN":
                case "PASSWORD_CHANGE_REQUIRED":
                    return {
                        success: true,
                        loginRequired: true,
                        verificationSent: false,
                    };
            }
        } catch (errorResponse) {
            this.handleError(errorResponse);
        }
        return {
            success: false,
            errorMessage: "Sorry, something went wrong",
            loginRequired: false,
            verificationSent: false,
        };
    }

    async beginForgotPassword(emailAddress: string): Promise<{ success: boolean; errorMessage?: string }> {
        const { base } = this.environment.auth;
        const { endpoints } = environmentCommon.auth;
        const url = `${base}${endpoints.forgotPassword}`;

        const body = {
            emailAddress,
            userPoolClientId: this.environment.auth.userPoolWebClientId,
            redirectUrl: this.environment.auth.forgotPasswordRedirect,
            themeName: this.environment.appTheme,
        };
        try {
            const result = await this.http.post<WebServiceStatusResponse>(url, body).toPromise();
            const status = result.data ? result.data.status : "";
            if (status === "OK") {
                return { success: true };
            }
            return { success: false, errorMessage: result.message };
        } catch (errorResponse) {
            this.handleError(errorResponse);
            return { success: false, errorMessage: errorResponse.error.message };
        }
    }

    async confirmPasswordReset(
        userName: string,
        code: string,
        newPassword: string,
    ): Promise<{ success: boolean; errorMessage?: string }> {
        const { base } = this.environment.auth;
        const { endpoints } = environmentCommon.auth;
        const url = `${base}${endpoints.forgotPasswordConfirm}`;

        const body = {
            userName,
            userPoolClientId: this.environment.auth.userPoolWebClientId,
            code,
            newPassword,
        };
        try {
            const result = await this.http.post<WebServiceStatusResponse>(url, body).toPromise();
            const status = result.data ? result.data.status : "";
            if (status === "OK") {
                return { success: true };
            }
            return { success: false, errorMessage: result.message };
        } catch (errorResponse) {
            return { success: false, errorMessage: errorResponse.error.message };
        }
    }

    isExternal(userId: string): boolean {
        return userId?.slice(0, 8) === "azuread-";
    }

    private handleError(errors: Error) {
        try {
            if ("error" in errors) {
                const errorContent = (errors as any).error;
                const status = errorContent.data ? errorContent.data.status : "";
                //Some 4xx responses are expected base on user behaviour, e.g. throttling. Only report actual errors.
                if (!status.toUpperCase().includes("ERROR")) {
                    return;
                }
            }
            this.errorHandler.handleError(new HandledError(errors));
            // eslint-disable-next-line no-empty
        } catch (err) {}
    }

    private manageImpersonationSession(): void {
        if (this.isImpersonationTokenExpired()) {
            this.stopImpersonation();
        } else if (this.impersonatedUser) {
            const expiresAt = DateTime.fromISO(this.impersonatedUser.expiresAt);
            const timeRemaining = expiresAt.diffNow().toMillis() - 1000 * 60;
            setTimeout(() => this.manageImpersonationSession(), timeRemaining);
        }
    }

    private isImpersonationTokenExpired(): boolean {
        if (!this.impersonatedUser) {
            return false;
        }

        return DateTime.fromISO(this.impersonatedUser.expiresAt) < DateTime.now();
    }

    private userStrategyInterceptor(
        user: AppUser,
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if (!user) {
            return next.handle(req);
        }

        const impersonationHeaders = this.getImpersonationHeader(req.url);
        const updatedReq = req.clone({ setHeaders: impersonationHeaders });

        if (user.type === AuthStrategy.Cognito) {
            return this.cognitoStrategy.intercept(updatedReq, next);
        }

        return this.activeDirectoryStrategy.intercept(updatedReq, next);
    }

    private getImpersonationHeader(endpoint: string): Record<string, string> {
        if (!this.impersonatedUser?.token) {
            return {};
        }

        if (!this.supportsImpersonation(endpoint)) {
            return {};
        }

        return {
            "X-IMPERSONATE-TOKEN": this.impersonatedUser?.token,
        };
    }

    private supportsImpersonation(endpoint: string): boolean {
        const impersonationResources = this.environment.auth.impersonationResources;
        return impersonationResources.some((resource) => endpoint.startsWith(resource));
    }

    private userStrategyHeaders(user: AppUser, endpoint: string): Observable<Record<string, string>> {
        if (!user) {
            return of({});
        }

        const impersonationHeaders = this.getImpersonationHeader(endpoint);

        if (user.type === AuthStrategy.Cognito) {
            return this.cognitoStrategy.getHttpHeaders();
        }

        return this.activeDirectoryStrategy
            .getHttpHeaders()
            .pipe(map((headers) => ({ ...headers, ...impersonationHeaders })));
    }
}
