import { Component, HostListener, Inject, NgZone, OnDestroy, OnInit, ViewChild, WritableSignal } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
    NavigationEnd,
    NavigationStart,
    Route,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router,
} from "@angular/router";
import * as Sentry from "@sentry/angular";
import { Notification, PossibleDeliveryData } from "@visoryplatform/notifications-core";
import { InternalRoles, Role } from "@visoryplatform/threads";
import { SystemStepId } from "@visoryplatform/workflow-core";
import { AnnouncekitComponent } from "announcekit-angular";
import { NotificationsService } from "projects/notifications-frontend/src/services/notifications.service";
import { AnalyticsService, GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AnnounceService } from "projects/portal-modules/src/lib/announce/services/announce.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth/model/AppUser";
import { AuthorizationLevel } from "projects/portal-modules/src/lib/findex-auth/model/AuthorizationLevel";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth/services/auth.service";
import { OnboardingService } from "projects/portal-modules/src/lib/onboarding/services/onboarding.service";
import { ILibrary, RouteHelper } from "projects/portal-modules/src/lib/plugins";
import { RouteExtension } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { HandledError } from "projects/portal-modules/src/lib/shared/interfaces/errors";
import { Tour } from "projects/portal-modules/src/lib/shared/interfaces/tours";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { ThemeService } from "projects/portal-modules/src/lib/shared/services/theme.service";
import { WindowListenersService } from "projects/portal-modules/src/lib/shared/services/window-listeners.service";
import { CloseThreadPromptComponent } from "projects/portal-modules/src/lib/threads-ui/components/close-thread-prompt/close-thread-prompt.component";
import { WorkflowExtensionService } from "projects/portal-modules/src/lib/threads-ui/services/workflow/workflow-extension.service";
import { UserProfileService } from "projects/portal-modules/src/lib/user-profile/services/user-profile.service";
import { combineLatest, EMPTY, merge, Observable, of, Subscription, zip } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take } from "rxjs/operators";
import { BannerNotificationsService } from "../../projects/portal-modules/src/lib/notifications";
import { Loader } from "../../projects/portal-modules/src/lib/shared/services/loader";
import { MenuService, MenuType } from "../../projects/portal-modules/src/lib/shared/services/menu.service";
import { PromptUpdateService } from "../../projects/portal-modules/src/lib/shared/services/prompt-app-update.service";
import { SystemStatusService } from "../../projects/portal-modules/src/lib/shared/services/system-status.service";
import { TourService } from "../../projects/portal-modules/src/lib/shared/services/tour.service";
import { ThreadsService } from "../../projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { routes, securedRoutes } from "./app-routing.module";
import { APP_ROUTE_LIBRARY, ENVIRONMENT, EXTENSION_DISPLAY_SERVICE } from "./injection-token";

type VisibleExtension = { id: string; label: string; icon: string; path: string };

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild("announcekitComponent", { static: false })
    announcekitComponent: AnnouncekitComponent;

    readonly showPaymentsSubscriptions = this.environment.featureFlags.showPaymentsSubscriptions;
    readonly showPaymentsBilling = this.environment.featureFlags.showPaymentsBilling;
    readonly subscriptionEnabled = this.environment.featureFlags.subscriptionEnabled;
    readonly supportEmail = this.environment.featureFlags.supportEmail;
    readonly showAccounts = this.environment.featureFlags.accountView;
    readonly showInsights = this.environment?.featureFlags?.insightsConfiguration?.showInsights;
    readonly canViewCalendarPage = this.environment.featureFlags.canViewCalendarPage;
    readonly announceKitWidgetId = this.environment.announceKitWidgetId;
    readonly roles = Role;
    readonly gaEvents = GA_EVENTS;
    readonly NOTIFICATION_COUNT_LIMIT = 99;
    readonly featureFlags = LaunchDarklyFeatureFlags;

    role: Role;
    user$: Observable<AppUser>;
    hideLayout: boolean; // test flag
    settingsNavigationActive: boolean;

    unreadNotificationCount = 0;
    showNavigation$: Observable<boolean>;
    menuToShow$: Observable<MenuType>;
    showNotificationMenu$: Observable<boolean>;
    showNotificationMobileMenu$: Observable<boolean>;
    showProfileMenu$: Observable<boolean>;
    showMobileCollapsedIcon$: Observable<boolean>;
    showCustomerRequestMenu$: Observable<boolean>;
    isCollapsedView = false;
    extensions$: Observable<VisibleExtension[]>;
    isIframe = false;
    bannerUpdates$: Observable<Notification<PossibleDeliveryData>[]>;
    isLaptopViewWidth: boolean;
    isStaffOrAdmin$: Observable<boolean>;
    announceKitUser$: Observable<{ id: string; email: string; name: string }>;
    announceKitData$: Observable<{ role: string }>;
    announceKitCounter: WritableSignal<number>;
    isImpersonated: boolean;

    private roleSub: Subscription;
    private eventSub: Subscription;
    private tourSubscription: Subscription;
    private unreadCountSub: Subscription;
    private unreadCountWsUpdateSub: Subscription;
    private analyticsAndRoleSub: Subscription;
    private manageToursSubscription: Subscription;
    private announceKitSubscription: Subscription;
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(APP_ROUTE_LIBRARY) library: ILibrary<RouteExtension>,
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        public loader: Loader,
        private authService: AuthService,
        private threadsService: ThreadsService,
        private analyticsService: AnalyticsService,
        private onboardingService: OnboardingService,
        private router: Router,
        private menuService: MenuService,
        public themer: ThemeService,
        private tourService: TourService,
        private userProfileService: UserProfileService,
        private promptAppUpdateService: PromptUpdateService,
        private systemStatusService: SystemStatusService,
        private windowListenersService: WindowListenersService,
        private bannerNotifications: BannerNotificationsService,
        private titleService: Title,
        private notificationService: NotificationsService,
        private featureFlagService: FeatureFlagService,
        private zone: NgZone,
        private announceService: AnnounceService,
        workflowExtensionService: WorkflowExtensionService,
    ) {
        this.isImpersonated = this.authService.isImpersonated();
        this.showNavigation$ = menuService.showNavigationMenu();
        this.showNotificationMobileMenu$ = menuService.showNotificationMenuMobile();
        this.showProfileMenu$ = menuService.showProfileMenu();
        this.showMobileCollapsedIcon$ = menuService.showMobileCollapsedIcon();
        this.showCustomerRequestMenu$ = menuService.showCustomerRequestMenu();

        this.eventSub = this.router.events.subscribe((event) => {
            if (event instanceof RouteConfigLoadStart) {
                this.loader.show();
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loader.hide();
            }
        });

        this.initExtensions(library);
        this.initWorkflowExtensions(workflowExtensionService);

        this.themer.apply();
        this.promptAppUpdateService.checkForUpdate();
        this.systemStatusService.checkForStatusChanges();
        this.setupWindowFocusListeners();
        this.setupAnnounceKit();
    }

    @HostListener("window:resize")
    windowResize(): void {
        this.isCollapsedView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthMenuBreakpoint,
        );

        if (this.isCollapsedView) {
            this.menuService.hide(MenuType.Navigation);
        } else {
            this.menuService.show(MenuType.Navigation);
        }

        this.menuService.hide(MenuType.NotificationsMobile);
        this.menuService.hide(MenuType.Profile);
        this.menuService.show(MenuType.CollapsedMenuIcon);

        // would prefer not to do it this way but you can't use HostListener in a service and wanting to avoid addEventListener
        this.windowListenersService.windowResized();
    }

    ngOnInit(): void {
        this.isIframe = window !== window.parent && !window.opener;
        this.loader.show();
        setTimeout(() => this.loader.hide());

        this.user$ = this.authService.getUser();

        this.announceKitUser$ = this.user$.pipe(
            filter((user) => !!user),
            map((user) => ({ id: user.id, email: user.details.emailAddress, name: user.name })),
        );

        const role$ = this.user$.pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
        );

        this.announceKitData$ = role$.pipe(map((role) => ({ role })));

        this.analyticsAndRoleSub = this.user$
            .pipe(
                filter((user) => !!user),
                switchMap((user) => {
                    this.setSentryScope(user);
                    this.initAnalytics(user);
                    this.onboardingService.checkStaffRole(user);
                    return this.featureFlagService.init(user);
                }),
            )
            .subscribe();

        this.roleSub = role$.subscribe((role) => (this.role = role));

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.toggleLayout(event);
            }
            if (event instanceof NavigationStart) {
                this.extensionDisplayService.close();
            }
        });

        this.isStaffOrAdmin$ = role$.pipe(map((role) => InternalRoles.includes(role)));

        const { tours } = this.environment.featureFlags;

        this.tourSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => this.urlMatchesTour(tours, event.url)),
            )
            .subscribe((showTour) => {
                return showTour && this.manageTour(tours);
            });

        this.authService
            .onLoginSuccess()
            .pipe(
                filter((user) => !!user),
                switchMap((user: AppUser) => {
                    if (user.authorizationLevel !== AuthorizationLevel.VERIFIED) {
                        return EMPTY;
                    }
                    const updateLastLogin$ = this.userProfileService.updateUserLastLogin(user.id);
                    const updateUserTimeZone$ = this.threadsService.updateUserTimeZone(user.id);
                    return zip(updateLastLogin$, updateUserTimeZone$);
                }),
            )
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .subscribe(() => {});

        const unreadCount$ = this.user$.pipe(
            filter((user) => !!user),
            switchMap(() => this.notificationService.getUnreadCount("activity")),
        );

        const unreadUpdates$ = this.user$.pipe(
            filter((user) => !!user),
            switchMap(() => this.notificationService.subscribeToChannel("activity")),
            debounceTime(1500),
            switchMap(() => this.notificationService.getUnreadCount("activity")),
        );

        this.unreadCountSub = merge(unreadCount$, unreadUpdates$)
            .pipe(
                distinctUntilChanged(),
                catchError((err: unknown) => {
                    throw new HandledError(err);
                }),
            )
            .subscribe((count) => {
                this.unreadNotificationCount = count;
                this.updatePageTitle(count);
            });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.bannerUpdates$ = this.user$.pipe(
            switchMap((user) => {
                if (!user) {
                    return of([]);
                }
                return this.bannerNotifications.getBanners();
            }),
        );
    }

    ngOnDestroy(): void {
        this.roleSub?.unsubscribe();
        this.unreadCountSub?.unsubscribe();
        this.unreadCountWsUpdateSub?.unsubscribe();
        this.tourSubscription?.unsubscribe();
        this.eventSub?.unsubscribe();
        this.analyticsAndRoleSub?.unsubscribe();
        this.manageToursSubscription?.unsubscribe();
        this.announceKitSubscription?.unsubscribe();
    }

    navigateToSettings(): void {
        void this.router.navigate(["/profile/notifications"]);
    }

    hideProfileMenu(): void {
        this.menuService.hide(MenuType.Profile);
    }

    toggleUserProfileDropdown(): void {
        this.menuService.hide(MenuType.NotificationsMobile);
        this.menuService.toggle(MenuType.Profile);
    }

    toggleRecentNotifications(): void {
        this.menuService.hide(MenuType.Profile);
        this.menuService.hide(MenuType.CustomerRequest);
        this.menuService.toggle(MenuType.NotificationsMobile);
    }

    toggleRequestMenu(): void {
        this.menuService.toggle(MenuType.CustomerRequest);
    }

    onWidgetUnread(unread: number): void {
        this.announceService.setUnread(unread);
    }

    async logout(): Promise<void> {
        this.loader.show();

        try {
            this.analyticsService.recordEvent("logout", "clicked");
            await this.authService.logout().toPromise();
            void this.router.navigateByUrl("/login");
        } finally {
            this.loader.hide();
        }
    }

    switchUser(): void {
        this.authService.switchUser();
    }

    menuAction(navigationOpen: boolean): void {
        if (navigationOpen) {
            this.menuService.show(MenuType.Navigation);
        } else {
            this.menuService.hide(MenuType.Navigation);
        }

        this.menuService.hide(MenuType.NotificationsMobile);
        this.menuService.hide(MenuType.CustomerRequest);
    }

    mobileMenuAction(navigationOpen: boolean): void {
        if (this.isCollapsedView) {
            if (navigationOpen) {
                this.menuAction(true);
            } else {
                this.menuAction(false);
            }
            this.analyticsService.recordEvent("side-menu", "toggle");
        } else {
            this.menuAction(true);
        }
    }

    recordEvent(event: string): void {
        if (event === "read") {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.APP_MARKASREADNOTIFICATION);
        } else if (event === "delete") {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.APP_DELETENOTIFICATION);
        } else if (event === "unread") {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.APP_MARKASUNREADNOTIFICATION);
        }
    }

    showAnnounceKitWidget($event: any): void {
        $event.preventDefault();
        this.menuService.show(MenuType.AnnounceKitWidget);
        this.announceService.setUnread(0);
    }

    private initExtensions(library: ILibrary<RouteExtension>): void {
        const loadedExtensions = library
            .listAll()
            .map(({ id, extension }) => this.loadExtension(securedRoutes, id, extension));

        this.router.resetConfig(routes);

        const visibleExtensions$ = RouteHelper.getVisibleExtensions(library);

        this.extensions$ = combineLatest(loadedExtensions).pipe(switchMap(() => visibleExtensions$));
    }

    private initWorkflowExtensions(workflowExtensionService: WorkflowExtensionService): void {
        workflowExtensionService.registerAction(SystemStepId.End, CloseThreadPromptComponent, {
            panelClass: ["mat-dialog-overflow", "mat-dialog-no-container"],
            maxWidth: "420px",
            disableClose: true,
        });

        workflowExtensionService.registerAction(SystemStepId.Cancelled, CloseThreadPromptComponent, {
            panelClass: ["mat-dialog-overflow", "mat-dialog-no-container"],
            maxWidth: "420px",
            disableClose: true,
        });
    }

    private loadExtension(
        siblingRoutes: Route[],
        id: string,
        extension: RouteExtension,
    ): Observable<VisibleExtension | null> {
        const { label, icon, route, showExtension } = extension;

        const visibleRoute = {
            id,
            label,
            path: route.path,
            icon,
        };

        siblingRoutes.unshift(route);

        return showExtension().pipe(map((visible) => (visible ? visibleRoute : null)));
    }

    private setupWindowFocusListeners(): void {
        this.zone.runOutsideAngular(() => {
            window.addEventListener(
                "focus",
                () => this.analyticsService.recordEvent("window-attention", "focus"),
                false,
            );
            window.addEventListener("blur", () => this.analyticsService.recordEvent("window-attention", "blur"), false);
        });
    }

    private setupAnnounceKit(): void {
        this.announceKitSubscription = this.menuService.showAnnounceKitWidget().subscribe((show) => {
            if (show) {
                this.announcekitComponent.widgetInstance.open();
            }
        });

        this.announceKitCounter = this.announceService.getUnread();
    }

    private getPageTitle(notificationCount: number): string {
        if (!notificationCount) {
            return this.environment.appName;
        }
        return `(${notificationCount}) ${this.environment.appName}`;
    }

    private setSentryScope(user: AppUser): void {
        const scopeUser = {
            id: user.id,
            email: user.details.emailAddress,
            username: user.name,
        };

        Sentry.getCurrentScope().setUser(scopeUser);
    }

    private updatePageTitle(unreadCount: number): void {
        const newTitle = this.getPageTitle(unreadCount);
        this.titleService.setTitle(newTitle);
    }

    private urlMatchesTour(tours: Tour[], url: string): boolean {
        if (!tours) {
            return false;
        }
        return tours.some((tour) => url.includes(tour.tourRoute));
    }

    private toggleLayout(event: NavigationEnd): void {
        this.isCollapsedView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthMenuBreakpoint,
        );

        if (!event && !event.urlAfterRedirects) {
            return;
        }

        const { urlAfterRedirects } = event;

        // TODO: ED-2364 refactor this - let pages register whether they want to hide layout and menu or not
        this.hideLayout =
            urlAfterRedirects.includes("/email-success") ||
            urlAfterRedirects.includes("/mobile-success") ||
            urlAfterRedirects.includes("/error") ||
            urlAfterRedirects.includes("/login") ||
            urlAfterRedirects.includes("/select-staff") ||
            urlAfterRedirects.includes("/onboarding-profile") ||
            urlAfterRedirects.includes("/onboarding-completion") ||
            urlAfterRedirects.includes("/reset-password") ||
            urlAfterRedirects.includes("/error");

        this.settingsNavigationActive =
            urlAfterRedirects.includes("/profile") ||
            urlAfterRedirects.includes("/subscription") ||
            urlAfterRedirects.includes("/billing");

        if (!this.hideLayout) {
            this.mobileMenuAction(false);
        }

        if (urlAfterRedirects.includes("/verify-mobile")) {
            this.menuAction(false);
            this.menuService.hide(MenuType.CollapsedMenuIcon);
        }
    }

    private initAnalytics(user: AppUser): void {
        this.analyticsService.init(user);
    }

    private manageTour(tours: Tour[]): void {
        if (!tours) {
            this.tourService.hideTour();
            return;
        }

        const $user = this.user$.pipe(
            filter((userObj) => !!userObj),
            take(1),
        );

        const featureFlags$ = this.featureFlagService.getFlags().pipe(take(1));

        this.manageToursSubscription = combineLatest([$user, featureFlags$])
            .pipe(
                switchMap(([user, featureFlags]) => {
                    return this.tourService.queueTours(user, featureFlags, tours);
                }),
            )
            .subscribe();
    }
}
