import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { map, take } from "rxjs/operators";
import { GA_EVENTS_PREFIX } from "../../analytics/services/gtagAnalytics.service";
import {
    UnsavedConfirmModalComponent,
    UnsavedConfirmModalParams,
} from "../components/unsaved-confirm-modal/unsaved-confirm-modal.component";

const DEFAULT_KEEP_EDITING_TEXT =
    "There are unsaved changes. If you would like to save changes, press the 'Keep editing' button.";
@Injectable({
    providedIn: "root",
})
export class UnsavedModalDialogService {
    public dialogRef: MatDialogRef<UnsavedConfirmModalComponent>;
    public isDirty: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public analyticsPrefix: string = GA_EVENTS_PREFIX.DEFAULT_UNSAVE_ANALYTICS_PREFIX;
    public dialogParamsDefaults: UnsavedConfirmModalParams = {
        promptText: "Unsaved Changes",
        confirmText: "Keep editing",
        analyticsPrefix: GA_EVENTS_PREFIX.DEFAULT_UNSAVE_ANALYTICS_PREFIX,
        declineText: "Close without saving",
        areYouSureText: DEFAULT_KEEP_EDITING_TEXT,
    };

    constructor(private dialog: MatDialog) {}

    public open(params: UnsavedConfirmModalParams, panelClass: string[] = ["centered-modal"], width = "540px") {
        this.dialogRef = this.dialog.open(UnsavedConfirmModalComponent, {
            data: params,
            panelClass,
            width,
        });
    }

    public confirm() {
        return this.dialogRef.afterClosed().pipe(
            take(1),
            map((res) => res),
        );
    }

    public confirmClose(analyticsPrefix: string, panelClass?: string[], width?: string): Promise<boolean> {
        this.open(
            {
                ...this.dialogParamsDefaults,
                analyticsPrefix,
            },
            panelClass,
            width,
        );
        return this.confirm()
            .pipe(map((confirmed) => (confirmed ? true : false)))
            .toPromise();
    }
}
