import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { customValidators, getPasswordErrorMessage } from "projects/portal-modules/src/lib/shared/validators";
import { UserProfileService } from "../../services/user-profile.service";

@Component({
    selector: "change-password-form",
    templateUrl: "./change-password-form.component.html",
    styleUrls: ["./change-password-form.component.scss"],
})
export class ChangePasswordFormComponent implements OnInit, OnDestroy {
    form = new UntypedFormGroup(
        {
            oldPassword: new UntypedFormControl(null, [Validators.required]),
            newPassword: new UntypedFormControl(null, [Validators.required, customValidators.passwordValidator]),
            confirmPassword: new UntypedFormControl(null, [Validators.required, customValidators.passwordValidator]),
        },
        customValidators.passwordsMatchValidator("newPassword", "confirmPassword"),
    );

    showLoader: boolean;
    errorMessage = "";
    successMessage = "";

    @Output() passwordUpdated: EventEmitter<void> = new EventEmitter<void>();

    constructor(private userProfileService: UserProfileService) {}

    async ngOnInit() {
        this.form.setValue({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
    }

    ngOnDestroy(): void {}

    async updatePassword() {
        const oldPassword = this.form.controls.oldPassword.value;
        const newPassword = this.form.controls.newPassword.value;

        this.showLoader = true;
        this.userProfileService.updateCurrentUserProfile({ oldPassword, newPassword }).subscribe(
            () => {
                this.showLoader = false;
                this.successMessage = "Password was changed successfully.";
            },
            (error) => {
                this.showLoader = false;
                this.errorMessage = error.message || "An unknown error occurred.";
            },
        );
    }

    getPasswordError(controlName: string, passwordMatchError: boolean): string {
        return getPasswordErrorMessage(controlName, passwordMatchError === false);
    }
}
