import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ISystemStatus } from "@visoryplatform/threads";
import { EMPTY, Observable, of, Subscription, timer } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, pairwise, share, startWith, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { UiToastComponent } from "../../threads-ui/components/toast/ui-toast.component";
import { ErrorService } from "./error.service";

enum SystemStatus {
    ONLINE = "online",
    MAINTENANCE = "maintenance",
}

@Injectable({ providedIn: "root" })
export class SystemStatusService implements OnDestroy {
    private readonly POLL_MINUTES = 1000 * 60;
    private openMaintenanceSubscription: Subscription;
    private openRefreshSubscription: Subscription;

    constructor(
        private dialog: MatDialog,
        private errorService: ErrorService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private http: HttpClient,
    ) {}

    checkForStatusChanges(): void {
        const systemStatus$ = timer(0, this.POLL_MINUTES).pipe(
            switchMap(() => this.getSystemStatus()),
            startWith(SystemStatus.ONLINE),
            distinctUntilChanged(),
            pairwise(),
            share(),
        );

        this.openMaintenanceSubscription = systemStatus$
            .pipe(filter(([prev, curr]) => this.shouldOpenMaintenanceDialog(prev, curr)))
            .subscribe(() => this.openOutageDialog());

        this.openRefreshSubscription = systemStatus$
            .pipe(filter(([prev, curr]) => this.shouldOpenRefreshDialog(prev, curr)))
            .subscribe(() => this.openRefreshDialog());
    }

    ngOnDestroy(): void {
        this.openMaintenanceSubscription?.unsubscribe();
        this.openRefreshSubscription?.unsubscribe();
    }

    private shouldOpenMaintenanceDialog(previousStatus: SystemStatus, currentStatus: SystemStatus): boolean {
        return currentStatus === SystemStatus.MAINTENANCE && previousStatus === SystemStatus.ONLINE;
    }

    private shouldOpenRefreshDialog(previousStatus: SystemStatus, currentStatus: SystemStatus): boolean {
        const isNotPreviousOnline = previousStatus !== SystemStatus.ONLINE;
        const isPreviousMaintenance = previousStatus === SystemStatus.MAINTENANCE;
        return currentStatus === SystemStatus.ONLINE && isNotPreviousOnline && isPreviousMaintenance;
    }

    private getSystemStatus(): Observable<SystemStatus> {
        if (!navigator.onLine) {
            return EMPTY;
        }

        const { systemStatus } = environmentCommon.threadsEndpoints;
        const { cachedBaseSystem } = this.environment.publicEndpoints;
        const url = `${cachedBaseSystem}${systemStatus}`;

        return this.http.get<ISystemStatus>(url).pipe(
            map((response) => response.status),
            catchError(() => {
                console.error("Error getting system status");
                return of(SystemStatus.MAINTENANCE);
            }),
        );
    }

    private openOutageDialog(): void {
        this.errorService.disableDialog = true;
        const dialogRef = this.dialog.open(UiToastComponent, {
            closeOnNavigation: false,
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["centre-toast-modal"],
            data: {
                message: "Visory is currently down for maintenance and improvements",
                description:
                    "We will be back soon. Thanks for your patience.</br>If you require assistance email support@visory.com.au or call 1800 VISORY",
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            this.errorService.disableDialog = false;
        });
    }

    private openRefreshDialog(): void {
        this.errorService.disableDialog = true;
        const dialogRef = this.dialog.open(UiToastComponent, {
            closeOnNavigation: false,
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["centre-toast-modal"],
            data: {
                message: "A new version is available. Please refresh to get the latest version.",
                buttonLabel: "Refresh",
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            this.errorService.disableDialog = false;
            document.location.reload();
        });
    }
}
