@if (message()) {
    <div class="placeholder-container">
        <h3 class="placeholder-header">{{ message().header }}</h3>
        <div class="placeholder-content">
            <p class="placeholder-message">{{ message().message }}</p>
            <i
                class="las placeholder-icon"
                [class]="
                    currentStepId() === systemStepId.Cancelled
                        ? 'la-ban'
                        : currentStepId() === systemStepId.End
                          ? 'la-check-circle'
                          : 'la-clock'
                "
            ></i>
        </div>
    </div>
}
