import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ITimeline } from "@visoryplatform/threads";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap } from "rxjs/operators";
import { ButtonType } from "../../../shared/components/error/error.component";
import { ErrorService } from "../../../shared/services/error.service";
import { Loader } from "../../../shared/services/loader";
import { PortalService } from "../../../shared/services/portal.service";
import { ThreadUpdateService } from "../../../shared/services/thread-update-service";

@Injectable({
    providedIn: "root",
})
export class ThreadRouteService {
    constructor(
        private portalService: PortalService,
        private threadUpdateService: ThreadUpdateService,
        private errorService: ErrorService,
    ) {}

    getThread(route: ActivatedRoute, loader: Loader): Observable<ITimeline> {
        return this.getCurrentThread(route, loader);
    }

    private getCurrentThread(route: ActivatedRoute, loader: Loader): Observable<ITimeline> {
        const threadRouteId$ = route.params.pipe(
            filter((params) => params?.threadId),
            map((params) => params.threadId),
            distinctUntilChanged(),
            shareReplay(1),
        );

        const currentThread$ = threadRouteId$.pipe(
            switchMap((threadIdInRoute) => loader.wrap(this.portalService.getThreadListById(threadIdInRoute))),
            switchMap((thread) => this.threadUpdateService.getUpdatesByThread(thread)),
            distinctUntilChanged(),
            shareReplay(1),
            catchError((error: unknown) => {
                if (error instanceof HttpErrorResponse && error.status === 404) {
                    this.errorService.showErrorModal(
                        "Workflow not found",
                        "No workflow found for that ID",
                        ButtonType.GO_BACK,
                        true,
                        "/timelines",
                    );
                    return EMPTY;
                }
                return throwError(error);
            }),
        );

        return currentThread$;
    }
}
