import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BadgeType } from "projects/portal-modules/src/lib/findex-ui/components/fx-badge/fx-badge.component";
import { RequestProgressTypes, RequestStatuses } from "../constants/request.constants";

@Component({
    selector: "request-header",
    templateUrl: "./request-header.component.html",
    styleUrls: ["./request-header.component.scss"],
})
export class RequestHeaderComponent implements OnChanges {
    @Input() isComplete: boolean;
    @Input() isSkipped: boolean;
    @Input() title: string;
    @Input() completedStatusLabel: RequestStatuses;
    @Input() subTitle: string;
    @Input() accountName: string;

    readonly REQUEST_STATUSES = RequestStatuses;
    readonly REQUEST_PROGRESS = RequestProgressTypes;

    label: string;
    type: BadgeType;

    ngOnChanges(changes: SimpleChanges): void {
        const { isComplete, isSkipped } = changes;

        if (isComplete || isSkipped) {
            this.label = this.getLabel();
            this.type = this.getType();
        }
    }

    private getLabel(): string {
        if (this.isComplete && this.isSkipped) {
            return this.REQUEST_STATUSES.Skipped;
        }

        if (this.isComplete && !this.isSkipped) {
            return this.completedStatusLabel;
        }

        return this.REQUEST_STATUSES.Pending;
    }

    private getType(): BadgeType {
        if (this.isComplete && !this.isSkipped) {
            return this.REQUEST_PROGRESS.Success;
        }

        if (this.isSkipped && this.isComplete) {
            return this.REQUEST_PROGRESS.Skipped;
        }

        return this.REQUEST_PROGRESS.Default;
    }
}
