@if (FEATURE_FLAGS.DisableAdhocCreateCardBar | featureEnabled | async) {
    <div class="step-entry-context-menu-container">
        <v-context-menu
            [gutter]="false"
            [overlayPosition]="contextMenuPosition"
            [offsetY]="offsetY"
            [offsetX]="offsetX"
            (open)="openContextMenu($event)"
        >
            <div
                custom-button
                class="step-entry-context-menu-button"
            >
                @if (asIconButton) {
                    <button
                        class="step-entry-context-menu-icon-button"
                        tuiHint="More actions"
                        tuiHintDirection="right"
                    >
                        <div class="dots">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </button>
                } @else {
                    <button
                        class="fx-btn fx-btn--secondary step-entry-context-menu-text-button"
                        [ngClass]="textButtonClass()"
                    >
                        More actions
                    </button>
                }
            </div>
            @for (cardExtension of cardExtensions; track cardExtension.id) {
                @if (
                    !cardExtension.extension.hideFromMenu &&
                    (cardExtension.extension.featureFlags | featureEnabled | async) &&
                    (cardExtension.extension.permission
                        ? (role | permissions: cardExtension.extension.permission | async)
                        : true)
                ) {
                    <a
                        [analyticsClick]="cardExtension.extension.analyticsEvent"
                        [analyticsLabel]="
                            role === roles.Client && cardExtension.extension.title === 'Meeting'
                                ? 'Meeting booked by Client'
                                : ''
                        "
                        [tuiHint]="cardExtension.extension.tooltipMessage"
                        tuiHintDirection="top"
                        class="step-entry-context-menu-item"
                        (click)="
                            createCard(
                                cardExtension.extension.componentRef,
                                cardExtension.extension?.data,
                                cardExtension.extension?.config
                            )
                        "
                    >
                        <i
                            [ngClass]="cardExtension.extension.icon"
                            class="las step-entry-context-menu-icon"
                        ></i>
                        {{ cardExtension.extension.title }}
                    </a>
                }
            }
        </v-context-menu>
    </div>
}
