import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { BillApprovalActions, BillApprovalState, CardReply } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ENVIRONMENT, TASK_ACTION_LIBRARY } from "../../../../../src/app/injection-token";
import { ILibrary, TaskAction } from "../../../../portal-modules/src/lib/plugins";
import { ActionableCardComponent } from "../../../../portal-modules/src/lib/shared/components/actionable-card/actionable-card.component";
import { TaskActionService } from "../../../../portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { CardResources, THREAD_CARD_RESOURCES } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { BillApprovalService } from "../../services/bill-approval.service";

@Component({
    selector: "bill-approval-card",
    templateUrl: "./bill-approval-card.component.html",
    styleUrls: ["./bill-approval-card.component.scss"],
})
export class BillApprovalCardComponent extends ActionableCardComponent<boolean> implements OnInit, OnDestroy {
    readonly BILL_APPROVAL_VIEW = BillApprovalActions.ViewBillApproval;
    readonly BILL_APPROVAL_EDIT = BillApprovalActions.EditBillApproval;
    readonly allowEdit = this.environment.featureFlags.editCardDescription;

    hasPermissionToAction$: Observable<boolean>;
    hasPermissionToDelete$: Observable<boolean>;
    hasPermissionToEdit$: Observable<boolean>;
    isThreadActive$: Observable<boolean>;
    isFileReady$: Observable<boolean>;
    loader = new Loader();

    replies$: Observable<CardReply[]>;
    actionTotal$: Observable<number>;
    actionedPercentage$: Observable<number>;
    actionAmmount$: Observable<number>;
    isComplete$: Observable<boolean>;

    private navigationSub: Subscription;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) protected cardResources: CardResources<BillApprovalState>,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<boolean>>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private permissionService: PermissionService,
        private billApprovalService: BillApprovalService,
        private cardsService: ThreadCardService,
        protected taskActionService: TaskActionService,
    ) {
        super(cardResources, taskActionService);
    }

    ngOnInit(): void {
        const { role, replies$, state$ } = this.cardResources;

        this.isComplete$ = this.billApprovalService.getInvoiceItemStatus(state$);
        this.actionAmmount$ = this.billApprovalService.getTotalTransactionAmount(state$);
        this.actionTotal$ = this.billApprovalService.getTotalCompletedInvoiceItemAmount(state$);
        this.actionedPercentage$ = this.billApprovalService.getCompletedInvoiceItemPercentage(state$);

        this.hasPermissionToAction$ = this.permissionService.checkPermissions(role, "ReadBillApprovalCard");
        this.hasPermissionToDelete$ = this.permissionService.checkPermissions(role, "DeleteBillApprovalCard");
        this.hasPermissionToEdit$ = this.permissionService.checkPermissions(role, "UpdateBillApprovalCard");

        this.replies$ = replies$;

        this.isFileReady$ = state$.pipe(map((state) => !!state?.attachments?.fileId));

        if (!this.navigationSub) {
            this.navigationSub = this.cardResources.navigateTo$.subscribe(() => this.openViewModal(false));
        }
    }

    ngOnDestroy(): void {
        this.navigationSub?.unsubscribe();
    }

    async openViewModal(focusReplyInput: boolean): Promise<void> {
        await this.action(this.BILL_APPROVAL_VIEW, { focusReplyInput });
    }

    async openEditModal(focusReplyInput: boolean): Promise<void> {
        await this.action(this.BILL_APPROVAL_EDIT, { focusReplyInput });
    }

    deleteCard(threadId: string, cardId: string): void {
        this.loader.wrap(this.cardsService.deleteCard(threadId, cardId)).subscribe();
    }

    async updateCard(_threadId: string, _cardId: string): Promise<void> {
        await this.openEditModal(false);
    }
}
