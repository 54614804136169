import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Account } from "@visoryplatform/threads";
import { Subscription } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { GA_EVENTS } from "../../../analytics/services/gtagAnalytics.service";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { WindowListenersService } from "../../../shared/services/window-listeners.service";

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectAccountComponent),
    multi: true,
};

@Component({
    selector: "select-account",
    templateUrl: "./select-account.component.html",
    styleUrls: ["./select-account.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR],
})
export class SelectAccountComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() accounts: Account[];

    form = new FormControl("");
    readonly gaEvents = GA_EVENTS;
    readonly messageSizeQuotaInKB = 128;
    isMobileView = false;

    formSubscription: Subscription;
    onChange: (newVal: string) => void;
    onTouched?: () => void;

    constructor(
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
    }

    ngOnInit(): void {
        this.formSubscription = this.form.valueChanges.subscribe((value) => {
            this.onChange?.(value);
        });
    }

    ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    }

    writeValue(message: string): void {
        this.form.setValue(message);
    }

    registerOnChange(fn: (newVal: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }
}
