import { ComponentType } from "@angular/cdk/portal";
import { Component, EventEmitter, Inject, Input, OnChanges, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSortable, SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IAvatarContent } from "@visoryplatform/fx-ui";
import { Account, InternalRoles, IThreadListing, ITimeline, Role } from "@visoryplatform/threads";
import { DUE_DATE_EXTENSION_TYPE, IExtension, IStep } from "@visoryplatform/workflow-core";
import { NotificationsService } from "projects/notifications-frontend/src/services/notifications.service";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { IPaginatorSort } from "projects/portal-modules/src/lib/fx-table/components/fx-paginator/fx-paginator.component";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { combineLatest, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { EmptyStateImageType } from "../../../empty-state/components/empty-state.component";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { NgChanges } from "../../../shared/interfaces/ng-changes.interface";
import { CreateThreadModalComponent } from "../../../threads-ui/modules/create-thread";
import { CreateWorkflowModalComponent } from "../../../threads-ui/modules/create-thread/components/create-workflow-modal/create-workflow-modal.component";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { ThreadsEnrichmentService } from "../../../threads-ui/services/threads-enrichment.service";
import { WorkflowExtensionService } from "../../../threads-ui/services/workflow/workflow-extension.service";
import {
    IStateModalData,
    ThreadStateModalComponent,
} from "../../../workflow-status/components/thread-state-modal/thread-state-modal.component";

enum DashboardThreadsTableHeaders {
    Service = "Service",
    Account = "Account",
    Workflow = "Workflow",
    Status = "Task",
    DueDate = "Due date",
    LastActivity = "Last activity",
    Activity = "Activity",
    AssignedTo = "Assigned to",
    SlaRemaining = "SLA",
    WorkflowStatusIndicator = "Status",
}

type TableThreadListing = IThreadListing & {
    hasCancelTransition: boolean;
    updatedAt: string;
    dueDate: string;
    avatars$: Observable<IAvatarContent[]>;
};

@Component({
    selector: "timelines-table",
    templateUrl: "./timelines-table.component.html",
    styleUrls: ["./timelines-table.component.scss"],
})
export class TimelinesTableComponent implements OnChanges {
    @Input() threads: ITimeline[];
    @Input() userId: string;
    @Input() globalRole: Role;
    @Input() sortActive: string;
    @Input() sortDirection: SortDirection;
    @Input() disableSort: boolean;
    @Input() hideAccounts: boolean;

    @Output() sortByClick = new EventEmitter<IPaginatorSort>();

    readonly gaEvents = GA_EVENTS;
    readonly hideStatusCol = this.environment.featureFlags.hideDashboardThreadsStatusCol;
    readonly tableHeaders = DashboardThreadsTableHeaders;
    readonly emptyState = EmptyStateImageType;
    readonly jobStatusEnabled = this.environment.featureFlags.jobStatusEnabled;
    readonly showAccounts = this.environment.featureFlags.accountView;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    loader = new Loader();
    tableData = new MatTableDataSource<TableThreadListing>();
    loading: boolean;
    isInternalRole: boolean;

    createWorkflowModalEnabled$: Observable<boolean>;
    contextMenuVisible$: Observable<boolean>;
    updateInternalWorkflowPermission$: Observable<boolean>;
    createThreadPermission$: Observable<boolean>;
    showSlaRemaining$: Observable<boolean>;
    showWorkflowStatusIndicator$: Observable<boolean>;
    workflowDueDateEnabled$: Observable<boolean>;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private workflowExtensionService: WorkflowExtensionService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog,
        private threadsEnrichmentService: ThreadsEnrichmentService,
        private featureFlagService: FeatureFlagService,
        private permissionsService: PermissionService,
    ) {
        this.createWorkflowModalEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableCreateWorkflowModal,
        );

        this.workflowDueDateEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkflowDashboardDueDate,
        );

        this.showSlaRemaining$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableSlaRemainingTime);
        this.showWorkflowStatusIndicator$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkflowStatusIndicator,
        );
    }

    ngOnChanges(changes: NgChanges<TimelinesTableComponent>): void {
        const { threads, globalRole: role } = changes;

        if ((threads || role) && this.threads && this.globalRole) {
            const sortedThreads = this.disableSort ? this.threads : this.sortThreadsByLatest(this.threads);
            this.tableData.data = Object.freeze(this.threadsEnrichmentService.enrichThreads(sortedThreads)) as any;
            this.isInternalRole = InternalRoles.includes(this.globalRole);
            this.contextMenuVisible$ = this.getContextMenuVisible(this.globalRole);
        }
    }

    onSortByClick(config: MatSortable): void {
        this.sortByClick.emit({ sort: config.id, order: config.start });
    }

    async cancelTimeline(thread: ITimeline, role: Role): Promise<void> {
        await this.workflowExtensionService.cancelTimeline(thread, role);
        this.notificationsService.deleteInChannel(thread.id);
    }

    manageStatus(thread: ITimeline, globalRole: Role): void {
        const data: IStateModalData = {
            thread,
            role: globalRole,
        };

        const config = {
            data,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
        };

        this.dialog.open(ThreadStateModalComponent, config);
    }

    createThread(account: Account, globalRole: Role, featureEnabled: boolean, thread?: IThreadListing): void {
        const config = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            data: {
                role: globalRole,
                account,
                defaultThread: thread ? this.resetExistingThreadDueDates(thread) : null,
            },
        };

        const componentToRender: ComponentType<unknown> = featureEnabled
            ? CreateWorkflowModalComponent
            : CreateThreadModalComponent;

        this.dialog
            .open(componentToRender, config)
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe();
    }

    private resetExistingThreadDueDates(thread: IThreadListing): IThreadListing {
        if (!thread?.workflow?.steps) {
            return thread;
        }
        return {
            ...thread,
            workflow: {
                ...thread.workflow,
                steps: this.resetStepsDueDates(thread.workflow.steps),
            },
        };
    }

    private resetStepsDueDates(steps: Record<string, IStep>): Record<string, IStep> {
        const newStepEntries = Object.entries(steps).map(([stepId, step]) => {
            step.extensions = this.filterDueDateExtension(step);
            return [stepId, step];
        });
        return Object.fromEntries(newStepEntries);
    }

    private filterDueDateExtension(step: IStep): IExtension[] {
        return step.extensions?.filter((ext) => ext?.type !== DUE_DATE_EXTENSION_TYPE);
    }

    private sortThreadsByLatest(threads: ITimeline[]): ITimeline[] {
        return threads.sort((a, b) => {
            const dateA = a.preview?.timestamp || a.createdAt;
            const dateB = b.preview?.timestamp || b.createdAt;

            return new Date(dateB).getTime() - new Date(dateA).getTime();
        });
    }

    private getContextMenuVisible(globalRole: Role): Observable<boolean> {
        this.updateInternalWorkflowPermission$ = this.permissionsService.hasSomePermission(globalRole, [
            "UpdateInternalWorkflow",
        ]);
        this.createThreadPermission$ = this.permissionsService.hasSomePermission(globalRole, ["CreateThread"]);

        return combineLatest([this.updateInternalWorkflowPermission$, this.createThreadPermission$]).pipe(
            map(
                ([updateInternalWorkflowPermission, createThreadPermission]) =>
                    this.jobStatusEnabled && updateInternalWorkflowPermission && createThreadPermission,
            ),
        );
    }
}
