import { Component } from "@angular/core";
import { IPaginated } from "@visoryplatform/datastore-types";
import { IWorkflowConfiguration, Role } from "@visoryplatform/threads";
import { Paginator } from "projects/portal-modules/src/lib/shared/services/paginator";
import { UserProfileRouteService } from "projects/portal-modules/src/lib/user-profile/services/user-profile-route.service";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { UserAssigneeService } from "../../services/user-assignee.service";

@Component({
    selector: "list-user-configurations",
    templateUrl: "./list-user-configurations.component.html",
    styleUrls: ["./list-user-configurations.component.scss"],
})
export class ListUserConfigurationsComponent {
    loader = new Loader();
    paginator = new Paginator<IWorkflowConfiguration>(10, false);
    workflowConfigs$: Observable<IWorkflowConfiguration[]>;

    role$: Observable<Role>;
    userId$: Observable<string>;

    constructor(
        private userTimelinesService: UserAssigneeService,
        private userProfileRouteService: UserProfileRouteService,
    ) {
        this.userId$ = this.userProfileRouteService.getUserId();

        this.workflowConfigs$ = this.paginator.wrap();
        this.paginator.refresh((page) => this.getThreadListing(page));
    }

    private getThreadListing(page: string): Observable<IPaginated<IWorkflowConfiguration>> {
        return this.userId$.pipe(
            switchMap((userId) => this.loader.wrap(this.userTimelinesService.listConfigurations(userId, page))),
        );
    }
}
