@if (slaTime$ | async; as slaTime) {
    <duration-badge
        [label]="label"
        [time]="slaTime"
        [colour]="slaColour$ | async"
        [hint]="
            slaTime > 0
                ? 'Remaining SLA includes adjustments for non-working hours'
                : 'This step has exceeded the allocated SLA'
        "
    ></duration-badge>
}
