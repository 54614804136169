@if (busy()) {
    <div class="ghost-loader">
        <ghost-loader
            [showAnimation]="true"
            shape="square"
        >
        </ghost-loader>
    </div>
} @else {
    <div class="buttons-container">
        <div>
            @if (showStepMenu()) {
                <workflow-step-menu
                    [role]="role()"
                    [thread]="thread()"
                    [asIconButton]="false"
                    [useModal]="true"
                    [textButtonSize]="isMobileView() ? 'large' : 'medium'"
                ></workflow-step-menu>
            }
        </div>

        @if (isMobileView()) {
            <div class="step-actions-mobile">
                <v-context-menu>
                    <div custom-button>
                        <button class="fx-btn fx-btn--large fx-btn--primary step-actions-mobile-open-button">
                            Continue
                        </button>
                    </div>
                    @for (action of reversedVisibleActions(); track action.id) {
                        @let isEnabled = currentStep() | enableStepAction;
                        <a
                            role="menuitem"
                            (click)="isEnabled ? selectStepAt(action) : null"
                            [attr.aria-disabled]="!isEnabled"
                            [tabindex]="isEnabled ? 0 : -1"
                            [ngClass]="{
                                'step-actions-mobile-action-primary': $index === visibleActions().length - 1,
                                'step-actions-mobile-action-disabled': !isEnabled,
                            }"
                        >
                            {{ action.label }}</a
                        >
                    }
                </v-context-menu>
            </div>
        } @else {
            <div class="step-actions-desktop">
                @for (action of visibleActions(); track action.id) {
                    <button
                        (click)="selectStepAt(action)"
                        [ngClass]="{ 'fx-btn--primary': $index === 0, 'fx-btn--secondary': $index !== 0 }"
                        class="fx-btn fx-btn--large step-action-button"
                        [disabled]="!(currentStep() | enableStepAction)"
                    >
                        {{ action.label }} &nbsp;
                    </button>
                }
            </div>
        }
    </div>
}
