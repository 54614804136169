import { Component, computed, input } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { AssigneeType, SystemStepId } from "@visoryplatform/workflow-core";

const EXTERNAL_MESSAGE = {
    header: "Your Visory team are working on this step...",
    message:
        "Your team will let you know if they need anything to complete the work.\n\nHave questions? Get in touch using the 'More actions' button below.",
};

const INTERNAL_MESSAGE = {
    header: "Waiting for customer...",
    message: "The customer is assigned to this step.",
};

const CANCELLED_MESSAGE = {
    header: "This workflow has been cancelled",
    message: "View activity for details.",
};

const COMPLETED_MESSAGE = {
    header: "This workflow is now complete",
    message: "View activity for details.",
};

@Component({
    selector: "thread-overview-content-placeholder",
    templateUrl: "./thread-overview-content-placeholder.component.html",
    styleUrl: "./thread-overview-content-placeholder.component.scss",
    standalone: true,
})
export class ThreadOverviewContentPlaceholderComponent {
    assigneeType = input.required<AssigneeType>();
    role = input.required<Role>();
    currentStepId = input.required<string>();

    readonly message = computed(() => this.getMessage());
    readonly systemStepId = SystemStepId;

    getMessage(): { header: string; message: string } {
        if (this.currentStepId() === this.systemStepId.Cancelled) {
            return CANCELLED_MESSAGE;
        }

        if (this.currentStepId() === this.systemStepId.End) {
            return COMPLETED_MESSAGE;
        }

        const isClient = this.role() === Role.Client;

        if (isClient && this.assigneeType() === AssigneeType.Internal) {
            return EXTERNAL_MESSAGE;
        }

        if (!isClient && this.assigneeType() === AssigneeType.External) {
            return INTERNAL_MESSAGE;
        }

        return null;
    }
}
