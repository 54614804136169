import { Injectable } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
    CardStatus,
    IRequestFileData,
    IRequestItem,
    IThreadCard,
    ITimeline,
    IUpdatedRequestItems,
    IVaultInstructionsRequestCardState,
    IVaultRequestCardState,
} from "@visoryplatform/threads";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { combineLatest, Observable, of } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import {
    IRequestForm,
    IRequestItemFormGroup,
    RequestItemFormControlValue,
} from "../components/request/interfaces/IRequestForms";
import {
    IReopenRequestParams,
    ReopenRequestModalComponent,
} from "../components/request/reopen-request/reopen-request-modal.component";
import { VaultRequestService } from "./vault-request.service";

@Injectable({
    providedIn: "root",
})
export class RfiService {
    constructor(
        private vaultRequestService: VaultRequestService,
        private threadsService: ThreadsService,
    ) {}

    handleFileDeleted(
        requestItem: RequestItemFormControlValue,
        file: IRequestFileData,
        threadId: string,
        cardId: string,
        vaultId: string,
    ): Observable<void> {
        const filesToDelete = [
            {
                fileId: requestItem.fileId,
                filenames: [file.filename],
            },
        ];

        return this.vaultRequestService.deleteRequestFiles(threadId, cardId, vaultId, filesToDelete);
    }

    async updateRequestItems(
        threadId: string,
        vaultId: string,
        cardId: string,
        updatedRequestItems: IUpdatedRequestItems[],
        file?: File,
    ): Promise<void> {
        return await this.vaultRequestService.uploadRequestResponse(
            threadId,
            cardId,
            vaultId,
            updatedRequestItems,
            null,
            file,
        );
    }

    async downloadFile(vaultId: string, fileId: string, fileName: string): Promise<void> {
        await this.vaultRequestService.downloadFile(vaultId, fileId, fileName);
    }

    reopenRequestModal(
        card: IThreadCard,
        threadId: string,
        analyticsPrefix: string,
        dialog: MatDialog,
    ): Observable<boolean> {
        if (!dialog) {
            return of(false);
        }

        const cardId = card.id;
        const type = card.type;
        const data: IReopenRequestParams = {
            threadId,
            cardId,
            analyticsPrefix,
        };

        const config = {
            data,
            panelClass: ["centered-modal"],
            disableClose: true,
        };

        return dialog
            .open(ReopenRequestModalComponent, config)
            .afterClosed()
            .pipe(
                switchMap((value) => {
                    if (!value) {
                        return of(false);
                    }
                    return this.vaultRequestService
                        .sendRequestCardEvent(threadId, cardId, type, {
                            body: { isCompleted: false },
                        })
                        .pipe(map(() => value));
                }),
            );
    }

    getCanUpdateItem(
        state$: Observable<IVaultRequestCardState | IVaultInstructionsRequestCardState>,
        thread$: Observable<ITimeline>,
        card$: Observable<IThreadCard>,
        canReopenRequest$: Observable<boolean>,
        readonly: boolean,
    ): Observable<boolean> {
        const isThreadActive$ = thread$.pipe(map((thread: ITimeline) => this.threadsService.isThreadActive(thread)));
        const disabled$ = card$.pipe(map((card) => card?.status !== CardStatus.Disabled));
        const hasPermissionAndCanRespond$ = combineLatest([canReopenRequest$, disabled$]).pipe(
            map(([canReopenRequest, disabled]) => canReopenRequest || (!disabled && !readonly)),
        );

        return combineLatest([hasPermissionAndCanRespond$, canReopenRequest$, isThreadActive$, state$]).pipe(
            map(([hasPermissionAndCanRespond, canReopenRequest, isThreadActive, state]) => {
                if (hasPermissionAndCanRespond && canReopenRequest && isThreadActive) {
                    return true;
                }
                return !state?.isCompleted;
            }),
            shareReplay(1),
        );
    }

    getRequestItemsFormArray(requestItems: IRequestItem[]): FormArray<FormGroup<IRequestItemFormGroup>> {
        const sortedRequestItems = requestItems.sort((a, b) => {
            const displayIndexA = a.displayIndex || 0;
            const displayIndexB = b.displayIndex || 0;
            return displayIndexA - displayIndexB;
        });
        return new FormArray(this.mapRequestItemsToFormGroups(sortedRequestItems));
    }

    markAsComplete(val: boolean, form: FormGroup<IRequestForm>): void {
        const control = form.controls.requestItems;
        control.controls.map((formGroup) => {
            const completedControl = formGroup.controls.completed;
            if (completedControl.disabled) {
                return;
            }
            completedControl.setValue(val);
        });
    }

    addRequestItem(form: FormGroup<IRequestForm>, isCompleted?: boolean): void {
        if (isCompleted) {
            return;
        }

        const control = form.controls.requestItems;
        if (!control.valid) {
            return;
        }

        const requestItem = new FormGroup<IRequestItemFormGroup>({
            requestItem: new FormControl(null),
            description: new FormControl("", [Validators.required]),
            completed: new FormControl({ value: false, disabled: true }),
        });

        control.push(requestItem);
    }

    private mapRequestItemsToFormGroups(
        requestItems: RequestItemFormControlValue[],
    ): Array<FormGroup<IRequestItemFormGroup>> {
        return requestItems.map(
            (item) =>
                new FormGroup({
                    requestItem: new FormControl({ value: item, disabled: true }),
                    description: new FormControl({ value: item.description, disabled: true }),
                    completed: new FormControl({ value: item.response.complete.state, disabled: false }),
                }),
        );
    }
}
