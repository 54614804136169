import { Component, Inject, Injector, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import {
    IRequestFileData,
    IThreadCard,
    ITimeline,
    IUpdatedRequestItems,
    IVaultRequestCardState,
    Role,
} from "@visoryplatform/threads";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { ExtensionDisplayRef } from "projects/portal-modules/src/lib/shared/services/extension-display-ref";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable } from "rxjs";
import { map, shareReplay, take, takeUntil } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { RfiService } from "../../../services/rfi.service";
import { VaultRequestService } from "../../../services/vault-request.service";
import { RequestActionButtonLabel, RequestStatuses, VaultCardType } from "../constants/request.constants";
import { IRequestAnalyticsTags } from "../interfaces/IRequestAnalyticsTags";
import { IRequestForm, IRequestItemFormGroup } from "../interfaces/IRequestForms";
import { IRequestModalData } from "../interfaces/IRequestModalData";
import { IUpdatedRequestState } from "../interfaces/IUpdatedRequestState";

@Component({
    selector: "edit-rfi-request",
    templateUrl: "./edit-rfi-request.component.html",
    styleUrls: ["./edit-rfi-request.component.scss"],
})
export class EditRfiRequestComponent extends SubscriberBaseComponent implements OnInit {
    readonly buttonLabel = RequestActionButtonLabel;
    readonly reopenPermissions = ["RequestReopen", "ThreadUpdateAll"];
    readonly requestStatuses = RequestStatuses;

    card$: Observable<IThreadCard>;
    state$: Observable<IVaultRequestCardState>;
    state: IVaultRequestCardState;
    thread$: Observable<ITimeline>;
    userId$: Observable<string>;
    form: FormGroup<IRequestForm>;
    canReopenRequest$: Observable<boolean>;
    readonly: boolean;
    role$: Observable<Role>;

    loader = new Loader();

    actionedPercentage = 0;
    actionedRequestItems = 0;
    analyticsTags: IRequestAnalyticsTags;
    updatedRequestState: IUpdatedRequestState;

    modalData: IRequestModalData;
    private extensionDisplayRef: ExtensionDisplayRef;

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private vaultRequestService: VaultRequestService,
        private permissionService: PermissionService,
        private rfiService: RfiService,
        private unsavedDialogService: UnsavedModalDialogService,
        private authService: AuthService,
        private injector: Injector,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.extensionDisplayRef = await this.extensionDisplayService.getRef(this.injector).toPromise();
        this.modalData = await this.extensionDisplayService.getData<IRequestModalData>(this.injector).toPromise();

        this.initForm();
        this.setLocalVariables(this.modalData);
        this.updateCardDescription(this.card$);
        this.updateState(this.state$);

        this.updatedRequestState = {
            attachmentsAdded: [],
            attachmentsDeleted: [],
            requestItemsRemoved: [],
        };
    }

    get requestItems(): FormArray<FormGroup> {
        return this.form.controls.requestItems;
    }

    handleAttachmentAdded(attachment: File): void {
        this.updatedRequestState.attachmentsAdded.push(attachment);
        this.state.attachments.data.push({
            actorId: "",
            timestamp: "",
            filename: attachment.name,
        });
    }

    handleRequestItemRemoved(controlIndex: number, control: AbstractControl, isCompleted?: boolean): void {
        if (isCompleted) {
            return;
        }
        this.removeControl(controlIndex);
        if (control?.value?.fileId) {
            this.updatedRequestState.requestItemsRemoved.push(control.value.fileId);
        }
    }

    handleAttachmentDeleted(attachment: IRequestFileData): void {
        const fileToDelete = {
            fileId: this.state.attachments.fileId,
            filenames: [attachment.filename],
        };
        this.updatedRequestState.attachmentsDeleted.push(fileToDelete);
        this.state.attachments.data = this.state.attachments.data.filter((data) => data !== attachment);
    }

    updateRequestState(card: IThreadCard, thread: ITimeline): void {
        this.loader
            .wrap(
                this.vaultRequestService.updateRequestState(
                    card,
                    thread,
                    this.form,
                    this.state,
                    this.updatedRequestState,
                ),
            )
            .pipe(take(1))
            .subscribe(() => {
                this.loader.hide();
                this.extensionDisplayRef.close();
            });
    }

    removeControl(index: number): void {
        const control = this.form.controls.requestItems;

        if (control.controls.length > 1) {
            control.removeAt(index);
            this.form.markAsDirty();
        }
    }

    async close(): Promise<void> {
        if (!this.form.dirty) {
            this.extensionDisplayRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("rfi-edit");
            if (confirmClose) {
                this.extensionDisplayRef.close();
            }
        }
    }

    addRequestItem(form: FormGroup<IRequestForm>, isCompleted?: boolean): void {
        this.rfiService.addRequestItem(form, isCompleted);
    }

    async markAllComplete(
        checkBoxState: boolean,
        card: IThreadCard,
        state: IVaultRequestCardState,
        threadId: string,
    ): Promise<void> {
        this.rfiService.markAsComplete(checkBoxState, this.form);
        const updatedRequestItems: IUpdatedRequestItems[] = state.requestItems
            .filter((requestItem) => requestItem.response.complete.state !== checkBoxState)
            .map((requestItem) => ({
                fileId: requestItem.fileId,
                complete: checkBoxState,
            }));
        this.actionedRequestItems = checkBoxState ? state.requestItems.length : 0;
        this.actionedPercentage = checkBoxState ? 100 : 0;
        await this.vaultRequestService.updateRequest(updatedRequestItems, card.id, threadId, state.vaultId);
        this.form.markAsDirty();
    }

    private updateState(state$: Observable<IVaultRequestCardState>): void {
        state$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
            if (state) {
                const requestItems = this.rfiService.getRequestItemsFormArray(state.requestItems);
                this.form.setControl("requestItems", requestItems);
                this.form.patchValue({
                    title: state.title,
                });
                this.readonly = state.isCompleted;
                this.updateRequestProgress(state);
            }
        });
    }

    private updateRequestProgress(state: IVaultRequestCardState): void {
        const progress = VaultRequestService.calculateProgress(state.requestItems);
        this.actionedRequestItems = progress.actionedRequestItems;
        this.actionedPercentage = progress.actionedPercentage;
    }

    private setLocalVariables(modalData: IRequestModalData): void {
        const currentUserRole = modalData.role;
        this.readonly = modalData.readonly;
        this.thread$ = modalData.thread$;
        this.state$ = modalData.state$;
        this.state$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => (this.state = state));
        this.card$ = modalData.card$;
        this.userId$ = this.authService.getUserId();
        this.role$ = this.authService.getUser().pipe(map((user) => user.globalRole));
        this.canReopenRequest$ = this.permissionService
            .hasSomePermission(currentUserRole, this.reopenPermissions)
            .pipe(shareReplay(1));

        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultRequest);
    }

    private initForm(): void {
        this.form = new FormGroup<IRequestForm>({
            title: new FormControl("", Validators.required),
            cardDescription: new FormControl("", Validators.required),
            replyMessage: new FormControl(""),
            requestItems: new FormArray<FormGroup<IRequestItemFormGroup>>([]),
        });
    }

    private updateCardDescription(card$: Observable<IThreadCard>): void {
        card$.pipe(take(1)).subscribe((card) =>
            this.form.patchValue({
                cardDescription: card.description,
            }),
        );
    }
}
