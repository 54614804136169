import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IStep, SlaExtensionHelpers, WorkTimeExtensionHelpers } from "@visoryplatform/workflow-core";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { filter, map, shareReplay, startWith, switchMap } from "rxjs/operators";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";

import { AsyncPipe } from "@angular/common";
import { DateTime } from "luxon";
import { of } from "rxjs";
import { AuthService } from "../../../findex-auth";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { WorkflowTimerService } from "../../services/workflow-timer.service";
import { DurationBadgeColour, DurationBadgeComponent } from "../duration-badge/duration-badge.component";

@Component({
    selector: "sla-remaining",
    templateUrl: "./sla-remaining.component.html",
    styleUrls: ["./sla-remaining.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DurationBadgeComponent, AsyncPipe],
})
export class SlaRemainingComponent implements OnChanges {
    @Input() step: IStep;
    @Input() liveCountdown: boolean;
    @Input() accountId: string;
    @Input() timezone: string;
    @Input() label: string = "";

    workTime$: Observable<number | null>;
    slaTime$: Observable<number | null>;
    slaColour$: Observable<DurationBadgeColour | null>;

    private refreshSubject = new BehaviorSubject<void>(undefined);

    constructor(
        private featureFlagService: FeatureFlagService,
        private permissionService: PermissionService,
        private authService: AuthService,
        private timerService: WorkflowTimerService,
    ) {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { step, liveCountdown } = changes;
        if (step || liveCountdown) {
            this.refreshSubject.next();
        }
    }

    private init(): void {
        this.slaTime$ = this.refreshSubject.pipe(
            switchMap(() => this.getSlaTimeRemaining(this.liveCountdown)),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        this.workTime$ = this.refreshSubject.pipe(
            switchMap(() => this.getWorkTime(this.step)),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        this.slaColour$ = combineLatest([this.slaTime$, this.workTime$]).pipe(
            map(([slaTime, workTime]) => this.getSlaColour(slaTime, workTime)),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private getSlaTimeRemaining(liveCountdown: boolean): Observable<number | null> {
        const flagEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableSlaRemainingTime);

        return flagEnabled$.pipe(
            switchMap((hasFlagEnabled) => {
                if (hasFlagEnabled) {
                    return this.getSlaRemainingInterval(liveCountdown);
                } else {
                    return of(null);
                }
            }),
        );
    }

    private getSlaRemainingInterval(liveCountdown: boolean): Observable<number | null> {
        const initialRemainingTime = SlaExtensionHelpers.getSlaRemainingTime(this.step?.extensions, this.timezone);

        if (!liveCountdown) {
            return of(initialRemainingTime);
        }

        const startTime = DateTime.now();
        return this.timerService.getTimer().pipe(
            map(() => {
                if (initialRemainingTime === null) {
                    return null;
                }
                const now = DateTime.now();
                const millisPassed = now.diff(startTime).milliseconds;
                return initialRemainingTime - millisPassed;
            }),
            startWith(initialRemainingTime),
        );
    }

    private getSlaColour(slaTime: number | null, workTime: number | null): DurationBadgeColour | null {
        if (!slaTime) {
            return DurationBadgeColour.Red;
        }

        if (!workTime) {
            return slaTime <= 0 ? DurationBadgeColour.Red : DurationBadgeColour.Green;
        }

        const atRiskSlaTime = workTime * 2;
        if (slaTime >= atRiskSlaTime) {
            return DurationBadgeColour.Green;
        } else if (slaTime >= 0) {
            return DurationBadgeColour.Yellow;
        } else {
            return DurationBadgeColour.Red;
        }
    }

    private getWorkTime(step: IStep): Observable<number | null> {
        const hasWorkTimeViewPermission$ = this.authService.getGlobalRole().pipe(
            filter((role) => !!role),
            switchMap((role) => this.permissionService.checkPermissions(role, "ViewWorkTime")),
        );
        const hasWorkTimeFeatureEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkTimeOnWorkflowStepper,
        );

        const canViewWorkTime$ = combineLatest([hasWorkTimeViewPermission$, hasWorkTimeFeatureEnabled$]).pipe(
            map(
                ([hasWorkTimeViewPermission, hasWorkTimeFeatureEnabled]) =>
                    hasWorkTimeViewPermission && hasWorkTimeFeatureEnabled,
            ),
        );

        return canViewWorkTime$.pipe(
            map((canViewWorkTime) => {
                if (canViewWorkTime) {
                    return WorkTimeExtensionHelpers.getMultipliedWorkTime(step?.extensions);
                } else {
                    return null;
                }
            }),
        );
    }
}
