<div class="participant-picker">
    @if (loader.counter) {
        <loader-icon color="light"></loader-icon>
    }

    @if (!assignees?.disabled) {
        <div class="participant-picker-search">
            <div class="participant-picker-search-input">
                <label
                    class="participant-picker-search-input-label"
                    [for]="'clientSearchInput-' + id"
                >
                    {{ label }}
                </label>
                <input
                    [id]="'clientSearchInput-' + id"
                    [formControl]="searchTerm"
                    class="fx-form-input remove-underline"
                    placeholder="Search name or email"
                    aria-label="Search name or email"
                    analyticsClick="app_searchclients"
                    autocomplete="off"
                    (blur)="assignees?.markAsTouched()"
                />
            </div>

            <ng-container *ngIf="{ searchResults: searchResults$ | async }; let context">
                <div
                    class="participant-picker-search-list-container"
                    *ngIf="searchLoader.counter || context.searchResults"
                >
                    <div class="participant-picker-search-list-container">
                        <participant-list
                            [showLoader]="!!searchLoader.counter"
                            [participants]="context.searchResults"
                            [showInvite]="false"
                            (selectParticipant)="selectUser($event)"
                        ></participant-list>
                    </div>
                </div>
            </ng-container>
        </div>
    }

    @if (assignees?.value?.length && !hideParticipants) {
        <div
            class="participant-picker-selected-container"
            [ngClass]="{ 'no-margin disabled': !!assignees?.disabled }"
        >
            @for (assignee of assignees.value; track assignee) {
                <div class="participant-picker-selected-container-pill">
                    @if (assignee) {
                        <avatar-pill
                            [disabled]="assignees?.disabled"
                            [name]="assignee | userToName | async"
                            [id]="assignee"
                            [image]="assignee | profilePicture | async"
                            (crossClicked)="removeAssignee(assignee)"
                        ></avatar-pill>
                    }
                </div>
            }
        </div>
    }

    @if (assignees?.disabled && disabledDescription) {
        <div class="participant-picker-description">
            <info-banner>{{ disabledDescription }}</info-banner>
        </div>
    }
</div>
