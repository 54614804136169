import { DueDateAnchor } from "@visoryplatform/workflow-core";

export const startDate = {
    key: "START DATE",
    value: DueDateAnchor.StartDate,
};

export const endDate = {
    key: "END DATE",
    value: DueDateAnchor.EndDate,
};

export const dueDate = {
    key: "DUE DATE",
    value: DueDateAnchor.DueDate,
};

export const calculationPoints = [startDate, endDate];
export const workflowConfigurationCalculationPoints = [startDate, dueDate];
