import { Component, Input, OnDestroy, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, FormRecord, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { IWorkflowConfiguration } from "@visoryplatform/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Paginator } from "projects/portal-modules/src/lib/shared/services/paginator";
import { FilterOption } from "projects/portal-modules/src/lib/timeline/interfaces/timeline-filters";
import { UserProfileRouteService } from "projects/portal-modules/src/lib/user-profile/services/user-profile-route.service";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { UserAssigneeService } from "../../services/user-assignee.service";

@Component({
    selector: "bulk-replace-user-configurations",
    templateUrl: "./bulk-replace-user-configurations.component.html",
    styleUrls: ["./bulk-replace-user-configurations.component.scss"],
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: BulkReplaceUserConfigurationsComponent }],
})
export class BulkReplaceUserConfigurationsComponent implements OnDestroy, ControlValueAccessor {
    @Input() newUserIds: string[];
    @Input() totalUserConfigurations: number;
    @Input() selectedAccountIds: string[];

    loader = new Loader();
    tableHeaders = {
        service: "Service",
        reviewChange: "Change",
        timelineTitle: "Workflow",
        replace: "Replace",
        account: "Account",
    };

    form = new FormRecord<FormControl<boolean>>({});

    tableData = new MatTableDataSource<IWorkflowConfiguration>();

    paginator = new Paginator<IWorkflowConfiguration>(10, false);
    userConfigurations$: Observable<IWorkflowConfiguration[]>;
    userId$: Observable<string>;
    accounts$: Observable<FilterOption[]>;

    private readonly allOption = "all";
    private userConfigurationsSub: Subscription;
    private onChange?: (excludeThreadIds: string[]) => void;
    private onTouch?: () => void;
    private selectedAccountIds$ = new BehaviorSubject<string[]>([]);

    constructor(
        private userAssigneeService: UserAssigneeService,
        private userProfileRouteService: UserProfileRouteService,
    ) {
        this.userId$ = this.userProfileRouteService.getUserId();
        this.userConfigurations$ = this.paginator.wrap();

        this.paginator.refresh((page) =>
            combineLatest([this.userId$, this.selectedAccountIds$]).pipe(
                switchMap(([userId, accountIds]) => {
                    const filteredAccountIds = accountIds.includes(this.allOption) ? null : accountIds;
                    return this.loader.wrap(
                        this.userAssigneeService.listConfigurations(userId, page, undefined, filteredAccountIds),
                    );
                }),
            ),
        );

        this.userConfigurationsSub = this.userConfigurations$.subscribe((configurations) => {
            const configurationIds = configurations.map((configuration) => configuration.id);

            for (const configurationId of configurationIds) {
                if (!this.form.controls[configurationId]) {
                    this.form.addControl(configurationId, new FormControl<boolean>(true));
                }
            }

            this.tableData.data = configurations;
        });

        this.form.valueChanges.subscribe(() => {
            const excludeConfigurationIds = Object.entries(this.form.controls)
                .filter(([_, control]) => !control.value)
                .map(([configurationId]) => configurationId);
            this.onChange?.(excludeConfigurationIds);
            this.onTouch?.();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { selectedAccountIds } = changes;
        if (selectedAccountIds) {
            const configurationIds = Object.keys(this.form.controls);
            for (const configurationId of configurationIds) {
                this.form.controls[configurationId].setValue(true, { emitEvent: true });
            }
            this.selectedAccountIds$.next(this.selectedAccountIds);
        }
    }

    ngOnDestroy(): void {
        this.userConfigurationsSub?.unsubscribe();
    }

    writeValue(excludeConfigurationIds: string[]): void {
        for (const configurationId of excludeConfigurationIds) {
            if (!this.form.controls[configurationId]) {
                this.form.addControl(configurationId, new FormControl<boolean>(false));
            } else {
                this.form.controls[configurationId].setValue(false);
            }
        }
    }

    registerOnChange(fn: (excludeConfigurationIds: string[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }
}
