import { Component, forwardRef, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { BILL_APPROVAL_DATE_FORMAT, BillApprovalDatePickerFormControl } from "../../interfaces/BillApproval";

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DueDateRangeComponent),
    multi: true,
};

@Component({
    selector: "due-date-range",
    templateUrl: "./due-date-range.component.html",
    styleUrls: ["./due-date-range.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR],
})
export class DueDateRangeComponent implements ControlValueAccessor, OnInit, OnDestroy {
    readonly dateFormat = "yyyy-MM-dd";

    form = new FormGroup({
        startDate: new FormControl<string>(""),
        endDate: new FormControl<string>(""),
    });

    private onChange?: (val: Partial<BillApprovalDatePickerFormControl>) => void;
    private onTouch?: () => void;
    private valSub?: Subscription;

    ngOnInit(): void {
        this.valSub = this.form.valueChanges.subscribe((formValues) => {
            const startDate = formValues.startDate;
            const endDate = formValues.endDate;

            if (!startDate || !endDate) {
                this.onTouch?.();
                this.onChange?.(null);

                return;
            }

            const fromDate = DateTime.fromISO(startDate).toFormat(BILL_APPROVAL_DATE_FORMAT);
            const toDate = DateTime.fromISO(endDate).toFormat(BILL_APPROVAL_DATE_FORMAT);

            this.onTouch?.();
            this.onChange?.({
                startDate: fromDate,
                endDate: toDate,
            });
        });
    }

    ngOnDestroy(): void {
        this.valSub?.unsubscribe();
    }

    writeValue(val: BillApprovalDatePickerFormControl): void {
        this.form.patchValue(val);
    }

    registerOnChange(fn: (val: BillApprovalDatePickerFormControl) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }
}
