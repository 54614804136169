import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Account, IWorkflowDesignType } from "@visoryplatform/threads";
import { IWorkflowDesign } from "@visoryplatform/workflow-core";
import { merge, Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { ThreadDatesService } from "../../../../services/thread-dates.service";

/** SPLIT from new-thread-modal, did not refactor/clean */
@Component({
    selector: "edit-title",
    templateUrl: "./edit-title.component.html",
    styleUrls: ["./edit-title.component.scss"],
})
export class EditTitleComponent implements OnInit, OnChanges, OnDestroy {
    @Input() title: string;
    @Input() designType: IWorkflowDesignType;
    @Input() design: IWorkflowDesign;
    @Input() account: Account;
    @Output() titleChange = new EventEmitter<string>();

    readonly CUSTOM_TITLE_MAX_LENGTH = 20;

    currentMonthControl = new FormControl<string>("", Validators.required);
    form = new FormGroup({
        currentMonthControl: this.currentMonthControl,
    });
    customThreadTitle = new FormControl<string>("", [
        Validators.required,
        Validators.maxLength(this.CUSTOM_TITLE_MAX_LENGTH),
    ]);

    newThreadMonths: string[];
    requiresCustomMonth: boolean;
    requiresCustomTitle: boolean;
    titleChangesSub: Subscription;
    titlePrefix: string;

    constructor(private threadDatesService: ThreadDatesService) {}

    ngOnInit(): void {
        this.newThreadMonths = this.threadDatesService.generateMonths();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { designType, design } = changes;

        if (designType && this.designType) {
            this.titleChangesSub?.unsubscribe();
            this.requiresCustomTitle = false;
            this.requiresCustomMonth = false;

            const titleChanges$ = merge(
                this.currentMonthControl.valueChanges,
                this.customThreadTitle.valueChanges,
            ).pipe(distinctUntilChanged());

            this.titleChangesSub = titleChanges$.subscribe((title) => {
                this.titleChange.emit(title);
            });
        }

        if ((design || designType) && this.designType) {
            this.setControls();
        }
    }

    shouldRequireCustomMonth(): boolean {
        return this.designType.requiresCustomMonth;
    }

    shouldRequireCustomTitle(): boolean {
        return !!this.designType.titleTemplate;
    }

    ngOnDestroy(): void {
        this.titleChangesSub?.unsubscribe();
    }

    private setControls(): void {
        if (this.shouldRequireCustomMonth()) {
            this.requiresCustomMonth = true;
            this.currentMonthControl.setValue("");
            this.currentMonthControl.markAsDirty();
        } else if (this.shouldRequireCustomTitle()) {
            this.requiresCustomTitle = true;
            this.customThreadTitle.setValue("");
            this.customThreadTitle.markAsDirty();
        }
    }
}
