import { SelectionModel } from "@angular/cdk/collections";
import {
    AfterContentInit,
    AfterViewInit,
    Component,
    computed,
    ContentChild,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    Output,
    QueryList,
    signal,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSort, MatSortable, Sort, SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DateTime } from "luxon";
import { combineLatest, merge, Observable, of, Subject, takeUntil } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AnalyticsService } from "../../analytics";
import { IsAllSelectedPipe } from "../../shared/pipes/is-all-selected.pipe";
import { TableMobileViewControlsService } from "../../shared/services/table-mobile-view-controls.service";
import { FxColumnDirective } from "../directives/fx-column.directive";
import { FxRowChildDirective } from "../directives/fx-row-child.directive";

type IFxTableSortOptions = {
    key: string;
    value: string;
};

type IFxTableSortFilters = {
    selectedSortBy: FormControl<IFxTableSortOptions>;
    selectedSortDirection?: FormControl<IFxTableSortOptions>;
};

@Component({
    selector: "fx-table",
    templateUrl: "./fx-table.component.html",
    styleUrls: ["./fx-table.component.scss"],
})
export class FxTableComponent<RowType> implements AfterContentInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() tableData: MatTableDataSource<RowType>;
    @Input() selectable?: boolean;
    @Input() sortEnabled?: boolean = true;
    @Input() analyticsPrefix = "";
    @Input() rowClickable?: boolean;
    @Input() enableFooter?: boolean;
    @Input() enableExpandAll = false;
    @Input() stickyHeader?: boolean;
    @Input() sortDynamically?: boolean;
    @Input() sortActive?: string;
    @Input() sortDirection?: SortDirection;
    @Input() trackBy?: (index: number, item: RowType) => string;
    @Output() rowClick = new EventEmitter<RowType>();
    @Output() expandClick = new EventEmitter<any>();
    @Output() expandAllClick = new EventEmitter<any>();
    @Output() selected = new EventEmitter<Set<RowType>>();
    @Output() sortByClick = new EventEmitter<MatSortable>();
    @ContentChildren(FxColumnDirective) columns: QueryList<FxColumnDirective>;
    @ContentChild(FxRowChildDirective) rowChild: FxRowChildDirective;
    @ViewChild(MatSort) sort: MatSort;

    readonly CHECKBOX_COLUMN_ID = "checkbox";
    readonly SORT_DIRECTIONS_OPTIONS = [
        {
            key: "Ascending",
            value: "asc",
        },
        {
            key: "Descending",
            value: "desc",
        },
    ];

    tableFooterColumns: string[] = ["total"];
    mobileSortFilters = new FormGroup<IFxTableSortFilters>({
        selectedSortBy: new FormControl(null),
        selectedSortDirection: new FormControl(null),
    });
    allRowsExpanded = false;
    expandedElements = [];
    displayedColumns$: Observable<string[]>;
    selectedRows = new Set<RowType>();
    selection = new SelectionModel<RowType>(true, []);
    sortableColumns: FxColumnDirective[];
    mobileSortVisible = signal(false);
    mobileSortActive = computed(() => this.isMobileSortActiveOrHasSelection());
    hasMobileViewControlsService = false;

    SORT_BY_OPTIONS = [];

    private updateColumns = new Subject<void>();
    private destroy$ = new Subject<void>();

    get selectedRowCount(): number {
        return this.selection.selected.length;
    }

    get totalRowsCount(): number {
        return this.tableData.data.length;
    }

    get sortApplied(): boolean {
        return !!this.mobileSortFilters.get("selectedSortBy")?.value;
    }

    constructor(
        private analytics: AnalyticsService,
        private selectedPipe: IsAllSelectedPipe,
        @Optional() private tableMobileViewControlsService: TableMobileViewControlsService,
    ) {
        this.setupMobileSortActions();
    }

    ngAfterContentInit(): void {
        this.displayedColumns$ = merge(of(null), this.updateColumns, this.columns.changes).pipe(
            switchMap(() => this.watchColumns(this.columns)),
            map((columnIds) => columnIds.filter((id) => !!id)),
            map((columnIds) => (this.selectable ? [this.CHECKBOX_COLUMN_ID, ...columnIds] : columnIds)),
        );
        this.sortableColumns = this.columns.filter((column) => column.sortable);
        this.SORT_BY_OPTIONS = this.filterSortableColumns().map((column) => ({
            key: column.label,
            value: column.id,
        }));

        this.setMobileSortState(this.sortDirection, this.sortActive);
    }

    ngAfterViewInit(): void {
        if (this.tableData) {
            this.tableData.sortingDataAccessor = (item, property): string | number =>
                this.accessColumnProperty(item, property);
            this.tableData.sort = this.sort;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { tableData, selectable } = changes;

        if (tableData || selectable) {
            this.selectedRows = new Set();
            this.emitSelectedRows(this.selectedRows);
        }

        if (tableData?.currentValue && this.sort) {
            this.tableData.sort = this.sort;
        }

        if (selectable) {
            this.updateColumns.next();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    checkboxClicked(row: RowType, isChecked: boolean): void {
        this.analytics.recordEvent("mouse-click", `${this.analyticsPrefix}_selectone`);
        if (isChecked) {
            this.selectedRows.add(row);
        } else {
            this.selectedRows.delete(row);
        }

        this.emitSelectedRows(this.selectedRows);
    }

    toggleSelectAll(): void {
        this.analytics.recordEvent("mouse-click", `${this.analyticsPrefix}_selectall`);
        const allSelected = this.selectedPipe.transform(this.selectedRowCount, this.totalRowsCount);

        if (allSelected) {
            this.selection.clear();
        }

        this.tableData.data.forEach((row) => {
            if (!allSelected) {
                this.selection.select(row);
            }
            this.checkboxClicked(row, !allSelected);
        });
    }

    sortBy(columnId: string, direction: "asc" | "desc" | ""): void {
        if (this.sort) {
            const sortConfig: MatSortable = {
                id: columnId,
                start: direction || "desc",
                disableClear: false,
            };

            if (!this.sortDynamically) {
                // this block is a bit of a hack. Sorting programatically is buggy via MatTable.
                // https://github.com/angular/components/issues/12754
                const sortState: Sort = { active: columnId, direction };

                this.sort.direction = direction;
                this.sort.active = columnId;
                this.sort.sortChange.emit(sortState);
            } else {
                this.sortByClick.emit(sortConfig);
            }
        }
    }

    sortChange(sort: Sort): void {
        this.SORT_BY_OPTIONS = this.filterSortableColumns().map((column) => ({
            key: column.label,
            value: column.id,
        }));

        this.setMobileSortState(sort.direction, sort.active);
        this.sortByClick.emit({ id: sort.active, start: sort.direction, disableClear: false });
    }

    analyticsSortClick(column: FxColumnDirective): void {
        if (column.sortAnalyticsEvent) {
            this.analytics.recordEvent("mouse-click", column.sortAnalyticsEvent);
        }
    }

    toggleExpandRow(row: unknown): void {
        const shouldNotExpand = !row || typeof row !== "object" || !("hideRowChild" in row);
        if (shouldNotExpand) {
            return;
        }

        row.hideRowChild = !row.hideRowChild;
        this.expandClick.emit(row);
    }

    toggleExpandAllRows(): void {
        this.allRowsExpanded = !this.allRowsExpanded;
        this.tableData.data.forEach((row: any) => {
            row.hideRowChild = this.allRowsExpanded;
            this.toggleExpandRow(row);
        });
        this.expandAllClick.emit();
    }

    getTotal(id: string): number {
        const values = this.tableData.data.map((t) => t[id]);
        return values.reduce((acc, value) => acc + value, 0);
    }

    toggleSortVisible(): void {
        if (this.hasMobileViewControlsService) {
            this.tableMobileViewControlsService.toggleSort();
        } else {
            this.mobileSortVisible.set(!this.mobileSortVisible());
        }
    }

    resetSort(): void {
        if (this.hasMobileViewControlsService) {
            this.tableMobileViewControlsService.resetFilters();
        }
    }

    closeSort(): void {
        if (this.hasMobileViewControlsService) {
            this.tableMobileViewControlsService.closeActions();
        } else {
            this.mobileSortVisible.set(false);
        }
    }

    private setupMobileSortActions(): void {
        this.hasMobileViewControlsService = !!this.tableMobileViewControlsService;

        this.mobileSortVisible = this.hasMobileViewControlsService
            ? this.tableMobileViewControlsService.showSort
            : this.mobileSortVisible;

        if (this.hasMobileViewControlsService) {
            this.mobileSortFilters.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((filters) => {
                this.tableMobileViewControlsService.setSortApplied(!!filters.selectedSortBy);
            });
        }
    }

    private filterSortableColumns(): FxColumnDirective[] {
        return this.columns.filter((column) => column.sortable);
    }

    private getSortBy(sortBy: string): any {
        return this.SORT_BY_OPTIONS.find((option) => option.value === sortBy);
    }

    private getSortDirection(sortDirection: string): { key: string; value: string } {
        return this.SORT_DIRECTIONS_OPTIONS.find((option) => option.value === sortDirection);
    }

    private accessColumnProperty(item: RowType, property: string): string | number {
        const columnVal = this.getColumnProperty(item, property);
        const columnProperty = typeof columnVal === "string" ? columnVal.toLocaleLowerCase() : columnVal;
        const columnDateProperty = typeof columnVal === "string" ? DateTime.fromISO(columnVal) : null;

        if (columnDateProperty?.isValid) {
            return columnDateProperty.toMillis();
        } else {
            return columnProperty;
        }
    }

    private getColumnProperty(item: RowType, property: string): string | number {
        if (!property) {
            return null;
        }

        if (property.includes(".")) {
            return property.split(".").reduce((accumulator, value) => accumulator && accumulator[value], item);
        }

        return item[property];
    }

    private emitSelectedRows(selected: Set<RowType>): void {
        const clonedSet = new Set(selected);
        this.selected.emit(clonedSet);
    }

    private watchColumns(columnDirectives: QueryList<FxColumnDirective>): Observable<string[]> {
        this.selection.clear(); // clear selection on table data change
        return combineLatest(columnDirectives.map((column) => column.idUpdated));
    }

    private setMobileSortState(sortDirection: string, sortActive: string): void {
        const sortDirectionOption = this.getSortDirection(sortDirection);
        const sortByOption = this.getSortBy(sortActive);

        this.mobileSortFilters.patchValue({
            selectedSortBy: sortByOption,
            selectedSortDirection: sortDirectionOption,
        });
    }

    private isMobileSortActiveOrHasSelection(): boolean {
        return this.mobileSortVisible() || this.sortApplied;
    }
}
