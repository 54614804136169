import { Component, Inject } from "@angular/core";
import { IThread, Memory, Role } from "@visoryplatform/threads";
import { EmptyStateImageType } from "projects/portal-modules/src/lib/empty-state/components/empty-state.component";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { EMPTY, Observable } from "rxjs";
import { expand, mergeMap, switchMap, take, toArray } from "rxjs/operators";
import { ROLE, THREAD } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { MemoriesService } from "../../services/memories.service";

@Component({
    selector: "list-thread-memories",
    templateUrl: "./list-thread-memories.component.html",
    styleUrls: ["./list-thread-memories.component.scss"],
})
export class ListThreadMemoriesComponent {
    readonly emptyStateImages = EmptyStateImageType;

    loader = new Loader();
    threadMemories$: Observable<Memory[]>;

    constructor(
        @Inject(THREAD) public thread$: Observable<IThread>,
        @Inject(ROLE) public role$: Observable<Role>,
        private memoriesService: MemoriesService,
    ) {
        this.refreshMemories();
    }

    refreshMemories(): void {
        this.threadMemories$ = this.thread$.pipe(
            switchMap((thread) => this.loader.wrap(this.getAllMemories(thread.id))),
        );
    }

    extractMemories(threadId: string): void {
        this.loader
            .wrap(this.memoriesService.extractThreadMemories(threadId))
            .pipe(take(1))
            .subscribe(() => this.refreshMemories());
    }

    removeMemory(accountId: string, memoryKey: string): void {
        this.loader
            .wrap(this.memoriesService.deleteMemory(accountId, memoryKey))
            .pipe(take(1))
            .subscribe(() => this.refreshMemories());
    }

    private getAllMemories(threadId: string): Observable<Memory[]> {
        return this.memoriesService.listThreadMemories(threadId).pipe(
            expand((page) => {
                if (page.next) {
                    return this.memoriesService.listThreadMemories(threadId, page.next);
                } else {
                    return EMPTY;
                }
            }),
            mergeMap((page) => page.result),
            toArray(),
        );
    }
}
