import { Inject, Injectable } from "@angular/core";
import { InsightsPermissionsComponent } from "projects/default-plugins/insights/components/insights-permissions/insights-permissions.component";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { authGuard, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries, RouteExtension } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { combineLatest, Observable, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { featureFlagGuard } from "../../portal-modules/src/lib/feature-flags/services/feature-flag.guard";
import { globalPermissionGuard } from "../../portal-modules/src/lib/findex-auth/services/global-permission.guard";
import { AccountInsightsComponent } from "./components/account-insights/account-insights.component";
import { InsightsReportingComponent } from "./components/insights-reporting/insights-reporting.component";
import { InsightsRoutingModule, scorecardRoutes } from "./insights-routing.module";
import { insightsGuard } from "./services/insights-guard";
import { OpenMeasuresService } from "./services/open-measures.service";

@Injectable()
export class InsightsPlugin implements IPluginFactory {
    readonly id = "InsightsPlugin";

    constructor(
        libraries: Libraries,
        private authService: AuthService,
        private permissionService: PermissionService,
        private openmeasuresService: OpenMeasuresService,
        private featureFlagService: FeatureFlagService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        const extension: RouteExtension = {
            label: "Insights",
            icon: "la-chart-bar",
            showExtension: () => this.clientOrOpenMeasures(),
            route: {
                path: "insights",
                loadChildren: () => InsightsRoutingModule,
                canActivate: [authGuard, insightsGuard],
            },
        };

        const reportsExtension: RouteExtension = {
            label: "Management Reports",
            icon: "la-chart-bar",
            showExtension: () => this.getShowIcon(),
            route: { path: "reports", component: InsightsReportingComponent, canActivate: [authGuard] },
        };

        const scorecardExtension: RouteExtension = {
            label: "Scorecard",
            icon: "la-chart-bar",
            showExtension: () => this.hasOpenMeasures(),
            route: { path: "scorecard", canActivate: [authGuard, insightsGuard], children: scorecardRoutes },
        };

        const insightspermission: RouteExtension<any> = {
            label: "Insights Permissions",
            icon: "la-chart-bar",
            showExtension: () => this.hasOpenMeasures(),
            route: {
                path: "insights-permissions",
                canActivate: [authGuard, insightsGuard],
                component: InsightsPermissionsComponent,
            },
        };

        const accountExtension: RouteExtension = {
            label: "Insights",
            showExtension: () => this.canViewAccountInsights(),
            route: {
                path: "insights",
                canActivate: [featureFlagGuard, globalPermissionGuard],
                component: AccountInsightsComponent,
                data: {
                    featureFlag: LaunchDarklyFeatureFlags.EnableUniversalInsights,
                    permission: "ConfigureAccountInsights",
                },
            },
        };

        libraries.appRoutes.register("insights", extension);

        libraries.insightsRoutes.register("scorecard", scorecardExtension);
        libraries.insightsRoutes.register("reports", reportsExtension);

        libraries.profileRoutes.register("insights-permissions", insightspermission);

        libraries.accountRoutes.register("insights", accountExtension);
    }

    private clientOrOpenMeasures(): Observable<boolean> {
        const openMeasures$ = this.hasOpenMeasures();
        const isClient$ = this.getShowIcon();

        return combineLatest([openMeasures$, isClient$]).pipe(
            map(([openMeasures, isClient]) => openMeasures || isClient),
            catchError(() => of(false)),
        );
    }

    private hasOpenMeasures(): Observable<boolean> {
        return this.openmeasuresService.getEnterprises().pipe(
            map((hasEnterprises) => !!hasEnterprises?.length),
            catchError(() => of(false)),
        );
    }

    private canViewAccountInsights(): Observable<boolean> {
        const featureEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableUniversalInsights);

        const hasPermission$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
            switchMap((role) => this.permissionService.checkPermissions(role, "ConfigureAccountInsights")),
        );

        return combineLatest([featureEnabled$, hasPermission$]).pipe(
            map(([featureEnabled, hasPermission]) => {
                return featureEnabled && hasPermission;
            }),
            catchError(() => of(false)),
        );
    }

    private getShowIcon(): Observable<boolean> {
        const showInsights = this.environment?.featureFlags?.insightsConfiguration?.showInsights;

        if (showInsights) {
            return this.authService.getUser().pipe(
                filter((user) => !!user),
                switchMap((user) => {
                    const role = user.globalRole;
                    return this.permissionService
                        .checkPermissions(role, "CreateInsights")
                        .pipe(map((hasPermission) => !hasPermission));
                }),
            );
        } else {
            return of(showInsights);
        }
    }
}
