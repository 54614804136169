import { Component, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Account, Memory, Role } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { EMPTY, Observable, Subscription } from "rxjs";
import { expand, filter, map, mergeMap, switchMap, take, toArray } from "rxjs/operators";
import { MemoriesService } from "../../services/memories.service";

@Component({
    selector: "list-account-memories",
    templateUrl: "./list-account-memories.component.html",
    styleUrls: ["./list-account-memories.component.scss"],
})
export class ListAccountMemoriesComponent implements OnDestroy {
    loader = new Loader();
    accountMemories$: Observable<Memory[]>;
    globalRole$: Observable<Role>;
    account$ = this.accountRouteService.getAccount();
    enabled = new FormControl(false);
    brandingEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding);

    private accountSub: Subscription;

    constructor(
        private accountRouteService: AccountRouteService,
        private memoriesService: MemoriesService,
        private authService: AuthService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.refreshMemories();

        this.accountSub = this.accountRouteService
            .getAccount()
            .subscribe((account) => this.enabled.setValue(account.metadata?.memories?.enabled || false));

        this.globalRole$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
        );
    }

    save(account: Account, memories: { enabled: boolean }): void {
        const currentDateIso = DateTime.utc().toISO();
        const metadata = { ...account.metadata, memories: { ...memories, updatedAt: currentDateIso } };
        this.loader.wrap(this.accountRouteService.updateMetadata(account.id, metadata)).pipe(take(1)).subscribe();
    }

    refreshMemories(): void {
        this.accountMemories$ = this.account$.pipe(
            switchMap((account) => this.loader.wrap(this.getAllMemories(account.id))),
        );
    }

    removeMemory(accountId: string, memoryKey: string): void {
        this.loader
            .wrap(this.memoriesService.deleteMemory(accountId, memoryKey))
            .pipe(take(1))
            .subscribe(() => this.refreshMemories());
    }

    ngOnDestroy(): void {
        this.accountSub?.unsubscribe();
    }

    private getAllMemories(accountId: string): Observable<Memory[]> {
        return this.memoriesService.listAccountMemories(accountId).pipe(
            expand((page) => {
                if (page.next) {
                    return this.memoriesService.listAccountMemories(accountId, page.next);
                } else {
                    return EMPTY;
                }
            }),
            mergeMap((page) => page.result),
            toArray(),
        );
    }
}
