import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import matchUrl from "match-url-wildcard";
import { QuillEditorComponent, QuillModule, QuillModules } from "ngx-quill";
import Quill from "quill";
import { Range } from "quill/core/selection";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { FeatureFlagService } from "../../../feature-flags/services/feature-flags.service";
import { AuthService } from "../../../findex-auth";
import { PermissionService } from "../../services/permissions.service";
import { MentionableUser } from "./mentionable-user";

const MENTION_HEADER = "Suggestions";
const MENTION_HEADER_CLASS = "ql-mention-header";
const MENTION_LIST_ITEM_CLASS = "ql-mention-list-item";
const MENTION_LIST_CLASS = "ql-mention-list";
const MENTION = "mention";
const MENTION_CLASS = `class="${MENTION}"`;
const DENOTATION_CHAR = "data-denotation-char";
const MENTION_LIST_CONTAINER_CLASS = "ql-mention-list-container";
const PERMISSION_CREATE_HYPERLINK = "CreateHyperLink";

export interface ActiveMention {
    id: string;
    value: string;
    index: number;
}

@Component({
    selector: "quill-editor-wrapper",
    templateUrl: "./quill-editor-wrapper.component.html",
    styleUrls: ["./quill-editor-wrapper.component.scss"],
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: QuillEditorWrapperComponent }],
})
export class QuillEditorWrapperComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Output() error = new EventEmitter<boolean>();
    @Output() activeMentions = new EventEmitter<boolean>();

    @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;

    @Input() placeholder = "";
    @Input() inline = false;
    @Input() readOnly = false;
    @Input() autoFocusOnInit = true;
    @Input() messageSizeQuotaInKB = 200;
    @Input() mentionableUsers: MentionableUser[] = [];
    @Input() threadType: string; /* temp for feature flag, quill should not know about threads */

    toolbar = false;
    modules$: Observable<QuillModules> = of(environmentCommon.quillConfig.toolbarState.withToolbar);
    quillStyles = environmentCommon.quillConfig.styling;
    characterError = false;
    createHyperlinkSubscription$: Subscription;
    quillInstance: any;
    activeMentions$: Observable<boolean>;
    message = new FormControl<string>("");
    formSub: Subscription;

    onChange?: (obj?: string) => void;
    onTouch?: () => void;

    private mentionObserver: MutationObserver;
    private activeMentionsSubscription: Subscription;

    constructor(
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private sanitizer: DomSanitizer,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        const user$ = this.authService.getValidUser();
        const role$ = user$.pipe(map((user) => user.globalRole));

        this.formSub = this.message.valueChanges.subscribe((value) => {
            const valueToEmit = this.getMessageValue(value);

            this.validateMessageInput();
            this.onChange?.(valueToEmit);
            this.onTouch?.();
        });

        this.trackActiveMentions();

        role$
            .pipe(
                switchMap((role) => this.permissionService.checkPermissions(role, PERMISSION_CREATE_HYPERLINK)),
                take(1),
            )
            .subscribe((hasHyperLinkPermission) => this.setToolbarWithLink(hasHyperLinkPermission));
    }

    trackActiveMentions(): void {
        this.activeMentions$ = this.message.valueChanges.pipe(
            startWith(this.message.value),
            map((value) => this.hasMention(value)),
            shareReplay(1),
        );

        this.activeMentionsSubscription = this.activeMentions$.subscribe((hasMentions) =>
            this.activeMentions.emit(hasMentions),
        );
    }

    /**
     * This is a workaround to prevent the default behavior of Quill when a mention is selected and the space key is pressed.
     * @param quill - The Quill instance.
     */
    bindKeyboardEvent(quill: Quill): void {
        if (!quill) {
            return;
        }

        this.quillInstance = quill;

        quill.keyboard.addBinding({ key: " " }, { collapsed: true }, function (range: Range) {
            const [leaf] = this.quill.getLeaf(range.index);
            if (leaf?.statics?.blotName === "mention") {
                this.quill.insertText(range.index, " ");
                this.quill.setSelection(range.index + 1);
                return false;
            }
            return true;
        });
    }

    ngOnDestroy(): void {
        this.createHyperlinkSubscription$?.unsubscribe();
        if (this.quillInstance?.keyboard) {
            this.quillInstance.keyboard.addBinding({ key: " " }, null);
        }
        const mentionContainer = document.querySelector(`.${MENTION_LIST_CONTAINER_CLASS}`);
        if (mentionContainer) {
            mentionContainer.remove();
        }

        if (this.mentionObserver) {
            this.mentionObserver.disconnect();
        }

        this.activeMentionsSubscription?.unsubscribe();
        this.formSub?.unsubscribe();
    }

    toggleToolbar(): void {
        if (!this.readOnly) {
            this.toolbar = !this.toolbar;
        }
    }

    autoFocus(quill: Quill): void {
        if (!this.readOnly && this.autoFocusOnInit) {
            setTimeout(() => {
                quill.focus();
                const length = quill.getLength();
                quill.setSelection(length, length);
            }, 300);
        }
    }

    validateMessageInput(): void {
        const message = this.message.value;
        const messageSize = this.calculateMessageSizeInKB(message);
        const isMessageSizeNotValid = messageSize > this.messageSizeQuotaInKB;

        this.characterError = isMessageSizeNotValid;
        this.error.emit(isMessageSizeNotValid);
    }

    writeValue(value: string): void {
        this.message.setValue(value);
    }

    clearValue(): void {
        this.message.setValue("");
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private setToolbarWithLink(hasHyperLinkPermission: boolean): void {
        const { withToolbar } = environmentCommon.quillConfig.toolbarState;

        const isUrlValid = (url: string): boolean => {
            const { whitelistedUrls } = this.environment;
            return whitelistedUrls.some((rule) => matchUrl(url, rule));
        };

        const linkHandler = function (value: string): void {
            if (!value) {
                return;
            }

            const href = prompt("Enter the URL");
            if (isUrlValid(href)) {
                this.quill.format("link", href, "user");
            } else {
                console.warn("Invalid URL", href);
            }
        };

        const hyperLinkModule = hasHyperLinkPermission
            ? {
                  container: [...withToolbar.toolbar, ["link"]],
                  handlers: {
                      link: linkHandler,
                  },
              }
            : {
                  container: [...withToolbar.toolbar],
              };

        const isEnabled$ = combineLatest([
            this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableQuillMentions),
            this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.ConfigureQuillMentionsThreadType),
        ]).pipe(
            map(([mentionsEnabled, threadTypes]) => {
                const isArray = Array.isArray(threadTypes);
                const isThreadTypeEnabled =
                    isArray && (threadTypes?.includes(this.threadType) || threadTypes?.includes("*"));

                return mentionsEnabled && isThreadTypeEnabled;
            }),
        );

        this.modules$ = isEnabled$.pipe(
            map((mentionsEnabled) => {
                return {
                    toolbar: hyperLinkModule,
                    imageCompress: {
                        ...withToolbar.imageCompress,
                    },
                    mention: this.getMentionsModules(mentionsEnabled),
                };
            }),
        );
    }

    private getMessageValue(value?: string): string {
        const valueContainsMentionClass = value?.includes(MENTION_CLASS);
        if (valueContainsMentionClass) {
            return this.removeGhostMentions(value);
        }

        return value;
    }

    /**
     * This edge case happens when the quill editor does not handle removing the parent class of the mention
     * Remove all ghost mentions elements from the DOM and all the span tags from the value
     * @param formValue - The value changes from the quill editor
     * @returns The value without the ghost mentions and the span tags
     */
    private removeGhostMentions(formValue: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(formValue, "text/html");

        // Remove all span tags with class mention that do not contain a span with class ql-mention-denotation-char
        const mentionSpans = doc.querySelectorAll(`span.${MENTION}`);
        for (let i = 0; i < mentionSpans.length; i++) {
            const span = mentionSpans[i];
            if (!span.getAttribute(DENOTATION_CHAR)) {
                span.remove();
            }
        }

        // Sanitize the value to avoid XSS
        const removedGhostMentions = this.sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);
        return removedGhostMentions["changingThisBreaksApplicationSecurity"];
    }

    private hasMention(value: string): boolean {
        const hasMentions = value?.includes(MENTION_CLASS);
        this.activeMentions.emit(hasMentions);
        return hasMentions;
    }

    private getMentionsModules(mentionsEnabled: boolean): QuillModule | void {
        if (!mentionsEnabled) {
            return;
        }

        return {
            mentionListClass: MENTION_LIST_CLASS,
            allowedChars: /^[A-Za-z\s']*$/,
            showDenotationChar: true,
            spaceAfterInsert: false,
            mentionDenotationChars: ["@"],
            dataAttributes: ["id", "value", "title"],
            minChars: 0,
            maxChars: 31,
            isolateCharacter: true,
            positioningStrategy: "fixed",
            source: (searchTerm: string, renderList: (items: MentionableUser[], searchTerm: string) => void) => {
                const mentions = searchTerm.length === 0 ? this.mentionableUsers : this.searchMentions(searchTerm);

                const mentionsWithTitle = mentions.map((mention) => ({
                    ...mention,
                    title: mention.title || "",
                }));

                renderList(mentionsWithTitle, searchTerm);
            },
            renderItem: (item: MentionableUser) => this.buildMentionItem(item),
            onOpen: () => {
                const mentionContainer = document.querySelector(`.${MENTION_LIST_CONTAINER_CLASS}`);
                if (mentionContainer && !mentionContainer.querySelector(`.${MENTION_HEADER_CLASS}`)) {
                    const header = document.createElement("div");
                    header.classList.add(MENTION_HEADER_CLASS);
                    header.textContent = MENTION_HEADER;
                    mentionContainer.prepend(header);

                    this.setupMentionScrollObserver(mentionContainer);
                }
            },
        };
    }

    private setupMentionScrollObserver(mentionContainer: Element): void {
        if (this.mentionObserver) {
            this.mentionObserver.disconnect();
        }

        this.mentionObserver = new MutationObserver((mutations) => {
            mutations.forEach(() => {
                const selectedItem = mentionContainer.querySelector(`.${MENTION_LIST_ITEM_CLASS}.selected`);
                if (selectedItem) {
                    selectedItem.scrollIntoView({
                        block: "nearest",
                        behavior: "smooth",
                    });
                }
            });
        });

        const mentionList = mentionContainer.querySelector(`.${MENTION_LIST_CLASS}`);
        if (mentionList) {
            this.mentionObserver.observe(mentionList, {
                attributes: true,
                subtree: true,
                attributeFilter: ["class"],
            });
        }
    }

    private buildMentionItem(item: MentionableUser): HTMLElement {
        const container = document.createElement("div");
        container.className = "ql-mention-item";

        const avatar = document.createElement("div");
        avatar.className = "ql-mention-item-avatar fx-avatar fx-avatar--medium";
        avatar.style.backgroundImage = `url(${item.avatarUrl})`;

        const name = document.createElement("div");
        name.className = "ql-mention-item-name";
        name.textContent = item.value;

        if (item.title) {
            const title = document.createElement("div");
            title.className = "ql-mention-item-title";
            title.textContent = item.title;
            name.appendChild(title);
        }

        container.appendChild(avatar);
        container.appendChild(name);

        return container;
    }

    private searchMentions(searchTerm: string): MentionableUser[] {
        if (!this.mentionableUsers) {
            return [];
        }

        const lowerTerm = searchTerm.toLowerCase().replace(/[^A-Za-z]/g, "");

        return this.mentionableUsers.filter((user) =>
            user?.value
                ?.toLowerCase()
                .replace(/[^A-Za-z]/g, "")
                .includes(lowerTerm),
        );
    }

    private calculateMessageSizeInKB(payload: string): number {
        if (!payload) {
            return 0;
        }

        const bytesInAKB = 1024;
        const payloadLength = payload.length;
        const kb = (payloadLength / bytesInAKB).toFixed(2);

        return Number(kb);
    }
}
