import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmPaymentData, SetupIntent, StripeElements, StripeError } from "@stripe/stripe-js";
import { AcceptedCurrencies, ICustomer, IPaymentMethod, ISetupIntent } from "@visoryplatform/payments-service-sdk";
import { IPaymentInvoiceDetails } from "@visoryplatform/threads";
import { HandledError } from "@visoryplatform/video-chat-sdk";
import { StripeInstance, StripePaymentElementComponent } from "ngx-stripe";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { combineLatest, EMPTY, Observable, of } from "rxjs";
import { catchError, switchMap, take, takeUntil } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon } from "src/environments/environment";
import { PaymentService } from "../../services/payment.service";

@Component({
    selector: "app-edit-billing-details",
    templateUrl: "./edit-billing-details.component.html",
    styleUrls: ["./edit-billing-details.component.scss"],
})
export class EditBillingDetailsComponent extends SubscriberBaseComponent implements OnInit {
    @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent;

    readonly theme = this.environment.theme;

    form = new UntypedFormGroup({
        name: new UntypedFormControl("", [Validators.required]),
        address: new UntypedFormControl("", [Validators.required]),
        city: new UntypedFormControl("", [Validators.required]),
        postcode: new UntypedFormControl("", [Validators.required]),
        country: new UntypedFormControl(null, [Validators.required]),
        state: new UntypedFormControl("", [Validators.required]),
        setAsDefault: new UntypedFormControl(false),
    });
    elements: StripeElements;
    errorMessage: string;
    acceptedCountries = environmentCommon.acceptedCountries;
    elementsOptions = environmentCommon.stripeConfig.options;
    appearance = environmentCommon.stripeConfig.appearance;
    paymentMethods: string[];
    loader = new Loader();
    stripe: StripeInstance;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            customerId: string;
            paymentMethods: IPaymentMethod[];
            currency: AcceptedCurrencies;
            accountId$: Observable<string>;
        },
        private dialogRef: MatDialogRef<EditBillingDetailsComponent>,
        private paymentService: PaymentService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        super();
        this.stripe = this.paymentService.stripe;
    }

    ngOnInit(): void {
        this.paymentMethods = environmentCommon.stripeConfig.paymentMethods[this.data.currency || "aud"];
        this.loadPaymentDetails();
    }

    save(): void {
        const paymentSetup$ = this.loader.wrap(
            this.paymentService.confirmSetupIntent(
                this.paymentElement.elements,
                this.buildPaymentParams(),
                "if_required",
            ),
        );
        const setupIntent$ = this.loader.wrap(this.confirmPaymentSetup(paymentSetup$));
        setupIntent$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.close(true));
    }

    close(success: boolean): void {
        this.dialogRef.close(success);
    }

    private loadPaymentDetails(): void {
        const customerAdressAndInvoice$ = this.loader.wrap(
            this.data.accountId$.pipe(
                switchMap((accountId) => this.paymentService.createCustomerAndressAndInvoice(accountId)),
            ),
        );
        const addressAndSetupIntent$ = this.loader.wrap(
            customerAdressAndInvoice$.pipe(
                switchMap(([address, invoiceDetails]) => {
                    const setupIntent$ = this.createSetupIntent(invoiceDetails);
                    return combineLatest([of(address), setupIntent$]);
                }),
                catchError((err: unknown) => {
                    throw new HandledError(err);
                }),
            ),
        );

        addressAndSetupIntent$.pipe(take(1)).subscribe(([address, setupIntent]) => {
            this.form.setValue({ ...address, setAsDefault: false });
            this.elementsOptions.clientSecret = setupIntent.client_secret;
        });
    }

    private createSetupIntent(invoiceDetails: IPaymentInvoiceDetails): Observable<ISetupIntent> {
        return this.paymentService.setupPaymentMethod(
            this.paymentMethods,
            invoiceDetails,
            this.data.currency,
            undefined,
            this.data.customerId,
        );
    }

    private buildAddressData(): IPaymentInvoiceDetails {
        return {
            address: {
                line1: this.form.controls.address.value,
                city: this.form.controls.city.value,
                state: this.form.controls.state.value,
                country: this.form.controls.country.value,
                postal_code: this.form.controls.postcode.value,
            },
        };
    }

    private buildPaymentParams(): ConfirmPaymentData {
        return {
            payment_method_data: {
                billing_details: { ...this.buildAddressData(), name: this.form.controls.name.value },
            },
            return_url: window.location.href,
        };
    }

    private setupBillingDetails(setupIntent: SetupIntent): Observable<ICustomer> {
        if (setupIntent.status === "succeeded") {
            const setAsDefault = this.form?.get("setAsDefault")?.value || false;
            const paymentMethod = setupIntent.payment_method as string;
            const setDefaultPaymentMethod$ = this.paymentService.setDefaultPaymentMethod(
                this.data.customerId,
                paymentMethod,
            );

            if (setAsDefault) {
                return setDefaultPaymentMethod$;
            }
        }

        return of(null);
    }

    private confirmPaymentSetup(
        paymentSetup$: Observable<{
            setupIntent?: SetupIntent;
            error?: StripeError;
        }>,
    ): Observable<[ICustomer, ICustomer]> {
        return paymentSetup$.pipe(
            switchMap((result) => {
                const addressData = this.buildAddressData();
                const setupBillingDetails$ = this.setupBillingDetails(result.setupIntent);
                const updateCustomer$ = this.data.accountId$.pipe(
                    switchMap((accountId) => this.paymentService.updateCustomerAddress(accountId, addressData)),
                );
                return combineLatest([updateCustomer$, setupBillingDetails$]);
            }),
            // eslint-disable-next-line rxjs/no-implicit-any-catch
            catchError((err) => {
                this.errorMessage = err.message || "Sorry, something went wrong. Please contact support.";
                return EMPTY;
            }),
        );
    }
}
