<ng-container *ngIf="report$ | async as longRunningPayrunReport; else showLoader">
    <div *ngIf="bankFileSettings?.length">
        <label class="fx-form-label">Select bank account:</label>
        <div class="select-account-container">
            <div class="select-account-container-control">
                <v-select
                    placeholder="Select a check"
                    [options]="bankFileSettings"
                    [optionContent]="optionContent"
                    [formControl]="bankFileSetting"
                >
                </v-select>

                <ng-template
                    #optionContent
                    let-option
                >
                    {{ option.accountName }}
                </ng-template>
            </div>

            <info-banner *ngIf="bankFileSetting?.value">
                Bank account selected: {{ bankFileSetting.value.accountName }} - Account:
                {{ bankFileSetting.value.accountNumber }}
            </info-banner>
        </div>
    </div>

    <ng-container *ngIf="tableData$ | async as tableData">
        <div class="threads table-container">
            <payrun-request-table
                [report]="longRunningPayrunReport.report"
                [tableData]="tableData"
            ></payrun-request-table>
        </div>
    </ng-container>
</ng-container>

<ng-template #showLoader>
    <div class="report-ghost">
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
    </div>
</ng-template>
