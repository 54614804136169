import { IThreadListing, ITimeline, SlaRemainingSortHelper } from "@visoryplatform/threads";
import {
    AssigneeExtensionHelpers,
    IWorkflow,
    SystemStepId,
    WorkflowDueDateService,
} from "@visoryplatform/workflow-core";

import { Injectable } from "@angular/core";
import { IAvatarContent } from "@visoryplatform/fx-ui";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ParticipantCache } from "./participant-cache.service";

export type TableThreadListing = IThreadListing & {
    hasCancelTransition: boolean;
    updatedAt: string;
    dueDate: string;
    slaRemaining: string | null;
    avatars$: Observable<IAvatarContent[]>;
};

@Injectable({ providedIn: "root" })
export class ThreadsEnrichmentService {
    constructor(private participantCacheService: ParticipantCache) {}

    enrichThreads(threadGroupListing: ITimeline[]): TableThreadListing[] {
        return threadGroupListing.map((threadListing) => {
            const updatedAt = threadListing.preview?.timestamp ?? threadListing.createdAt;
            const currentStepId = threadListing.workflow?.currentStepId;
            const dueDate = WorkflowDueDateService.getEffectiveDueDate(threadListing?.workflow);
            const assignees = this.getAssigneesFromExtension(threadListing, currentStepId);
            const slaRemaining = SlaRemainingSortHelper.getSlaRemainingTime(
                threadListing.workflow?.steps[currentStepId],
            );
            const participants$ = this.participantCacheService.getParticipants(assignees);
            const avatars$ = participants$.pipe(
                switchMap((participants) => {
                    return this.participantCacheService.getMultipleAvatars(participants);
                }),
            );

            return {
                ...threadListing,
                hasCancelTransition: this.hasCancelTransition(threadListing?.workflow),
                updatedAt,
                dueDate,
                slaRemaining,
                avatars$,
            };
        });
    }

    private getAssigneesFromExtension(threadListing: ITimeline, currentStepId: string): string[] {
        const step = threadListing?.workflow?.steps[currentStepId];

        if (!step) {
            return [];
        }

        const assigneeData = AssigneeExtensionHelpers.getAssigneeData(step.extensions);
        return assigneeData?.assignees || [];
    }

    private hasCancelTransition(workflow?: IWorkflow): boolean {
        if (!workflow) {
            return false;
        }

        const currentStep = workflow?.steps[workflow.currentStepId];
        return currentStep?.actions?.some((action) => action.toStepId === String(SystemStepId.Cancelled));
    }
}
