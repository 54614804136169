import { Component, Input, OnChanges } from "@angular/core";

import { MatTableDataSource } from "@angular/material/table";
import { IWorkflowConfiguration } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { NgChanges } from "../../../shared/interfaces/ng-changes.interface";

enum WorkflowsListTableHeaders {
    Service = "Service",
    WorkflowType = "Workflow type",
    AccountLabel = "Account",
    Label = "Label",
    Frequency = "Frequency",
    Description = "Description",
    Status = "Status",
    NextSchedule = "Next schedule",
    Variation = "Variation",
}

enum WorkflowStatus {
    Active = "Active",
    Inactive = "Inactive",
}

@Component({
    selector: "workflow-configuration-list",
    templateUrl: "./workflow-configuration-list.component.html",
    styleUrls: ["./workflow-configuration-list.component.scss"],
})
export class WorkflowConfigurationListComponent implements OnChanges {
    @Input() workflows: IWorkflowConfiguration[];
    @Input() isRequiredConfiguration = false;
    @Input() showAccountLabel = false;
    readonly gaEvents = GA_EVENTS;
    readonly tableHeaders = WorkflowsListTableHeaders;

    tableData = new MatTableDataSource<IWorkflowConfiguration>();
    workflowStatus = WorkflowStatus;

    ngOnChanges(changes: NgChanges<WorkflowConfigurationListComponent>): void {
        const { workflows } = changes;
        if (workflows.currentValue) {
            this.tableData.data = this.workflows;
        }
    }
}
