<section class="workflow fx-threads">
    <workflow-details-header [thread]="thread"></workflow-details-header>

    <ng-container *ngIf="thread?.workflow && !(drawerVisible$ | async)">
        <button
            *ngIf="showWorkflowList$ | async; else workflowProgress"
            type="button"
            class="workflow-progress-close"
            (click)="toggleWorkflowList(false)"
        >
            <i class="las la-times icon-size"></i>
        </button>

        <ng-template #workflowProgress>
            <div class="workflow-progress">
                <thread-workflow-status
                    (click)="toggleWorkflowList(true)"
                    [workflow]="thread?.workflow"
                    [enablePopUp]="false"
                    [showIcon]="true"
                    [role]="role"
                    [thread]="thread"
                ></thread-workflow-status>
            </div>
        </ng-template>
    </ng-container>

    <div class="workflow-body fx-threads-frame">
        <fx-layout [gutters]="false">
            <fx-layout-column
                class="fx-threads-frame-column"
                [sizes]="{ mobile: 24, laptop: 8 }"
                [ngClass]="{
                    show: showWorkflowList$ | async,
                }"
            >
                <div
                    *ngIf="thread"
                    class="fx-threads-stepper-list"
                >
                    <loader-icon
                        *ngIf="loader.counter"
                        color="light"
                        [showBackground]="true"
                    ></loader-icon>

                    <workflow-step-list
                        [startDate]="thread.createdAt"
                        [workflow]="thread.workflow"
                        [role]="role"
                        [thread]="thread"
                        (performAction)="performAction(thread, role, thread.workflow, $event)"
                        (editDates)="editDates(thread, role)"
                        [attr.data-workflow-id]="thread?.workflow?.id"
                    ></workflow-step-list>
                </div>
            </fx-layout-column>

            <fx-layout-column
                [sizes]="{ mobile: 24, laptop: 16 }"
                customClass="thread-main"
            >
                <ng-content></ng-content>
            </fx-layout-column>
        </fx-layout>
    </div>
</section>
