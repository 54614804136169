import { Component, Inject, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import {
    CardStatus,
    IThreadCard,
    ITimeline,
    IVaultRequestCardState,
    Role,
    VaultStateAction,
} from "@visoryplatform/threads";
import { EmptyStateImageType } from "projects/portal-modules/src/lib/empty-state/components/empty-state.component";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { UiCardService } from "projects/portal-modules/src/lib/threads-ui/services/ui-card.service";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil } from "rxjs/operators";
import { TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { CardResources, ROLE, THREAD } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import {
    ThreadsVaultService,
    VaultRequestRow,
} from "../../../../portal-modules/src/lib/threads-ui/services/threads-vault.service";
import { RequestProgressTypes, RequestStatuses, VaultCardType } from "../request/constants/request.constants";

enum RequestListTableHeaders {
    Title = "Title",
    CreatedBy = "Created By",
    Progress = "Progress",
    Status = "Status",
}

@Component({
    selector: "request-list",
    templateUrl: "./request-list.component.html",
    styleUrls: ["./request-list.component.scss"],
})
export class RequestListComponent extends SubscriberBaseComponent implements OnInit {
    actionInProgress = false;
    cardStatuses = CardStatus;
    loader = new Loader();
    requestProgress = RequestProgressTypes;
    requestStatuses = RequestStatuses;
    requestSubscription: Subscription;
    requestTypes = VaultCardType;
    requests$: Observable<VaultRequestRow[]>;
    roles = Role;
    tableData = new MatTableDataSource<VaultRequestRow>();
    userId$: Observable<string>;

    readonly emptyStateImages = EmptyStateImageType;
    readonly tableHeaders = RequestListTableHeaders;

    constructor(
        @Inject(THREAD) public thread$: Observable<ITimeline>,
        @Inject(ROLE) public role$: Observable<Role>,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<void>>,
        private uiCardService: UiCardService,
        private threadsVaultService: ThreadsVaultService,
        private authService: AuthService,
        private permissionService: PermissionService,
        private taskActionService: TaskActionService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.requests$ = this.thread$.pipe(
            distinctUntilChanged(),
            switchMap((thread) => this.threadsVaultService.getRequestList(thread.id, this.loader)),
            shareReplay(1),
        );

        this.requestSubscription = this.requests$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((requests) => {
            this.tableData.data = requests;
        });

        this.userId$ = this.authService.getUserWithoutRole().pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );
    }

    openRequestModal(request: VaultRequestRow): void {
        const vaultId = request.state.vaultId;
        const card = request.card;

        if (request.card?.type === this.requestTypes.VaultRequest) {
            this.performActionForVaultRequest(this.role$, card, vaultId, request);
        }

        if (request.card?.type === this.requestTypes.VaultPayrollApprovalRequest) {
            this.performActionForVaultPayrunRequest(this.role$, card, vaultId);
        }
    }

    trackRequest(_index: number, request: VaultRequestRow): string {
        return request.card?.id;
    }

    private getCardResources(vaultId: string, role: Role): Observable<CardResources> {
        const card$ = this.getRowCard(vaultId);

        return combineLatest([this.thread$, card$]).pipe(
            map(([thread, card]) => this.uiCardService.getCardResources(thread.id, this.thread$, card, role)),
        );
    }

    private getRowState(vaultId: string): Observable<IVaultRequestCardState> {
        return this.getRequestRow(vaultId).pipe(map((row) => row.state));
    }

    private getRowCard(vaultId: string): Observable<IThreadCard> {
        return this.getRequestRow(vaultId).pipe(map((row) => row.card));
    }

    private getRequestRow(vaultId: string): Observable<VaultRequestRow> {
        return this.requests$.pipe(
            map((requests) => requests.find((request) => request.state.vaultId === vaultId)),
            filter((row) => !!row),
            distinctUntilChanged((x, y) => x.card.id === y.card.id),
        );
    }

    private performActionForVaultRequest(
        role$: Observable<Role>,
        card: IThreadCard,
        vaultId: string,
        request: VaultRequestRow,
    ): void {
        const hasPermission$ = role$.pipe(
            switchMap((role) => this.permissionService.checkPermissions(role, "RequestRespond")),
            take(1),
        );

        this.loader
            .wrap(combineLatest([role$, hasPermission$, this.userId$]))
            .pipe(
                switchMap(([role, hasPermission, userId]) => {
                    const isDisabled = card.status === CardStatus.Disabled;
                    const canUpdate = hasPermission || card.createdBy === userId;
                    const canUpdateRFI = !isDisabled && canUpdate && request.progress !== 100;
                    const actionId = canUpdateRFI ? VaultStateAction.RequestResponse : VaultStateAction.ViewResponse;

                    const resources$ = this.getCardResources(vaultId, role);

                    return combineLatest([of(actionId), resources$]);
                }),
                take(1),
            )
            .subscribe(([actionId, resources]) => {
                this.taskActionService.action(actionId, resources);
            });
    }

    private performActionForVaultPayrunRequest(role$: Observable<Role>, card: IThreadCard, vaultId: string): void {
        const state$ = this.getRowState(vaultId).pipe(take(1));
        const hasPermission$ = role$.pipe(
            switchMap((role) =>
                this.permissionService.hasSomePermission(role, ["PayrunRequestRespond", "ThreadUpdateAll"]),
            ),
            take(1),
        );

        this.loader
            .wrap(combineLatest([hasPermission$, role$, state$]))
            .pipe(
                switchMap(([hasPermission, role, state]) => {
                    const canRespondToRequest =
                        hasPermission && card.status !== this.cardStatuses.Disabled && !state.isCompleted;

                    const actionId = canRespondToRequest
                        ? VaultStateAction.PayrunApprovalRequestResponse
                        : VaultStateAction.PayrunApprovalViewResponse;
                    const resources$ = this.getCardResources(vaultId, role);

                    return combineLatest([of(actionId), resources$]);
                }),
                take(1),
            )
            .subscribe(([actionId, resources]) => {
                this.taskActionService.action(actionId, resources);
            });
    }
}
