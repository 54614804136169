<ng-container
    *ngIf="{ card: card$ | async, state: state$ | async, thread: thread$ | async, form: form$ | async } as context"
>
    <centered-modal-layout
        [loading]="false"
        [cancelButtonTitle]="context.state?.isCompleted ? buttonLabels.Close : buttonLabels.SaveClose"
        [disableSave]="actionedPercentage !== 100 || (context.state?.isCompleted && !modalData.allowRecomplete)"
        [saveButtonTitle]="buttonLabels.NextStep"
        (save)="save(context.thread, context.card)"
        (close)="extensionDisplayRef.close()"
    >
        <div modal-header>
            <request-header
                [title]="context.state?.title || todosTitle"
                [subTitle]="context.thread?.title | titlecase"
                [isComplete]="context.state?.isCompleted"
                [isSkipped]="context.state?.isSkipped"
                [completedStatusLabel]="requestStatuses.Completed"
                [accountName]="context.thread?.account?.label || ''"
            ></request-header>
        </div>

        <div modal-content>
            <banner-relevant-memories
                *ngIf="(enableMemories$ | async) && context.thread?.id"
                [threadId]="context.thread?.id"
                [accountId]="context.thread?.accountId"
            ></banner-relevant-memories>
            <rfi-todos
                #rfiTodosComponent
                [secondaryTitle]="secondaryTitle"
                [title]="todosTitle"
                [state]="context.state"
                [actionedRequestItems]="actionedRequestItems"
                [actionedPercentage]="actionedPercentage"
                [form]="context.form"
                [card]="context.card"
                [canUpdateTodoListItem]="canUpdateTodoListItem$ | async"
                [userId]="userId$ | async"
                [readonly]="readonly"
                [thread]="context.thread"
                [isSkipped]="context.state?.isSkipped"
                [showActions]="false"
                [canReopenRequest]="false"
                (updateControl)="updateRequestItemsComplete($event, analyticsTags.analyticsPrefix)"
            ></rfi-todos>

            <div
                *ngIf="context.card?.status !== CARD_STATUSES.Disabled || !readonly || !context.state?.isCompleted"
                [ngClass]="
                    actionedPercentage === 100 ? 'request-common-complete-all--disabled' : 'request-common-complete-all'
                "
            >
                <button (click)="markAllComplete(true, context.card, context.state, context.thread?.id, context.form)">
                    {{ buttonLabels.MarkAllAsComplete }}
                </button>
            </div>
        </div>

        <div modal-footer-content>
            @if (enableSkipButton$ | async) {
                <button
                    (click)="skip(context.thread, context.card)"
                    analyticsClick="{{ analyticsTags.analyticsPrefix }}_skip_button"
                    [disabled]="context.state?.isCompleted && !modalData.allowRecomplete"
                    class="fx-btn fx-btn fx-btn--primary-outlined skip-button"
                >
                    Skip
                </button>
            }
        </div>
    </centered-modal-layout>
</ng-container>
