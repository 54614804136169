<ng-container *ngIf="modalData">
    <request
        (save)="save()"
        (close)="close()"
        [title]="modalTitle.PayrolReport"
        [subtitle]="subtitle"
        [loading]="!!loader?.counter"
        [accountName]="accountName"
        [disableSave]="disableSave()"
        [saveButtonTitle]="buttonLabel.Create"
        [analyticsPrefix]="analyticsTags.analyticsPrefix"
    >
        <div class="payrun-container">
            <div class="request-common-group request-common-group--narrow">
                <div class="request-common-label request-common-group-header">Note or message</div>
                <request-view-reply
                    [cardDescription]="form.controls.cardDescription"
                    [participants]="modalData.thread?.participants"
                    [threadType]="modalData.thread?.type"
                ></request-view-reply>
            </div>
            <payrun-report-create-request
                [cardDescription]="form?.controls?.cardDescription"
                [reportListItem]="modalData.reportListItem"
                [bankFileSettings]="bankFileSettings$ | async"
                [thread]="modalData.thread"
                [provider]="modalData.provider"
                (longRunningTaskId)="longRunningTaskId.set($event)"
                (changeBankFile)="handleSelectedBankFile($event)"
            >
            </payrun-report-create-request>
        </div>
    </request>
</ng-container>
