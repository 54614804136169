import { ComponentType } from "@angular/cdk/portal";
import { InjectionToken } from "@angular/core";
import { CardReply, ICardEvent, IThread, IThreadCard, ITimeline, Role } from "@visoryplatform/threads";
import { IExtension } from "@visoryplatform/workflow-core";
import { Observable, Subject } from "rxjs";
import { Loader } from "../../shared/services/loader";

export const THREAD = new InjectionToken<Observable<IThread>>("THREAD");
export const ROLE = new InjectionToken<Observable<Role>>("ROLE");
export const THREAD_CARD_RESOURCES = new InjectionToken<CardResources>("THREAD_CARD_RESOURCES");
export const BANNER_LABEL = new InjectionToken<CardResources>("BANNER_LABEL");

export type CardResources<StateType = any> = {
    threadId: string;
    cardId: string;
    thread$: Observable<ITimeline>;
    card$: Observable<IThreadCard>;
    events$: Observable<ICardEvent>;
    state$: Observable<StateType>;
    replies$: Observable<CardReply[]>;
    navigateTo$: Observable<void>;
    role: Role;
    navigateToSubject?: Subject<void>;
    loader: Loader;
    cardExtension$: Observable<IExtension>;
};

export interface IUiCard<C = any, StateType = any> extends CardResources<StateType> {
    navigateToSubject: Subject<void>;
    timestamp: number;

    scrollTo?: boolean;
    component: ComponentType<C>;
}
