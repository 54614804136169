import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import {
    AccessLevel,
    Enterprise,
    EnterpriseListing,
    Entity,
    EntityRank,
    LatestTableData,
    Period,
    PeriodTableData,
    TableReportTrend,
} from "@visoryplatform/openmeasures-core";
import { Observable } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
import { AuthService } from "../../../portal-modules/src/lib/findex-auth";

export const OPEN_MEASURES_ENDPOINT = new InjectionToken<string>("OPEN_MESURES_ENDPOINT");

@Injectable()
export class OpenMeasuresService {
    constructor(
        private http: HttpClient,
        @Inject(OPEN_MEASURES_ENDPOINT) private endpoint: string,
        private authService: AuthService,
    ) {}

    private get<T>(path: string): Observable<T> {
        return this.makeReq("get", path);
    }

    private delete<T>(path: string): Observable<T> {
        return this.makeReq("delete", path);
    }

    private put<T>(path: string, body: unknown): Observable<T> {
        return this.makeReq("put", path, body);
    }

    private makeReq<T>(method: "get" | "post" | "put" | "delete", path: string, body?: any): Observable<T> {
        const headers = {};

        return this.authService.getUserWithoutRole().pipe(
            filter((user) => !!user),
            take(1),
            map((user) =>
                user?.id?.startsWith("cognito-") ? `${this.endpoint}/client/${path}` : `${this.endpoint}/${path}`,
            ),
            switchMap((url) => this.http.request<T>(method, url, { headers, body })),
        );
    }

    private removeUserIdPrefix(userId: string): string {
        const prefixIndex = userId?.indexOf("-");
        if (!prefixIndex || prefixIndex < 0) {
            return userId;
        }

        return userId?.slice(prefixIndex + 1);
    }

    getEnterprises(): Observable<EnterpriseListing[]> {
        return this.get(`enterprises`);
    }

    getAllEnterprises(): Observable<EnterpriseListing[]> {
        return this.get(`super/enterprises`);
    }

    listEnterprises(userId: string): Observable<EnterpriseListing[]> {
        const omUserId = this.removeUserIdPrefix(userId);
        return this.get(`users/${omUserId}/enterprises`);
    }

    getEnterprise(enterpriseId: string): Observable<Enterprise> {
        return this.get(`enterprises/${enterpriseId}`);
    }

    getPermissions(enterpriseId: string, userId: string): Observable<AccessLevel> {
        const omUserId = this.removeUserIdPrefix(userId);
        return this.get(`enterprises/${enterpriseId}/levels/${omUserId}`);
    }

    setPermissions(enterpriseId: string, userId: string, accessLevel: AccessLevel): Observable<void> {
        const omUserId = this.removeUserIdPrefix(userId);
        return this.put(`enterprises/${enterpriseId}/levels/${omUserId}`, { accessLevel });
    }

    removeUser(enterpriseId: string, userId: string): Observable<void> {
        const omUserId = this.removeUserIdPrefix(userId);
        return this.delete(`/enterprises/${enterpriseId}/roles/${omUserId}`);
    }

    addUser(enterpriseId: string, userId: string): Observable<void> {
        const omUserId = this.removeUserIdPrefix(userId);
        return this.put(`/enterprises/${enterpriseId}/roles/${omUserId}`, { role: "User" });
    }

    getEntities(enterpriseId: string): Observable<Entity[]> {
        return this.get(`enterprises/${enterpriseId}/entities`);
    }

    getPeriods(enterpriseId: string): Observable<Period[]> {
        return this.get(`enterprises/${enterpriseId}/periods`);
    }

    getMetrics(enterpriseId: string, entityId: string): Observable<LatestTableData> {
        return this.get(`enterprises/${enterpriseId}/reports/table/${entityId}`);
    }

    getRanks(enterpriseId: string, periodId: string, metricId: string): Observable<EntityRank[]> {
        return this.get(`enterprises/${enterpriseId}/periods/${periodId}/reports/rank/${metricId}`);
    }

    getTrends(
        enterpriseId: string,
        entityId: string,
        periodId: string,
        metricId: string,
    ): Observable<TableReportTrend[]> {
        return this.get(`enterprises/${enterpriseId}/periods/${periodId}/reports/trends/${entityId}/${metricId}`);
    }

    getReportsByPeriod(enterpriseId: string, periodId: string, entityId: string): Observable<PeriodTableData> {
        return this.get(`enterprises/${enterpriseId}/periods/${periodId}/reports/table/${entityId}`);
    }
}
