import { Directive, Input, OnChanges, SimpleChanges, TemplateRef } from "@angular/core";
import { AcceptedCurrencies } from "@visoryplatform/payments-service-sdk";
import { ReplaySubject } from "rxjs";

@Directive({
    selector: "[fxColumn]",
})
export class FxColumnDirective implements OnChanges {
    @Input("fxColumn") id: string;
    @Input("fxColumnLabel") label: string;
    @Input("fxColumnSortable") sortable: boolean;
    @Input("fxColumnMobileCollapse") mobileCollapse: boolean;
    @Input("fxColumnMobileGrid") mobileGrid: boolean;
    @Input("fxColumnHjSupressData") hjSupressData: boolean;
    @Input("fxColumnSortAnalyticsEvent") sortAnalyticsEvent: string;
    @Input("fxColumnExpandable") expandable = false;
    @Input("fxColumnAlignRight") alignRight = false;
    @Input("fxColumnType") type = "";
    @Input("fxColumnInfo") info: string;
    @Input("fxColumnCurrency") currency: AcceptedCurrencies;

    idUpdated = new ReplaySubject<string>();

    constructor(public template: TemplateRef<any>) {}

    ngOnChanges(changes: SimpleChanges) {
        const { id } = changes;

        if (id) {
            this.idUpdated.next(id.currentValue);
        }
    }
}
