import { DIALOG_DATA } from "@angular/cdk/dialog";
import { Injectable, Injector, Type } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { FeatureFlagService } from "../../feature-flags";
import { LaunchDarklyFeatureFlags } from "../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { DrawerRef } from "./drawer-ref";
import { DRAWER_DATA, DrawerConfig, DrawerService } from "./drawer.service";
import { ExtensionDisplayService } from "./extension-display.service";
export type DialogRef<R = unknown> = DrawerRef<R> | MatDialogRef<any, R>;

@Injectable({ providedIn: "root" })
export class DialogService implements ExtensionDisplayService {
    constructor(
        private featureFlagService: FeatureFlagService,
        private drawerService: DrawerService,
        private dialogService: MatDialog,
    ) {}

    open<R = unknown>(
        component: Type<any>,
        config: DrawerConfig | MatDialogConfig,
        useModal: boolean = false,
    ): Observable<R> {
        return this.getEnableFlag().pipe(
            switchMap((flag) => {
                if (flag) {
                    if (useModal) {
                        return this.dialogService.open<any, any, R>(component, config as MatDialogConfig).afterClosed();
                    }
                    return this.drawerService.open<R>(component, config as DrawerConfig);
                } else {
                    return this.dialogService.open<any, any, R>(component, config as MatDialogConfig).afterClosed();
                }
            }),
        );
    }

    async close(): Promise<void> {
        const enableFlag = await this.getEnableFlag().toPromise();

        if (enableFlag) {
            this.drawerService.close();
        } else {
            this.dialogService.closeAll();
        }
    }

    async back(): Promise<void> {
        const enableFlag = await this.getEnableFlag().toPromise();

        if (enableFlag) {
            this.drawerService.back();
        } else {
            this.dialogService.closeAll();
        }
    }

    getData<T>(injector: Injector, useModal: boolean = false): Observable<T> {
        return this.getEnableFlag().pipe(
            map((flag) => {
                if (flag) {
                    if (useModal) {
                        return injector.get<T>(DIALOG_DATA);
                    }
                    return injector.get<T>(DRAWER_DATA);
                } else {
                    return injector.get<T>(DIALOG_DATA);
                }
            }),
        );
    }

    getRef<R = unknown>(injector: Injector, useModal: boolean = false): Observable<DialogRef<R>> {
        return this.getEnableFlag().pipe(
            map((flag) => {
                if (flag) {
                    if (useModal) {
                        return injector.get(MatDialogRef);
                    }
                    return injector.get(DrawerRef);
                } else {
                    return injector.get(MatDialogRef);
                }
            }),
        );
    }

    getConfirmConfigPanelClass(): Observable<string[]> {
        return this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableWorkflowListAndStepper).pipe(
            map((flag) => {
                if (flag) {
                    return ["threads-sidebar-inner-close-modal"];
                } else {
                    return ["threads-sidebar", "threads-sidebar-inner-close-modal"];
                }
            }),
        );
    }

    private getEnableFlag(): Observable<boolean> {
        return this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableWorkflowListAndStepper).pipe(take(1));
    }
}
