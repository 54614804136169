import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MAT_LUXON_DATE_FORMATS } from "@angular/material-luxon-adapter";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { FindexUiModule } from "../../../findex-ui";
import { SharedPipesModule } from "../../../shared/pipes/shared-pipes.module";
import { UiComponentsModule } from "../../../ui-components";
import { UiLayoutsModule } from "../../../ui-layouts";
import { ThreadsUiPipesModule } from "../../pipes/threads-ui-pipes.module";
import { ThreadParticipantsModule } from "../thread-participants/thread-participants.module";
import { EditAssigneesComponent } from "./components/edit-assignees/edit-assignees.component";
import { EditDueDateComponent } from "./components/edit-due-date/edit-due-date.component";
import { EditWorkflowConfigComponent } from "./components/edit-workflow-config/edit-workflow-config.component";
import { TimePickerComponent } from "./components/time-picker/time-picker.component";
import { ViewAssigneesComponent } from "./components/view-assignees/view-assignees.component";

@NgModule({
    imports: [
        CommonModule,
        FxUiModule,
        FindexUiModule,
        PortalUiModule,
        ThreadsUiPipesModule,
        SharedPipesModule,
        MatDatepickerModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        UiComponentsModule,
        UiLayoutsModule,
        ThreadParticipantsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    declarations: [
        EditWorkflowConfigComponent,
        TimePickerComponent,
        EditDueDateComponent,
        EditAssigneesComponent,
        ViewAssigneesComponent,
    ],
    exports: [EditWorkflowConfigComponent],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    ],
})
export class EditWorkflowConfigModule {}
