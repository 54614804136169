import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { PortalUiModule, VInputDateRangeComponent } from "@visoryplatform/portal-ui";
import { FindexUiModule } from "../findex-ui";
import { FxTableModule } from "../fx-table/fx-table.module";
import { SearchModule } from "../search/search.module";
import { SharedModule } from "../shared";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { ThreadsUiModule } from "../threads-ui";
import { ThreadsUiPipesModule } from "../threads-ui/pipes/threads-ui-pipes.module";
import { WorkflowStatusModule } from "../workflow-status/workflow-status.module";
import { ThreadOverviewActionsComponent } from "./components/thread-overview/thread-overview-actions/thread-overview-actions.component";
import { ThreadOverviewComponent } from "./components/thread-overview/thread-overview.component";
import { ThreadRouteComponent } from "./components/thread-route/thread-route.component";
import { ThreadTypeBadgeComponent } from "./components/thread-type-badge/thread-type-badge.component";
import { ThreadsListRouteComponent } from "./components/threads-list-route/threads-list-route.component";
import { ThreadsServicesBadgeComponent } from "./components/threads-services-badge/threads-services-badge.component";
import { TimelinesFiltersComponent } from "./components/timelines-filters/timelines-filters.component";
import { TimelinesPaginatedComponent } from "./components/timelines-paginated/timelines-paginated.component";
import { TimelinesTableComponent } from "./components/timelines-table/timelines-table.component";
import { TimelinesComponent } from "./components/timelines/timelines.component";
import { WorkflowDetailsHeaderComponent } from "./components/workflow-details-header/workflow-details-header.component";
import { WorkflowDetailsComponent } from "./components/workflow-details/workflow-details.component";
import { WorkflowPriorityDebugComponent } from "./components/workflow-priority-debug/workflow-priority-debug.component";
import { WorkflowTabsComponent } from "./components/workflow-tabs/workflow-tabs.component";
import { WorkflowRoutesModule } from "./timeline-routes.module";

const exportDeclarations = [
    TimelinesComponent,
    TimelinesTableComponent,
    TimelinesPaginatedComponent,
    ThreadTypeBadgeComponent,
    TimelinesFiltersComponent,
    WorkflowDetailsComponent,
    WorkflowDetailsHeaderComponent,
    WorkflowTabsComponent,
    ThreadsServicesBadgeComponent,
    ThreadsListRouteComponent,
    ThreadRouteComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FindexUiModule,
        FxUiModule,
        PortalModule,
        RouterModule,
        WorkflowStatusModule,
        FxTableModule,
        PortalUiModule,
        WorkflowStatusModule,
        SharedPipesModule,
        ThreadsUiPipesModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        SharedModule,
        WorkflowRoutesModule,
        ThreadsUiModule,
        ThreadOverviewActionsComponent,
        ThreadOverviewComponent,
        SearchModule,
        WorkflowPriorityDebugComponent,
        VInputDateRangeComponent,
    ],
    declarations: [...exportDeclarations],
    exports: [...exportDeclarations],
})
export class TimelineModule {}
