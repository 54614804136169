import { Component, OnInit } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Component({
    selector: "dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
    role$: Observable<Role>;

    readonly gaEvents = GA_EVENTS;

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        const user$ = this.authService.getValidUser();

        this.role$ = user$.pipe(
            map((user) => user.globalRole || Role.Client),
            shareReplay(1),
        );
    }
}
