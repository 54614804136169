import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { VAvatar } from "@visoryplatform/portal-ui";
import { IParticipant, ITimeline, Role } from "@visoryplatform/threads";
import { WorkflowSteps } from "@visoryplatform/workflow-core";
import { Observable } from "rxjs";
import { filter, take } from "rxjs/operators";
import { IParticipantDetail } from "../../../threads-ui/interfaces/IParticipantDetail";
import { ParticipantService } from "../../../threads-ui/services/participant.service";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { UserAvatarService } from "../../../threads-ui/services/user-avatar.service";

@Component({
    selector: "timeline-participants",
    templateUrl: "./timeline-participants.component.html",
    styleUrls: ["./timeline-participants.component.scss"],
})
export class TimelineParticipantsComponent implements OnChanges {
    @Input() thread: ITimeline;
    @Input() role: Role;

    avatars$: Observable<VAvatar[]>;
    roles = Role;
    showAllParticipants = false;

    constructor(
        private participantsService: ParticipantService,
        private userAvatarService: UserAvatarService,
        private permissionService: PermissionService,
        private router: Router,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { thread } = changes;
        if (thread && thread.currentValue.participants) {
            this.setParticipantData();
        }
    }

    setParticipantData(): void {
        const participants = this.getThreadParticipants(this.thread);
        this.avatars$ = this.userAvatarService.getAvatars(participants);
    }

    navigateToProfile(profilePath: string): void {
        if (!profilePath) {
            return;
        }

        this.permissionService
            .checkPermissions(this.role, "ReadRoleAll")
            .pipe(
                take(1),
                filter((canAccess) => canAccess),
            )
            .subscribe(() => {
                const url = this.router.createUrlTree([profilePath]).toString();
                window.open(url, "_blank");
            });
    }

    private getThreadParticipants(thread: ITimeline): IParticipant[] | IParticipantDetail[] {
        const participants = thread.participants;
        const workflowSteps = thread.workflow?.steps;

        if (thread.workflowConfigurationId) {
            return this.getWorkflowParticipants(participants, workflowSteps);
        }
        return participants;
    }

    private getWorkflowParticipants(participants: IParticipant[], workflowSteps: WorkflowSteps): IParticipantDetail[] {
        const uniqueAssignees = this.participantsService.getThreadAssignees(workflowSteps);
        return this.getParticipantsDetails(participants, uniqueAssignees);
    }

    private getParticipantsDetails(
        participants: IParticipantDetail[],
        uniqueAssignees: string[],
    ): IParticipantDetail[] {
        return participants.map((participant) => {
            const businessName = participant.role === Role.Client ? this.thread.account?.label : null;
            const isAssignee = !!uniqueAssignees.find((assigneeId) => assigneeId === participant.id);

            return { ...participant, isAssignee, businessName };
        });
    }
}
