@if (loader.counter) {
    <loader-icon color="light"></loader-icon>
}
<route-drawer>
    <fx-layout [gutters]="false">
        @if (currentThread$ | async; as thread) {
            <fx-layout-column [sizes]="{ mobile: 24 }">
                @if (referencedThread$ | async; as referencedThread) {
                    <div>
                        <info-banner>
                            Workflow created from a request in
                            <strong
                                ><a
                                    [routerLink]="[
                                        '/workflows',
                                        thread.referenceFrom.threadId,
                                        'cards',
                                        thread.referenceFrom.cardId,
                                    ]"
                                >
                                    {{ referencedThread.title }}</a
                                >
                            </strong>
                        </info-banner>
                    </div>
                }

                @if ((currentThread$ | async).restrictCardsToInternal) {
                    <div>
                        <warning-banner>
                            <span><strong>INTERNAL</strong> - Not visible to customers</span>
                        </warning-banner>
                    </div>
                }
                @if (currentRole$ | async; as role) {
                    @if (extension$ | async; as extension) {
                        <div class="fx-threads-route">
                            <router-outlet></router-outlet>
                        </div>
                    } @else {
                        @if (currentPath$ | async; as currentPath) {
                            <div
                                #threadsContainer
                                class="fx-threads-messages"
                                invertScroll
                            >
                                <thread
                                    [ngClass]="{ loading: loader.counter }"
                                    [threadId]="thread.id"
                                    [thread$]="currentThread$"
                                    [role]="role"
                                    [routeToCardId]="cardId$ | async"
                                    [excludeCardTypes]="['thread']"
                                    (loadCardComplete)="scrollToBottom()"
                                ></thread>
                            </div>
                            @if (!loader.counter) {
                                <div class="fx-threads-new-message">
                                    @if (
                                        (currentPath$ | async) === INTERNAL_CHAT_ROUTE
                                            ? true
                                            : !(FEATURE_FLAGS.DisableAdhocCreateCardBar | featureEnabled | async)
                                    ) {
                                        @if (thread | isThreadActive) {
                                            <create-card
                                                [showLoader]="!!createLoader.counter"
                                                [role]="role"
                                                [globalRole]="globalRole$ | async"
                                                [restrictCardsToInternal]="thread.restrictCardsToInternal"
                                                (newCard)="addCard($event, thread)"
                                            ></create-card>
                                        }
                                    }
                                </div>
                            }
                        }
                    }
                }
            </fx-layout-column>
        }
    </fx-layout>
</route-drawer>
