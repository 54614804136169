<div class="integrations-payrun-settings">
    <h3>Bank file settings</h3>
    <div class="integrations-payrun-settings-header">Optional settings for Xero payments.</div>

    <div class="integrations-payrun-settings-form">
        <div
            [formGroup]="payrunSettings"
            class="fx-form"
        >
            <div class="fx-form-field">
                <div class="fx-form-field-icon-container">
                    <i class="las la-cog"></i>
                </div>
                <div class="fx-form-field-content">
                    <label
                        class="fx-form-field-content-label"
                        for="deNumber"
                        >DE Number</label
                    >

                    <label class="fx-form-field-content-sublabel">
                        Enter the direct entry user ID if your bank requires it.
                    </label>
                    <div class="fx-form-field-content-control">
                        <input
                            class="fx-form-input"
                            formControlName="deNumber"
                            minlength="6"
                            maxlength="6"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !payrunSettings.controls.deNumber.valid && payrunSettings.controls.deNumber.touched,
                            }"
                        />
                    </div>

                    @if (!payrunSettings.valid) {
                        <div class="fx-form-validation">DE number must be 6 characters long</div>
                    }
                </div>
            </div>

            <div class="fx-form-field">
                <div class="fx-form-field-icon-container">
                    <i class="las la-cog"></i>
                </div>
                <div class="fx-form-field-content">
                    <label
                        class="fx-form-field-content-label"
                        for="selfBalancing"
                        >Self balancing</label
                    >

                    <label class="fx-form-field-content-sublabel">
                        Enable self balancing if your bank requires it.
                    </label>

                    <v-toggle formControlName="selfBalancing">Self balancing</v-toggle>
                </div>
            </div>
        </div>
    </div>

    <div class="integrations-payrun-settings-buttons">
        <div class="button-container">
            <button
                role="button"
                class="fx-btn fx-btn--primary"
                (click)="savePayrunSettings()"
                [disabled]="!payrunSettings.valid"
            >
                Save
            </button>
        </div>
    </div>
</div>
