import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { AppUser } from "../../findex-auth/model/AppUser";
import { ANALYTICS_SERVICE, IAnalyticsService } from "./IAnalyticsService";

@Injectable({ providedIn: "root" })
export class AnalyticsService {
    initialized = new BehaviorSubject(false);

    constructor(
        @Inject(ENVIRONMENT) _environment: EnvironmentSpecificConfig,
        @Inject(ANALYTICS_SERVICE) private analyticsServices: IAnalyticsService[],
    ) {}

    init(user: AppUser): void {
        for (const service of this.analyticsServices) {
            service.init(user);
        }

        this.initialized.next(true);
    }

    setUserId(user: AppUser): void {
        for (const service of this.analyticsServices) {
            service.setUserId(user);
        }
    }

    recordEvent(category: string, action: string, label?: string, value?: number): void {
        for (const service of this.analyticsServices) {
            service.sendEvent(category, action, label, value);
        }
    }

    pageViewed(path: string): void {
        for (const service of this.analyticsServices) {
            service.pageViewed(path);
        }
    }
}
