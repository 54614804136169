import { Inject, Injectable } from "@angular/core";
import { Account, PaymentAction } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries, RouteExtension } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { AccountBillingHistoryComponent } from "./components/account-billing-history/account-billing-history.component";
import { AccountSubscriptionComponent } from "./components/account-subscriptions/account-subscriptions.component";
import { CreatePaymentModalComponent } from "./components/create-payment-modal/create-payment-modal.component";
import { PaymentCardComponent } from "./components/payment-card/payment-card.component";
import { addPaymentMethodPaymentTaskAction, payNowPaymentTaskAction } from "./payment-task-actions";
@Injectable()
export class PaymentPlugin implements IPluginFactory {
    readonly id = "PaymentPlugin";
    readonly gaEvents = GA_EVENTS;

    constructor(
        libraries: Libraries,
        @Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig,
        private authService: AuthService,
        private permissionService: PermissionService,
    ) {
        const subscription: RouteExtension<Account> = {
            label: "Subscription",
            showExtension: (account) => this.showExtension(account, environment.featureFlags.showPaymentsSubscriptions),
            route: {
                path: "subscription",
                component: AccountSubscriptionComponent,
            },
        };

        const billing: RouteExtension<Account> = {
            label: "Billing history",
            showExtension: (account) => this.showExtension(account, environment.featureFlags.showPaymentsBilling),
            route: {
                path: "billing",
                component: AccountBillingHistoryComponent,
            },
        };

        libraries.cardViews.register("payments", PaymentCardComponent);
        libraries.accountRoutes.register("subscription", subscription);
        libraries.accountRoutes.register("billing", billing);

        libraries.createCard.register("payment", {
            title: "Payment",
            tooltipMessage: "Create a once off or recurring payment",
            analyticsEvent: this.gaEvents.APP_CREATE_PAYMENT_CARD,
            permission: ["CreatePaymentCard"],
            icon: "la-file-invoice-dollar",
            featureFlags: [LaunchDarklyFeatureFlags.EnableCreatePaymentCard],
            componentRef: CreatePaymentModalComponent,
            disableInternalCreation: true,
            config: {
                panelClass: ["create-payment-modal", "centered-modal"],
                disableClose: false,
            },
        });

        libraries.taskActions.register(PaymentAction.PAYMENT_METHOD_REQUIRED, addPaymentMethodPaymentTaskAction);
        libraries.taskActions.register(PaymentAction.PAYMENT_REQUIRED, payNowPaymentTaskAction);
    }

    private showExtension(account: Account, featureFlag: boolean): Observable<boolean> {
        if (!featureFlag) {
            return of(featureFlag);
        }

        return this.authService.getUser().pipe(
            filter((user) => !!user),
            switchMap((user) => this.userHasPermission(account, user)),
        );
    }

    private userHasPermission(account: Account, user: AppUser) {
        if (account.contacts.some((contact) => contact.id === user.id)) {
            return of(true);
        } else {
            return this.permissionService.checkPermissions(user.globalRole, "ReadAllPayments");
        }
    }
}
