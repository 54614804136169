import { Component, Inject, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ITimeline } from "@visoryplatform/threads";
import { Observable, combineLatest, of } from "rxjs";
import { map } from "rxjs/operators";
import { THREAD_LIBRARY } from "src/app/injection-token";
import { GA_EVENTS } from "../../../analytics";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { ExtensionEntry, ILibrary, RouteExtension } from "../../../plugins";
import { ACTIVITY_PATH, OVERVIEW_PATH } from "../../timeline-routes.module";

const ACTIVITY_TAB: ExtensionEntry<RouteExtension> = {
    id: "activity",
    extension: {
        label: "Activity",
        route: { path: ACTIVITY_PATH },
        showExtension: () => of(true),
    },
};

@Component({
    selector: "workflow-tabs",
    templateUrl: "./workflow-tabs.component.html",
    styleUrls: ["./workflow-tabs.component.scss"],
})
export class WorkflowTabsComponent implements OnInit {
    @Input() thread: ITimeline;

    threadExtensions$: Observable<RouteExtension[]>;
    gaEvents = GA_EVENTS;

    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    constructor(
        @Inject(THREAD_LIBRARY) private threadLibrary: ILibrary<RouteExtension>,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        const extensions = this.threadLibrary.listAll();
        const overviewExtension = this.getOverviewExtension(extensions);
        const nonOverviewExtensions = this.getNonOverviewExtensions(extensions);
        const allExtensions = this.getOrderedExtensions(overviewExtension, nonOverviewExtensions);

        this.threadExtensions$ = combineLatest(
            allExtensions.map(({ extension }) => {
                const ext = this.showExtension(extension);
                return ext;
            }),
        ).pipe(map((extensions) => extensions.filter(Boolean)));
    }

    trackByExtension(_index: number, extension: RouteExtension): string {
        return extension?.label;
    }

    handleRedirect(extension: RouteExtension): void {
        extension.redirect(this.thread);
    }

    private showExtension(extension: RouteExtension): Observable<RouteExtension> {
        if (extension.showExtension) {
            return extension.showExtension(this.thread).pipe(map((show) => (show ? extension : null)));
        }

        return of(extension);
    }

    private getOrderedExtensions(
        overviewExt: ExtensionEntry<RouteExtension>,
        nonOverviewExts: ExtensionEntry<RouteExtension>[],
    ): ExtensionEntry<RouteExtension>[] {
        if (overviewExt) {
            return [overviewExt, ACTIVITY_TAB, ...nonOverviewExts];
        }

        return [ACTIVITY_TAB, ...nonOverviewExts];
    }

    private getNonOverviewExtensions(extensions: ExtensionEntry<RouteExtension>[]): ExtensionEntry<RouteExtension>[] {
        return extensions.filter((ext) => ext.extension.route.path !== OVERVIEW_PATH);
    }

    private getOverviewExtension(extensions: ExtensionEntry<RouteExtension>[]): ExtensionEntry<RouteExtension> | null {
        const hasOverviewRoute = this.configHasOverview();

        if (hasOverviewRoute) {
            return extensions.find((ext) => ext.extension.route.path === OVERVIEW_PATH);
        }

        return null;
    }

    private configHasOverview(): boolean {
        return this.route.snapshot.routeConfig?.children?.some((child) => child?.path === OVERVIEW_PATH);
    }
}
