<div
    [ngClass]="{
        'list-item-container--completed': entry().isCompleted,
        'list-item-container--not-ready': !(workflow.currentStepId === entry().step.id || entry().isCompleted),
        'list-item-container--last-milestone': isLastMilestone,
    }"
    class="list-item-container"
>
    <div
        [ngClass]="{
            'list-item-container-border--active-step': workflow.currentStepId === entry().step.id,
            'list-item-container-border--not-ready': !(
                workflow.currentStepId === entry().step.id || entry().isCompleted
            ),
        }"
        class="list-item-container-border"
    ></div>
    <div class="list-item">
        <workflow-step-checkbox
            [isCompleted]="entry().isCompleted"
            [currentStepId]="workflow.currentStepId"
            [stepId]="entry().step.id"
            [index]="index"
        ></workflow-step-checkbox>

        <div class="list-item-body">
            <div>
                <div
                    [ngClass]="{
                        'active-step': workflow.currentStepId === entry().step.id,
                        'not-ready': !(workflow.currentStepId === entry().step.id || entry().isCompleted),
                    }"
                    class="list-item-body-title"
                >
                    <span class="list-item-body-title-label">{{ entry().step.label }} </span>
                </div>

                <div class="list-item-body-details">
                    @if (role() | permissions: "UpdateInternalWorkflow" | async) {
                        <div class="list-item-body-details-timestamp">
                            @if ((entry().dueDate || entry().isCompleted) && hasDueDates()) {
                                <div
                                    class="list-item-body-details-timestamp-description"
                                    [ngClass]="{
                                        'active-step': workflow.currentStepId === entry().step.id,
                                    }"
                                >
                                    @if (entry()?.dueDate) {
                                        <div class="list-item-body-details-timestamp-description-due-date">
                                            <span>{{ text.Due }}</span>
                                            <strong>
                                                <span class="list-item-body-details-timestamp-description-value">
                                                    {{ entry().dueDate | date: "ccc, d LLL yy h:mma" }}
                                                </span>
                                            </strong>
                                        </div>
                                    }
                                    @if (entry().isCompleted && entry().completedDate) {
                                        <span class="list-item-body-details-timestamp-description-completed-date">
                                            {{ text.Completed + (entry().completedDate | date: "ccc, d LLL yy h:mma") }}
                                        </span>
                                    }

                                    @if (completedBy()) {
                                        <div class="list-item-body-details-completed-by">
                                            <span class="list-item-body-details-completed-by-label">Completed by:</span>

                                            <v-avatar
                                                [name]="completedBy().name"
                                                [src]="completedBy().src"
                                                size="xs"
                                            ></v-avatar>

                                            {{ completedBy().name }}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>

                <sla-remaining
                    [step]="entry().step"
                    [liveCountdown]="entry().step.id === workflow.currentStepId"
                    [timezone]="timezone"
                    label="SLA"
                ></sla-remaining>

                @if (entry().step.id === workflow.currentStepId) {
                    @if (workTime()) {
                        <duration-badge
                            label="Should take"
                            [time]="workTime()"
                            hint="The expected time to complete this task"
                            [isCountdown]="false"
                        ></duration-badge>
                    }
                }

                @if (FEATURE_FLAGS.EnableDelphiSortDebug | featureEnabled | async) {
                    <workflow-step-entry-debug [step]="entry().step"></workflow-step-entry-debug>
                }

                @if (workflow.currentStepId === entry().step.id && displayActionButtons()) {
                    <div class="action-buttons">
                        @for (action of entry().step.actions; track action.id; let i = $index) {
                            @if (workflow | showStepAction: action | async) {
                                <button
                                    (click)="selectStepAt(action)"
                                    [ngClass]="{ 'fx-btn--primary': i === 0, 'fx-btn--secondary': i !== 0 }"
                                    class="fx-btn fx-btn--small action-button"
                                    [disabled]="!(entry().step | enableStepAction)"
                                >
                                    {{ action.label }} &nbsp;
                                </button>
                            }
                        }
                    </div>
                }
            </div>

            <div
                class="list-item-body-right"
                [ngClass]="{ 'list-item-body-right--active': workflow.currentStepId === entry().step.id }"
            >
                <div class="list-item-body-right-assignees">
                    @if (avatars()) {
                        <v-avatars
                            size="s"
                            [avatars]="avatars()"
                            [max]="2"
                        ></v-avatars>
                    }
                </div>
                @if (
                    workflow.currentStepId === entry().step.id &&
                    workflow.currentStepId !== SystemStepId.End &&
                    displayActionButtons()
                ) {
                    <workflow-step-menu
                        [role]="role()"
                        [thread]="thread"
                    ></workflow-step-menu>
                }
            </div>
        </div>
    </div>
</div>
