import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { PayRunEarningsViewType, PayRunExpandedCardTitles } from "../../constants/payrun-report-constants";
import { IPayrunReportLineUI } from "../../interfaces/IPayrunReportLineUI";

@Component({
    selector: "payrun-expanded-details-block",
    templateUrl: "./payrun-expanded-details-block.component.html",
    styleUrls: ["./payrun-expanded-details-block.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayrunExpandedDetailsBlockComponent implements OnDestroy {
    @Input() data: IPayrunReportLineUI;

    @Output() viewTypeControlChange = new EventEmitter<PayRunEarningsViewType>();

    viewTypeControl = new FormControl<PayRunEarningsViewType>(PayRunEarningsViewType.Summary);
    earningsViewType = PayRunEarningsViewType;
    payrunCardTitles = PayRunExpandedCardTitles;
    viewTypeSubscription: Subscription;

    readonly PayrunAlignColumnsToLeft = ["type", "location", "notes", "account"];

    constructor() {
        this.viewTypeSubscription = this.viewTypeControl.valueChanges.subscribe((value) => {
            this.viewTypeControlChange.emit(value);
        });
    }

    sort(): number {
        return 0;
    }

    ngOnDestroy(): void {
        this.viewTypeSubscription?.unsubscribe();
    }
}
