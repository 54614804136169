import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { PermissionService } from "../../threads-ui/services/permissions.service";
import { AuthService } from "./auth.service";

export const globalPermissionGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
): Observable<UrlTree | boolean> => {
    const authService = inject(AuthService);
    const permissionsService = inject(PermissionService);

    return authService.getUser().pipe(
        filter((user) => !!user),
        map((user) => user.globalRole),
        switchMap((role) => permissionsService.checkPermissions(role, route.data.permission)),
    );
};
