import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ITimeline, Role, WorkflowStepType } from "@visoryplatform/threads";
import { IStep, IWorkflow, IWorkflowAction, SLA_EXTENSION_TYPE } from "@visoryplatform/workflow-core";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { Loader } from "../../../shared/services/loader";
import { IWorkflowStepUI } from "../../../threads-ui/interfaces/IWorkflowStepUI";
import { WorkflowStepsService } from "../../../threads-ui/services/workflow/workflow-steps.service";
import { WorkflowTextConstants } from "../../constants/workflow-text.constants";

@Component({
    selector: "workflow-step-list",
    templateUrl: "./workflow-step-list.component.html",
    styleUrls: ["./workflow-step-list.component.scss"],
})
export class WorkflowStepListComponent implements OnChanges, OnInit {
    @Input() startDate: string;
    @Input() role: Role;
    @Input() thread: ITimeline;
    @Input() workflow: IWorkflow;
    @Output() performAction = new EventEmitter<IWorkflowAction>();
    @Output() editDates = new EventEmitter<void>();

    readonly stepTypes = WorkflowStepType;
    readonly theme = this.environment.theme;

    loader = new Loader();
    accountLabel: string;
    hasDueDates: boolean;
    roles = Role;
    stepEntries: IWorkflowStepUI[] = [];
    text = WorkflowTextConstants;
    timeZone: string;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private workflowStepsService: WorkflowStepsService,
    ) {}

    ngOnInit(): void {
        this.setValuesFromThread();
        this.setUIStates();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { workflow, startDate } = changes;

        if (workflow || (startDate && this.startDate)) {
            this.setUIStates();
        }
    }

    selectStepAt(action: IWorkflowAction): void {
        this.performAction.emit(action);
    }

    trackEntry(_index: number, entry: IWorkflowStepUI): string | IWorkflowStepUI {
        return entry?.step.id;
    }

    editDatesClicked(): void {
        this.editDates.emit();
    }

    private setUIStates(): void {
        this.stepEntries = this.workflowStepsService.getUpdatedStepEntries(this.startDate, this.workflow);

        if (this.workflow?.steps) {
            const steps = Object.values(this.workflow?.steps);
            this.hasDueDates = steps.some((step) => this.hasSlaExtension(step));
        } else {
            this.hasDueDates = false;
        }
    }

    private hasSlaExtension(step: IStep): boolean {
        return step.extensions?.some((extension) => extension?.type === SLA_EXTENSION_TYPE);
    }

    private setValuesFromThread(): void {
        const account = this.thread?.account;

        this.timeZone = account?.metadata?.contactInfo?.timeZone;
        this.accountLabel = account?.label;
    }
}
