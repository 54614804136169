import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { FeatureFlagService } from "./feature-flags.service";

export const featureFlagGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
): Observable<boolean> => {
    const featureFlagService = inject(FeatureFlagService);

    if (!route.data.featureFlag) {
        return of(false);
    }
    return featureFlagService.getFlag(route.data.featureFlag);
};
