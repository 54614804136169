import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { InternalRoles, Role, ThreadFilterSources, ThreadFilters, ThreadStatus } from "@visoryplatform/threads";
import { Observable, Subscription, merge } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { FilterOption, ITimelineFilters } from "../../interfaces/timeline-filters";

import { IPaginated } from "@visoryplatform/datastore-types";
import { SystemStepId } from "@visoryplatform/workflow-core";
import { SearchableThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/searchable-threads.service";
import { AuthService } from "../../../findex-auth";
import { IPaginatorSort } from "../../../shared/interfaces/IPaginatorSort";
import { Loader } from "../../../shared/services/loader";
import { Paginator } from "../../../shared/services/paginator";
import { PortalService } from "../../../shared/services/portal.service";
import { TableMobileViewControlsService } from "../../../shared/services/table-mobile-view-controls.service";
import { ThreadFilterService } from "../../../threads-ui/services/thread-filter.service";
import { TableThreadListing } from "../../../threads-ui/services/threads-enrichment.service";

export enum AssigneeRoles {
    Visory = "Visory",
    Expert = "Expert",
    Customer = "Customer",
}

@Component({
    selector: "timelines-paginated",
    templateUrl: "./timelines-paginated.component.html",
    styleUrls: ["./timelines-paginated.component.scss"],
    providers: [{ provide: Loader, useClass: Loader }],
})
export class TimelinesPaginatedComponent implements OnInit, OnChanges, OnDestroy {
    @Input() filters: ITimelineFilters;
    @Input() hideAccounts: boolean;
    @Input() delphiSortEnabled: boolean;
    @Output() filtersChange = new EventEmitter<ITimelineFilters>();

    readonly pageSize = 10;

    role$: Observable<Role>;
    userId$: Observable<string>;
    threads$: Observable<TableThreadListing[]>;
    paginator = new Paginator<TableThreadListing>(this.pageSize);

    threadSub: Subscription;

    constructor(
        private authService: AuthService,
        private portalService: PortalService,
        private searchableThreadsService: SearchableThreadsService,
        private threadFilterService: ThreadFilterService,
        public loader: Loader,
        private tableMobileViewControlsService: TableMobileViewControlsService,
    ) {
        this.threads$ = this.paginator.wrap();
    }

    ngOnInit(): void {
        const user$ = this.authService.getValidUser();

        this.userId$ = user$.pipe(
            map((user) => user.id),
            shareReplay(1),
        );

        this.role$ = user$.pipe(
            map((user) => user.globalRole),
            shareReplay(1),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { filters, includeAll } = changes;

        if ((includeAll || filters) && this.filters) {
            if (this.filters.delphiSort) {
                this.paginator.sort({ sort: "", order: "" });
            }

            this.setupThreadsObservable();
        }
    }

    ngOnDestroy(): void {
        this.threadSub?.unsubscribe();
    }

    onSortByClick(sort: IPaginatorSort): void {
        if (sort.sort && sort.order && this.filters.delphiSort) {
            const updatedFilters = {
                ...this.filters,
                delphiSort: false,
            };
            this.filtersChange.emit(updatedFilters);
        }
        this.paginator.sort(sort);
    }

    private setupThreadsObservable(): void {
        this.threadSub?.unsubscribe();

        this.paginator.refresh((page, _, sort) =>
            this.getThreadListing(page, sort, this.filters, this.filters.includeAll, this.delphiSortEnabled),
        );

        this.threadSub = this.threads$.subscribe((threads) => {
            this.tableMobileViewControlsService.setSortEnabled(threads.length > 0);
        });
    }

    private getThreadListing(
        page: string,
        sort: IPaginatorSort,
        filters: ITimelineFilters,
        includeAll: boolean,
        delphiSort: boolean,
    ): Observable<IPaginated<TableThreadListing>> {
        const searchFilter = this.filters.search;
        const searchParams$ = this.role$.pipe(
            map((role) => this.getSearchParams(filters, role)),
            shareReplay(1),
        );
        const userId$ = this.userId$.pipe(shareReplay(1));
        const threadList$ = searchParams$.pipe(
            switchMap((searchParams) => {
                const searchThreadList$ = this.portalService.getSearchThreadList(
                    page,
                    this.pageSize,
                    searchParams,
                    searchFilter,
                    sort.sort,
                    sort.order,
                    includeAll ?? filters.includeAll,
                    delphiSort,
                );
                return this.loader.wrap(searchThreadList$);
            }),
        );
        const threadListUpdates$ = searchParams$.pipe(
            switchMap((searchParams) =>
                this.getThreadListUpdates(page, userId$, sort, searchParams, searchFilter, includeAll),
            ),
        );

        return merge(threadList$, threadListUpdates$).pipe(
            switchMap((listing) => this.searchableThreadsService.getListingCreatedUpdates(this.userId$, listing)),
            switchMap((listing) => this.searchableThreadsService.getThreadUpdates(listing)),
            map((listing) => this.searchableThreadsService.getEnrichedListings(listing)),
            shareReplay(1),
        );
    }

    private getThreadListUpdates(
        page: string,
        userId$: Observable<string>,
        sort: IPaginatorSort,
        searchParams: ThreadFilters,
        searchFilter: string,
        includeAll: boolean,
    ): Observable<IPaginated<TableThreadListing>> {
        const searchThreadList$ = this.portalService.getSearchThreadList(
            page,
            this.pageSize,
            searchParams,
            searchFilter,
            sort.sort,
            sort.order,
            includeAll ?? this.filters.includeAll,
            this.delphiSortEnabled,
        );

        return userId$.pipe(
            switchMap((userId) => this.searchableThreadsService.threadListUpdates(page, userId)),
            switchMap(() => searchThreadList$),
            map((listing) => this.searchableThreadsService.getEnrichedListings(listing)),
        );
    }

    private getSearchParams(filters: ITimelineFilters, role: Role): ThreadFilters {
        const {
            account: accountFilters,
            workflow: workflowFilters,
            type: typeFilters,
            status: statusFilters,
            assignees: assigneeFilters,
            dateRange: dateRangeFilters,
            roles: rolesFilters,
        } = filters;
        const account = accountFilters.map((account) => account.key);
        const workflow = workflowFilters.map((workflow) => workflow.key);
        const stepAssigneeRoles = this.mapAssigneeRoles(rolesFilters);

        const type = typeFilters.map((type) => type.key);

        const assigneeSearchParam = this.getAssigneeSearchParam(assigneeFilters, role);
        const workflowStatusSearchParam = this.getWorkflowStatusSearchParam(statusFilters, role);
        const dueDateStart = dateRangeFilters?.from ? dateRangeFilters.from.toISOString() : null;
        const dueDateEnd = dateRangeFilters?.to ? dateRangeFilters.to.toISOString() : null;

        return {
            account,
            workflow,
            type,
            dueDateStart,
            dueDateEnd,
            stepAssigneeRoles,
            ...assigneeSearchParam,
            ...workflowStatusSearchParam,
        };
    }

    private getAssigneeSearchParam(assigneeFilterOptions: FilterOption[], role: Role): ThreadFilters {
        const source: ThreadFilterSources = this.threadFilterService.getAssigneeFilterSource(role);
        const assignees = assigneeFilterOptions.map((assignee) => assignee.key);
        return { [source]: assignees };
    }

    private getWorkflowStatusSearchParam(workflowStatus: FilterOption[], role: Role): ThreadFilters {
        const source: ThreadFilterSources = InternalRoles.includes(role) ? "internalStepStatus" : "externalStepStatus";

        const sourceStatus = workflowStatus.map((status) =>
            status.key === ThreadStatus.closed ? SystemStepId.End : status.key,
        );

        return { [source]: sourceStatus };
    }

    private mapAssigneeRoles(rolesFilters: FilterOption[]): string[] {
        const roleMapping: Record<AssigneeRoles, Role[]> = {
            [AssigneeRoles.Visory]: [
                Role.SuperAdministrator,
                Role.Administrator,
                Role.SuccessManager,
                Role.Lead,
                Role.QualityTeam,
                Role.Staff,
                Role.Partner,
            ],
            [AssigneeRoles.Expert]: [Role.Expert],
            [AssigneeRoles.Customer]: [Role.Client],
        };

        return rolesFilters
            .map(({ key }) => roleMapping[key as AssigneeRoles])
            .filter(Boolean)
            .flat();
    }
}
