import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IThreadCard, IVaultRequestCardState } from "@visoryplatform/threads";
import { RequestStatuses } from "../constants/request.constants";

@Component({
    selector: "request",
    templateUrl: "./request.component.html",
    styleUrls: ["./request.component.scss"],
})
export class RequestComponent {
    @Input() accountName: string;
    @Input() analyticsPrefix: string;
    @Input() saveButtonTitle: string;
    @Input() subtitle: string;
    @Input() title: string;
    @Input() subheaderDescription: string;
    @Input() loading: boolean;
    @Input() cancelButtonTitle: string;
    @Input() completedStatusLabel: RequestStatuses;
    @Input() disableSave: boolean;
    @Input() card: IThreadCard;
    @Input() state: IVaultRequestCardState;

    @Output() close = new EventEmitter<void>();
    @Output() save = new EventEmitter<void>();
}
