import { CommonModule } from "@angular/common";
import { Component, computed, input } from "@angular/core";
import { SystemStepId } from "@visoryplatform/workflow-core";
import { FindexUiModule } from "../../../findex-ui/findex-ui.module";
@Component({
    selector: "workflow-step-checkbox",
    templateUrl: "./workflow-step-checkbox.component.html",
    styleUrls: ["./workflow-step-checkbox.component.scss"],
    standalone: true,
    imports: [CommonModule, FindexUiModule],
})
export class WorkflowStepCheckboxComponent {
    isCompleted = input.required<boolean>();
    currentStepId = input.required<string>();
    stepId = input.required<string>();
    index = input.required<number>();

    readonly SystemStepId = SystemStepId;

    readonly isActive = computed(() => this.currentStepId() === this.stepId());
    readonly isNotReady = computed(() => !(this.isActive() || this.isCompleted()));
    readonly isClosed = computed(() => this.currentStepId() === SystemStepId.End);
    readonly isGreyNumber = computed(() => this.currentStepId() !== this.stepId());
    readonly isDoubleDigit = computed(() => this.index() + 1 >= 10);
    readonly displayNumber = computed(() => (this.currentStepId() !== SystemStepId.End ? this.index() + 1 : null));
}
