import { Component } from "@angular/core";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui/findex-ui.module";

@Component({
    selector: "thread-overview-content-ghost-loader",
    imports: [FindexUiModule],
    styleUrls: ["./thread-overview-content-ghostloader.component.scss"],
    template: `
        <div class="ghost-loader">
            <ghost-loader
                [showAnimation]="true"
                shape="square"
            >
            </ghost-loader>
        </div>
    `,
    standalone: true,
})
export class ThreadOverviewContentGhostLoaderComponent {}
