import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ITimeline, Role } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { Loader } from "../../../shared/services/loader";
import { ROLE, THREAD } from "../../../threads-ui/interfaces/IUiCard";
import { ThreadRouteService } from "../thread-route/thread-route.service";

@Component({
    selector: "threads-list-route",
    templateUrl: "./threads-list-route.component.html",
    styleUrls: ["./threads-list-route.component.scss"],
    providers: [
        {
            provide: THREAD,
            useFactory: (threadRouteService: ThreadRouteService, activatedRoute: ActivatedRoute, loader: Loader) =>
                threadRouteService.getThread(activatedRoute, loader),
            deps: [ThreadRouteService, ActivatedRoute, Loader],
        },
        {
            provide: ROLE,
            useFactory: (authService: AuthService) => authService.getGlobalRole(),
            deps: [AuthService, ActivatedRoute, Loader],
        },
    ],
})
export class ThreadsListRouteComponent implements OnInit {
    globalRole$: Observable<Role>;

    currentThread$: Observable<ITimeline>;
    currentRole$: Observable<Role>;

    constructor(
        private authService: AuthService,
        @Inject(THREAD) private thread$: Observable<ITimeline>,
        @Inject(ROLE) private role$: Observable<Role>,
    ) {}

    ngOnInit(): void {
        const user$ = this.authService.getValidUser();

        this.currentThread$ = this.thread$;
        this.currentRole$ = this.role$;
        this.globalRole$ = user$.pipe(map((user) => user.globalRole));
    }
}
