import { Injectable } from "@angular/core";
import { VaultStateAction } from "@visoryplatform/threads";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { viewInstructionsRequestTaskAction } from "../vault/vault-task-actions";
import { InstructionsCardComponent } from "./components/instructions-card/instructions-card.component";

@Injectable()
export class InstructionsPlugin implements IPluginFactory {
    readonly id = "InstructionsPlugin";

    constructor(libraries: Libraries) {
        libraries.cardViews.register("vault-request-instructions", InstructionsCardComponent);
        libraries.taskActions.register(VaultStateAction.InstructionsViewResponse, viewInstructionsRequestTaskAction);
    }
}
