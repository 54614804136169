import { Platform } from "@angular/cdk/platform";
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "pdf-preview",
    templateUrl: "./pdf-preview.component.html",
    styleUrls: ["./pdf-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPreviewComponent implements OnInit, OnChanges {
    @Input() data: string;

    dataUrlBypass: SafeResourceUrl;
    contentType: string;

    constructor(
        private sanitizer: DomSanitizer,
        private platform: Platform,
    ) {}

    ngOnInit() {
        //Safari has a bug that crashest the browser if you set the content type
        //Only remove once bug is resolved and majority of Safari users are on the fixed versions
        //https://discussions.apple.com/thread/253191136
        //https://github.com/angular/angular/issues/40309
        this.contentType = this.platform.SAFARI ? "" : "application/pdf";
    }

    ngOnChanges(changes: SimpleChanges) {
        const { data } = changes;

        if (data) {
            if (data.currentValue) {
                //WARNING: if at any point we let users upload previewable documents we need to reconsider
                this.dataUrlBypass = this.sanitizer.bypassSecurityTrustResourceUrl(data.currentValue + "#navpanes=0");
            } else {
                this.dataUrlBypass = null;
            }
        }
    }
}
