import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { NotificationsFrontendModule } from "projects/notifications-frontend/src/notifications-frontend.module";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui/findex-ui.module";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { NotificationCardComponent } from "./components/notification-card/notification-card.component";
import { NotificationPlugin } from "./notification.plugin";

@NgModule({
    imports: [CommonModule, FxUiModule, ThreadsUiModule, MatDialogModule, FindexUiModule, NotificationsFrontendModule],
    declarations: [NotificationCardComponent],
    providers: [{ provide: PLUGIN_FACTORY, useClass: NotificationPlugin, multi: true }],
})
export class NotificationModule {}
