import { Component, EventEmitter, Input, OnInit, output, Output } from "@angular/core";
import { SortDirection } from "@angular/material/sort";
import { BehaviorSubject, Observable } from "rxjs";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { IPaginationDetails } from "../../../shared/interfaces/IPaginationDetails";
export interface IPaginatorSort {
    sort: string;
    order: SortDirection;
}

@Component({
    selector: "fx-paginator",
    templateUrl: "fx-paginator.component.html",
    styleUrls: ["./fx-paginator.component.scss"],
})
export class FxPaginatorComponent implements OnInit {
    @Input() enableBack?: boolean;
    @Input() enableNext?: boolean;
    @Input() paginationDetails?: IPaginationDetails;
    @Output() goBack = new EventEmitter<number>();
    @Output() goNext = new EventEmitter<number>();

    goTo = output<number>();

    public canGoBack: Observable<boolean>;

    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    private currentPageSource = new BehaviorSubject<number>(1);

    ngOnInit(): void {
        // Initialize current page to 1
        this.currentPageSource.next(1);
    }

    nextPage(): void {
        const currentPage = this.currentPageSource.value;
        this.currentPageSource.next(currentPage + 1);
        this.goNext.emit(currentPage + 1);
    }

    previousPage(): void {
        const currentPage = this.currentPageSource.value;
        this.currentPageSource.next(currentPage - 1);
        this.goBack.emit(currentPage - 1);
    }

    onPageChange(event: number): void {
        this.currentPageSource.next(event);
        this.goTo.emit(event);
    }
}
