import { startCase } from "lodash-es";
import { DateTime } from "luxon";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth";

export class GreetingFunction {
    static userToGreeting(user: AppUser): string {
        const firstName = user.details?.givenName || user.name;
        const titleCaseName = startCase(firstName);
        const now = DateTime.local();
        return `${this.getGreetingTime(now)} ${titleCaseName}!`;
    }

    private static getGreetingTime(dateTime: DateTime): string {
        if (!dateTime || !dateTime.isValid) {
            return "Hello";
        }

        const splitAfternoon = 12; // 24hr time to split the afternoon
        const splitEvening = 17; // 24hr time to split the evening

        const currentHour = parseFloat(dateTime.toFormat("HH"));

        if (isNaN(currentHour)) {
            return "Hello";
        }

        if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
            // Between 12 PM and 5PM
            return "Good afternoon";
        } else if (currentHour >= splitEvening) {
            // Between 5PM and Midnight
            return "Good evening";
        }
        // Between dawn and noon
        return "Good morning";
    }
}
