import { Role } from "@visoryplatform/threads";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { Tour } from "../../projects/portal-modules/src/lib/shared/interfaces/tours";

export const introductionTour: Tour = {
    id: "introduction",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Welcome to <img class="tour-logo tour-logo-align-center" src="" alt="Visory Logo" />`,
            contentHtml: `
                <p>Since this is your first time on the platform, we've prepared a quick guide to show you around.</p>`,
            nextButtonText: `Start Guide`,
            menuStateBeforeShow: "closed",
            rolesToShow: [Role.Administrator, Role.Staff, Role.Client],
        },
        {
            titleHtml: `Timelines`,
            contentHtml: `<p>Timelines are where you'll communicate with your Visory team, access outstanding tasks, join meetings and more.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#timeline-menu-item .menu-item",
                side: "bottom",
            },
            menuStateBeforeShow: "open",
            rolesToShow: [Role.Administrator, Role.Staff, Role.Client],
            hideForFeatureFlag: LaunchDarklyFeatureFlags.EnableWorkflowListAndStepper,
        },
    ],
};

export const meetingsTour: Tour = {
    id: "meetings",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Upcoming Meetings`,
            contentHtml: `<p>All meetings are held on the platform. Please ensure that you join the meeting at the scheduled time. You will need a working microphone and speaker with video camera optional. </p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#dashboard-upcoming-meetings",
                side: "bottom",
            },
            menuStateBeforeShow: "closed",
            rolesToShow: [Role.Administrator, Role.Staff, Role.Client],
        },
    ],
};

export const notificationsTour: Tour = {
    id: "visory-notifications",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Notifications`,
            contentHtml: `<p>Your notification feed will keep you up to date on account activity, so nothing is missed.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: ".recent-notifications-link",
                side: "bottom",
            },
            menuStateBeforeShow: "closed",
            rolesToShow: [Role.Administrator, Role.Staff, Role.Client],
        },
    ],
};

export const insightsTour: Tour = {
    id: "visory-insights",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Insights`,
            contentHtml: `<p>All uploaded Insight Reports delivered by your Visory team can be found here.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#insights-menu-item .menu-item",
                side: "bottom",
            },
            menuStateBeforeShow: "open",
            rolesToShow: [Role.Client],
        },
    ],
};

export const filesTour: Tour = {
    id: "files",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Files`,
            contentHtml: `<p>The files tab will allow you to access and search for all accessible documents.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#files-menu-item .menu-item",
                side: "bottom",
            },
            menuStateBeforeShow: "open",
            rolesToShow: [Role.Administrator, Role.Staff, Role.Client],
        },
    ],
};

export const calendarTour: Tour = {
    id: "calendar",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Calendar`,
            contentHtml: `<p>Your calendar will show you key dates and information.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#calendar-menu-item .menu-item",
                side: "bottom",
            },
            menuStateBeforeShow: "open",
            rolesToShow: [Role.Administrator, Role.Staff, Role.Client],
        },
    ],
};

export const newDashboardTour: Tour = {
    id: "newDashboard",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Your new dashboard!`,
            contentHtml: `
                <p>Designed to help you focus on what matters most, your dashboard will highlight tasks that need your attention</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#dashboard-threads",
                side: "bottom",
            },
            menuStateBeforeShow: "open",
            rolesToShow: [Role.Administrator, Role.Staff, Role.Client],
        },
    ],
};

export const newWorkflowListTour: Tour = {
    id: "newWorkflows",
    tourRoute: "dashboard",
    featureFlag: LaunchDarklyFeatureFlags.EnableWorkflowListAndStepper,
    steps: [
        {
            titleHtml: `View workflows!`,
            contentHtml: `
                <p>Click 'Workflows' for a full view of your services and active workflows. Each workflow will have defined steps that are updated by your team for a more transparent and organised experience</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#workflows-menu-item",
                side: "bottom",
            },
            menuStateBeforeShow: "open",
            rolesToShow: [Role.Administrator, Role.Staff, Role.Client],
        },
    ],
};
