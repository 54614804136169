import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { Inject, NgModule } from "@angular/core";
import { ViewExtensionComponent } from "./components/view-extension/view-extension.component";
import { IPluginFactory, PLUGIN_FACTORY } from "./interfaces/IPluginFactory";
import { Libraries } from "./services/Libraries";

@NgModule({
    declarations: [ViewExtensionComponent],
    exports: [ViewExtensionComponent],
    imports: [CommonModule, PortalModule],
    providers: [Libraries],
})
export class PluginsModule {
    constructor(@Inject(PLUGIN_FACTORY) _plugins: IPluginFactory[]) {}
}
