import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ISlot } from "@visoryplatform/fx-ui/lib/components/calendar/calendar";
import { IStaffProfile } from "@visoryplatform/threads";
import { CalendarService, ICalendarAvailability } from "projects/default-plugins/calendar/services/calendar.service";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics/services/analytics.service";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { OnboardingVideoModalComponent } from "../onboarding-video-modal/onboarding-video-modal.component";

const TWO_MONTHS = 60 * 24 * 60 * 60 * 1000;
const MEETING_DURATION = 45;

@Component({
    selector: "app-onboarding-book-meeting",
    templateUrl: "./onboarding-book-meeting.component.html",
    styleUrls: ["./onboarding-book-meeting.component.scss"],
})
export class OnboardingBookMeetingComponent {
    @Input() staffId: string;
    @Input() staffProfile: IStaffProfile;
    @Input() analyticsPrefix = "";

    @Output() modelUpdate: EventEmitter<ISlot> = new EventEmitter();
    @Output() handleResetStaff: EventEmitter<void> = new EventEmitter();

    minDate: string;
    maxDate: string;
    slotPreviews: any[];
    selectedDate: { start: string; end: string };
    onboardingVideoUrl: string;

    loading$: Observable<any>;
    activeAvailability$: BehaviorSubject<ICalendarAvailability> = new BehaviorSubject(null);

    constructor(
        private calendarService: CalendarService,
        private dialog: MatDialog,
        private analyticsService: AnalyticsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.maxDate = new Date(Date.now() + TWO_MONTHS).toISOString();
        this.minDate = new Date().toISOString();
        this.onboardingVideoUrl = this.environment.onboardingVideoUrl;
    }

    initialize(staffProfile: IStaffProfile) {
        // reset state
        this.staffProfile = null;
        this.activeAvailability$.next(null);

        this.handleResetStaff.emit();

        this.calendarService
            .checkPublicStaffAvailability(staffProfile?.userId, new Date().toISOString(), MEETING_DURATION)
            .pipe(take(1))
            .subscribe((availability) => {
                this.staffProfile = staffProfile;
                this.activeAvailability$.next(availability);
            });
    }

    async monthChange(startDate: string | Date) {
        const slots = await this.calendarService
            .checkPublicStaffAvailability(
                this.staffProfile?.userId,
                new Date(startDate).toISOString(),
                MEETING_DURATION,
            )
            .toPromise();

        this.activeAvailability$.next(slots);
    }

    handleCalendarEvent(event: ISlot): void {
        if (!event) {
            this.selectedDate = null;
            this.modelUpdate.emit(this.selectedDate);
            return;
        }

        if (event.start && event.end) {
            this.selectedDate = event;
        } else {
            this.selectedDate = null;
        }
        this.modelUpdate.emit(this.selectedDate);
    }

    openVideoModal($event: Event) {
        $event.preventDefault();

        this.analyticsService.recordEvent("onboarding-video", "clicked");

        const config = {
            panelClass: "onboarding-video-modal-panel",
            width: "90%",
            maxWidth: "640px",
            disableClose: false,
        };

        this.dialog.open(OnboardingVideoModalComponent, config);
    }
}
