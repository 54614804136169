import { Component, Input, OnDestroy, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, FormRecord, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs";

import { MatTableDataSource } from "@angular/material/table";
import { ITimeline } from "@visoryplatform/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Paginator } from "projects/portal-modules/src/lib/shared/services/paginator";
import { ThreadsEnrichmentService } from "projects/portal-modules/src/lib/threads-ui/services/threads-enrichment.service";
import { UserProfileRouteService } from "projects/portal-modules/src/lib/user-profile/services/user-profile-route.service";
import { switchMap } from "rxjs/operators";
import { UserAssigneeService } from "../../services/user-assignee.service";

@Component({
    selector: "bulk-replace-user-workflows",
    templateUrl: "./bulk-replace-user-workflows.component.html",
    styleUrls: ["./bulk-replace-user-workflows.component.scss"],
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: BulkReplaceUserWorkflowsComponent }],
})
export class BulkReplaceUserWorkflowsComponent implements OnDestroy, ControlValueAccessor {
    @Input() newUserIds: string[];
    @Input() totalUserActiveWorkflows: number;
    @Input() selectedAccountIds: string[];

    loader = new Loader();
    tableHeaders = {
        service: "Service",
        reviewChange: "Change",
        account: "Account",
        timelineTitle: "Workflow",
        replace: "Replace",
        dueDate: "Due date",
    };

    form = new FormRecord<FormControl<boolean>>({});

    tableData = new MatTableDataSource<ITimeline>();

    paginator = new Paginator<ITimeline>(10, false);
    userTimelines$: Observable<ITimeline[]>;
    userId$: Observable<string>;

    private userTimelinesSubscription: Subscription;
    private onChange?: (excludeThreadIds: string[]) => void;
    private onTouch?: () => void;
    private selectedAccountIds$ = new BehaviorSubject<string[]>([]);

    constructor(
        private userAssigneeService: UserAssigneeService,
        private userProfileRouteService: UserProfileRouteService,
        private threadsEnrichmentService: ThreadsEnrichmentService,
    ) {
        this.userId$ = this.userProfileRouteService.getUserId();
        this.userTimelines$ = this.paginator.wrap();
        this.paginator.refresh((page) =>
            combineLatest([this.userId$, this.selectedAccountIds$]).pipe(
                switchMap(([userId, accountIds]) => {
                    const filteredAccountIds = accountIds.includes("all") ? null : accountIds;
                    return this.loader.wrap(
                        this.userAssigneeService.listTimelines(userId, page, undefined, undefined, filteredAccountIds),
                    );
                }),
            ),
        );

        this.userTimelinesSubscription = this.userTimelines$.subscribe((timelines) => {
            const threadIds = timelines.map((timeline) => timeline.id);
            for (const threadId of threadIds) {
                if (!this.form.controls[threadId]) {
                    this.form.addControl(threadId, new FormControl<boolean>(true));
                }
            }

            this.tableData.data = this.threadsEnrichmentService.enrichThreads(timelines);
        });

        this.form.valueChanges.subscribe(() => {
            const excludeThreadIds = Object.entries(this.form.controls)
                .filter(([_, control]) => !control.value)
                .map(([threadId]) => threadId);
            this.onChange?.(excludeThreadIds);
            this.onTouch?.();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { selectedAccountIds } = changes;
        if (selectedAccountIds) {
            const threadIds = Object.keys(this.form.controls);
            for (const threadId of threadIds) {
                this.form.controls[threadId].setValue(true, { emitEvent: true });
            }

            this.selectedAccountIds$.next(this.selectedAccountIds);
        }
    }

    ngOnDestroy(): void {
        this.userTimelinesSubscription?.unsubscribe();
    }

    writeValue(excludeThreadIds: string[]): void {
        for (const threadId of excludeThreadIds) {
            if (!this.form.controls[threadId]) {
                this.form.addControl(threadId, new FormControl<boolean>(false));
            } else {
                this.form.controls[threadId].setValue(false);
            }
        }
    }

    registerOnChange(fn: (excludeThreadIds: string[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }
}
