import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IWorkflowDesignType, SortOption } from "@visoryplatform/threads";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { UniqueThreadType, WorkflowConfigurationOptions } from "../../types/UniqueThreadType";

import { FeatureFlagService } from "../../../../../feature-flags/services/feature-flags.service";
import { ThreadsHelperService } from "../../../../services/threads-helper.service";
import { DesignTypeService } from "../../../../services/workflow/design-type.service";
import { SelectServiceControl } from "../../types/SelectDesignType";

@Component({
    selector: "select-workflow-service",
    templateUrl: "./select-workflow-service.component.html",
    styleUrls: ["./select-workflow-service.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectWorkflowServiceComponent,
        },
    ],
})
export class SelectWorkflowServiceComponent implements ControlValueAccessor, OnDestroy, OnChanges {
    @Input() workflowDesignTypes?: IWorkflowDesignType[];
    @Input() showWorkflowOptionSelectors: boolean;

    public sortOption = SortOption;

    form = new FormGroup({
        threadType: new FormControl<UniqueThreadType>(null),
        configurationOption: new FormControl<WorkflowConfigurationOptions>(null),
    });
    WorkflowConfigurationOptions = WorkflowConfigurationOptions;

    onChange?: (obj: Partial<SelectServiceControl>) => void;
    onTouch?: () => void;

    controlSub?: Subscription;

    workflowDesignTypes$ = new ReplaySubject<IWorkflowDesignType[]>(1);
    threadTypes$: Observable<UniqueThreadType[]> = this.workflowDesignTypes$
        .asObservable()
        .pipe(switchMap((threadTypes) => this.getUniqueThreadTypes(threadTypes)));

    constructor(
        private threadHelperService: ThreadsHelperService,
        private designTypeService: DesignTypeService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.controlSub = this.form.valueChanges.subscribe((value) => {
            const mappedValue = { ...value, threadType: value.threadType?.threadType };
            this.onChange?.(mappedValue);
            this.onTouch?.();
        });
    }

    writeValue(serviceControl: SelectServiceControl): void {
        this.threadTypes$.pipe(take(1)).subscribe((types) => {
            const threadType = types?.find((type) => type.threadType === serviceControl.threadType);
            this.form.patchValue({ ...serviceControl, threadType });
        });
    }

    registerOnChange(fn: (serviceControl: SelectServiceControl) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { workflowDesignTypes } = changes;

        if (workflowDesignTypes && this.workflowDesignTypes) {
            this.workflowDesignTypes$.next(workflowDesignTypes?.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.controlSub?.unsubscribe();
    }

    private getUniqueThreadTypes(threadTypes: IWorkflowDesignType[]): Observable<UniqueThreadType[]> {
        return this.featureFlagService.getFlags().pipe(
            map((flags) => this.designTypeService.filterByFeatureFlag(threadTypes, flags)),
            map((filteredDesigns) => this.threadHelperService.getUniqueTypes(filteredDesigns)),
        );
    }
}
