<section class="tab-section">
    <h4 class="integrations-settings-title">Connect your accounts to {{ appName }}</h4>
    <div
        class="integrations-settings-grid"
        *ngIf="account$ | async as account"
    >
        <integrations-account
            *ngFor="let integration of integrations$ | async"
            [accountId]="account.id"
            [integration]="integration"
            [loading]="loader.counter > 0"
            [enabled]="hasUpdatePermissions$ | async"
            [metadata]="account.metadata?.integrations?.[integration.issuerType]"
            (connectIntegration)="connectIntegration($event)"
            (selectTenant)="selectTenant(account, $event, integration)"
            (disconnectIntegration)="disconnectIntegration($event)"
        >
        </integrations-account>
    </div>

    @if (showPayrunSettings$ | async) {
        <payroll-settings
            [account]="account$ | async"
            (save)="savePayrunSettings($event)"
        ></payroll-settings>
    }
</section>
