import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuardConfiguration,
    MsalInterceptorConfiguration,
    MsalService,
} from "@azure/msal-angular";
import {
    AccountInfo,
    BrowserCacheLocation,
    EventType,
    InteractionStatus,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication,
    RedirectRequest,
} from "@azure/msal-browser";
import { BehaviorSubject, defer, merge, Observable, of, Subject, Subscription, throwError } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { environmentCommon, EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { Loader } from "../../../shared/services/loader";
import { AppUser, AuthStrategy } from "../../model/AppUser";
import { AuthorizationLevel } from "../../model/AuthorizationLevel";
import { IAuthenticationStrategy } from "../../model/IAuthenticationStrategy";
import { LoginStep, LoginStepDetails } from "../../model/LoginStep";
import { AzureAdInterceptor } from "./azure-ad.interceptor";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(_logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(environment: EnvironmentSpecificConfig): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.activeDirectory.clientId,
            authority: `https://login.microsoftonline.com/${environmentCommon.activeDirectory.tenant}`,
            redirectUri: `${environment.appUrl}${environmentCommon.activeDirectory.redirectPath}`,
            postLogoutRedirectUri: `${environment.appUrl}${environmentCommon.login.staff}`,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE,
        },
        system: {
            tokenRenewalOffsetSeconds: 450,
        },
    });
}

const graphApi = "https://graph.microsoft.com/v1.0/me";

export function MSALInterceptorConfigFactory(environment: EnvironmentSpecificConfig): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set(graphApi, ["user.read"]);
    for (const resource of environment.auth.protectedResources) {
        protectedResourceMap.set(resource, ["user.read"]);
    }

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ["user.read"],
        },
    };
}

@Injectable({ providedIn: "root" })
export class AzureAdAuthStrategy implements IAuthenticationStrategy, HttpInterceptor, OnDestroy {
    private isReady$: Observable<InteractionStatus>;
    private user$: Observable<AppUser>;
    private login$ = new BehaviorSubject<LoginStepDetails>({ step: undefined });
    private readonly _destroying$ = new Subject<void>();

    private loginSuccessSub: Subscription;
    private tokenFailureSub: Subscription;
    private acquireTokenSub: Subscription;
    private notAuthedSub: Subscription;

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        @Inject(MSAL_INTERCEPTOR_CONFIG) private interceptorConfig: MsalInterceptorConfiguration,
        private msalService: MsalService,
        private broadcastService: MsalBroadcastService,
        private azureAdInterceptor: AzureAdInterceptor,
        private loader: Loader,
    ) {
        this.msalService.instance.enableAccountStorageEvents();

        this.isReady$ = this.broadcastService.inProgress$.pipe(
            filter((status) => status === InteractionStatus.None),
            shareReplay(1),
        );

        const loginEvents$ = this.broadcastService.msalSubject$.pipe(
            filter(
                (msg) =>
                    msg.eventType === EventType.ACCOUNT_ADDED ||
                    msg.eventType === EventType.ACCOUNT_REMOVED ||
                    msg.eventType === EventType.LOGIN_SUCCESS,
            ),
            switchMap(() => this.isReady$),
        );

        loginEvents$.pipe(takeUntil(this._destroying$)).subscribe(() => {
            if (this.msalService.instance.getAllAccounts().length === 0) {
                window.location.pathname = "/";
            }
        });

        const eventType$ = this.broadcastService.msalSubject$.pipe(
            map((message) => message.eventType),
            distinctUntilChanged(),
        );

        const acquireTokenStart$ = eventType$.pipe(
            filter((type) => type === EventType.ACQUIRE_TOKEN_START),
            take(1),
        );

        const acquireTokenSuccess$ = eventType$.pipe(
            filter((type) => type === EventType.ACQUIRE_TOKEN_SUCCESS),
            take(1),
        );

        const acquireTokenFailure$ = eventType$.pipe(
            filter((type) => type === EventType.ACQUIRE_TOKEN_FAILURE),
            take(1),
        );

        const acquireToken$ = merge(acquireTokenStart$, acquireTokenSuccess$, acquireTokenFailure$);

        this.acquireTokenSub = acquireToken$.subscribe((value) => {
            if (value === EventType.ACQUIRE_TOKEN_START) {
                this.loader.show();
            } else {
                this.loader.hide();
            }
        });

        this.isReady$.pipe(takeUntil(this._destroying$)).subscribe(() => this.defaultActiveAccount());

        this.user$ = loginEvents$.pipe(
            switchMap(() => this.isReady$),
            map(() => this.defaultActiveAccount()),
            startWith(this.msalService.instance.getActiveAccount()),
            switchMap((user) => this.mapAccountToUser(user)),
            shareReplay(1),
        );

        this.user$
            .pipe(
                filter((user) => !!user),
                takeUntil(this._destroying$),
            )
            .subscribe(() => this.login$.next({ step: LoginStep.LOGIN_COMPLETE }));

        this.broadcastService.msalSubject$
            .pipe(
                filter((event) => event.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._destroying$),
            )
            .subscribe(() => this.login());
    }

    getHttpHeaders(): Observable<any> {
        return this.azureAdInterceptor.getHeaders("").pipe(map((headers) => this.buildHeaders(headers)));
    }

    getLogin() {
        return this.login$.asObservable();
    }

    startLogin(): Observable<LoginStepDetails> {
        this.login();

        return this.login$.asObservable();
    }

    answerChallenge(): Observable<never> {
        return throwError(() => new Error("Not supported by this authentication strategy"));
    }

    logout(): Observable<void> {
        return defer(() => {
            const currentAccount = this.defaultActiveAccount();

            if (currentAccount) {
                // need to pass this to logout automatically without account selection prompt
                // alternatively can provide domain_hint
                this.msalService.logout();
            }

            return of(null);
        });
    }

    getUser(): Observable<AppUser> {
        return this.user$;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.azureAdInterceptor.intercept(req, next);
    }

    switchUser() {
        this.broadcastService.inProgress$
            .pipe(
                filter((status) => status === InteractionStatus.None),
                take(1),
            )
            .subscribe(() => {
                if (this.hasStaffLogin()) {
                    const authRequest = this.interceptorConfig.authRequest as RedirectRequest;
                    this.msalService.loginRedirect({ ...authRequest, prompt: "select_account" });
                }
            });
    }

    ngOnDestroy() {
        this.loginSuccessSub?.unsubscribe();
        this.tokenFailureSub?.unsubscribe();
        this.notAuthedSub?.unsubscribe();
        this.acquireTokenSub?.unsubscribe();

        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    private buildHeaders(headers: HttpHeaders): Record<string, string> {
        const headerKeys = headers.keys();
        return headerKeys.reduce((updated, name) => ({ ...updated, [name]: headers.get(name) }), {});
    }

    private defaultActiveAccount(): AccountInfo | null {
        const activeAccount = this.msalService.instance.getActiveAccount();

        if (activeAccount) {
            return activeAccount;
        }

        const accounts = this.msalService.instance.getAllAccounts();
        if (!accounts?.length) {
            return null;
        }

        const account = accounts[0];
        this.msalService.instance.setActiveAccount(account);
        return account;
    }

    private hasStaffLogin() {
        const hasLogin = this.defaultActiveAccount();
        return !!hasLogin;
    }

    private login() {
        this.isReady$.pipe(take(1)).subscribe(() => {
            if (this.msalGuardConfig.authRequest) {
                this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
            } else {
                this.msalService.loginRedirect();
            }
        });
    }

    private async mapAccountToUser(userInfo: AccountInfo): Promise<AppUser> {
        if (!userInfo) {
            return null;
        }

        const rawUserId = userInfo.localAccountId;
        const prefixedUserId = `${environmentCommon.activeDirectory.userIdPrefix}-${rawUserId}`;
        const profile = await this.getUserProfile(userInfo);

        return {
            authorizationLevel: AuthorizationLevel.VERIFIED,
            details: {
                givenName: profile.givenName,
                familyName: profile.surname,
            },
            emailAddressVerified: true,
            id: prefixedUserId,
            mobileNumberVerified: true,
            name: userInfo.name,
            type: AuthStrategy.AzureAD,
            globalRole: null,
        };
    }

    private async getUserProfile(account: AccountInfo, retries = 0): Promise<{ givenName: string; surname: string }> {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: account,
        };

        await this.msalService.initialize().toPromise();
        const token = await this.msalService.acquireTokenSilent(accessTokenRequest).pipe(take(1)).toPromise();
        const headers = { Authorization: token.accessToken };

        try {
            const response = await fetch(graphApi, { headers });

            if (response.status !== 200) {
                throw new Error(response.statusText);
            }

            return await response.json();
        } catch (err) {
            console.error("MS Graph API error", err);
            if (retries < 3) {
                throw err;
            } else {
                return await this.getUserProfile(account, retries + 1);
            }
        }
    }
}
