import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DueDateStatus, IWorkflow, getWorkflowStatus } from "@visoryplatform/workflow-core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";

import { VBadgeColors } from "@visoryplatform/portal-ui";
import { WorkflowTimerService } from "../../../workflow-status/services/workflow-timer.service";

export type DueDateStatusIndicator = {
    status: DueDateStatus;
    color: VBadgeColors;
    tooltip: string;
};

@Component({
    selector: "workflow-status-indicator",
    templateUrl: "./workflow-status-indicator.component.html",
    styleUrls: ["./workflow-status-indicator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowStatusIndicatorComponent implements OnChanges {
    @Input() workflow: IWorkflow;
    @Input() showTooltipIcon = true;
    @Input() size: "small" | "medium" | "large" = "medium";

    dueDateStatus$: Observable<DueDateStatusIndicator>;
    private refreshSubject = new BehaviorSubject<void>(undefined);

    constructor(private timerService: WorkflowTimerService) {
        this.dueDateStatus$ = this.refreshSubject.pipe(
            switchMap(() => {
                const initialStatus = this.getDueDateStatusWithColor();
                return this.timerService.getTimer().pipe(
                    map(() => this.getDueDateStatusWithColor()),
                    startWith(initialStatus),
                );
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workflow) {
            this.refreshSubject.next();
        }
    }

    private getDueDateStatusWithColor(): DueDateStatusIndicator | null {
        const status = this.getDueDateStatus();
        if (!status) {
            return null;
        }
        const { color, tooltip } = this.getColorForStatus(status);
        return { status, color, tooltip };
    }

    private getDueDateStatus(): DueDateStatus {
        try {
            return getWorkflowStatus(this.workflow);
        } catch (error) {
            console.debug(error);
            return DueDateStatus.ERROR as DueDateStatus;
        }
    }

    private getColorForStatus(status: DueDateStatus): Partial<DueDateStatusIndicator> {
        switch (status) {
            case DueDateStatus.OVERDUE:
                return { color: VBadgeColors.error, tooltip: "This workflow is overdue" };
            case DueDateStatus.BEHIND:
                return { color: VBadgeColors.warning, tooltip: "Time is available to catch up." };
            case DueDateStatus.AT_RISK:
                return { color: VBadgeColors.warning, tooltip: "At risk of missing the due date" };
            case DueDateStatus.ON_TRACK:
                return { color: VBadgeColors.success, tooltip: "On track to meet the due date" };
            case DueDateStatus.MISSED:
                return { color: VBadgeColors.error, tooltip: "Due date has been missed" };
            case DueDateStatus.DELIVERED:
                return { color: VBadgeColors.success, tooltip: "Due date was met" };
            case DueDateStatus.ERROR:
                return {
                    color: VBadgeColors.neutral,
                    tooltip: "There is insufficient data to determine the status of this workflow",
                };
        }
    }
}
