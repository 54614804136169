import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FxUiModule } from "@visoryplatform/fx-ui";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { AnalyticsModule } from "../analytics";
import { FindexUiModule } from "../findex-ui";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { ThreadsUiModule } from "../threads-ui";
import { UiComponentsModule } from "../ui-components";
import { UiLayoutsModule } from "../ui-layouts/ui-layouts.module";
import { CreateRequestGlobalButtonComponent } from "./components/create-request-global-button/create-request-global-button.component";
import { CreateRequestModalComponent } from "./components/create-request-modal/create-request-modal.component";
import { CreateSuccessComponent } from "./components/create-success/create-success.component";
import { CustomerComponent } from "./components/customer/customer.component";
import { ExpertComponent } from "./components/expert/expert.component";
import { MessageInputComponent } from "./components/message-input/message-input.component";
import { SelectAccountComponent } from "./components/select-account/select-account.component";
import { RequestService } from "./services/request.service";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FxUiModule,
        SharedPipesModule,
        AnalyticsModule,
        FindexUiModule,
        UiComponentsModule,
        PortalUiModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        FormsModule,
        UiLayoutsModule,
        ThreadsUiModule,
    ],
    declarations: [
        CreateRequestModalComponent,
        CreateSuccessComponent,
        CreateRequestGlobalButtonComponent,
        CustomerComponent,
        ExpertComponent,
        SelectAccountComponent,
        MessageInputComponent,
    ],
    exports: [CreateRequestModalComponent, CreateRequestGlobalButtonComponent],
    providers: [RequestService],
})
export class CreateRequestModule {}
