import { Component, Inject, Injector, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
    CardReply,
    CardStatus,
    IRequestItem,
    IThreadCard,
    ITimeline,
    IUpdatedRequestItems,
    IVaultRequestCardState,
    InternalRoles,
    Role,
} from "@visoryplatform/threads";
import { AnalyticsService, GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable, Subscription, combineLatest, of } from "rxjs";
import { filter, map, mapTo, switchMap, take, tap } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { PayrunRequestService } from "../../../services/payrun-request.service";
import { VaultRequestService } from "../../../services/vault-request.service";
import { FormRequestItem, FormRequestTable } from "../../../types/RequestTableForm";
import { PayRunRequestActionButtonText, RequestStatuses, VaultCardType } from "../constants/request.constants";
import { IRequestAnalyticsTags } from "../interfaces/IRequestAnalyticsTags";
import { IRequestForm, IRequestItemFormGroup } from "../interfaces/IRequestForms";
import { IRequestModalData } from "../interfaces/IRequestModalData";
import { IRequestReviewModalTitles } from "../interfaces/IRequestReviewModalTitles";
import { IReopenRequestParams, ReopenRequestModalComponent } from "../reopen-request/reopen-request-modal.component";

interface UpdatePayrunValues {
    control: FormGroup<FormRequestItem>;
    formValues: FormGroup<FormRequestTable>["value"];
}

@Component({
    selector: "action-payrun-request",
    templateUrl: "./action-payrun-request.component.html",
    styleUrls: ["./action-payrun-request.component.scss"],
})
export class ActionPayrunRequestComponent implements OnInit, OnDestroy {
    readonly completeButtonText = PayRunRequestActionButtonText.Complete;
    readonly requiredReviewPermissions = ["PayrunRequestApprove", "ThreadUpdateAll"];
    readonly requiredReopenPermissions = ["PayrunRequestReopen", "ThreadUpdateAll"];
    readonly requestStatuses = RequestStatuses;

    actionedPercentage = 0;
    actionedRequestItems = 0;
    analyticsTags: IRequestAnalyticsTags;
    canReopenPayrunItem$: Observable<boolean>;
    canUpdatePayrunItem$: Observable<boolean>;
    card$: Observable<IThreadCard>;
    currentUserRole: Role;
    form: FormGroup<IRequestForm>;
    readonly: boolean;
    replies$: Observable<CardReply[]>;
    requestReviewModalTitles: IRequestReviewModalTitles;
    state$: Observable<IVaultRequestCardState>;
    thread$: Observable<ITimeline>;
    userId$: Observable<string>;
    updatePayrunRequestItemsSub: Subscription;
    modalData: IRequestModalData;

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private payrunRequestService: PayrunRequestService,
        private vaultRequestService: VaultRequestService,
        private authService: AuthService,
        private analytics: AnalyticsService,
        private permissionService: PermissionService,
        private dialog: MatDialog,
        private injector: Injector,
    ) {}

    async ngOnInit(): Promise<void> {
        this.modalData = await this.extensionDisplayService.getData<IRequestModalData>(this.injector).toPromise();

        this.initForm();
        this.setLocalVariables(this.modalData);
    }

    ngOnDestroy(): void {
        this.updatePayrunRequestItemsSub?.unsubscribe();
    }

    updatePayrun({ control }: UpdatePayrunValues, analyticsPrefix: GA_EVENTS_PREFIX): void {
        this.markPayrunItemComplete(control.value.completed, control, analyticsPrefix);
    }

    approveAllPayrun(cardId: string, threadId: string, state: IVaultRequestCardState): void {
        this.analytics.recordEvent("mouse-click", GA_EVENTS.PAYRUN_LINES_APPROVE_ALL);
        this.markAllComplete(true, cardId, threadId, state);
    }

    async markAllComplete(
        isTicked: boolean,
        cardId: string,
        threadId: string,
        state: IVaultRequestCardState,
    ): Promise<void> {
        this.markAsComplete(isTicked);
        const updatedRequestItems: IUpdatedRequestItems[] = state.requestItems
            .filter((requestItem) => requestItem.response.complete.state !== isTicked)
            .map((requestItem) => ({
                fileId: requestItem.fileId,
                complete: isTicked,
            }));
        this.actionedRequestItems = isTicked ? state.requestItems.length : 0;
        this.actionedPercentage = isTicked ? 100 : 0;
        await this.vaultRequestService.updateRequest(updatedRequestItems, cardId, threadId, state.vaultId);
    }

    markPayrunItemComplete(
        isTicked: boolean,
        requestItemControl: FormGroup<FormRequestItem>,
        analyticsPrefix: string,
    ): void {
        this.analytics.recordEvent("mouse-click", `${analyticsPrefix}_markitemcomplete`);
        if (!requestItemControl || !requestItemControl.value?.fileId) {
            return;
        }
        const fileId = requestItemControl.value?.fileId;
        const stateThreadCard$ = combineLatest([this.state$, this.thread$, this.card$]).pipe(take(1));
        const updatePayrunRequestItem$ = stateThreadCard$.pipe(
            switchMap(([state, thread, card]) =>
                this.updatePayrunRequestItem(state, thread, card, isTicked, fileId, requestItemControl),
            ),
        );

        this.updatePayrunRequestItemsSub?.unsubscribe();
        this.updatePayrunRequestItemsSub = updatePayrunRequestItem$.subscribe(() => {
            requestItemControl.markAsPristine();
        });
    }

    requestItemsCompletedChanges(requestItemsValues: boolean[]): void {
        const numberOfItems = requestItemsValues.length;
        const lengthOfCompletedItems = requestItemsValues.filter((value) => !!value);
        this.actionedRequestItems = lengthOfCompletedItems?.length || 0;
        this.actionedPercentage = Math.floor((this.actionedRequestItems / numberOfItems) * 100);
    }

    private updatePayrunRequestItem(
        state: IVaultRequestCardState,
        thread: ITimeline,
        card: IThreadCard,
        isTicked: boolean,
        fileId: string,
        control: FormGroup<FormRequestItem>,
    ): Observable<void> {
        const cardId = card.id;
        const threadId = thread.id;
        const stateVaultId = state.vaultId;
        const requestUpdate = { fileId, complete: isTicked };

        return this.shouldReopenPayrunRequestItem(state, !isTicked).pipe(
            switchMap((shouldReopen) => (shouldReopen ? this.reopenPayrunRequest(thread, card, control) : of(null))),
            switchMap(() => this.vaultRequestService.updateRequest([requestUpdate], cardId, threadId, stateVaultId)),
        );
    }

    private shouldReopenPayrunRequestItem(state: IVaultRequestCardState, notTicked: boolean): Observable<boolean> {
        const isRequestComplete = VaultRequestService.isRequestComplete(state.requestItems);
        const isStateComplete = state.isCompleted;
        const canReopenPayrunItem = isRequestComplete && isStateComplete && notTicked;

        return this.canReopenPayrunItem$.pipe(map((canReopen) => canReopen && canReopenPayrunItem));
    }

    private markAsComplete(val: boolean): void {
        const control = this.form.controls.requestItems;
        control.controls.map((formGroup) => {
            const completedControl = formGroup.controls.completed;
            if (completedControl.disabled) {
                return;
            }
            completedControl.setValue(val);
        });
    }

    private reopenPayrunRequest(
        thread: ITimeline,
        card: IThreadCard,
        control: FormGroup<FormRequestItem>,
    ): Observable<boolean> {
        const reopen$ = this.reopenPayrunRequestModal(thread, card);
        return reopen$.pipe(
            tap((value) => {
                if (!value) {
                    control.controls.completed.setValue(true);
                }
            }),
            filter((value) => !!value),
        );
    }

    private reopenPayrunRequestModal(thread: ITimeline, card: IThreadCard): Observable<boolean> {
        const data: IReopenRequestParams = {
            threadId: thread.id,
            cardId: card.id,
            analyticsPrefix: this.analyticsTags.analyticsPrefix,
        };

        const config = {
            data,
            panelClass: ["centered-modal"],
            disableClose: true,
        };

        return this.dialog
            .open(ReopenRequestModalComponent, config)
            .afterClosed()
            .pipe(
                switchMap((value) => {
                    if (!value) {
                        return of(false);
                    }
                    return this.vaultRequestService
                        .sendRequestCardEvent(thread.id, card.id, card.type, {
                            body: { isCompleted: false },
                        })
                        .pipe(mapTo(true));
                }),
            );
    }

    private initForm(): void {
        this.form = new FormGroup<IRequestForm>({
            title: new FormControl(""),
            cardDescription: new FormControl(""),
            replyMessage: new FormControl(""),
            requestItems: new FormArray<FormGroup<IRequestItemFormGroup>>([]),
        });
    }

    private setLocalVariables(modalData: IRequestModalData): void {
        this.currentUserRole = modalData.role;
        this.readonly = modalData.readonly;
        this.thread$ = modalData.thread$;
        this.card$ = modalData.card$;
        this.state$ = modalData.state$;
        this.replies$ = modalData.replies$;
        this.userId$ = this.authService.getUserId();
        this.canReopenPayrunItem$ = this.permissionService.hasSomePermission(
            this.currentUserRole,
            this.requiredReopenPermissions,
        );
        this.canUpdatePayrunItem$ = this.getCanUpdatePayrunItem(this.card$, this.state$, this.currentUserRole);
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultPayrollApprovalRequest);
        this.requestReviewModalTitles = this.vaultRequestService.getRequestReviewModalTitles(
            VaultCardType.VaultPayrollApprovalRequest,
        );
        this.state$.pipe(take(1)).subscribe((state) => {
            this.setInitialActionedRequestItems(state.requestItems);
        });
    }

    private getCanUpdatePayrunItem(
        card$: Observable<IThreadCard>,
        cardState$: Observable<IVaultRequestCardState>,
        userRole: Role,
    ): Observable<boolean> {
        return combineLatest([card$, cardState$]).pipe(
            switchMap(([card, cardState]) => {
                const isCompleted = cardState?.isCompleted;
                const isCardDisabled = card?.status === CardStatus.Disabled;
                const isStaffOrAdmin = InternalRoles.includes(userRole);
                const canUserUpdate = (isStaffOrAdmin && isCompleted) || !isCompleted;
                const canUpdate = !isCardDisabled && canUserUpdate;
                return this.payrunRequestService.getCanUpdatePayrunItems(userRole, canUpdate);
            }),
        );
    }

    private setInitialActionedRequestItems(requestItems: IRequestItem[]): void {
        if (!requestItems?.length) {
            this.actionedRequestItems = 0;
            this.actionedPercentage = 0;
        }

        const numberOfItems = requestItems.length;
        const lengthOfCompletedItems = requestItems.filter((item) => item.response.complete.state);

        this.actionedRequestItems = lengthOfCompletedItems?.length || 0;
        this.actionedPercentage = Math.floor((this.actionedRequestItems / numberOfItems) * 100);
    }
}
