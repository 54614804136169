export enum WorkflowTitles {
    WorkflowProgress = "Workflow progress",
}

export enum WorkflowTextConstants {
    Automated = "Automated",
    Completed = "Completed: ",
    Due = "Due: ",
    Edit = "Edit",
    Save = "Save",
    SaveComplete = "Save and complete",
    StepAssigned = "Step assigned to",
    TransitionAutomated = "Transition to next step is automated",
    Visory = "Visory",
}
