import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { scan, startWith } from "rxjs/operators";
import { IRequestFileDataExtended } from "../../../interfaces/IVaultRequestCardState";
import { RequestItemFormControlValue } from "../interfaces/IRequestForms";
import { RequestResponseComponent } from "../request-response/request-response.component";

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RequestTodoActionsComponent),
    multi: true,
};

@Component({
    selector: "request-todo-actions",
    templateUrl: "./request-todo-actions.component.html",
    styleUrls: ["./request-todo-actions.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR],
})
export class RequestTodoActionsComponent implements OnInit, OnChanges, ControlValueAccessor {
    @ViewChild(RequestResponseComponent) requestResponseComponent: RequestResponseComponent;
    @Output() textInput = new EventEmitter<{ requestItem: RequestItemFormControlValue; value: string }>();
    @Output() fileAttached = new EventEmitter<{ requestItem: RequestItemFormControlValue; file: File }>();
    @Output() fileRemoved = new EventEmitter<{
        requestItem: RequestItemFormControlValue;
        file: IRequestFileDataExtended;
    }>();
    @Output() fileDownloaded = new EventEmitter<{
        requestItem: RequestItemFormControlValue;
        file: IRequestFileDataExtended;
    }>();

    @Input() requestItem: RequestItemFormControlValue;
    @Input() canDelete: boolean;
    @Input() userId: string;
    @Input() analyticsPrefix: string;
    @Input() readonly = false;

    showTextResponse = false;
    showAttach = false;

    requestFilesDataSource = new Subject<IRequestFileDataExtended[]>();
    requestFilesData$: Observable<IRequestFileDataExtended[]>;

    ngOnInit(): void {
        this.requestFilesData$ = this.requestFilesDataSource.pipe(
            startWith(this.requestItem?.response?.data?.state || []),
            scan((acc: IRequestFileDataExtended[], curr: IRequestFileDataExtended[]): IRequestFileDataExtended[] => {
                const newFiles = this.filterNewFiles(curr, acc);
                const removedFiles = this.filterRemovedFiles(acc, curr);
                const updatedFiles = this.filterUpdatedFiles(acc, curr);
                const filesToDisplay = [...updatedFiles, ...newFiles].filter(
                    (file) => !removedFiles.find((f) => f.filename === file.filename),
                );

                return filesToDisplay;
            }),
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChanged: (newValue?: RequestItemFormControlValue) => void = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: () => void = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        const { requestItem } = changes;

        const currentTextResponse: string = requestItem?.currentValue?.response?.text?.state as string;
        const previousTextResponse: string = requestItem?.previousValue?.response?.text?.state as string;

        if (currentTextResponse !== previousTextResponse) {
            this.showTextResponse = !!changes.requestItem.currentValue.response.text.state;
        }

        this.requestFilesDataSource.next(this.requestItem?.response?.data?.state);
    }

    downloadFile(fileDownloaded: IRequestFileDataExtended): void {
        this.fileDownloaded.emit({ requestItem: this.requestItem, file: fileDownloaded });
    }

    toggleTextResponse(complete?: boolean, hasResponse?: number): void {
        if (complete || hasResponse) {
            return;
        }
        this.showTextResponse = !this.showTextResponse;
    }

    toggleAttach(complete?: boolean): void {
        if (complete) {
            return;
        }
        this.showAttach = !this.showAttach;
    }

    submitTextResponse(value: string): void {
        this.requestItem.response.text.state = value;
        this.textInput.emit({ requestItem: this.requestItem, value });
        this.showTextResponse = !!value;
    }

    attachFile(attachment: File): void {
        const fileName = this.incrementFileNumber(attachment.name);
        const _attachment: File = new File([attachment], fileName, { type: attachment.type });
        const requestFileData: IRequestFileDataExtended = {
            filename: fileName,
            actorId: this.userId,
            timestamp: new Date().toISOString(),
            isLoading: true,
        };

        this.handleUpdatingAttachmentState(requestFileData, _attachment);
    }

    removeFile(removedFile: IRequestFileDataExtended, requestFiles: IRequestFileDataExtended[]): void {
        const requestFilesWithDeleted = requestFiles.map((file) => {
            if (file.filename === removedFile.filename) {
                return { ...file, isDeleting: true };
            } else {
                return file;
            }
        });
        this.requestFilesDataSource.next(requestFilesWithDeleted);
        this.fileRemoved.emit({ requestItem: this.requestItem, file: removedFile });
    }

    responseUpdated(_response: string): void {
        this.onTouched();
    }

    writeValue(value: RequestItemFormControlValue): void {
        this.requestItem = value;
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private handleUpdatingAttachmentState(requestFileData: IRequestFileDataExtended, _attachment: File): void {
        this.requestItem.response.data.state.push(requestFileData);
        this.fileAttached.emit({ requestItem: this.requestItem, file: _attachment });
        this.requestFilesDataSource.next(this.requestItem.response.data.state);
    }

    private incrementFileNumber(fileName: string): string {
        const files = this.requestItem.response.data.state;
        const fileSections = fileName.split(".");
        const fileNumber = files.filter((file) => file.filename.includes(fileSections[0])).length;
        if (fileNumber > 0) {
            return `${fileSections[0]} (${fileNumber}).${fileSections[1]}`;
        } else {
            return fileName;
        }
    }

    private filterNewFiles(
        curr: IRequestFileDataExtended[],
        acc: IRequestFileDataExtended[],
    ): IRequestFileDataExtended[] {
        return curr.filter((currFile) => !acc.find((accFile) => accFile.filename === currFile.filename));
    }

    private filterRemovedFiles(
        acc: IRequestFileDataExtended[],
        curr: IRequestFileDataExtended[],
    ): IRequestFileDataExtended[] {
        return acc.filter(
            (accFile) => !accFile.isLoading && !curr.find((currFile) => currFile.filename === accFile.filename),
        );
    }

    private filterUpdatedFiles(
        acc: IRequestFileDataExtended[],
        curr: IRequestFileDataExtended[],
    ): IRequestFileDataExtended[] {
        return acc.map((file) => {
            const updatedFile = curr.find((newFile) => newFile.filename === file.filename);
            if (updatedFile && !file.isDeleting) {
                return updatedFile;
            } else {
                return file;
            }
        });
    }
}
