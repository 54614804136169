import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IntegrationTypes, Tenant } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { integrations } from "../constants/integrations.constants";
import { Integration } from "../types/Integration";

@Injectable({
    providedIn: "root",
})
export class IntegrationsService {
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private authService: AuthService,
        private permissionService: PermissionService,
    ) {}

    getRedirectUrl(issuerType: string, scopes: string[], redirect: string): string {
        const paramScope = encodeURIComponent(scopes.join(" "));
        return `${this.environment.tokensEndpoints.base}/connect/oauth2/${issuerType}/redirect?scope=${paramScope}&redirect=${redirect}`;
    }

    listIntegrations(): Observable<Integration[]> {
        return of(integrations);
    }

    listTenants(accountId: string, tenantType: IntegrationTypes): Observable<Tenant[]> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/integrations/${tenantType}/tenants`;

        return this.http.get<Tenant[]>(url);
    }

    hasUpdatePermission(): Observable<boolean> {
        return this.authService.getUser().pipe(
            filter((user: AppUser) => !!user),
            switchMap((user) => this.permissionService.checkPermissions(user.globalRole, "UpdateIntegrations")),
        );
    }
}
