import { Component, forwardRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { DateRange, getPredefinedRanges } from "@visoryplatform/portal-ui";
import { IParticipant, Role, ThreadFilters, ThreadStatus } from "@visoryplatform/threads";
import { DueDateStatus } from "@visoryplatform/workflow-core";
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { GA_EVENTS } from "../../../analytics";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { AuthService } from "../../../findex-auth";
import { Loader } from "../../../shared/services/loader";
import { ParticipantCache } from "../../../threads-ui/services/participant-cache.service";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { ThreadFilterService } from "../../../threads-ui/services/thread-filter.service";
import { ThreadsService } from "../../../threads-ui/services/threads.service";
import { ACTIVE_STATUS_OPTION, ALL_OPTION } from "../../constants/option-constants";
import { FilterOption, ITimelineFilters } from "../../interfaces/timeline-filters";
import { AssigneeRoles } from "../timelines-paginated/timelines-paginated.component";

type AccountFilter = { label: string; id: string };
type WorkflowLabelFilter = { label: string };
type AssigneesFilter = { id: string };
type ThreadTypeFilter = { type: string };

type TimelineQueryParams = {
    status?: string | string[];
    type?: string | string[];
    account?: string | string[];
    assignees?: string | string[];
    roles?: string | string[];
    workflow?: string | string[];
    search?: string;
    includeAll?: "true" | "false";
    delphiSort?: "true" | "false";
    startRange?: string;
    endRange?: string;
};

type FilterOptions = {
    assigneeFilters: FilterOption[];
    workflowFilters: FilterOption[];
    typeFilters: FilterOption[];
    statusOptions: FilterOption[];
    roleFilters: FilterOption[];
};

type ResolvedFilterOptions = {
    status: FilterOption[];
    type: FilterOption[];
    assignees: FilterOption[];
    roles: FilterOption[];
    workflow: FilterOption[];
};

@Component({
    selector: "timelines-filters",
    templateUrl: "./timelines-filters.component.html",
    styleUrls: ["./timelines-filters.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimelinesFiltersComponent),
            multi: true,
        },
    ],
})
export class TimelinesFiltersComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
    @Input() accountId: string;
    @Input() defaultToShowAllWorkflows: boolean;

    statusOptions$: Observable<FilterOption[]>;
    accounts$: Observable<FilterOption[]>;
    assignees$: Observable<FilterOption[]>;
    workflows$: Observable<FilterOption[]>;
    services$: Observable<FilterOption[]>;

    accountIdSubject = new BehaviorSubject<string>("");
    accountId$ = this.accountIdSubject.asObservable();

    readonly role = Role;
    readonly dateRangeOptions = getPredefinedRanges();
    readonly gaEvents = GA_EVENTS;
    readonly closedStatuses = [ThreadStatus.cancelled, ThreadStatus.closed];
    readonly statusesPrefixx = "statuses";
    readonly workflowsPrefix = "workflows";
    readonly servicesPrefix = "services";
    readonly assigneesPrefix = "assignees";
    readonly accountsPrefix = "accounts";
    readonly activeStatusFilter = ACTIVE_STATUS_OPTION;
    readonly dueDateStatuses = [
        { key: DueDateStatus.AT_RISK, value: DueDateStatus.AT_RISK, group: "Due date status" },
        { key: DueDateStatus.DELIVERED, value: DueDateStatus.DELIVERED, group: "Due date status" },
        { key: DueDateStatus.MISSED, value: DueDateStatus.MISSED, group: "Due date status" },
        { key: DueDateStatus.ON_TRACK, value: DueDateStatus.ON_TRACK, group: "Due date status" },
        { key: DueDateStatus.OVERDUE, value: DueDateStatus.OVERDUE, group: "Due date status" },
        { key: DueDateStatus.BEHIND, value: DueDateStatus.BEHIND, group: "Due date status" },
    ];
    readonly rolesFilterOptions: FilterOption[] = [
        { key: AssigneeRoles.Visory, value: AssigneeRoles.Visory },
        { key: AssigneeRoles.Expert, value: AssigneeRoles.Expert },
        { key: AssigneeRoles.Customer, value: AssigneeRoles.Customer },
    ];

    searchQuerySource$ = new Subject<string>();
    searchQuery$ = this.searchQuerySource$.asObservable();
    filtersSubscription: Subscription;
    statusSubscription: Subscription;
    delphiSortSubscription: Subscription;
    showAllWorkflowsToggle$: Observable<boolean>;
    showDelphiSortToggle$: Observable<boolean>;
    globalRole$: Observable<Role>;

    form = new FormGroup({
        type: new FormControl<FilterOption[]>(null),
        status: new FormControl<FilterOption[]>(null),
        account: new FormControl<FilterOption[]>(null),
        search: new FormControl<string>(""),
        assignees: new FormControl<FilterOption[]>(null),
        roles: new FormControl<FilterOption[]>(null),
        workflow: new FormControl<FilterOption[]>(null),
        includeAll: new FormControl<boolean>(false),
        delphiSort: new FormControl<boolean>(false),
        dateRange: new FormControl<DateRange | null>(null),
    });

    onChange?: (value: unknown) => void;
    onTouch?: () => void;

    protected readonly ALL_OPTION = ALL_OPTION;
    protected readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    private formValues$ = new BehaviorSubject<ITimelineFilters | null>(null);

    constructor(
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private threadFilterService: ThreadFilterService,
        private participantCache: ParticipantCache,
        private threadsService: ThreadsService,
        private route: ActivatedRoute,
        public loader: Loader,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { accountId } = changes;

        if (accountId && accountId?.currentValue) {
            this.accountIdSubject.next(this.accountId);
        }
    }

    ngOnInit(): void {
        this.initializeBaseObservables();
        this.initializeFilterObservables();
        this.initializeInitialFilterValues();
        this.initForm();
        this.initializeToggles();
    }

    ngOnDestroy(): void {
        this.filtersSubscription?.unsubscribe();
        this.statusSubscription?.unsubscribe();
        this.delphiSortSubscription?.unsubscribe();
    }

    writeValue(value: ITimelineFilters): void {
        this.form.setValue(value, { emitEvent: false });
    }

    registerOnChange(fn: (value: ITimelineFilters) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private initForm(): void {
        this.statusSubscription = this.form.controls.status.valueChanges.subscribe((statuses) => {
            if (!this.canEnableDelphiSort(statuses) && this.form.controls.delphiSort.value) {
                this.form.controls.delphiSort.setValue(false, { emitEvent: false });
            }
        });

        this.delphiSortSubscription = this.form.controls.delphiSort.valueChanges.subscribe((delphiSort) => {
            if (delphiSort) {
                const statuses = this.getDelphiStatuses(this.form.controls.status.value);
                this.form.controls.status.setValue(statuses, { emitEvent: false });
            }
        });

        this.filtersSubscription = this.form.valueChanges.subscribe((values) => {
            if (this.onChange) {
                this.onChange(values);
            }
        });
    }

    private initializeBaseObservables(): void {
        this.globalRole$ = this.authService.getGlobalRole().pipe(shareReplay(1));
        this.statusOptions$ = this.getStatusOptions();
        this.formValues$.next(this.form.value as ITimelineFilters);
    }

    private initializeFilterObservables(): void {
        const threadFilters$ = this.getThreadSourceFilters();
        const includeAll$ = this.form.controls.includeAll.valueChanges.pipe(startWith(this.form.value.includeAll));

        this.accounts$ = this.getAccountsFilterOptions(includeAll$);
        this.assignees$ = this.getAssigneesFilterOptions(threadFilters$, includeAll$);
        this.workflows$ = this.getWorkflowsFilterOptions(threadFilters$, includeAll$);
        this.services$ = this.getServicesFilterOptions(threadFilters$, includeAll$);
    }

    private getAccountsFilterOptions(includeAll$: Observable<boolean>): Observable<FilterOption[]> {
        return combineLatest([this.accountId$, includeAll$]).pipe(
            switchMap(([accountId, includeAll]) =>
                accountId ? of<FilterOption[]>([]) : this.getAccountsFilters(includeAll),
            ),
            shareReplay(1),
        );
    }

    private getAssigneesFilterOptions(
        threadFilters$: Observable<ThreadFilters>,
        includeAll$: Observable<boolean>,
    ): Observable<FilterOption[]> {
        return combineLatest([threadFilters$, includeAll$]).pipe(
            switchMap(([threadFilters, includeAll]) => this.getAssigneesFilters(threadFilters, includeAll)),
            shareReplay(1),
        );
    }

    private getWorkflowsFilterOptions(
        threadFilters$: Observable<ThreadFilters>,
        includeAll$: Observable<boolean>,
    ): Observable<FilterOption[]> {
        return combineLatest([threadFilters$, includeAll$]).pipe(
            switchMap(([threadFilters, includeAll]) => this.getWorkflowsFilters(threadFilters, includeAll)),
            shareReplay(1),
        );
    }

    private getServicesFilterOptions(
        threadFilters$: Observable<ThreadFilters>,
        includeAll$: Observable<boolean>,
    ): Observable<FilterOption[]> {
        return combineLatest([threadFilters$, includeAll$]).pipe(
            switchMap(([threadFilters, includeAll]) => this.getServicesFilters(threadFilters, includeAll)),
            shareReplay(1),
        );
    }

    private initializeInitialFilterValues(): void {
        const allOptions$ = [
            this.formValues$,
            this.accounts$,
            this.assignees$,
            this.workflows$,
            this.services$,
            this.statusOptions$,
            of(this.rolesFilterOptions),
        ];

        this.loader
            .wrap(combineLatest(allOptions$))
            .pipe(
                take(1),
                switchMap(([_formValues, ...filterOptions]) => this.getFiltersValues(filterOptions)),
            )
            .subscribe((filterValues) => {
                this.form.setValue(filterValues);
            });
    }

    private initializeToggles(): void {
        this.showAllWorkflowsToggle$ = combineLatest([
            this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableShowAllWorkflowsToggle),
            this.globalRole$.pipe(switchMap((role) => this.permissionService.checkPermissions(role, "ThreadReadAll"))),
        ]).pipe(map(([flagEnabled, hasPermission]) => flagEnabled && hasPermission));

        this.showDelphiSortToggle$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiSort);
    }

    private getDelphiStatuses(statuses: FilterOption[]): FilterOption[] {
        const excludeStatuses = [...this.closedStatuses, ThreadStatus.active];
        const filteredStatuses = statuses.filter((status) => !excludeStatuses.includes(status.key as ThreadStatus));
        return [this.activeStatusFilter, ...filteredStatuses];
    }

    private getFiltersValues(filterOptions: FilterOption[][]): Observable<ITimelineFilters> {
        const enableDelphiSort$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiSort);
        return combineLatest([enableDelphiSort$, this.accountId$]).pipe(
            map(([enableDelphiSort, accountId]) =>
                this.resolveTimelineFilters(filterOptions, accountId, enableDelphiSort),
            ),
        );
    }

    private resolveTimelineFilters(
        filterOptions: FilterOption[][],
        defaultAccountId: string,
        enableDelphiSort: boolean,
    ): ITimelineFilters {
        const queryParams: TimelineQueryParams = this.route.snapshot.queryParams;

        const [accountFilters, assigneeFilters, workflowFilters, typeFilters, statusOptions, roleFilters] =
            filterOptions;

        const currentValues = this.form.value;

        const { status, type, assignees, roles, workflow } = this.getResolveSelectedFilterOptions(
            {
                assigneeFilters,
                workflowFilters,
                typeFilters,
                statusOptions,
                roleFilters,
            },
            queryParams,
            currentValues,
        );

        const account = this.resolveSelectedAccountFilters(
            defaultAccountId,
            queryParams.account,
            accountFilters,
            currentValues.account,
        );

        const search = queryParams.search || currentValues.search || "";
        const includeAll = this.resolveIncludeAllValue(queryParams.includeAll, currentValues.includeAll);
        const delphiSort = this.resolveDelphiSortValue(queryParams.delphiSort, enableDelphiSort);

        const startRangeValue = queryParams.startRange ? new Date(queryParams.startRange) : null;
        const endRangeValue = queryParams.endRange ? new Date(queryParams.endRange) : null;
        const dateRange = startRangeValue && endRangeValue ? { from: startRangeValue, to: endRangeValue } : null;

        return {
            status,
            type,
            account,
            assignees,
            roles,
            workflow,
            search,
            includeAll,
            delphiSort,
            dateRange,
        };
    }

    private getResolveSelectedFilterOptions(
        filterOptions: FilterOptions,
        queryParams: TimelineQueryParams,
        currentValues: Partial<ITimelineFilters>,
    ): ResolvedFilterOptions {
        const { statusOptions, typeFilters, assigneeFilters, workflowFilters, roleFilters } = filterOptions;

        const status = this.resolveSelectedFilterOptions(queryParams.status, statusOptions, currentValues.status);
        const type = this.resolveSelectedFilterOptions(queryParams.type, typeFilters, currentValues.type);

        const assignees = this.resolveSelectedFilterOptions(
            queryParams.assignees,
            assigneeFilters,
            currentValues.assignees,
        );
        const workflow = this.resolveSelectedFilterOptions(
            queryParams.workflow,
            workflowFilters,
            currentValues.workflow,
        );

        const roles = this.resolveSelectedFilterOptions(queryParams.roles, roleFilters, currentValues.roles);

        return { status, type, assignees, roles, workflow };
    }

    private resolveSelectedFilterOptions(
        paramValue: string | string[],
        filterOptions: FilterOption[],
        currentValue: FilterOption[],
    ): FilterOption[] {
        return paramValue ? this.findFilterOptionsByKey(paramValue, filterOptions) : currentValue;
    }

    private resolveSelectedAccountFilters(
        defaultAccountId: string,
        account: string | string[],
        accountFilters: FilterOption[],
        currentAccount: FilterOption[],
    ): FilterOption[] {
        return this.findAccountFilterOptionsByKey(defaultAccountId, account, accountFilters) || currentAccount || [];
    }

    private findFilterOptionsByKey(key: string | string[], filterOptions: Array<FilterOption>): FilterOption[] {
        if (typeof key !== "string" && !Array.isArray(key)) {
            return [];
        }

        if (Array.isArray(key)) {
            return filterOptions.filter((option) => key.includes(option.key));
        }

        return filterOptions.filter((option) => option.key === key);
    }

    private findAccountFilterOptionsByKey(
        defaultAccountId: string,
        key: string | string[],
        filterOptions: Array<FilterOption>,
    ): FilterOption[] {
        if (defaultAccountId) {
            return [{ key: defaultAccountId, value: ALL_OPTION.value }];
        }
        return this.findFilterOptionsByKey(key, filterOptions);
    }

    private resolveIncludeAllValue(includeAllParam: string, currentIncludeAll: boolean): boolean {
        return includeAllParam !== undefined
            ? includeAllParam === "true"
            : this.defaultToShowAllWorkflows || currentIncludeAll || false;
    }

    private resolveDelphiSortValue(delphiSortParam: string, enableDelphiSort: boolean): boolean {
        return delphiSortParam !== undefined ? enableDelphiSort && delphiSortParam === "true" : enableDelphiSort;
    }

    private canEnableDelphiSort(status: FilterOption[]): boolean {
        return status.length > 0 && status.every((status) => !this.closedStatuses.includes(status.key as ThreadStatus));
    }

    private getAssigneesFilters(threadFilters: ThreadFilters, includeAll?: boolean): Observable<FilterOption[]> {
        return this.globalRole$.pipe(
            map((role) => this.threadFilterService.getAssigneeFilterSource(role)),
            switchMap((source) =>
                this.threadFilterService.getFilteredThreads<AssigneesFilter>(source, threadFilters, includeAll),
            ),
            switchMap((assignees) => this.getParticipantsOfAssignees(assignees)),
            map((participants) => participants.sort((a, b) => a?.profile?.name?.localeCompare(b?.profile?.name))),
            map((participants) => participants.map((participant) => this.mapParticipantToFilterOption(participant))),
        );
    }

    private getParticipantsOfAssignees(assignees: AssigneesFilter[]): Observable<IParticipant[]> {
        const uniqueAssigneeIds = new Set(assignees.map((assignee) => assignee.id));
        const assigneesIds = Array.from(uniqueAssigneeIds);
        return this.participantCache.getParticipants(assigneesIds);
    }

    private mapParticipantToFilterOption(participant): FilterOption {
        return { key: participant.id, value: participant.profile.name };
    }

    private getThreadSourceFilters(): Observable<ThreadFilters> {
        return this.accountId$.pipe(map((accountId) => (accountId ? { account: accountId } : {})));
    }

    private getAccountsFilters(includeAll?: boolean): Observable<FilterOption[]> {
        return this.threadFilterService.getFilteredThreads<AccountFilter>("account", undefined, includeAll).pipe(
            map((accounts) => accounts.sort((a, b) => a.label.localeCompare(b.label))),
            map((accounts) => accounts.map((account) => ({ key: account.id, value: account.label }))),
        );
    }

    private getWorkflowsFilters(threadFilters: ThreadFilters, includeAll?: boolean): Observable<FilterOption[]> {
        return this.threadFilterService
            .getFilteredThreads<WorkflowLabelFilter>("workflow", threadFilters, includeAll, undefined)
            .pipe(
                map((workflows) => workflows.sort((a, b) => a.label.localeCompare(b.label))),
                map((workflows) => workflows.map((workflow) => ({ key: workflow.label, value: workflow.label }))),
            );
    }

    private getServicesFilters(threadFilters: ThreadFilters, includeAll?: boolean): Observable<FilterOption[]> {
        const filteredTypes$ = this.threadFilterService.getFilteredThreads<ThreadTypeFilter>(
            "type",
            threadFilters,
            includeAll,
        );
        const threadTypes$ = this.threadsService.getThreadTypes();
        return combineLatest([filteredTypes$, threadTypes$]).pipe(
            map(([filteredTypes, threadTypes]) => this.mapThreadTypesToFilterOptions(filteredTypes, threadTypes)),
            map((workflows) => workflows.filter((workflow) => !!workflow.value)),
            map((workflows) => workflows.sort((a, b) => a.value.localeCompare(b.value))),
        );
    }

    private mapThreadTypesToFilterOptions(
        filteredTypes: ThreadTypeFilter[],
        threadTypes: Record<string, string>,
    ): FilterOption[] {
        return filteredTypes.map((type) => ({ key: type.type, value: threadTypes[type.type] }));
    }

    private getStatusOptions(): Observable<FilterOption[]> {
        const threadStatusOptions = Object.entries(this.environment.featureFlags.threadListFilterStatus)
            .map(([key, value]) => ({ key, value, group: "Workflow status" }))
            .sort((a, b) => a.value.localeCompare(b.value));

        return this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableMultiSelectWorkflowStatusFilter).pipe(
            map((flagEnabled) => {
                if (flagEnabled) {
                    return [...this.dueDateStatuses, ...threadStatusOptions];
                }
                return threadStatusOptions;
            }),
        );
    }
}
