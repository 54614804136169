import { NgModule } from "@angular/core";
import { RouterModule, ROUTES } from "@angular/router";
import { THREAD_LIBRARY } from "src/app/injection-token";
import { LaunchDarklyFeatureFlags } from "../feature-flags";
import { ILibrary, RouteExtension, RouteHelper } from "../plugins";
import { ThreadsListRouteComponent } from "./components/threads-list-route/threads-list-route.component";
import { TimelinesComponent } from "./components/timelines/timelines.component";
import { threadViewRedirectGuard } from "./services/thread-view-redirect-guard";

export const ACTIVITY_PATH = "activity";
export const OVERVIEW_PATH = "overview";

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule],
    providers: [
        {
            provide: ROUTES,
            useFactory: (library: ILibrary<RouteExtension>) => {
                const dynamicRoutes = RouteHelper.getRoutes(library);
                return [
                    { path: "", component: TimelinesComponent },
                    {
                        path: ":threadId",
                        component: ThreadsListRouteComponent,
                        children: [
                            {
                                path: "",
                                pathMatch: "full",
                                data: {
                                    featureFlag: LaunchDarklyFeatureFlags.EnableCentreAppComponent,
                                    primaryPath: OVERVIEW_PATH,
                                    fallbackPath: ACTIVITY_PATH,
                                },
                                canActivate: [threadViewRedirectGuard],
                                children: [], // route is superficial and does not load component
                            },
                            {
                                path: ACTIVITY_PATH,
                                children: [], // route is superficial and does not load component
                            },
                            {
                                path: "cards/:cardId",
                                redirectTo: `${ACTIVITY_PATH}/cards/:cardId`,
                            },
                            {
                                path: `${ACTIVITY_PATH}/cards/:cardId`,
                                children: [], // route is superficial and does not load component
                            },
                            ...dynamicRoutes,
                        ],
                    },
                ];
            },
            deps: [THREAD_LIBRARY],
            multi: true,
        },
    ],
})
export class WorkflowRoutesModule {}
