<div class="container">
    @if (brandingEnabled$ | async) {
        <delphi-logo
            [class]="size"
            label="{{ aiName }} Memories"
            [size]="size"
        ></delphi-logo>
    }

    @if (loader.counter) {
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
            class="ghost-loader"
        ></ghost-loader>
    }

    @if (contextMemories$ | async; as context) {
        <info-banner (click)="context?.memories?.length && openModal(context)">
            {{ context?.memories?.length ? context.summary : "No relevant memories" }}
        </info-banner>
    }
</div>
