import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sentencecase", pure: false })
export class SentenceCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value.length) {
            return "";
        }

        const words = value.split(" ");

        const transformedWords = words.map((word, index) => {
            if (this.isAcronym(word)) {
                return word;
            }

            return this.formatWord(word, index === 0);
        });

        return transformedWords.join(" ");
    }

    private isAcronym(word: string): boolean {
        return word === word.toUpperCase() && word.length > 1;
    }

    private formatWord(word: string, isFirstWord: boolean): string {
        if (isFirstWord) {
            return this.capitalizeFirstLetter(word);
        }
        return word.toLowerCase();
    }

    private capitalizeFirstLetter(word: string): string {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
}
