<div class="fx-paginator">
    <div class="fx-paginator-button-group">
        @if ((FEATURE_FLAGS.EnableNewPaginationComponent | featureEnabled | async) && paginationDetails?.totalItems) {
            <v-pagination
                [length]="paginationDetails.pageLength"
                [initialIndex]="paginationDetails.currentPage - 1"
                (activeIndex)="onPageChange($event)"
            ></v-pagination>
        } @else {
            @if (enableBack) {
                <button
                    class="fx-btn fx-btn--primary-outlined fx-paginator-button fx-paginator-button-left"
                    (click)="previousPage()"
                >
                    <i class="las la-angle-left"></i>
                    Previous
                </button>
            }
            @if (enableNext) {
                <button
                    class="fx-btn fx-btn--primary-outlined fx-paginator-button fx-paginator-button-right"
                    (click)="nextPage()"
                >
                    Next
                    <i class="las la-angle-right"></i>
                </button>
            }
        }
    </div>
    @if (paginationDetails?.totalItems) {
        <div class="fx-paginator-details">
            Showing {{ paginationDetails.startItem }} - {{ paginationDetails.endItem }} of
            {{ paginationDetails.totalItems }}
        </div>
    }
</div>
