import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { CreateRequestType, ITimeline } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";

type CreateRequestBody = {
    message: string;
    accountId?: string;
    requestType: CreateRequestType;
    requestCategory?: string;
};

export class RequestService {
    private environment = inject<EnvironmentSpecificConfig>(ENVIRONMENT);
    private http = inject(HttpClient);

    createRequest(
        message: string,
        requestType: CreateRequestType,
        accountId?: string,
        requestCategory?: string,
    ): Observable<ITimeline> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/request`;
        const body: CreateRequestBody = {
            message,
            accountId,
            requestType,
            requestCategory,
        };

        return this.http.post<ITimeline>(url, body);
    }

    getCustomerCategories(): string[] {
        return [
            "An existing workflow",
            "A question",
            "Providing you information",
            "Technical support",
            "An additional service",
            "Providing feedback",
            "My Visory agreement",
            "Something else",
        ];
    }

    getExpertCategories(): string[] {
        return [
            "Unable to complete workflow",
            "Pause upcoming work",
            "Waiting on internal response",
            "System/software issue",
            "Technical/process query",
            "Invoice/payment query",
            "Scope query",
            "Other",
        ];
    }
}
