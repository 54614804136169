import { Component, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ICardTaskDetail, ITimeline, Role } from "@visoryplatform/threads";
import { Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged, switchMap } from "rxjs/operators";
import { ThreadUpdateService } from "../../../../../shared/services/thread-update-service";
import { THREAD_CARD_RESOURCES } from "../../../../interfaces/IUiCard";
import { ThreadCardService } from "../../../../services/thread-card.service";
import { UiCardService } from "../../../../services/ui-card.service";
import { DASHBOARD_THREAD_TASK_DATA, ThreadsTaskComponent } from "../threads-task/threads-task.component";

type TaskChanges = {
    cardTaskDetail: ICardTaskDetail;
    threadListing: ITimeline;
    role: Role;
};

@Component({
    selector: "task-list-item",
    templateUrl: "./task-list-item.component.html",
    styleUrls: ["./task-list-item.component.scss"],
})
export class TaskListItemComponent implements OnChanges {
    @Input() cardTaskDetail: ICardTaskDetail;
    @Input() threadListing: ITimeline;
    @Input() role: Role;

    ThreadsTaskComponent = ThreadsTaskComponent;

    taskChanges = new ReplaySubject<TaskChanges>(1);
    taskInjector$: Observable<Injector>;

    constructor(
        private cardService: ThreadCardService,
        private uiCardService: UiCardService,
        private threadUpdateService: ThreadUpdateService,
        private injector: Injector,
    ) {
        this.taskInjector$ = this.taskChanges.pipe(
            distinctUntilChanged((prev, curr) => this.hasChanged(prev, curr)),
            switchMap(({ cardTaskDetail, threadListing, role }) =>
                this.getInjector(threadListing, cardTaskDetail, role),
            ),
        );
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.taskChanges.next({
            cardTaskDetail: this.cardTaskDetail,
            threadListing: this.threadListing,
            role: this.role,
        });
    }

    private hasChanged(prev: TaskChanges, curr: TaskChanges): boolean {
        if (prev.role !== curr.role) {
            return true;
        }

        if (prev.threadListing.id !== curr.threadListing.id) {
            return true;
        }

        if (prev.cardTaskDetail.taskId !== curr.cardTaskDetail.taskId) {
            return true;
        }

        return false;
    }

    private async getInjector(thread: ITimeline, task: ICardTaskDetail, role: Role): Promise<Injector> {
        const navigateToSubject = new ReplaySubject<void>(1);

        const card = await this.cardService.getCard(thread.id, task.cardId).toPromise();
        const thread$ = this.threadUpdateService.getUpdatesByThread(thread);

        const cardResources = this.uiCardService.getCardResources(thread.id, thread$, card, role, navigateToSubject);

        return Injector.create({
            providers: [
                { provide: THREAD_CARD_RESOURCES, useValue: cardResources },
                { provide: DASHBOARD_THREAD_TASK_DATA, useValue: task },
            ],
            parent: this.injector,
        });
    }
}
