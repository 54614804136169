import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { BankfileSetting, IPayRunReport, ITimeline, IntegrationTypes } from "@visoryplatform/threads";
import { ICreatePayrunListItem } from "projects/default-plugins/vault/components/request/interfaces/request.interfaces";
import { Observable, Subscription } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { IPayrunReportLineData } from "../../interfaces/IPayrunReportLineData";
import { LongRunningPayrunReport, PayrunReportDataService } from "../../services/payrun-report-data.service";

@Component({
    selector: "payrun-report-create-request",
    templateUrl: "./payrun-report-create-request.component.html",
    styleUrls: ["./payrun-report-create-request.component.scss"],
})
export class PayrunReportCreateRequestComponent implements OnChanges, OnDestroy {
    @Input() provider: IntegrationTypes;
    @Input() reportListItem: ICreatePayrunListItem;
    @Input() thread: ITimeline;
    @Input() cardDescription: FormControl<string>;
    @Input() bankFileSettings: BankfileSetting[];
    @Output() changeBankFile = new EventEmitter<BankfileSetting>();
    @Output() longRunningTaskId = new EventEmitter<string>();

    form: FormGroup;
    report$: Observable<LongRunningPayrunReport>;
    tableData$: Observable<MatTableDataSource<IPayrunReportLineData>>;
    bankFileSetting = new FormControl<BankfileSetting>(null);
    settingsSub = new Subscription();

    private readonly dataSource = new MatTableDataSource<IPayrunReportLineData>();
    private reportSubscription: Subscription;

    constructor(
        private payrunDataService: PayrunReportDataService,
        private payrunReportDataService: PayrunReportDataService,
    ) {
        this.settingsSub = this.bankFileSetting.valueChanges.pipe().subscribe((value) => {
            this.changeBankFile.emit(value);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { reportListItem, provider } = changes;

        if ((reportListItem || provider) && this.reportListItem && this.provider) {
            this.reportSubscription?.unsubscribe();
            this.report$ = this.getPayRunReport(this.reportListItem.id, this.thread?.accountId, this.provider);
            this.reportSubscription = this.report$.subscribe((longRunningReport) => {
                this.longRunningTaskId.emit(longRunningReport.task.id);
            });

            const payrunReport$ = this.report$.pipe(map((report) => report.report));
            this.tableData$ = this.getDataSource(payrunReport$);
        }

        if (changes?.bankFileSettings && this.bankFileSettings?.length === 1) {
            this.bankFileSetting.setValue(this.bankFileSettings[0]);
        }
    }

    ngOnDestroy(): void {
        this.reportSubscription?.unsubscribe();
    }

    private getPayRunReport(
        reportId: string | number,
        accountId: string,
        provider: IntegrationTypes,
    ): Observable<LongRunningPayrunReport> {
        const report$ = this.payrunDataService.getPayRunReportById(reportId, accountId, provider);
        return report$.pipe(shareReplay(1));
    }

    private getDataSource(report$: Observable<IPayRunReport>): Observable<MatTableDataSource<IPayrunReportLineData>> {
        return report$.pipe(
            map((report) => {
                const dataSource = this.dataSource;
                dataSource.data = report.lines
                    .sort((a, b) => a.employeeName.localeCompare(b.employeeName))
                    .map((reportLine) => ({
                        ...reportLine,
                        currency: this.payrunReportDataService.getCurrencyByRegion(report.region),
                        hideRowChild: true,
                    }));
                return dataSource;
            }),
        );
    }
}
