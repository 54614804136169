import { Component, OnDestroy, OnInit, signal } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Role } from "@visoryplatform/threads";
import { Observable, Subject, Subscription } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { GA_EVENTS } from "../../../analytics";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { FeatureFlagService } from "../../../feature-flags/services/feature-flags.service";
import { AuthService } from "../../../findex-auth";
import { Loader } from "../../../shared/services/loader";
import { TableMobileViewControlsService } from "../../../shared/services/table-mobile-view-controls.service";
import { ACTIVE_STATUS_OPTION, ALL_OPTION, ALL_STATUSES_LABEL } from "../../constants/option-constants";
import { ITimelineFilters } from "../../interfaces/timeline-filters";

interface IFormTimelistList {
    filters: FormControl<ITimelineFilters>;
}

const DEFAULT_TIMELINE_FILTERS: ITimelineFilters = {
    type: [],
    status: [ACTIVE_STATUS_OPTION],
    account: [],
    assignees: [],
    roles: [],
    workflow: [],
    search: "",
    includeAll: false,
    delphiSort: false,
    dateRange: null,
};

@Component({
    selector: "timelines",
    templateUrl: "./timelines.component.html",
    styleUrls: ["./timelines.component.scss"],
    providers: [TableMobileViewControlsService],
})
export class TimelinesComponent implements OnInit, OnDestroy {
    readonly initialFormValues = DEFAULT_TIMELINE_FILTERS;
    readonly defaultFilters = {
        ...DEFAULT_TIMELINE_FILTERS,
        status: [{ ...ALL_OPTION, value: ALL_STATUSES_LABEL }],
    };

    loader = new Loader();
    form = new FormGroup<IFormTimelistList>({
        filters: new FormControl<ITimelineFilters>(this.initialFormValues),
    });

    globalRole$: Observable<Role>;
    formValues$: Observable<ITimelineFilters>;
    formValuesSub: Subscription;
    activeFilterCount = signal<number>(0);

    protected readonly role = Role;
    protected readonly gaEvents = GA_EVENTS;

    private destroy$ = new Subject<void>();

    constructor(
        private authService: AuthService,
        private router: Router,
        private tableMobileViewControlsService: TableMobileViewControlsService,
        private featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit(): void {
        const user$ = this.authService.getValidUser();

        this.globalRole$ = user$.pipe(
            map((user) => user.globalRole),
            take(1),
        );

        this.formValues$ = this.form.valueChanges.pipe(map((val) => val.filters));
        this.formValuesSub = this.formValues$.pipe(takeUntil(this.destroy$)).subscribe((filterValues) => {
            this.updateQueryParams(filterValues);
        });

        this.setupMobileFilterActions();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    resetFilters(): void {
        this.featureFlagService
            .getFlag(LaunchDarklyFeatureFlags.EnableDelphiSort)
            .pipe(take(1))
            .subscribe((isEnabled) => {
                this.form.setValue({
                    filters: {
                        ...this.initialFormValues,
                        delphiSort: isEnabled,
                    },
                });
            });
    }

    private setupMobileFilterActions(): void {
        this.form.valueChanges
            .pipe(
                map((formValues) =>
                    this.tableMobileViewControlsService.getActiveFilterCount(this.defaultFilters, formValues.filters),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((count) => {
                this.activeFilterCount.set(count);
            });

        this.tableMobileViewControlsService.filterReset$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.resetFilters();
        });
    }

    private updateQueryParams(filterValues: Partial<ITimelineFilters>): void {
        const queryParams = {
            type: filterValues?.type?.map((type) => type.key) || "",
            status: filterValues?.status?.map((status) => status.key) || "",
            search: filterValues?.search || "",
            account: filterValues?.account?.map((account) => account.key) || "",
            assignees: filterValues?.assignees?.map((assignee) => assignee.key) || "",
            workflow: filterValues?.workflow?.map((workflow) => workflow.key) || "",
            roles: filterValues?.roles?.map((role) => role.key) || "",
            includeAll: filterValues?.includeAll ?? DEFAULT_TIMELINE_FILTERS.includeAll,
            delphiSort: filterValues?.delphiSort ?? DEFAULT_TIMELINE_FILTERS.delphiSort,
            startRange: this.getIsoFromDate(filterValues?.dateRange?.from),
            endRange: this.getIsoFromDate(filterValues?.dateRange?.to),
        };

        void this.router.navigate([], {
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private getIsoFromDate(date: Date): string {
        return date ? date.toISOString() : "";
    }
}
