import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { FindexUiModule } from "../../portal-modules/src/lib/findex-ui/findex-ui.module";
import { ThreadsUiModule } from "../../portal-modules/src/lib/threads-ui/threads-ui.module";
import { TimelineModule } from "../../portal-modules/src/lib/timeline/timeline.module";
import { InternalChatRouteComponent } from "./components/internal-chat-route/internal-chat-route.component";
import { InternalChatPlugin } from "./internal-chat.plugin";

@NgModule({
    exports: [],
    declarations: [InternalChatRouteComponent],
    providers: [{ provide: PLUGIN_FACTORY, useClass: InternalChatPlugin, multi: true }],
    imports: [BrowserModule, CommonModule, FindexUiModule, ThreadsUiModule, TimelineModule, PortalUiModule],
})
export class InternalChatModule {}
