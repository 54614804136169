@if (busy()) {
    <thread-overview-header-ghost-loader />
} @else {
    @if (showAssigneeBanner()) {
        <div class="assignee-banner-container">
            <v-banner type="info">
                <span>You are not assigned to this step</span>
            </v-banner>
        </div>
    }
    <div class="headline">
        <workflow-step-checkbox
            [currentStepId]="currentStep().id"
            [stepId]="currentStep().id"
            [isCompleted]="stepEntry().isCompleted"
            [index]="stepEntry().index"
        ></workflow-step-checkbox>
        <h3>{{ currentStep().label }}</h3>
    </div>
    @if (showDetails()) {
        <div class="details">
            <div class="details-assigned-to">
                <i class="las la-user"></i>
                <div class="details-assigned-to-label">Assigned to</div>
            </div>
            <v-avatars
                size="xs"
                [avatars]="avatars()"
                [max]="2"
            ></v-avatars>
            <sla-remaining
                [step]="currentStep()"
                [liveCountdown]="true"
                [accountId]="thread().accountId"
                label="SLA"
                [timezone]="this.thread()?.account?.metadata?.contactInfo?.timeZone"
            ></sla-remaining>
            @if (workTime()) {
                <duration-badge
                    [time]="workTime()"
                    hint="The expected time to complete this task"
                    [isCountdown]="false"
                    label="Should take"
                ></duration-badge>
            }
        </div>
    }

    @if (enableMemories()) {
        <banner-relevant-memories
            [threadId]="thread()?.id"
            [accountId]="thread()?.accountId"
            size="small"
        ></banner-relevant-memories>
    }
}
