import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "@visoryplatform/portal-ui";
import { CardReply, CardStatus, IThread, IThreadCard, Role, SubjectType } from "@visoryplatform/threads";
import { PreviewAction, VAULT_ACTION, VaultService } from "@visoryplatform/vault";
import { IExtension } from "@visoryplatform/workflow-core";
import { DocumentCategory } from "projects/default-plugins/vault/enums/DocumentCategory";
import { GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import {
    ToastMessage,
    ToastSeverity,
    ToastSummary,
} from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CreateReportModalComponent } from "projects/portal-modules/src/lib/threads-ui/components/create-report-modal/create-report-modal.component";
import {
    ICreateReportModalInput,
    ICreateReportModalOutput,
} from "projects/portal-modules/src/lib/threads-ui/interfaces/ICreateReportModal";
import { CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { combineLatest, EMPTY, Observable, of, Subject, Subscription } from "rxjs";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { ENVIRONMENT, EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { DocumentSignDeclarations } from "../../../../enums/DocumentSignDeclarations";
import { IReport } from "../../../../interfaces/IReport";
import { IVaultItem } from "../../../../interfaces/IVaultItem";
import { IVaultState } from "../../../../interfaces/IVaultState";
import { VaultCardDeleteItem, VaultCardRenameItem } from "../../../../types/EditCardRequests";
import {
    DocumentPreviewModalComponent,
    DocumentPreviewModalData,
} from "../../../document-preview/document-preview.component";
import { SigningModalComponent } from "../../../signing-modal/signing-modal.component";
import { VaultCardApiService } from "../../services/vault-card-api.service";
import { VaultCountService } from "../../services/vault-count.service";

@Component({
    selector: "vault-card",
    templateUrl: "./vault-card.component.html",
    styleUrls: ["./vault-card.component.scss"],
})
export class VaultCardComponent implements OnDestroy, OnInit {
    readonly gaEvents = GA_EVENTS;
    readonly gaEventsPrefix = GA_EVENTS_PREFIX;
    readonly roles = Role;
    readonly vaultActions = VAULT_ACTION;

    allowEdit = this.environment.featureFlags.editCardDescription;
    card$: Observable<IThreadCard>;
    cardStatuses = CardStatus;
    documentsRequireSignature: boolean;
    documentsUnsigned: number;
    edit$ = new Subject<boolean>();
    errorMessage: string;
    loader = new Loader();
    rebuilt: boolean;
    replies$: Observable<CardReply[]>;
    report: IReport;
    role: Role;
    selectedItem: IVaultItem;
    state$: Observable<IVaultState>;
    state: IVaultState;
    thread$: Observable<IThread>;
    userId$: Observable<string>;
    cardExtension$: Observable<IExtension>;

    private cardId: string;
    private stateSub: Subscription;
    private threadId: string;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private cardResources: CardResources<IVaultState>,
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private dialog: MatDialog,
        private vaultService: VaultService,
        private activatedRoute: ActivatedRoute,
        private cardService: ThreadCardService,
        private router: Router,
        private alertService: AlertService,
        private authService: AuthService,
        private vaultCardApiService: VaultCardApiService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        const { threadId, cardId, thread$, card$, state$, role, replies$, cardExtension$ } = this.cardResources;

        this.role = role;
        this.userId$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );

        this.thread$ = thread$;
        this.card$ = card$;
        this.threadId = threadId;
        this.cardId = cardId;
        this.replies$ = replies$;
        this.state$ = state$.pipe(filter((vaultState) => !!(vaultState && vaultState.groups)));
        this.stateSub = state$.subscribe((vaultState) => this.updateState(threadId, cardId, vaultState));
        this.cardExtension$ = cardExtension$.pipe(shareReplay(1));
    }

    ngOnDestroy(): void {
        this.stateSub?.unsubscribe();
    }

    async download(item: IVaultItem): Promise<void> {
        const filename = item.files[0].filename;
        const { vaultId, fileId } = item;
        const downloadWindow = window.open("", "_self");
        downloadWindow.location.href = await this.vaultService.getDownloadUrl(vaultId, fileId, filename).toPromise();
    }

    async downloadItem(item: IVaultItem): Promise<void> {
        this.loader.show();
        if (!item.files.length) {
            return;
        }
        await this.download(item);
        this.loader.hide();
    }

    async uploadFile(card: IThreadCard, file: File): Promise<void> {
        const firstVaultId = card.subjects.find((subject) => subject.type === SubjectType.Vault)?.id;
        if (!firstVaultId) {
            return;
        }

        this.loader.show();
        try {
            await this.vaultService.uploadFile(firstVaultId, file.name, file, DocumentCategory.Document).toPromise();
        } finally {
            this.loader.hide();
        }
    }

    async renameItem(item: IVaultItem, displayName: string): Promise<void> {
        const { vault } = environmentCommon.cardsEndpoints;

        this.loader.show();
        try {
            const data: VaultCardRenameItem = {
                vaultId: item.vaultId,
                fileId: item.fileId,
                editAction: "rename",
                displayName,
            };

            await this.cardService.editCard<VaultCardRenameItem>(this.threadId, vault, this.cardId, data).toPromise();
        } finally {
            this.loader.hide();
        }
    }

    markDocumentAsSign(item: IVaultItem): void {
        const { vaultId, fileId } = item;
        const declaration = DocumentSignDeclarations.Authorise;
        this.loader
            .wrap(this.vaultService.setSignable(vaultId, fileId, declaration))
            .pipe(
                take(1),
                switchMap(() =>
                    this.alertService.show({
                        status: ToastSeverity.Success,
                        label: ToastSummary.Success,
                        message: ToastMessage.SignatureRequested,
                    }),
                ),
            )
            .subscribe();
    }

    markDocumentAsReport(item: IVaultItem): void {
        const modalData: ICreateReportModalInput = {
            title: item.files[0]?.filename,
            fileType: item.informationType,
        };

        this.dialog
            .open<CreateReportModalComponent>(CreateReportModalComponent, {
                disableClose: true,
                closeOnNavigation: false,
                hasBackdrop: true,
                autoFocus: true,
                data: modalData,
                panelClass: ["centered-modal"],
            })
            .afterClosed()
            .pipe(
                switchMap((modalDataOutput: ICreateReportModalOutput) => {
                    if (!modalDataOutput) {
                        return EMPTY;
                    }
                    const newName$ = of(modalDataOutput.description);
                    const convertToReport$ = this.setAction(item, modalDataOutput);
                    return combineLatest([newName$, convertToReport$]);
                }),
                switchMap(([displayName]) => {
                    const { vaultId, fileId, informationType } = item;
                    const category = DocumentCategory.Report;
                    return this.vaultCardApiService.updateFile(vaultId, fileId, displayName, informationType, category);
                }),
                take(1),
            )
            .subscribe(() => {
                this.alertService.show({
                    status: ToastSeverity.Success,
                    label: ToastSummary.Success,
                    message: ToastMessage.MarkedAsReport,
                });
            });
    }

    setAction(item: IVaultItem, modalDataOutput: ICreateReportModalOutput): Observable<null> {
        const actionData = {
            data: modalDataOutput?.description,
            type: item.informationType,
            metaData: {
                reportingPeriod: modalDataOutput.reportingPeriod,
            },
        };

        const { vaultId, fileId } = item;

        return this.vaultService.setAction<PreviewAction>(vaultId, fileId, VAULT_ACTION.Preview, actionData);
    }

    async deleteItem(item: IVaultItem): Promise<void> {
        const { vault } = environmentCommon.cardsEndpoints;

        this.loader.show();
        try {
            const data: VaultCardDeleteItem = {
                vaultId: item.vaultId,
                fileId: item.fileId,
                editAction: "delete",
            };

            await this.cardService.editCard<VaultCardDeleteItem>(this.threadId, vault, this.cardId, data).toPromise();
        } finally {
            this.loader.hide();
        }
    }

    signItem(item: IVaultItem): void {
        this.selectedItem = item;
        void this.extensionDisplayService
            .open(SigningModalComponent, {
                data: {
                    vaultItem: item,
                    state$: this.state$,
                },
                height: "100vh",
                maxWidth: "100vw",
                panelClass: ["mat-dialog-no-styling", "threads-sidebar"],
            })
            .toPromise();
    }

    editCard(): void {
        this.edit$.next(true);
    }

    async cancelCard(): Promise<void> {
        this.errorMessage = null;
        this.loader.show();
        try {
            await this.cardService.cancelCard(this.threadId, this.cardId).toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    async removeMessage(): Promise<void> {
        this.errorMessage = null;
        this.loader.show();

        try {
            await this.cardService.deleteCard(this.threadId, this.cardId).toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    async save(updatedMessage: string): Promise<void> {
        this.errorMessage = null;
        this.loader.show();

        try {
            await this.cardService
                .updateCardDescription(this.threadId, this.cardId, updatedMessage, CardStatus.Edited)
                .toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    openDocumentPreview(item: IVaultItem): void {
        const isSignable = item.actions.includes(this.vaultActions.Sign);
        const isSigned = !item.signed;

        const documentPreviewRef = this.dialog.open<DocumentPreviewModalComponent, DocumentPreviewModalData>(
            DocumentPreviewModalComponent,
            {
                data: {
                    item,
                    isSigned,
                    isSignable,
                },
                maxWidth: "none",
                panelClass: ["mat-dialog-no-styling", "document-preview-modal"],
            },
        );

        const dialogRefClosed = documentPreviewRef.afterClosed();
        dialogRefClosed
            .pipe(
                switchMap((result) => {
                    if (!result) {
                        return of(null);
                    }

                    this.loader.show();

                    return this.vaultService.sign(item.vaultId, item.fileId, result);
                }),
            )
            .subscribe(() => {
                this.loader.hide();
            });
    }

    private async updateState(threadId: string, cardId: string, state: IVaultState): Promise<void> {
        const { vaultId, fileId } = this.activatedRoute.snapshot.queryParams;

        if (state) {
            const downloadCount = VaultCountService.countActions(state.groups, [
                VAULT_ACTION.Download,
                VAULT_ACTION.Sign,
            ]);
            const rfiCount = VaultCountService.countActions(state.groups, [VAULT_ACTION.Rfi]);

            for (const group of state.groups) {
                for (const item of group.items) {
                    if (cardId === cardId && vaultId === item.vaultId && fileId === item.fileId) {
                        this.signItem(item);
                        await this.router.navigateByUrl(`/timelines/${threadId}`);
                    }

                    if (group.displayName === DocumentCategory.Report) {
                        this.report = {
                            fileId: item.fileId,
                            vaultId: item.vaultId,
                        };
                    }
                }
            }

            this.state = state;

            if (state.groups[0]) {
                this.documentsRequireSignature = state.groups[0].items.some(
                    (vaultItem) => vaultItem.signed || vaultItem.actions.includes(VAULT_ACTION.Sign),
                );

                this.documentsUnsigned = state.groups[0].items.filter(
                    (vaultItem) => !vaultItem.signed && vaultItem.actions.includes(VAULT_ACTION.Sign),
                ).length;
            }

            const inconsistentRfi = rfiCount > 0 && downloadCount;
            const inconsistentShare = !rfiCount && !downloadCount;

            if ((inconsistentRfi || inconsistentShare) && !this.rebuilt) {
                //TODO: remove once race condition sorted
                this.rebuilt = true;
                await this.cardService.rebuildCardState(threadId, cardId).toPromise();
            }
        }
    }
}
