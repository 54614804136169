import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ICardTaskDetail, IThread } from "@visoryplatform/threads";
import { SystemStepId } from "@visoryplatform/workflow-core";
import { CloseThreadService } from "./close-thread-prompt.service";
export interface CloseThreadPromptParams {
    thread: IThread;
    workflowStepName: string;
}

@Component({
    selector: "app-close-thread-prompt",
    templateUrl: "./close-thread-prompt.component.html",
    styleUrls: ["./close-thread-prompt.component.scss"],
    providers: [CloseThreadService],
})
export class CloseThreadPromptComponent implements OnInit {
    threads: IThread[];
    selectedThread: IThread = null;
    loading = true;
    error: string;
    hasUpcomingMeetings: boolean;

    workflowStepName: string;
    pendingTasks: ICardTaskDetail[];

    systemStepIds = SystemStepId;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CloseThreadPromptParams,
        private dialogRef: MatDialogRef<CloseThreadPromptComponent>,
        private closeThreadService: CloseThreadService,
    ) {
        this.workflowStepName = data.workflowStepName;
    }

    async ngOnInit(): Promise<void> {
        this.pendingTasks = await this.closeThreadService.getPendingTasks(this.data.thread.id);
        if (!this.pendingTasks?.length) {
            this.hasUpcomingMeetings = await this.closeThreadService.threadHasUpcomingMeetings(this.data.thread.id);
            if (this.hasUpcomingMeetings) {
                this.threads = await this.closeThreadService.findThreadsToMigrateMeetings(this.data.thread);
            }
        }

        this.loading = false;
    }

    async copyMeetingsCards(targetThreadId: string): Promise<void> {
        this.loading = true;
        this.error = null;
        try {
            await this.closeThreadService.migrateCalendarCards(this.data.thread.id, targetThreadId);
            this.dialogRef.close(true);
        } catch (err) {
            this.error = "Sorry, something went wrong";
        } finally {
            this.loading = false;
        }
    }

    close(val: boolean): void {
        this.dialogRef.close(val);
    }
}
