import { ElementRef, Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ViewportObserverService {
    private intersection: IntersectionObserver;
    private subject = new Subject<IntersectionObserverEntry>();

    constructor() {
        if (!window["IntersectionObserver"]) {
            console.warn("Browser or IntersectionObserver polyfill does not support this browser");
            return;
        }

        const options = {
            root: null,
        };

        this.intersection = new IntersectionObserver((entries) => this.intersectionObserved(entries), options);
    }

    observe(elm: ElementRef): Observable<boolean> {
        if (!this.intersection) {
            return of();
        }

        const initialSetup = new Observable((observer) => {
            this.intersection.observe(elm.nativeElement);
            observer.next();
            return () => this.intersection.unobserve(elm.nativeElement);
        });

        //Emits if element in the viewport for at least MIN_VISIBILITY_TIME
        return initialSetup.pipe(
            switchMap(() => this.subject),
            filter((event) => event.target === elm.nativeElement),
            map((event) => event.isIntersecting),
        );
    }

    private intersectionObserved(entries: IntersectionObserverEntry[]) {
        if (window.document.visibilityState === "hidden") {
            return;
        }

        for (const entry of entries) {
            this.subject.next(entry);
        }
    }
}
