import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { AnalyticsModule, HotjarAnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "../threads-ui/threads-ui.module";
import { CheckStaffRoleDialogComponent } from "./components/check-staff-role-dialog/check-staff-role-dialog.component";
import { VerifyEmailSuccessComponent } from "./components/verify-email-success/verify-email-success.component";
import { VerifyMobileWizardComponent } from "./components/verify-mobile-wizard/verify-mobile-wizard.component";
import { OnboardingRoutingModule } from "./onboarding-routing.module";
import { OnboardingService } from "./services/onboarding.service";

@NgModule({
    imports: [
        CommonModule,
        OnboardingRoutingModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        FormsModule,
        RouterModule,
        MatDialogModule,
        FindexUiModule,
        SharedModule,
        AnalyticsModule,
        ThreadsUiModule,
    ],
    declarations: [VerifyEmailSuccessComponent, VerifyMobileWizardComponent, CheckStaffRoleDialogComponent],
    providers: [OnboardingService, HotjarAnalyticsService],
})
export class OnboardingModule {}
