import { Pipe, PipeTransform } from "@angular/core";
import { RRuleDateService } from "@visoryplatform/threads";
import { RRule } from "rrule";

@Pipe({
    name: "rruleFrequency",
})
export class RruleFrequencyPipe implements PipeTransform {
    transform(rruleString: string): string {
        if (!rruleString?.length) {
            return "";
        }
        const options = RRule.parseString(rruleString);
        return RRuleDateService.findRRuleFrequency(options)?.key || "";
    }
}
