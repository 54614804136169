import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { FormGroup, FormRecord } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Invoice } from "@visoryplatform/copilot";
import { BillApprovalState, BillApprovalUpdatedItem, Role } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { WindowListenersService } from "projects/portal-modules/src/lib/shared/services/window-listeners.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable, Subscription } from "rxjs";
import { debounceTime, map, pairwise, startWith, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { BillApprovalForm, BillApprovalFormItem, BillApprovalFormValues } from "../../interfaces/BillApproval";

@Component({
    selector: "bill-approval-list",
    templateUrl: "./bill-approval-list.component.html",
    styleUrls: ["./bill-approval-list.component.scss"],
})
export class BillApprovalListComponent implements OnChanges, OnDestroy {
    @Input() invoices: Invoice[];
    @Input() state: BillApprovalState;
    @Input() allowUpdate = false;
    @Input() allowEdit = false;
    @Input() form = new FormGroup<BillApprovalForm>({
        invoiceItems: new FormRecord<FormGroup<BillApprovalFormItem>>({}),
    });

    @Output() updateTable = new EventEmitter<Invoice[]>();
    @Output() updateInvoiceItem = new EventEmitter<BillApprovalUpdatedItem>();

    tableData = new MatTableDataSource<Invoice>();
    isMobileView: boolean;
    quillError: boolean;
    messageSubscription: Subscription;
    appName = this.environment.appName;
    globalRole$: Observable<Role>;

    canEditInternalComments$: Observable<boolean>;
    canEditExternalComments$: Observable<boolean>;

    readonly messageSizeQuotaInKB = 128;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    constructor(
        windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private authService: AuthService,
        private permissionService: PermissionService,
    ) {
        this.appName = this.environment.appName;
        this.isMobileView = windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );

        this.globalRole$ = this.authService.getGlobalRole();

        this.canEditInternalComments$ = this.globalRole$.pipe(
            switchMap((role) => this.permissionService.checkPermissions(role, "EditBillInternalComment")),
        );
        this.canEditExternalComments$ = this.globalRole$.pipe(
            switchMap((role) => this.permissionService.checkPermissions(role, "EditBillExternalComment")),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { invoices, form } = changes;

        if (invoices && this.invoices) {
            this.updateTableData(this.invoices);
        }

        if (form && this.form) {
            this.subscribeToMessageUpdates();
        }
    }

    ngOnDestroy(): void {
        this.messageSubscription?.unsubscribe();
    }

    removeInvoice(invoices: Invoice[], invoiceToRemove: Invoice): void {
        if (this.form?.controls.invoiceItems.controls[invoiceToRemove.invoiceID]?.value) {
            const controls = this.getFormControl(invoiceToRemove);
            const values = controls?.value;

            if (values.approved || values.declined) {
                return;
            }
        }

        const updatedInvoices = invoices.filter((invoice) => invoice !== invoiceToRemove);
        this.updateTableData(updatedInvoices);
    }

    updateValue(requestTableGroup: FormGroup<BillApprovalFormItem>, $event: boolean, approve: boolean): void {
        const { fileId, externalComment, internalComment } = requestTableGroup.value;

        if (approve) {
            requestTableGroup.patchValue({ approved: $event, declined: false });
            this.updateInvoiceItem.emit({
                approved: $event,
                declined: false,
                fileId,
                externalComment,
                internalComment,
            });
        } else {
            requestTableGroup.patchValue({ approved: false, declined: $event });
            this.updateInvoiceItem.emit({
                approved: false,
                declined: $event,
                fileId,
                externalComment,
                internalComment,
            });
        }
    }

    private subscribeToMessageUpdates(): void {
        const messageUpdates$ = this.form.controls.invoiceItems.valueChanges.pipe(
            startWith(this.form.controls.invoiceItems.value),
            debounceTime(400),
            startWith(this.form.controls.invoiceItems.value),
            pairwise(),
            map(([prev, next]) => this.reduceInvoiceChanges(prev, next)),
        );

        this.messageSubscription?.unsubscribe();
        this.messageSubscription = messageUpdates$.subscribe((invoiceItems) => {
            Object.values(invoiceItems).forEach((bill) => {
                this.updateInvoiceItem.emit({
                    fileId: bill.fileId,
                    externalComment: bill.externalComment,
                    internalComment: bill.internalComment,
                    approved: bill.approved,
                    declined: bill.declined,
                });
            });
        });
    }

    private reduceInvoiceChanges(
        prev: Record<string, Partial<BillApprovalFormValues>>,
        curr: Record<string, Partial<BillApprovalFormValues>>,
    ): Record<string, Partial<BillApprovalFormValues>> {
        return Object.entries(curr).reduce((acc, [id, bill]) => {
            if (
                bill?.externalComment !== prev[id]?.externalComment ||
                bill?.internalComment !== prev[id]?.internalComment
            ) {
                acc[id] = bill;
            }
            return acc;
        }, {});
    }

    private getFormControl(invoiceToRemove: Invoice): FormGroup<BillApprovalFormItem> {
        return this.form.controls?.invoiceItems?.controls[invoiceToRemove.invoiceID];
    }

    private updateTableData(invoices: Invoice[]): void {
        this.tableData.data = invoices;
        this.updateTable.emit(invoices);
    }
}
