import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { AnalyticsModule } from "projects/portal-modules/src/lib/analytics";
import { CreateRequestModule } from "projects/portal-modules/src/lib/create-request/create-request.module";
import { FindexAuthModule } from "projects/portal-modules/src/lib/findex-auth";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { FxTableModule } from "projects/portal-modules/src/lib/fx-table/fx-table.module";
import { PluginsModule } from "projects/portal-modules/src/lib/plugins";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { TimelineModule } from "projects/portal-modules/src/lib/timeline/timeline.module";
import { WorkflowStatusModule } from "projects/portal-modules/src/lib/workflow-status/workflow-status.module";
import { SharedPipesModule } from "../../portal-modules/src/lib/shared/pipes/shared-pipes.module";
import { ThreadTasksModule } from "../../portal-modules/src/lib/threads-ui/modules/thread-tasks/thread-tasks.module";
import { ThreadsUiPipesModule } from "../../portal-modules/src/lib/threads-ui/pipes/threads-ui-pipes.module";
import { DashboardHeaderComponent } from "./components/dashboard-header/dashboard-header.component";
import { DashboardTimelinesPaginatedComponent } from "./components/dashboard-timelines-paginated/dashboard-timelines-paginated.component";
import { DashboardTimelinesTableComponent } from "./components/dashboard-timelines-table/dashboard-timelines-table.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { UpcomingMeetingsComponent } from "./components/upcoming-meetings/upcoming-meetings.component";

@NgModule({
    declarations: [
        DashboardComponent,
        UpcomingMeetingsComponent,
        DashboardTimelinesTableComponent,
        DashboardTimelinesPaginatedComponent,
        DashboardHeaderComponent,
    ],
    imports: [
        FindexUiModule,
        ThreadsUiModule,
        CommonModule,
        FormsModule,
        FindexAuthModule,
        RouterModule,
        FxUiModule,
        SharedModule,
        AnalyticsModule,
        FxTableModule,
        PluginsModule,
        WorkflowStatusModule,
        SharedPipesModule,
        ThreadsUiPipesModule,
        ThreadTasksModule,
        PortalUiModule,
        TimelineModule,
        CreateRequestModule,
    ],
})
export class DashboardModule {}
