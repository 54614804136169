import { Pipe, PipeTransform } from "@angular/core";
import { ISla } from "@visoryplatform/threads";

@Pipe({ name: "stepSlaFormatter" })
export class StepSlaFormatterPipe implements PipeTransform {
    transform(sla: ISla): string {
        return this.getSlaLabel(sla);
    }

    private getSlaLabel(sla: ISla): string {
        let slaLabel = "";

        if (sla.slaDays) {
            const days = this.getPlural(sla.slaDays, "day");
            slaLabel += days;
        }

        if (sla.slaDays && sla.slaHours) {
            slaLabel += ", ";
        }

        if (sla.slaHours) {
            const hours = this.getPlural(sla.slaHours, "hour");
            slaLabel += hours;
        }

        return slaLabel;
    }

    private getPlural(value: number, dateUnit: string): string {
        return value === 1 ? `${value} ${dateUnit}` : `${value} ${dateUnit}s`;
    }
}
