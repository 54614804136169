import { inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IPaginated } from "@visoryplatform/datastore-types";
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, of } from "rxjs";
import { debounceTime, filter, map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { IPaginationDetails } from "../interfaces/IPaginationDetails";
import { IPaginatorSort } from "../interfaces/IPaginatorSort";

type RequestWrapper<T> = (page: string, limit: number, sort: IPaginatorSort) => Observable<IPaginated<T>>;

const DEFAULT_SORT: IPaginatorSort = { sort: null, order: null };

export class Paginator<T> {
    previousPages$: Observable<string[]>;
    currentPage$: Observable<string>;
    nextPage$: Observable<string>;
    sort$: Observable<IPaginatorSort>;

    canGoBack$: Observable<boolean>;
    canGoNext$: Observable<boolean>;
    paginationDetails$: Observable<IPaginationDetails>;

    private readonly defaultCurrentPage = "";
    private readonly defaultPreviousPages: string[] = [];

    private requestSource: Subject<RequestWrapper<T>>;
    private currentPageSource: BehaviorSubject<string>;
    private orderSource: BehaviorSubject<IPaginatorSort>;
    private previousPagesSource: BehaviorSubject<string[]>;

    private refreshCount = 0;

    constructor(
        private limit: number,
        private saveToQueryParams: boolean = true,
        private router?: Router,
        private route?: ActivatedRoute,
    ) {
        if ((!this.router || !this.route) && this.saveToQueryParams) {
            this.router = inject(Router);
            this.route = inject(ActivatedRoute);
        }
        this.startPaginator();
        this.syncWithQueryParams();
        this.updateQueryParams();
    }

    goBack(): void {
        const pages = this.previousPagesSource.value;
        const previousPages = pages.slice(0, pages.length - 1);
        const currentPage = previousPages[previousPages.length - 1];
        this.previousPagesSource.next(previousPages);
        this.currentPageSource.next(currentPage === undefined ? this.defaultCurrentPage : currentPage);
        this.updateQueryParams();
    }

    goNext(): void {
        const nextPage$ = this.nextPage$.pipe(take(1));
        const previousPage$ = this.previousPages$.pipe(take(1));

        combineLatest([nextPage$, previousPage$]).subscribe(([nextPage, previousPages]) => {
            this.previousPagesSource.next([...previousPages, nextPage]);
            this.currentPageSource.next(nextPage);
            this.updateQueryParams();
        });
    }

    goTo(page: number): void {
        this.currentPageSource.next(page.toString());
        this.updateQueryParams();
    }

    wrap(request?: RequestWrapper<T>): Observable<T[]> {
        const currentPage$ = this.currentPageSource.asObservable();
        const order$ = this.orderSource.asObservable();
        const request$ = this.requestSource.asObservable().pipe(startWith(request));

        const response$ = combineLatest([currentPage$, order$, request$]).pipe(
            debounceTime(0),
            filter(([, , request]) => !!request),
            switchMap(([page, sort, request]) => this.request(request, page, this.limit, sort)),
            map((paginated) => ({ ...paginated, next: paginated.next ?? null })),
            shareReplay(1),
        );

        this.setNextPage(response$);
        this.setPaginationDetails(response$);
        return response$.pipe(map((paginated) => paginated.result));
    }

    refresh(request: RequestWrapper<T>): void {
        // Refresh is called on first load and whenever filters/sorting is changed
        if (this.refreshCount > 0) {
            this.currentPageSource.next(this.defaultCurrentPage);
            this.previousPagesSource.next(this.defaultPreviousPages);
        }

        this.requestSource.next(request);
        this.updateQueryParams();
        this.refreshCount++;
    }

    sort(paginatorSort: IPaginatorSort): void {
        const order = paginatorSort.order !== "" ? paginatorSort : DEFAULT_SORT;
        if (this.refreshCount > 0) {
            this.currentPageSource.next(this.defaultCurrentPage);
            this.previousPagesSource.next(this.defaultPreviousPages);
        }
        this.orderSource.next(order);
    }

    private request(
        requestWrapper: RequestWrapper<T>,
        page: string,
        limit: number,
        sort: IPaginatorSort,
    ): Observable<IPaginated<T>> {
        if (this.isPageEmpty(page)) {
            return of({ result: [], next: null });
        }

        return requestWrapper(page, limit, sort);
    }

    private startPaginator(): void {
        this.currentPageSource = new BehaviorSubject(this.defaultCurrentPage);
        this.orderSource = new BehaviorSubject(DEFAULT_SORT);
        this.previousPagesSource = new BehaviorSubject<string[]>(this.defaultPreviousPages);
        this.requestSource = new ReplaySubject(1);

        this.previousPages$ = this.previousPagesSource.asObservable();
        this.currentPage$ = this.currentPageSource.asObservable();
        this.sort$ = this.orderSource.asObservable();

        this.canGoBack$ = this.previousPages$.pipe(map((pages) => pages.length > 0));
    }

    private setNextPage(response$: Observable<IPaginated<T>>): void {
        this.nextPage$ = response$.pipe(
            map((paginated) => paginated.next),
            shareReplay(1),
        );

        this.canGoNext$ = this.nextPage$.pipe(map((nextPage) => !this.isPageEmpty(nextPage)));
    }

    private setPaginationDetails(response$: Observable<IPaginated<T>>): void {
        this.paginationDetails$ = response$.pipe(
            map((paginated) => {
                const totalItems = this.calculateTotalItems(paginated);
                const pageNumber = this.calculatePageNumber(totalItems);
                const currentPage = this.calculateCurrentPage(paginated, pageNumber);
                const startItem = this.calculateStartItem(currentPage);
                const endItem = this.calculateEndItem(currentPage, totalItems);

                return { totalItems, startItem, endItem, currentPage, pageLength: pageNumber };
            }),
            shareReplay(1),
        );
    }

    private calculateTotalItems(paginated: IPaginated<T>): number {
        return paginated.total || paginated.result.length;
    }

    private calculatePageNumber(totalItems: number): number {
        return this.limit > 0 ? Math.ceil(totalItems / this.limit) : 1;
    }

    private calculateCurrentPage(paginated: IPaginated<T>, pageNumber: number): number {
        return paginated.next ? Number(paginated.next) : pageNumber;
    }

    private calculateStartItem(currentPage: number): number {
        if (currentPage <= 0) {
            return 0;
        }
        return (currentPage - 1) * this.limit + 1;
    }

    private calculateEndItem(currentPage: number, totalItems: number): number {
        return this.limit > 0 ? Math.min(currentPage * this.limit, totalItems) : totalItems;
    }

    private isPageEmpty(page: string | null): boolean {
        return page == null;
    }

    private syncWithQueryParams(): void {
        if (!this.saveToQueryParams) {
            return;
        }

        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            const pageParam = params["page"];
            if (!pageParam) {
                return;
            }

            // Query params are 1-indexed
            const page = Number(pageParam) - 1;

            if (!isNaN(page) && page >= 0) {
                this.currentPageSource.next(page.toString());

                // Create an array of numbers from 0 to page for legacy "previous/next" navigation
                const previousPages = Array.from({ length: page }, (_, i) => (i + 1).toString());
                this.previousPagesSource.next(previousPages);
            } else {
                this.currentPageSource.next(this.defaultCurrentPage);
            }
        });
    }

    private updateQueryParams(): void {
        if (!this.saveToQueryParams) {
            return;
        }

        // Query params are 1-indexed
        const currentPage = Number(this.currentPageSource.value) + 1;

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: currentPage.toString() },
            queryParamsHandling: "merge",
        });
    }
}
