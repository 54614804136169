import { Component, computed, input } from "@angular/core";
import { IStep, SlaAdjustmentsOutput, SlaExtensionHelpers } from "@visoryplatform/workflow-core";
import { DateTime } from "luxon";

@Component({
    selector: "workflow-step-entry-debug",
    templateUrl: "./workflow-step-entry-debug.component.html",
    standalone: true,
})
export class WorkflowStepEntryDebugComponent {
    step = input.required<IStep>();

    slaAdjustmentOutput = computed(() => this.getLastSlaAdjustment(this.step()));
    slaDuration = computed(() => this.getStepSlaDuration(this.step()));

    private getLastSlaAdjustment(step: IStep): SlaAdjustmentsOutput | null {
        const slaAdjustmentOutput = SlaExtensionHelpers.getSlaAdjustmentsOutput(step.extensions);

        const lastAdjustment = slaAdjustmentOutput?.data[slaAdjustmentOutput.data.length - 1];
        if (!lastAdjustment) {
            return null;
        }

        return {
            adjustedDate: DateTime.fromISO(lastAdjustment.adjustedDate).toString(), // User's timezone (not Accounts timezone)
            duration: lastAdjustment.duration,
        };
    }

    private getStepSlaDuration(step: IStep): string | null {
        const slaExtension = SlaExtensionHelpers.getSlaExtension(step.extensions);
        const slaDuration = slaExtension?.inputs.find(SlaExtensionHelpers.isSlaInput);
        return slaDuration?.data.duration.toString() ?? null;
    }
}
