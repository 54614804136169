import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
    CardReply,
    CardStatus,
    ICardSubject,
    IThread,
    IThreadCard,
    PaymentAction,
    Role,
    SubjectType,
} from "@visoryplatform/threads";
import { AnalyticsService, GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth/services/auth.service";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { ActionableCardComponent } from "projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card.component";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { combineLatest, Observable, Subject, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ENVIRONMENT, TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { IPackagePriceDetails } from "../../interfaces/IPackagePriceDetails";
import { IPaymentCardState } from "../../interfaces/IPaymentCardState";
import { buildPackagePriceDetails } from "../../payment-task-actions";

@Component({
    selector: "payment-card",
    templateUrl: "./payment-card.component.html",
    styleUrls: ["./payment-card.component.scss"],
})
export class PaymentCardComponent extends ActionableCardComponent<void> implements OnInit, OnDestroy {
    readonly gaEvents = GA_EVENTS;
    readonly roles = Role;
    readonly allowEdit = this.environment.featureFlags.editCardDescription;
    card$: Observable<IThreadCard>;
    userId$: Observable<string>;
    thread$: Observable<IThread>;
    replies$: Observable<CardReply[]>;
    state$: Observable<IPaymentCardState>;
    isCancelable$: Observable<boolean>;

    edit$ = new Subject<boolean>();

    message: string;
    role: Role;
    errorMessage: string;
    loading: boolean;
    packagePriceDetails$: Observable<IPackagePriceDetails>;
    cardStatuses = CardStatus;
    cardTitle$: Observable<string>;

    navigationSub: Subscription;

    private threadId: string;
    private cardId: string;
    private paymentSubject$: Observable<ICardSubject>;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(THREAD_CARD_RESOURCES) protected cardResources: CardResources,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<void>>,
        private authService: AuthService,
        private cardService: ThreadCardService,
        private analytics: AnalyticsService,
        protected taskActionService: TaskActionService,
    ) {
        super(cardResources, taskActionService);
    }

    async ngOnInit(): Promise<void> {
        const { threadId, cardId, card$, replies$, role, state$, thread$ } = this.cardResources;

        this.role = role;
        this.threadId = threadId;
        this.cardId = cardId;

        this.thread$ = thread$;
        this.card$ = card$;
        this.state$ = state$;

        this.paymentSubject$ = card$.pipe(map((card) => this.getPaymentSubject(card)));
        this.cardTitle$ = this.paymentSubject$.pipe(map((subject) => this.buildCardTitle(subject)));

        this.packagePriceDetails$ = buildPackagePriceDetails(state$);
        this.replies$ = replies$;

        this.isCancelable$ = combineLatest([state$, card$]).pipe(
            map(
                ([state, card]) =>
                    !state?.isPaid && !state?.paymentMethodId && card.status !== this.cardStatuses.Disabled,
            ),
        );

        this.userId$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );

        if (!this.navigationSub) {
            this.navigationSub = this.cardResources.navigateTo$.subscribe(() => this.openPaymentCardModal());
        }
    }

    ngOnDestroy(): void {
        this.navigationSub?.unsubscribe();
    }

    editMessage(): void {
        this.edit$.next(true);
    }

    async removeMessage(): Promise<void> {
        this.errorMessage = null;
        this.loading = true;
        try {
            await this.cardService.removeCard(this.threadId, this.cardId).toPromise();

            this.loading = false;
        } catch {
            this.loading = false;
            this.errorMessage = "Sorry, something went wrong";
        }
    }

    async cancelCard(): Promise<void> {
        this.analytics.recordEvent("mouse-click", this.gaEvents.PAYMENTCARD_CANCEL);
        this.errorMessage = null;
        this.loading = true;
        try {
            await this.cardService.cancelCard(this.threadId, this.cardId).toPromise();
            this.loading = false;
        } catch {
            this.loading = false;
            this.errorMessage = "Sorry, something went wrong";
        }
    }

    async save(updatedMessage: string): Promise<void> {
        this.errorMessage = null;
        this.loading = true;
        try {
            await this.cardService
                .updateCardDescription(this.threadId, this.cardId, updatedMessage, CardStatus.Edited)
                .toPromise();

            this.loading = false;
        } catch {
            this.loading = false;
            this.errorMessage = "Sorry, something went wrong";
        }
    }

    private buildCardTitle(paymentSubject: ICardSubject): string {
        switch (paymentSubject?.type) {
            case SubjectType.Payment:
                return "Payment";
            case SubjectType.Subscription:
                return "Subscription";
            default:
                return "Payment";
        }
    }

    private getPaymentSubject(card: IThreadCard): ICardSubject {
        // look for subscription/payment first, then look for schedule afterwards, in case a release event has occurred
        const paymentOrSubscription = card.subjects.find(
            (subject) => subject.type === SubjectType.Payment || subject.type === SubjectType.Subscription,
        );
        if (paymentOrSubscription) {
            return paymentOrSubscription;
        }

        return card.subjects.find((subject) => subject.type === SubjectType.SubscriptionSchedule);
    }

    private async openPaymentCardModal(): Promise<void> {
        await this.taskActionService.action(PaymentAction.PAYMENT_REQUIRED, this.cardResources);
    }
}
