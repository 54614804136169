import { Pipe, PipeTransform } from "@angular/core";
import { VariationsControlContent, WorkflowVariationTypes } from "@visoryplatform/threads";
import { IWorkflowVariation } from "@visoryplatform/workflow-core";

import { workflowVariations } from "@visoryplatform/threads";
import { RolesControl } from "../../account/components/workflow-configuration-roles/workflow-configuration-roles.component";
@Pipe({ name: "workflowVariationTokenToLabel" })
export class WorkflowVariationTokenToLabelPipe implements PipeTransform {
    transform(configTokenKey: RolesControl): string {
        const configTokensArray = Object.entries(workflowVariations);

        return configTokensArray.reduce(
            (configTokenLabel, [, variations]) =>
                this.getConfigTokenLabel(
                    configTokenLabel,
                    configTokenKey.controlToken,
                    configTokenKey.variationType as WorkflowVariationTypes,
                    variations,
                ),
            "",
        );
    }

    private getConfigTokenLabel(
        configTokenLabel: string,
        configTokenKey: string,
        variationType: WorkflowVariationTypes,
        variations: VariationsControlContent[],
    ): string {
        const configTokens = this.findVariationConfigToken(variations, configTokenKey, variationType);
        if (!configTokens.length) {
            return configTokenLabel;
        }

        const configToken = configTokens.find((token) => token.label);
        if (!configToken?.label) {
            console.warn(`No label found for config token: ${configToken}, ensure config token is setup`);
            return configTokenLabel;
        }

        return configToken.label;
    }

    private findVariationConfigToken(
        variations: VariationsControlContent[],
        configTokenKey: string,
        variationType: WorkflowVariationTypes,
    ): IWorkflowVariation[] {
        return variations
            .map((variation) =>
                variation.tokens.find((token) => token.configTokens[configTokenKey] && token.type === variationType),
            )
            .filter((token) => !!token);
    }
}
