import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IVisualEffect } from "@visoryplatform/video-chat-sdk/types/vc-visual-effect";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "virtual-background-selector",
    templateUrl: "./virtual-background.component.html",
    styleUrls: ["./virtual-background-selector.component.scss"],
})
export class VirtualBackgroundSelectorComponent implements OnInit {
    backgroundNone: IVisualEffect = { name: "none", type: "none" };
    backgroundBlur: IVisualEffect = { name: "blur", type: "blur" };
    presetBackgrounds: IVisualEffect[] = [];

    base: IVisualEffect[] = [];
    selectedBackground = this.backgroundNone;
    constructor(
        public matDialogRef: MatDialogRef<VirtualBackgroundSelectorComponent>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(MAT_DIALOG_DATA) private state: { background?: IVisualEffect },
    ) {}

    ngOnInit(): void {
        this.selectedBackground = this.state?.background || this.backgroundNone;
        if (this.environment.virtualBackgroundPresets) {
            const presets: IVisualEffect[] = Object.keys(this.environment.virtualBackgroundPresets).map((key) => {
                return { src: this.environment.virtualBackgroundPresets[key].src, name: key, type: "image" };
            });
            this.presetBackgrounds = [...this.presetBackgrounds, ...presets];
        }
    }

    selectBackground(background: any) {
        this.selectedBackground = background;
    }

    isActive(background: IVisualEffect) {
        return background.name === this.selectedBackground.name;
    }

    applyEffect() {
        this.matDialogRef.close(this.selectedBackground);
    }
}
