import { AsyncPipe, NgClass } from "@angular/common";
import { Component, computed, Inject, input, output } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { ITimeline, Role } from "@visoryplatform/threads";
import { IWorkflowAction, SYSTEM_STEP_IDS, SystemStepId } from "@visoryplatform/workflow-core";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../../environment/environment.common";
import { FindexUiModule } from "../../../../findex-ui/findex-ui.module";
import { WindowListenersService } from "../../../../shared/services/window-listeners.service";
import { FilterVisibleActionsPipe } from "../../../../workflow-status/pipes/filter-visible-actions.pipe";
import { WorkflowStatusModule } from "../../../../workflow-status/workflow-status.module";

@Component({
    selector: "thread-overview-actions",
    templateUrl: "./thread-overview-actions.component.html",
    styleUrls: ["./thread-overview-actions.component.scss"],
    imports: [NgClass, WorkflowStatusModule, AsyncPipe, FilterVisibleActionsPipe, FindexUiModule, PortalUiModule],
    standalone: true,
})
export class ThreadOverviewActionsComponent {
    thread = input.required<ITimeline>();
    role = input.required<Role>();
    busy = input<boolean>(false);
    visibleActions = input.required<IWorkflowAction[]>();

    performAction = output<IWorkflowAction["id"]>();

    readonly SystemStepId = SystemStepId;

    readonly workflow = computed(() => this.thread()?.workflow);
    readonly currentStepId = computed(() => this.workflow()?.currentStepId);
    readonly currentStep = computed(() => this.workflow()?.steps[this.currentStepId()]);
    readonly showStepMenu = computed(() => !(SYSTEM_STEP_IDS as string[]).includes(this.currentStepId()));
    readonly reversedVisibleActions = computed(() => this.visibleActions().slice().reverse());
    readonly isMobileView = computed(() => {
        this.resize();
        return this.checkMobileView();
    });
    private readonly resize = toSignal(this.windowListenersService.resize, { initialValue: undefined });

    constructor(
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    selectStepAt(action: IWorkflowAction): void {
        this.performAction.emit(action.id);
    }

    private checkMobileView(): boolean {
        return this.windowListenersService.isWindowSmaller(this.environment.featureFlags.windowWidthTabletBreakpoint);
    }
}
