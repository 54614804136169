import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AlertService } from "@visoryplatform/portal-ui";
import {
    Account,
    CardReference,
    CardReply,
    ConfigTokenHelpers,
    ConfigurationOption,
    IParticipant,
    IThread,
    IThreadCard,
    IThreadListing,
    ITimeline,
    IWorkflowConfiguration,
    IWorkflowDesignType,
    Role,
    TitleTemplateService,
    WorkflowExtensionService,
    workflowVariations,
} from "@visoryplatform/threads";
import {
    IStep,
    IWorkflowDesign,
    IWorkflowInputs,
    IWorkflowVariation,
    ResolvedWorkflow,
    SLA_EXTENSION_TYPE,
    WorkflowResolverService,
} from "@visoryplatform/workflow-core";
import { DateTime } from "luxon";
import { WorkflowConfigurationService } from "projects/portal-modules/src/lib/account/services/workflow-configuration.service";
import { ToastSeverity, ToastSummary } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from "rxjs";
import { catchError, filter, map, shareReplay, startWith, switchMap, take, withLatestFrom } from "rxjs/operators";
import { WorkflowVariationsService } from "../../../../../workflow-variations/services/workflow-variations.service";
import { IParticipantUi } from "../../../../interfaces/IParticipantUi";
import { ParticipantService } from "../../../../services/participant.service";
import { ThreadsService } from "../../../../services/threads.service";
import { WorkflowService } from "../../../../services/workflow/workflow.service";
import { SelectDesignControl, SelectServiceControl, SelectWorkflowTokenControl } from "../../types/SelectDesignType";

export interface CreateWorkflowModalData {
    role?: Role;
    defaultParticipants?: IParticipant[];
    defaultThreadType?: string;
    defaultWorkflowDesignId?: string;
    account: Account;
    defaultThread?: IThreadListing | ITimeline;
    referenceFrom: CardReference;
    referenceCard: IThreadCard;
    referenceReply: CardReply;
}

@Component({
    selector: "create-thread-modal",
    templateUrl: "./create-thread-modal.component.html",
    styleUrls: ["./create-thread-modal.component.scss"],
    providers: [{ provide: Loader, useValue: new Loader() }],
})
export class CreateThreadModalComponent implements OnInit, OnDestroy {
    account?: Account;
    participants$: Observable<IParticipantUi[]>;
    defaultThread: ITimeline | IThreadListing;
    defaultParticipants: IParticipant[];
    defaultWorkflow?: ITimeline;
    dueDates?: Record<string, DateTime>;
    assignees?: Record<string, string[]>;
    selectedHasSlas?: boolean;
    threadType?: string;
    role?: Role;
    workflowConfigurationId?: string;
    workflowDesignId?: string;
    isFormValid: boolean;
    isWorkflowValid = true;
    isFormCompleted = false;

    selectedServiceControl: SelectServiceControl;
    selectedDesignControl: SelectDesignControl;

    workflowDesignTypes$: Observable<IWorkflowDesignType[]>;
    workflowConfiguration$: Observable<IWorkflowConfiguration>;

    customTitle$: BehaviorSubject<string> = new BehaviorSubject("");
    previewTitle$: BehaviorSubject<string> = new BehaviorSubject("");
    selectedDesignSubject: BehaviorSubject<IWorkflowDesign> = new BehaviorSubject(null);
    selectedDesignType$: BehaviorSubject<IWorkflowDesignType> = new BehaviorSubject(null);
    selectWorkflowTokenControl$: BehaviorSubject<SelectWorkflowTokenControl> = new BehaviorSubject(null);
    workflowDesignSubject: BehaviorSubject<IWorkflowDesign> = new BehaviorSubject(null);

    formSub: Subscription;
    configTokenSub: Subscription;

    referenceCard: IThreadCard;
    referenceReply: CardReply;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CreateWorkflowModalData,
        public loader: Loader,
        private dialogRef: MatDialogRef<CreateThreadModalComponent>,
        private workflowService: WorkflowService,
        private threadsService: ThreadsService,
        private participantService: ParticipantService,
        private workflowConfigurationService: WorkflowConfigurationService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private workflowVariationsService: WorkflowVariationsService,
        private alertService: AlertService,
    ) {
        this.account = this.data.account;
        this.defaultThread = this.data.defaultThread;
        this.role = this.data.role;
        this.defaultParticipants = this.data.defaultParticipants;
        this.referenceCard = this.data.referenceCard;
        this.referenceReply = this.data.referenceReply;

        const designTypes$ = this.workflowService
            .listDesignTypes()
            .pipe(map((designTypes) => designTypes?.filter((designType) => this.filterDesignType(designType))));

        this.workflowDesignTypes$ = this.loader.wrap(designTypes$);

        if (this.defaultThread?.workflowConfigurationId) {
            this.workflowConfiguration$ = this.getWorkflowConfiguration(this.defaultThread.workflowConfigurationId);
            this.setParticipantUpdates(
                this.workflowConfiguration$,
                this.assignees,
                this.defaultThread.participants,
                this.defaultParticipants,
            );
            this.setIsWorkflowValid(this.workflowConfiguration$);
        }
    }

    ngOnInit(): void {
        this.formSub = this.subscribeToFormChanges();
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe();
        this.configTokenSub?.unsubscribe();
    }

    setCustomTitle(
        selectedDesign: IWorkflowDesign,
        selectedDesignType: IWorkflowDesignType,
        customTitle?: string,
    ): void {
        this.customTitle$.next(customTitle);

        if (selectedDesignType.titleTemplate && customTitle?.length) {
            const generatedTitle = this.getTitleFromTemplate(
                selectedDesign,
                this.account,
                customTitle,
                selectedDesignType.titleTemplate,
            );
            this.previewTitle$.next(generatedTitle.trim());
        } else {
            this.previewTitle$.next(customTitle.trim());
        }
    }

    async designSelected(
        selectedService: SelectServiceControl,
        selectedDesign: SelectDesignControl,
        designTypes: IWorkflowDesignType[],
    ): Promise<void> {
        if (!selectedService && !selectedDesign) {
            return;
        }

        this.selectedServiceControl = selectedService;
        this.selectedDesignControl = selectedDesign;
        this.threadType = selectedService?.threadType;
        this.workflowDesignId = selectedDesign?.designId;
        this.workflowConfigurationId = selectedDesign?.workflowConfigurationId;

        if (!selectedService?.threadType || !selectedService?.configuration || !selectedDesign?.designId) {
            this.selectedDesignSubject.next(null);
            this.changeDetectorRef.detectChanges();
            return;
        }

        if (this.defaultThread?.workflow.designId !== selectedDesign?.designId) {
            this.defaultThread = null;
            this.changeDetectorRef.detectChanges();
        }

        this.workflowConfiguration$ = this.getWorkflowConfiguration(this.workflowConfigurationId);

        const { unresolvedWorkflow, resolvedWorkflow } = await this.getWorkflowDesign(selectedDesign).toPromise();
        const designType = this.findDesignType(designTypes, selectedService, selectedDesign);

        this.workflowDesignSubject.next(unresolvedWorkflow);
        this.selectedDesignSubject.next(resolvedWorkflow);
        this.selectedDesignType$.next(designType);

        const defaultParticipantsFromModalData = this.data.defaultParticipants;

        this.setParticipantUpdates(
            this.workflowConfiguration$,
            this.assignees,
            this.data?.defaultThread?.participants,
            defaultParticipantsFromModalData,
        );

        this.setIsWorkflowValid(this.workflowConfiguration$);
        this.setWorkflowConfigTokens();

        if (!resolvedWorkflow) {
            console.warn("No design found");
            return;
        }

        this.selectedHasSlas = Object.values(resolvedWorkflow.steps).some((step: IStep) =>
            step.extensions.some((extension) => extension?.type === SLA_EXTENSION_TYPE),
        );

        this.manageThreadTitle(resolvedWorkflow, designType);
    }

    createThread(
        threadTitle: BehaviorSubject<string>,
        accountId: string,
        participants$: Observable<IParticipant[]>,
        dueDates: Record<string, DateTime>,
        assignees: Record<string, string[]>,
        workflowDesignSubject$: BehaviorSubject<IWorkflowDesign>,
        selectedDesignType$: BehaviorSubject<IWorkflowDesignType>,
        selectedWorkflowTokens$: BehaviorSubject<SelectWorkflowTokenControl>,
        workflowConfiguration$: Observable<IWorkflowConfiguration>,
    ): void {
        const createThread$ = combineLatest([
            threadTitle,
            workflowConfiguration$,
            participants$,
            workflowDesignSubject$,
            selectedDesignType$,
            selectedWorkflowTokens$,
        ]);
        createThread$
            .pipe(
                filter(([, , participants]) => !!participants?.length),
                take(1),
                switchMap(
                    ([
                        threadTitle,
                        workflowConfig,
                        participants,
                        workflowsDesign,
                        selectedDesignType,
                        selectedWorkflowTokens,
                    ]) => {
                        const workflowTokens = this.getWorkflowDesignTokens(workflowConfig, selectedWorkflowTokens);
                        const workflowInputs = this.getWorkflowInputs(
                            workflowsDesign,
                            workflowConfig,
                            dueDates,
                            assignees,
                        );
                        const referencedFrom = this.data?.referenceFrom || null;
                        const createThread$ = this.threadsService.createThread(
                            threadTitle,
                            workflowsDesign.id,
                            accountId,
                            participants,
                            selectedDesignType.threadType,
                            workflowInputs,
                            workflowConfig?.id,
                            workflowTokens,
                            referencedFrom,
                            selectedDesignType.restrictCardsToInternal,
                        );

                        return this.loader.wrap(createThread$);
                    },
                ),
            )
            .subscribe((thread) => {
                this.showAlert(thread);
                this.dialogRef.close(thread);
            });
    }

    showAlert(thread: IThread): void {
        this.alertService
            .show({
                status: ToastSeverity.Success,
                label: ToastSummary.NewWorkflowCreated,
                message: `${thread.title} has been created. Click to view`,
                routerLink: ["workflows", thread.id],
            })
            .subscribe();
    }

    close(): void {
        this.dialogRef.close();
    }

    async navigateToConfiguration(accountId: string, workflowConfigurationId: string): Promise<void> {
        this.close();
        await this.router.navigate(["/accounts", accountId, "workflow-configurations", workflowConfigurationId]);
    }

    setAssignees(assignees: Record<string, string[]>): void {
        this.assignees = assignees;

        const mergedDefaultParticipants = this.mergeClonedThreadAndModalParticipants(
            this.data.defaultThread?.participants,
            this.data.defaultParticipants,
        );

        this.setParticipantUpdates(this.workflowConfiguration$, this.assignees, mergedDefaultParticipants);
    }

    updateParticipants(participants: IParticipantUi[]): void {
        this.participants$ = of(participants);
    }

    async workflowTokenSelected(selectWorkflowTokenControl: SelectWorkflowTokenControl): Promise<void> {
        this.selectWorkflowTokenControl$.next(selectWorkflowTokenControl);
        if (selectWorkflowTokenControl.workflowTokenIds?.length) {
            const { unresolvedWorkflow, resolvedWorkflow } = await this.getWorkflowDesign(
                this.selectedDesignControl,
            ).toPromise();
            this.workflowDesignSubject.next(unresolvedWorkflow);
            this.selectedDesignSubject.next(resolvedWorkflow);
        }
    }

    private getExtensionInputUpdates(
        selectedDesign: IWorkflowDesign,
        workflowConfig: IWorkflowConfiguration,
        dueDates: Record<string, DateTime>,
        assignees: Record<string, string[]>,
        workflowTokensInputs: IWorkflowInputs,
    ): IWorkflowInputs {
        if (workflowConfig?.id) {
            const workflowAssignees = WorkflowExtensionService.getStepAssigneesFromConfig(workflowConfig);
            return WorkflowExtensionService.getExtensionInputUpdates(
                selectedDesign,
                workflowAssignees,
                dueDates,
                workflowTokensInputs,
            );
        } else {
            return WorkflowExtensionService.getExtensionInputUpdates(
                selectedDesign,
                assignees,
                dueDates,
                workflowTokensInputs,
            );
        }
    }

    private getTitleFromTemplate(
        design: IWorkflowDesign,
        account: Account,
        customThreadTitle: string,
        titleTemplate?: string,
    ): string {
        return TitleTemplateService.getThreadTitle(design, account, customThreadTitle, titleTemplate);
    }

    private filterDesignType(designType: IWorkflowDesignType): boolean {
        const hasConfigurations =
            designType.requiresConfiguration === ConfigurationOption.Enabled ||
            designType.requiresConfiguration === ConfigurationOption.Optional;
        const allowAdhocCreation = designType.allowAdhocCreation;

        return hasConfigurations && allowAdhocCreation;
    }

    private getWorkflowConfiguration(workflowConfigurationId?: string): Observable<IWorkflowConfiguration | null> {
        if (!workflowConfigurationId) {
            return of(null);
        }

        return this.loader
            .wrap(this.workflowConfigurationService.getWorkflowConfiguration(this.account.id, workflowConfigurationId))
            .pipe(
                catchError(() => {
                    console.error(`Error fetching workflow configuration with id: ${workflowConfigurationId}`);
                    this.workflowConfigurationId = null;
                    this.defaultThread.workflowConfigurationId = null;
                    return of(null);
                }),
                shareReplay(1),
            );
    }

    private getWorkflowDesign(selectedDesign: SelectDesignControl): Observable<ResolvedWorkflow> {
        const design$ = this.workflowService.getDesign(selectedDesign.designId).pipe(shareReplay(1));
        const workflowDesignSubject$ = combineLatest([design$, this.workflowConfiguration$]);

        const resolvedWorkflowDesign$ = workflowDesignSubject$.pipe(
            withLatestFrom(this.selectWorkflowTokenControl$),
            map(([[workflowDesign, workflowConfig], selectedWorkflowTokens]) => {
                const workflowVariations = this.getWorkflowDesignTokens(workflowConfig, selectedWorkflowTokens);
                return WorkflowResolverService.resolveDesign(workflowDesign, workflowVariations);
            }),
        );

        return resolvedWorkflowDesign$;
    }

    private getWorkflowDesignTokens(
        workflowConfig: IWorkflowConfiguration,
        selectedWorkflowTokens?: SelectWorkflowTokenControl,
    ): IWorkflowVariation[] {
        const configTokenAssignees = ConfigTokenHelpers.getConfigRoleTokensAssignees(workflowConfig?.workflowTokens);
        if (configTokenAssignees.length || workflowConfig?.workflowTokens) {
            return workflowConfig?.workflowTokens;
        }

        const selectedTokens = this.workflowVariationsService.getWorkflowTokens(
            selectedWorkflowTokens?.workflowTokenIds,
        );
        return selectedTokens;
    }

    private findDesignType(
        designTypes: IWorkflowDesignType[],
        selectedService: SelectServiceControl,
        selectedDesign: SelectDesignControl,
    ): IWorkflowDesignType {
        return designTypes.find(
            (designType) =>
                designType.threadType === selectedService.threadType &&
                designType.workflowDesignId === selectedDesign.designId,
        );
    }

    private setParticipantUpdates(
        workflowConfig$: Observable<IWorkflowConfiguration>,
        assignees: Record<string, string[]>,
        defaultThreadParticipants: IParticipant[],
        defaultParticipantsFromModalConfig?: IParticipant[],
    ): void {
        const mergedDefaultParticipants = this.mergeClonedThreadAndModalParticipants(
            defaultThreadParticipants,
            defaultParticipantsFromModalConfig,
        );

        this.participants$ = workflowConfig$.pipe(
            switchMap((workflowConfig) => {
                if (workflowConfig) {
                    const steps = workflowConfig.steps;
                    const observers = workflowConfig.observers;
                    const workflowAssignees = this.participantService.getWorkflowConfigurationAssignees(steps);
                    const workflowObservers = this.participantService.getWorkflowConfigurationObservers(observers);
                    const tokenAssignees = ConfigTokenHelpers.getConfigRoleTokensAssignees(
                        workflowConfig?.workflowTokens,
                    );

                    return this.participantService.getClonedParticipants(
                        mergedDefaultParticipants,
                        workflowAssignees,
                        workflowObservers,
                        tokenAssignees,
                    );
                } else {
                    const uniqueAssignees = new Set(Object.values(assignees || []).flat());

                    return this.participantService.getClonedParticipants(mergedDefaultParticipants, [
                        ...uniqueAssignees,
                    ]);
                }
            }),
            startWith([]),
        );
    }

    private mergeClonedThreadAndModalParticipants(
        defaultThreadParticipants: IParticipant[],
        defaultParticipantsFromModalConfig: IParticipant[],
    ): IParticipant[] {
        const fromClonedThread = defaultThreadParticipants || [];
        const fromModalData = defaultParticipantsFromModalConfig || [];
        const modalDataParticipants = this.getUniqueModalDataParticipants(fromModalData, fromClonedThread);

        return [...fromClonedThread, ...modalDataParticipants];
    }

    private getUniqueModalDataParticipants(
        fromModalData: IParticipant[],
        fromClonedThread: IParticipant[],
    ): IParticipant[] {
        const participants = fromModalData.filter((participant) => {
            const index = this.checkExistingParticipant(fromClonedThread, participant);
            return index < 0;
        });

        return participants;
    }

    private checkExistingParticipant(existingParticipants: IParticipant[], participant: IParticipant): number {
        return existingParticipants.findIndex((addedParticipant) => addedParticipant.id === participant.id);
    }

    private setIsWorkflowValid(workflowConfig$: Observable<IWorkflowConfiguration>): void {
        workflowConfig$.pipe(take(1)).subscribe((workflowConfig) => {
            if (!workflowConfig) {
                this.isWorkflowValid = true;
            } else {
                this.isWorkflowValid = !!workflowConfig?.steps;
            }
        });
    }

    private manageThreadTitle(selectedDesign: IWorkflowDesign, selectedDesignType: IWorkflowDesignType): void {
        if (!selectedDesignType?.titleTemplate) {
            this.customTitle$.next(selectedDesign.label);
            this.previewTitle$.next(selectedDesign.label);
        } else {
            this.customTitle$.next("");
            this.previewTitle$.next("");
        }
    }

    private validateSelectedTokens(designType: IWorkflowDesignType, control: SelectWorkflowTokenControl): boolean {
        const controls = workflowVariations[designType?.workflowToken];

        return controls?.length === control?.workflowTokenIds?.length;
    }

    private subscribeToFormChanges(): Subscription {
        const formValues$ = combineLatest([
            this.selectedDesignSubject,
            this.selectedDesignType$,
            this.selectWorkflowTokenControl$,
            this.previewTitle$,
        ]);

        return formValues$.subscribe(
            ([selectedDesign, selectedDesignType, selectWorkflowTokenControl, previewTitle]) => {
                const isDesignControlsCompleted = !!selectedDesignType && !!selectedDesign && !!previewTitle;
                const designTypeRequiresToken = !!selectedDesignType?.workflowToken;
                const isTokenSelected = this.validateSelectedTokens(selectedDesignType, selectWorkflowTokenControl);
                const isTokenRequiredCompleted = designTypeRequiresToken ? isTokenSelected : true;

                this.isFormCompleted = isDesignControlsCompleted && isTokenRequiredCompleted;
            },
        );
    }

    private getWorkflowInputs(
        workflowDesign: IWorkflowDesign,
        workflowConfig: IWorkflowConfiguration,
        dueDates: Record<string, DateTime>,
        assignees: Record<string, string[]>,
    ): IWorkflowInputs {
        const startStepInputUpdates = WorkflowExtensionService.getStartStepInputUpdates(workflowDesign);
        return this.getExtensionInputUpdates(
            workflowDesign,
            workflowConfig,
            dueDates,
            assignees,
            startStepInputUpdates,
        );
    }

    private setWorkflowConfigTokens(): void {
        this.configTokenSub?.unsubscribe();

        const configTokensIds$ = this.workflowConfiguration$.pipe(
            map((config) => config?.workflowTokens?.map((token) => token.id)),
            startWith([]),
            shareReplay(1),
        );

        this.configTokenSub = configTokensIds$.subscribe((configTokensIds) => {
            this.workflowTokenSelected({ workflowTokenIds: configTokensIds });
        });
    }
}
