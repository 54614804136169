<header class="workflow-header">
    <div class="workflow-header-prepend">
        <threads-services-badge [threadType]="thread().type"></threads-services-badge>
    </div>

    <div class="workflow-header-content">
        <div class="workflow-header-content-leading">
            <div class="workflow-title">
                <div class="workflow-title-part">
                    {{ thread().title }}
                </div>

                <div
                    *ngIf="thread().accountId"
                    class="workflow-title-part workflow-title-account"
                >
                    <a [routerLink]="['/accounts', thread().accountId]">
                        {{ thread().account?.label }}
                    </a>
                </div>
            </div>

            <workflow-tabs [thread]="thread()"></workflow-tabs>
        </div>

        @if (FEATURE_FLAGS.EnableDelphiSortDebug | featureEnabled | async) {
            <div class="workflow-priority">Workflow Priority: {{ debugPriority() }}</div>
            <div class="workflow-due-date">Due Date: {{ debugDueDate() }}</div>
            <div class="workflow-predicted-date">Predicted Date: {{ debugPredictedDate() }}</div>
        }

        @if (hasWorkflowStatusIndicatorEnabled$ | async) {
            <div class="workflow-status-indicator">
                <workflow-status-indicator
                    [workflow]="thread().workflow"
                    size="large"
                ></workflow-status-indicator>
            </div>
        }
    </div>
</header>
