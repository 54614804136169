import { Component, effect, input, output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Account } from "@visoryplatform/threads";

@Component({
    selector: "payroll-settings",
    templateUrl: "./payroll-settings.component.html",
    styleUrls: ["./payroll-settings.component.scss"],
})
export class PayrollSettingsComponent {
    account = input<Account>();
    save = output<Partial<{ deNumber: string; selfBalancing: boolean }>>();

    payrunSettings = new FormGroup({
        deNumber: new FormControl("", [Validators.minLength(6), Validators.maxLength(6)]),
        selfBalancing: new FormControl(false),
    });

    constructor() {
        effect(() => {
            const currentAccount = this.account();
            if (currentAccount?.metadata?.payrunSettings) {
                const { deNumber, selfBalancing } = currentAccount.metadata.payrunSettings;
                this.payrunSettings.patchValue({
                    deNumber,
                    selfBalancing,
                });
            }
        });
    }

    savePayrunSettings(): void {
        if (this.payrunSettings.valid) {
            this.save.emit(this.payrunSettings.value);
        }
    }
}
