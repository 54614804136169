import { Injector, Type } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { EmbeddedItem } from "./embedded.service";
import { ExtensionDisplayRef } from "./extension-display-ref";

export interface ExtensionDisplayConfig<T = unknown> {
    data: T;
    [key: string]: any;
}

export abstract class ExtensionDisplayService {
    abstract item$?: Subject<EmbeddedItem | null>;
    abstract open<R, C = unknown>(
        component: Type<C>,
        config: ExtensionDisplayConfig<any>,
        useModal?: boolean,
    ): Observable<R>;
    abstract close(): void;
    back?(): void;
    getRef?<R = unknown>(injector: Injector, useModal?: boolean): Observable<ExtensionDisplayRef<R>>;
    getData?<T = unknown>(injector: Injector, useModal?: boolean): Observable<T>;
    getConfirmConfigPanelClass?(): Observable<string[]>;
}
