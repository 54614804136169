import { Component, computed, effect, Inject, input, Signal, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { PortalUiModule, VAvatar, VBannerComponent } from "@visoryplatform/portal-ui";
import { ITimeline, Role } from "@visoryplatform/threads";
import {
    AssigneeExtensionHelpers,
    INACTIVE_STEP_IDS,
    IStep,
    IWorkflow,
    SystemStepId,
    WorkTimeExtensionHelpers,
} from "@visoryplatform/workflow-core";
import { BannerRelevantMemoriesComponent } from "projects/default-plugins/memories/components/banner-relevant-memories/banner-relevant-memories.component";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags/enums/LaunchDarklyFeatureFlags";
import { FeatureFlagService } from "projects/portal-modules/src/lib/feature-flags/services/feature-flags.service";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ROLE } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { IWorkflowStepUI } from "projects/portal-modules/src/lib/threads-ui/interfaces/IWorkflowStepUI";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { UserAvatarService } from "projects/portal-modules/src/lib/threads-ui/services/user-avatar.service";
import { WorkflowStepsService } from "projects/portal-modules/src/lib/threads-ui/services/workflow/workflow-steps.service";
import { DurationBadgeComponent } from "projects/portal-modules/src/lib/workflow-status/components/duration-badge/duration-badge.component";
import { WorkflowStepCheckboxComponent } from "projects/portal-modules/src/lib/workflow-status/components/workflow-step-checkbox/workflow-step-checkbox.component";
import { filter, firstValueFrom, Observable } from "rxjs";
import { SlaRemainingComponent } from "../../../../workflow-status/components/sla-remaining/sla-remaining.component";
import { ThreadOverviewHeaderGhostLoaderComponent } from "./thread-overview-header-ghostloader.component";

type StepEntry = IWorkflowStepUI & {
    index: number;
};

@Component({
    selector: "thread-overview-header",
    templateUrl: "./thread-overview-header.component.html",
    styleUrl: "./thread-overview-header.component.scss",
    standalone: true,
    imports: [
        SlaRemainingComponent,
        DurationBadgeComponent,
        PortalUiModule,
        WorkflowStepCheckboxComponent,
        ThreadOverviewHeaderGhostLoaderComponent,
        VBannerComponent,
        BannerRelevantMemoriesComponent,
    ],
})
export class ThreadOverviewHeaderComponent {
    busy = input.required<boolean>();
    thread = input.required<ITimeline>();
    workflow = input.required<IWorkflow>();
    currentStep = input.required<IStep>();
    user: Signal<AppUser | undefined>;
    role: Signal<Role | undefined>;

    readonly SystemStepId = SystemStepId;
    readonly assignees = computed(
        () => AssigneeExtensionHelpers.getAssigneeData(this.currentStep()?.extensions)?.assignees || [],
    );
    readonly avatars = signal<VAvatar[]>([]);
    readonly workTime = computed(() => this.getWorktime());

    stepEntry = computed<StepEntry | undefined>(() => this.getStepEntry());
    showDetails = computed(() => !(INACTIVE_STEP_IDS as string[]).includes(this.currentStep().id));
    showAssigneeBanner = computed(() => this.shouldShowAssigneeBanner());
    enableMemories: Signal<boolean>;

    private readonly viewWorkTimePermission = this.permissionService.checkPermissionSignal("ViewWorkTime");

    constructor(
        @Inject(ROLE) role$: Observable<Role>,
        private permissionService: PermissionService,
        private userAvatarService: UserAvatarService,
        private workflowStepsService: WorkflowStepsService,
        private authService: AuthService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.user = toSignal(this.authService.getUser().pipe(filter((user) => !!user)));

        effect(
            async () => {
                const assignees = this.assignees();
                const avatars = await this.getAssigneeAvatars(assignees);
                this.avatars.set(avatars);
            },
            { allowSignalWrites: true },
        );

        this.role = toSignal(role$);

        const hasReadServiceMemories = toSignal(
            this.permissionService.checkPermissions(this.role(), "ReadServiceMemories"),
        );

        const enableMemoriesFlag = toSignal(
            this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableWorkflowRelevantMemories),
        );

        this.enableMemories = computed(() => hasReadServiceMemories() && (enableMemoriesFlag() ?? false));
    }

    private async getAssigneeAvatars(assignees: string[]): Promise<VAvatar[]> {
        if (!assignees?.length) {
            return [];
        }

        try {
            return await firstValueFrom(this.userAvatarService.getAvatarsFromIds(assignees));
        } catch (error) {
            console.error("Error getting assignee avatars", error);
            return [];
        }
    }

    private getStepEntry(): StepEntry | undefined {
        const stepEntries = this.workflowStepsService.getUpdatedStepEntries(this.thread().createdAt, this.workflow());
        const visibleEntries = stepEntries.filter((step) => this.isEntryVisible(step, this.currentStep().id));
        const stepEntry = visibleEntries.find((step) => step.step.id === this.currentStep().id);

        if (stepEntry) {
            return { ...stepEntry, index: visibleEntries.indexOf(stepEntry) };
        }

        return undefined;
    }

    private isEntryVisible(entry: IWorkflowStepUI, currentStepId: string): boolean {
        return entry.isMilestone || entry.step.id === currentStepId;
    }

    private getWorktime(): number | null {
        const viewWorkTimePermission = this.viewWorkTimePermission();

        if (viewWorkTimePermission && this.currentStep()) {
            const workTime = WorkTimeExtensionHelpers.getMultipliedWorkTime(this.currentStep()?.extensions);
            return workTime;
        }

        return null;
    }

    private shouldShowAssigneeBanner(): boolean {
        const notAssigned = !this.assignees().includes(this.user()?.id || "");
        const isEndStep = this.currentStep().id === SystemStepId.End;
        const isCancelledStep = this.currentStep().id === SystemStepId.Cancelled;

        return notAssigned && !isEndStep && !isCancelledStep;
    }
}
