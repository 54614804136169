import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { QuillModule } from "ngx-quill";
import { FindexUiModule } from "../../portal-modules/src/lib/findex-ui/findex-ui.module";
import { PLUGIN_FACTORY } from "../../portal-modules/src/lib/plugins";
import { SharedModule } from "../../portal-modules/src/lib/shared";
import { ThreadsUiModule } from "../../portal-modules/src/lib/threads-ui/threads-ui.module";
import { AssistantPlugin } from "./assistant.plugin";
import { ChatListComponent } from "./components/chat-list/chat-list.component";
import { ChatComponent } from "./components/chat/chat.component";
import { MessageListComponent } from "./components/message-list/message-list.component";
import { MessageComponent } from "./components/message/message.component";
import { AccountAssistantService } from "./services/account-assistant.service";
import { AppAssistantService } from "./services/app-assistant.service";
import { VectorSearchService } from "./services/vector-search.service";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        FindexUiModule,
        ThreadsUiModule,
        SharedModule,
        RouterModule,
        QuillModule,
        PortalUiModule,
    ],
    declarations: [MessageListComponent, MessageComponent, ChatListComponent, ChatComponent],
    providers: [
        VectorSearchService,
        AppAssistantService,
        AccountAssistantService,
        { provide: PLUGIN_FACTORY, useClass: AssistantPlugin, multi: true },
    ],
})
export class AssistantModule {}
