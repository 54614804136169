import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, merge } from "rxjs";
import { debounceTime, filter, map, mapTo, startWith, switchMap } from "rxjs/operators";
import { environmentCommon } from "../../../../portal-modules/src/lib/environment/environment.common";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { VectorSearchService } from "../../services/vector-search.service";

@Component({
    selector: "messages",
    templateUrl: "./message-list.component.html",
    styleUrls: ["./message-list.component.scss"],
})
export class MessageListComponent implements OnInit {
    readonly userTypeOptions: string[];
    readonly threadTypeOptions: string[];
    readonly userTypeLabels: Record<string, string> = {
        all: "All users",
        client: "Clients only",
        expert: "Experts only",
    };
    readonly threadTypeLabels: Record<string, string> = {
        all: "All timelines",
        bookkeeping: "Bookkeeping",
        payroll: "Payroll",
    };
    readonly userTypeFilterParams: Record<string, string> = {
        all: null,
        client: environmentCommon.auth.userIdPrefix,
        expert: environmentCommon.activeDirectory.userIdPrefix,
    };
    readonly threadTypeFilterParams: Record<string, string> = {
        all: null,
        bookkeeping: "bookkeeping",
        payroll: "payroll",
    };

    loader = new Loader();

    form = new FormGroup({
        query: new FormControl("", [Validators.required]),
        actorType: new FormControl(),
        threadTypes: new FormControl(),
    });

    results$: Observable<unknown[]>;

    constructor(private vectorSearch: VectorSearchService) {
        this.userTypeOptions = Object.keys(this.userTypeLabels);
        this.threadTypeOptions = Object.keys(this.threadTypeLabels);
    }

    private mapToSearchParams = (search: Record<string, any>) => {
        return {
            ...search,
            actorType: this.userTypeFilterParams[search.actorType],
            threadTypes: this.threadTypeFilterParams[search.threadTypes],
        };
    };

    ngOnInit(): void {
        const valueChanges$ = this.form.valueChanges.pipe(startWith(this.form.value));

        const results$ = valueChanges$.pipe(
            filter((search) => search?.query.length > 2),
            debounceTime(400),
            map((search) => this.mapToSearchParams(search)),
            switchMap((search) => this.loader.wrap(this.vectorSearch.search(search))),
        );

        const clearResults$ = valueChanges$.pipe(
            filter((search) => !search || search?.query.length <= 2),
            mapTo(null),
        );

        this.results$ = merge(results$, clearResults$);
    }
}
