import { Component, Inject, Injector, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
    Account,
    AccountData,
    BankfileSetting,
    IPayrollWorkflowConfiguration,
    IThread,
    ITimeline,
    IntegrationTypes,
    RequestTypes,
    Role,
} from "@visoryplatform/threads";
import { CREATE_CARD_EXTENSION_TYPE, IExtension, IStep } from "@visoryplatform/workflow-core";
import {
    CreateVaultBankFileRequestCardPayload,
    VaultRequestService,
} from "projects/default-plugins/vault/services/vault-request.service";
import { WorkflowConfigurationService } from "projects/portal-modules/src/lib/account/services/workflow-configuration.service";
import { AnalyticsService, GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags/enums/LaunchDarklyFeatureFlags";
import { FeatureFlagService } from "projects/portal-modules/src/lib/feature-flags/services/feature-flags.service";
import { ErrorService } from "projects/portal-modules/src/lib/shared/services/error.service";
import { ExtensionDisplayRef } from "projects/portal-modules/src/lib/shared/services/extension-display-ref";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { EMPTY, Observable } from "rxjs";
import { catchError, filter, take } from "rxjs/operators";
import { ENVIRONMENT, EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { RequestTitles } from "../../../../../../src/environments/sigma-feature-flags";
import { PayrunReportDataService } from "../../../../payroll/services/payrun-report-data.service";
import { ICreateRequestMenu, ICreateRequestTemplate } from "../../../types/CreateRequest";
import { VaultCardType } from "../constants/request.constants";
import { CreatePayrunRequestComponent } from "../create-payrun-request/create-payrun-request.component";
import { CreateRequestService } from "../create-rfi-request/create-request.service";
import { CreateRfiRequestComponent } from "../create-rfi-request/create-rfi-request.component";
import { ICreatePayrunListItem, ICreateRequestModalData } from "../interfaces/request.interfaces";

export type PreCreateRequestData = {
    isWorkflow?: boolean;
    thread: ITimeline;
    role: Role;
    requestType?: string;
    templateId?: string;
    disableEmails?: boolean;
    isInternal?: boolean;
};

@Component({
    selector: "app-pre-create-request",
    templateUrl: "./pre-create-request.component.html",
    styleUrls: ["./pre-create-request.component.scss"],
})
export class PreCreateRequestComponent implements OnInit {
    readonly createRequestConfiguration = this.environment.featureFlags?.createRequestConfiguration;
    readonly gaEvents = GA_EVENTS;
    readonly gaEventsPrefix = GA_EVENTS_PREFIX;
    readonly REQUEST_TYPES = RequestTypes;
    readonly modalWidthRfi = "761px";
    readonly modalWidthPayrun = "1200px";

    currentTemplateSelection: ICreateRequestTemplate;
    currentActionSelection: ICreateRequestMenu;
    menuOptions: ICreateRequestMenu[];
    requestTitles = RequestTitles;
    reports: ICreatePayrunListItem[] = [];
    bankFileSettings: BankfileSetting[] = [];
    loader = new Loader();
    modalLoader = new Loader();
    selectedPayrollReport = new FormControl<ICreatePayrunListItem>(null, Validators.required);
    selectedBankFileSettings = new FormControl<BankfileSetting>(null, Validators.required);
    bankFileListError: string;
    isIntegrationSetup: boolean;
    isPayrollAutomated: boolean;
    integrationStepError: string;
    bankFileSettingsError: string;
    payrollAutomationsEnabledError: string;
    data: PreCreateRequestData;
    enableCreateBankFileCard$: Observable<boolean>;
    private extensionDisplayRef: ExtensionDisplayRef<string>;

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private analytics: AnalyticsService,
        private createRequestService: CreateRequestService,
        private payrunDataService: PayrunReportDataService,
        private vaultRequestService: VaultRequestService,
        private workflowConfigurationService: WorkflowConfigurationService,
        private injector: Injector,
        private featureFlagService: FeatureFlagService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    async ngOnInit(): Promise<void> {
        this.data = await this.extensionDisplayService.getData<PreCreateRequestData>(this.injector).toPromise();
        this.extensionDisplayRef = await this.extensionDisplayService.getRef<string>(this.injector).toPromise();

        const thread = this.data?.thread;
        const provider = this.getProvider(thread.account);

        this.getWorkflowConfigurationSub(thread.account, thread.workflowConfigurationId);

        this.isIntegrationSetup = !!provider;
        this.menuOptions = await this.filterOptionsByTimeline();
        this.enableCreateBankFileCard$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableCreateBankFileCard,
        );

        if (this.menuOptions?.length === 1) {
            this.currentActionSelection = this.menuOptions[0];
            this.openCreateRequestModal();
        }

        this.buildPayRunErrors(thread.account);
    }

    buildPayRunErrors(account: Account): void {
        if (!account) {
            return;
        }

        this.integrationStepError = `${account?.label} should be linked to an intergration for this option,
        please contact your administrator`;
        this.payrollAutomationsEnabledError = `${account?.label}  has payroll automations enabled. Please create the report via the workflow.`;
    }

    setAnalyticsForSelection(request): void {
        switch (request.type) {
            case RequestTypes.BlankRequest:
                this.analytics.recordEvent("mouse-click", this.gaEvents.RFI_CREATE_REQUEST_BLANK);
                break;
            case RequestTypes.TemplateRequest:
                this.analytics.recordEvent("mouse-click", this.gaEvents.RFI_CREATE_REQUEST_TEMPLATE);
                break;
            case RequestTypes.PayrunRequest:
                this.analytics.recordEvent("mouse-click", this.gaEvents.PAYRUN_CREATE_REQUEST);
                break;
        }
    }

    selectionActionType(request: ICreateRequestMenu): void {
        this.currentTemplateSelection = this.getDefaultTemplate(request.templates, this.data.templateId);
        this.setAnalyticsForSelection(request);

        if (request !== this.currentActionSelection) {
            const accountId = this.data?.thread?.account?.id;
            const provider = this.getProvider(this.data?.thread?.account);

            this.currentActionSelection = request;

            if (
                this.isIntegrationSetup &&
                (request.type === RequestTypes.PayrunRequest || request.type === RequestTypes.CreateBankFileRequest) &&
                !this.reports.length
            ) {
                this.getPayRunList();
            }

            if (request.type === RequestTypes.CreateBankFileRequest && !this.bankFileSettings.length) {
                this.getBankFileSettingsList(accountId, provider);
            }
        } else {
            this.currentActionSelection = null;
        }

        if (this.currentTemplateSelection) {
            this.openCreateRequestModal();
        }
    }

    templateSelection(template: ICreateRequestTemplate): void {
        if (!template) {
            this.currentTemplateSelection = null;
        } else {
            this.currentTemplateSelection = template;
        }
    }

    close(result?: string): void {
        this.extensionDisplayRef.close(result);
    }

    async openCreateRequestModal(): Promise<void> {
        switch (this.currentActionSelection?.type) {
            case RequestTypes.PayrunRequest:
                if (this.selectedPayrollReport?.value) {
                    this.openCreatePayrunModal()?.subscribe((result) => this.close(result));
                }
                break;
            case RequestTypes.CreateBankFileRequest:
                await this.createBankFileRequest();
                break;
            default:
                this.analytics.recordEvent("mouse-click", this.buildAnalyticsTemplate());
                this.openCreateRfiModal()?.subscribe((result) => this.close(result));
                break;
        }
    }

    private openCreateRfiModal(): Observable<string> {
        const data = {
            template: this.currentTemplateSelection?.template || null,
            thread: this.data.thread,
            type: VaultCardType.VaultRequest,
            disableEmails: this.data.disableEmails,
        };

        const config = {
            panelClass: ["centered-modal"],
            width: this.modalWidthRfi,
            data,
            disableClose: true,
        };

        return this.extensionDisplayService.open<string>(CreateRfiRequestComponent, config);
    }

    private getBankFileSettingsList(accountId: string, provider: IntegrationTypes): void {
        this.loader
            .wrap(
                this.payrunDataService.getBankFileSettings(accountId, provider).pipe(
                    catchError((error: unknown) => {
                        if (ErrorService.isHttpErrorResponse(error)) {
                            this.bankFileListError = error?.error?.message || "Sorry, something went wrong.";
                        }
                        return EMPTY;
                    }),
                    filter((settings) => !!settings.length),
                    take(1),
                ),
            )
            .subscribe((settings) => {
                this.bankFileSettings = settings;
                this.selectedBankFileSettings.setValue(settings[0]);
            });
    }

    private getPayRunList(): void {
        const accountId = this.data?.thread?.accountId;
        const provider = this.getProvider(this.data?.thread?.account);

        this.loader
            .wrap(this.payrunDataService.getPayRunList(accountId, provider).pipe(take(1)))
            .subscribe((activeReports) => {
                if (activeReports.length) {
                    this.reports = activeReports;
                    this.selectedPayrollReport.setValue(activeReports[0]);
                }
            });
    }

    private getProvider(account: AccountData): IntegrationTypes {
        const hasXero = account?.metadata?.integrations?.xero?.businessId;
        const hasEmploymentHero = account?.metadata?.integrations?.keypay?.businessId;

        if (hasEmploymentHero) {
            return IntegrationTypes.EmploymentHero;
        } else if (hasXero) {
            return IntegrationTypes.Xero;
        } else {
            return null;
        }
    }

    private async createBankFileRequest(): Promise<void> {
        const bankFileTemplate = this.createRequestConfiguration.find(
            (template) => template.type === RequestTypes.CreateBankFileRequest,
        );

        const mapTemplate = bankFileTemplate.template.requestItems.map((template) => ({
            description: template,
        }));

        const payload: CreateVaultBankFileRequestCardPayload = {
            ...bankFileTemplate.template,
            accountId: this.data.thread.account.id,
            bankFileSettingId: this.selectedBankFileSettings?.value.id,
            payrunId: this.selectedPayrollReport?.value.id,
            requestItems: mapTemplate,
            thread: this.data.thread,
            type: VaultCardType.VaultRequest,
        };

        this.analytics.recordEvent("mouse-click", this.gaEvents.RFI_CREATE_REQUEST_BANK_FILE);

        await this.loader
            .wrap(this.vaultRequestService.createVaultBankFileRequestCard(this.data.thread.id, payload))
            .toPromise();

        this.close();
    }

    private openCreatePayrunModal(): Observable<string> {
        const provider = this.getProvider(this.data?.thread?.account);
        const data: ICreateRequestModalData = {
            reportListItem: this.selectedPayrollReport?.value || null,
            template: this.currentTemplateSelection?.template || null,
            thread: this.data.thread,
            type: VaultCardType.VaultPayrollApprovalRequest,
            disableEmails: this.data.disableEmails,
            provider: provider,
            isInternal: this.data.isInternal,
        };

        const config = {
            panelClass: ["centered-modal"],
            width: this.modalWidthPayrun,
            data,
            disableClose: true,
        };

        return this.extensionDisplayService.open<string>(CreatePayrunRequestComponent, config);
    }

    private getDefaultTemplate(
        templates: ICreateRequestTemplate[],
        defaultTemplateId: string,
    ): ICreateRequestTemplate | null {
        const template = templates?.find((template) => template.id === defaultTemplateId);
        return template || null;
    }

    private sanitizeString(val: string): string {
        return val.replace(/\s/g, "_").toLowerCase();
    }

    private buildAnalyticsTemplate(): string {
        const selection = this.currentActionSelection;
        const templateSelection = this.currentTemplateSelection;
        const reportSelection = this.selectedPayrollReport.value;

        if (templateSelection) {
            return `${this.gaEventsPrefix.RFI}_template_selected_${this.sanitizeString(templateSelection.title)}`;
        } else if (reportSelection) {
            return GA_EVENTS.PAYRUN_REPORT_SELECTED;
        } else if (selection) {
            return `${this.gaEventsPrefix.RFI}_template_selected_${this.sanitizeString(selection.title)}`;
        }

        return null;
    }

    private async filterOptionsByTimeline(): Promise<ICreateRequestMenu[]> {
        const threadType = this.data.thread.type;

        const options = await this.createRequestService.filterOptions(
            threadType,
            this.data.role,
            this.createRequestConfiguration,
        );

        if (this.data.requestType) {
            const matches = options.filter((option) => option.type === this.data.requestType);
            if (matches.length === 1) {
                this.selectionActionType(matches[0]);
            }
            return matches;
        } else {
            return options;
        }
    }

    private getIsPayrollAutomated(thread: IThread, requestType: string): boolean {
        const isThreadAutomated = this.isThreadPayrollAutomatedExtension(thread);

        return isThreadAutomated && !requestType;
    }

    private isThreadPayrollAutomatedExtension(thread: IThread): boolean {
        if (!thread?.workflow?.steps) {
            return false;
        }

        return Object.values(thread.workflow.steps).some((step) => this.hasCreatePayrunCardExtension(step));
    }

    private hasCreatePayrunCardExtension(step: IStep): boolean {
        return step?.extensions?.some((extension) => this.isCreatePayrunCardExtension(extension));
    }

    private isCreatePayrunCardExtension(extension: IExtension): boolean {
        return extension?.type === CREATE_CARD_EXTENSION_TYPE;
    }

    private getWorkflowConfigurationSub(account: Account, workflowConfigurationId?: string): void {
        if (!workflowConfigurationId || !account) {
            return;
        }

        const workflowConfig$ = this.workflowConfigurationService
            .getWorkflowConfiguration(account.id, workflowConfigurationId)
            .pipe(
                catchError(() => EMPTY),
                take(1),
            );

        this.modalLoader.wrap(workflowConfig$).subscribe((_workflowConfiguration: IPayrollWorkflowConfiguration) => {
            this.isPayrollAutomated = this.getIsPayrollAutomated(this.data?.thread, this.data?.requestType);
        });
    }
}
