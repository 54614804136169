import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CardReply, IThread, IThreadCard } from "@visoryplatform/threads";
import { filter, take } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { Loader } from "../../../shared/services/loader";
import { ThreadCardService } from "../../services/thread-card.service";
import { ThreadCardRepliesListComponent } from "../thread-card-replies-list/thread-card-replies-list.component";

@Component({
    selector: "thread-card-replies",
    templateUrl: "./thread-card-replies.component.html",
    styleUrls: ["./thread-card-replies.component.scss"],
})
export class ThreadCardRepliesComponent {
    @ViewChild("threadCardRepliesListComponent")
    threadCardRepliesListComponent: ThreadCardRepliesListComponent;

    @Input() replies: CardReply[];
    @Input() thread: IThread;
    @Input() userId: string;
    @Input() card: IThreadCard;
    @Input() showCompose = false;
    @Input() readonly = false;
    @Input() replyControl = new FormControl(""); //TODO: should be a form control
    @Input() isInternal = false;

    @Output() replySaved: EventEmitter<CardReply> = new EventEmitter();

    replyLoader = new Loader();

    constructor(
        private cardService: ThreadCardService,
        private authService: AuthService,
    ) {}

    async reply(message: string): Promise<void> {
        if (!message) {
            return;
        }

        this.replyLoader.show();

        const user = await this.authService
            .getUser()
            .pipe(
                filter((userObj) => !!userObj),
                take(1),
            )
            .toPromise();

        const reply = {
            id: "",
            message: message,
            timestamp: new Date().toISOString(),
            actor: user.id,
        };

        try {
            this.replies = this.replies || [];
            this.replies.push(reply);

            await this.cardService.replyCard(this.thread.id, this.card.id, reply.message).toPromise();

            this.replySaved.next(reply);
        } catch (err) {
            const replyIndex = this.replies.indexOf(reply);
            this.replies.splice(replyIndex, 1);
            throw err; //Let global error show
        } finally {
            this.replyLoader.hide();
        }
    }
}
