import { Component, Inject, Injector, OnInit } from "@angular/core";
import { IInvitation } from "@visoryplatform/calendar-types";
import { IAvatarContent } from "@visoryplatform/fx-ui";
import { IParticipant, IThread, IThreadCard, Role, SubjectType } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ExtensionDisplayRef } from "projects/portal-modules/src/lib/shared/services/extension-display-ref";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { ENVIRONMENT, EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { environmentCommon } from "src/environments/environment";
import { CalendarState, MeetingStatus } from "../../calendar-state.type";
import { OpenCalendarModal } from "../../calendar-task-actions";
import { CalendarCardService } from "../../services/calendar-card.service";
import { CalendarService } from "../../services/calendar.service";

export interface CalendarDetailsModel {
    state$: Observable<CalendarState>;
    userId$: Observable<string>;
    thread$: Observable<IThread>;
    meetingStatus$: Observable<string>;
    invitedToMeeting$: Observable<boolean>;
    avatar$: Observable<IAvatarContent[]>;
    card$: Observable<IThreadCard>;
    role: Role;
}

@Component({
    selector: "calendar-details-modal",
    templateUrl: "./calendar-details-modal.component.html",
    styleUrls: ["./calendar-details-modal.component.scss"],
})
export class CalendarDetailsModalComponent implements OnInit {
    readonly notInvitedToMeetingText = this.environment.featureFlags.text.default.notInvitedToMeeting;
    readonly roles = Role;
    readonly meetingStatuses = MeetingStatus;
    readonly meetingTimeFormat = environmentCommon.dateFormats.short;
    calendarModalOptions = OpenCalendarModal;

    state$: Observable<CalendarState>;
    userId$: Observable<string>;
    thread$: Observable<IThread>;
    meetingStatus$: Observable<string>;
    invitedToMeeting$: Observable<boolean>;
    avatar$: Observable<IAvatarContent[]>;
    meetingName$: Observable<string>;
    invitation$: Observable<IInvitation>;
    attendees$: Observable<IParticipant[]>;
    canRescheduleMeeting$: Observable<boolean>;

    loaded$: Observable<any>;

    invitationCancelled$: Observable<boolean>;
    start$: Observable<Date>;
    end$: Observable<Date>;
    role: Role;
    invitationId: string;
    appointmentConfirmed$: Observable<boolean>;
    organiser$: Observable<string>;

    private data: CalendarDetailsModel;
    private extensionDisplayRef: ExtensionDisplayRef<{ openCalendarModal: OpenCalendarModal }>;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private calendarService: CalendarService,
        private participantsCache: ParticipantCache,
        private injector: Injector,
    ) {}

    async ngOnInit(): Promise<void> {
        this.extensionDisplayRef = await this.extensionDisplayService
            .getRef<{ openCalendarModal: OpenCalendarModal }>(this.injector)
            .toPromise();
        this.data = await this.extensionDisplayService.getData<CalendarDetailsModel>(this.injector).toPromise();

        const { state$, userId$, thread$, meetingStatus$, invitedToMeeting$, avatar$, role, card$ } = this.data;

        this.role = role;
        this.state$ = state$.pipe(shareReplay(1));
        this.userId$ = userId$;
        this.thread$ = thread$;
        this.meetingStatus$ = meetingStatus$;
        this.invitedToMeeting$ = invitedToMeeting$;
        this.appointmentConfirmed$ = state$.pipe(map((state: CalendarState) => state.scheduled));
        this.canRescheduleMeeting$ = combineLatest([this.appointmentConfirmed$, this.invitedToMeeting$]).pipe(
            map(([appointmentConfirmed, isInvited]) => {
                return (this.role !== this.roles.Client || isInvited) && appointmentConfirmed;
            }),
        );
        this.avatar$ = avatar$;
        this.invitationCancelled$ = this.state$.pipe(map((state: CalendarState) => state.cancelled));
        this.organiser$ = card$.pipe(map((card: IThreadCard) => card.createdBy));
        this.meetingName$ = this.state$.pipe(map((state: CalendarState) => state.details?.meetingName || "Meeting"));
        this.attendees$ = this.state$.pipe(
            switchMap((state: CalendarState) => {
                const participantIds = CalendarCardService.getAllAttendees(state).map((attendee) => attendee.id);
                return this.participantsCache.getParticipants(participantIds);
            }),
        );

        this.invitation$ = card$.pipe(
            map((card) => {
                const invitationId = card.subjects?.find((subject) => subject.type === SubjectType.Calendar)?.id;
                return invitationId;
            }),
            switchMap((invitationId) => this.calendarService.getClientInvitation(invitationId)),
            shareReplay(1),
        );

        const nextInstance$ = this.state$.pipe(map((state) => this.calendarService.findNextInstance(state.instances)));

        this.start$ = nextInstance$.pipe(map((instance) => instance?.start && new Date(instance.start)));
        this.end$ = nextInstance$.pipe(map((instance) => instance?.end && new Date(instance.end)));

        this.loaded$ = combineLatest([this.attendees$, this.invitation$]);
    }

    openCalendarModal(action: OpenCalendarModal) {
        this.extensionDisplayRef.close({ openCalendarModal: action });
    }

    closeDialog(): void {
        this.extensionDisplayRef.close();
    }
}
