/* eslint-disable max-lines */
import { RequestTypes, ThreadTypes } from "@visoryplatform/threads";
import { defaultTextResource } from "../../projects/portal-modules/src/lib/environment/default-text-resource";
import {
    FeatureConfig,
    defaultCalendarMeetingDurations,
} from "../../projects/portal-modules/src/lib/environment/environment.common";
import {
    calendarTour,
    filesTour,
    insightsTour,
    introductionTour,
    meetingsTour,
    newDashboardTour,
    newWorkflowListTour,
    notificationsTour,
} from "./tours";

export enum VisoryLegacyWorkflows {
    Bookkeeping = "bookkeeping",
    PeopleAndCulture = "people_culture",
    PayrollFortnightly = "payroll_fortnightly",
    PayrollMonthly = "payroll_monthly",
    PayrollWeekly = "payroll",
    AccountsFortnightlyPayable = "accounts_payable",
    AccountsMonthlyPayable = "accounts_payable_monthly",
    AccountsWeeklyPayable = "accounts_payable_weekly",
    Catchup = "catchup",
    Onboarding = "onboarding",
    Transition = "transition",
    AccountManagement = "account_management",
}

export enum RequestTitles {
    CreateBankFile = "Create ABA file",
    PayrollReportTitle = "Payroll report for approval",
    RequestFromTemplate = "New request from template",
}

export enum PayrollReportRequest {
    Message = "Hi,<br>" +
        "<br>Your payroll report is ready for your review and approval." +
        "<br><br><ul>" +
        "<li><strong>What to do:</strong> Review employee details and approve the pay run information.</li>" +
        "<li><strong>Need changes?</strong> Simply add comments directly to the report if updates are required.</li>" +
        "<li><strong>Payment date:</strong> Payroll will be processed on the pay date listed below.</li>" +
        "</ul>" +
        "<br>If you have any questions or need assistance, feel free to reach out - we're happy to help!",
}

export const SIGMA_FEATURE_CONFIG: FeatureConfig = {
    signupEnabled: true,
    signupCountries: [
        "AC",
        "AD",
        "AE",
        "AF",
        "AG",
        "AI",
        "AL",
        "AM",
        "AO",
        "AR",
        "AS",
        "AT",
        "AU",
        "AW",
        "AX",
        "AZ",
        "BA",
        "BB",
        "BD",
        "BE",
        "BF",
        "BG",
        "BH",
        "BI",
        "BJ",
        "BL",
        "BM",
        "BN",
        "BO",
        "BQ",
        "BR",
        "BS",
        "BT",
        "BW",
        "BY",
        "BZ",
        "CA",
        "CC",
        "CD",
        "CF",
        "CG",
        "CH",
        "CI",
        "CK",
        "CL",
        "CM",
        "CN",
        "CO",
        "CR",
        "CU",
        "CV",
        "CW",
        "CX",
        "CY",
        "CZ",
        "DE",
        "DJ",
        "DK",
        "DM",
        "DO",
        "DZ",
        "EC",
        "EE",
        "EG",
        "EH",
        "ER",
        "ES",
        "ET",
        "FI",
        "FJ",
        "FK",
        "FM",
        "FO",
        "FR",
        "GA",
        "GB",
        "GD",
        "GE",
        "GF",
        "GG",
        "GH",
        "GI",
        "GL",
        "GM",
        "GN",
        "GP",
        "GQ",
        "GR",
        "GT",
        "GU",
        "GW",
        "GY",
        "HK",
        "HN",
        "HR",
        "HT",
        "HU",
        "ID",
        "IE",
        "IL",
        "IM",
        "IN",
        "IO",
        "IQ",
        "IR",
        "IS",
        "IT",
        "JE",
        "JM",
        "JO",
        "JP",
        "KE",
        "KG",
        "KH",
        "KI",
        "KM",
        "KN",
        "KP",
        "KR",
        "KW",
        "KY",
        "KZ",
        "LA",
        "LB",
        "LC",
        "LI",
        "LK",
        "LR",
        "LS",
        "LT",
        "LU",
        "LV",
        "LY",
        "MA",
        "MC",
        "MD",
        "ME",
        "MF",
        "MG",
        "MH",
        "MK",
        "ML",
        "MM",
        "MN",
        "MO",
        "MP",
        "MQ",
        "MR",
        "MS",
        "MT",
        "MU",
        "MV",
        "MW",
        "MX",
        "MY",
        "MZ",
        "NA",
        "NC",
        "NE",
        "NF",
        "NG",
        "NI",
        "NL",
        "NO",
        "NP",
        "NR",
        "NU",
        "NZ",
        "OM",
        "PA",
        "PE",
        "PF",
        "PG",
        "PH",
        "PK",
        "PL",
        "PM",
        "PR",
        "PS",
        "PT",
        "PW",
        "PY",
        "QA",
        "RE",
        "RO",
        "RS",
        "RU",
        "RW",
        "SA",
        "SB",
        "SC",
        "SD",
        "SE",
        "SG",
        "SH",
        "SI",
        "SJ",
        "SK",
        "SL",
        "SM",
        "SN",
        "SO",
        "SR",
        "SS",
        "ST",
        "SV",
        "SX",
        "SY",
        "SZ",
        "TA",
        "TC",
        "TD",
        "TG",
        "TH",
        "TJ",
        "TK",
        "TL",
        "TM",
        "TN",
        "TO",
        "TR",
        "TT",
        "TV",
        "TW",
        "TZ",
        "UA",
        "UG",
        "US",
        "UY",
        "UZ",
        "VA",
        "VC",
        "VE",
        "VG",
        "VI",
        "VN",
        "VU",
        "WF",
        "WS",
        "XK",
        "YE",
        "YT",
        "ZA",
        "ZM",
        "ZW",
    ],
    subscriptionEnabled: true,
    mobileVerifiedRedirect: "/dashboard",
    rfiCategories: ["Permanent documents", "Financial statements", "Banking", "Receipts", "Other"],
    calendarMeetingDurations: defaultCalendarMeetingDurations,
    newThreadButton: true,
    showPayrollSettings: true,
    jobStatusEnabled: true,
    closeThreadContextMenu: false,
    tours: [
        introductionTour,
        newDashboardTour,
        meetingsTour,
        notificationsTour,
        newWorkflowListTour,
        calendarTour,
        filesTour,
        insightsTour,
    ],
    supportEmail: "support@visory.com.au",
    serviceDeskUrl: "https://jira.findex.com.au/servicedesk/customer/portal/68/group/387",
    packageDisplayName: "subscription",
    initialThreadDetails: {
        threadType: ThreadTypes.MyAccount,
    },
    temporaryFeatureFlags: {},
    addParticipants: true,
    inviteClients: true,
    threadParticipantDetail: false,
    showAgreePoliciesLogin: false,
    canViewCalendarPage: true,
    text: {
        loginTitle: "Log in",
        loginDescription: "",
        loginAction: "",
        loginFooter: "",
        signUpTitle: "Sign up to Visory today!",
        signupInstruction: "Join a platform that will support your business and address all your bookkeeping needs.",
        createPasswordTitle: "Create password",
        createPasswordDescription: "In two quick steps, you will be booking your first meeting.",
        verifyEmailTitle: "We've sent you an email!",
        verifyEmailDescription: "To allow us to verify your identity please click on the verification link sent to ",
        verifyMobileTitle: "Almost there, one last step",
        verifyMobileDescription: "To keep your data secure we require you to verify your mobile number.",
        verifyMobileAction: "Verify",
        verifyMobileFooter:
            "Your mobile number will not be shared with third parties or used for marketing communications.",
        verifyCodeTitle: "Almost there, one last step",
        verifyCodeDescription: "We've sent a PIN to your mobile, enter it below to finalise your account.",
        verifyCodeAction: "Verify",
        verifyCodeFooter:
            "Once verified, you'll be taken to your standard log in screen. From here you'll enter the Visory platform and book your first meeting!",
        selectStaffMemberDescription: "Finding the best Customer Success Manager for you.",
        matchLoaderDescription: "Hang tight. This will only take a few seconds.",
        default: defaultTextResource,
    },
    signingRequiresFileDownloadEnabled: false,
    editCardDescription: true,
    threadListFilterStatus: {
        closed: "Complete",
        cancelled: "Cancelled",
        active: "Active",
    },
    showPaymentsSubscriptions: true,
    showPaymentsBilling: true,
    windowWidthMenuBreakpoint: 1279,
    windowWidthTabletBreakpoint: 768,
    requiresOnboarding: true,
    enableBrandLoader: true,
    accountView: true,
    selectStaffAnimationLoadTimeRange: {
        min: 2000,
        max: 4000,
    },
    updateAccountMetadataOnVerifyMobile: true,
    accountMetadataConfiguration: {
        contactInfo: {
            primaryPhoneNumber: {
                label: "Phone number",
            },
        },
        onboarding: {
            industryTypes: {
                label: "Industry types",
                type: "chip",
                valueLabels: {
                    "division-a": "Agriculture & primary production",
                    "division-e": "Construction & sub trades",
                    "division-t": "Ecommerce",
                    "division-p": "Education",
                    "division-h": "Food & hospitality services",
                    "division-q": "Healthcare & recreation",
                    "division-c": "Manufacturing",
                    "division-m": "Professional services",
                    "division-f": "Wholesale trade",
                    "division-g": "Retail trade",
                    "division-u": "Technology",
                    "division-s": "Other",
                },
            },
            businessGoals: {
                label: "Business goals",
                type: "chip",
                valueLabels: {
                    "goal-1": "Create a new business",
                    "goal-2": "Grow revenue",
                    "goal-3": "Improve systems and processes",
                    "goal-4": "Increase profits or valuation",
                    "goal-5": "Improve strategic decision-making",
                    "goal-6": "I'm not sure",
                },
            },
            interestedServices: {
                label: "Interested services",
                type: "chip",
                valueLabels: {
                    "service-1": "Bookkeeping",
                    "service-2": "Payroll",
                    "service-3": "Accounts payable",
                    "service-4": "Accounts receivable",
                    "service-5": "Budgeting and forecasting",
                    "service-6": "Expense optimisation",
                    "service-7": "Reporting & Insights",
                    "service-8": "I'm not sure",
                },
            },
            numberofEmployees: {
                label: "Number of employees",
            },
        },
    },
    onboardingConfiguration: {
        selectGoals: {
            title: "Tell us about you and we'll find a Customer Success Manager to match.",
            subtitle: `We'll find you a team member who'll complete a <strong>free business health check.</strong> To begin with, what are your goals?`,
            tiles: [
                { name: "Create a new business", value: "goal-1", image: "Shop" },
                { name: "Grow revenue", value: "goal-2", image: "Account Payment" },
                { name: "Improve systems and processes", value: "goal-3", image: "Abacus" },
                { name: "Increase profits or valuation", value: "goal-4", image: "Piggy Bank" },
                { name: "Improve strategic decision-making", value: "goal-5", image: "Graph" },
                { name: "I'm not sure", value: "goal-6", image: "Question" },
            ],
            desktopImage: "./assets/images/visory/onboarding/goals/group-photo.png",
            mobileImage: "./assets/images/visory/onboarding/goals/single-photo.png",
        },
        selectServices: {
            title: "What services are you interested in?",
            subtitle: "Select as many services as you like.",
            tiles: [
                { name: "Bookkeeping", value: "service-1", image: "Bookkeeping" },
                { name: "Payroll", value: "service-2", image: "Payment date" },
                { name: "Accounts payable", value: "service-3", image: "Account Payment" },
                { name: "Accounts receivable", value: "service-4", image: "Account Receive" },
                { name: "Budgeting and forecasting", value: "service-5", image: "Scales" },
                { name: "Expense optimisation", value: "service-6", image: "Screen Pie Chart" },
                { name: "Reporting & Insights", value: "service-7", image: "Graph" },
                { name: "I'm not sure", value: "service-8", image: "Question" },
            ],
        },
        selectIndustry: {
            title: "Which industry does your business work in?",
            subtitle:
                "Understanding how your business works will help us match you to the right Customer Success Manager.",
            tiles: [
                { name: "Agriculture & primary production", value: "division-a", image: "Tractor" },
                { name: "Construction & sub trades", value: "division-e", image: "Crane" },
                { name: "Ecommerce", value: "division-t", image: "Ecommerce" },
                { name: "Education", value: "division-p", image: "School" },
                { name: "Food & hospitality services", value: "division-h", image: "Cafe" },
                { name: "Healthcare & recreation", value: "division-q", image: "Hospital" },
                { name: "Manufacturing", value: "division-c", image: "Factory" },
                { name: "Professional services", value: "division-m", image: "Buildings" },
                { name: "Wholesale trade", value: "division-f", image: "Warehouse" },
                { name: "Retail trade", value: "division-g", image: "Shop" },
                { name: "Technology", value: "division-u", image: "Technology" },
                { name: "Other", value: "division-s", image: "Question" },
            ],
        },
        selectNumberOfEmployees: {
            title: "How many people are employed in your business?",
            subtitle: "This includes all full-time, part-time, casual, and contract employees.",
            tiles: [
                { value: "segment-1", title: "Just me" },
                { value: "segment-2", title: "1-5", subtitle: "employees" },
                { value: "segment-3", title: "6-10", subtitle: "employees" },
                { value: "segment-4", title: "11-20", subtitle: "employees" },
                { value: "segment-5", title: "20+", subtitle: "employees" },
            ],
        },
    },
    insightsConfiguration: {
        showInsights: true,
        emptyStateImage: "/assets/images/visory/insights/sample-insights-reports.png",
    },
    vaultConfiguration: {
        showVaultList: true,
    },
    onboardingStaffTitle: "Customer Success Manager",
    createRequestConfiguration: [
        {
            title: "New blank request",
            type: RequestTypes.BlankRequest,
            description: "Manually enter the details of a request.",
        },
        {
            title: "New request from template",
            type: RequestTypes.TemplateRequest,
            description: "Select from a prefilled request template.",
            templates: [
                {
                    id: "8f22a0c9-a3d6-4732-b55a-9a15fd6248d3",
                    title: "Provide information for customer invoices",
                    type: [ThreadTypes.AccountsReceivable],
                    template: {
                        vaultTitle: "Provide information for invoices",
                        cardDescription: `<p>Hi,</p>
                            <p> </p>
                            <p>Please provide/review the below information so that we can complete invoicing </p>
                            <p> </p>
                            <p>Let me know if you have any questions.</p>
                            <p> </p>
                            <p>Thanks.</p>`,
                        requestItems: ["[Insert required information]"],
                    },
                },
                {
                    id: "0510bbdd-423c-4255-bbc6-f1d52550fb92",
                    title: "Bills for review and approval",
                    type: [ThreadTypes.AccountsPayable],
                    template: {
                        vaultTitle: "Bills for review and approval",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find your bill payments approval report here [insert link].<br>" +
                            "Click the link and follow the instructions at the top of the spreadsheet.<br>" +
                            "Your next bills payment will be made on [insert date].<br>" +
                            "If you have any queries please let me know.<br>" +
                            "Thanks.",
                        requestItems: [
                            "Click the above link and select 'Yes' or 'No' from the drop down list in the approved column",
                            "Bill payment approval spreadsheet updated",
                        ],
                    },
                },
                {
                    id: "e92b8d49-4a0a-4314-a5eb-33af79c8ab87",
                    title: "Bank file ready",
                    type: [ThreadTypes.AccountsPayable, ThreadTypes.Payroll, ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "Bank file ready",
                        cardDescription:
                            "Hi,<br>" +
                            "We have now uploaded your bank file ready for your authorisation.<br>" +
                            "If you have any queries please let me know.<br>" +
                            "Thanks.",
                        requestItems: ["Log into internet banking and authorise payment"],
                    },
                },
                {
                    id: "c609a2b7-e914-454f-84cb-ba1a7475adef",
                    title: "Month end queries",
                    type: [ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "Month end queries",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find below a list of queries that require your input.<br>" +
                            "If you have any queries please let me know.<br>" +
                            "Thanks.",
                        requestItems: ["[Insert required information]"],
                    },
                },
                {
                    id: "8af3b584-f4fa-4a11-b52f-3ae73cd2f9db",
                    title: "Month end queries",
                    type: [ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "Month end queries",
                        cardDescription:
                            "Hi,<br><br>" +
                            "Please find below a list of queries we require your assistance with in order to complete Month end. Please answer what you can and let us know which queries we need to send directly to the customer.<br><br>" +
                            "Thanks.",
                        requestItems: ["[Insert required information]"],
                    },
                },
                {
                    id: "80401eb5-afb4-460d-8494-6ebaf2bf0c4e",
                    title: "Queries",
                    type: [ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "Queries",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find attached a list of transactions that require your input.<br>" +
                            "Thanks.",
                        requestItems: [
                            "Please review attached",
                            "Send missing invoices to Dext where possible",
                            "Provide information on remaining transactions",
                            "Provide bank statements for the period [insert date range]",
                        ],
                    },
                },
                {
                    id: "eecb2b03-c516-492f-8b6b-37e9279d6555",
                    title: "Onboarding to do list",
                    type: [ThreadTypes.Bookkeeping, ThreadTypes.MyAccount],
                    template: {
                        vaultTitle: "Onboarding to do list",
                        cardDescription:
                            "Hi,<br>" +
                            "My name is [Onboarding Manager Name].<br>" +
                            "I am your Customer Success Manager at Visory.<br>" +
                            "My role is to ensure that your transition to Visory is a smooth one.<br>" +
                            "Below I have listed tasks for you to complete for us to get started with your onboarding.<br>" +
                            "Thanks.",
                        requestItems: [
                            "Update the access to Xero for client@visoryhq.com to include manage user permissions (see instructions attached above)",
                            "Transfer your Xero subscription to client@visoryhq.com (see instructions attached above)",
                            "Select a time on the below meeting request for our first Onboarding meeting",
                            "Create your Dext account from the email sent to you separately",
                            "Give client@visoryhq.com access to [3rd party software]",
                            "Provide your Shopify url so we can send you a collaborator request",
                            "Please complete the below payment card",
                        ],
                    },
                },
                {
                    id: "e6dc3ccf-b097-4442-8dc4-bbcb4e8b8b2e",
                    title: "Onboarding documents required",
                    type: [ThreadTypes.MyAccount],
                    template: {
                        vaultTitle: "Onboarding documents required",
                        cardDescription: "Please provide copies of the following documents",
                        requestItems: [
                            "Lease agreement",
                            "Finance agreements",
                            "Insurance policies",
                            "Completed Fitstop Excel finance model",
                        ],
                    },
                },
                {
                    id: "c490117c-79e4-469a-8510-aee450b6e756",
                    title: "Grant Visory Xero Access",
                    type: [VisoryLegacyWorkflows.Transition, VisoryLegacyWorkflows.Onboarding],
                    template: {
                        vaultTitle: "Grant Visory Xero Access",
                        cardDescription: `
                            <p>Hi,</p>
                            <p> </p>
                            <p>Please follow the steps below to give Visory access to your Xero file. This access will ensure that we are able to do a detailed scope of your requirements and provide you with an accurate proposal of how Visory can best meet your needs.</p>
                            <p> </p>
                            <ol>
                                <li>Log into <strong>Xero</strong></li>
                                <li>Click on <strong>your company name</strong> in the <strong>top left corner</strong></li>
                                <li>Click <strong>Settings</strong></li>
                                <li>Click <strong>Users</strong></li>
                                <li>Click <strong>Invite Users</strong></li>
                                <li>Enter Name as <strong>Visory AU</strong> and email address as <strong>client@visoryhq.com</strong></li>
                                <li>Tick <strong>Business and accounting</strong></li>
                                <li>Select <strong>Standard</strong> (please note Read-Only access does not give us enough access to perform a detailed scope on your file)</li>
                                <li>Click <strong>Send</strong></li>
                            </ol>
                            <p> </p>
                            <p>If you have any queries, please let me know.</p>
                            <p> </p>
                            <p>Thanks.</p>
                        `,
                        requestItems: ["Tick this once the above steps have been completed"],
                    },
                },
                {
                    id: "503a0e9a-f441-4036-b932-09d0c4cfdd04",
                    title: "Grant Visory MYOB Access",
                    type: [VisoryLegacyWorkflows.Transition, VisoryLegacyWorkflows.Onboarding],
                    template: {
                        vaultTitle: "Grant Visory MYOB Access",
                        cardDescription: `
                            <p>Hi,</p>
                            <p> </p>
                            <p>Please follow the steps below to give Visory access to your MYOB file. This access will ensure that we are able to do a detailed scope of your requirements and provide you with an accurate proposal of how Visory can best meet your needs.</p>
                            <p> </p>
                            <p><strong>Desktop Version Instructions</strong></p>
                            <p> </p>
                            <ol>
                                <li>Log into <strong>MYOB</strong></li>
                                <li>Click <strong>Setup</strong></li>
                                <li>Click <strong>User Access</strong></li>
                                <li>Click <strong>New User</strong></li>
                                <li>Enter Name as <strong>Visory AU</strong></li>
                                <li>Enter email address as <strong>client@visory.com.au</strong></li>
                                <li>Make sure the box at top '<strong>The user will sign in with a my.MYOB account</strong>' is ticked</li>
                                <li>Tick <strong>Invite user</strong> to be an online administrator</li>
                                <li>Tick <strong>Accountant/Bookkeeper</strong> role</li>
                                <li>Click <strong>Send Invite</strong></li>
                            </ol>
                            <p> </p>
                            <p><strong>Online Version Instructions</strong></p>
                            <p> </p>
                            <ol>
                                <li>Log into <strong>MYOB</strong><li>
                                <li>Click your <strong>business name</strong> in the <strong>top right corner</strong><li>
                                <li>Click <strong>Users</strong><li>
                                <li>Click <strong>Create User</strong><li>
                                <li>Enter <strong>Name</strong> as <strong>Visory AU</strong><li>
                                <li>Enter email address as <strong>client@visory.com.au</strong><li>
                                <li>Tick <strong>Invite user</strong> to be an online administrator<li>
                                <li>Tick <strong>Accountant/Bookkeeper</strong> role<li>
                                <li>Select <strong>Online Admin</strong> from the <strong>User Type</strong> drop down box<li>
                                <li>Click <strong>Send invite</strong><li>
                            </ol>
                            <p> </p>
                            <p>If you have any queries, please let me know.</p>
                            <p> </p>
                            <p>Thanks.</p>
                        `,
                        requestItems: ["Tick this once the above steps have been completed "],
                    },
                },
                {
                    id: "b6eb850f-8c98-464d-b094-8d3a06dd8af4",
                    title: "Grant Visory QBO Access",
                    type: [VisoryLegacyWorkflows.Transition, VisoryLegacyWorkflows.Onboarding],
                    template: {
                        vaultTitle: "Grant Visory QBO Access",
                        cardDescription: `
                            <p>Hi,</p>
                            <p> </p>
                            <p>Please follow the steps below to give Visory access to your QBO file. This access will ensure that we are able to do a detailed scope of your requirements and provide you with an accurate proposal of how Visory can best meet your needs.</p>
                            <p> </p>
                            <ol>
                                <li>Log into <strong>QBO</strong></li>
                                <li>Select the <strong>Settings icon</strong> in the top right corner</li>
                                <li>Click <strong>Manage Users</strong></li>
                                <li>Click <strong>Add User</strong></li>
                                <li>Select <strong>Standard User</strong>, click <strong>Next</strong></li>
                                <li><strong>Select All</strong>, click <strong>next</strong></li>
                                <li>Select '<strong>No</strong>' for each of the user settings, click <strong>next</strong></li>
                                <li><strong>First Name</strong> - Visory, <strong>Last Name</strong> - AU, <strong>Email</strong> - client@visory.com.au</li>
                                <li>Click <strong>Save</strong></li>
                            </ol>
                            <p> </p>
                            <p>If you have any queries, please let me know.</p>
                            <p> </p>
                            <p>Thanks.</p>
                        `,
                        requestItems: ["Tick this once the above steps have been completed"],
                    },
                },
                {
                    id: "72624941-1ef9-48eb-a1ee-1f2cbb410205",
                    title: "Bank file ready for upload",
                    type: [ThreadTypes.Payroll, ThreadTypes.AccountsPayable, ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "Bank file ready for upload",
                        cardDescription: `
                            <p>Hi,</p>
                            <p> </p>
                            <p>Please find attached your bank file.</p>
                            <p>Please upload the bank file to internet banking and then authorise to release the payment.</p>
                            <p>If you have any queries please let me know.</p>
                            <p> </p>
                            <p>Thanks</p>
                            `,
                        requestItems: ["Payment made"],
                    },
                },
                {
                    id: "c0ac1387-795d-4a7f-9314-1391cab6fb8b",
                    title: "Payroll for review and approval",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Payroll for review and approval",
                        cardDescription: `
                            <p>Hi,</p>
                            <p> </p>
                            <p>Please find attached payroll reports.</p>
                            <p>Payroll will be paid on [insert date].</p>
                            <p>If you have any queries please let me know.</p>
                            <p> </p>
                            <p>Thanks.</p>
                            `,
                        requestItems: [
                            "Review payroll reports and advise any changes required",
                            "I approve the attached payroll and authorise Visory to submit STP to the ATO",
                        ],
                    },
                },
                {
                    id: "795b0ea6-2c54-473b-bedf-8742187543f5",
                    title: "Payroll reminder",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Pay run information required",
                        cardDescription:
                            "Hi,<br>" +
                            "Please complete the below to ensure we can process your payroll on time.<br>" +
                            "Thanks.",
                        requestItems: [
                            "Ensure all timesheets are submitted",
                            "Review and approve all timesheets by [insert date]",
                            "Provide information on any changes to your workforce (i.e. new starters, terminations, changed conditions etc.)",
                            "Advise any payroll variations (i.e. salary sacrifice, deductions, overtime etc.)",
                        ],
                    },
                },
                {
                    id: "266d6f35-f6e5-420f-a1d5-8dd6cb2ace9b",
                    title: "New Employee Personal Details - Payroll workflow",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "New employee personal details",
                        cardDescription:
                            "Hi,<br>" +
                            "Please provide the below information so that we can initiate the set up of your new employee.<br>" +
                            "Thanks.",
                        requestItems: [
                            "Employee's full name",
                            "Employee's email address",
                            "Employee's mobile phone number",
                        ],
                    },
                },
                {
                    id: "d5da0e7f-fd66-4061-afbc-b4868b6abfc7",
                    title: "New Employee Employment Information - Payroll workflow",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "New employee employment information",
                        cardDescription:
                            "Hi,<br>" +
                            "Please provide the below information so that we can finalise the set up of your new employee.<br>" +
                            "Thanks.",
                        requestItems: [
                            "Is the employee paid under an agreement or an Award? ",
                            "If the employee is under an Award, which Award and what level in that Award?",
                            "If the employee is under an Award, will they be paid at award rates or above award rates?",
                            "If the employee is under an Award and they are paid above award rates, will any overtime be paid at above award rates?",
                            "If agreement, please provide a copy of that agreement",
                            "Is the employee full time, part time or casual?",
                            "What is the employee's salary/hourly rate?",
                            "Will the employee be required to complete timesheets?",
                            "Is the employee entitled to any leave in addition to standard leave?",
                            "Is the employee entitled to leave loading?",
                            "If the employee is entitled to leave loading, do you pay super on that leave loading?",
                        ],
                    },
                },
                {
                    id: "7a4cd32e-aeec-4ee1-98f8-681b68671372",
                    title: "Employment changes - Payroll workflow",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Employment changes",
                        cardDescription:
                            "Hi,<br>" +
                            "Please provide the below employment changes information so that we can update your employee.<br>" +
                            "Thanks.",
                        requestItems: [
                            "Employee name",
                            "Effective date of the changes",
                            "Provide details of the changes to be made",
                        ],
                    },
                },
                {
                    id: "c1b4b257-bffa-4fb1-b20f-ce3fd06cebe6",
                    title: "Bill payments reminder",
                    type: [ThreadTypes.AccountsPayable],
                    template: {
                        vaultTitle: "It's bill payments time",
                        cardDescription:
                            "Hi,<br>" +
                            "Please complete the below to ensure we can process your bill payments on time.<br>" +
                            "Thanks.",
                        requestItems: [
                            "Ensure all invoices, credit notes and statements are sent to Dext by [insert date]",
                            "Advise any relevant information for this payment run",
                        ],
                    },
                },
                {
                    id: "3cbc75c7-409e-4001-bab6-b8bbe5a0a4c8",
                    title: "Month end reminder",
                    type: [ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "It's month end time",
                        cardDescription:
                            "Hi,<br>" +
                            "Please complete the below to ensure we can process your month end on time.<br>" +
                            "Thanks",
                        requestItems: [
                            "Ensure all invoices, credit notes and statements are sent to Dext by [insert date]",
                            "Ensure all relevant documentation relating to last month is provided",
                        ],
                    },
                },
                {
                    id: "46e0da8c-f33e-4d8c-b094-7d58beddd10b",
                    title: "Activity statement payment",
                    type: [ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "Activity statement payment",
                        cardDescription:
                            "Hi,<br><br>" +
                            "Your activity statement has been lodged and now requires payment by [insert due date]<br><br>" +
                            "Payment details are as follows<br>" +
                            "BPAY<br>" +
                            "Amount - <br>" +
                            "Biller code - <br>" +
                            "Reference Number - <br><br>" +
                            "Feel free to reach out if you have any questions<br>" +
                            "Thanks",
                        requestItems: ["Pay activity statement"],
                    },
                },
                {
                    id: "9799b017-b6a3-4f0f-9c4e-8c646de96135",
                    title: "GST return payment",
                    type: [ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "GST return payment",
                        cardDescription:
                            "Hi,<br><br>" +
                            "Your GST return has been lodged and now requires payment of [insert amount] by [insert due date]<br><br>" +
                            "Please find attached the bank file forward dated to the due date, please upload to internet banking <br><br>" +
                            "Let me know if you have any questions <br><br>" +
                            "Thanks",
                        requestItems: ["Bank file has been uploaded to Internet Banking"],
                    },
                },
                {
                    id: "c9319097-4b57-45fa-b44a-621775893357",
                    title: "Transaction queries",
                    type: [VisoryLegacyWorkflows.Bookkeeping],
                    template: {
                        vaultTitle: "Transaction queries",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find below a list of transactions that require your input<br>" +
                            "If you have any queries please let me know.<br>" +
                            "Thanks",
                        requestItems: ["[Insert required information]"],
                    },
                },
                {
                    id: "e0f9f230-89b8-42cd-89ba-5b6b69169fea",
                    title: "PAYE payment",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "PAYE payment",
                        cardDescription:
                            "Hi,<br>" +
                            "We have now reconciled your PAYE with the IRD. Please attend to payment of $ [insert amount] by [insert due date] using the following payment information<br>" +
                            "Thanks",
                        requestItems: ["PAYE paid"],
                    },
                },
                {
                    id: "724555f9-f8f5-4c52-9abf-5b26c6836697",
                    title: "Super payment",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Super payment",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find attached your superannuation payment for the period [insert date]<br>" +
                            "Please follow below instructions to make superannuation payment [insert payment instructions]",
                        requestItems: ["Make super payment by [insert date]"],
                    },
                },
                {
                    id: "24bcd879-9c26-4eab-960d-130cc1e25ca0",
                    title: "Super contributions",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Super contributions",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find attached your superannuation for the period ending [insert date]<br>" +
                            "Please review and tick below to acknowledge your approval. Once approved we will create your Super payment which will need to be made by [insert date]<br>" +
                            "Let me know if you have any questions<br>" +
                            "Thanks",
                        requestItems: ["Super approved"],
                    },
                },
                {
                    id: "83f5e7e7-fd43-443d-8bc7-bf942dac765c",
                    title: "Payroll tax",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Payroll tax",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find attached your payroll tax for [insert state/s] for the period ending [insert date]<br>" +
                            "Please review and tick below to acknowledge your approval. Once approved we will create your payroll tax payment which will need to be made by [insert date]<br>" +
                            "Let me know if you have any questions<br>" +
                            "Thanks",
                        requestItems: ["Payroll tax approved"],
                    },
                },
                {
                    id: "225b08f5-1331-4c0d-9544-89109510b5d8",
                    title: "Payroll tax payment",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Payroll tax payment",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find attached your payroll tax payment for the period [insert date]<br>" +
                            "Please follow below instructions to make payroll tax payment [Insert payment instructions]",
                        requestItems: ["Make payroll tax payment by [insert date]"],
                    },
                },
                {
                    id: "e685c4f2-0b2d-4953-a050-ca33a8917f4f",
                    title: "Bonuses and commissions",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Bonuses and commissions info required",
                        cardDescription:
                            "Hi,<br>" +
                            "Please provide information so that we can process your bonuses and commissions<br>" +
                            "Thanks",
                        requestItems: [
                            "Provide the amounts to be paid",
                            "Provide the period these bonuses/commissions relate to",
                        ],
                    },
                },
                {
                    id: "553194b2-3ac2-4b3f-8b14-cadb40954385",
                    title: "Termination Details - Payroll workflow",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Termination details",
                        cardDescription:
                            "Hi,<br>" +
                            "Please provide the below information so that we can process the termination of your employee<br>" +
                            "Thanks",
                        requestItems: [
                            "Termination date",
                            "Termination reason",
                            "Are there any additional entitlements the employee is entitled to have paid out?",
                        ],
                    },
                },
                {
                    id: "303a3805-43be-4333-88cb-82addd3afa1b",
                    title: "Termination Approval",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Termination calculation approval",
                        cardDescription:
                            "Hi,<br>" +
                            "Please see attached the termination calculation for your employee. Please review and approve calculation<br>" +
                            "Thanks",
                        requestItems: ["Termination calculation approved"],
                    },
                },
                {
                    id: "a3b306ad-9303-492d-935f-eaed1875e8bc",
                    title: "Other payments information required",
                    type: [ThreadTypes.Payroll, ThreadTypes.AccountsPayable],
                    template: {
                        vaultTitle: "Information required",
                        cardDescription: `<p>Hi,</p>
                            <p> </p>
                            <p>We are processing your [insert description] payments and require the below information:</p>
                            <p>Let me know if you have any questions.</p>
                            <p>Thanks.</p>`,
                        requestItems: ["[Insert required information]"],
                    },
                },
                {
                    id: "2db3e1dc-89f3-43d0-b2b3-c6b379d5a42c",
                    title: "Payroll for review and approval (USA)",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Payroll for review and approval",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find attached payroll reports<br>" +
                            "Payroll will be paid on [insert date]<br>" +
                            "If you have any queries please let me know<br>" +
                            "Thanks",
                        requestItems: ["Review payroll reports and advise any changes required"],
                    },
                },
                {
                    id: "a5076932-68b0-4eaf-94d1-b92b5ff13f26",
                    title: "Payroll approval process",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "Payroll for review and approval",
                        cardDescription:
                            "Hi,<br>" +
                            "Please find attached payroll reports<br>" +
                            "Payroll will be paid on [insert date]<br>" +
                            "If you have any queries please let me know<br>" +
                            "Thanks",
                        requestItems: [
                            "Review payroll reports and advise any changes required",
                            "I approve the attached payroll and authorise Visory to submit STP to the ATO",
                        ],
                    },
                },
                {
                    id: "30d3b46f-a6a0-468b-9f63-006be4b93512",
                    title: "Superannuation increase to 11%",
                    type: [
                        VisoryLegacyWorkflows.PayrollFortnightly,
                        VisoryLegacyWorkflows.PayrollMonthly,
                        VisoryLegacyWorkflows.PayrollWeekly,
                    ],
                    template: {
                        vaultTitle: "Superannuation changes",
                        cardDescription: `<p>Hi,</p>
                            <p> </p>
                            <p>From 1 July 2023, the Super Guarantee (SG) rate for employers to pay on behalf of eligible employees increases from 10.5% to 11%.</p>
                            <p> </p>
                            <p>Because you pay your employees a salary inclusive of superannuation, you have two options:</p>
                            <ol>
                                <li>Option 1 - Increase your employees' salaries to allow for the 0.5% increase in superannuation. This will result in their net pay remaining the same.</li>
                                <li>Option 2 - Keep your employees' salaries the same. This will result in a reduction of their net pay.</li>
                            </ol>
                            <p> </p>
                            <p><strong>Please let us know which option you would like us to proceed with by [INSERT DATE 2 DAYS BEFORE PAY RUN]</strong></p>
                            <p> </p>
                            <p>If you have any questions, please feel free to reach out.</p>
                            <p> </p>
                            <p>Thanks.</p>`,
                        requestItems: ["Respond with Option 1 or Option 2"],
                    },
                },
                {
                    id: "5b122d83-dab5-4157-802a-a1942ad70156",
                    title: "Christmas payroll",
                    type: [ThreadTypes.Payroll],
                    template: {
                        vaultTitle: "importANT: Planning for your services over the holiday period",
                        cardDescription: `<p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>The Christmas period is fast approaching and as such, Visory is organising its operations to ensure that all services continue to run with minimal interruption over this time.</p>
                            <p>&nbsp;</p>
                            <p>Our payroll team will still be in operation over the holiday period, however, we will be offline on the national public holidays - Christmas, Boxing Day and New Years Day.</p>
                            <p>&nbsp;</p>
                            <p>Your pay day falls on either a national public holiday or the following day, which will impact usual processing rhythms.</p>
                            <p>&nbsp;</p>
                            <p>To prevent any disruption, we'd like to give you the option of whether you would like payroll to be completed prior to your regular pay day or after that public holiday.</p>
                            <p>&nbsp;</p>
                            Two important notes to consider when choosing your alternate pay dates:
                            <ol>
                                <li>Visory will need all required information one day prior the proposed pay date to ensure your employees are paid accurately and timely</li>
                                <li>If you elect for your pay day to be earlier, we understand that some information will be assumed and if the actual circumstances are different from the assumptions, any adjustments will be included in the following pay run.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <p><strong>Please note</strong></p>
                            <p>Most of our Bookkeeping team will be taking some time off over the holiday period. For December and January's Month End Reporting, accuracy is of upmost importance. We will work to complete this ASAP, aiming for no later than 10th February. If this is a concern for you, please let us know and we will work with you on a revised plan to deliver.</p>
                            <p>&nbsp;</p>
                            <p>Please directly respond to each of the items below with your preferred alternate pay day for each pay day that falls on a public holiday and whether you will be available to make relevant approvals:</p>`,
                        requestItems: [
                            "Christmas weekend - preferred alternate pay day: 23rd or 29th Dec",
                            "New Years Weekend - preferred alternate pay day: 30th Dec or 3rd Jan",
                            "Please confirm by ticking this option that you will be available the day prior and day of your selected alternate pay day to approve the payroll report and bank payments",
                        ],
                    },
                },
                {
                    id: "0d1156e6-ca15-4dba-b241-e468aa091270",
                    title: "Information required",
                    type: [
                        ThreadTypes.Request,
                        ThreadTypes.Payroll,
                        ThreadTypes.AccountsReceivable,
                        ThreadTypes.AccountsPayable,
                        ThreadTypes.Bookkeeping,
                        ThreadTypes.MyAccount,
                        ThreadTypes.Alert,
                        ThreadTypes.PeopleAndCulture,
                    ],
                    template: {
                        vaultTitle: "Information required",
                        cardDescription: `<p>Hi,</p>
                            <p> </p>
                            <p>We are preparing your [Insert description] and require the below information.</p>
                            <p> </p>
                            <p>Let me know if you have any questions.</p>
                            <p> </p>
                            <p>Thanks</p>`,
                        requestItems: ["[Insert required information]"],
                    },
                },
                {
                    id: "db4f1c1f-934a-49ca-bc43-cbd4a0b63584",
                    title: "Information required - onboarding",
                    type: [ThreadTypes.MyAccount],
                    template: {
                        vaultTitle: "Information required",
                        cardDescription: `
                            <p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>Welcome to Visory! We're excited to have you on board.</p>
                            <p>&nbsp;</p>
                            <p>Throughout your journey with us, you'll receive requests to provide information through our Visory Platform. These requests help us provide you with the best service possible.</p>
                            <p>&nbsp;</p>
                            <p>Here's your first request for information. Please review the items, check any attachments and take the necessary actions. You might need to attach a file, type a response, or complete an action outside the platform (e.g., inviting Visory to your Xero file). After you've completed an item, please click the radio button next to it to signal to your Visory Team that it's done.</p>
                            <p>&nbsp;</p>
                            <p>Feel free to reach out if you have any questions or need further assistance.</p>
                            <p>&nbsp;</p>
                            <p>Thanks!</p>
                        `,
                        requestItems: [
                            "ATO nomination completed",
                            "Accounting software access provided",
                            "Payroll software access provided",
                            "Third party software access provided",
                            "Bank access process initiated",
                            "Have attended the onboarding call introducing you to your Visory Team",
                        ],
                    },
                },
                {
                    id: "bf33e65f-db7f-4b5a-83d4-aebc2c218006",
                    title: "Information required - onboarding queries",
                    type: [ThreadTypes.MyAccount],
                    template: {
                        vaultTitle: "Information required",
                        cardDescription: `
                            <p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>We have a few queries we need your help with as part of your onboarding journey. This list may not cover everything, and we might have more questions along the way.</p>
                            <p>&nbsp;</p>
                            <p>Feel free to reach out if you have any questions or need further assistance.</p>
                            <p>&nbsp;</p>
                            <p>Thanks!</p>
                        `,
                        requestItems: ["[Insert required information]"],
                    },
                },
                {
                    id: "457ddc5c-dd44-4e95-afaf-a4afba6fddd8",
                    title: "Information required - onboarding Fitstop",
                    type: [ThreadTypes.MyAccount],
                    template: {
                        vaultTitle: "Information required",
                        cardDescription: `
                            <p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>Welcome to Visory! We're excited to have you on board.</p>
                            <p>&nbsp;</p>
                            <p>Throughout your journey with us, you'll receive requests to provide information through our Visory Platform. These requests help us provide you with the best service possible.</p>
                            <p>&nbsp;</p>
                            <p>Here's your first request for information. Please review the items, check any attachments and take the necessary actions. You might need to attach a file, type a response, or complete an action outside the platform (e.g., inviting Visory to your Xero file). After you've completed an item, please click the radio button next to it to signal to your Visory Team that it's done.</p>
                            <p>&nbsp;</p>
                            <p>Feel free to reach out if you have any questions or need further assistance.</p>
                            <p>&nbsp;</p>
                            <p>Thanks!</p>
                        `,
                        requestItems: [
                            "Transfer your Xero subscription to Visory using email: client@visoryhq.com (see instructions attached)",
                            "Upgrade the Visory Xero/MYOB permission to include 'manage users'",
                            "Nominate Visory as your agent with the ATO (see attached instructions)",
                            "Print, sign, scan and attach the IRD authority form attached",
                            "Confirm name and email of Dext mobile app users",
                            "Provide login details to access your email inbox",
                            "Provide read only bank access with password (not token) for",
                            "Provide access to [system name]",
                            "Provide access to [system name]",
                            "Attach a copy of your franchise agreement",
                            "Attach a copy of your lease agreement",
                            "Attach a copy of your insurance policy",
                        ],
                    },
                },
                {
                    id: "0c1c6445-294b-48e0-bb72-89b616bce229",
                    title: "Information required - request",
                    type: [ThreadTypes.Request],
                    template: {
                        vaultTitle: "Information required",
                        cardDescription: `
                            <p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>We are currently working on your request, in order to complete the request we require the below information.</p>
                            <p>&nbsp;</p>
                            <p>Let me know if you have any questions.</p>
                            <p>&nbsp;</p>
                            <p>Thanks!</p>
                        `,
                        requestItems: ["[Insert required information]"],
                    },
                },
                {
                    id: "f2a7fb5c-1237-46f5-b248-56680da36469",
                    title: "Information required - software access",
                    type: [ThreadTypes.MyAccount],
                    template: {
                        vaultTitle: "Information required",
                        cardDescription: `
                            <p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>Thank you for your time and for the information you shared. It was a great discussion, and I'm confident that Visory will be able to provide you with the service you're looking for.</p>
                            <p>&nbsp;</p>
                            <p>I've attached some additional information about Visory for your reference. You'll also find instructions for granting us access to your accounting software file below:</p>
                            <p>&nbsp;</p>
                            <p>[Insert instructions]</p>
                            <p>&nbsp;</p>
                            <p>Once we received access, one of our technical team members will review the file. This will allow us to prepare a detailed, customised proposal, including the right software, automation potential, and pricing with accuracy and confidence.</p>
                            <p>&nbsp;</p>
                            <p>I look forward to speaking with you again next [Day of the week].</p>
                            <p>&nbsp;</p>
                            <p>Thanks!</p>
                        `,
                        requestItems: ["[Insert required information]"],
                    },
                },
                {
                    id: "d009e679-fd2f-4ad0-8152-ef68745493c6",
                    title: "Information required - software invite",
                    type: [ThreadTypes.Onboarding],
                    template: {
                        vaultTitle: "Information required",
                        cardDescription: `
                            <p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>Welcome to the next step! 🎉 You’ve been invited to [software name], and we’re excited to get things rolling. To keep everything moving smoothly, please log in and complete the setup at your earliest convenience.</p>
                            <p>&nbsp;</p>
                            <p>Here's what to do next:</p>
                            <ol>
                                <li>Check your inbox for the invite from [software name].</li>
                                <li>Follow the prompts to set up your account.</li>
                                <li>Once done, mark this request as complete and we’ll be ready to move forward.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>If you run into any challenges, please feel to send us a message.</p>
                            <p>&nbsp;</p>
                            <p>Thanks!</p>
                        `,
                        requestItems: ["Software invite accepted and logged in successfully"],
                    },
                },
                {
                    id: "d78e48b3-1f5c-4bf9-b814-c9c49a98520c",
                    title: "Information required - Employment Hero invite",
                    type: [ThreadTypes.Onboarding],
                    template: {
                        vaultTitle: "Information required",
                        cardDescription: `
                            <p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>Welcome to the next step! 🎉 You’ve been invited to Employment Hero, and we’re excited to get things rolling. To keep everything moving smoothly, please log in and complete the setup at your earliest convenience.</p>
                            <p>&nbsp;</p>
                            <p>Here's what to do next:</p>
                            <ol>
                                <li>Check your inbox for the invite from Employment Hero.</li>
                                <li>Follow the prompts to set up your account.</li>
                                <li>Once done, mark this request as complete and we’ll be ready to move forward.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>If you run into any challenges, please feel to send us a message.</p>
                            <p>&nbsp;</p>
                            <p>Thanks!</p>
                        `,
                        requestItems: ["Employment Hero invite accepted and logged in successfully"],
                    },
                },
                {
                    id: "a70112ba-3479-4964-b294-e1b3496dc29e",
                    title: "Missing vendor details",
                    type: [ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "Missing vendor details",
                        cardDescription: `
                            <p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>Could you please download and open the attached list and insert any missing vendor names and emails?</p>
                            <p>&nbsp;</p>
                            <p>Once we have this, we'll identify which suppliers require 1099s and take care of the next steps for you by contacting the relevant vendors with you cc'd.</p>
                            <p>&nbsp;</p>
                            <p>Thanks,</p>`,
                        requestItems: ["Vendor names and emails updated on list", "Updated list attached here"],
                    },
                },
                {
                    id: "1dbc10ed-d2a7-4f8b-a03d-8ec4f1ceb2a9",
                    title: "Additional W-9 support",
                    type: [ThreadTypes.Bookkeeping],
                    template: {
                        vaultTitle: "Additional W-9 support",
                        cardDescription: `
                            <p>Hi,</p>
                            <p>&nbsp;</p>
                            <p>We need a little extra support from you to move this process along. Could you please review and action the requests below at your earliest convenience?</p>
                            <p>&nbsp;</p>
                            <p>Thank you so much for your help!</p>
                        `,
                        requestItems: ["[Insert required information]"],
                    },
                },
            ],
        },
        {
            title: RequestTitles.PayrollReportTitle,
            type: RequestTypes.PayrunRequest,
            description: "import a finalised pay run report for approval",
        },
        {
            title: RequestTitles.CreateBankFile,
            type: RequestTypes.CreateBankFileRequest,
            description: "Create an ABA file request with selected settings",
            template: {
                vaultTitle: "Payment file ready for {{payDate}}",
                cardDescription: `<p>Hi,</p>
                    <p>&nbsp;</p>
                    <p>Please find attached the aba file for this pay run.</p>
                    <p>&nbsp;</p>
                    <p>Download the file and upload to internet banking.</p>
                    <p>&nbsp;</p>
                    <p> Please let us when you have done this by completing the below, so that we can send the payslips and lodge STP.</p>
                    <p>&nbsp;</p>
                    <p>Let me know if you have any questions.</p>
                    <p>&nbsp;</p>
                    <p>Thanks.</p>`,
                requestItems: ["Upload aba file to Internet Banking"],
            },
        },
    ],
    calendarBookingLimits: {
        maxTimeAheadMs: 365 * 24 * 60 * 60 * 1000,
    },
};
