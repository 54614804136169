import { AsyncPipe, NgComponentOutlet } from "@angular/common";
import { Component, ElementRef, Inject, input, OnInit, ViewChild } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { AssigneeType } from "@visoryplatform/workflow-core";
import { EmbeddedItem } from "projects/portal-modules/src/lib/shared/services/embedded.service";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Observable } from "rxjs";
import { distinctUntilChanged, shareReplay } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { ThreadOverviewContentGhostLoaderComponent } from "./thread-overview-content-ghostloader.component";
import { ThreadOverviewContentPlaceholderComponent } from "./thread-overview-content-placeholder/thread-overview-content-placeholder.component";
@Component({
    selector: "thread-overview-content",
    templateUrl: "./thread-overview-content.component.html",
    styleUrl: "./thread-overview-content.component.scss",
    imports: [
        NgComponentOutlet,
        AsyncPipe,
        ThreadOverviewContentGhostLoaderComponent,
        ThreadOverviewContentPlaceholderComponent,
    ],
    standalone: true,
})
export class ThreadOverviewContentComponent implements OnInit {
    @ViewChild("appComponent") appComponent: ElementRef;

    busy = input<boolean>(false);
    item$: Observable<EmbeddedItem>;
    assigneeType = input.required<AssigneeType>();
    role = input.required<Role>();
    currentStepId = input.required<string>();

    constructor(@Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService) {}

    ngOnInit(): void {
        this.item$ = this.extensionDisplayService.item$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));
    }
}
