import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TuiSizeL, TuiSizeS } from "@taiga-ui/core";
import { VBadgeColors } from "@visoryplatform/portal-ui";
import { ThreadTypes } from "@visoryplatform/threads";
import { ThreadTypeBadgeMappingService } from "../../../threads-ui/services/thread-type-badge-mapping.service";

@Component({
    selector: "threads-services-badge",
    templateUrl: "./threads-services-badge.component.html",
    styleUrls: ["./threads-services-badge.component.scss"],
})
export class ThreadsServicesBadgeComponent implements OnChanges {
    @Input() threadType: string;
    @Input() size?: TuiSizeS | TuiSizeL = "s";

    badgeColor: VBadgeColors;
    isDelphiGenerated = false;

    constructor(private threadTypeMappingService: ThreadTypeBadgeMappingService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.threadType?.currentValue) {
            this.isDelphiGenerated = this.threadType === ThreadTypes.Alert;
            this.badgeColor = this.threadTypeMappingService.getBadgeColor(this.threadType);
        }
    }
}
