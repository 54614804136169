import { FeatureFlagService, ILaunchDarklyFeatureFlags, LaunchDarklyFeatureFlags } from "../../feature-flags";

import { Injectable } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { forkJoin, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { PermissionService } from "../../threads-ui/services/permissions.service";
import { FilterOption } from "../../timeline/interfaces/timeline-filters";

interface RoleOption {
    key: string;
    value: string;
    flag?: LaunchDarklyFeatureFlags;
    permission?: string;
}

@Injectable({ providedIn: "root" })
export class UserRoleOptionsService {
    private readonly roleOptions: RoleOption[] = [
        {
            key: Role.SuperAdministrator,
            value: "Super Administrator",
            permission: "SetSuperAdministratorRole",
        },
        {
            key: Role.Administrator,
            value: "Administrator",
        },
        {
            key: Role.Lead,
            value: "Lead",
            flag: LaunchDarklyFeatureFlags.EnableLeadRole,
        },
        {
            key: Role.SuccessManager,
            value: "Success Manager",
            flag: LaunchDarklyFeatureFlags.EnableSuccessManagerRole,
        },
        {
            key: Role.QualityTeam,
            value: "Quality Team",
            flag: LaunchDarklyFeatureFlags.EnableQualityTeamRole,
        },
        {
            key: Role.Staff,
            value: "Staff",
        },
        {
            key: Role.Expert,
            value: "Expert",
            flag: LaunchDarklyFeatureFlags.EnableExpertRole,
        },
        {
            key: Role.Partner,
            value: "Partner",
            flag: LaunchDarklyFeatureFlags.EnablePartnerRole,
        },
        {
            key: null,
            value: "No global role",
        },
    ];

    constructor(
        private featureFlagsService: FeatureFlagService,
        private permissionsService: PermissionService,
    ) {}

    list(userRole: Role): Observable<FilterOption[]> {
        return this.featureFlagsService.getFlags().pipe(
            map((flags) => this.filterOptions(this.roleOptions, flags)),
            switchMap((enabledOptions) => this.filterByPermissions(enabledOptions, userRole)),
            map((enabledOptions) => enabledOptions.map(({ key, value }) => ({ key, value }))),
        );
    }

    private filterByPermissions(options: RoleOption[], userRole: Role): Observable<RoleOption[]> {
        const hasPermissions$ = options.map((option) =>
            this.hasPermission(option, userRole).pipe(map((hasPermission) => ({ option, hasPermission }))),
        );

        return forkJoin(hasPermissions$).pipe(
            map((results) => results.filter(({ hasPermission }) => hasPermission).map(({ option }) => option)),
        );
    }

    private filterOptions(options: RoleOption[], flags: ILaunchDarklyFeatureFlags): RoleOption[] {
        return options.filter((option) => this.flagEnabled(flags, option));
    }

    private hasPermission(option: RoleOption, userRole: Role): Observable<boolean> {
        if (!option.permission) {
            return of(true);
        }

        return this.permissionsService.checkPermissions(userRole, option.permission);
    }

    private flagEnabled(flags: ILaunchDarklyFeatureFlags, option: RoleOption): boolean {
        return !option.flag || flags[option.flag];
    }
}
