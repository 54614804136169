import { Component, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmPaymentData, SetupIntent, StripeError } from "@stripe/stripe-js";
import {
    IPayment,
    ISetupIntent,
    ISubscription,
    PaymentMethodTypes,
    PaymentTypes,
} from "@visoryplatform/payments-service-sdk";
import { IPaymentInvoiceDetails } from "@visoryplatform/threads";
import { ErrorHelper } from "@visoryplatform/threads/src/helpers";
import { HandledError } from "@visoryplatform/video-chat-sdk";
import { StripeInstance, StripePaymentElementComponent } from "ngx-stripe";
import { environmentCommon } from "projects/portal-modules/src/lib/environment/environment.common";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { ExtensionDisplayRef } from "projects/portal-modules/src/lib/shared/services/extension-display-ref";
import { ExtensionDisplayService } from "projects/portal-modules/src/lib/shared/services/extension-display.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { combineLatest, EMPTY, from, Observable, of } from "rxjs";
import { catchError, switchMap, take, takeUntil } from "rxjs/operators";
import { EXTENSION_DISPLAY_SERVICE } from "src/app/injection-token";
import { IPackagePriceDetails } from "../../interfaces/IPackagePriceDetails";
import { IPaymentCardState } from "../../interfaces/IPaymentCardState";
import { PaymentService } from "../../services/payment.service";

export interface IPaymentModalData {
    state: IPaymentCardState;
    threadId: string;
    cardId: string;
    paymentsSubjectId: string;
    accountId: string;
    packagePriceDetails: IPackagePriceDetails;
    description: string;
    invoiceId?: string;
}

type ConfirmPaymentResponse = {
    setupIntent?: SetupIntent;
    error?: StripeError;
};

@Component({
    selector: "payment-modal",
    templateUrl: "./payment-modal.component.html",
    styleUrls: ["./payment-modal.component.scss"],
})
export class PaymentModalComponent extends SubscriberBaseComponent implements OnInit {
    @ViewChild(StripePaymentElementComponent)
    paymentElement: StripePaymentElementComponent;
    stripe: StripeInstance;

    readonly paymentTypes = PaymentTypes;

    form = new UntypedFormGroup({
        name: new UntypedFormControl("", [Validators.required]),
        address: new UntypedFormControl("", [Validators.required]),
        city: new UntypedFormControl("", [Validators.required]),
        postcode: new UntypedFormControl("", [Validators.required]),
        country: new UntypedFormControl("", [Validators.required]),
        state: new UntypedFormControl("", [Validators.required]),
    });
    errorMessage = "";
    packagePriceDetails: IPackagePriceDetails;
    cardState: IPaymentCardState;
    threadId: string;
    cardId: string;
    accountId: string;
    paymentsSubjectId: string;
    billingDate: Date;
    paymentType: PaymentTypes;
    paymentMethods: string[];
    description: string;
    invoiceId?: string;
    acceptedCountries = environmentCommon.acceptedCountries;
    elementsOptions = environmentCommon.stripeConfig.options;
    appearance = environmentCommon.stripeConfig.appearance;
    loader = new Loader();

    data: IPaymentModalData;
    private extensionDisplayRef: ExtensionDisplayRef<boolean>;

    constructor(
        @Inject(EXTENSION_DISPLAY_SERVICE) private extensionDisplayService: ExtensionDisplayService,
        private paymentService: PaymentService,
        private unsavedDialogService: UnsavedModalDialogService,
        private injector: Injector,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.getDialogData();

        this.invoiceId = this.data.invoiceId;
        this.accountId = this.data.accountId;
        this.cardState = this.data.state;
        this.paymentType = this.cardState.paymentType;
        this.packagePriceDetails = this.data.packagePriceDetails;
        this.threadId = this.data.threadId;
        this.cardId = this.data.cardId;
        this.description = this.data.description;
        this.paymentsSubjectId = this.data.paymentsSubjectId;
        this.billingDate =
            this.paymentType === PaymentTypes.ScheduledPayment ? new Date(this.cardState.billingDate) : new Date();
        this.stripe = this.paymentService.stripe;

        this.paymentMethods = environmentCommon.stripeConfig.paymentMethods[this.cardState.currency || "aud"];
        this.loadPaymentDetails();
    }

    private async getDialogData(): Promise<void> {
        try {
            this.data = await this.extensionDisplayService.getData<IPaymentModalData>(this.injector).toPromise();
            this.extensionDisplayRef = await this.extensionDisplayService.getRef<boolean>(this.injector).toPromise();
        } catch (e) {
            // Use modal as fallback
            this.data = await this.extensionDisplayService.getData<IPaymentModalData>(this.injector, true).toPromise();
            this.extensionDisplayRef = await this.extensionDisplayService
                .getRef<boolean>(this.injector, true)
                .toPromise();
        }
    }

    public actionPaymentCard(invoiceId?: string): void {
        const paymentSetup$ = this.loader.wrap(
            this.paymentService.confirmSetupIntent(
                this.paymentElement.elements,
                this.buildPaymentParams(),
                "if_required",
            ),
        );
        const setupIntent$ = this.loader.wrap(this.confirmPaymentSetup(paymentSetup$, invoiceId));
        setupIntent$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.extensionDisplayRef.close(true));
    }

    public async close(): Promise<void> {
        if (!this.form.dirty) {
            this.extensionDisplayRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("payment_edit");
            if (confirmClose) {
                this.extensionDisplayRef.close();
            }
        }
    }

    private loadPaymentDetails(): void {
        const customerAdressAndInvoice$ = this.loader.wrap(
            this.paymentService.createCustomerAndressAndInvoice(this.accountId),
        );
        const addressAndSetupIntent$ = this.loader.wrap(
            customerAdressAndInvoice$.pipe(
                switchMap(([address, invoiceDetails]) => {
                    const setupIntent$ = this.createSetupIntent(invoiceDetails);
                    return combineLatest([of(address), setupIntent$]);
                }),
                catchError((err: unknown) => {
                    throw new HandledError(err);
                }),
            ),
        );

        addressAndSetupIntent$.pipe(take(1)).subscribe(([address, setupIntent]) => {
            this.form.setValue(address);
            this.elementsOptions.clientSecret = setupIntent.client_secret;
        });
    }

    private createSetupIntent(invoiceDetails: IPaymentInvoiceDetails): Observable<ISetupIntent> {
        return this.paymentService.setupPaymentMethod(
            this.paymentMethods,
            invoiceDetails,
            this.cardState.currency,
            this.accountId,
        );
    }

    private buildAddressData(): IPaymentInvoiceDetails {
        return {
            address: {
                city: this.form.controls.city.value,
                country: this.form.controls.country.value,
                line1: this.form.controls.address.value,
                postal_code: this.form.controls.postcode.value,
                state: this.form.controls.state.value,
            },
        };
    }

    private buildPaymentParams(): ConfirmPaymentData {
        return {
            payment_method_data: {
                billing_details: { ...this.buildAddressData(), name: this.form.controls.name.value },
            },
            return_url: window.location.href,
        };
    }

    private setupPayment(
        setupIntent: SetupIntent,
        addressData: IPaymentInvoiceDetails,
        invoiceId?: string,
    ): Observable<IPayment | ISubscription | void> {
        if (this.paymentType === PaymentTypes.ScheduledPayment) {
            const paymentMethod$ = this.paymentService.setupPaymentMethodForCard({
                threadId: this.threadId,
                cardId: this.cardId,
                paymentMethodId: setupIntent.payment_method as string,
                paymentMethodType: PaymentMethodTypes.Card,
                invoiceDetails: addressData,
                currency: this.cardState.currency,
                accountId: this.accountId,
                paymentSubjectId: this.paymentsSubjectId,
            });

            return from(paymentMethod$);
        } else if (this.cardState.isRecurring) {
            return this.paymentService.activateSubscription(
                this.threadId,
                this.paymentsSubjectId,
                setupIntent.payment_method as string,
                true,
                invoiceId,
            );
        } else if (!this.cardState.isRecurring && this.cardState.status !== "succeeded") {
            return this.paymentService.completePayment(
                this.threadId,
                this.paymentsSubjectId,
                setupIntent.payment_method as string,
                true,
            );
        } else {
            return of(null);
        }
    }

    private confirmPaymentSetup(paymentSetup$: Observable<ConfirmPaymentResponse>, invoiceId?: string) {
        return paymentSetup$.pipe(
            switchMap((result) => {
                const addressData = this.buildAddressData();
                const setupPayment$ = this.setupPayment(result.setupIntent, addressData, invoiceId);
                const updateCustomer$ = this.paymentService.updateCustomerAddress(this.accountId, addressData);
                return combineLatest([updateCustomer$, setupPayment$]);
            }),
            catchError((err: unknown) => {
                if (ErrorHelper.isErrorWithMessage(err)) {
                    this.errorMessage = err?.message || "Sorry, something went wrong. Please contact support.";
                }
                return EMPTY;
            }),
        );
    }
}
