import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { VBadgeColors } from "@visoryplatform/portal-ui";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { ThreadTypeBadgeMappingService } from "../../../threads-ui/services/thread-type-badge-mapping.service";

@Component({
    selector: "thread-type-badge",
    templateUrl: "./thread-type-badge.component.html",
    styleUrls: ["./thread-type-badge.component.scss"],
})
export class ThreadTypeBadgeComponent implements OnChanges {
    @Input() threadType: string;

    badgeColor: VBadgeColors;
    showAiIcon$: Observable<boolean>;

    constructor(
        private threadTypeMappingService: ThreadTypeBadgeMappingService,
        private featureFlagService: FeatureFlagService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.threadType?.currentValue) {
            this.badgeColor = this.threadTypeMappingService.getBadgeColor(this.threadType);

            this.showAiIcon$ = this.featureFlagService
                .getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding)
                .pipe(map((isEnabled) => isEnabled && this.threadType === "Alert"));
        }
    }
}
